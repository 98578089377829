import { Icon, Variable, WidgetAutoXML, WidgetProperties } from "lazy-widgets";
import { BaseDecoratedButton } from "../../lazy-widgets/widgets/base-decorated-button.js";
import { sfThemeSmaller } from "../../misc/sf-theme.js";

export class KioskTabIconButton extends BaseDecoratedButton<Icon> {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-tab-icon-button",
        inputConfig: [
            {
                mode: "value",
                name: "icon",
            },
            {
                mode: "value",
                name: "value",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    constructor(icon: string, readonly value: unknown, readonly variable: Variable<unknown>, properties?: Readonly<WidgetProperties>) {
        super(
            new Icon(icon), {
            bodyTextFont: sfThemeSmaller.bodyTextFont,
            unhoverBackgroundFill: "rgba(120, 120, 120, 0.25)",
            hoverBackgroundFill: "rgba(120, 120, 120, 0.35)",
            holdBackgroundFill: "rgba(0, 120, 240, 0.35)",
            forceBackgroundFill: "rgba(0, 120, 240, 0.45)",
            roundedCornersRadii: [4, 4, 0, 0],
            minWidth: 20, maxWidth: 20,
            minHeight: 20, maxHeight: 20,
            hasBorder: false,
            noCursorOnForced: true,
            ...properties,
        });

        this.variable.watch(() => {
            this.forced = variable.value === value;
        }, true);

        this.on("click", () => {
            this.variable.value = this.value;
        });
    }
}