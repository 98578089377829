import { AxisCoupling, Column, Container, Label, Row, ScrollableViewportWidget, ScrollbarMode, Variable, WrapMode, type ScrollableViewportWidgetProperties, type WidgetAutoXML } from "lazy-widgets";
import { common } from "../../../common.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedCheckbox } from "./decorated-checkbox.js";

const MUTE_ICON_URL = 'assets/textures/ui/icons/kiosk/mute.svg';
const VOLUME_MAX_ICON_URL = 'assets/textures/ui/icons/kiosk/volume-max.svg';

export class PlayerList extends ScrollableViewportWidget {
    static override autoXML: WidgetAutoXML = {
        name: "player-list",
        inputConfig: []
    };

    private playerRows = new Map<string, Container>();

    constructor(properties?: ScrollableViewportWidgetProperties) {
        const column = new Column();

        super(
            column,
            {
                minHeight: 140,
                scrollBarThickness: 12,
                scrollbarCornersRadii: 4.5,
                scrollbarMode: ScrollbarMode.Hidden,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.None,
                backgroundFill: "rgba(0,0,0,0)",
                backgroundGlowFill: "rgba(0,0,0,0)",
                primaryFill: "rgba(255, 255, 255, 0.75)",
                accentFill: "rgba(255, 255, 255, 0.60)",
                ...properties,
            },
        );

        common.MAIN_CHANNEL.on("room-player-join", ([key, player]: [key: string, player: { name: string }]) => {
            const muteVariable = new Variable(false);
            muteVariable.watch(() => { common.hoverboardNetworking.setPeerMute(key, muteVariable.value); });
            const muteButton = new DecoratedCheckbox(
                muteVariable,
                {
                    containerPadding: { top: 5, right: 5, bottom: 5, left: 5 },
                    tickedSVG: MUTE_ICON_URL,
                    untickedSVG: VOLUME_MAX_ICON_URL,
                    iconWidth: 16,
                    iconHeight: 16,
                    forceStrokeThickness: 0,
                    normalStrokeThickness: 0,
                    unclickableStrokeThickness: 0
                });


            const widget = new Container(
                new BackPane(
                    new Row([
                        new Label(player.name, {
                            bodyTextFont: "1.5em sui-generis",
                            wrapMode: WrapMode.Ellipsis,
                            minWidth: 200, maxWidth: 200,
                        }),
                        muteButton
                    ],
                        { multiContainerSpacing: 16 }
                    ),
                    { containerPadding: { top: 5, right: 10, bottom: 5, left: 10 } },
                ), {
                containerPadding: { top: 0, right: 5, bottom: 0, left: 0 },
            });


            this.playerRows.set(key, widget);
            column.add(widget);

            this.updateScrollbarVisibility();
        });

        common.MAIN_CHANNEL.on("room-player-leave", (key: string) => {
            const widget = this.playerRows.get(key);
            if (widget) {
                this.playerRows.delete(key);
                column.remove(widget);
            }

            this.updateScrollbarVisibility();
        });

        common.MAIN_CHANNEL.on("room-leave", (key: string) => {
            for (const widget of this.playerRows.values()) {
                column.remove(widget);
            }

            this.playerRows.clear();

            this.updateScrollbarVisibility();
        });
    }

    private updateScrollbarVisibility() {
        if (this.playerRows.size > 4) {
            this.scrollbarMode = ScrollbarMode.Layout;
            for (const container of this.playerRows.values()) {
                container.containerPadding = { top: 0, right: 7, bottom: 0, left: 0 };
            }
        } else {
            this.scrollbarMode = ScrollbarMode.Hidden;
            for (const container of this.playerRows.values()) {
                container.containerPadding = { top: 0, right: 5, bottom: 0, left: 0 };
            }
        }
    }
}