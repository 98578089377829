import { common } from "src/hoverfit/common.js";
import { GameModeVariable } from "./game-mode-variable.js";

export class TagDurationVariable extends GameModeVariable<number> {
    constructor() {
        super(90, "change_tag_duration");
    }

    protected override sendNetVar(): void {
        common.hoverboardNetworking?.updateTagDuration(this.value);
    }
}