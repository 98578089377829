export const enum ObservableItemIDCollectionEventType {
    /** One or more items have been added to the collection */
    Add = 0,
    /** One or more items have been removed from the collection */
    Remove = 1,
    /**
     * The values mapped to one or more items have been changed. Only used by
     * mapped item collections
     */
    Update = 2,
}

export type ObservableItemIDCollectionEventListener = (type: ObservableItemIDCollectionEventType, ids: ReadonlyArray<string>) => void;

export abstract class ObservableItemIDCollection {
    protected readonly ids: string[] = [];
    private readonly listeners: ObservableItemIDCollectionEventListener[] = [];

    constructor(protected _suppressed = false) { }

    /**
     * Should notifications and collection items be suppressed? If true, then no
     * events will be fired, and the collection will pretend to be empty for
     * external reads (you can, however, still write to the collection).
     */
    get suppressed() {
        return this._suppressed;
    }

    /**
     * If the collection is suppressed, then it will be unsuppressed; you will
     * be able to read items from the collection and events will start being
     * fired. If the collection had items at the time of unsuppression, then an
     * {@link ObservableItemIDCollectionEventType#Add} event will be fired
     * immediately.
     */
    unsuppress() {
        if (!this._suppressed) return;
        this._suppressed = false;
        if (this.ids.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, this.ids);
        }
    }

    /**
     * Get all item IDs in this collection. Returns a read-only live view into
     * the collection's internal item ID list.
     */
    getAllIDs() {
        if (this._suppressed) return [];
        // XXX should this return a snapshot?
        return this.ids as ReadonlyArray<string>;
    }

    /**
     * Check if the collection contains an item by ID.
     *
     * If suppressed, the collection will pretend that it doesn't have the item,
     * even if it actually does.
     */
    abstract has(id: string): boolean;

    /** Send notification to all listeners. Does nothing if suppressed. */
    protected notify(type: ObservableItemIDCollectionEventType, ids: ReadonlyArray<string>) {
        if (this._suppressed) return;
        for (let i = 0; i < this.listeners.length; i++) {
            try {
                this.listeners[i](type, ids);
            } catch (err) {
                console.error(err);
            }
        }
    }

    /**
     * Start listening for changes to the collection.
     *
     * @param callNow If true, fires an {@link ObservableItemIDCollectionEventType#Add} event immediately to the new listener if the collection is not empty and not suppressed
     */
    watch(listener: ObservableItemIDCollectionEventListener, callNow = false) {
        if (this.listeners.indexOf(listener) >= 0) return false;
        this.listeners.push(listener);
        if (callNow && !this._suppressed && this.ids.length > 0) {
            listener(ObservableItemIDCollectionEventType.Add, this.ids);
        }
        return true;
    }

    /** Stop listening for changes to the collection. */
    unwatch(listener: ObservableItemIDCollectionEventListener) {
        const i = this.listeners.indexOf(listener);
        if (i < 0) return false;
        this.listeners.splice(i, 1);
        return true;
    }
}