import { Object3D } from "@wonderlandengine/api";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";
import { MathUtils, vec3_create, Vector3 } from "wle-pp";
import { HowlerAudio } from "../../audio-manager/implementations/howler-audio.js";

export class EnvironmentalSound {
    private sound: HowlerAudio;

    private soundObject: Object3D;
    private useObjectPositionAsPlayPosition: boolean;

    private minWorldPosition: Vector3;
    private maxWorldPosition: Vector3;

    private minHorizontalRadius: number;
    private maxHorizontalRadius: number;

    private minVerticalDistance: number;
    private maxVerticalDistance: number;

    private minPitch: number;
    private maxPitch: number;

    constructor(sound: HowlerAudio, soundObject: Object3D, useObjectPositionAsPlayPosition: boolean, minWorldPosition: Vector3, maxWorldPosition: Vector3, minHorizontalRadius: number, maxHorizontalRadius: number, minVerticalDistance: number, maxVerticalDistance: number, minPitch: number, maxPitch: number) {
        this.sound = sound;

        this.soundObject = soundObject;
        this.useObjectPositionAsPlayPosition = useObjectPositionAsPlayPosition;

        this.minWorldPosition = minWorldPosition;
        this.maxWorldPosition = maxWorldPosition;

        this.minHorizontalRadius = minHorizontalRadius;
        this.maxHorizontalRadius = maxHorizontalRadius;

        this.minVerticalDistance = minVerticalDistance;
        this.maxVerticalDistance = maxVerticalDistance;

        this.minPitch = minPitch;
        this.maxPitch = maxPitch;
    }

    play(position: Vector3) {
        if (this.useObjectPositionAsPlayPosition) {
            position = this.soundObject.pp_getPosition();
        }

        const offsetPosition = vec3_create(
            0,
            MathUtils.random(this.minVerticalDistance, this.maxVerticalDistance),
            MathUtils.random(this.minHorizontalRadius, this.maxHorizontalRadius)
        );

        offsetPosition.vec3_rotateAxis(MathUtils.random(0, 360), GameGlobals.up, offsetPosition);

        const playPosition = position.vec3_add(offsetPosition);

        for (let i = 0; i < 3; i++) {
            playPosition[i] = MathUtils.clamp(playPosition[i], this.minWorldPosition[i], this.maxWorldPosition[i]);
        }

        const soundID = this.sound.play();
        this.sound.setPitch(MathUtils.random(this.minPitch, this.maxPitch), true);
        this.sound.setPosition(playPosition, true);

        return soundID;
    }

    isPlaying(soundID: number) {
        return this.sound.isPlaying(soundID);
    }
}