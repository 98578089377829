import { Component, PhysXComponent, Property } from "@wonderlandengine/api";
import { CursorTarget } from "@wonderlandengine/components";
import { CursorStyleManagerComponent } from "cursor-style-manager-wle";
import { AnalyticsUtils, Globals, ObjectCloneParams, Vec3Utils, quat_create } from "wle-pp";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { common } from "../../../common.js";
import { ScaleOnHoverComponent } from "../../../misc/components/transform/scale-on-hover-component.js";
import { downHapticFeedback, hoverHapticFeedback, unHoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";

export class RoadMapBalconyMarkerComponent extends Component {
    static TypeName = "road-map-balcony-marker";
    static Properties = {
        miniMap: Property.object(),
        markerPrototype: Property.object(),
        hubBalcony: Property.object(),
        balconyParent: Property.object(),
        returnToBalconyButton: Property.object()
    };

    static onRegister(engine) {
        engine.registerComponent(ScaleOnHoverComponent);
    }

    start() {
        const csm = Globals.getRootObject().pp_getComponent(CursorStyleManagerComponent);

        this.markerPrototype.pp_setActive(false);

        this.position = new Float32Array(3);
        this.forward = new Float32Array(3);
        for (const currentBalcony of this.balconyParent.children) {
            const balconyPhysxComponent = currentBalcony.pp_getComponent(PhysXComponent);

            let cloneParams = new ObjectCloneParams();
            cloneParams.myUseDefaultComponentCloneAsFallback = true;
            const balconyMarkerObject = this.markerPrototype.pp_clone(cloneParams);
            balconyMarkerObject.pp_setActive(true);
            balconyMarkerObject.addComponent(ScaleOnHoverComponent, { scaleSpeed: 4.5 });

            currentBalcony.pp_getPosition(this.position);
            this.miniMap.pp_convertPositionLocalToWorld(this.position, this.position);
            balconyMarkerObject.pp_setPosition(this.position);

            let balconyTarget = balconyMarkerObject.pp_getComponent(CursorTarget);
            balconyTarget.onUpWithDown.add(() => {
                balconyPhysxComponent.active = true;
                this.currentBalconyPhysx = balconyPhysxComponent;
                this.transportPlayerToSmallBalcony(currentBalcony);
                common.audioManager.getAudio(AudioID.BUTTON_CLICK).play();
            });

            balconyTarget.onHover.add((_, cursor) => {
                if (csm) csm.setStyle(this, "pointer");
                common.audioManager.getAudio(AudioID.BUTTON_HOVER).play();
                if (cursor) hoverHapticFeedback(cursor.handedness);
            });

            balconyTarget.onUnhover.add((_, cursor) => {
                if (csm) csm.clearStyle(this);
                if (cursor) unHoverHapticFeedback(cursor.handedness);
            });

            balconyTarget.onUp.add((_, cursor) => {
                if (cursor) upHapticFeedback(cursor.handedness);
            });

            balconyTarget.onDown.add((_, cursor) => {
                if (cursor) downHapticFeedback(cursor.handedness);
            });
        }

        if (this.hubBalcony != null) {
            let cloneParams = new ObjectCloneParams();
            cloneParams.myUseDefaultComponentCloneAsFallback = true;
            const balconyMarkerObject = this.markerPrototype.pp_clone(cloneParams);
            balconyMarkerObject.pp_setActive(true);
            balconyMarkerObject.addComponent(ScaleOnHoverComponent, { scaleSpeed: 4.5 });

            this.hubBalcony.pp_getPosition(this.position);
            this.miniMap.pp_convertPositionLocalToWorld(this.position, this.position);
            balconyMarkerObject.pp_setPosition(this.position);

            let balconyTarget = balconyMarkerObject.pp_getComponent(CursorTarget);
            balconyTarget.onUpWithDown.add(() => {
                common.balcony.moveToBalcony(true);
            });

            balconyTarget.onHover.add((_, cursor) => {
                if (csm) csm.setStyle(this, "pointer");
                common.audioManager.getAudio(AudioID.BUTTON_HOVER).play();
                if (cursor) hoverHapticFeedback(cursor.handedness);
            });

            balconyTarget.onUnhover.add((_, cursor) => {
                if (csm) csm.clearStyle(this);
                if (cursor) unHoverHapticFeedback(cursor.handedness);
            });

            balconyTarget.onUp.add((_, cursor) => {
                if (cursor) upHapticFeedback(cursor.handedness);
            });

            balconyTarget.onDown.add((_, cursor) => {
                if (cursor) downHapticFeedback(cursor.handedness);
            });
        }

        let returnButtonTarget = this.returnToBalconyButton.pp_getComponent(CursorTarget);
        returnButtonTarget.onUpWithDown.add(() => {
            this.currentBalconyPhysx.active = false;
            common.balcony.isPlayerOnBalcony.value = false;
            common.balcony.moveToBalcony();

            if (common.environmentSourroundingController) {
                common.environmentSourroundingController.onMoveToBalconyOrTrack();
            }

            if (this.balconySFXPath) {
                this.balconyAmbientSFXPlayer.stop();
            }

            this.returnToBalconyButton.pp_setParent(this.returnToBalconyButtonOriginalParent, false);
            this.returnToBalconyButton.pp_setScale(this.returnToBalconyButtonOriginalScale);
            this.returnToBalconyButton.pp_setActive(false);
        });

        this.returnToBalconyButton.pp_setActive(false);
        this.returnToBalconyButtonOriginalParent = this.returnToBalconyButton.pp_getParent();
        this.returnToBalconyButtonOriginalScale = this.returnToBalconyButton.pp_getScale();
    }

    transportPlayerToSmallBalcony(smallBalcony) {
        AnalyticsUtils.sendEvent("move_to_small_balcony");

        smallBalcony.pp_getPosition(this.position);
        smallBalcony.pp_getForward(this.forward);
        Vec3Utils.add(this.position, this.forward, this.position);

        Globals.getPlayerLocomotion().setActive(true);
        const playerTransformManager = Globals.getPlayerTransformManager();
        playerTransformManager.teleportPosition(this.position, null, true);

        if (common.environmentSourroundingController) {
            common.environmentSourroundingController.onMoveToSmallBalcony();
        }

        let rotation = quat_create();
        rotation.quat_setForward(this.forward);
        playerTransformManager.setRotationQuat(rotation);

        playerTransformManager.resetReal(true, false, false, true);
        playerTransformManager.resetHeadToReal();

        this.returnToBalconyButton.pp_setParent(smallBalcony, false);
        this.returnToBalconyButton.pp_setScale(this.returnToBalconyButtonOriginalScale);
        this.returnToBalconyButton.pp_setActive(true);
    }
}
