import { Label, TextAlignMode, WidgetAutoXML, filterIDFromProperties } from "lazy-widgets";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";

export interface LabelledDecoratedButtonProperties extends DecoratedButtonProperties {
    buttonLabelFill?: string;
    unclickableButtonLabelFill?: string;
}

export class DecoratedButton extends BaseDecoratedButton<Label> {
    static override autoXML: WidgetAutoXML = {
        name: "decorated-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            }
        ]
    };

    readonly label: Label;

    private buttonLabelFill: string;
    private unclickableButtonLabelFill: string;

    constructor(name: string, properties?: Readonly<LabelledDecoratedButtonProperties>) {
        const buttonLabelFill = properties?.buttonLabelFill ?? "black";
        const unclickableButtonLabelFill = properties?.unclickableButtonLabelFill ?? "rgb(128,128,128)";

        const label = new Label(
            name,
            {
                bodyTextFill: buttonLabelFill,
                bodyTextFont: (properties?.fontSize || 0.9) + "em " + (properties?.fontFamily || "sui-generis"),
                bodyTextAlign: TextAlignMode.Center,
                ...filterIDFromProperties(properties),
            },
        );

        super(label, properties);

        this.label = label;

        this.buttonLabelFill = buttonLabelFill;
        this.unclickableButtonLabelFill = unclickableButtonLabelFill;
        this.updateTextColor();
    }

    private updateTextColor() {
        this.child.bodyTextFill = this.clickable ? this.buttonLabelFill : this.unclickableButtonLabelFill;
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateTextColor();
    }

    override get clickable() {
        return super.clickable;
    }
}