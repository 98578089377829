import { Object3D } from "@wonderlandengine/api";
import { common } from "src/hoverfit/common.js";
import { MathUtils } from "wle-pp";
import { PickupGrabber } from "../pickup-grabber.js";
import { Pickup, PickupParams, PickupType } from "../pickup.js";

export class SyncedPickupParams extends PickupParams { }

export class SyncedPickup extends Pickup {
    declare protected _params: SyncedPickupParams;

    private _waitingNetworkPickupRequestConfirm: boolean = false;
    private _pendingPickupGrabber: PickupGrabber | null = null;
    private _pickupRequestUUID: string | null = null;

    // The sync counter is used as a simple way to prevent picking a pickup multiple times due to network delays
    // and players getting the same pickup at the same time
    private _syncCounter: number = 0;

    constructor(pickupType: PickupType, pickupObject: Object3D, params: SyncedPickupParams) {
        super(pickupType, pickupObject, params);
    }

    override update(dt: number, pickupGrabbers: PickupGrabber[]) {
        if (!this._active) return;

        if (!this._waitingNetworkPickupRequestConfirm) {
            super.update(dt, pickupGrabbers);
        }
    }

    override reset() {
        super._onRespawn();

        this._waitingNetworkPickupRequestConfirm = false;
        this._pickupRequestUUID = null;
        this._pendingPickupGrabber = null;

        this._syncCounter = 0;
    }

    override setActive(active: boolean) {
        super.setActive(active);

        this._syncCounter = 0;

        this._waitingNetworkPickupRequestConfirm = false;
        this._pickupRequestUUID = null;
        this._pendingPickupGrabber = null;
    }

    onPickupRequestConfirmed(syncCounter: number, pickupRequestUUID: string) {
        if (this._syncCounter > syncCounter) return;

        if (this.isEnabled() && this._syncCounter == syncCounter && this._pickupRequestUUID == pickupRequestUUID && this._waitingNetworkPickupRequestConfirm && this._pendingPickupGrabber!.canPickup()) {
            super._onPickup(this._pendingPickupGrabber!);
        } else {
            this._disablePickup();
        }

        this._syncCounter = syncCounter + 1;

        this._waitingNetworkPickupRequestConfirm = false;
        this._pickupRequestUUID = null;
        this._pendingPickupGrabber = null;
    }

    onPickupRespawn(syncCounter: number) {
        if (this._syncCounter > syncCounter) return;

        super._onRespawn();

        this._waitingNetworkPickupRequestConfirm = false;
        this._pickupRequestUUID = null;
        this._pendingPickupGrabber = null;

        this._syncCounter = syncCounter + 1;
    }

    protected override _onPickup(pickupGrabber: PickupGrabber) {
        if (!common.hoverboardNetworking.room) {
            super._onPickup(pickupGrabber);
        } else {
            this._pickupRequestUUID = MathUtils.randomUUID();
            common.hoverboardNetworking.sendPickupRequest(this.getID(), this._syncCounter, this._pickupRequestUUID);

            this._waitingNetworkPickupRequestConfirm = true;
            this._pendingPickupGrabber = pickupGrabber;
        }
    }

    protected _onRespawn() {
        if (!common.hoverboardNetworking.room) {
            super._onRespawn();
        } else {
            common.hoverboardNetworking.sendPickupRespawn(this.getID(), this._syncCounter);
        }
    }
}