import { Object3D } from "@wonderlandengine/api";
import { common } from "src/hoverfit/common.js";
import { SuperRocketStatusEffect, SuperRocketStatusEffectParams } from "src/hoverfit/game/hoverboard/status-effects/implementations/super-rocket-status-effect.js";
import { StatusEffectTarget } from "src/hoverfit/game/hoverboard/status-effects/status-effect-target.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupSuperRocketParams extends SyncedPickupParams { }

export class PickupSuperRocket extends SyncedPickup {
    declare protected _params: PickupSuperRocketParams;

    constructor(pickupObject: Object3D, params: PickupSuperRocketParams) {
        super(PickupType.SuperRocket, pickupObject, params);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

        const statusEffectParams = new SuperRocketStatusEffectParams();
        const statusEffect = new SuperRocketStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);

        statusEffectsManager.addStatusEffect(statusEffect);
    }
}