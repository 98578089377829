import { OnAuthMessage } from "@heyvr/sdk-gameplay/types";
import { Object3D } from "@wonderlandengine/api";
import { GameLocation, GameMode } from "hoverfit-shared-netcode";
import { AnalyticsUtils, Globals, ObjectUtils, Timer } from "wle-pp";
import { pushUnique } from "wle-pp/cauldron/utils/array/array_utils.js";
import { AvatarComponent } from "../avatar/components/avatar-component.js";
import { common } from "../common.js";
import { HoverboardDebugs } from "../game/components/hoverboard-debugs-component.js";
import { HoverboardComponent } from "../game/hoverboard/components/hoverboard-component.js";
import { LeaderboardType } from "../game/track/leaderboard/leaderboards-manager.js";
import { ItemCategory, ItemNamespace } from "../misc/asset-provision/asset-provider.js";
import { getBuiltInItemIDByIndex, getGenderFilteredBuiltInItemIDByIndex, getRandomBuiltInItemID, getRandomGenderFilteredBuiltInItemID } from "../misc/asset-provision/built-in-asset-provider.js";
import { type OwnedItem } from "../misc/asset-provision/owned-item.js";
import { getRandomName } from "../misc/get-random-name.js";
import { HeyVR } from "../misc/heyvr-sdk-provider.js";
import { getPotentialRewardBoardLevel, getPotentialRewardHelmetLevel, getPotentialRewardSuitLevel } from "../ui/misc/getRewardIDs.js";
import { PopupIconImage } from "../ui/popup/popup.js";
import { UICustomPopupHelperParams } from "../ui/xml-ui/ui-custom-popup-helper.js";
import { notifyOnArrayChange, notifyOnChange, notifyOnStrictArrayChange, onChange } from "../utils/decorators.js";
import { RewardTier } from "../utils/reward-utils.js";
import { getDayStart, getMonthStart, getWeekStart, MS_PER_15_MINUTES, MS_PER_DAY, MS_PER_MINUTE } from "../utils/time-utils.js";
import { wait } from "../utils/wait.js";
import { GameSettings } from "./game-settings.js";
import { type PendingLeaderboardScore, type SavedRewardTierList, type UnsafeDecodedSaveData } from "./save-data.js";
import { SAVE_SLOT_KEY } from "./save-loaders/common/constants.js";
import { type SaveDecoder } from "./save-loaders/common/save-decoder.js";
import { SaveDecoderLegacy } from "./save-loaders/save-decoder-legacy.js";
import { SaveDecoderV1 } from "./save-loaders/save-decoder-v1.js";
import { SaveDecoderV2 } from "./save-loaders/save-decoder-v2.js";
import { SaveDecoderV3 } from "./save-loaders/save-decoder-v3.js";
import { SaveDecoderV4 } from "./save-loaders/save-decoder-v4.js";
import { SaveDecoderV5 } from "./save-loaders/save-decoder-v5.js";
import { validateDouble } from "./validation/validate-double.js";
import { validateInt } from "./validation/validate-int.js";
import { Gender } from "./values/gender.js";

const LEADERBOARD_PERSIST_MS = 36000000; // 10 hours

const SAVEDATA_ASSET_KEYS = [
    ['skinColor', ItemCategory.Skin],
    ['hoverboardVariant', ItemCategory.Hoverboard],
    ['suitVariant', ItemCategory.Suit],
    ['hairColor', ItemCategory.HairColor],
    ['headwearVariantMale', ItemCategory.Headwear],
    ['headwearVariantFemale', ItemCategory.Headwear],
] as const;

export const REWARD_TYPE_LABEL = ["helmet", "suit", "board"] as const;

// FOUNDER_BOARD_PROMO_CODES - slightly obfuscated so that it's slightly harder
// to figure out from reading the build bundle
const FBPC = ["kaleah", "nikitaf", "tigressx", "cryptik", "justinw", "founders"];

const DEFAULT_SAVE_DELAY = 3;

export const enum RewardType {
    Helmet = 0,
    Suit = 1,
    Board = 2,
}

const REWARD_OTHER_BITMASK = 0b00000011;
export const enum RewardOtherFlags {
    /*          */ Founder = 0b00000001,
    /*    */ Halloween2024 = 0b00000010,
}

export type PlayerDataListener = (changedKey?: string | symbol) => void;
type UnsafeDecodedSaveMetadata = [unsafeData: UnsafeDecodedSaveData, version: number, decoder: SaveDecoder];

const SAVE_DECODER_CTORS = [
    SaveDecoderLegacy,
    SaveDecoderV1,
    SaveDecoderV2,
    SaveDecoderV3,
    SaveDecoderV4,
    SaveDecoderV5,
] as const;

const LATEST_SAVE_VERSION = SAVE_DECODER_CTORS.length - 1;

const MAILING_LIST_SLUG = "thefitnessresort_general";

function getSaveVersion(rawData: unknown): number {
    if (rawData == null || typeof rawData !== "object") return 0;
    const version = (rawData as Record<string, unknown>).version;
    if (typeof version !== "number" || version < 0 || Math.trunc(version) !== version) return 0;
    if (version > LATEST_SAVE_VERSION) throw new Error("Future save version");
    return version;
}

export class PlayerData {
    private _initialized: boolean = false;
    private listeners = new Set<PlayerDataListener>();
    private listenerFilters = new Map<PlayerDataListener, (string | symbol)[]>();

    private _saveSlot: number = 2;

    private _player: Object3D | null = null;
    private _avatar: AvatarComponent | null = null;
    private _hoverboard: HoverboardComponent | null = null;
    private _name: string | null = null;
    private _defaultName: string | null = null;

    @onChange(function (this: PlayerData) {
        this._defaultName = getRandomName(this);
        this.notify(this.isUsingDefaultName() ? undefined : 'avatarType');
    })
    avatarType!: Gender;
    @notifyOnChange
    skinColor!: string;
    @notifyOnChange
    hoverboardVariant!: string;
    @notifyOnChange
    suitVariant!: string;
    @notifyOnChange
    hairColor!: string;
    @notifyOnChange
    headwearVariantMale!: string;
    @notifyOnChange
    headwearVariantFemale!: string;
    @notifyOnChange
    totalFitPoints!: number;
    @notifyOnChange
    dailyFitPoints!: number;
    dailyRewardTier!: RewardTier;
    bronzeMedals!: number;
    silverMedals!: number;
    goldMedals!: number;
    platinumMedals!: number;
    currentMidnightTime!: number;
    currentWeekMidnightTime!: number;
    currentMonthMidnightTime!: number;
    @notifyOnChange
    fitabux!: number;
    @notifyOnChange
    heyVRCoins!: number;
    @notifyOnArrayChange
    fitabuxInventory: OwnedItem[] = [];
    @notifyOnStrictArrayChange
    rewards: SavedRewardTierList = [0, 0, 0, 0];
    readonly gameSettings: GameSettings = new GameSettings();
    private pendingLeaderboardScores: PendingLeaderboardScore[] = [];
    @notifyOnArrayChange
    iapExpirables: string[] = [];

    @notifyOnChange
    dailySquats!: number;
    @notifyOnChange
    weeklySquats!: number;
    @notifyOnChange
    monthlySquats!: number;
    @notifyOnChange
    totalSquats!: number;
    @notifyOnChange
    dailyPlayTime!: number;
    @notifyOnChange
    weeklyPlayTime!: number;
    @notifyOnChange
    monthlyPlayTime!: number;
    @notifyOnChange
    totalPlayTime!: number;

    @notifyOnChange
    isGuest = true;

    @notifyOnChange
    subscribed!: boolean;
    subscribeReminderSnoozeElapsedTime!: number;
    subscribeReminderSnoozeDayAmount!: number;

    private _checkDailyResetTimer = new Timer(5);

    private _firstLoadDataDone = false;
    private _lastLoadSucceded = false;
    private _fallbackGuestDataUsed = false;
    private _displayLoadFailedMessage = false;

    private _authChangedQueueCount = 0;

    private _delayedSaveTimer = new Timer(0, false);
    private _saveOnDelayedSaveTimerEnd = false;

    constructor(private readonly _randomize: boolean = false) {
        this._resetPlayerData();
    }

    init() {
        if (this._initialized) return;

        if (HeyVR) {
            HeyVR.user.onAuthChange((payload: OnAuthMessage) => {
                this.onAuthChanged();
            });
        }

        this.onAuthChanged();

        this._initialized = true;
    }

    invalidateSceneData() {
        this._player = null;
        this._avatar = null;
        this._hoverboard = null;
    }

    isFirstLoadDataDone() {
        return this._firstLoadDataDone;
    }

    get player(): Object3D {
        if (!this._player) this._player = Globals.getPlayerObjects()!.myPlayer!;
        return this._player;
    }

    get avatar() {
        if (!this._avatar) this._avatar = ObjectUtils.getComponent(this.player, AvatarComponent);
        return this._avatar;
    }

    get hoverboard() {
        if (!this._hoverboard) this._hoverboard = ObjectUtils.getComponent(this.player, HoverboardComponent);
        return this._hoverboard;
    }

    isUsingDefaultName() {
        return this._name == null;
    }

    get defaultName() {
        if (!this._defaultName) {
            this._defaultName = getRandomName(this);
        }

        return this._defaultName;
    }

    get name(): string {
        return this._name != null ? this._name : this.defaultName;
    }

    // TODO i don't like that name can be null, but the getter always returns a
    //      string. this is going to cause a bug eventually
    set name(name: string | null) {
        if (this._name !== name) {
            this._name = name;
            this.notify("name");
        }
    }

    /**
     * DO NOT CALL THIS OUTSIDE THE PLAYERDATA CLASS. the only reason this is
     * public instead of private is so that we can use decorators
     */
    notify(changedKey?: string | symbol) {
        if (!changedKey) {
            for (const listener of this.listeners) {
                listener(changedKey);
            }
        } else {
            for (const listener of this.listeners) {
                const filter = this.listenerFilters.get(listener);
                if (filter && filter.indexOf(changedKey) < 0) continue;
                listener(changedKey);
            }
        }
    }

    listen(callback: () => void, keyFilter?: string | symbol | (string | symbol)[]) {
        this.listeners.add(callback);

        if (keyFilter) {
            if (Array.isArray(keyFilter)) {
                this.listenerFilters.set(callback, keyFilter);
            } else {
                this.listenerFilters.set(callback, [keyFilter]);
            }
        }
    }

    unlisten(callback: () => void) {
        this.listeners.delete(callback);
        this.listenerFilters.delete(callback);
    }

    update(dt: number) {
        if (this.isFirstLoadDataDone()) {
            this._checkDailyResetTimer.update(dt);
            if (this._checkDailyResetTimer.isDone()) {
                this.checkDailyReset();
                this._checkDailyResetTimer.start();
            }

            this._delayedSaveTimer.update(dt);
            if (this._delayedSaveTimer.isDone()) {
                this._delayedSaveTimer.reset();

                if (this._saveOnDelayedSaveTimerEnd) {
                    this._saveOnDelayedSaveTimerEnd = false;
                    this.savePlayerData();
                }
            }
        }

        if (common.intro.isDone() || common.intro.isFadingIn()) {
            if (this._displayLoadFailedMessage) {
                this._displayLoadFailedMessage = false;
                const popupParams = new UICustomPopupHelperParams();
                popupParams.title = "LOADING ERROR";
                popupParams.message = "Failed to load current player data.\nPlease reload the page.";
                popupParams.primaryButtonText = "RELOAD";
                popupParams.primaryButtonClickCallback = () => {
                    window.location.reload();
                };
                popupParams.lowPriorityButtonText = "CLOSE";
                popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };
                common.kioskLowerUI.showCustomPopup(popupParams);
            }
        }
    }

    openLogin() {
        if (HeyVR == null) return;

        AnalyticsUtils.sendEventOnce("open_login");

        HeyVR.user.openLogin();
    }

    delayedSavePlayerData(seconds = DEFAULT_SAVE_DELAY) {
        if (!this._delayedSaveTimer.isRunning()) {
            this.savePlayerData();
            this._saveOnDelayedSaveTimerEnd = false;
            this._delayedSaveTimer.start(seconds);
        } else {
            this._saveOnDelayedSaveTimerEnd = true;
            if (!this._delayedSaveTimer.isRunning() || this._delayedSaveTimer.getTimeLeft() > seconds) {
                this._delayedSaveTimer.start(seconds);
            }
        }
    }

    subscribe() {
        HeyVR?.marketing.subscribe(MAILING_LIST_SLUG).then((subscribed: boolean) => {
            this.subscribed = subscribed;
        });
    }

    unsubscribe() {
        HeyVR?.marketing.unsubscribe(MAILING_LIST_SLUG).then((unsubscribed: boolean) => {
            this.subscribed = !unsubscribed;
        });
    }

    snoozeSubscribeReminder() {
        const maxSnoozeDayAmount = 5;
        this.subscribeReminderSnoozeDayAmount = Math.min(this.subscribeReminderSnoozeDayAmount + 1, maxSnoozeDayAmount);

        const now = Date.now();
        this.subscribeReminderSnoozeElapsedTime = now + this.subscribeReminderSnoozeDayAmount * MS_PER_DAY;

        this.delayedSavePlayerData();
    }

    subscribeReminderSnoozeElapsed(): boolean {
        const now = Date.now();
        return now > this.subscribeReminderSnoozeElapsedTime;
    }

    private onAuthChanged(manualCall: boolean = false) {
        if (this._authChangedQueueCount == 0 || manualCall) {
            if (!manualCall) {
                this._authChangedQueueCount++;
            }

            this.onAuthChangedInternal().finally(() => {
                this._authChangedQueueCount--;
                if (this._authChangedQueueCount > 0) {
                    this.onAuthChanged(true);
                }
            });
        } else {
            this._authChangedQueueCount++;
        }
    }

    private async onAuthChangedInternal() {
        this.notify("pre_auth_changed");

        return this._loadPlayerNameInternal().finally(() => {
            this._loadPlayerDataInternal().finally(() => {
                this.notify("auth_changed");
            });
        });
    }

    private async loadPlayerName() {
        if (!HeyVR) return false;

        let loadSucceeded = false;

        try {
            console.debug("get user name - start");
            const username = await HeyVR.user.getName();
            console.debug("get user name - done");

            this.isGuest = false;
            this.name = username;

            loadSucceeded = true;

            AnalyticsUtils.sendEventOnce("logged_in");
        } catch (error) {
            console.debug("get user name - error", error);

            this.isGuest = true;
            this.name = null;

            // At most, re enable this when we can check if it's a bad error or just "log in not checked"
            // console.error("Error loading name:", error);
        }

        return loadSucceeded;
    }

    checkDailyReset() {
        let hadReset = false;

        const now = Date.now();
        const newMidnightTime = getDayStart(now);
        if (this.currentMidnightTime !== newMidnightTime) {
            // This will update to the midnight of the current time zone if you changed it in the meantime
            // It's a good thing, since this way until a day from the last midnight has passed, you use the old midnight
            // And then adjust to the new one.
            // This means you will have less time for the current day due to this, but after that it will adjust
            // If you keep changing time zones then screw you
            this.currentMidnightTime = newMidnightTime;

            this.dailySquats = 0;
            this.dailyPlayTime = 0;

            this.dailyFitPoints = 0;
            this.dailyRewardTier = RewardTier.None;

            // same thing, but for weeks
            const newWeekMidnightTime = getWeekStart(now);
            if (this.currentWeekMidnightTime !== newWeekMidnightTime) {
                this.currentWeekMidnightTime = newWeekMidnightTime;

                this.weeklySquats = 0;
                this.weeklyPlayTime = 0;
            }

            // same thing, but for months
            const newMonthMidnightTime = getMonthStart(now);
            if (this.currentMonthMidnightTime !== newMonthMidnightTime) {
                this.currentMonthMidnightTime = newMonthMidnightTime;

                this.monthlySquats = 0;
                this.monthlyPlayTime = 0;
            }

            hadReset = true;
        }

        return hadReset;
    }

    private handlePendingScores(): boolean {
        let pendingScoresUpdated = false;
        if (this.pendingLeaderboardScores.length > 0) {
            const currentTime = Date.now();
            pendingScoresUpdated = this.pendingLeaderboardScores.pp_removeAll((elementToCheck) => { return elementToCheck.elapsingTimeOnNewSession < currentTime; }).length > 0;
        }

        if (this.pendingLeaderboardScores.length > 0 && HeyVR && !this.isGuest) {
            const leaderboardPromises = [];
            for (const pendingScore of this.pendingLeaderboardScores) {
                let lapsAmount: undefined | number = pendingScore.lapsAmount;
                if (lapsAmount === -1) lapsAmount = undefined;

                leaderboardPromises.push(
                    common.leaderboardsManager.getLeaderboard({
                        type: LeaderboardType.Global,
                        mode: GameMode.Race,
                        location: pendingScore.location,
                        track: pendingScore.track,
                        lapsAmount,
                    }).submitScore(pendingScore.score)
                );
            }

            Promise.allSettled(leaderboardPromises).finally(() => common.kioskUpperUI.refreshLeaderboard());

            this.pendingLeaderboardScores = [];
            pendingScoresUpdated = true;
        }

        return pendingScoresUpdated;
    }

    async savePlayerData() {
        if (PRE_OWN_ALL_ITEMS) {
            console.warn("Game saving disabled; all items are pre-owned");
            return true;
        }

        this._saveOnDelayedSaveTimerEnd = false;
        if (!this._delayedSaveTimer.isRunning() || this._delayedSaveTimer.getTimeLeft() < DEFAULT_SAVE_DELAY) {
            this._delayedSaveTimer.start(DEFAULT_SAVE_DELAY);
        }

        if (!this._lastLoadSucceded) return false;

        this.checkDailyReset();

        const fitabuxSIDs: string[] = [];
        const fitabuxExpiry: number[] = [];

        const fitabuxInv = this.fitabuxInventory;
        const iMax = fitabuxInv.length;
        for (let i = 0; i < iMax; i++) {
            const ownedItem = fitabuxInv[i];
            fitabuxSIDs.push(ownedItem[0]);

            const expiresOn = ownedItem[1].expiresOn;
            if (expiresOn) {
                fitabuxExpiry.push(i, Math.ceil(expiresOn / MS_PER_MINUTE));
            }
        }

        const saveData = {
            version: LATEST_SAVE_VERSION,
            avatarType: this.avatarType,
            skinColor: this.skinColor,
            hoverboardVariant: this.hoverboardVariant,
            suitVariant: this.suitVariant,
            hairColor: this.hairColor,
            headwearVariantMale: this.headwearVariantMale,
            headwearVariantFemale: this.headwearVariantFemale,
            totalFitPoints: this.totalFitPoints,
            bronzeMedals: this.bronzeMedals,
            silverMedals: this.silverMedals,
            goldMedals: this.goldMedals,
            platinumMedals: this.platinumMedals,
            dailyFitPoints: Math.floor(this.dailyFitPoints),
            dailyRewardTier: this.dailyRewardTier,
            // XXX midnights are always multiples of 15 minutes UTC (this is
            //     because some time zones have an offsets of 30 or 45 minutes
            //     instead of whole hours)
            currentMidnightTime: Math.trunc(this.currentMidnightTime / MS_PER_15_MINUTES),
            currentWeekMidnightTime: Math.trunc(this.currentWeekMidnightTime / MS_PER_15_MINUTES),
            currentMonthMidnightTime: Math.trunc(this.currentMonthMidnightTime / MS_PER_15_MINUTES),
            fitabux: this.fitabux,
            fitabuxSIDs,
            fitabuxExpiry,
            rewards: this.rewards,
            gameSettingsData: {
                policyAccepted: this.gameSettings.policyAccepted.value,
                musicVolume: this.gameSettings.musicVolume.value,
                quietMode: this.gameSettings.quietMode.value,
                voiceVolume: this.gameSettings.voiceVolume.value,
                microphoneEnabled: this.gameSettings.microphoneEnabled.value,
                voiceP2P: this.gameSettings.voiceP2P.value,
                voiceMediasoup: this.gameSettings.voiceMediasoup.value,
                gameConfigOnLoad: this.gameSettings.gameConfigOnLoad.value,
                gameOnlineConfigOnLoad: this.gameSettings.gameOnlineConfigOnLoad.value,
                showTutorialOnStart: this.gameSettings.showTutorialOnStart.value,
            },
            pendingLeaderboardScores: this.pendingLeaderboardScores,
            iapExpirables: this.iapExpirables,
            dailySquats: this.dailySquats,
            weeklySquats: this.weeklySquats,
            monthlySquats: this.monthlySquats,
            totalSquats: this.totalSquats,
            dailyPlayTime: this.dailyPlayTime,
            weeklyPlayTime: this.weeklyPlayTime,
            monthlyPlayTime: this.monthlyPlayTime,
            totalPlayTime: this.totalPlayTime,
            subscribeReminderSnoozeElapsedTime: this.subscribeReminderSnoozeElapsedTime,
            subscribeReminderSnoozeDayAmount: this.subscribeReminderSnoozeDayAmount,
        };

        let saveSucceeded = false;
        if (HeyVR && !this.isGuest) {
            try {
                saveSucceeded = await HeyVR.saveGame.write(saveData, true, this._saveSlot, "Player Save " + this._saveSlot);
            } catch (error) {
                console.error("Error saving data:", error);
            }

            if (saveSucceeded && this._fallbackGuestDataUsed) {
                this._fallbackGuestDataUsed = false;
                common.preferences.unsetPref(SAVE_SLOT_KEY + this._saveSlot);
            }
        } else {
            try {
                const saveDataJSON = JSON.stringify(saveData);
                common.preferences.setPref(SAVE_SLOT_KEY + this._saveSlot, saveDataJSON);

                saveSucceeded = true;
            } catch (error) {
                console.error("Error saving data:", error);
            }
        }

        return saveSucceeded;
    }

    private decodeSaveData(rawData: any): UnsafeDecodedSaveMetadata | null {
        const version = getSaveVersion(rawData);
        const decoder = new SAVE_DECODER_CTORS[version];
        const unsafeData = decoder.decodeSaveData(rawData);
        return unsafeData ? [unsafeData, version, decoder] : null;
    }

    async loadPlayerData() {
        // XXX at a glance, it might seem that there is a data race here. what
        //     if:
        //     - we wait for the IAPCC to be ready
        //     - it's ready
        //     - we load the heyvr save
        //     - scene switch happens
        //     - IAPCC is disposed
        //     - heyvr save finishes loading
        //     - we get assets from the disposed IAPCC
        //     this might seem like a data race, and it is, but it has no
        //     negative side-effects because the disposal of the IAPCC doesn't
        //     clear the asset list, so this method will still work
        const iapContentController = common.iapContentController;
        await iapContentController.waitForReady();

        let currentLoadSucceded = false;
        this._lastLoadSucceded = false;
        this._fallbackGuestDataUsed = false;

        let saveData: UnsafeDecodedSaveMetadata | null = null;
        let needsResave = false;
        let fallbackToGuestData = false;

        const isLoggedInUser = HeyVR && !this.isGuest;
        if (isLoggedInUser) {
            try {
                // TODO migrate to a single save slot in a future MR, and
                //      implement a backwards compatible loader with the
                //      save-game version INSIDE THE SAVE DATA. check the save
                //      version to decide which loader to use, and if there's no
                //      version, then use a legacy loader
                const loadedData = await HeyVR!.saveGame.load(this._saveSlot);

                if (loadedData && loadedData.save_data) {
                    saveData = this.decodeSaveData(loadedData.save_data);
                } else {
                    fallbackToGuestData = true;
                }

                currentLoadSucceded = true;
            } catch (error) {
                if (error != null && (error as any).status != null && (error as any).status.debug == "err_invalid_save") {
                    fallbackToGuestData = true;
                } else {
                    console.error("Error loading heyvr data:", error);
                }
            }
        }

        if (!isLoggedInUser || fallbackToGuestData) {
            try {
                const saveDataJSON = common.preferences.getPref(SAVE_SLOT_KEY + this._saveSlot);
                if (saveDataJSON) saveData = this.decodeSaveData(JSON.parse(saveDataJSON));
                this._fallbackGuestDataUsed = fallbackToGuestData;

                currentLoadSucceded = true;
            } catch (error) {
                console.error("Error loading local data:", error);
            }
        }

        if (currentLoadSucceded) {
            this._resetPlayerData();

            if (saveData != null) {
                const unsafeData = saveData[0] as UnsafeDecodedSaveData;

                // validate basic values
                const avatarType = unsafeData.avatarType;
                if (avatarType === Gender.Female || avatarType === Gender.Male) {
                    this.avatarType = avatarType;
                } else {
                    this.avatarType = Gender.Female;
                }

                const now = Date.now();

                this.totalFitPoints = validateInt(unsafeData.totalFitPoints);
                this.dailyFitPoints = validateInt(unsafeData.dailyFitPoints);
                this.dailyRewardTier = validateInt(unsafeData.dailyRewardTier, RewardTier.None, RewardTier.None, RewardTier.MAX);
                this.bronzeMedals = validateInt(unsafeData.bronzeMedals);
                this.silverMedals = validateInt(unsafeData.silverMedals);
                this.goldMedals = validateInt(unsafeData.goldMedals);
                this.platinumMedals = validateInt(unsafeData.platinumMedals);
                this.currentMidnightTime = validateInt(unsafeData.currentMidnightTime, getDayStart(now));
                this.currentWeekMidnightTime = validateInt(unsafeData.currentWeekMidnightTime, getWeekStart(now));
                this.currentMonthMidnightTime = validateInt(unsafeData.currentMonthMidnightTime, getMonthStart(now));
                this.fitabux = validateInt(unsafeData.fitabux);

                this.dailySquats = validateInt(unsafeData.dailySquats);
                this.weeklySquats = validateInt(unsafeData.weeklySquats);
                this.monthlySquats = validateInt(unsafeData.monthlySquats);
                this.totalSquats = validateInt(unsafeData.totalSquats);
                this.dailyPlayTime = validateInt(unsafeData.dailyPlayTime);
                this.weeklyPlayTime = validateInt(unsafeData.weeklyPlayTime);
                this.monthlyPlayTime = validateInt(unsafeData.monthlyPlayTime);
                this.totalPlayTime = validateInt(unsafeData.totalPlayTime);

                this.subscribeReminderSnoozeElapsedTime = validateInt(unsafeData.subscribeReminderSnoozeElapsedTime);
                this.subscribeReminderSnoozeDayAmount = validateInt(unsafeData.subscribeReminderSnoozeDayAmount);

                const rewards = unsafeData.rewards;
                if (Array.isArray(rewards)) {
                    this.rewards = [
                        validateInt(rewards[0]),
                        validateInt(rewards[1]),
                        validateInt(rewards[2]),
                        validateInt(rewards[3], 0, -Infinity) && REWARD_OTHER_BITMASK,
                    ];
                }

                // validate fitabux inventory
                const fitabuxInventory = unsafeData.fitabuxInventory;
                if (Array.isArray(fitabuxInventory)) {
                    const validatedInventory: OwnedItem[] = [];
                    for (const ownedItem of fitabuxInventory) {
                        if (!Array.isArray(ownedItem) || ownedItem.length < 2) continue;

                        const shortID = ownedItem[0];
                        if (typeof shortID !== 'string') continue;

                        const meta = ownedItem[1];
                        if (meta === null || typeof meta !== 'object') continue;

                        const expiresOn = validateInt(meta.expiresOn);

                        if (iapContentController.getAsset(`${ItemNamespace.Fitabux}:${shortID}`)) {
                            validatedInventory.push([shortID, { expiresOn }]);
                        }
                    }

                    this.fitabuxInventory = validatedInventory;
                }

                // validate equipment
                for (const [key, assetCat] of SAVEDATA_ASSET_KEYS) {
                    const value = unsafeData[key];
                    let actualCat: ItemCategory | undefined;
                    if (typeof value !== 'string') {
                        // XXX fallback for old saves where there are
                        //     numeric IDs
                        actualCat = undefined;
                    } else {
                        actualCat = iapContentController.getAssetClass(value);
                    }

                    if (actualCat !== assetCat) {
                        // invalid asset, reset to default
                        switch (assetCat) {
                            case ItemCategory.Skin:
                                this.resetSkinColor();
                                break;
                            case ItemCategory.Hoverboard:
                                this.resetHoverboard();
                                break;
                            case ItemCategory.Suit:
                                this.resetSuit();
                                break;
                            case ItemCategory.HairColor:
                                this.resetHairColor();
                                break;
                            case ItemCategory.Headwear:
                                this.resetHeadwear();
                                break;
                        }
                        continue;
                    }

                    this[key] = value as string;
                }

                // validate game settings
                this.gameSettings.loadDecodedData(unsafeData.gameSettingsData);

                // validate pending leaderboard scores
                const savedScores = unsafeData.pendingLeaderboardScores;
                if (Array.isArray(savedScores)) {
                    for (const savedScore of savedScores) {
                        if (savedScore === null || typeof savedScore !== "object") continue;
                        const location = savedScore.location;
                        if (location === "" || typeof location !== "string") continue;
                        const track = savedScore.track;
                        if (track === "" || typeof location !== "string") continue;
                        const lapsAmount = validateInt(savedScore.lapsAmount, null, -1);
                        if (lapsAmount == null || (lapsAmount < 1 && lapsAmount !== -1)) continue;

                        const score = validateDouble(savedScore.score, null);
                        if (score === null) continue;

                        const elapsingTimeOnNewSession = validateInt(savedScore.elapsingTimeOnNewSession, null);
                        if (elapsingTimeOnNewSession === null) continue;

                        this.addPendingLeaderboardScore(score, location as GameLocation, track, lapsAmount, elapsingTimeOnNewSession);
                    }
                }

                // validate expirable IAP item list
                const iapExpirablesRaw = unsafeData.iapExpirables;
                // NOTE expirables need to be merged instead of replaced due to
                //      a potential data race where the inventory is loaded
                //      before the inventory and new expirables are missed
                const iapExpirables = [...this.iapExpirables];
                if (Array.isArray(iapExpirablesRaw)) {
                    for (const shortID of iapExpirablesRaw) {
                        if (typeof shortID !== "string" || !iapContentController.getAssetClass(`${ItemNamespace.IAP}:${shortID}`)) continue;
                        pushUnique(iapExpirables, shortID);
                    }
                }

                this.iapExpirables = iapExpirables;
            }

            this._lastLoadSucceded = true;

            this._saveOnDelayedSaveTimerEnd = false;
            if (!this._delayedSaveTimer.isRunning() || this._delayedSaveTimer.getTimeLeft() < DEFAULT_SAVE_DELAY) {
                this._delayedSaveTimer.start(DEFAULT_SAVE_DELAY);
            }

            // WARNING don't be tempted to put these in the same if-statement.
            //         javascript does short-circuit boolean operators
            if (this.checkDailyReset()) needsResave = true;
            if (this.handlePendingScores()) needsResave = true;
            if (this._fallbackGuestDataUsed) needsResave = true;

            if (saveData) {
                const saveVersion = saveData[1] as number;
                const needsUpgrade = saveVersion < LATEST_SAVE_VERSION;
                if (needsResave || needsUpgrade) {
                    try {
                        // Since these cases change some data on load, save the new data
                        await this.savePlayerData();

                        if (needsUpgrade) {
                            console.warn(`Upgrading game save from version ${saveVersion} to ${LATEST_SAVE_VERSION}`);
                            await (saveData[2] as SaveDecoder).postUpgradeCleanup();
                        }
                    } catch (error) {
                        // Do nothing
                    }
                }
            }
        }

        if (PRE_OWN_ALL_ITEMS) {
            const rewards = this.rewards;
            this.rewards = [
                Math.max(1, rewards[0]),
                Math.max(1, rewards[1]),
                Math.max(1, rewards[2]),
                REWARD_OTHER_BITMASK,
            ];
        }

        return this._lastLoadSucceded;
    }

    addPendingLeaderboardScore(score: number, location: GameLocation, track: string, lapsAmount: number = -1, elapsingTimeOnNewSession?: number) {
        if (elapsingTimeOnNewSession === undefined) {
            elapsingTimeOnNewSession = Date.now() + LEADERBOARD_PERSIST_MS;
        }

        for (let i = this.pendingLeaderboardScores.length - 1; i >= 0; i--) {
            const savedScore = this.pendingLeaderboardScores[i];
            if (savedScore.location !== location || savedScore.track !== track || savedScore.lapsAmount != lapsAmount) continue;
            if (savedScore.score < score && savedScore.elapsingTimeOnNewSession > elapsingTimeOnNewSession) return;
            this.pendingLeaderboardScores.splice(i, 1);
            break;
        }

        this.pendingLeaderboardScores.push({ location, track, lapsAmount, score, elapsingTimeOnNewSession });
        this.delayedSavePlayerData();
    }

    updateDailyRewardTier(newDailyRewardTier: RewardTier) {
        this._updateRewardTierAmount(this.dailyRewardTier, -1);
        this._updateRewardTierAmount(newDailyRewardTier, 1);
        this.dailyRewardTier = newDailyRewardTier;
    }

    private _updateRewardTierAmount(rewardTier: RewardTier, amount: number) {
        switch (rewardTier) {
            case RewardTier.Bronze:
                this.bronzeMedals = Math.max(0, this.bronzeMedals + amount);
                break;
            case RewardTier.Silver:
                this.silverMedals = Math.max(0, this.silverMedals + amount);
                break;
            case RewardTier.Gold:
                this.goldMedals = Math.max(0, this.goldMedals + amount);
                break;
            case RewardTier.Platinum:
                this.platinumMedals = Math.max(0, this.platinumMedals + amount);
                break;
        }
    }

    getDefaultSkinColor() {
        return this._randomize ? getRandomBuiltInItemID(ItemCategory.Skin) : getBuiltInItemIDByIndex(ItemCategory.Skin, 0);
    }

    getDefaultHoverboard() {
        return this._randomize ? getRandomBuiltInItemID(ItemCategory.Hoverboard) : getBuiltInItemIDByIndex(ItemCategory.Hoverboard, 0);
    }

    getDefaultSuit() {
        return this._randomize ? getRandomBuiltInItemID(ItemCategory.Suit) : getBuiltInItemIDByIndex(ItemCategory.Suit, 0);
    }

    getDefaultHeadwearMale() {
        return this._randomize ? getRandomGenderFilteredBuiltInItemID(ItemCategory.Headwear, Gender.Male) : getGenderFilteredBuiltInItemIDByIndex(ItemCategory.Headwear, 0, Gender.Male);
    }

    getDefaultHeadwearFemale() {
        return this._randomize ? getRandomGenderFilteredBuiltInItemID(ItemCategory.Headwear, Gender.Female) : getGenderFilteredBuiltInItemIDByIndex(ItemCategory.Headwear, 0, Gender.Female);
    }

    getDefaultHairColor() {
        return this._randomize ? getRandomBuiltInItemID(ItemCategory.HairColor) : getBuiltInItemIDByIndex(ItemCategory.HairColor, 0);
    }

    resetSkinColor() {
        this.skinColor = this.getDefaultSkinColor();
    }

    resetHoverboard() {
        this.hoverboardVariant = this.getDefaultHoverboard();
    }

    resetSuit() {
        this.suitVariant = this.getDefaultSuit();
    }

    resetHeadwear() {
        // TODO separate this into 2 methods
        this.headwearVariantMale = this.getDefaultHeadwearMale();
        this.headwearVariantFemale = this.getDefaultHeadwearFemale();
    }

    resetHairColor() {
        this.hairColor = this.getDefaultHairColor();
    }

    private _resetPlayerData() {
        const now = Date.now();
        this.avatarType = this._randomize ? Math.trunc(Math.random() * 2) : 0;
        this.resetSkinColor();
        this.resetHoverboard();
        this.resetSuit();
        this.resetHeadwear();
        this.resetHairColor();
        this.totalFitPoints = 0;
        this.dailyFitPoints = 0;
        this.dailyRewardTier = RewardTier.None;
        this.bronzeMedals = 0;
        this.silverMedals = 0;
        this.goldMedals = 0;
        this.platinumMedals = 0;
        this.currentMidnightTime = getDayStart(now);
        this.currentWeekMidnightTime = getWeekStart(now);
        this.currentMonthMidnightTime = getMonthStart(now);
        this.fitabux = 0;
        this.heyVRCoins = 0;
        this.fitabuxInventory = [];
        this.rewards = PRE_OWN_ALL_ITEMS ? [1, 1, 1, REWARD_OTHER_BITMASK] : [0, 0, 0, 0];
        this.gameSettings.reset();
        this.pendingLeaderboardScores = [];
        this.iapExpirables = [];
        this.dailySquats = 0;
        this.weeklySquats = 0;
        this.monthlySquats = 0;
        this.totalSquats = 0;
        this.dailyPlayTime = 0;
        this.weeklyPlayTime = 0;
        this.monthlyPlayTime = 0;
        this.totalPlayTime = 0;
        this.subscribeReminderSnoozeElapsedTime = 0;
        this.subscribeReminderSnoozeDayAmount = 0;
    }

    private _loadPlayerNameInternal() {
        return this.loadPlayerName().then((loadSucceeded) => {
            if ((HeyVR == null && !loadSucceeded) || (DEV_MODE && HoverboardDebugs.heyvrSandboxRandomize)) {
                if (DEV_MODE && HeyVR != null) {
                    this.name = null;
                }

                const searchParams = new URLSearchParams(window.location.search);
                const desiredName = searchParams.get("name");
                if (desiredName != null && desiredName.length > 0) {
                    this.name = desiredName;
                }
            }
        });
    }

    private _loadPlayerDataInternal() {
        this._displayLoadFailedMessage = false;

        return this.loadPlayerData().then((loadSucceeded) => {
            if (loadSucceeded) {
                if (DEV_MODE && RANDOMIZE_EQUIPPED_ITEMS && HeyVR != null && HoverboardDebugs.heyvrSandboxRandomize) {
                    this.avatarType = this._randomize ? Math.trunc(Math.random() * 2) : 0;
                    this.resetSkinColor();
                    this.resetHoverboard();
                    this.resetSuit();
                    this.resetHeadwear();
                    this.resetHairColor();
                }
            } else {
                this._resetPlayerData();
                this._displayLoadFailedMessage = true;
            }
        }).catch((err) => {
            console.error(err);

            this._lastLoadSucceded = false;

            this._resetPlayerData();

            this._displayLoadFailedMessage = true;
        }).finally(() => {
            this._firstLoadDataDone = true;
            this.awardPromotionalEquipment();

            this.subscribed = false;
            if (HeyVR && !this.isGuest) {
                HeyVR?.marketing.isSubscribed(MAILING_LIST_SLUG).then((subscribed: boolean) => {
                    this.subscribed = subscribed;
                });
            }
        });
    }

    upgradeReward(rewardType: RewardType) {
        let rewardTypeName = "";

        let level: number;
        switch (rewardType) {
            case RewardType.Helmet:
                rewardTypeName = "helmet";
                level = getPotentialRewardHelmetLevel();
                break;
            case RewardType.Suit:
                rewardTypeName = "suit";
                level = getPotentialRewardSuitLevel();
                break;
            case RewardType.Board:
                rewardTypeName = "board";
                level = getPotentialRewardBoardLevel();
                break;
            default:
                return;
        }

        if (this.rewards[rewardType] === level) return;

        AnalyticsUtils.sendEvent("unlock_reward", { rewardTypeName });

        this.rewards[rewardType] = level;
        this.notify("rewards");
        this.savePlayerData();
    }

    private awardRewardWithFlag(flag: number, message: string, analyticsEvent?: string, code?: string): void {
        const oldFlags = this.rewards[3];
        const newFlags = oldFlags | flag;
        if (oldFlags === newFlags) return;
        this.rewards[3] = newFlags;
        this.notify("rewards");
        common.popupManager.showMessagePopup(message, PopupIconImage.Info);

        if (analyticsEvent) AnalyticsUtils.sendEvent(analyticsEvent);
        if (code) AnalyticsUtils.sendEvent("url_code");

        this.savePlayerData();
    }

    private async awardPromotionalEquipment() {
        // HACK this horrible code prevents a data race where the promotional
        //      equipment is awarded during a scene switch
        while (!common.popupManager) { await wait(0); }

        // promo codes
        const searchParams = (new URL(window.location.href)).searchParams;
        const code = searchParams.get("code");

        if (code) {
            if (FBPC.indexOf(code) >= 0) {
                this.awardRewardWithFlag(RewardOtherFlags.Founder, "You have been awarded\nthe Founder Hoverboard!", "award_founder_board", code);
            }
        }

        // seasonal
        const time = Date.now();

        if (time >= 1730073600000 /* 2024-10-28-00:00 UTC */ && time < 1731283200000 /* 2024-11-11-00:00 UTC */) {
            this.awardRewardWithFlag(RewardOtherFlags.Halloween2024, "You have been awarded\nthe Halloween 2024 set!", "award_halloween_2024", code === "halloween" ? code : undefined);
        }

        // TODO use external file?
    }
}