import { Room } from "colyseus.js";
import { Observable, ObservableTransformer, Variable, type ObservableCallback } from "lazy-widgets";
import { AnalyticsUtils } from "wle-pp";
import { common } from "../common.js";
import { HoverboardGameOnlineConfigJSON } from "../data/game-configuration.js";
import { cancelSceneLoad } from "../misc/load-scene/load-scene.js";
import { PopupIconImage } from "../ui/popup/popup.js";

export enum RoomState {
    Disconnected = 0,
    Connected = 1,
    Connecting = 2
}

export class RoomProxy implements Observable<RoomState> {
    public state = RoomState.Disconnected;
    public changingGameConfig: boolean = false;

    private callbacks = new Array<ObservableCallback<RoomState>>();
    private _currentRoom: Variable<Room | null>;
    readonly currentRoom: Observable<Room | null>;
    readonly currentRoomID: Observable<string | null>;
    readonly currentRoomPrivateness: Variable<boolean | null>;
    readonly currentRoomText: Observable<string>;

    constructor() {
        this._currentRoom = new Variable(null);
        this.currentRoom = new ObservableTransformer([this._currentRoom, this], () => {
            if (this.state === RoomState.Connected) {
                return this._currentRoom.value;
            } else {
                return null;
            }
        });
        this.currentRoomID = new ObservableTransformer([this.currentRoom, this], () => {
            return this.currentRoom.value?.id ?? null;
        });

        this.currentRoomPrivateness = new Variable(null);

        this.currentRoomText = new ObservableTransformer([this.currentRoomID, this.currentRoomPrivateness], () => {
            const roomNum = this.currentRoomID.value;
            const roomPrivateness = this.currentRoomPrivateness.value;
            if (roomNum === null) {
                return "NO ROOM JOINED";
            } else {
                return `CURRENT ROOM: ${roomNum}${roomPrivateness == null ? "" : (roomPrivateness ? " - PRIVATE" : " - PUBLIC")}`;
            }
        });

        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.on("room-no-autojoin", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-create", this.setState.bind(this, RoomState.Connecting));
        MAIN_CHANNEL.on("room-join", this.setState.bind(this, RoomState.Connecting));
        MAIN_CHANNEL.on("room-init-start", this.onRoomPicked.bind(this));
        MAIN_CHANNEL.on("room-init-done", this.setState.bind(this, RoomState.Connected));
        MAIN_CHANNEL.on("room-create-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-join-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-init-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-leave", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("room-error", this.setState.bind(this, RoomState.Disconnected));
        MAIN_CHANNEL.on("load-scene-start", () => { this.changingGameConfig = true; });
        MAIN_CHANNEL.on("load-scene-end", () => { this.changingGameConfig = false; });
    }

    destroy() {
        this.callbacks.length = 0;
    }

    private onRoomPicked(room: Room) {
        this._currentRoom.value = room;
        this.setState(RoomState.Connecting);
    }

    private setState(state: RoomState, onlyIfNotConfiguring = true) {
        if (this.state === state) return;
        if (onlyIfNotConfiguring && this.changingGameConfig) return;

        this.state = state;
        for (const callback of this.callbacks) {
            this.doCallback(callback);
        }
    }

    get value() {
        return this.state;
    }

    watch(callback: ObservableCallback<RoomState>, callNow = false, group?: unknown): this {
        this.callbacks.push(callback);

        if (callNow) {
            this.doCallback(callback, group);
        }

        return this;
    }

    unwatch(callback: ObservableCallback<RoomState>): this {
        const i = this.callbacks.indexOf(callback);

        if (i === -1) {
            console.warn("unwatch called, but watcher was not registered");
        } else {
            this.callbacks.splice(i, 1);
        }

        return this;
    }

    private doCallback(callback: ObservableCallback<RoomState>, group?: unknown): void {
        try {
            callback(this, group);
        } catch (e) {
            console.error("Exception in watcher:", e);
        }
    }

    disconnect() {
        if (this.state !== RoomState.Connected) throw new Error("Not connected");

        this._disconnect();
    }

    private _disconnect() {
        this.updateLastSessionGameOnlineConfig(false, null, false);

        common.MAIN_CHANNEL.emit("try-disconnect");
    }

    quickPlay() {
        if (this.state !== RoomState.Disconnected) throw new Error("Busy or already connected");
        this.updateLastSessionGameOnlineConfig(true, null, false);

        common.hoverboardNetworking.joinOrCreate(undefined, false);

        AnalyticsUtils.sendEvent("play_online", { publicRoom: true });
    }

    cancelConfigurationChange(checkNetworkRoom: boolean = true,) {
        if (!this.changingGameConfig) return;

        if (common.hoverboardNetworking.room && checkNetworkRoom) {
            common.hoverboardNetworking.cancelConfigurationChange();
        } else {
            if (cancelSceneLoad()) {
                common.MAIN_CHANNEL.emit("load-scene-end");

                common.kioskLowerUI.resetConfigurationValues();

                common.popupManager.showQuickMessagePopup("Map change cancelled", PopupIconImage.Warn, "moving_to");
                common.kioskLowerUI.showCustomInfoPopup("CANCELED", "Changing location has been canceled.");
            }
        }
    }

    hostRoom(roomNumber: number | null, privateRoom: boolean) {
        this.updateLastSessionGameOnlineConfig(true, roomNumber, privateRoom);

        common.hoverboardNetworking.create(roomNumber, privateRoom);

        AnalyticsUtils.sendEvent("play_online", { publicRoom: !privateRoom });
    }

    joinRoom(roomNumber: number | null) {
        this.updateLastSessionGameOnlineConfig(true, roomNumber, true);

        common.hoverboardNetworking.join(roomNumber);

        AnalyticsUtils.sendEvent("play_online");
    }

    hostOrJoinRoom(roomNumber: number | null, privateRoom: boolean) {
        this.updateLastSessionGameOnlineConfig(true, roomNumber, privateRoom);

        common.hoverboardNetworking.joinOrCreate(roomNumber, privateRoom);

        AnalyticsUtils.sendEvent("play_online", { publicRoom: !privateRoom });
    }

    updateLastSessionGameOnlineConfig(isOnline: boolean, roomID: number | null, isPrivateRoom: boolean) {
        const newSessionGameOnlineConfig = new HoverboardGameOnlineConfigJSON();
        newSessionGameOnlineConfig.isOnline = isOnline;
        newSessionGameOnlineConfig.roomID = roomID;
        newSessionGameOnlineConfig.isPrivateRoom = isPrivateRoom;

        common.playerData.gameSettings.gameOnlineConfigOnLoad.value = newSessionGameOnlineConfig;
    }
}