import { Icon, IconProperties, SingleParentXMLInputConfig, Widget, WidgetAutoXML } from "lazy-widgets";
import { BaseDecoratedButton, type DecoratedButtonProperties } from "./base-decorated-button.js";

export interface IconDecoratedButtonProperties extends IconProperties, DecoratedButtonProperties {
    iconFillStyle?: string;
    iconFillStyleDisabled?: string;
}

export class IconDecoratedButton<W extends Widget = Widget> extends BaseDecoratedButton<W> {
    static override autoXML: WidgetAutoXML = {
        name: "icon-decorated-button",
        inputConfig: SingleParentXMLInputConfig
    };

    private iconFillStyle: string;
    private iconFillStyleDisabled: string;

    constructor(icon: W, properties?: IconDecoratedButtonProperties) {
        super(
            icon,
            {
                hasBorder: properties?.hasBorder ?? false,
                ...properties,
            }
        );

        if (!(icon instanceof Icon)) {
            throw new Error("IconDecoratedButton only accepts instances of Icon");
        }

        this.iconFillStyle = properties?.iconFillStyle ?? "black";
        this.iconFillStyleDisabled = properties?.iconFillStyleDisabled ?? "rgb(128,128,128)";

        this.updateIconColor();
    }

    private updateIconColor() {
        this.child.bodyTextFill = this.clickable ? this.iconFillStyle : this.iconFillStyleDisabled;
    }

    override set clickable(clickable) {
        super.clickable = clickable;
        this.updateIconColor();
    }

    override get clickable() {
        return super.clickable;
    }
}