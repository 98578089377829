import { property } from "@wonderlandengine/api/decorators.js";
import { XRUtils } from "wle-pp";
import { common } from "../../../common.js";
import { FollowPositionComponent } from "./follow-position-component.js";

export class SyncedFollowPositionComponent extends FollowPositionComponent {
    static override TypeName = "synced-follow-position";

    static override Properties = {
        ...FollowPositionComponent.Properties
    };

    @property.bool(false)
    syncWhenUIHidden!: boolean;

    @property.bool(true)
    syncOnSessionChanges!: boolean;

    @property.bool(true)
    syncOnMoveFromBalcony!: boolean;

    private _prevIsPlayerOnBalcony: boolean = true;
    private _keepSyncingCounter: number = 0;

    private static readonly _keepSyncingFramesCount: number = 5;

    override start(): void {
        if (this.syncOnSessionChanges) {
            XRUtils.registerSessionStartEndEventListeners(this, this._onXRSessionStart.bind(this), this._onXRSessionEnd.bind(this));
        }
    }

    override update(dt: number): void {
        super.update(dt);

        if (this.syncOnMoveFromBalcony) {
            if (this._prevIsPlayerOnBalcony && !common.balcony.isPlayerOnBalcony.value) {
                this._prevIsPlayerOnBalcony = false;
                this._syncWithCounter();
            }

            if (!this._prevIsPlayerOnBalcony && common.balcony.isPlayerOnBalcony.value) {
                this._prevIsPlayerOnBalcony = true;
                this._syncWithCounter();
            }
        }

        if (this.syncWhenUIHidden) {
            if (!common.pauseMenu.isPaused() && !common.popupManager?.isShowingPopup()) {
                this.sync();
            }
        }

        if (this._keepSyncingCounter > 0) {
            this._keepSyncingCounter--;
            this.sync();
        }
    }

    _onXRSessionStart(session: XRSession) {
        this._syncWithCounter();

        const referenceSpace = XRUtils.getReferenceSpace()!;
        referenceSpace.addEventListener("reset", () => this._syncWithCounter());
    }

    _onXRSessionEnd() {
        this._syncWithCounter();
    }

    private _syncWithCounter() {
        this.sync();

        // This is needed since the changes like the session one might take a few frames before stabilizing
        this._keepSyncingCounter = SyncedFollowPositionComponent._keepSyncingFramesCount;
    }

    override onDestroy(): void {
        XRUtils.unregisterSessionStartEndEventListeners(this);
    }
}