import { Material, type MeshComponent, type Object3D, type WonderlandEngine } from "@wonderlandengine/api";
import { ObjectCloneParams, quat_create } from "wle-pp";
import { common } from "../common.js";
import { CancellablePromise, type CancelledMarker } from "../utils/cancellable-promise.js";
import { type AssetDecoration, type AssetMaterial, type MaterialInterface } from "./asset-provision/asset-manifest-types.js";

// XXX don't change this prefix string. it's also used (or going to be used as
//     of writing this message) in the .blend files. by changing this prefix,
//     you're breaking a safety feature in the .blend file export script
const DECORATIONS_KEY_PREFIX = "__decor_attachment_";
const TEMP_QUAT = quat_create();
const CLONE_OPTS = new ObjectCloneParams();
CLONE_OPTS.myComponentsToInclude = ["mesh"];
CLONE_OPTS.myDefaultComponentCloneAutoStartIfNotActive = false;

const PHONG_BASE_PROPERTIES = {
    ambientColor: [0.5, 0.5, 0.5, 1.0],
    diffuseColor: [0.75, 0.75, 0.75, 1.0],
} as const;

const PHONG_SPECULAR_BASE_PROPERTIES = {
    ...PHONG_BASE_PROPERTIES,
    specularColor: null,
    shininess: null,
} as const;

const FLAT_BASE_PROPERTIES = {
    color: null,
} as const;

const PIPELINES = {
    "Flat Opaque": {
        textures: [],
        properties: FLAT_BASE_PROPERTIES,
    },
    "Flat Opaque Textured": {
        textures: ["flatTexture"],
        properties: FLAT_BASE_PROPERTIES,
    },
    "Flat Transparent": {
        textures: [],
        properties: FLAT_BASE_PROPERTIES,
    },
    "Flat Transparent Textured": {
        textures: ["flatTexture"],
        properties: FLAT_BASE_PROPERTIES,
    },
    "Phong Normalmapped": {
        textures: ["diffuseTexture", "normalTexture"],
        properties: PHONG_SPECULAR_BASE_PROPERTIES,
    },
    "Phong Opaque": {
        textures: [],
        properties: PHONG_SPECULAR_BASE_PROPERTIES,
    },
    "Phong Opaque Textured": {
        textures: ["diffuseTexture"],
        properties: PHONG_SPECULAR_BASE_PROPERTIES,
    },
    "Phong Opaque Textured Emissive": {
        textures: ["diffuseTexture", "emissiveTexture"],
        properties: {
            ...PHONG_SPECULAR_BASE_PROPERTIES,
            emissiveColor: null,
        },
    },
    "Phong Transparent Textured": {
        textures: ["diffuseTexture"],
        properties: PHONG_BASE_PROPERTIES,
    },
} as const;

export function setMaterialFromMaterialConfig(meshComponent: MeshComponent, configObject: MaterialInterface, isPreview: boolean, engine: WonderlandEngine, cancelMarker: CancelledMarker) {
    const localRes = common.hoverfitSceneResources;
    const pipeline = configObject.pipeline;
    const pipelineDef = PIPELINES[pipeline as keyof typeof PIPELINES];

    if (!pipelineDef) {
        console.warn(`Unknown pipeline "${pipeline}"`);
        return;
    }

    const newMaterial = new Material(engine, { pipeline }) as AssetMaterial;
    meshComponent.material = newMaterial;

    if (isPreview) {
        // config boards (boards in the kiosk preview) should be fully bright,
        // but have diffuse reduced to prevent blown-out colors
        configObject = { ...configObject };

        if ("ambientColor" in pipelineDef.properties) {
            configObject.ambientColor = [1.0, 1.0, 1.0, 1.0];
        }

        if ("diffuseColor" in pipelineDef.properties) {
            if (configObject.diffuseColor) {
                configObject.diffuseColor = [
                    configObject.diffuseColor[0] * 0.85,
                    configObject.diffuseColor[1] * 0.85,
                    configObject.diffuseColor[2] * 0.85,
                    configObject.diffuseColor[3],
                ];
            } else {
                configObject.diffuseColor = [0.6, 0.6, 0.6, 1.0];
            }
        }
    }

    for (const key of Object.getOwnPropertyNames(pipelineDef.properties)) {
        const val = (configObject as any)[key] ?? (pipelineDef.properties as any)[key] ?? null;
        if (val === null) continue;
        (newMaterial as any)[key] = val;
    }

    for (const key of pipelineDef.textures) {
        const url = configObject[key];
        if (!url) continue;

        const textureName = key;
        CancellablePromise.wrapPromise(localRes.getTextureFromURL(url), cancelMarker).then((tex) => {
            newMaterial[textureName] = tex;
        }).catch(CancellablePromise.ignoreCancel);
    }
}

export function isDecorationName(name: string) {
    return name.startsWith(DECORATIONS_KEY_PREFIX);
}

export function isDecorationObject(object: Object3D) {
    return isDecorationName(object.name);
}

export function getNonDecorationObjectsByName(object: Object3D, targetName: string, isRegex = false) {
    const candidates: Object3D[] = [];
    const queue: Object3D[] = [object];
    let next: Object3D | undefined;
    while ((next = queue.shift())) {
        const name = next.name;
        if (isDecorationName(name)) continue;
        if ((!isRegex && name === targetName) || (isRegex && name.match(targetName) !== null)) {
            candidates.push(next);
        }
        queue.push.apply(queue, next.children);
    }

    return candidates;
}

export function getNonDecorationObjectByName(object: Object3D, targetName: string, isRegex = false, targetIndex = 0) {
    let index = 0;
    const queue: Object3D[] = [object];
    let next: Object3D | undefined;
    while ((next = queue.shift())) {
        const name = next.name;
        if (isDecorationName(name)) continue;
        if ((!isRegex && name === targetName) || (isRegex && name.match(targetName) !== null)) {
            if (index++ === targetIndex) return next;
        }
        queue.push.apply(queue, next.children);
    }

    return null;
}

function getDecorationParent(decorationRoot: Object3D, decoration: AssetDecoration): Object3D | null {
    const targetName = decoration.parent;
    if (targetName) {
        if (isDecorationName(targetName)) {
            console.warn(`Ignored decoration with target parent "${targetName}"; parent names can't use the special decoration prefix (${DECORATIONS_KEY_PREFIX})`);
            return null;
        }

        const decorationParentIndex = decoration.parentIndex ?? 0;
        const candidate = getNonDecorationObjectByName(decorationRoot, targetName, false, decorationParentIndex);

        if (!candidate) {
            console.warn(`Ignored decoration with target parent "${targetName}"; no such parent${decorationParentIndex === 0 ? "" : ` with index ${decorationParentIndex}`}`);
        }

        return candidate;
    } else {
        return decorationRoot;
    }
}

function addDecorationWrapper(decorationParent: Object3D, decorationsKey: string, decoration: AssetDecoration) {
    const obj = decorationParent.engine.scene.addObject(decorationParent);
    obj.name = decorationsKey;
    if (decoration.position) obj.setPositionLocal(decoration.position);
    if (decoration.rotation) {
        TEMP_QUAT.quat_set(decoration.rotation[0], decoration.rotation[1], decoration.rotation[2], decoration.rotation[3]);
        TEMP_QUAT.quat_normalize(TEMP_QUAT);
        obj.setRotationLocal(TEMP_QUAT);
    }
    if (decoration.scaling) obj.setScalingLocal(decoration.scaling);

    return obj;
}

export function addDecorationObjects(decorationRoot: Object3D, decorationsKeySuffix: string, decorations: AssetDecoration[] | undefined, active: boolean, isPreview: boolean, cancelMarker: CancelledMarker) {
    if (!decorations) return;

    const decorationsKey = `${DECORATIONS_KEY_PREFIX}${decorationsKeySuffix}`;
    const engine = decorationRoot.engine;
    const localRes = common.hoverfitSceneResources;

    for (const decoration of decorations) {
        switch (decoration.type) {
            case "mesh": {
                const decorationParent = getDecorationParent(decorationRoot, decoration);
                if (!decorationParent) continue;

                CancellablePromise.wrapPromise(localRes.getMeshFromURL(decoration.mesh), cancelMarker).then((mesh) => {
                    const obj = addDecorationWrapper(decorationParent, decorationsKey, decoration);
                    const meshComp = obj.addComponent("mesh", { mesh, active });

                    if (meshComp) {
                        setMaterialFromMaterialConfig(meshComp, decoration.material, isPreview, engine, cancelMarker);
                    }
                });
            } break;
            case "object": {
                const decorationParent = getDecorationParent(decorationRoot, decoration);
                if (!decorationParent) continue;

                CancellablePromise.wrapPromise(localRes.getObjectFromURL(decoration.object), cancelMarker).then((obj) => {
                    const wrapperObj = addDecorationWrapper(decorationParent, decorationsKey, decoration);

                    // WARNING i'd love to use the native Object3D.clone here,
                    //         but for unknown reasons it crashes with some
                    //         objects. maybe it's an engine bug?
                    CLONE_OPTS.myCloneParent = wrapperObj;
                    const clonedObj = obj.pp_clone(CLONE_OPTS);

                    if (clonedObj) {
                        if (decoration.resetTransform) clonedObj.resetTransform();

                        for (const component of clonedObj.pp_getComponents("mesh") as MeshComponent[]) {
                            component.active = active;
                            // XXX skinning MUST be disabled, otherwise you will
                            //     get very glitchy behaviour, like things
                            //     appearing where they shouldn't, or corrupted
                            //     meshes
                            component.skin = null;
                        }
                    } else {
                        console.warn(`Object clone failed for object decoration with object URL "${decoration.object}"`);
                    }
                });
            } break;
            default:
                console.warn(`Ignored unknown decoration "${(decoration as { type: unknown }).type}"`);
        }
    }
}

export function cleanupDecorationObjects(decorationRoot: Object3D, decorationsKeySuffix: string) {
    const decorationsKey = `${DECORATIONS_KEY_PREFIX}${decorationsKeySuffix}`;
    const queue: Object3D[] = [decorationRoot];
    let next: Object3D | undefined;
    while ((next = queue.shift())) {
        if (next.name === decorationsKey) {
            next.destroy();
        } else {
            queue.push.apply(queue, next.children);
        }
    }
}