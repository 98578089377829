import { MS_PER_15_MINUTES } from "src/hoverfit/utils/time-utils.js";

export function other_2_parse_latest(rawData: Record<string, unknown>) {
    return {
        currentWeekMidnightTime: Number(rawData.currentWeekMidnightTime) * MS_PER_15_MINUTES,
        dailySquats: rawData.dailySquats,
        weeklySquats: rawData.weeklySquats,
        totalSquats: rawData.totalSquats,
        dailyPlayTime: rawData.dailyPlayTime,
        weeklyPlayTime: rawData.weeklyPlayTime,
        totalPlayTime: rawData.totalPlayTime,
    };
}