export interface CancelledMarker {
    cancelled: boolean;
}

export class CancelledMarkerMap<K> {
    private markers = new Map<K, CancelledMarker>();
    private disposed = false;

    private assertNotDisposed() {
        if (this.disposed) throw new Error('Disposed');
    }

    replace(key: K): CancelledMarker {
        this.assertNotDisposed();
        let marker = this.markers.get(key);
        if (marker) marker.cancelled = true;
        marker = CancellablePromise.makeMarker();
        this.markers.set(key, marker);
        return marker;
    }

    cancelAll() {
        for (const marker of this.markers.values()) {
            marker.cancelled = true;
        }

        this.markers.clear();
    }

    dispose() {
        this.assertNotDisposed();
        this.cancelAll();
        this.disposed = true;
    }
}

export class PromiseCancelledError extends Error {
    constructor() {
        super('Promise cancelled');
    }
}

export type CancellablePromiseExecuter<T> = (resolve: (value: T) => void, reject: (error?: unknown) => void, cancelledMarker: CancelledMarker) => void;

export class CancellablePromise<T> extends Promise<T> {
    private marker: CancelledMarker;

    /**
     * A Promise that can be cancelled. The promise will be auto-rejected with a
     * PromiseCancelled error if the marker is set before the executor is
     * called, otherwise, you are still expected to check if the marker is set
     * in your executor.
     *
     * @param marker An existing CancelledMarker if you want to share cancellation between promises. If not provided, a new marker will be created
     */
    constructor(executor: CancellablePromiseExecuter<T>, marker?: CancelledMarker) {
        if (!marker) marker = CancellablePromise.makeMarker();

        super((resolve, reject) => {
            if (marker.cancelled) {
                reject(new PromiseCancelledError());
                return;
            }

            executor(resolve, reject, marker);
        });

        this.marker = marker;
    }

    /**
     * Wrap a promise and turn it into a CancellablePromise. The promise will be
     * auto-rejected with a PromiseCancelled error if the marker is set.
     *
     * @param marker An existing CancelledMarker if you want to share cancellation between promises. If not provided, a new marker will be created
     */
    static wrapPromise<T>(promise: Promise<T>, marker?: CancelledMarker) {
        const cp = new CancellablePromise<T>((resolve, reject, innerMarker) => {
            promise.then((value) => {
                if (innerMarker.cancelled) {
                    reject(new PromiseCancelledError());
                    return;
                }

                resolve(value);
            }).catch(reject);
        }, marker);
        return cp;
    }

    static makeMarker(): CancelledMarker {
        return { cancelled: false };
    }

    /** Catch all PromiseCancelled errors. Chainable. */
    static ignoreCancel = function (err: unknown): void {
        if (!(err instanceof PromiseCancelledError)) {
            throw err;
        }
    };

    cancel() {
        this.marker.cancelled = true;
    }

    get [Symbol.toStringTag]() {
        return 'CancellablePromise';
    }
}