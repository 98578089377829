import { Timer } from "wle-pp";
import { EnvironmentalSoundActor } from "./environmental-sound-actor.js";
import { EnvironmentalSoundArea } from "./environmental-sound-area.js";

export class EnvironmentalSoundsManager {

    private areas: EnvironmentalSoundArea[] = [];

    private delayBetweenSounds: Timer = new Timer(0);

    public addArea(area: EnvironmentalSoundArea) {
        this.areas.push(area);
    }

    update(dt: number) {
        let envSoundActorsPlayed: EnvironmentalSoundActor[] | null = null;

        for (const area of this.areas) {
            const currentEnvSoundActorPlayed = area.update(dt);

            if (currentEnvSoundActorPlayed != null) {
                if (envSoundActorsPlayed == null) {
                    envSoundActorsPlayed = [];
                }

                envSoundActorsPlayed.push(...currentEnvSoundActorPlayed);
            }
        }

        if (envSoundActorsPlayed != null) {
            for (const envSoundActorPlayed of envSoundActorsPlayed) {
                for (const area of this.areas) {
                    area.cooldownActor(envSoundActorPlayed);
                }
            }
        }
    }
}