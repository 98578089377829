export function bigNumberToString(x: number) {
    let eraseMul, maxDigits, suffix;
    if (x >= 1e12) { // trillions
        eraseMul = 1e-10;
        maxDigits = 3;
        suffix = 'T';
    } else if (x >= 1e9) { // billions
        eraseMul = 1e-7;
        maxDigits = 3;
        suffix = 'B';
    } else if (x >= 1e6) { // millions
        eraseMul = 1e-4;
        maxDigits = 3;
        suffix = 'M';
    } else if (x >= 1e3) { // thousands
        eraseMul = 1e-1;
        maxDigits = 3;
        suffix = 'K';
    } else { // small number
        eraseMul = 1e2;
        maxDigits = 4;
        suffix = '';
    }

    x = Math.trunc(x * eraseMul) * 1e-2;
    const rightDigits = Math.max(0, maxDigits - Math.trunc(Math.log10(Math.max(1, x))) - 1);
    return `${rightDigits > 0 ? Math.trunc(x * (10 ** rightDigits)) * (10 ** -rightDigits) : Math.trunc(x)}${suffix}`;
}