import { common } from "src/hoverfit/common.js";
import { ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { getAllCatalogItemsByCategory } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { BrowserUtils, XRUtils } from "wle-pp";
import { UICustomPopupHelperParams } from "../../xml-ui/ui-custom-popup-helper.js";
import { KioskPage, ShopPage } from "../components/kiosk-lower-ui-component.js";
import { PopupTip, PopupTipParams } from "./popup-tip.js";

export function preparePopupTips() {
    const popupTips = [];
    {
        const popupTipParams = new PopupTipParams();

        popupTipParams.popupParamsCreateCallback = () => {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "TIP: OPEN THE KIOSK";

            let buttonName = "";
            if (XRUtils.isSessionActive()) {
                buttonName = "the Y button";
            } else if (BrowserUtils.isMobile()) {
                buttonName = "the DOT button";
            } else {
                buttonName = "the F key";
            }

            popupParams.message = "You can open the kiosk in front of you with " + buttonName + "!\n\nYou can use it, for example, to go back to the balcony when on track.";

            popupParams.primaryButtonText = "CLOSE";
            popupParams.primaryButtonClickCallback = () => {
                common.kioskLowerUI.closePopup();
            };

            return popupParams;
        };

        popupTipParams.pickWeight = 2;
        popupTipParams.cooldownOnPick = 10;

        // A callback could be added to check if the user ever pressed the pause menu or did it a few times already so there is no need to display this anymore
        // popupTipParams.validCallback = ...;

        popupTips.push(new PopupTip(popupTipParams));
    }

    {
        const popupTipParams = new PopupTipParams();

        popupTipParams.popupParamsCreateCallback = () => {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "TIP: RESET AVATAR";

            popupParams.message = "If you have issues with your avatar position, you can reset it by long pressing the META button!";

            popupParams.primaryButtonText = "CLOSE";
            popupParams.primaryButtonClickCallback = () => {
                common.kioskLowerUI.closePopup();
            };

            return popupParams;
        };

        popupTipParams.pickWeight = 1;
        popupTipParams.cooldownOnPick = 10;

        popupTipParams.validCallback = () => {
            return XRUtils.isSessionActive() || true;
        };

        popupTips.push(new PopupTip(popupTipParams));
    }

    {
        const popupTipParams = new PopupTipParams();

        popupTipParams.popupParamsCreateCallback = () => {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "MORE LOCATIONS";

            popupParams.message = "Want to hover to new places? Check the shop!\n\nBuy a new location and you will get access to all its modes and features.";

            popupParams.primaryButtonText = "BUY NOW";
            popupParams.primaryButtonClickCallback = () => {
                common.kioskLowerUI.closePopup();
                common.kioskLowerUI.changeKioskPage(KioskPage.Shop);
                common.kioskLowerUI.changeKioskShopPage(ShopPage.Locations);
            };

            popupParams.lowPriorityButtonText = "MAYBE LATER";
            popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };

            return popupParams;
        };

        popupTipParams.pickWeight = 4;
        popupTipParams.cooldownOnPick = 10;

        popupTipParams.validCallback = () => {
            if (common.iapContentController.inventory.has("iap:b_one-year-premium-pass")) return false;

            const locationItems = getAllCatalogItemsByCategory(common.iapContentController.catalog, ItemCategory.Location);

            let ownsAllCatalogLocations = true;
            for (const locationItem of locationItems) {
                if (!common.iapContentController.inventory.has(locationItem.id)) {
                    ownsAllCatalogLocations = false;
                    break;
                }
            }

            return !ownsAllCatalogLocations;
        };

        popupTips.push(new PopupTip(popupTipParams));
    }

    {
        const popupTipParams = new PopupTipParams();

        popupTipParams.popupParamsCreateCallback = () => {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "GET MORE, PAY LESS";

            popupParams.message = "Save up to 95% with the Ultimate Premium Bundle!\nMaximize your workouts with one year of access to ALL locations, game settings and catalog items.";

            popupParams.primaryButtonText = "BUY NOW";
            popupParams.primaryButtonClickCallback = () => {
                common.kioskLowerUI.closePopup();
                common.kioskLowerUI.changeKioskPage(KioskPage.Shop);
                common.kioskLowerUI.changeKioskShopPage(ShopPage.Bundles);
            };

            popupParams.lowPriorityButtonText = "MAYBE LATER";
            popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };

            return popupParams;
        };

        popupTipParams.pickWeight = 6;
        popupTipParams.cooldownOnPick = 10;

        popupTipParams.validCallback = () => {
            return !common.iapContentController.inventory.has("iap:b_one-year-premium-pass");
        };

        popupTips.push(new PopupTip(popupTipParams));
    }

    return popupTips;
}