import { AnimationComponent, AnimationState, Component, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameMode } from "hoverfit-shared-netcode";
import { AudioPlayer, AudioSetup, EasingFunction, MathUtils, ObjectUtils, Timer } from "wle-pp";
import { AudioChannelName } from "../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { common } from "../../common.js";
import { GameGlobals } from "../../misc/game-globals.js";
import { TrainerMusclesComponent } from "./trainer-muscles-component.js";

export enum InstructorAudioType {
    Instructions = "instructions",
    HealthBenefits = "health_benefits",
    GameModeInstructions = "game_mode_instructions",
}

const gameModeToInstructionAudioPath = {
    [GameMode.Race]: "assets/audio/sfx/maps/misc/track/instructions/race-instructions.webm",
    [GameMode.Roam]: "assets/audio/sfx/maps/misc/track/instructions/roam-instructions.webm",
    [GameMode.Tag]: "assets/audio/sfx/maps/misc/track/instructions/tag-instructions.webm",
};

export class HoverboardInstructorComponent extends Component {
    static override TypeName = "hoverboard-instructor";

    @property.object()
    hoverboard!: Object3D;

    @property.object()
    instructor!: Object3D;

    @property.string()
    instructorAudioPath!: string;

    @property.string()
    healthAudioPath!: string;

    @property.float(30)
    hoverboardYRotationSpeed!: number;

    animationComponent!: AnimationComponent;
    trainerMusclesComponent!: TrainerMusclesComponent;

    enableAudio: boolean = false;
    currentInstructorAudioType: InstructorAudioType = InstructorAudioType.Instructions;
    started: boolean = false;

    startTimer: Timer = new Timer(0.75);
    rotationSpeedUpTimer: Timer = new Timer(3);
    currentHoverboardYRotationSpeed: number = 0;

    override init() {
        this.animationComponent = ObjectUtils.getComponent(this.instructor, AnimationComponent)!;
        this.trainerMusclesComponent = ObjectUtils.getComponent(this.instructor, TrainerMusclesComponent)!;

        common.hoverboardInstructor = this;
    }

    override start() {
        const instructorAudioSetup = new AudioSetup(this.instructorAudioPath);
        instructorAudioSetup.myVolume = 0.5;
        const instructorAudioPlayer = new AudioPlayer(instructorAudioSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.INSTRUCTOR, instructorAudioPlayer, AudioChannelName.INSTRUCTIONS);

        const healthAudioSetup = new AudioSetup(this.healthAudioPath);
        healthAudioSetup.myVolume = 0.5;
        const healthAudioPlayer = new AudioPlayer(healthAudioSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.HEALTH, healthAudioPlayer, AudioChannelName.INSTRUCTIONS);

        const gameModeInstructionAudioPath = gameModeToInstructionAudioPath[common.gameConfig.mode];
        const gameModeInstructionAudioSetup = new AudioSetup(gameModeInstructionAudioPath);
        gameModeInstructionAudioSetup.myVolume = 0.5;
        const gameModeInstructionAudioPlayer = new AudioPlayer(gameModeInstructionAudioSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.GAME_MODE_INSTRUCTION, gameModeInstructionAudioPlayer, AudioChannelName.INSTRUCTIONS);

        this.animationComponent.stop();
        this.stopAudioPlayers();

        this.trainerMusclesComponent.resetPulse();

        this.started = true;
    }

    setSelectedAudioPlayer(instructorAudioType: InstructorAudioType) {
        this.currentInstructorAudioType = instructorAudioType;
    }

    override update(dt: number) {
        this.startTimer.update(dt);
        if (this.animationComponent.state == AnimationState.Stopped && this.startTimer.isDone()) {
            this.animationComponent.stop();
            this.animationComponent.play();

            if (this.enableAudio) {
                this.stopAudioPlayers();
                this.playSelectedAudio();
                this.enableAudio = false;
            }
        }

        this.updateHoverboardRotation(dt);
    }

    playSelectedAudio() {
        if (!this.started) return;

        switch (this.currentInstructorAudioType) {
            case InstructorAudioType.Instructions:
                common.audioManager.getAudio(AudioID.INSTRUCTOR)!.play();
                common.MAIN_CHANNEL.emit("play-tutorial", InstructorAudioType.Instructions);
                break;
            case InstructorAudioType.HealthBenefits:
                common.audioManager.getAudio(AudioID.HEALTH)!.play();
                common.MAIN_CHANNEL.emit("play-tutorial", InstructorAudioType.HealthBenefits);
                break;
            case InstructorAudioType.GameModeInstructions:
                common.audioManager.getAudio(AudioID.GAME_MODE_INSTRUCTION)!.play();
                break;
        }
    }

    stopAudioPlayers() {
        if (!this.started) return;

        common.audioManager.getAudio(AudioID.INSTRUCTOR)!.stop();
        common.audioManager.getAudio(AudioID.HEALTH)!.stop();
        common.audioManager.getAudio(AudioID.GAME_MODE_INSTRUCTION)!.stop();
    }

    updateHoverboardRotation(dt: number) {
        this.rotationSpeedUpTimer.update(dt);
        this.currentHoverboardYRotationSpeed = MathUtils.interpolate(0, this.hoverboardYRotationSpeed, this.rotationSpeedUpTimer.getPercentage(), EasingFunction.easeInWeak);
        ObjectUtils.rotateAxisObject(this.hoverboard, -this.currentHoverboardYRotationSpeed * dt, GameGlobals.up);
    }

    restartAnimation(enableAudio = false, instant = false) {
        if (!this.started) return;

        this.startTimer.start();
        if (instant) {
            this.startTimer.end();
        }
        this.animationComponent.stop(); // to force restart, to sync audio
        this.enableAudio = enableAudio;
    }

    override onDeactivate(): void {
        if (this.started) {
            this.trainerMusclesComponent.resetPulse();
            this.hoverboard.resetRotation();
            ObjectUtils.rotateAxisObject(this.hoverboard, 90, GameGlobals.up);
            this.currentHoverboardYRotationSpeed = 0;

            this.startTimer.reset();
            this.rotationSpeedUpTimer.start();
            this.restartAnimation();
            this.stopAudioPlayers();
        }
    }
}