import { HeyVRLeaderboard } from "./heyvr-leaderboard.js";
import { type LeaderboardScore, type LeaderboardScoreCtor } from "./leaderboard-score.js";

export class HeyVRNumericLeaderboard<S extends LeaderboardScore<number>> extends HeyVRLeaderboard<S> {
    constructor(readonly leaderboardID: string, subtitle: string, scoreLabel: string, leaderboardScoreCtor: LeaderboardScoreCtor<S>) {
        super(subtitle, scoreLabel, leaderboardScoreCtor);
    }

    protected override scoreToNumber(score: number): number {
        return score;
    }

    protected override scoreFromNumber(val: number): number {
        return val;
    }

    protected getLeaderboardID(_score?: number | undefined): string {
        return this.leaderboardID;
    }
}