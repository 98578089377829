import { Alignment, Column, Container, FlexAlignment, Label, Row, TextAlignMode, Widget, WrapMode, type ScrollableViewportWidgetProperties, type WidgetAutoXML } from "lazy-widgets";
import { common } from "src/hoverfit/common.js";
import { BrowserUtils, XRUtils } from "wle-pp";
import { WidgetDecoratedButton } from "../../lazy-widgets/widgets/widget-decorated-button.js";
import { UICustomPopupHelperParams } from "../../xml-ui/ui-custom-popup-helper.js";

interface Credit {
    name: string,
    role: string,
    link?: string,
}

const CREDIT_LIST: Credit[] = [
    {
        name: "Yinch Yeap",
        role: "Lead Developer",
        link: "https://linktr.ee/yinch"
    },
    {
        name: "Tim Dudgeon",
        role: "Game Designer"
    },
    {
        name: "Lydia Berry",
        role: "Operations"
    },
    {
        name: "Jack Gower",
        role: "Partnerships"
    },
    {
        name: "Elia \"Pipo\" Ducceschi",
        role: "Developer",
        link: "https://signorpipo.itch.io"
    },
    {
        name: "Rafael Fernandes",
        role: "Developer",
        link: "https://github.com/rafern"
    },
    {
        name: "Florian Isikci",
        role: "Developer"
    },
    {
        name: "Olive Ketteridge",
        role: "3D Artist"
    },
    {
        name: "Sophia Schreiter",
        role: "3D Artist"
    },
    {
        name: "Matt Berry",
        role: "Audio Designer"
    },
    {
        name: "Annie Simon",
        role: "UI Artist"
    },
];

export interface CreditListProperties extends ScrollableViewportWidgetProperties {
    creditWidth?: number;
}

export class CreditList extends Column {
    static override autoXML: WidgetAutoXML = {
        name: "credit-list",
        inputConfig: []
    };

    constructor(properties?: CreditListProperties) {
        const widgets: Widget[] = [];

        let nameWidth = undefined;
        let roleWidth = undefined;

        if (properties?.creditWidth) {
            nameWidth = properties.creditWidth * 0.5;
            roleWidth = properties.creditWidth * 0.45;
        }

        const bodyTextFont = "0.8em sui-generis";
        for (const credit of CREDIT_LIST) {
            const widgetDecoratedButton = new WidgetDecoratedButton(
                new Row(
                    [
                        new Label(
                            credit.name,
                            {
                                bodyTextAlign: TextAlignMode.End,
                                wrapMode: WrapMode.Ellipsis,
                                bodyTextFont: bodyTextFont,
                                minWidth: nameWidth,
                                maxWidth: nameWidth,
                            }
                        ),
                        new Container(
                            new Label(
                                "○",
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFont: bodyTextFont,
                                    flex: 1
                                }
                            ),
                            {
                                containerPadding: { top: 0, right: 0, bottom: 1, left: 0 },
                            }
                        ),
                        new Label(
                            credit.role,
                            {
                                bodyTextAlign: TextAlignMode.Start,
                                wrapMode: WrapMode.Ellipsis,
                                bodyTextFont: bodyTextFont,
                                minWidth: roleWidth,
                                maxWidth: roleWidth,
                            }
                        )
                    ],
                    {
                        multiContainerSpacing: 5,
                        multiContainerAlignment: {
                            main: FlexAlignment.Center,
                            cross: Alignment.Stretch,
                        }
                    }
                )
                , {
                    minWidth: properties?.creditWidth,
                    maxWidth: properties?.creditWidth,
                    minHeight: 16.5,
                    maxHeight: 16.5,
                    unhoverBackgroundFill: "rgba(0.6,0.6,0.6,0)",
                    hoverBackgroundFill: "rgba(0.6,0.6,0.6,0.2)",
                    holdBackgroundFill: "rgba(0.6,0.6,0.6,0.4)",
                }
            );

            if (credit.link != null) {
                widgetDecoratedButton.on("click", showCreatorPage.bind(null, credit.link));
            } else {
                widgetDecoratedButton.clickable = false;
            }

            widgets.push(widgetDecoratedButton);
        }

        super(
            widgets,
            {
                multiContainerSpacing: 1,
                flex: 1,
                multiContainerAlignment: {
                    main: FlexAlignment.Start,
                    cross: Alignment.Stretch,
                },
                ...properties,
            },
        );
    }
}

function showCreatorPage(url: string) {
    if (XRUtils.isSessionActive()) {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "LEAVING VR";
        popupParams.message = "To open the creator page, you will be taken out of the current VR session.";
        popupParams.primaryButtonText = "OPEN";
        popupParams.primaryButtonClickCallback = () => {
            common.kioskLowerUI.closePopup();
            BrowserUtils.openLink(url);
        };
        popupParams.lowPriorityButtonText = "CANCEL";
        popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };

        common.kioskLowerUI.showCustomPopup(popupParams);
    } else {
        BrowserUtils.openLink(url);
    }
}