import { Icon, type Variable, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { Gender } from "../../../data/values/gender.js";
import { BaseDecoratedButton } from "../../lazy-widgets/widgets/base-decorated-button.js";

interface CustomiseGenderButtonProperties extends WidgetProperties {
    darkMode?: boolean;
}

export class CustomiseGenderButton extends BaseDecoratedButton<Icon> {
    static override autoXML: WidgetAutoXML = {
        name: "customise-gender-button",
        inputConfig: [
            {
                mode: "value",
                name: "gender",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    constructor(readonly gender: Gender, readonly variable: Variable<Gender>, properties?: Readonly<CustomiseGenderButtonProperties>) {
        const darkMode = properties?.darkMode ?? false;

        super(
            new Icon(`assets/textures/ui/icons/kiosk/${darkMode ? "dark-" : ""}${gender === Gender.Female ? "fe" : ""}male.svg`),
            {
                unhoverBackgroundFill: `rgba(120, 120, 120, ${darkMode ? 0.35 : 0.25})`,
                hoverBackgroundFill: `rgba(120, 120, 120, ${darkMode ? 0.5 : 0.4})`,
                forceBackgroundFill: `rgba(0, 120, 240, ${darkMode ? 0.7 : 0.5})`,
                holdBackgroundFill: `rgba(0, 120, 240, ${darkMode ? 0.5 : 0.4})`,
                forceStroke: darkMode ? "rgba(0, 0, 64, 0.8)" : undefined,
                hasBorder: darkMode,
                forceStrokeThickness: 2,
                normalStrokeThickness: 2,
                unclickableStrokeThickness: 2,
                containerPadding: { top: 6, right: 6, bottom: 6, left: 6 },
                ...properties,
            }
        );

        this.on("click", () => {
            variable.value = gender;
        });
    }

    private watchVariable = () => {
        this.forced = this.variable.value === this.gender;
    };

    protected override activate(): void {
        super.activate();
        this.variable.watch(this.watchVariable, true);
    }

    protected override deactivate(): void {
        this.variable.unwatch(this.watchVariable);
        super.deactivate();
    }
}