import { Column, Row, type ValidatedVariable, type WidgetProperties } from "lazy-widgets";
import { DecoratedButton } from "./decorated-button.js";

interface NumpadProperties extends WidgetProperties {
    buttonHeight?: number;
    buttonWidth?: number;
    fontSize?: number;
    buttonSpacing?: number;
    submitLabel?: string,
    submitCallback?: (() => void) | null,
}

export class Numpad extends Column {
    static override autoXML = {
        name: "numpad",
        inputConfig: [
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            }
        ],
    };

    private buttonHeight: number | undefined;
    private buttonWidth: number;
    private fontSize: number | undefined;
    private rowWidth: number;

    private submitButton: DecoratedButton;

    constructor(readonly variable: ValidatedVariable<string, number | null>, properties?: NumpadProperties) {
        const buttonWidth = properties!.buttonWidth ?? 50;
        const buttonHeight = properties!.buttonHeight ?? undefined;
        const columnHeight = buttonHeight ? ((properties!.buttonSpacing ?? 10) * 3 + buttonHeight * 4) : undefined;
        super([], {
            ...properties,
            minHeight: columnHeight,
            maxHeight: columnHeight,
        });

        this.buttonHeight = buttonHeight;
        this.buttonWidth = buttonWidth;
        this.fontSize = properties!.fontSize ?? undefined;
        this.rowWidth = (properties!.buttonSpacing ?? 10) * 2 + this.buttonWidth * 3;

        this.submitButton = this.button(properties?.submitLabel ?? "SUBMIT");
        if (properties != null && properties.submitCallback != null) {
            this.submitButton.on("click", () => properties!.submitCallback!());
        }

        this.add([
            this.nRow(1, 2, 3),
            this.nRow(4, 5, 6),
            this.nRow(7, 8, 9),
            new Row([
                this.button("CLEAR").on("click", () => { this.variable.value = ""; }),
                this.nButton(0),
                this.submitButton,
            ], {
                minWidth: this.rowWidth,
                maxWidth: this.rowWidth,
            }),
        ]);
    }

    set submitClickable(clickable: boolean) {
        this.submitButton.clickable = clickable;
    }

    private buttonProperties() {
        return {
            minWidth: this.buttonWidth,
            maxWidth: this.buttonWidth,
            minHeight: this.buttonHeight,
            maxHeight: this.buttonHeight,
            fontSize: this.fontSize
        };
    }

    private button(text: string) {
        return new DecoratedButton(text, this.buttonProperties());
    }

    private nRow(n1: number, n2: number, n3: number) {
        return new Row([this.nButton(n1), this.nButton(n2), this.nButton(n3)], {
            minWidth: this.rowWidth,
            maxWidth: this.rowWidth,
        });
    }

    private nButton(n: number) {
        return this.button(n.toString()).on("click", () => {
            if (this.variable.setValue(`${this.variable.value}${n}`) && this.variable.validValue !== null) {
                this.variable.setValue(this.variable.validValue.toString(), false);
            }
        });
    }
}