<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <book option:id="mode-book" option:min-width="@624" option:max-width="@624" option:min-height="@303" option:max-height="@303">
        <container option:container-padding='@{"top":8,"right":16,"bottom":16,"left":16}' option:flex="@1">
            <column>
                <row option:multi-container-alignment='@{"main":0.5,"cross":1}'>
                    <row>
                        <icon image="assets/textures/ui/icons/kiosk/person.svg" option:width="@24" option:height="@24"/>
                        <live-label option:body-text-align="@0.5" option:body-text-fill="white" option:body-text-font="2em sui-generis" text-source="$welcome"/>
                    </row>
                    <row option:id="openLoginButtonContainer">
                        <label option:body-text-align="@0.5" option:body-text-fill="white" option:body-text-font="2em sui-generis">  -  </label>
                        <container  option:container-padding='@{"top":1.5,"right":0,"bottom":0,"left":0}'>
                            <decorated-button on:click="$openLogin" option:font-size="@1.25" option:min-width="@70" option:max-width="@70" option:min-height="@25" option:max-height="@25">LOGIN</decorated-button>
                        </container>
                    </row>
                </row>
                <spacing option:min-height="@4" option:flex="@0"/>
                <rounded-corners option:rounded-corners-radii="@6">
                    <background option:canvas-fill="rgba(30, 50, 100, 0.3)" option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                        <score-row fit-points="$fitPoints" fitabux="$fitabux" heyvr-coins="$heyvrCoins" option:dark="@true"/>
                    </background>
                </rounded-corners>
                <spacing option:min-height="@8" option:flex="@0"/>
                <back-pane option:flex="@1" option:alpha="@0.25" option:container-padding='@{"top":8,"right":0,"bottom":8,"left":0}'>
                    <column>
                        <container option:container-padding='@{"top":0,"right":15,"bottom":0,"left":15}'>
                            <row option:multi-container-alignment='@{"main":"0.5","cross":"soft-stretch"}'>
                                <spacing option:flex="@0" option:min-width="@75" option:max-width="@75"/>
                                <spacing/>
                                <book option:id="leaderboard-mode-button-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <decorated-button on:click="$changeLeaderboardMode" option:font-size="@0.9" option:min-width="@75" option:max-width="@75" option:min-height="@18" option:max-height="@18" option:enabled="@false" option:id="leaderboard-mode-button"></decorated-button>
                                    <spacing option:flex="@0" option:min-width="@75" option:max-width="@75"/>
                                </book>
                                <arrow-decorated-button on:click="$prevLeaderboardType" option:icon-size="@10" direction="left"/>
                                <live-label option:min-width="@175" option:max-width="@175" option:body-text-align="@0.5" text-source="$leaderboardTitleText" option:body-text-fill="black" option:body-text-font="1.5em sui-generis" />
                                <arrow-decorated-button on:click="$nextLeaderboardType" option:icon-size="@10" direction="right"/>
                                <book option:id="last-race-button-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <decorated-button on:click="$showLastRaceLeaderboard" option:font-size="@0.9" option:min-width="@75" option:max-width="@75" option:min-height="@18" option:max-height="@18">LAST RACE</decorated-button>
                                    <spacing option:flex="@0" option:min-width="@75" option:max-width="@75"/>
                                </book>
                                <spacing/>
                                <book option:id="get-notified-button-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                    <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                        <decorated-button on:click="$getNotified" option:font-size="@0.7" option:min-width="@70" option:max-width="@70" option:min-height="@14" option:max-height="@14">GET NOTIFIED</decorated-button>
                                    </column>
                                    <spacing option:flex="@0" option:min-width="@70" option:max-width="@70"/>
                                </book>
                            </row>
                        </container>
                        <book option:id="leaderboard-book" option:flex="@1">
                            <live-label option:body-text-align="@0.5" option:body-text-fill="black" text-source="$leaderboardUnavailableText"/>
                            <container option:container-padding='@{"top":0,"right":11,"bottom":0,"left":28}'>
                                <column>
                                    <container option:container-padding='@{"top":0,"right":17,"bottom":0,"left":0}'>
                                        <column>
                                            <live-label option:body-text-align="@0.5" text-source="$leaderboardSubtitleText" option:body-text-fill="black"/>
                                            <row option:multi-container-spacing="@0" option:min-width="$leaderboardWidth" option:max-width="$leaderboardWidth">
                                                <label option:body-text-align="@0.5" option:body-text-fill="black" option:min-width="$leaderboardRankWidth" option:max-width="$leaderboardRankWidth">Rank</label>
                                                <label option:body-text-align="@0.5" option:flex="@1" option:body-text-fill="black">Name</label>
                                                <live-label text-source="$leaderboardScoreLabel" option:body-text-align="@0.5" option:body-text-fill="black" option:min-width="$leaderboardTimeWidth" option:max-width="$leaderboardTimeWidth"/>
                                            </row>
                                        </column>
                                    </container>
                                    <leaderboard option:flex="@1" option:id="leaderboard" table-width="$leaderboardWidth" rank-width="$leaderboardRankWidth" time-width="$leaderboardTimeWidth" row-height="$leaderboardRowHeight">
                                        <live-label text-source="$leaderboardUnavailableText" option:body-text-fill="black" option:body-text-align="@0.5"/>
                                    </leaderboard>
                                </column>
                            </container>
                        </book>
                    </column>
                </back-pane>
            </column>
        </container>
        <rounded-corners option:rounded-corners-radii="@20" option:flex="@1">
            <kiosk-background>
                <container option:container-padding='@{"top":16,"right":16,"bottom":16,"left":16}'>
                    <column>
                        <rounded-corners option:rounded-corners-radii="@6">
                            <background option:canvas-fill="rgba(128, 128, 128, 0.225)" option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                                <score-row fit-points="$fitPoints" fitabux="$fitabux" heyvr-coins="$heyvrCoins" option:dark="@true"/>
                            </background>
                        </rounded-corners>
                        <spacing option:min-height="@8" option:flex="@0"/>
                        <back-pane option:flex="@1" option:container-padding='@{"top":10,"right":10,"bottom":10,"left":10}'>
                            <column>
                                <label option:body-text-align="@0.5" option:body-text-font="2em sui-generis">CUSTOMISE YOUR AVATAR</label>
                                <spacing/>
                            </column>
                        </back-pane>
                    </column>
                </container>
            </kiosk-background>
        </rounded-corners>
        <rounded-corners option:rounded-corners-radii="@20" option:flex="@1">
            <kiosk-background>
                <container option:container-padding='@{"top":16,"right":16,"bottom":16,"left":16}'>
                    <column>
                        <rounded-corners option:rounded-corners-radii="@6">
                            <background option:canvas-fill="rgba(128, 128, 128, 0.225)" option:container-padding='@{"top":8,"right":16,"bottom":8,"left":16}'>
                                <score-row fit-points="$fitPoints" fitabux="$fitabux" heyvr-coins="$heyvrCoins" option:dark="@true"/>
                            </background>
                        </rounded-corners>
                        <spacing option:min-height="@8" option:flex="@0"/>
                        <back-pane option:flex="@1" option:container-padding='@{"top":10,"right":10,"bottom":10,"left":10}'>
                            <column option:multi-container-spacing="@6">
                                <container option:container-padding='@{"top":0,"right":28,"bottom":0,"left":28}'>
                                    <column option:multi-container-spacing="@8">
                                        <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis" option:body-text-fill="white">Statistics</label>
                                        <row option:multi-container-spacing="@0" option:min-width="$statsWidth" option:max-width="$statsWidth" option:multi-container-alignment='@{"main":0,"cross":0}'>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-fill="white" option:flex-basis="@0" option:flex="@1.4" option:flex-shrink="@1.4">Name</label>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-fill="white" option:flex-basis="@0" option:flex="@1" option:flex-shrink="@1">Daily</label>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-fill="white" option:flex-basis="@0" option:flex="@1" option:flex-shrink="@1">Weekly</label>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-fill="white" option:flex-basis="@0" option:flex="@1" option:flex-shrink="@1">Monthly</label>
                                            <label option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-fill="white" option:flex-basis="@0" option:flex="@1" option:flex-shrink="@1">Lifetime</label>
                                        </row>
                                    </column>
                                </container>
                                <container option:flex="@1" option:container-padding='@{"top":0,"right":11,"bottom":0,"left":28}'>
                                    <stats option:id="stats" table-width="$statsWidth" row-height="$statsRowHeight">
                                        <label option:body-text-align="@0.5">No stats available</label>
                                    </stats>
                                </container>
                            </column>
                        </back-pane>
                    </column>
                </container>
            </kiosk-background>
        </rounded-corners>
    </book>
</ui-tree>