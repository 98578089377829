import { Gender } from "../../data/values/gender.js";
import { type ObservableItemIDCollection } from "../data-structs/observable-item-id-collection.js";
import { ObservableItemIDMap } from "../data-structs/observable-item-id-map.js";
import { type AssetBase, type AssetManifest, type DefaultAssetBase, type GenderedThumbnailAssetBase } from "./asset-manifest-types.js";
import { ItemCategory, type ItemNamespace } from "./asset-provider.js";
import { type ItemOwnershipMetadata } from "./item-ownership-metadata.js";
import { type OwnedItem } from "./owned-item.js";
import { ShopItem } from "./shop-item.js";

const EQUIPABLE_ITEM_CATEGORIES: ReadonlySet<ItemCategory> = new Set([
    ItemCategory.Skin,
    ItemCategory.Hoverboard,
    ItemCategory.Headwear,
    ItemCategory.Suit,
    ItemCategory.HairColor,
]);

export const DEFAULT_ITEM_OWN_META: Readonly<ItemOwnershipMetadata> = {
    expiresOn: 0,
};

export function unpackAssetShortID(outOwnedItems: OwnedItem[], catalog: ObservableItemIDCollection, assetManifest: AssetManifest, shortID: string, namespace: ItemNamespace, metadata: Readonly<ItemOwnershipMetadata>) {
    outOwnedItems.push([shortID, metadata]);

    const bundle = assetManifest.bundle[shortID];
    if (bundle) {
        // technically this means you can't have bundles in bundles, and bundles
        // can't contain things from other shops. if for some crazy reason you
        // want to allow bundles to contain other bundles, then use
        // unpackAssetShortID here too instead of generating a namespaced ID
        for (const otherShortID of bundle.items) {
            if (otherShortID.startsWith("*")) {
                // filter
                const filterData = otherShortID.slice(1).split(":", 2);
                const filter = filterData[0];
                if (filter === "catalog") {
                    for (const category of (filterData[1] ?? "").split(",") as ItemCategory[]) {
                        for (const uShortID of Object.getOwnPropertyNames(assetManifest[category])) {
                            if (catalog.has(`${namespace}:${uShortID}`)) {
                                outOwnedItems.push([uShortID, metadata]);
                            }
                        }
                    }
                } else {
                    console.error(`Unknown bundle filter "${filter}"`);
                }
            } else {
                // short ID
                outOwnedItems.push([otherShortID, metadata]);
            }
        }
    }
}

export function unpackAssetShortIDsInMap(outOwnedItems: OwnedItem[], catalog: ObservableItemIDCollection, assetManifest: AssetManifest, map: Record<string, unknown>, namespace: ItemNamespace) {
    for (const shortID of Object.getOwnPropertyNames(map)) {
        unpackAssetShortID(outOwnedItems, catalog, assetManifest, shortID, namespace, DEFAULT_ITEM_OWN_META);
    }
}

export function unpackAssetShortIDsInManifest(outOwnedItems: OwnedItem[], catalog: ObservableItemIDCollection, assetManifest: AssetManifest, namespace: ItemNamespace) {
    for (const map of Object.values(assetManifest)) {
        unpackAssetShortIDsInMap(outOwnedItems, catalog, assetManifest, map, namespace);
    }
}

export function replaceNamespaceFromManifest(inventory: ObservableItemIDMap<ItemOwnershipMetadata>, catalog: ObservableItemIDCollection, manifest: AssetManifest, namespace: ItemNamespace) {
    const ownedItems: OwnedItem[] = [];
    unpackAssetShortIDsInManifest(ownedItems, catalog, manifest, namespace);
    inventory.replaceNamespace(ownedItems, namespace);
}

export function getAssetFromManifest(manifest: AssetManifest, shortID: string): [asset: AssetBase, category: ItemCategory] | undefined {
    let asset: AssetBase | undefined = manifest.hoverboard[shortID];
    if (asset) return [asset, ItemCategory.Hoverboard];
    if ((asset = manifest.suit[shortID])) return [asset, ItemCategory.Suit];
    if ((asset = manifest.headwear[shortID])) return [asset, ItemCategory.Headwear];
    if ((asset = manifest.hairColor[shortID])) return [asset, ItemCategory.HairColor];
    if ((asset = manifest.skin[shortID])) return [asset, ItemCategory.Skin];
    if ((asset = manifest.bundle[shortID])) return [asset, ItemCategory.Bundle];
    if ((asset = manifest.location[shortID])) return [asset, ItemCategory.Location];
    return;
}

export function getAssetThumbnail(asset: AssetBase, gender: Gender) {
    const genderlessThumb = (asset as DefaultAssetBase).thumbnail;
    if (genderlessThumb) return genderlessThumb;
    return (asset as GenderedThumbnailAssetBase)[`thumbnail${gender === Gender.Female ? "Fem" : "M"}ale`];
}

export function isItemCategoryEquippable(category: ItemCategory | null = null) {
    if (!category) return false;
    return EQUIPABLE_ITEM_CATEGORIES.has(category);
}

export function getAllCatalogItemsByCategory(catalog: ObservableItemIDMap<ShopItem>, category: ItemCategory): ShopItem[] {
    const items: ShopItem[] = [];

    for (const id of catalog.getAllIDs()) {
        const item = catalog.get(id);
        if (item?.itemCategory == category) {
            items.push(item);
        }
    }

    return items;
}