import { type UnsafeDecodedSaveData } from "src/hoverfit/data/save-data.js";
import { other_1_parse_latest } from "./common/other_1_parse_latest.js";
import { other_2_parse_latest } from "./common/other_2_parse_latest.js";
import { other_4_parse_latest } from "./common/other_4_parse_latest.js";
import { other_5_parse_latest } from "./common/other_5_parse_latest.js";
import { SaveDecoder } from "./common/save-decoder.js";

export class SaveDecoderV5 extends SaveDecoder {
    override decodeSaveData(rawData: any): UnsafeDecodedSaveData | null {
        if (rawData === null || (typeof rawData !== 'object')) return null;

        return {
            ...other_1_parse_latest(rawData),
            ...other_2_parse_latest(rawData),
            ...other_4_parse_latest(rawData),
            pendingLeaderboardScores: rawData.pendingLeaderboardScores,
            ...other_5_parse_latest(rawData)
        };
    }
}