import { common } from "../../common.js";
import { GameModeVariable } from "./game-mode-variable.js";

export class NPCsAmountVariable extends GameModeVariable<number> {
    constructor() {
        super(2, "change_npcs_amount");
    }

    protected override sendNetVar(): void {
        common.hoverboardNetworking.updateNPCsAmount(this.value);
    }
}