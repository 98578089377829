import { ItemNamespace } from "../asset-provision/asset-provider.js";
import { ObservableItemIDCollection, ObservableItemIDCollectionEventType } from "./observable-item-id-collection.js";

/**
 * An observable ordered set of item IDs, mapped to an arbitrary value.
 *
 * Mapped values can have custom logic for how they're merged if they already
 * exist; this is useful for, for example, mapping expiry times to item IDs and
 * keeping only the expiry times that end at the most late time (or items that
 * last forever over items that expire).
 */
export class ObservableItemIDMap<V> extends ObservableItemIDCollection {
    private readonly values = new Map<string, V>();

    /**
     * Get value assigned to a specific item ID, or undefined if it doesn't
     * exist, or if the collection is suppressed.
     */
    get(id: string): V | undefined {
        if (this._suppressed) return undefined;
        return this.values.get(id);
    }

    /**
     * Similar to {@link ObservableItemIDMap#get}, but uses a short ID instead
     * of a namespaced ID.
     */
    getShort(shortID: string, namespace: ItemNamespace): V | undefined {
        return this.get(`${namespace}:${shortID}`);
    }

    /**
     * Similar to {@link ObservableItemIDMap#get}, but throws an exception if
     * the ID doesn't exist, or if the collection is suppressed.
     */
    getRequired(id: string): V {
        if (this._suppressed) {
            throw new Error(`Can't get item with ID "${id}"; list is suppressed`);
        }

        if (!this.values.has(id)) {
            throw new Error(`No such item with ID "${id}"`);
        }

        return this.values.get(id)!;
    }

    /**
     * Similar to {@link ObservableItemIDMap#getRequired}, but uses a short ID
     * instead of a namespaced ID.
     */
    getShortRequired(shortID: string, namespace: ItemNamespace): V {
        return this.getRequired(`${namespace}:${shortID}`);
    }

    private _has(id: string) {
        return this.values.has(id);
    }

    override has(id: string) {
        if (this._suppressed) return false;
        return this._has(id);
    }

    /**
     * Check if a new value should replace a given old value.
     *
     * Does an identity operation (===) by default, but could be overridden to a
     * more complicated comparison operation, such as deep comparison, or even a
     * method which returns true when the new value has a higher "score" than
     * the old value (the new value might be different than the old value, but
     * less desirable).
     */
    protected shouldValueReplace(oldValue: Readonly<V>, newValue: Readonly<V>) {
        return oldValue === newValue;
    }

    /**
     * Get the new value that should be stored in the map if
     * {@link ObservableItemIDMap#shouldValueReplace} returns true.
     *
     * Returns the newValue parameter by default.
     */
    protected getReplacingValue(oldValue: Readonly<V>, newValue: Readonly<V>): V {
        return newValue;
    }

    /**
     * Deduplicates a given list of ID-value pairs, in-place. The most desirable
     * value is kept in the index of the first pair when a duplicate is
     * encountered (See {@link ObservableItemIDMap#shouldValueReplace} and
     * {@link ObservableItemIDMap#getReplacingValue} for details), keeping the
     * order of the array.
     *
     * This deduplication step is required for any collection method that sets
     * many ID-value pairs at the same time, otherwise duplicate update events
     * may be sent, or in the worst case scenario, inconsistent events may be
     * sent which put a listener into an invalid state (e.g. an update AND
     * remove event are sent at the same time because the collection method
     * expected the input ID-value pair list to only have unique IDs).
     *
     * Complexity is probably O(nlogn).
     */
    deduplicateIDValuePairs(idValuePairs: Array<readonly [id: string, value: V]>) {
        let iMax = idValuePairs.length;
        for (let i = 0; i < iMax; i++) {
            const iPair = idValuePairs[i];
            const id = iPair[0];
            let iValue = iPair[1];

            for (let j = iMax - 1; j > i; j--) {
                const jPair = idValuePairs[j];
                if (jPair[0] !== id) continue;

                const jValue = jPair[1];
                if (this.shouldValueReplace(iValue, jValue)) {
                    iValue = this.getReplacingValue(iValue, jValue);
                    idValuePairs[i] = [id, iValue];
                }

                idValuePairs.splice(j, 1);
                iMax--;
            }
        }
    }

    /**
     * Add a new item to the collection, or set the value of an existing item.
     *
     * @returns Returns true if a new item was added to the collection, or the mapped value changed
     */
    set(id: string, value: V) {
        if (this._has(id)) {
            const oldValue = this.values.get(id)!;
            if (this.shouldValueReplace(oldValue, value)) {
                this.values.set(id, this.getReplacingValue(oldValue, value));
                this.notify(ObservableItemIDCollectionEventType.Update, [id]);
                return true;
            } else {
                return false;
            }
        }

        this.ids.push(id);
        this.values.set(id, value);
        this.notify(ObservableItemIDCollectionEventType.Add, [id]);
        return true;
    }

    /**
     * Similar to {@link ObservableItemIDMap#set}, but takes in a list of ID and
     * value pairs, and doesn't return anything.
     *
     * @param idValuePairs A list of ID and value pairs to add to the collection. May contain duplicates. Note that the list may be mutated, but each item in the list is guaranteed to not be mutated
     */
    setMany(idValuePairs: Array<readonly [id: string, value: V]>) {
        this.deduplicateIDValuePairs(idValuePairs);

        const added: string[] = [];
        const updated: string[] = [];

        for (let i = idValuePairs.length - 1; i >= 0; i--) {
            const idValuePair = idValuePairs[i];
            const id = idValuePair[0];
            const value = idValuePair[1];

            if (this._has(id)) {
                const oldValue = this.values.get(id)!;
                if (this.shouldValueReplace(oldValue, value)) {
                    this.values.set(id, this.getReplacingValue(oldValue, value));
                    updated.push(id);
                }
            } else {
                this.ids.push(id);
                this.values.set(id, value);
                added.push(id);
            }
        }

        if (updated.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Update, updated);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    /**
     * Similar to {@link ObservableItemIDMap#set}, but uses a short ID instead
     * of a namespaced ID.
     */
    setShort(shortID: string, namespace: ItemNamespace, value: V) {
        return this.set(`${namespace}:${shortID}`, value);
    }

    /**
     * Similar to {@link ObservableItemIDMap#setMany}, but each ID in each pair
     * is a short ID instead of a namespaced ID.
     *
     * @param shortIDValuePairs A list of short ID and value pairs to add to the collection. May contain duplicates. Note that the list may be mutated, but each item in the list is guaranteed to not be mutated
     */
    setManyShort(shortIDValuePairs: Array<readonly [shortID: string, value: V]>, namespace: ItemNamespace) {
        this.deduplicateIDValuePairs(shortIDValuePairs);

        const added: string[] = [];
        const updated: string[] = [];

        for (let i = shortIDValuePairs.length - 1; i >= 0; i--) {
            const idValuePair = shortIDValuePairs[i];
            const id = `${namespace}:${idValuePair[0]}`;
            const value = idValuePair[1];

            if (this._has(id)) {
                const oldValue = this.values.get(id)!;
                if (this.shouldValueReplace(oldValue, value)) {
                    this.values.set(id, this.getReplacingValue(oldValue, value));
                    updated.push(id);
                }
            } else {
                this.ids.push(id);
                this.values.set(id, idValuePair[1]);
                added.push(id);
            }
        }

        if (updated.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Update, updated);
        }

        if (added.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Add, added);
        }
    }

    /**
     * Remove an item from the collection.
     *
     * @returns Returns true if an item was removed from the collection
     */
    remove(id: string) {
        const i = this.ids.indexOf(id);
        if (i < 0) return false;
        this.ids.splice(i, 1);
        this.values.delete(id);
        this.notify(ObservableItemIDCollectionEventType.Remove, [id]);
        return true;
    }

    /**
     * Similar to {@link ObservableItemIDMap#remove}, but takes in a list of
     * IDs, and doesn't return anything.
     */
    removeMany(ids: ReadonlyArray<string>) {
        const removed: string[] = [];
        for (let i = ids.length - 1; i >= 0; i--) {
            const id = ids[i];
            const idx = this.ids.indexOf(id);
            if (idx < 0) continue;
            this.ids.splice(idx, 1);
            this.values.delete(id);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }

    /** Remove all items from the collection with a given namespace. */
    removeNamespace(namespace: ItemNamespace) {
        const prefix = `${namespace}:`;
        const removed: string[] = [];
        for (let i = this.ids.length - 1; i >= 0; i--) {
            const id = this.ids[i];
            if (!id.startsWith(prefix)) continue;
            this.ids.splice(i, 1);
            this.values.delete(id);
            removed.push(id);
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }
    }

    /**
     * Replace all items from the collection with new ones, given as short IDs.
     * If a specific item already exists, it may have its value replaced. If an
     * item exists but is not in the given ID list, it will be removed. If an
     * item doesn't exist but is in the given ID list, it will be added.
     *
     * @param shortIDValuePairs A list of short ID and value pairs to replace the collection. May contain duplicates. Note that the list will be mutated, but each item in the list is guaranteed to not be mutated
     */
    replaceNamespace(shortIDValuePairs: Array<readonly [shortID: string, value: V]>, namespace: ItemNamespace, deduplicateInput = true) {
        if (deduplicateInput) this.deduplicateIDValuePairs(shortIDValuePairs);

        const prefix = `${namespace}:`;
        const prefixLen = prefix.length;
        const removed: string[] = [];
        const updated: string[] = [];
        let toAddCount = shortIDValuePairs.length;

        for (let i = this.ids.length - 1; i >= 0; i--) {
            const id = this.ids[i];
            if (!id.startsWith(prefix)) continue;

            const shortID = id.slice(prefixLen);
            let idx = 0;
            for (; idx < toAddCount; idx++) {
                if (shortIDValuePairs[idx][0] === shortID) break;
            }

            if (idx >= toAddCount) {
                this.ids.splice(i, 1);
                this.values.delete(id);
                removed.push(id);
            } else {
                const oldValue = this.values.get(id)!;
                const value = shortIDValuePairs[idx][1];

                shortIDValuePairs.splice(idx, 1);
                toAddCount--;

                if (this.shouldValueReplace(oldValue, value)) {
                    this.values.set(id, this.getReplacingValue(oldValue, value));
                    updated.push(id);
                }
            }
        }

        if (removed.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Remove, removed);
        }

        if (updated.length > 0) {
            this.notify(ObservableItemIDCollectionEventType.Update, updated);
        }

        if (toAddCount > 0) {
            const idsToAdd: string[] = [];
            for (let i = toAddCount - 1; i >= 0; i--) {
                const itemPair = shortIDValuePairs[i];
                const id = prefix + itemPair[0];
                this.ids.push(id);
                this.values.set(id, itemPair[1]);
                idsToAdd.push(id);
            }

            this.notify(ObservableItemIDCollectionEventType.Add, idsToAdd);
        }
    }
}