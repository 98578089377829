import { EventEmitter } from "events";
import { type EncryptedAudioSinkOptions } from "../encrypted-audio/encrypted-audio-sink-options.js";
import { AudioPlacement } from "./audio-placement.js";

const TMP_DIR_1 = new Float32Array(3);
const TMP_DIR_2 = new Float32Array(3);

// TODO make a library that unifies encrypted audio and voip audio sinks

export class AudioSink extends EventEmitter {
    private _context: AudioContext | null = null;
    private _node: GainNode | null = null;
    private autoResumeCallback: (() => void) | null = null;
    private contextOwned = false;
    private _volume = 1;
    protected _contextStateListener: (() => void) | null = null;
    placement: AudioPlacement;

    constructor(options?: EncryptedAudioSinkOptions) {
        super();

        this.placement = options?.placement ?? AudioPlacement.default();

        const context = options?.context;
        if (context) {
            this.context = context;
        }

        if (this._context === null && (options?.createContext ?? true)) {
            this.makeContext();
            this.contextOwned = true;
        }

        this.autoResume = options?.autoResume ?? true;

        if (options && options.ownContext !== undefined) {
            this.contextOwned = options.ownContext;
        }
    }

    tryResuming(): void {
        if (this._context && this._context.state !== "running") {
            this._context.resume().then(() => {
                if (this._context?.state === "running") {
                    console.debug("Audio context auto-resume success");
                } else {
                    console.debug("Audio context auto-resume fail");
                }
            }).catch(err => {
                console.debug("Audio context auto-resume fail (exception)");
                console.error(err);
            });
        }
    }

    makeContext() {
        this.context = new AudioContext();
    }

    private cleanupContext(context: AudioContext, contextStateListener: () => void) {
        if (contextStateListener) {
            context.removeEventListener("statechange", contextStateListener);
        }

        // TODO do we really need all these extra safety checks?
        if (this._context === context) {
            this._node!.disconnect();
            const oldNode = this._node!;
            this._node = null;
            this._contextStateListener = null;
            this._context = null;
            this.emit("unavailable", context, oldNode);
        }

        if (this.contextOwned && context.state !== "closed") {
            context.close();
        }
    }

    get context() {
        return this._context;
    }

    set context(context: AudioContext | null) {
        if (context && context.state === "closed") {
            context = null;
        }

        if (this._context === context) {
            return;
        }

        if (this._context) {
            this.cleanupContext(this._context, this._contextStateListener!);
        }

        if (context) {
            // XXX alias needed because typescript fails here
            const thisContext = context;
            this._context = thisContext;
            this._node = new GainNode(context);
            this._node.gain.value = this._volume;
            this._node.connect(context.destination);
            this._updateListenerPosition(thisContext);

            const contextStateListener = () => {
                if (thisContext.state === "closed") {
                    this.cleanupContext(thisContext, contextStateListener);
                }
            };
            this._contextStateListener = contextStateListener;
            thisContext.addEventListener("statechange", contextStateListener);

            this.emit("available", thisContext, this._node);
        }
    }

    get node() {
        return this._node;
    }

    async resume() {
        const ctx = this._context;
        if (!ctx) {
            throw new Error("Can't resume; no context set");
        }

        if (ctx.state === "running") {
            return;
        } else {
            await ctx.resume();

            // HACK typecast is necessary here, otherwise typescript gets
            //      confused with TS2367
            if (ctx.state as unknown !== "running") {
                throw new Error("Could not resume context");
            } else if (this._context === null) {
                throw new Error("Audio context abandoned by audio instance while resuming");
            } else if (ctx !== this._context) {
                throw new Error("Audio context changed in audio instance while resuming");
            } else {
                console.debug("Audio context resumed");
            }
        }
    }

    get autoResume(): boolean {
        return this.autoResumeCallback !== null;
    }

    set autoResume(autoResume: boolean) {
        if (this.autoResume === autoResume) {
            return;
        }

        // TODO should we listen to keyboard events?
        if (autoResume) {
            this.autoResumeCallback = this.tryResuming.bind(this);
            window.addEventListener("pointerdown", this.autoResumeCallback!);
        } else if (this.autoResumeCallback) {
            window.removeEventListener("pointerdown", this.autoResumeCallback);
            this.autoResumeCallback = null;
        }
    }

    dispose() {
        if (this._context) {
            this.cleanupContext(this._context, this._contextStateListener!);
        }

        this.autoResume = false;
    }

    private _updateListenerPosition(context: AudioContext) {
        const placement = this.placement;
        const listener = context.listener;

        const position = placement.position;
        if (listener.positionX) {
            listener.positionX.value = position[0];
            listener.positionY.value = position[1];
            listener.positionZ.value = position[2];
        } else {
            // HACK needed for firefox
            listener.setPosition(position[0], position[1], position[2]);
        }

        placement.getForward(TMP_DIR_1);
        placement.getUp(TMP_DIR_2);
        if (listener.forwardX) {
            listener.forwardX.value = TMP_DIR_1[0];
            listener.forwardY.value = TMP_DIR_1[1];
            listener.forwardZ.value = TMP_DIR_1[2];

            listener.upX.value = TMP_DIR_2[0];
            listener.upY.value = TMP_DIR_2[1];
            listener.upZ.value = TMP_DIR_2[2];
        } else {
            // HACK needed for firefox
            listener.setOrientation(TMP_DIR_1[0], TMP_DIR_1[1], TMP_DIR_1[2], TMP_DIR_2[0], TMP_DIR_2[1], TMP_DIR_2[2]);
        }
    }

    updatePlacement() {
        const context = this.context;
        if (context) {
            this._updateListenerPosition(context);
        }
    }

    get volume() {
        return this._volume;
    }

    set volume(volume: number) {
        if (isNaN(volume) || !isFinite(volume)) {
            // XXX despite needing the range [0,1], volume outside this range is
            //     clamped, no error is thrown for that
            throw new Error("Volume must be a valid, finite number between zero and one");
        } else if (volume < 0) {
            volume = 0;
        } else if (volume > 1) {
            volume = 1;
        }

        this._volume = volume;
        if (this._node) this._node.gain.value = volume;
    }
}