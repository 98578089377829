import { GameLocation, GameMode, VALID_CONFIGURATIONS } from "hoverfit-shared-netcode";
import { type BaseLeaderboard } from "./base-leaderboard.js";
import { HeyVRContestLeaderboard } from "./heyvr-contest-leaderboard.js";
import { HeyVRNumericLeaderboard } from "./heyvr-numeric-leaderboard.js";
import { TimeLeaderboardScore } from "./leaderboard-score.js";
import { Leaderboard } from "./leaderboard.js";

export const enum LeaderboardType {
    Global,
    Local,
    SquatContest,
}

export const LEADERBOARD_TYPE_MODULO = LeaderboardType.SquatContest + 1;
export const LEADERBOARD_TITLES = ["Global Leaderboard", "Local Leaderboard", "Squat Challenge"] as const;

export type LeaderboardIdentifier = {
    type: LeaderboardType.Global | LeaderboardType.Local,
    location: GameLocation,
    mode: GameMode.Race,
    track: string,
    lapsAmount?: number,
} | {
    type: LeaderboardType.SquatContest,
};

export class LeaderboardsManager {
    private leaderboards: BaseLeaderboard[] = [];
    private squatContestLeaderboard = new HeyVRContestLeaderboard("squat", "All locations and modes - Runs monthly", "Squats");
    /** Other mode leaderboards might need a different organization, for example lapsAmount do not exists in tag/roam */
    private raceLeaderboardsMap: Map<LeaderboardType.Global | LeaderboardType.Local, Map<GameLocation, Map<string, Map<number | null, BaseLeaderboard<TimeLeaderboardScore>>>>> = new Map();

    update(dt: number) {
        for (const leaderboard of this.leaderboards) {
            leaderboard.update(dt);
        }
    }

    getLeaderboard(id: LeaderboardIdentifier & { type: LeaderboardType.Global }): HeyVRNumericLeaderboard<TimeLeaderboardScore>
    getLeaderboard(id: LeaderboardIdentifier & { type: LeaderboardType.Local }): Leaderboard<TimeLeaderboardScore>
    getLeaderboard(id: LeaderboardIdentifier & { type: LeaderboardType.SquatContest }): HeyVRContestLeaderboard
    getLeaderboard(id: LeaderboardIdentifier): BaseLeaderboard
    getLeaderboard(id: LeaderboardIdentifier): BaseLeaderboard | null {
        let out: BaseLeaderboard | null = null;
        const type = id.type;

        if (type === LeaderboardType.Global || type === LeaderboardType.Local) {
            switch (id.mode) {
                case GameMode.Race: {
                    const location = id.location;
                    const track = id.track;
                    const lapsAmount = id.lapsAmount;
                    out = this.raceLeaderboardsMap.get(type)?.get(location)?.get(track)?.get(lapsAmount ?? null) ?? null;
                    if (!out) out = this.addRaceLeaderboard(type, location, track, lapsAmount);
                } break;
            }
        } else if (id.type === LeaderboardType.SquatContest) {
            out = this.squatContestLeaderboard;
        }

        return out;
    }

    private addRaceLeaderboard(local: LeaderboardType.Global, location: GameLocation, track: string, lapsAmount?: number): HeyVRNumericLeaderboard<TimeLeaderboardScore>
    private addRaceLeaderboard(local: LeaderboardType.Local, location: GameLocation, track: string, lapsAmount?: number): Leaderboard<TimeLeaderboardScore>
    private addRaceLeaderboard(local: LeaderboardType.Global | LeaderboardType.Local, location: GameLocation, track: string, lapsAmount?: number): Leaderboard<TimeLeaderboardScore> | HeyVRNumericLeaderboard<TimeLeaderboardScore>
    private addRaceLeaderboard(local: LeaderboardType.Global | LeaderboardType.Local, location: GameLocation, track: string, lapsAmount?: number): Leaderboard<TimeLeaderboardScore> | HeyVRNumericLeaderboard<TimeLeaderboardScore> {
        if (!this.raceLeaderboardsMap.has(local)) {
            this.raceLeaderboardsMap.set(local, new Map());
        }

        const locationMap = this.raceLeaderboardsMap.get(local)!;

        if (!locationMap.has(location)) {
            locationMap.set(location, new Map());
        }

        const trackMap = locationMap.get(location)!;

        if (!trackMap.has(track)) {
            trackMap.set(track, new Map());
        }

        const lapsAmountMap = trackMap.get(track)!;

        let locationName = 'Unknown location';
        let trackName = 'Unknown track';

        const locationConfig = VALID_CONFIGURATIONS.get(location);
        if (locationConfig) {
            locationName = locationConfig.name;
            const modeConfig = locationConfig.modes.get(GameMode.Race);
            if (modeConfig) {
                for (const trackConfig of modeConfig.tracks) {
                    if (trackConfig.id === track) {
                        trackName = trackConfig.name;
                        break;
                    }
                }
            }
        }

        const subtitle = `${locationName} - ${trackName} - ${local === LeaderboardType.Global ? "Fastest Lap" : `Laps: ${lapsAmount}`}`;

        const leaderboard = local === LeaderboardType.Local
            ? new Leaderboard(
                subtitle,
                "Time",
                TimeLeaderboardScore
            )
            : new HeyVRNumericLeaderboard(
                `${location}-${GameMode.Race}-${track}${lapsAmount != null ? `-laps-${lapsAmount}` : "-fastest-lap"}`,
                subtitle,
                "Time",
                TimeLeaderboardScore
            );
        lapsAmountMap.set(lapsAmount ?? null, leaderboard);

        this.leaderboards.push(leaderboard);

        return leaderboard;
    }
}