import { Variable, WidgetAutoXML, WidgetProperties, type Root, type Viewport, type Widget } from "lazy-widgets";
import { Carousel } from "./carousel.js";
import { DecoratedButton } from "./decorated-button.js";

export class OptionButton extends DecoratedButton {
    static override autoXML: WidgetAutoXML = {
        name: "option-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            },
            {
                mode: "value",
                name: "value",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    private ascendantCarousel: Carousel | null = null;

    constructor(name: string, readonly value: unknown, readonly variable: Variable<unknown>, properties?: Readonly<WidgetProperties>) {
        super(name, properties);

        this.forced = this.variable.value === this.value;

        this.on("click", () => {
            this.variable.value = this.value;
        });
    }

    override handlePostLayoutUpdate() {
        super.handlePostLayoutUpdate();

        if (this.ascendantCarousel && this.forced) {
            this.ascendantCarousel.slideToDescendant(this);
        }
    }

    private watchVariable = () => {
        const newHasBorder = this.variable.value === this.value;
        if (this.forced === newHasBorder) return;
        this.forced = newHasBorder;
        this.markWholeAsDirty();
    };

    protected override activate(): void {
        super.activate();
        this.variable.watch(this.watchVariable, true);
    }

    protected override deactivate(): void {
        this.variable.unwatch(this.watchVariable);
        super.deactivate();
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);

        let focus = this.parent;
        while (focus) {
            if (focus instanceof Carousel) {
                this.ascendantCarousel = focus;
                break;
            }
            focus = focus.parent;
        }
    }

    override detach(): void {
        super.detach();
        this.ascendantCarousel = null;
    }
}