import { type UnsafeDecodedSaveData } from "src/hoverfit/data/save-data.js";
import { other_1_parse_latest } from "./common/other_1_parse_latest.js";
import { pendingLeaderboardScores_parse_1to2 } from "./common/pendingLeaderboardScores_parse_1to2.js";
import { SaveDecoder } from "./common/save-decoder.js";

export class SaveDecoderV1 extends SaveDecoder {
    override decodeSaveData(rawData: any): UnsafeDecodedSaveData | null {
        if (rawData === null || (typeof rawData !== 'object')) return null;

        return {
            ...other_1_parse_latest(rawData),
            pendingLeaderboardScores: pendingLeaderboardScores_parse_1to2(rawData),
        };
    }
}