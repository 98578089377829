import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { AudioPlayer, AudioSetup } from "wle-pp";
import { common } from "../../../common.js";
import { AudioChannelName } from "../audio-channel.js";

export class AudioLoadComponent extends Component {
    static TypeName = "audio-load";

    @property.string()
    audioID!: string;

    @property.string()
    audioPath!: string;

    @property.float(1)
    volume!: number;

    @property.float(15)
    spatialReferenceDistance!: number;

    start() {
        const audioSetup = new AudioSetup(this.audioPath);
        audioSetup.myVolume = this.volume;
        audioSetup.myReferenceDistance = this.spatialReferenceDistance;
        common.audioManager.addSourceAudioToChannel(this.audioID, new AudioPlayer(audioSetup), AudioChannelName.SFX);
    }
}