import { PhysXComponent } from "@wonderlandengine/api";
import { MathUtils, PhysicsCollisionCollector, Timer } from "wle-pp";
import { EnvironmentalSoundActorComponent } from "./components/environmental-sound-actor-component.js";
import { EnvironmentalSoundActor } from "./environmental-sound-actor.js";
import { EnvironmentalSound } from "./environmental-sound.js";

export class EnvironmentalSoundArea {
    private sounds: EnvironmentalSound[] = [];

    private areaCollisionsCollector: PhysicsCollisionCollector;

    private minCooldownSeconds: number;
    private maxCooldownSeconds: number;

    private minDelayToPlaySeconds: number;
    private maxDelayToPlaySeconds: number;

    private currentActors: EnvironmentalSoundActor[] = [];

    private initialDelay: Timer = new Timer(5);

    private actorCooldownTimerMap: Map<EnvironmentalSoundActor, Timer> = new Map();
    private actorDelayToPlayTimerMap: Map<EnvironmentalSoundActor, Timer> = new Map();

    constructor(area: PhysXComponent, minCooldownSeconds: number, maxCooldownSeconds: number, minDelayToPlaySeconds: number, maxDelayToPlaySeconds: number) {
        this.minCooldownSeconds = minCooldownSeconds;
        this.maxCooldownSeconds = maxCooldownSeconds;

        this.minDelayToPlaySeconds = minDelayToPlaySeconds;
        this.maxDelayToPlaySeconds = maxDelayToPlaySeconds;

        this.areaCollisionsCollector = new PhysicsCollisionCollector(area);
    }

    setSounds(sounds: EnvironmentalSound[]) {
        this.sounds = sounds;
    }

    update(dt: number): EnvironmentalSoundActor[] | null {
        let envSoundActorsPlayed: EnvironmentalSoundActor[] | null = null;

        this.areaCollisionsCollector.update(dt);

        const collisionsStarted = this.areaCollisionsCollector.getCollisionsStarted();
        for (const collisionStarted of collisionsStarted) {
            const envSoundActorComponent = collisionStarted.object.pp_getComponentSelf(EnvironmentalSoundActorComponent);
            if (envSoundActorComponent != null) {
                this.currentActors.pp_pushUnique(envSoundActorComponent.getActor());
            }
        }

        const collisionsEnded = this.areaCollisionsCollector.getCollisionsEnded();
        for (const collisionEnded of collisionsEnded) {
            const envSoundActorComponent = collisionEnded.object.pp_getComponentSelf(EnvironmentalSoundActorComponent);
            if (envSoundActorComponent != null) {
                const envSoundActor = envSoundActorComponent.getActor();
                this.currentActors.pp_removeAllEqual(envSoundActor);
                this.stopActorDelayToPlayTimer(envSoundActor);
            }
        }

        this.initialDelay.update(dt);
        if (this.initialDelay.isDone()) {
            if (this.sounds.length > 0) {
                for (const cooldownTimer of this.actorCooldownTimerMap.values()) {
                    cooldownTimer.update(dt);
                }

                for (const currentActor of this.currentActors) {
                    const actorCooldownTimer = this.actorCooldownTimerMap.get(currentActor);
                    const actorDelayToPlayTimer = this.actorDelayToPlayTimerMap.get(currentActor);
                    if ((actorDelayToPlayTimer == null || !actorDelayToPlayTimer.isRunning()) && (actorCooldownTimer == null || actorCooldownTimer.isDone())) {
                        this.actorDelayToPlayTimerMap.set(currentActor, new Timer(MathUtils.random(this.minDelayToPlaySeconds, this.maxDelayToPlaySeconds)));
                    }
                }

                for (const [envSoundActor, delayToPlaytimer] of this.actorDelayToPlayTimerMap.entries()) {
                    delayToPlaytimer.update(dt);
                    if (delayToPlaytimer.isDone()) {
                        delayToPlaytimer.reset();

                        if (envSoundActor.canPlayAudio()) {
                            this.playSound(envSoundActor);
                        }

                        if (envSoundActorsPlayed == null) {
                            envSoundActorsPlayed = [];
                        }

                        envSoundActorsPlayed.push(envSoundActor);
                    }
                }
            }
        }

        return envSoundActorsPlayed;
    }

    playSound(envSoundActor: EnvironmentalSoundActor) {
        const randomSound = MathUtils.randomPick(this.sounds);
        if (randomSound != null) {
            randomSound.play(envSoundActor.getPosition());
        }
    }

    cooldownActor(envSoundActor: EnvironmentalSoundActor) {
        this.stopActorDelayToPlayTimer(envSoundActor);
        this.actorCooldownTimerMap.set(envSoundActor, new Timer(MathUtils.random(this.minCooldownSeconds, this.maxCooldownSeconds)));
    }

    private stopActorDelayToPlayTimer(envSoundActor: EnvironmentalSoundActor) {
        const actorDelayToPlayTimer = this.actorDelayToPlayTimerMap.get(envSoundActor);
        if (actorDelayToPlayTimer != null) {
            actorDelayToPlayTimer.reset();
        }
    }
}