export const HOVERBOARD_MULTIPLAYER_STATES = {
    RACE_PREPARATION: 0,
    RACE_STARTED: 1,
    RACE_ENDED: 2
} as const;

export const HOVERBOARD_PLAYER_MULTIPLAYER_STATES = {
    RACE_MENU: 0,
    RACE_RACETRACK_UNREADY: 1,
    RACE_RACETRACK_READY: 2,
    RACE_STARTED: 3,
    RACE_ENDED: 4,
    RACE_ENDED_CONTINUE: 5
} as const;