import { type Highscore } from "@heyvr/sdk-gameplay/types";
import { HeyVR } from "src/hoverfit/misc/heyvr-sdk-provider.js";
import { AnalyticsUtils, Timer } from "wle-pp";
import { BaseLeaderboard } from "./base-leaderboard.js";
import { type LeaderboardScore, type LeaderboardScoreCtor } from "./leaderboard-score.js";

const MODE_METHOD_NAME = ["get", "getMy"] as const;

export abstract class HeyVRLeaderboard<S extends LeaderboardScore<unknown>> extends BaseLeaderboard<S> {
    private fetchScoresDelays = [new Timer(5, false), new Timer(5, false)]; // Avoid too many heyvr requests
    private cachedScores: Highscore[][] = [[], []];

    override get unavailableMessage() {
        if (HeyVR) return null;
        return "This leaderboard is only available on HeyVR";
    }

    constructor(subtitle: string, scoreLabel: string, leaderboardScoreCtor: LeaderboardScoreCtor<S>) {
        super(subtitle, scoreLabel, leaderboardScoreCtor);
    }

    update(dt: number) {
        super.update(dt);

        if (!HeyVR) return;

        for (const delay of this.fetchScoresDelays) {
            delay.update(dt);
        }
    }

    protected abstract scoreToNumber(score: S["score"]): number;
    protected abstract scoreFromNumber(val: number): S["score"];
    protected abstract getLeaderboardID(score?: S["score"]): string;

    protected actuallySubmitScore(score: S["score"]): Promise<boolean> {
        return HeyVR!.leaderboard.postScore(this.getLeaderboardID(score), this.scoreToNumber(score));
    }

    async submitScore(score: S["score"]): Promise<this> {
        if (!HeyVR) return this;

        try {
            await this.actuallySubmitScore(score);
        } catch (error) {
            console.error(error);
            throw error;
        }

        for (const delay of this.fetchScoresDelays) {
            delay.reset();
        }

        AnalyticsUtils.sendEvent("learderboard_score_submit");

        return this;
    }

    submitNamedScore(_score: S["score"], _name: string): Promise<this> {
        return Promise.reject("Named score submission is not supported in HeyVR leaderboards");
    }

    async fetchScores(): Promise<this> {
        if (!HeyVR) return this;

        const mode = this.selectedMode;
        const delay = this.fetchScoresDelays[mode];
        let leaderboard: null | Highscore[] = null;

        if (delay.isRunning()) {
            leaderboard = this.cachedScores[mode];
        } else {
            try {
                leaderboard = await HeyVR!.leaderboard[MODE_METHOD_NAME[mode]](this.getLeaderboardID(), 20);
                this.cachedScores[mode] = leaderboard;
            } catch (error) {
                console.error(error);
            }

            delay.start();
        }

        try {
            if (leaderboard) {
                super.clearScores();

                for (const entry of leaderboard) {
                    if (entry != null) {
                        super.addScore(this.scoreFromNumber(entry.score), entry.user, entry.rank);
                    }
                }
            }
        } catch (error) {
            console.error(error);
        }

        return this;
    }

    override getAvailableModes(): string[] {
        return ["TOP 20", "NEAR ME"];
    }

    clearScores() {
        // Do nothing since it doesn't make sense to do this for heyvr
    }

    clearScoresOnNextSubmit() {
        // Do nothing since it doesn't make sense to do this for heyvr
    }

    removeScore(_name: string) {
        // Do nothing since it doesn't make sense to do this for heyvr
    }
}