export const INVALID_RECORD_VALUE = Symbol();

export function validateRecord<T, Default>(val: unknown, template: Record<string, [validator: (val: unknown, ...args: any[]) => unknown, ...validatorArgs: any[]]>, defaultVal: Default): T | Default {
    if (val === null || typeof val !== "object") return defaultVal;

    const output: Record<string, unknown> = {};
    for (const [key, validatorData] of Object.entries(template)) {
        const subVal = validatorData[0]((val as Record<string, unknown>)[key], ...validatorData.slice(1));
        if (subVal === INVALID_RECORD_VALUE) return defaultVal;
        output[key] = subVal;
    }

    return output as T;
}