import { vec3_create, Vector3 } from "wle-pp";
import { FollowForwardComponent } from "./follow-forward-component.js";
import { SyncedFollowForwardComponent } from "./synced-follow-forward-component.js";

export class SyncedLookAtComponent extends SyncedFollowForwardComponent {
    static override TypeName = "synced-look-at";

    protected static readonly __getTargetForwardSV =
        {
            targetObjectPosition: vec3_create(),
            currentPosition: vec3_create(),
            lookTo: vec3_create(),
        };
    protected _getTargetForward(): Vector3 {
        const targetForward = FollowForwardComponent._getTargetForwardSV.targetForward;
        this.targetObject.pp_getForward(targetForward);

        const targetObjectPosition = SyncedLookAtComponent.__getTargetForwardSV.targetObjectPosition;
        const currentPosition = SyncedLookAtComponent.__getTargetForwardSV.currentPosition;
        const lookTo = SyncedLookAtComponent.__getTargetForwardSV.lookTo;

        this.targetObject.pp_getPosition(targetObjectPosition);
        this.object.pp_getPosition(currentPosition);

        targetObjectPosition.vec3_sub(currentPosition, lookTo);
        lookTo.vec3_normalize(lookTo);

        return lookTo;
    }
}