import { Component, MeshComponent, Object3D, WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { FlatMaterial } from "wle-pp";
import { PhongMaterial } from "../../types/material-types.js";
import { seededRandom } from "../../utils/math-utils.js";
import { SelfMoveComponent } from "./self-move.js";

export class DecoSpawnerComponent extends Component {
    static TypeName = "deco-spawner";

    @property.object()
    SourceObject!: Object3D;

    @property.int(1)
    SpawneeCount: number = 1;

    private spawnList: Array<Object3D> = [];
    private seqIndex: number = 0;

    static onRegister(engine: WonderlandEngine) {
        engine.registerComponent(SelfMoveComponent);
    }

    start() {

    }

    seqRandom(s: number = Number.NEGATIVE_INFINITY) {
        if (s != Number.NEGATIVE_INFINITY) {
            this.seqIndex = s;
            return seededRandom(s);
        } else {
            return seededRandom(++this.seqIndex);
        }
    }

    update(dt: number) {
        if (this.spawnList.length == 0) {
            for (let i = 0; i < this.SpawneeCount; i++) {
                const fraci = i / this.SpawneeCount;
                const newObject = this.SourceObject.clone(this.object);
                this.spawnList.push(newObject);
                const ChildMesh = newObject.children[0].getComponent(MeshComponent);
                const newMaterial: PhongMaterial | null | undefined = ChildMesh?.material?.clone() as PhongMaterial | null;
                let colR = this.seqRandom(i); //Math.random();
                let colG = this.seqRandom(); //Math.random();
                let colB = this.seqRandom();  //Math.random();
                if (newMaterial) newMaterial.diffuseColor = [colR, colG, colB, 1];
                if (ChildMesh) ChildMesh.material = newMaterial;

                newObject.setPositionLocal([0, 0, 0]);
                newObject.translateLocal([12 * (this.seqRandom() - 0.5), 1 + i * 4, 500 * ((fraci - 0.5) * 1)]);
                const sx = 1 + this.seqRandom() * 3;
                const sy = sx * (1 + 0.2 * this.seqRandom());
                const sz = sx * (1 + 3 * this.seqRandom());
                newObject.setScalingLocal([sx, sy, sz]);
                const newObjectChild = newObject.children[0].children[0];
                const sc = 1.5;

                newObjectChild.setScalingLocal([sx * sc / sx, sz * sc / sy, sy * sc / sz]);
                const GrandchildMesh = newObject.children[0].children[0].getComponent(MeshComponent);
                const newGrandchildMaterial: FlatMaterial | null | undefined = GrandchildMesh?.material?.clone() as FlatMaterial | null;
                let colMax = Math.max(colR, colG, colB);
                if (colMax == 0) colMax = 1;
                colR = colR / colMax;
                colG = colG / colMax;
                colB = colB / colMax;
                if (newGrandchildMaterial) newGrandchildMaterial.color = [colR, colG, colB, 1];
                if (GrandchildMesh) GrandchildMesh.material = newGrandchildMaterial;

                ///newObject.rotateAxisAngleDegObject([0, 1, 0], this.seqRandom() * 360);
                newObject.rotateAxisAngleDegObject([0, 1, 0], (this.seqRandom() < 0.5) ? 0 : 180);
                const newMove = newObject.addComponent(SelfMoveComponent);
                if (newMove) {
                    newMove!.setSpeed(5 * (5 + 10 * this.seqRandom()));
                    const sign = ((this.seqRandom()) < 0.5) ? 1 : -1;
                    newMove!.turn = (5 + 10 * this.seqRandom()) * sign;
                }

            }
        }
    }
}
