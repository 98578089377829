import { Container, Icon, Label, Spacing } from "lazy-widgets";
import { WLRoot } from "lazy-widgets-wle";
import { Book } from "../lazy-widgets/widgets/book.js";
import { DecoratedButton } from "../lazy-widgets/widgets/decorated-button.js";
import { IconDecoratedButton } from "../lazy-widgets/widgets/icon-decorated-button.js";

export class UICustomPopupHelperParams {
    title: string = "";
    titleFontSize: number = 2.4;
    titleIconPath: string = "";
    titleIconSpacing: number = 0;
    message: string = "";
    messageFontSize: number = 1.1;
    messageFontSpacing: number | null = 4;
    primaryButtonText: string = "";
    secondaryButtonText: string = "";
    lowPriorityButtonText: string = "";
    primaryButtonWidth: number | null = null;
    primaryButtonClickCallback: (() => void) | null = null;
    secondaryButtonClickCallback: (() => void) | null = null;
    lowPriorityButtonClickCallback: (() => void) | null = null;
    enableCloseButton: boolean = true;
    closeButtonClickCallback: (() => void) | null = null;
}

export class UICustomPopupHelper {
    readonly titleLabel: Label;
    readonly titleIcon: Icon;
    readonly titleIconSpacing: Spacing;
    readonly messageLabel: Label;
    readonly primaryButton: DecoratedButton;
    readonly secondaryButton: DecoratedButton;
    readonly lowPriorityButtonContainer: Container;
    readonly lowPriorityButton: DecoratedButton;
    readonly closeButtonBook: Book;
    readonly closeButton: IconDecoratedButton;

    readonly closeButtonClickCallbackDefault: () => void;

    primaryButtonClickCallback: (() => void) | null = null;
    secondaryButtonClickCallback: (() => void) | null = null;
    lowPriorityButtonClickCallback: (() => void) | null = null;
    closeButtonClickCallback: (() => void) | null = null;

    protected defaultLargeHighPriorityButtonWidth: number = 150;
    protected defaultSmallHighPriorityButtonWidth: number = 120;
    protected defaultLargeLowPriorityButtonWidth: number = 120;
    protected defaultSmallLowPriorityButtonWidth: number = 80;

    constructor(root: WLRoot, closeButtonClickCallbackDefault: () => void) {
        this.titleLabel = root.getWidgetByID("custom-popup-title") as Label;
        this.titleIcon = root.getWidgetByID("custom-popup-title-icon") as Icon;
        this.titleIconSpacing = root.getWidgetByID("custom-popup-title-icon-spacing") as Spacing;
        this.messageLabel = root.getWidgetByID("custom-popup-message") as Label;

        this.primaryButton = root.getWidgetByID("custom-popup-primary-button") as DecoratedButton;
        this.secondaryButton = root.getWidgetByID("custom-popup-secondary-button") as DecoratedButton;
        this.lowPriorityButton = root.getWidgetByID("custom-popup-low-priority-button") as DecoratedButton;
        this.primaryButton.on("click", this.primaryButtonClick.bind(this));
        this.secondaryButton.on("click", this.secondaryButtonClick.bind(this));
        this.lowPriorityButton.on("click", this.lowPriorityButtonClick.bind(this));

        this.lowPriorityButtonContainer = root.getWidgetByID("custom-popup-low-priority-button-container") as Container;

        this.closeButtonBook = root.getWidgetByID("custom-popup-close-button-book") as Book;
        this.closeButton = root.getWidgetByID("custom-popup-close-button") as IconDecoratedButton;
        this.closeButton.on("click", this.closeButtonClick.bind(this));

        this.closeButtonClickCallbackDefault = closeButtonClickCallbackDefault;

        this.reset();
    }

    setupPopup(params: UICustomPopupHelperParams) {
        this.reset();

        this.titleLabel.text = params.title;
        this.titleLabel.bodyTextFont = `${params.titleFontSize}em sui-generis`;

        if (params.titleIconPath.length > 0) {
            (this.titleIcon.image as HTMLImageElement).src = params.titleIconPath;
            this.titleIcon.enabled = true;
        }

        this.titleIconSpacing.minWidth = params.titleIconSpacing;
        this.titleIconSpacing.maxWidth = params.titleIconSpacing;
        this.titleIconSpacing.enabled = params.titleIconSpacing > 0;

        this.messageLabel.text = params.message;
        this.messageLabel.bodyTextFont = `${params.messageFontSize}em sui-generis`;
        if (params.messageFontSpacing != null) {
            this.messageLabel.bodyTextSpacing = params.messageFontSpacing;
        }

        this.primaryButton.enabled = params.primaryButtonText.length > 0;
        this.primaryButton.label.text = params.primaryButtonText;
        this.primaryButtonClickCallback = params.primaryButtonClickCallback;

        this.secondaryButton.enabled = params.secondaryButtonText.length > 0;
        this.secondaryButton.label.text = params.secondaryButtonText;
        this.secondaryButtonClickCallback = params.secondaryButtonClickCallback;

        this.lowPriorityButton.enabled = params.lowPriorityButtonText.length > 0;
        this.lowPriorityButton.label.text = params.lowPriorityButtonText;
        this.lowPriorityButtonClickCallback = params.lowPriorityButtonClickCallback;
        this.lowPriorityButtonContainer.enabled = this.lowPriorityButton.enabled;

        if (this.primaryButton.enabled && this.secondaryButton.enabled && this.lowPriorityButton.enabled) {
            this.primaryButton.minWidth = this.defaultSmallHighPriorityButtonWidth;
            this.primaryButton.maxWidth = this.defaultSmallHighPriorityButtonWidth;

            this.secondaryButton.minWidth = this.defaultSmallHighPriorityButtonWidth;
            this.secondaryButton.maxWidth = this.defaultSmallHighPriorityButtonWidth;

            this.lowPriorityButtonContainer.containerPadding = { left: 0, right: 0, top: 1.5, bottom: 0 };
            this.lowPriorityButtonContainer.minWidth = this.defaultSmallLowPriorityButtonWidth;
            this.lowPriorityButtonContainer.maxWidth = this.defaultSmallLowPriorityButtonWidth;
            this.lowPriorityButton.minWidth = this.defaultSmallLowPriorityButtonWidth;
            this.lowPriorityButton.maxWidth = this.defaultSmallLowPriorityButtonWidth;
        } else {
            this.primaryButton.minWidth = this.defaultLargeHighPriorityButtonWidth;
            this.primaryButton.maxWidth = this.defaultLargeHighPriorityButtonWidth;

            this.secondaryButton.minWidth = this.defaultLargeHighPriorityButtonWidth;
            this.secondaryButton.maxWidth = this.defaultLargeHighPriorityButtonWidth;

            this.lowPriorityButtonContainer.containerPadding = { left: 20, right: 10, top: 1.5, bottom: 0 };
            this.lowPriorityButtonContainer.minWidth = this.defaultLargeHighPriorityButtonWidth;
            this.lowPriorityButtonContainer.maxWidth = this.defaultLargeHighPriorityButtonWidth;
            this.lowPriorityButton.minWidth = this.defaultLargeLowPriorityButtonWidth;
            this.lowPriorityButton.maxWidth = this.defaultLargeLowPriorityButtonWidth;
        }

        if (params.primaryButtonWidth != null) {
            this.primaryButton.minWidth = params.primaryButtonWidth;
            this.primaryButton.maxWidth = params.primaryButtonWidth;

            this.primaryButton.label.minWidth = params.primaryButtonWidth;
            this.primaryButton.label.maxWidth = params.primaryButtonWidth;
        }

        this.setCloseButtonEnabled(params.enableCloseButton);
        this.closeButtonClickCallback = params.closeButtonClickCallback ?? this.closeButtonClickCallbackDefault;
    }

    reset() {
        this.titleIcon.enabled = false;
        this.titleIconSpacing.enabled = false;

        this.primaryButton.enabled = false;
        this.secondaryButton.enabled = false;

        this.primaryButtonClickCallback = null;
        this.secondaryButtonClickCallback = null;

        this.setCloseButtonEnabled(true);
    }

    setCloseButtonEnabled(enabled: boolean) {
        this.closeButtonBook.changePage(enabled ? 0 : 1);
    }

    private primaryButtonClick() {
        if (this.primaryButtonClickCallback != null) {
            this.primaryButtonClickCallback();
        }
    }

    private secondaryButtonClick() {
        if (this.secondaryButtonClickCallback != null) {
            this.secondaryButtonClickCallback();
        }
    }

    private lowPriorityButtonClick() {
        if (this.lowPriorityButtonClickCallback != null) {
            this.lowPriorityButtonClickCallback();
        }
    }

    private closeButtonClick() {
        if (this.closeButtonClickCallback != null) {
            this.closeButtonClickCallback();
        }
    }
}