import { Component, Emitter, MeshComponent, Object3D, TextComponent } from "@wonderlandengine/api";
import { Cursor } from "@wonderlandengine/components";
import anime from "animejs";
import { GameMode, gameConfigChangeRequiresReload } from "hoverfit-shared-netcode";
import { HoverboardGameConfig, HoverboardGameConfigJSON, HoverboardGameOnlineConfigJSON } from "src/hoverfit/data/game-configuration.js";
import { validateInt } from "src/hoverfit/data/validation/validate-int.js";
import { RoomData } from "src/hoverfit/network/components/hoverboard-networking-component.js";
import { FlatMaterial, PhongMaterial } from "src/hoverfit/types/material-types.js";
import { PauseState } from "src/hoverfit/ui/xml-ui/components/pause-menu-component.js";
import { PauseMenuCustomPopupHelperParams } from "src/hoverfit/ui/xml-ui/pause-menu-custom-popup-helper.js";
import { replaceSearchParams } from "src/hoverfit/utils/url-utils.js";
import { AnalyticsUtils, BrowserUtils, FSM, FramesCountdownState, GamepadUtils, Globals, MaterialUtils, MathUtils, Timer, TimerState, Vector3, Vector4, XRUtils, quat2_create, vec3_create, vec4_create } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { common } from "../../common.js";
import { GameGlobals } from "../../misc/game-globals.js";
import { getTime } from "../../utils/time-utils.js";
import { GAME_STATES } from "../game-states.js";
import { HoverboardDebugs } from "./hoverboard-debugs-component.js";

// XXX persistent, don't put in the common object
export let skipIntroOnNextLoad = false;
export let skipIntroChecksOnNextLoad = false;

let _xrButtonsShown = false;

export class IntroComponent extends Component {
    static TypeName = "intro";

    // Using 2 spheres, one occluding everything and one not, so that in the first part of the intro we can use
    // UIs and whatever in 3D without the need to have it ignore depth, and only use the ignore depth sphere when playing the intro,
    // that we want to fade/blend with the game as it fades out
    private _backgroundSphere!: Object3D;
    private _backgroundSphereMeshComponent!: MeshComponent;

    private _backgroundOccludeSphere!: Object3D;

    private _hoverfitLogo!: Object3D;
    private _hoverfitLogoMeshComponent!: MeshComponent;

    private _pressAnyButton!: Object3D;
    private _pressAnyButtonTextComponent!: TextComponent;

    private _gameVersion!: Object3D;

    private _hoverfitLogoPosition: Vector3 = vec3_create();
    private _hoverfitLogoZStart: number = 0;

    private _fogColor: Vector4 = vec4_create();

    private _firstUpdateDone: boolean = false;

    private _firstXRButtonsUpdateDone: boolean = false;

    private _xrButtonsContainer: HTMLElement | null = null;
    private _vrButton: HTMLElement | null = null;
    private _arButton: HTMLElement | null = null;

    private _done: boolean = false;

    private _fsm: FSM = new FSM();

    private _allCursorComponents!: Cursor[];
    private _disableCursorsOnNextUpdate: boolean = false;

    private _waitMicrophonePermissionDone: boolean = false;

    private _checksSectionDone: boolean = false;

    private _setupOnlineModeUpdateDelay: Timer = new Timer(0.5);

    private _introMusicDelay: Timer = new Timer(0.5, false);
    private _introAnim: anime.AnimeInstance | null = null;
    private _fadeInAnim: anime.AnimeInstance | null = null;
    private _manualFadeOutAnim: anime.AnimeInstance | null = null;

    private _pressAnyButtonDelay: Timer = new Timer(0.5);

    private _cursorComponentsActiveBackup: Map<Cursor, boolean> = new Map();

    private _syncTransformWithHeadCountdown: number = 0;

    private _gameReadyCallbacks: Map<string, () => boolean> = new Map();
    private _gameReadyFlags: Map<string, boolean> = new Map();
    private _gameReadyEmitter: Emitter = new Emitter();

    private _delayIntro: boolean = false;

    private _gameReadyMinTimer = new Timer(1, false);
    private _gameReadyWaitTimerState = new TimerState(0, "done");

    private static readonly _fadeDuration: number = 600;
    private static readonly _hoverfitLogoZEnd: number = -10.0;

    init(): void {
        common.intro = this;

        MaterialUtils.setObjectClonedMaterials(this.object);

        this._backgroundSphere = this.object.pp_getObjectByName("Background Sphere")!;
        this._backgroundOccludeSphere = this.object.pp_getObjectByName("Background Occlude Sphere")!;
        this._hoverfitLogo = this.object.pp_getObjectByName("HoverFit Logo")!;
        this._pressAnyButton = this.object.pp_getObjectByName("Press Any Button")!;
        this._gameVersion = this.object.pp_getObjectByName("Game Version")!;

        this._backgroundSphereMeshComponent = this._backgroundSphere.pp_getComponent(MeshComponent)!;
        this._hoverfitLogoMeshComponent = this._hoverfitLogo.pp_getComponent(MeshComponent)!;
        this._pressAnyButtonTextComponent = this._pressAnyButton.pp_getComponent(TextComponent)!;

        this._pressAnyButtonTextComponent.text = "PRESS VR TO ENTER THE GAME";

        this._backgroundSphere.pp_setActive(false);
        this._backgroundOccludeSphere.pp_setActive(false);
        this._hoverfitLogo.pp_setActive(false);
        this._pressAnyButton.pp_setActive(false);
        this._gameVersion.pp_setActive(false);

        this._backgroundSphere.pp_setScale(100);
        this._backgroundOccludeSphere.pp_setScale(100);

        this._hoverfitLogo.getPositionLocal(this._hoverfitLogoPosition);
        this._hoverfitLogoZStart = this._hoverfitLogoPosition[2];

        // Color of fog for appearing effect
        this._fogColor.vec4_copy((this._backgroundSphereMeshComponent.material as FlatMaterial).color);
        (this._hoverfitLogoMeshComponent.material as PhongMaterial).fogColor = this._fogColor;

        this._xrButtonsContainer = document.getElementById("xr-buttons-container");
        this._vrButton = document.getElementById("vr-button");
        this._arButton = document.getElementById("ar-button");

        let locomotionIdleSetDone = false;
        this._gameReadyCallbacks.set("Locomotion", () => {
            const playerLocomotion = Globals.getPlayerLocomotion();
            if (playerLocomotion != null && playerLocomotion.isStarted()) {
                if (!locomotionIdleSetDone) {
                    playerLocomotion.setIdle(true);

                    const playerTransformManager = playerLocomotion.getPlayerTransformManager();
                    playerTransformManager.forceTeleportAndReset(vec3_create(0, -1000, 0), quat2_create());

                    this._synTransformWithHead();
                    this._syncTransformWithHeadCountdown = 2;

                    locomotionIdleSetDone = true;
                }

                return true;
            }

            return false;
        });

        let popupManagerDeactivated = false;
        this._gameReadyCallbacks.set("Popup Manager", () => {
            if (common.popupManager != null) {
                if (!popupManagerDeactivated) {
                    common.popupManager.setActive(false);
                    popupManagerDeactivated = true;
                }

                return true;
            }

            return false;
        });

        let loadingPopupShown = false;
        this._gameReadyCallbacks.set("Pause Menu", () => {
            if (common.pauseMenu != null && common.pauseMenu.isReady()) {
                if (!loadingPopupShown) {
                    const skipIntro = skipIntroOnNextLoad || skipIntroChecksOnNextLoad || (HoverboardDebugs.debugStartedEnabled && HoverboardDebugs.skipIntro);
                    if (!skipIntro) {
                        this._gameReadyMinTimer.start();

                        this._setPlayerVisible(true);
                        common.pauseMenu.showLoadingPopup();
                    }

                    loadingPopupShown = true;
                }

                return true;
            }

            return false;
        });

        this._gameReadyCallbacks.set("Network", () => !!common.hoverboardNetworking);
        this._gameReadyCallbacks.set("VoIP", () => !!common.hoverboardNetworking.voip);
        this._gameReadyCallbacks.set("Menu", () => common.menu.ready);
        this._gameReadyCallbacks.set("Tracks", () => common.tracksManager.areAllTracksReady());
        this._gameReadyCallbacks.set("Player Data", () => common.playerData.isFirstLoadDataDone());
        this._gameReadyCallbacks.set("Avatar", () => !!common.playerData.avatar!.isReady());
        this._gameReadyCallbacks.set("Game Config", () => common.gameConfig.isReady());
        this._gameReadyCallbacks.set("Kiosk Upper", () => common.kioskUpperUI.ready);
        this._gameReadyCallbacks.set("Kiosk Lower", () => common.kioskLowerUI.ready);
        this._gameReadyCallbacks.set("IAP", () => common.iapContentController.ready != null);
        this._gameReadyCallbacks.set("Config Avatar", () => !!common.kioskController.configAvatarComponent!.isReady());
        this._gameReadyCallbacks.set("Network Players", () => !!common.networkPlayerPool.ready);

        this._setupFSM();
    }

    start(): void {
        this._allCursorComponents = Globals.getRootObject()!.pp_getComponents(Cursor);
        for (const cursorComponent of this._allCursorComponents) {
            this._cursorComponentsActiveBackup.set(cursorComponent, cursorComponent.active);
        }

        if (BrowserUtils.isMobile() || (BrowserUtils.isDesktop() && !XRUtils.isVRSupported())) {
            this._pressAnyButtonTextComponent.text = "PRESS ANY BUTTON TO BEGIN";
        }

        this._fsm.perform("start");

        XRUtils.registerSessionStartEndEventListeners(this, this._onXRSessionStart.bind(this), this._onXRSessionEnd.bind(this), true);
    }

    update(dt: number): void {
        if (!this._firstUpdateDone) {
            this._firstUpdateDone = true;
        } else {
            // This can be updates since the game start, since it's a delay from the load and not from when the setup online mode state is reached
            this._setupOnlineModeUpdateDelay.update(dt);

            if (this._syncTransformWithHeadCountdown > 0) {
                this._syncTransformWithHeadCountdown--;
                if (this._syncTransformWithHeadCountdown == 0) {
                    this._synTransformWithHead(); // This delay is needed to await for the first frames to have been processed so that the head position is valid
                }
            }

            if (this._disableCursorsOnNextUpdate) {
                this._disableCursorsOnNextUpdate = false;

                this._setCursorsEnabled(false);
            }

            if (this._checksSectionDone) {
                this._updateXRButtons();
            }

            this._fsm.update(dt);
        }
    }

    isDone(): boolean {
        return this._done;
    }

    isFadingIn(): boolean {
        return this._fsm.isInState("fade_in");
    }

    async manualFadeOut(): Promise<void> {
        while (!this._fsm.canPerform("manual_fade_out")) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }

        this._fsm.perform("manual_fade_out");

        while (!this._fsm.isInState("faded_out")) {
            await new Promise(resolve => setTimeout(resolve, 100));
        }
    }

    isFadedOut(): boolean {
        return this._fsm.isInState("faded_out");
    }

    isManuallyFadingOut(): boolean {
        return this._fsm.isInState("manual_fade_out");
    }

    cancelManualFadeOut(): void {
        this._fsm.perform("cancel_fade_out");
    }

    forceOccluderSphere(): void {
        this._backgroundOccludeSphere.pp_setActive(true);
    }

    private _setupFSM(): void {
        //this._fsm.setLogEnabled(true, "Intro");

        // Init
        this._fsm.addState("init");
        this._fsm.addState("skip_first_frames", new FramesCountdownState(2, "done"));
        this._fsm.addState("wait_game_ready", this._waitGameReadyUpdate.bind(this));
        this._fsm.addState("wait_game_ready_wait", this._gameReadyWaitTimerState);
        this._fsm.addState("check_skip_checks", this._checkSkipChecksUpdate.bind(this));

        // Permissions
        this._fsm.addState("check_ask_permissions", { update: this._checkAskPermissionsUpdate.bind(this) });
        this._fsm.addState("ask_permissions", { update: this._askPermissionsUpdate.bind(this), start: this._askPermissionsStart.bind(this) });
        this._fsm.addState("wait_microphone_permission", { update: this._waitMicrophonePermissionUpdate.bind(this), start: this._waitMicrophonePermissionStart.bind(this) });
        this._fsm.addState("permissions_denied", { start: this._permissionsDeniedStart.bind(this) });

        // Game Config
        this._fsm.addState("check_ask_game_mode", this._checkAskGameModeUpdate.bind(this));
        this._fsm.addState("ask_game_mode", { start: this._askGameModeStart.bind(this) });
        this._fsm.addState("check_change_config", { update: this._checkChangeConfigUpdate.bind(this) });
        this._fsm.addState("change_config", { start: this._changeConfigStart.bind(this) });
        this._fsm.addState("change_config_error", new TimerState(3, "done"));
        this._fsm.addState("setup_online_mode", { update: this._setupOnlineModeUpdate.bind(this), start: () => { common.pauseMenu.setState(PauseState.MAYBE_CLOSE); } });

        // Play intro
        this._fsm.addState("check_skip_play_intro", { update: this._checkSkipPlayIntroUpdate.bind(this), start: () => { common.pauseMenu.setState(PauseState.MAYBE_CLOSE); this._checksCompleted(); this._preparePlayIntro(); } });
        this._fsm.addState("wait_play_intro", new TimerState(0.5, "done"));
        this._fsm.addState("wait_skip_intro", new TimerState(0.25, "done"));
        this._fsm.addState("play_intro", { update: this._playIntroUpdate.bind(this), start: this._playIntroStart.bind(this) });
        this._fsm.addState("fade_in", { update: this._fadeInUpdate.bind(this), start: this._fadeInStart.bind(this) });
        this._fsm.addState("quick_fade_in", { update: this._fadeInUpdate.bind(this), start: this._quickFadeInStart.bind(this) });
        this._fsm.addState("hidden");

        // Manual Fade Out
        this._fsm.addState("manual_fade_out", { update: this._manualFadeOutUpdate.bind(this), start: this._manualFadeOutStart.bind(this) });
        this._fsm.addState("faded_out");



        // Init
        this._fsm.addTransition("init", "skip_first_frames", "start", this._start.bind(this));
        this._fsm.addTransition("skip_first_frames", "wait_game_ready", "done");
        this._fsm.addTransition("wait_game_ready", "wait_game_ready_wait", "done_wait");
        this._fsm.addTransition("wait_game_ready", "check_skip_checks", "done");
        this._fsm.addTransition("wait_game_ready_wait", "check_skip_checks", "done");
        this._fsm.addTransition("check_skip_checks", "setup_online_mode", "skip_checks");
        this._fsm.addTransition("check_skip_checks", "check_skip_play_intro", "skip_checks_permissions_denied");
        this._fsm.addTransition("check_skip_checks", "check_ask_permissions", "dont_skip_checks");

        // Permissions
        this._fsm.addTransition("check_ask_permissions", "ask_permissions", "ask_permissions");
        this._fsm.addTransition("check_ask_permissions", "check_ask_game_mode", "permissions_granted");
        this._fsm.addTransition("check_ask_permissions", "check_ask_game_mode", "permissions_denied");
        this._fsm.addTransition("ask_permissions", "check_ask_game_mode", "permissions_granted");
        this._fsm.addTransition("ask_permissions", "wait_microphone_permission", "ask_microphone");
        this._fsm.addTransition("ask_permissions", "check_ask_game_mode", "permissions_denied");
        this._fsm.addTransition("ask_permissions", "check_skip_play_intro", "permissions_denied_skip_game_mode");
        this._fsm.addTransition("wait_microphone_permission", "check_ask_game_mode", "permissions_granted");
        this._fsm.addTransition("wait_microphone_permission", "check_ask_game_mode", "permissions_denied");
        this._fsm.addTransition("permissions_denied", "check_ask_game_mode", "done");

        // Game Config
        this._fsm.addTransition("check_ask_game_mode", "ask_game_mode", "ask_game_mode");
        this._fsm.addTransition("check_ask_game_mode", "check_change_config", "game_mode_already_specified");
        this._fsm.addTransition("ask_game_mode", "check_change_config", "done", this._askGameModeDone.bind(this));
        this._fsm.addTransition("check_change_config", "change_config", "change_config");
        this._fsm.addTransition("check_change_config", "setup_online_mode", "config_is_ok");
        this._fsm.addTransition("change_config", "setup_online_mode", "done");
        this._fsm.addTransition("setup_online_mode", "check_skip_play_intro", "done");

        // Play intro
        this._fsm.addTransition("check_skip_play_intro", "wait_play_intro", "play_intro_with_delay");
        this._fsm.addTransition("check_skip_play_intro", "wait_skip_intro", "skip_intro");
        this._fsm.addTransition("check_skip_play_intro", "play_intro", "play_intro");
        this._fsm.addTransition("wait_play_intro", "play_intro", "done");
        this._fsm.addTransition("wait_skip_intro", "fade_in", "done");
        this._fsm.addTransition("play_intro", "fade_in", "done");
        this._fsm.addTransition("fade_in", "hidden", "done", this._introDone.bind(this));

        // Manual Fade Out
        this._fsm.addTransition("change_config", "faded_out", "manual_fade_out", this._cancelIntro.bind(this));
        this._fsm.addTransition("wait_play_intro", "faded_out", "manual_fade_out", this._cancelIntro.bind(this));
        this._fsm.addTransition("play_intro", "faded_out", "manual_fade_out", this._cancelIntro.bind(this));
        this._fsm.addTransition("hidden", "manual_fade_out", "manual_fade_out");

        this._fsm.addTransition("manual_fade_out", "change_config_error", "cancel_change_config");
        this._fsm.addTransition("faded_out", "change_config_error", "cancel_change_config");
        this._fsm.addTransition("change_config_error", "setup_online_mode", "done");

        this._fsm.addTransition("manual_fade_out", "quick_fade_in", "cancel_fade_out", this._cancelFadeOut.bind(this));
        this._fsm.addTransition("faded_out", "quick_fade_in", "cancel_fade_out", this._cancelFadeOut.bind(this));
        this._fsm.addTransition("quick_fade_in", "hidden", "done", this._introDone.bind(this));

        this._fsm.addTransition("manual_fade_out", "faded_out", "done");



        this._fsm.init("init");
    }

    private _start(): void {
        this._synTransformWithHead();
        this._syncTransformWithHeadCountdown = 2;

        this._setPlayerVisible(false);
    }

    private _waitGameReadyUpdate(dt: number): void {
        this._gameReadyMinTimer.update(dt);

        let gameReady = true;
        for (const [key, callbak] of this._gameReadyCallbacks.entries()) {
            if (!this._gameReadyFlags.has(key)) {
                if (!callbak()) {
                    gameReady = false;
                } else {
                    this._gameReadyFlags.set(key, true);
                    console.debug(key + " - ready");
                }
            }
        }

        if (gameReady) {
            common.gameReady = true;
            this._gameReadyEmitter.notify();

            if (skipIntroOnNextLoad || skipIntroChecksOnNextLoad || (HoverboardDebugs.debugStartedEnabled && HoverboardDebugs.skipIntro) || this._gameReadyMinTimer.isDone()) {
                this._fsm.perform("done");
            } else {
                this._gameReadyWaitTimerState.setDuration(this._gameReadyMinTimer.getTimeLeft());
                this._fsm.perform("done_wait");
            }
        }
    }

    registerGameReadyEventListener(id: unknown, listener: () => void): void {
        this._gameReadyEmitter.add(listener, { id: id });
    }

    unregisterGameReadyEventListener(id: unknown): void {
        this._gameReadyEmitter.remove(id);
    }

    private _checkSkipChecksUpdate(dt: number): void {
        if (skipIntroOnNextLoad || skipIntroChecksOnNextLoad || (HoverboardDebugs.debugStartedEnabled && HoverboardDebugs.skipIntro)) {
            if (common.playerData.gameSettings.policyAccepted.value) {
                this._fsm.perform("skip_checks");
            } else {
                common.MAIN_CHANNEL.emit("room-no-autojoin");
                this._fsm.perform("skip_checks_permissions_denied");
            }
        } else {
            this._fsm.perform("dont_skip_checks");
        }

        skipIntroChecksOnNextLoad = true;
    }

    private _preparePlayIntro(): void {
        common.balcony.moveToBalcony();

        this._synTransformWithHead();
        this._syncTransformWithHeadCountdown = 2;

        this._setPlayerVisible(false);
    }

    private _checkAskPermissionsUpdate(dt: number): void {
        if (common.hoverboardNetworking.microphonePermission!.value != null) {
            if (common.playerData.gameSettings.policyAccepted.value == null || common.hoverboardNetworking.microphonePermission!.value == "prompt") {
                this._fsm.perform("ask_permissions");
            } else if (common.playerData.gameSettings.policyAccepted.value && (common.hoverboardNetworking.microphonePermission!.value == "granted" || common.hoverboardNetworking.microphonePermission!.value == "error")) {
                this._fsm.perform("permissions_granted");
            } else {
                this._fsm.perform("permissions_denied");
            }
        }
    }

    private _askPermissionsStart(): void {
        this._setPlayerVisible(true);

        if (common.playerData.gameSettings.policyAccepted.value == null) {
            this._delayIntro = true;
            common.pauseMenu.getPermissionsConsent(false);
        }
    }

    private _askPermissionsUpdate(dt: number): void {
        if (common.playerData.gameSettings.policyAccepted.value != null) {
            if (common.playerData.gameSettings.policyAccepted.value && (common.hoverboardNetworking.microphonePermission!.value == "granted" || common.hoverboardNetworking.microphonePermission!.value == "error")) {
                this._fsm.perform("permissions_granted");
            } else if (common.playerData.gameSettings.policyAccepted.value && (common.hoverboardNetworking.microphonePermission!.value == "prompt" || common.hoverboardNetworking.microphonePermission!.value == "unsupported")) {
                this._fsm.perform("ask_microphone");
            } else {
                if (common.playerData.gameSettings.policyAccepted.value) {
                    this._fsm.perform("permissions_denied");
                } else {
                    const url = new URL(window.location.href);
                    const searchParams = url.searchParams;
                    searchParams.delete("room");
                    replaceSearchParams(url, searchParams);

                    common.pauseMenu.setState(PauseState.MAYBE_CLOSE);
                    this._fsm.perform("permissions_denied_skip_game_mode");
                }
            }
        }
    }

    private _permissionsDeniedStart(): void {
        this._setPlayerVisible(true);

        let message = "";
        if (common.playerData.gameSettings.policyAccepted.value && (common.hoverboardNetworking.microphonePermission!.value != "granted" && common.hoverboardNetworking.microphonePermission!.value != "error")) {
            message += "Microphone permissions have been denied.";
        } else if (!common.playerData.gameSettings.policyAccepted.value) {
            message += "Permissions have been denied.";
        }

        const popupParams = new PauseMenuCustomPopupHelperParams();
        popupParams.title = "PERMISSIONS DENIED";
        popupParams.message = message;
        popupParams.primaryButtonText = "CONTINUE";
        popupParams.primaryButtonClickCallback = () => {
            this._fsm.perform("done");
        };
        popupParams.enableCloseButton = false;

        common.pauseMenu.showCustomPopup(popupParams);
    }

    private _waitMicrophonePermissionStart(): void {
        let message = "";
        message += "A microphone request should now be visible.";
        message += "\n\n";
        message += "If that is not the case, you can press the\n";
        message += "following button to continue without accepting it.";

        const buttonText = "REQUEST NOT VISIBLE";

        this._delayIntro = true;

        this._waitMicrophonePermissionDone = false;

        const popupParams = new PauseMenuCustomPopupHelperParams();
        popupParams.title = "MICROPHONE REQUESTED";
        popupParams.titleFontSize = 1.65;
        popupParams.message = message;
        popupParams.messageFontSize = 0.75;
        popupParams.primaryButtonText = buttonText;
        popupParams.primaryButtonWidth = 200;
        popupParams.primaryButtonClickCallback = () => {
            common.hoverboardNetworking.updateMicrophonePermissions("granted");
            this._waitMicrophonePermissionDone = true;
        };
        popupParams.enableCloseButton = false;

        common.pauseMenu.showCustomPopup(popupParams);

        common.hoverboardNetworking.promptMicrophonePermissions().finally(() => this._waitMicrophonePermissionDone = true);
    }

    private _waitMicrophonePermissionUpdate(dt: number): void {
        if (this._waitMicrophonePermissionDone) {
            if (this._fsm.isInState("wait_microphone_permission")) {

                if ((common.hoverboardNetworking.microphonePermission!.value == "granted" || common.hoverboardNetworking.microphonePermission!.value == "prompt" || common.hoverboardNetworking.microphonePermission!.value == "error")) {
                    this._fsm.perform("permissions_granted");
                } else {
                    this._fsm.perform("permissions_denied");
                }
            }
        }
    }

    private _checkAskGameModeUpdate(dt: number): void {
        if (common.playerData.gameSettings.gameConfigOnLoad.value == null && common.playerData.gameSettings.gameOnlineConfigOnLoad.value == null) {
            this._fsm.perform("ask_game_mode");
        } else {
            this._fsm.perform("game_mode_already_specified");
        }
    }

    private _askGameModeStart(): void {
        this._setPlayerVisible(true);

        this._delayIntro = true;

        common.pauseMenu.getAskGameModePanel().
            then((askGameModeResult: { gameMode: GameMode, isOnline: boolean } | null) => {
                if (askGameModeResult != null) {
                    const gameConfig = new HoverboardGameConfigJSON();
                    const gameOnlineConfig = new HoverboardGameOnlineConfigJSON();

                    gameConfig.location = common.gameConfig.location;
                    gameConfig.mode = askGameModeResult.gameMode;
                    gameOnlineConfig.isOnline = askGameModeResult.isOnline;

                    common.playerData.gameSettings.gameConfigOnLoad.value = gameConfig;
                    common.playerData.gameSettings.gameOnlineConfigOnLoad.value = gameOnlineConfig;
                }

                this._fsm.perform("done");
            }).catch(() => {
                this._fsm.perform("done");
            });
    }

    private _askGameModeDone(): void {
        this._setPlayerVisible(true);
    }

    private _checkChangeConfigUpdate(dt: number): void {
        if (common.playerData.gameSettings.gameConfigOnLoad.value != null && gameConfigChangeRequiresReload(common.gameConfig, common.playerData.gameSettings.gameConfigOnLoad.value) != null) {
            this._fsm.perform("change_config");
        } else {
            this._fsm.perform("config_is_ok");
        }
    }

    private _changeConfigStart(): void {
        if (common.playerData.gameSettings.gameConfigOnLoad.value != null) {
            const newGameConfig = HoverboardGameConfig.fromServerJSON(common.playerData.gameSettings.gameConfigOnLoad.value);
            common.menu.changeGameConfig(new RoomData(common.roomData), newGameConfig, false).then((configChanged?: boolean) => {
                if (configChanged) {
                    this._fsm.performDelayed("done");
                } else {
                    common.popupManager.clearPopupTag("moving_to");
                    this._fsm.performDelayed("cancel_change_config");
                }
            });
        } else {
            this._fsm.performDelayed("done");
        }
    }

    private _checksCompleted(): void {
        this._checksSectionDone = true;

        common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!.play();
        common.audioManager.getAudio(AudioID.SHOP_MUSIC)!.play();
        common.audioManager.getAudio(AudioID.AMBIENT)!.play();
    }

    private _setupOnlineModeUpdate(dt: number): void {
        if (this._setupOnlineModeUpdateDelay.isDone()) {
            if (common.roomData.roomNumber != null) {
                common.hoverboardNetworking.joinOrCreate(common.roomData.roomNumber, common.roomData.privateRoom);
            } else {
                const gameOnlineConfigOnLoad = common.playerData.gameSettings.gameOnlineConfigOnLoad.value;
                let isOnline = gameOnlineConfigOnLoad?.isOnline ?? false;
                let roomNumber = gameOnlineConfigOnLoad?.roomID ?? null;
                let isPrivateRoom = gameOnlineConfigOnLoad?.isPrivateRoom ?? false;

                if (roomNumber == null) {
                    const searchParams = new URLSearchParams(window.location.search);
                    const searchParamsRoomNumber = searchParams.get("room");
                    if (searchParamsRoomNumber != null) {
                        roomNumber = validateInt(parseInt(searchParamsRoomNumber), null);
                        isOnline = true;
                        isPrivateRoom = true;
                    }
                }

                if (roomNumber != null && isOnline) {
                    common.roomProxy.hostOrJoinRoom(roomNumber, isPrivateRoom);
                } else if (isOnline) {
                    common.roomProxy.quickPlay();
                } else {
                    common.MAIN_CHANNEL.emit("room-no-autojoin");
                }
            }

            this._fsm.perform("done");
        }
    }

    private _checkSkipPlayIntroUpdate(dt: number) {
        if (skipIntroOnNextLoad || (HoverboardDebugs.debugStartedEnabled && HoverboardDebugs.skipIntro)) {
            this._fsm.perform("skip_intro");
        } else if (this._delayIntro) {
            this._fsm.perform("play_intro_with_delay");
        } else {
            this._fsm.perform("play_intro");
        }
    }

    private _playIntroStart(): void {
        this._introMusicDelay.start();

        this._hoverfitLogo.pp_setActive(true);

        this._introAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            duration: 2300,
            autoplay: false,
            update: (anim) => {
                if (this._introAnim != null) {
                    const animationPercentage = anim.progress / 100.0;
                    this._fogColor[3] = 1.0 - animationPercentage;
                    (this._hoverfitLogoMeshComponent.material as PhongMaterial).fogColor = this._fogColor;

                    const hoverfitLogocCurrentZ = this._hoverfitLogoZStart + (IntroComponent._hoverfitLogoZEnd - this._hoverfitLogoZStart) * animationPercentage;
                    this._hoverfitLogoPosition[2] = hoverfitLogocCurrentZ;
                    this._hoverfitLogo.setPositionLocal(this._hoverfitLogoPosition);
                }
            },
            complete: (anim) => {
                this._completePlayIntro();
            }
        });
    }

    private _completePlayIntro(): void {
        this._introAnim = null;

        this._fogColor[3] = 0;
        (this._hoverfitLogoMeshComponent.material as PhongMaterial).fogColor = this._fogColor;

        this._hoverfitLogoPosition[2] = IntroComponent._hoverfitLogoZEnd;
        this._hoverfitLogo.setPositionLocal(this._hoverfitLogoPosition);

        this._pressAnyButton.pp_setActive(true);
        this._gameVersion.pp_setActive(true);
    }

    private _playIntroUpdate(dt: number): void {
        if (this._introMusicDelay.isRunning()) {
            this._introMusicDelay.update(dt);
            if (this._introMusicDelay.isDone()) {
                common.audioManager.getAudio(AudioID.INTRO)!.play();
            }
        }

        this._pressAnyButtonDelay.update(dt);

        if (this._introAnim != null) {
            this._introAnim!.tick(getTime());
        } else {
            if (this._pressAnyButtonDelay.isDone()) {
                if ((XRUtils.isSessionActive() || BrowserUtils.isMobile() || (BrowserUtils.isDesktop() && (!XRUtils.isVRSupported() || HoverboardDebugs.debugStartedEnabled)))
                    && GamepadUtils.isAnyButtonPressEnd([Globals.getLeftGamepad(this._engine)], [Globals.getRightGamepad(this._engine)])) {
                    skipIntroOnNextLoad = true;

                    this._fsm.perform("done");
                }
            }
        }
    }

    private _fadeInStart(): void {
        if (!skipIntroOnNextLoad) {
            AnalyticsUtils.sendEventOnce("intro_done");
        }

        // So that it is refreshed with the "granted" state instead of "unsupported" (which is needed initially)
        common.hoverboardNetworking.updateMicrophonePermissions("granted");

        common.countdown.resetCountdown();

        this._setCursorsEnabled(true);

        Globals.getPlayerLocomotion()!.setIdle(false);

        common.CURRENT_STATE = GAME_STATES.MENU;

        common.audioManager.getAudio(AudioID.AMBIENT)!.fade(0.0, common.audioManager.getAudio(AudioID.AMBIENT)!.getDefaultVolume(), 5);
        common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!.fade(0.0, common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!.getDefaultVolume(), 1.25);

        this._fadeInAnim = anime({
            targets: this,
            easing: "linear",
            delay: 0,
            autoplay: false,
            duration: IntroComponent._fadeDuration,
            update: (anim) => {
                if (this._fadeInAnim != null) {
                    const animationPercentage = anim.progress / 100.0;
                    MaterialUtils.setObjectAlpha(this.object, MathUtils.clamp(1 - animationPercentage, 0, 1));
                }
            },
            complete: () => {
                this.object.pp_setActiveDescendants(false);
                this._fadeInAnim = null;

                this._fsm.perform("done");
            }
        });
    }

    private _fadeInUpdate(dt: number): void {
        this._fadeInAnim!.tick(getTime());
    }

    private _quickFadeInStart(): void {
        this._fadeInAnim = anime({
            targets: this,
            easing: "linear",
            delay: 0,
            autoplay: false,
            duration: IntroComponent._fadeDuration / 4,
            update: (anim) => {
                if (this._fadeInAnim != null) {
                    const animationPercentage = anim.progress / 100.0;
                    MaterialUtils.setObjectAlpha(this.object, MathUtils.clamp(1 - animationPercentage, 0, 1));
                }
            },
            complete: () => {
                this.object.pp_setActiveDescendants(false);
                this._fadeInAnim = null;

                this._fsm.perform("done");
            }
        });
    }

    private _introDone(): void {
        this._done = true;
        common.popupManager.setActive(true);
    }

    private _cancelIntro(): void {
        this._introAnim = null;

        MaterialUtils.setObjectAlpha(this._backgroundOccludeSphere, 1);
        this.object.pp_setActiveDescendants(false);
        this._backgroundOccludeSphere.pp_setActive(true);
        //this._setCursorsEnabled(false);
    }

    private _manualFadeOutStart(): void {
        MaterialUtils.setObjectAlpha(this._backgroundOccludeSphere, 0);
        this._backgroundOccludeSphere.pp_setActive(true);

        this._synTransformWithHead();
        this._syncTransformWithHeadCountdown = 2;

        this._manualFadeOutAnim = anime({
            targets: this,
            easing: "linear",
            delay: 0,
            duration: IntroComponent._fadeDuration,
            autoplay: false,
            update: (anim) => {
                if (this._manualFadeOutAnim != null) {
                    const animationPercentage = anim.progress / 100.0;
                    MaterialUtils.setObjectAlpha(this._backgroundOccludeSphere, MathUtils.clamp(animationPercentage, 0, 1));
                }
            },
            complete: (anim) => {
                MaterialUtils.setObjectAlpha(this._backgroundOccludeSphere, 1);
                this._manualFadeOutAnim = null;

                this._fsm.perform("done");
            }
        });
    }

    private _manualFadeOutUpdate(dt: number): void {
        this._manualFadeOutAnim!.tick(getTime());
    }

    private _cancelFadeOut(): void {
        // this._manualFadeOutAnim = null;
        // this._backgroundOccludeSphere.pp_setActive(false);

        this._disableCursorsOnNextUpdate = false;
        this._setCursorsEnabled(true);
    }

    private _synTransformWithHead(): void {
        const playerHeadPosition = Globals.getPlayerObjects()!.myHead!.pp_getPosition();
        const playerHeadForward = Globals.getPlayerObjects()!.myHead!.pp_getForward();
        this.object.pp_setPosition(playerHeadPosition);
        this.object.pp_setUp(GameGlobals.up, playerHeadForward.vec3_negate());
    }

    private _onXRSessionStart(): void {
        if (this._xrButtonsContainer != null) {
            this._xrButtonsContainer.style.setProperty("display", "none");
        }

        this._pressAnyButtonTextComponent.text = "PRESS ANY BUTTON TO BEGIN";
        this._syncTransformWithHeadCountdown = 5;

        this._pressAnyButtonDelay.start(0.25);
    }

    private _onXRSessionEnd(): void {
        if (this._xrButtonsContainer != null) {
            this._xrButtonsContainer.style.removeProperty("display");
        }

        this._pressAnyButtonTextComponent.text = "PRESS VR TO ENTER THE GAME";
        this._syncTransformWithHeadCountdown = 5;
    }

    private _setPlayerVisible(visible: boolean) {
        this._backgroundSphere.pp_setActive(visible);
        this._backgroundOccludeSphere.pp_setActive(!visible);
        this._setCursorsEnabled(visible);
    }

    private _setCursorsEnabled(enabled: boolean): void {
        if (!enabled) {
            for (const cursorComponent of this._allCursorComponents) {
                cursorComponent.active = false;
            }
        } else {
            for (const [cursorComponent, activeBackup] of this._cursorComponentsActiveBackup.entries()) {
                cursorComponent.active = activeBackup;
            }
        }
    }

    private _updateXRButtons(): void {
        if (!_xrButtonsShown) {
            if (!this._firstXRButtonsUpdateDone) {
                this._firstXRButtonsUpdateDone = true;

                if (this._vrButton != null) {
                    this._vrButton.style.setProperty("display", "block");
                }

                if (this._arButton != null) {
                    this._arButton.style.setProperty("display", "block");
                }
            } else {
                if (this._vrButton != null) {
                    this._vrButton.style.setProperty("transform", "scale(1)");

                    if (XRUtils.isVRSupported()) {
                        this._vrButton.style.setProperty("opacity", "1");
                        this._vrButton.style.setProperty("pointer-events", "all");
                    } else {
                        this._vrButton.style.setProperty("display", "none");
                    }
                }

                if (this._arButton != null) {
                    this._arButton.style.setProperty("transform", "scale(1)");

                    if (XRUtils.isARSupported()) {
                        this._arButton.style.setProperty("opacity", "1");
                        this._arButton.style.setProperty("pointer-events", "all");
                    } else {
                        this._arButton.style.setProperty("display", "none");
                    }
                }

                if ((XRUtils.isVRSupported() || this._vrButton == null) && (XRUtils.isARSupported() || this._arButton == null)) {
                    _xrButtonsShown = true;
                }
            }
        }
    }
}