import { common } from "src/hoverfit/common.js";
import { DEFAULT_ITEM_OWN_META, getAssetFromManifest, replaceNamespaceFromManifest, unpackAssetShortID } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { AnalyticsUtils } from "wle-pp";
import { type AssetBase, type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, GenericPurchaseError, ItemCategory, ItemCurrency, ItemNamespace, NoFundsPurchaseError } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { type OwnedItem } from "./owned-item.js";
import { ShopItem } from "./shop-item.js";

const FITABUX_ASSET_MANIFEST_URL = "assets/json/shop/fitabux-asset-manifest.json";

export class FitabuxAssetProvider extends AssetProvider {
    static instance: FitabuxAssetProvider;
    private readonly manifest: Promise<AssetManifest>;

    private ignoreInventoryChange = false;
    private readonly onInventoryChanged = () => {
        // HACK this is needed to avoid infinite loops
        if (this.ignoreInventoryChange) return;
        this.fetchInventory();
    };

    constructor(controller: IAPContentController) {
        super(controller);
        FitabuxAssetProvider.instance = this;
        this.manifest = controller.fetchAndLoadManifest(FITABUX_ASSET_MANIFEST_URL, ItemNamespace.Fitabux);

        this.manifest.then((manifest) => {
            this.fetchInventory();
            common.playerData.listen(this.onInventoryChanged, "fitabuxInventory");

            const added: Array<[shortID: string, item: FitabuxShopItem]> = [];
            this.makeShopItemsFromAssets(added, manifest.hoverboard, ItemCategory.Hoverboard);
            this.makeShopItemsFromAssets(added, manifest.suit, ItemCategory.Suit);
            this.makeShopItemsFromAssets(added, manifest.headwear, ItemCategory.Headwear);
            this.makeShopItemsFromAssets(added, manifest.hairColor, ItemCategory.HairColor);
            this.makeShopItemsFromAssets(added, manifest.bundle, ItemCategory.Bundle);
            this.catalog.replaceNamespace(added, ItemNamespace.Fitabux);
        });
    }

    clientSideAssetBaseMap: Map<string, AssetBase> = new Map<string, AssetBase>();

    private makeShopItemsFromAssets(added: Array<[shortID: string, item: FitabuxShopItem]>, manifestList: { [shortID: string]: AssetBase }, catalogClass: ItemCategory) {
        for (const shortID of Object.getOwnPropertyNames(manifestList)) {
            const assetBase = manifestList[shortID];
            const price = assetBase.price;
            if (price !== undefined && price >= 0) {
                // fitabux have no discounts
                added.push([shortID, new FitabuxShopItem(this, assetBase, shortID, catalogClass, price, price, assetBase.expiresAfter ?? 0)]);
            }
        }
    }

    protected override async handleExpiredItems(expiredShortIDs: Set<string>, namespace: ItemNamespace): Promise<void> {
        // remove expired items from fitabux inventory
        if (expiredShortIDs.size > 0) {
            const playerData = common.playerData;
            const newInv = [...playerData.fitabuxInventory];
            let changed = false;
            for (let i = newInv.length - 1; i >= 0; i--) {
                if (expiredShortIDs.has(newInv[i][0])) {
                    newInv.splice(i, 1);
                    changed = true;
                }
            }

            if (changed) {
                this.ignoreInventoryChange = true;
                try {
                    playerData.fitabuxInventory = newInv;
                } finally {
                    this.ignoreInventoryChange = false;
                }

                playerData.savePlayerData();
            }
        }

        super.handleExpiredItems(expiredShortIDs, namespace);
    }

    protected override async handleInventoryFetch(): Promise<void> {
        if (PRE_OWN_ALL_ITEMS) {
            replaceNamespaceFromManifest(this.inventory, this.catalog, await this.manifest, ItemNamespace.Fitabux);
        } else {
            const added: OwnedItem[] = [];
            const manifest = await this.manifest;
            const shortInv = common.playerData.fitabuxInventory;
            const expectedExpirableShortIDs = new Set<string>();
            for (let i = 0; i < shortInv.length; i++) {
                const ownedItem = shortInv[i];
                const shortID = ownedItem[0];
                const meta = ownedItem[1];
                if (meta.expiresOn !== 0) expectedExpirableShortIDs.add(shortID);
                unpackAssetShortID(added, this.catalog, manifest, shortID, ItemNamespace.Fitabux, meta);
            }

            this.replaceOwnedItems(added, ItemNamespace.Fitabux, expectedExpirableShortIDs);
        }
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        const assetCatPair = getAssetFromManifest(await this.manifest, shortID);

        if (!assetCatPair) {
            throw new GenericPurchaseError(`No such item with short ID "${shortID}"`);
        }

        const [asset, category] = assetCatPair;
        const price = asset.price!;
        if (common.playerData.fitabux < price) {
            throw new NoFundsPurchaseError(ItemCurrency.Fitabux);
        }

        AnalyticsUtils.sendEvent("purchase", {
            itemNamespace: ItemNamespace.Fitabux,
            itemShortID: shortID,
            itemCategory: category,
        });

        // XXX no need to add directly to the IAPCC inventory. fitabuxInventory
        //     changes cascade to it; we'd just be duplicating work
        const meta = asset.expiresAfter ? { expiresOn: Date.now() + asset.expiresAfter } : DEFAULT_ITEM_OWN_META;
        common.playerData.fitabux -= price;
        common.playerData.fitabuxInventory = [...common.playerData.fitabuxInventory, [shortID, meta]];
        common.playerData.savePlayerData();
        return true;
    }

    override dispose() {
        super.dispose();

        if (this.onInventoryChanged) {
            common.playerData.unlisten(this.onInventoryChanged);
        }
    }
}

export class FitabuxShopItem extends ShopItem {
    constructor(shopProvider: AssetProvider, asset: AssetBase, readonly shortID: string, readonly itemCategory: ItemCategory, readonly price: number, readonly priceDiscounted: number, readonly expiresAfter: number) {
        super(shopProvider, asset);
    }

    get namespace(): ItemNamespace.Fitabux {
        return ItemNamespace.Fitabux;
    }

    get currencyType(): ItemCurrency.Fitabux {
        return ItemCurrency.Fitabux;
    }

    purchase() {
        return this.shopProvider.purchaseItem(this.shortID);
    }
}
