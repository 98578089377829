import { common } from "../../common.js";
import { GameModeVariable } from "./game-mode-variable.js";

export class LapsAmountVariable extends GameModeVariable<number> {
    constructor() {
        super(1, "change_laps_amount");
    }

    protected override sendNetVar(): void {
        common.hoverboardNetworking.updateLapsAmount(this.value);
    }
}