import { Alignment, Column, FlexAlignment, type FlexAlignment2D, Label, Root, Row, TextAlignMode, Viewport, Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { type ObservableItemIDCollection, type ObservableItemIDCollectionEventListener, ObservableItemIDCollectionEventType } from "src/hoverfit/misc/data-structs/observable-item-id-collection.js";
import { Carousel } from "../../lazy-widgets/widgets/carousel.js";

const COLUMN_ALIGNMENT_EMPTY: FlexAlignment2D = {
    main: FlexAlignment.Center, cross: Alignment.Stretch,
};

const COLUMN_ALIGNMENT_NOT_EMPTY: FlexAlignment2D = {
    main: FlexAlignment.Start, cross: Alignment.Stretch,
};

export interface KioskCustomPageProperties extends WidgetProperties {
    maxRowItems?: number;
    emptyLabel?: string;
}

export class KioskItemGrid extends Carousel<Column> {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-item-grid",
        inputConfig: [
            {
                mode: "value",
                name: "item-generator",
            },
            {
                mode: "value",
                name: "collection",
            },
            {
                mode: "value",
                name: "filter",
            },
        ]
    };

    readonly maxRowItems: number;
    readonly column: Column;
    private readonly rows: Row[] = [];
    private readonly idWidgetMap = new Map<string, Widget>();

    private hadEmptyLabel = false;
    private emptyLabel: string | null = null;

    constructor(readonly itemGenerator: (id: string) => Widget, readonly collection: ObservableItemIDCollection, readonly filter: (id: string) => boolean, properties?: Readonly<KioskCustomPageProperties>) {
        const column = new Column();

        super(
            column,
            {
                loops: false, vertical: true,
                ...properties,
            }
        );

        this.column = column;
        this.maxRowItems = properties?.maxRowItems ?? 5;
        this.emptyLabel = properties?.emptyLabel ?? null;

        this.maybeAddEmptyLabel();
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);
        this.collection.watch(this.onInventoryChange, true);
    }

    override detach(): void {
        this.collection.unwatch(this.onInventoryChange);
        super.detach();
    }

    private makeRow() {
        const row = new Row<Widget>([], {
            multiContainerAlignment: {
                main: FlexAlignment.Start, cross: Alignment.Stretch,
            }
        });

        this.rows.push(row);
        this.column.add(row);
        return row;
    }

    private addItemsByID(widgets: Widget[]) {
        this.maybeRemoveEmptyLabel();

        const widgetCount = widgets.length;
        const rowCount = this.rows.length;
        let row = rowCount === 0 ? this.makeRow() : this.rows[rowCount - 1];
        const rowCap = this.maxRowItems;
        let space = rowCap - row.childCount;

        for (let i = 0; i < widgetCount; i++) {
            if (space === 0) {
                space = rowCap - 1;
                row = this.makeRow();
            } else {
                space--;
            }

            row.add(widgets[i]);
        }
    }

    private readonly onInventoryChange: ObservableItemIDCollectionEventListener = (type, ids) => {
        const affectedIDs: string[] = [];
        for (const id of ids) {
            if (this.filter(id)) affectedIDs.push(id);
        }

        const affectedIDCount = affectedIDs.length;
        if (affectedIDCount === 0) return;

        if (type === ObservableItemIDCollectionEventType.Add) {
            const widgets: Widget[] = [];
            for (let i = 0; i < affectedIDCount; i++) {
                const id = affectedIDs[i];
                const widget = this.itemGenerator(id);
                this.idWidgetMap.set(id, widget);
                widgets.push(widget);
            }

            this.addItemsByID(widgets);
        } else if (type === ObservableItemIDCollectionEventType.Update) {
            const updatedRows = new Map<Row, Widget[]>();
            for (const id of ids) {
                const oldWidget = this.idWidgetMap.get(id)!;
                const row = oldWidget.parent as Row;

                let widgets = updatedRows.get(row);
                if (!widgets) {
                    widgets = Array.from(row);
                    updatedRows.set(row, widgets);
                }

                const idx = widgets.indexOf(oldWidget);
                const newWidget = this.itemGenerator(id);
                this.idWidgetMap.set(id, newWidget);
                widgets.splice(idx, 1, newWidget);
            }

            for (const [row, widgets] of updatedRows) {
                row.clearChildren().add(widgets);
            }
        } else {
            const keptWidgets: Widget[] = [];
            for (let i = 0; i < affectedIDCount; i++) {
                const id = affectedIDs[i];
                const widget = this.idWidgetMap.get(id)!;
                this.idWidgetMap.delete(id);

                // check if already removed from rows
                const keptIdx = keptWidgets.indexOf(widget);
                if (keptIdx >= 0) {
                    keptWidgets.splice(keptIdx, 1);
                    continue;
                }

                // remove from row (and remove every row after this item's
                // position, so it can be added later in the right order)
                const rowIdx = this.rows.indexOf(widget.parent as Row);
                const rowCount = this.rows.length;
                for (let r = rowIdx; r < rowCount; r++) {
                    const row = this.rows[r];
                    for (const child of row) {
                        if (child !== widget) keptWidgets.push(child);
                    }

                    row.clearChildren();
                    this.column.remove(row);
                }

                this.rows.length = rowIdx;
            }

            if (keptWidgets.length > 0) {
                this.addItemsByID(keptWidgets);
            } else {
                this.maybeAddEmptyLabel();
            }
        }
    };

    private maybeRemoveEmptyLabel() {
        if (!this.hadEmptyLabel) return;
        this.column.clearChildren();
        this.hadEmptyLabel = false;
        this.column.multiContainerAlignment = COLUMN_ALIGNMENT_NOT_EMPTY;
    }

    private maybeAddEmptyLabel() {
        if (this.hadEmptyLabel || !this.emptyLabel || this.column.childCount > 0) return;

        this.hadEmptyLabel = true;

        this.column.add(new Label(this.emptyLabel, {
            bodyTextAlign: TextAlignMode.Center,
            bodyTextFont: "1.5em sui-generis",
        }));

        this.column.multiContainerAlignment = COLUMN_ALIGNMENT_EMPTY;
    }
}