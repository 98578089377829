import { common } from "src/hoverfit/common.js";
import { HoverboardDebugs } from "src/hoverfit/game/components/hoverboard-debugs-component.js";
import { HeyVR } from "src/hoverfit/misc/heyvr-sdk-provider.js";
import { MathUtils } from "wle-pp";
import { PopupTip } from "./popup-tip.js";
import { preparePopupTips } from "./prepare-popup-tips.js";

export class PopupTipsManager {

    private roundStarted: boolean = false;

    private currentTipChance: number = 0.5;

    // Static is used for persistence between scene changes
    private static loginReminderDisplayedOnce: boolean = false;
    private static subscribeReminderDisplayedOnce: boolean = false;

    private static popupTips: PopupTip[] = [];

    constructor() {
        if (PopupTipsManager.popupTips.length == 0) {
            PopupTipsManager.popupTips.push(...preparePopupTips());
        }
    }

    countdownStarted() {
        this.roundStarted = true;
    }

    returnedToBalcony() {
        if (this.roundStarted || HoverboardDebugs.alwaysShowPopupTips) {
            for (const popupTip of PopupTipsManager.popupTips) {
                popupTip.decreaseCooldown();
            }

            // These 2 must popups might be in some way implemented as popup tips too with a super high chance and some special valid callback
            if (this.roundStarted && HeyVR && common.playerData.isGuest && !PopupTipsManager.loginReminderDisplayedOnce) {
                PopupTipsManager.loginReminderDisplayedOnce = true;
                common.kioskLowerUI.displayLoginReminder();
            } else if (this.roundStarted && HeyVR && !common.playerData.isGuest && !common.playerData.subscribed &&
                !PopupTipsManager.subscribeReminderDisplayedOnce && common.playerData.subscribeReminderSnoozeElapsed()) {
                PopupTipsManager.subscribeReminderDisplayedOnce = true;
                common.kioskLowerUI.displaySubscribeReminder();
            } else {
                if (this.shouldShowTip()) {
                    this.showTip();
                } else {
                    // Slowly making the chance a tip is shown higher
                    this.currentTipChance += 0.1;
                }
            }
        }

        this.roundStarted = false;
    }

    showTip() {
        const popupTipToShow = this.getRandomPopupTip();

        if (popupTipToShow != null) {
            popupTipToShow.showPopup();

            this.currentTipChance = 0.5;
        }
    }

    private getRandomPopupTip(): PopupTip | null {
        let randomPopupTip = null;

        let totalWeight = 0;
        for (const popupTip of PopupTipsManager.popupTips) {
            if (popupTip.isValid()) {
                totalWeight += popupTip.getParams().pickWeight;
            }
        }

        if (totalWeight > 0) {
            const randomWeight = MathUtils.randomInt(0, totalWeight - 1);
            let currentWeight = 0;

            for (const popupTip of PopupTipsManager.popupTips) {
                if (popupTip.isValid()) {
                    currentWeight += popupTip.getParams().pickWeight;
                    if (randomWeight < currentWeight) {
                        randomPopupTip = popupTip;
                        break;
                    }
                }
            }
        }

        return randomPopupTip;
    }

    private shouldShowTip() {
        return MathUtils.random() < this.currentTipChance || HoverboardDebugs.alwaysShowPopupTips;
    }
}