<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <rounded-corners>
        <book option:id="ui-book">
            <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@111.5" option:vertical-edge-pixels="@15" option:min-width="@250" option:max-width="@250" option:min-height="@119" option:max-height="@119">
                <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                    <column option:flex="@1" option:flex-shrink="@1">
                        <spacing option:min-height="@18" option:max-height="@18"/>
                        <label option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis">CHANGING LOCATION</label>
                        <spacing/>
                        <label option:id="moving-to-config-message" option:body-text-align="@0.5" option:body-text-font="0.8em sui-generis-mono-digits" option:max-width="@290">MESSAGE</label>
                        <spacing option:min-height="@25" option:max-height="@25"/>
                    </column>
                </row>
            </background-9slice>
        </book>
    </rounded-corners>
</ui-tree>