import { Component, PhysXComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { EnvironmentalSoundArea } from "../environmental-sound-area.js";

export class EnvironmentalSoundAreaComponent extends Component {
    static TypeName = "environmental-sound-area";

    @property.float(5)
    public minCooldownSeconds!: number;

    @property.float(15)
    public maxCooldownSeconds!: number;

    @property.float(0)
    public minDelayToPlaySeconds!: number;

    @property.float(7)
    public maxDelayToPlaySeconds!: number;

    private area!: EnvironmentalSoundArea;

    start() {
        this.area = new EnvironmentalSoundArea(
            this.object.pp_getComponent(PhysXComponent)!,
            this.minCooldownSeconds,
            this.maxCooldownSeconds,
            this.minDelayToPlaySeconds,
            this.maxDelayToPlaySeconds
        );
    }

    getArea() {
        return this.area;
    }
}