import { common } from "src/hoverfit/common.js";
import { RewardOtherFlags } from "src/hoverfit/data/player-data.js";
import { DEFAULT_ITEM_OWN_META } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { FP_PER_BOARD_LEVEL, FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL } from "src/hoverfit/ui/kiosk/widgets/lifetime-level-display.js";
import { bigNumberToString } from "src/hoverfit/utils/big-number-to-string.js";
import { type HelmetVariant, type HoverboardVariant, type SuitVariant } from "./asset-manifest-types.js";
import { AssetProvider, HeadwearSubCategory, ItemCategory, ItemNamespace } from "./asset-provider.js";
import { DynamicManifest, type DynamicManifestTypeAssetPair } from "./dynamic-manifest.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { type OwnedItem } from "./owned-item.js";

function fitpointsStrToDisplayStr(str: string): string | undefined {
    const fp = Number(str);
    if (isNaN(fp) || fp < 0 || Math.trunc(fp) !== fp) {
        return undefined;
    }

    return bigNumberToString(fp);
}

function displayStrToStringImageURL(str: string): string {
    return `string-image://256,128,96,sui-generis,black,${str}`;
}

const REWARD_STATIC_ASSETS = [
    [
        "hb_founder",
        [
            ItemCategory.Hoverboard,
            <HoverboardVariant>{
                name: "Viper - The Founder",
                description: "The Founder Board, with it's sleek blue design and neon vibes is only available to Founder members of HoverFit. Ride with pride and show off with this early supporter exclusive.",
                thumbnail: "assets/items/reward/hb_founder/thumbnail.webp",
                materials: {
                    primaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_founder/primary-diffuse.webp"
                    },
                    secondaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_founder/primary-diffuse.webp"
                    },
                    tertiaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_founder/primary-diffuse.webp"
                    },
                    emissive: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_founder/emissive-diffuse.webp",
                        emissiveTexture: "assets/items/reward/hb_founder/emissive-diffuse.webp"
                    }
                },
                url: "assets/shared-parts/models/hb_racer.glb",
                jetstreamColor: [
                    0.2409,
                    0.9635,
                    0.6415,
                    1.0
                ],
            },
        ],
    ],
    [
        "hb_halloween2024",
        [
            ItemCategory.Hoverboard,
            <HoverboardVariant>{
                name: "Halloween Board - Race In Peace",
                thumbnail: "assets/items/reward/hb_halloween2024/thumbnail.webp",
                materials: {
                    primaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_halloween2024/primary-secondary-diffuse.webp"
                    },
                    secondaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_halloween2024/primary-secondary-diffuse.webp"
                    },
                    tertiaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_halloween2024/tertiary-diffuse.webp"
                    },
                    emissive: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/hb_halloween2024/emissive-emissive.webp",
                        emissiveTexture: "assets/items/reward/hb_halloween2024/emissive-emissive.webp"
                    }
                },
                url: "assets/shared-parts/models/hb_future.glb",
                jetstreamColor: [
                    0.1145,
                    0.0194,
                    0.1248,
                    1.0
                ],
                logoLocalPosition: [
                    -0.02,
                    0.0066,
                    -1.2
                ],
            },
        ],
    ],
    [
        "h_halloween2024",
        [
            ItemCategory.Headwear,
            <HelmetVariant>{
                name: "Halloween Helmet - Spooky Skull",
                type: "helmet",
                thumbnail: "assets/items/reward/h_halloween2024/thumbnail.webp",
                url: "assets/shared-parts/models/h_base.glb",
                materials: {
                    primaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/h_halloween2024/primary-secondary-diffuse.webp"
                    },
                    secondaryMaterial: {
                        pipeline: "Phong Opaque Textured Emissive",
                        diffuseTexture: "assets/items/reward/h_halloween2024/primary-secondary-diffuse.webp"
                    }
                },
            },
        ],
    ],
] as const;

class RewardManifest extends DynamicManifest {
    private readonly cache: Map<string, DynamicManifestTypeAssetPair>;

    constructor() {
        super();
        this.cache = new Map<string, DynamicManifestTypeAssetPair>(REWARD_STATIC_ASSETS);
    }

    override getAssetByShortID(shortID: string): DynamicManifestTypeAssetPair | undefined {
        let cached = this.cache.get(shortID);
        if (cached) return cached;

        if (shortID.startsWith("h_reward-")) {
            const fpStr = fitpointsStrToDisplayStr(shortID.slice(9));
            if (!fpStr) return;
            const fpURL = displayStrToStringImageURL(fpStr);

            cached = [
                ItemCategory.Headwear,
                <HelmetVariant>{
                    name: `Reward helmet (${fpStr})`,
                    url: "assets/items/reward/h_reward/model.glb",
                    thumbnail: "assets/items/reward/h_reward/thumbnail.webp",
                    type: HeadwearSubCategory.Helmet,
                    materials: {
                        primaryMaterial: {
                            pipeline: "Phong Opaque Textured Emissive",
                            diffuseTexture: "assets/items/reward/h_reward/primary-diffuse.webp",
                        },
                        secondaryMaterial: {
                            pipeline: "Phong Transparent Textured",
                            diffuseTexture: fpURL,
                        },
                    },
                },
            ];
        } else if (shortID.startsWith("s_reward-")) {
            const fpStr = fitpointsStrToDisplayStr(shortID.slice(9));
            if (!fpStr) return;
            const fpURL = displayStrToStringImageURL(fpStr);

            cached = [
                ItemCategory.Suit,
                <SuitVariant>{
                    name: `Reward suit (${fpStr})`,
                    urlMale: "assets/items/reward/s_reward/model-male.glb",
                    urlFemale: "assets/items/reward/s_reward/model-female.glb",
                    thumbnailMale: "assets/items/reward/s_reward/thumbnail-male.webp",
                    thumbnailFemale: "assets/items/reward/s_reward/thumbnail-female.webp",
                    materialsMale: {
                        primaryMaterial: {
                            pipeline: "Phong Opaque Textured",
                            diffuseTexture: "assets/items/reward/s_reward/primary-diffuse-male.webp",
                        },
                        secondaryMaterial: {
                            pipeline: "Phong Transparent Textured",
                            diffuseTexture: fpURL,
                        },
                        emissive: {
                            pipeline: "Phong Transparent Textured",
                            diffuseTexture: fpURL,
                        },
                    },
                    materialsFemale: {
                        primaryMaterial: {
                            pipeline: "Phong Opaque Textured",
                            diffuseTexture: "assets/items/reward/s_reward/primary-diffuse-female.webp",
                        },
                        secondaryMaterial: {
                            pipeline: "Phong Transparent Textured",
                            diffuseTexture: fpURL,
                        },
                        emissive: {
                            pipeline: "Phong Transparent Textured",
                            diffuseTexture: fpURL,
                        },
                    },
                },
            ];
        } else if (shortID.startsWith("hb_reward-")) {
            const fpStr = fitpointsStrToDisplayStr(shortID.slice(10));
            if (!fpStr) return;
            const fpURL = displayStrToStringImageURL(fpStr);

            cached = [
                ItemCategory.Hoverboard,
                <HoverboardVariant>{
                    name: `Reward hoverboard (${fpStr})`,
                    url: "assets/shared-parts/models/hb_racer.glb",
                    thumbnail: "assets/items/reward/hb_reward/thumbnail.webp",
                    materials: {
                        primaryMaterial: {
                            pipeline: "Phong Opaque Textured",
                            diffuseTexture: "assets/items/reward/hb_reward/primary-diffuse.webp"
                        },
                        secondaryMaterial: {
                            pipeline: "Phong Opaque",
                            diffuseColor: [
                                0.7992,
                                0.552,
                                0.117,
                                1.0
                            ]
                        },
                        tertiaryMaterial: {
                            pipeline: "Phong Opaque",
                            diffuseColor: [
                                1.0,
                                0.7011,
                                0.1046,
                                1.0
                            ]
                        },
                        emissive: {
                            pipeline: "Phong Opaque",
                            diffuseColor: [
                                0.8633,
                                0.0024,
                                0.0075,
                                1.0
                            ]
                        }
                    },
                    jetstreamColor: [
                        0.8633,
                        0.0024,
                        0.0075,
                        1.0
                    ],
                    logoLocalPosition: [
                        0,
                        0.012,
                        -2.2,
                    ],
                    logoLocalScaling: [
                        0.4,
                        0.4,
                        0.4,
                    ],
                    logoTexture: "assets/shared-parts/textures/heart-logo.webp",
                    decorations: [
                        {
                            type: "mesh",
                            mesh: "loft-mesh://256,128",
                            material: {
                                pipeline: "Flat Transparent Textured",
                                flatTexture: fpURL,
                            },
                            position: [0, 0.02, -2.3],
                            rotation: [-0.707, 0, 0, 0.707],
                            scaling: [0.4, 0.2, 1],
                        },
                    ],
                },
            ];
        }

        if (cached) this.cache.set(shortID, cached);
        return cached;
    }
}

export class RewardAssetProvider extends AssetProvider {
    private readonly manifest: RewardManifest;

    constructor(controller: IAPContentController) {
        super(controller);
        this.manifest = new RewardManifest();
        controller.registerDynamicManifest(ItemNamespace.Reward, this.manifest);
        common.playerData.listen(this.onInventoryChanged, "rewards");
    }

    private onInventoryChanged = () => {
        this.fetchInventory();
    };

    protected override async handleInventoryFetch(): Promise<void> {
        const ownedItems: OwnedItem[] = [];
        const rewards = common.playerData.rewards;

        const hLevel = rewards[0];
        if (hLevel > 0) ownedItems.push([`h_reward-${hLevel * FP_PER_HELMET_LEVEL}`, DEFAULT_ITEM_OWN_META]);
        const sLevel = rewards[1];
        if (sLevel > 0) ownedItems.push([`s_reward-${sLevel * FP_PER_SUIT_LEVEL}`, DEFAULT_ITEM_OWN_META]);
        const hbLevel = rewards[2];
        if (hbLevel > 0) ownedItems.push([`hb_reward-${hbLevel * FP_PER_BOARD_LEVEL}`, DEFAULT_ITEM_OWN_META]);

        const other = rewards[3];
        if (other & RewardOtherFlags.Founder) ownedItems.push(["hb_founder", DEFAULT_ITEM_OWN_META]);
        if (other & RewardOtherFlags.Halloween2024) ownedItems.push(["h_halloween2024", DEFAULT_ITEM_OWN_META], ["hb_halloween2024", DEFAULT_ITEM_OWN_META]);

        this.inventory.replaceNamespace(ownedItems, ItemNamespace.Reward);
    }

    override async purchaseItem(_shortID: string): Promise<boolean> {
        return false;
    }

    override dispose(): void {
        super.dispose();
        common.playerData.unlisten(this.onInventoryChanged);
    }
}