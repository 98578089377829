import { type Object3D, type ViewComponent, type WonderlandEngine } from "@wonderlandengine/api";
import { getBaseUrl } from "@wonderlandengine/api/utils/fetch.js";
import { PopupIconImage } from "src/hoverfit/ui/popup/popup.js";
import { LoadingSceneUIComponent } from "src/hoverfit/ui/xml-ui/components/loading-scene-ui-component.js";
import { wait } from "src/hoverfit/utils/wait.js";
import { Globals, vec3_create } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { common, destroyCommon, initCommon } from "../../common.js";
import { HoverboardGameConfig } from "../../data/game-configuration.js";
import { RoomData } from "../../network/components/hoverboard-networking-component.js";
import { DownloadAbortError, fetchCancellableProgress } from "../../utils/fetch-utils.js";
import { GameGlobals } from "../game-globals.js";


export let isAnotherSceneLoading = false;
let cancelCallback: (() => void) | null = null;

function deactivateRecursively(obj: Object3D, ignoreObj: Object3D) {
    if (obj === ignoreObj) return;

    for (const child of obj.children) {
        deactivateRecursively(child, ignoreObj);
    }

    obj.active = false;
}

export function cancelSceneLoad() {
    if (cancelCallback) {
        cancelCallback();
        return true;
    } else {
        return false;
    }
}

export class LoadCancelError extends Error {
    constructor() {
        super("Load cancelled");
    }
}

/**
 * Switch to another scene. Only attempts to load the new scene if the download
 * is successful. If downloading fails, an error is thrown. If scene loading
 * fails, the promise never resolves and an error message is shown to the user.
 * The screen fades to white when scene loading begins
 */
export function loadScene(engine: WonderlandEngine, newGameConfig: HoverboardGameConfig, newRoomData: RoomData | null = null) {
    if (isAnotherSceneLoading) return Promise.resolve();

    isAnotherSceneLoading = true;
    let cancelSceneDownload: (() => void) | null = null;
    let cancelled = false;

    cancelCallback = () => {
        if (cancelSceneDownload) {
            cancelSceneDownload();
            cancelSceneDownload = null;
        }

        cancelled = true;
    };

    const assetNotCancelled = () => {
        if (cancelled) throw new LoadCancelError();
    };

    // eslint-disable-next-line no-async-promise-executor
    return new Promise<void>(async (resolve, reject) => {
        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.emit("load-scene-start");

        common.popupManager.showQuickMessagePopup("Changing Location\n" + newGameConfig.locationConfig.name + " - " + newGameConfig.fancyMode, PopupIconImage.Info, "moving_to");
        common.kioskLowerUI.updateSceneConfigurationChangePopup(newGameConfig, "Loading...");

        let recoverable = true;
        let loadingSceneUIComponent: LoadingSceneUIComponent | null = null;

        let downloadTimeoutID = null;
        try {
            const switchingDuringIntro = !common.intro.isDone() && !common.intro.isFadingIn();
            // fade out
            assetNotCancelled();
            if (switchingDuringIntro) {
                await common.intro.manualFadeOut();
            }
            assetNotCancelled();

            const textObjPrevScene = engine.scene.addObject(Globals.getPlayerHeadObject());
            textObjPrevScene.pp_setPositionLocal(vec3_create(0, 0, 1));
            textObjPrevScene.pp_setScaleLocal(vec3_create(2, 2, 1));
            textObjPrevScene.rotateAxisAngleDegObject(GameGlobals.up, 180);

            const newMapAndModeText = newGameConfig.locationConfig.name + " - " + newGameConfig.fancyMode + "\n\n";

            if (switchingDuringIntro) {
                loadingSceneUIComponent = addLoadingSceneUIComponent(textObjPrevScene, newMapAndModeText + "Loading...");
            }

            let displayDownloadMapProgress = false;
            let downloadMapProgress = 0;
            downloadTimeoutID = setTimeout(() => {
                displayDownloadMapProgress = true;
                if (loadingSceneUIComponent != null && loadingSceneUIComponent.isReady()) {
                    loadingSceneUIComponent.showMovingToPopup(newMapAndModeText + "Downloading Map: " + downloadMapProgress.toFixed().padStart(2, "0") + "%");
                }

                common.kioskLowerUI.updateSceneConfigurationChangePopup(newGameConfig, "Downloading Map: " + downloadMapProgress.toFixed().padStart(2, "0") + "%");
            }, 1000);

            let promise: Promise<ArrayBuffer>;
            [promise, cancelSceneDownload] = fetchCancellableProgress(newGameConfig.sceneBinPath, (cur, total) => {
                downloadMapProgress = 100 * cur / total;

                if (cur !== 0 && displayDownloadMapProgress) {
                    if (loadingSceneUIComponent != null && loadingSceneUIComponent.isReady()) {
                        loadingSceneUIComponent.showMovingToPopup(newMapAndModeText + "Downloading Map: " + downloadMapProgress.toFixed().padStart(2, "0") + "%");
                    }

                    common.kioskLowerUI.updateSceneConfigurationChangePopup(newGameConfig, "Downloading Map: " + downloadMapProgress.toFixed().padStart(2, "0") + "%");
                }
            });

            const buffer = await promise;
            clearTimeout(downloadTimeoutID);
            assetNotCancelled();

            if (common.hoverboardNetworking.room) {
                const timeToWaitBetweenCanceledCheck = 0.25;
                let updateTextCountdown = Math.round(0.5 / timeToWaitBetweenCanceledCheck);

                common.hoverboardNetworking.changeGameConfigReady();

                while (!common.hoverboardNetworking.allPlayersReadyToChangeConfig()) {
                    assetNotCancelled();
                    await wait(timeToWaitBetweenCanceledCheck); // I'm doing this instead of just waiting for all players since I also need to check if it was canceled

                    if (updateTextCountdown > 0) {
                        updateTextCountdown--;
                        if (updateTextCountdown == 0) {
                            if (loadingSceneUIComponent != null && loadingSceneUIComponent.isReady()) {
                                loadingSceneUIComponent.showMovingToPopup(newMapAndModeText + "Waiting for everyone...");
                            }

                            common.kioskLowerUI.updateSceneConfigurationChangePopup(newGameConfig, "Waiting for everyone...");
                        }
                    }
                }
            }

            if (loadingSceneUIComponent != null && loadingSceneUIComponent.isReady()) {
                loadingSceneUIComponent.showMovingToPopup(newMapAndModeText + "Loading...");
            }

            common.kioskLowerUI.updateSceneConfigurationChangePopup(newGameConfig, "Loading...", true);

            if (!switchingDuringIntro) {
                await common.intro.manualFadeOut();

                loadingSceneUIComponent = addLoadingSceneUIComponent(textObjPrevScene, newMapAndModeText + "Loading...");
            }

            // #TODO REMOVE!!
            await wait(1);

            cancelSceneDownload = null;
            cancelCallback = null;
            assetNotCancelled();

            // clean up current scene

            if (common.hoverboardNetworking.room) {
                common.hoverboardNetworking.changeGameConfigDisconnect();
                // WARNING need to wait for room to disconnect, otherwise it's
                //         gonna crash once the room leave event is fired due to
                //         the scene being deactivated (disconnecting is not a
                //         synchronous operation)
                await common.hoverboardNetworking.tryDisconnect();
            }

            recoverable = false;

            // HACK pause the engine
            (engine.wasm as unknown as { _wl_application_stop: () => void })._wl_application_stop();

            common.audioManager.getAudio(AudioID.TRACK_MUSIC)?.stop();

            const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
            const ambientAudio = common.audioManager.getAudio(AudioID.AMBIENT);
            balconyMusicAudio!.fade(balconyMusicAudio!.getVolume(), 0, 1.0);
            ambientAudio!.fade(ambientAudio!.getVolume(), 0, 1.0);

            const views = new Set<ViewComponent>();
            for (const view of engine.scene.activeViews) {
                views.add(view);
            }

            for (const child of engine.wrapObject(0).children) {
                deactivateRecursively(child, textObjPrevScene);
            }

            // HACK show occluder sphere again because it was deactivated
            common.intro.forceOccluderSphere();

            // HACK same thing for views
            for (const view of views) {
                view.active = true;
            }

            await new Promise<void>((timeoutResolve) => setTimeout(() => timeoutResolve(), 750));

            // re-initialize common object
            destroyCommon();
            common.playerData.invalidateSceneData();
            initCommon();

            if (newRoomData != null) {
                common.roomData.roomNumber = newRoomData.roomNumber;
                common.roomData.privateRoom = newRoomData.privateRoom;
            } else {
                common.roomData.roomNumber = null;
                common.roomData.privateRoom = false;
            }

            common.gameConfig.copyFrom(newGameConfig);

            if (loadingSceneUIComponent != null) {
                loadingSceneUIComponent.sceneIsAboutToSwitch();
            }

            // load new scene
            const loadPromise = engine.scene.load({
                buffer,
                baseURL: getBaseUrl(newGameConfig.sceneBinPath),
            });
            // HACK have to set isAnotherSceneLoading to false before load,
            //      otherwise the promise is resolved too late and the new
            //      loaded scene thinks the scene is still loading
            isAnotherSceneLoading = false;
            // XXX event not fired because it's too late to do anything about
            //     scene switching at this point
            await loadPromise;
            engine.start();

            resolve();
        } catch (err) {
            console.error(err);

            if (recoverable) {
                if (err instanceof DownloadAbortError) {
                    if (loadingSceneUIComponent) loadingSceneUIComponent.object.destroy();
                    reject(new LoadCancelError());
                } else if (err instanceof LoadCancelError) {
                    if (loadingSceneUIComponent) loadingSceneUIComponent.object.destroy();
                    reject(err);
                } else if (loadingSceneUIComponent && loadingSceneUIComponent.isReady()) {
                    loadingSceneUIComponent.showMovingToPopup("ERROR\n\nPlease reload the page");
                    setTimeout(() => {
                        loadingSceneUIComponent!.object.destroy();
                        reject(err);
                    }, 2000);
                } else {
                    reject(err);
                }
            }
        } finally {
            if (downloadTimeoutID != null) {
                clearTimeout(downloadTimeoutID);
            }

            cancelCallback = null;
            cancelSceneDownload = null;
            isAnotherSceneLoading = false;
            MAIN_CHANNEL.emit("load-scene-end");
        }
    });
}

function addLoadingSceneUIComponent(object: Object3D, message: string): LoadingSceneUIComponent {
    const resources = common.sceneSwitcherVars;

    const loadingSceneUIComponent = object.addComponent(LoadingSceneUIComponent, {
        material: resources.uiMaterialNoDepth,
        unitsPerPixel: 0.002,
        collisionGroupsMask: 16,
        textureUniformName: "flatTexture",
        resolution: 2,
        xmlUrl: "loading-scene-ui.xml",
        allowScripts: false,
    })!;

    const readyCallback = () => {
        loadingSceneUIComponent.showMovingToPopup(message);
    };

    if (!loadingSceneUIComponent.isReady()) {
        loadingSceneUIComponent.registerReadyEventListener(0, readyCallback);
    } else {
        readyCallback();
    }

    return loadingSceneUIComponent;
}