export function setCookie(cname, cvalue, exdays) {
    if (typeof document === "undefined") return;
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function setCookieUntilMidnight(cname, cvalue) {
    if (typeof document === "undefined") return;
    const d = new Date();
    d.setHours(23);
    d.setMinutes(59);
    d.setSeconds(59);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function unsetCookie(cname) {
    document.cookie = cname + "=;Max-Age=0";
}

export function getCookie(cname) {
    if (typeof document === "undefined") return;
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}