import { GameMode } from "hoverfit-shared-netcode";
import { common } from "src/hoverfit/common.js";
import { AnalyticsUtils } from "wle-pp";
import { type ContestScoreValue } from "./leaderboard-score.js";
import { LeaderboardType } from "./leaderboards-manager.js";

export function submitPlayerRaceScore() {
    if (common.menu.bestLapTime > 1) {
        const globalScore = Math.floor(common.menu.bestLapTime * 1000);
        common.leaderboardsManager.getLeaderboard({
            type: LeaderboardType.Global,
            mode: GameMode.Race,
            location: common.gameConfig.location,
            track: common.gameConfig.trackConfig.id,
        }).submitScore(globalScore).then((_leaderboard) => {
            common.kioskUpperUI.pickCurrentLeaderboard();

            AnalyticsUtils.sendEvent("learderboard_score_submit");
        }).catch(() => {
            // This way this will be done both for guests and for failed submits

            common.playerData.addPendingLeaderboardScore(globalScore, common.gameConfig.location, common.gameConfig.trackConfig.id);
        });
    }

    const localScore = Math.floor(common.menu.finishTime * 1000);
    common.leaderboardsManager.getLeaderboard({
        type: LeaderboardType.Local,
        mode: GameMode.Race,
        location: common.gameConfig.location,
        track: common.gameConfig.trackConfig.id,
        lapsAmount: common.gameConfig.lapsAmount.value,
    }).submitScore(localScore).then((_leaderboard) => {
        common.kioskUpperUI.pickCurrentLeaderboard();
    });
}

export function submitPlayerContestScore() {
    // XXX get current time and make sure daily reset is applied so that player
    //     scores don't get submitted to the wrong leaderboard near the time of
    //     a reset
    const now = Date.now();
    common.playerData.checkDailyReset();
    const score: ContestScoreValue = [common.playerData.monthlySquats, now];

    common.leaderboardsManager.getLeaderboard({
        type: LeaderboardType.SquatContest,
    }).submitScore(score).then((_leaderboard) => {
        common.kioskUpperUI.pickCurrentLeaderboard();
    });
}

export const LeaderboardUtils = {
    submitPlayerRaceScore,
    submitPlayerContestScore,
} as const;