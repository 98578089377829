import { Component } from '@wonderlandengine/api';
import { property } from '@wonderlandengine/api/decorators.js';
import { common } from 'src/hoverfit/common.js';
import { Vec3Utils, vec3_create } from 'wle-pp';

const tempVec = vec3_create();

/** 
 * Component meant to control the behavior of the environment for optimal viewing experience while viewing the map from
 * different angles.
 */
export class EnvironmentSurroundingControllerComponent extends Component {
    static TypeName = 'environment-surrounding-controller';

    @property.float(1.0)
    scaleOnSmallBalcony!: number;

    @property.float(2.0)
    scaleOnBalconyOrTrack!: number;

    start() {
        common.environmentSourroundingController = this;
    }

    onMoveToSmallBalcony() {
        Vec3Utils.set(tempVec, this.scaleOnSmallBalcony, 1.0, this.scaleOnSmallBalcony);
        this.object.setScalingLocal(tempVec);
    }

    onMoveToBalconyOrTrack() {
        Vec3Utils.set(tempVec, this.scaleOnBalconyOrTrack, 1.0, this.scaleOnBalconyOrTrack);
        this.object.setScalingLocal(tempVec);
    }
}
