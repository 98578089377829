export class WaitQueue {
    private resolved = false;
    private readonly queue = new Array<() => void>();

    done() {
        if (this.resolved) return;
        this.resolved = true;
        for (const resolve of this.queue) {
            try {
                resolve();
            } catch (err) {
                console.error(err);
            }
        }
    }

    wait() {
        return new Promise<void>((resolve) => {
            if (this.resolved) {
                resolve();
            } else {
                this.queue.push(resolve);
            }
        });
    }
}