import { Component, Material, MeshComponent, type Object3D, type Texture } from "@wonderlandengine/api";
import { addDecorationObjects, cleanupDecorationObjects, getNonDecorationObjectByName, getNonDecorationObjectsByName, setMaterialFromMaterialConfig } from "src/hoverfit/misc/asset-loading.js";
import { type MaterialInterface } from "src/hoverfit/misc/asset-provision/asset-manifest-types.js";
import { ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { CancellablePromise, CancelledMarkerMap } from "src/hoverfit/utils/cancellable-promise.js";
import { quat2_create } from "wle-pp";
import { common } from "../../../common.js";

const BOARD_MATERIAL_INDEX_TO_KEY = ["primaryMaterial", "secondaryMaterial", "tertiaryMaterial", "emissive"] as const;
const BOARD_DECORATIONS_KEY = "board";

const tempDualQuat = quat2_create();

type LogoMaterial = {
    flatTexture: Texture,
    color: number[],
} & Material;

export class HoverboardSelectorComponent extends Component {
    static TypeName = "hoverboard-selector";

    private markers!: CancelledMarkerMap<Object3D>;

    start() {
        common.hoverboardSelector = this;
        this.markers = new CancelledMarkerMap();
    }

    setHoverboard(hoverboardVariant: string, hoverboardObject: Object3D, isConfigBoard: boolean, shareToNetwork: boolean) {
        const boardConfigObject = common.iapContentController.getAsset(hoverboardVariant, ItemCategory.Hoverboard);
        if (!boardConfigObject) return console.error("Can't load board ", hoverboardVariant);

        // this.setDefaultMeshData(hoverboardObject);
        const adjustedHoverboardObject = hoverboardObject.pp_getComponent(MeshComponent)!.object;
        const meshComponents = adjustedHoverboardObject.pp_getComponentsSelf(MeshComponent);
        const streamObjects = getNonDecorationObjectsByName(hoverboardObject, "Stream", true);
        const logoObject = getNonDecorationObjectByName(hoverboardObject, "Logo", true)!;
        const logoMeshComp = logoObject.getComponent("mesh")!;

        cleanupDecorationObjects(adjustedHoverboardObject, BOARD_DECORATIONS_KEY);

        const marker = this.markers.replace(hoverboardObject);
        const localRes = common.hoverfitSceneResources;
        CancellablePromise.wrapPromise(localRes.getObjectFromURL(boardConfigObject.url), marker).then((boardRoot) => {
            if (!boardRoot) return;

            const newHoverboardObject = getNonDecorationObjectByName(boardRoot, "Hoverboard", true)!;
            const newHoverboardMeshComponents = newHoverboardObject.pp_getComponentsSelf(MeshComponent);
            const newStreams: Object3D[] = [];
            let logoTargetObject: Object3D | null = null;

            const searchQueue = newHoverboardObject.children;
            while (searchQueue.length > 0) {
                const obj = searchQueue.pop()!;
                if (obj.name.match("Stream") != null) {
                    newStreams.push(obj);
                } else if (obj.name.match("Logo") != null) {
                    logoTargetObject = obj;
                } else {
                    newHoverboardMeshComponents.push(...obj.pp_getComponentsSelf(MeshComponent));
                    searchQueue.push(...obj.children);
                }
            }

            for (let i = 0; i < newHoverboardMeshComponents.length; i++) {
                const oldMeshComponent = meshComponents[i];
                oldMeshComponent.mesh = newHoverboardMeshComponents[i].mesh;
                setMaterialFromMaterialConfig(oldMeshComponent, (boardConfigObject.materials as { [key: string]: MaterialInterface })[BOARD_MATERIAL_INDEX_TO_KEY[i]], isConfigBoard, this.engine, marker);
            }

            let streamMaterial;
            for (let i = 0; i < streamObjects.length; i++) {
                const streamObject = streamObjects[i];
                const streamMeshComponent = streamObject.pp_getComponent(MeshComponent)!;
                if (i == 0) {
                    streamMaterial = streamMeshComponent.material!;
                    (streamMaterial as any).color = boardConfigObject.jetstreamColor;
                } else {
                    streamMeshComponent.material = streamMaterial;
                }

                if (i < newStreams.length) {
                    streamMeshComponent.active = true;
                    streamMeshComponent.mesh = newStreams[i].pp_getComponent(MeshComponent)!.mesh;
                    newStreams[i].getTransformLocal(tempDualQuat);
                    streamObject.setTransformLocal(tempDualQuat);
                } else {
                    streamMeshComponent.mesh = null;
                }
            }

            function getLogoTargetObject() {
                if (!logoTargetObject) {
                    const defaultBoard = common.hoverfitSceneResources.getObject("hoverboardDefaultRoot");
                    logoTargetObject = defaultBoard.pp_getObjectByName("Logo", true)!;
                }

                return logoTargetObject;
            };

            if (boardConfigObject.logoLocalPosition) {
                logoObject.setPositionLocal(boardConfigObject.logoLocalPosition);
            } else {
                logoObject.setPositionLocal(getLogoTargetObject().getPositionLocal());
            }

            if (boardConfigObject.logoLocalScaling) {
                logoObject.setScalingLocal(boardConfigObject.logoLocalScaling);
            } else {
                logoObject.setScalingLocal(getLogoTargetObject().getScalingLocal());
            }

            const logoTextureURL = boardConfigObject.logoTexture ?? "local://defaultLogoTexture";
            CancellablePromise.wrapPromise(localRes.getTextureFromURL(logoTextureURL), marker).then((tex) => {
                (logoMeshComp.material as unknown as LogoMaterial).flatTexture = tex;
            }).catch(CancellablePromise.ignoreCancel);

            // HACK get initial active state from logo mesh, since there isn't a
            //      standard way to check if the hoverboard is active
            addDecorationObjects(adjustedHoverboardObject, BOARD_DECORATIONS_KEY, boardConfigObject.decorations, logoMeshComp.active, isConfigBoard, marker);
        }).catch(CancellablePromise.ignoreCancel);

        const hoverboardRoom = common.hoverboardNetworking.room;
        if (shareToNetwork && hoverboardRoom != null) {
            hoverboardRoom.send("set-hoverboard-variant", { hoverboardVariant });
        }
    }

    onDestroy(): void {
        this.markers.dispose();
    }
}