import { Globals, Handedness, InputSourceType } from "wle-pp";

export function hapticFeedback(handedness: Handedness | null, strength: number, duration: number) {
    if (handedness != null) {
        if (Globals.getHandPose(handedness)!.getInputSourceTypeReal() == InputSourceType.GAMEPAD) {
            const gamepad = Globals.getGamepad(handedness);
            if (gamepad) gamepad.pulse(strength, duration);
        }
    }
}

export function hoverHapticFeedback(handedness: Handedness) {
    hapticFeedback(handedness, 0.25, 0.05);
}

export function unHoverHapticFeedback(handedness: Handedness) {
    //hapticFeedback(handedness, 0.3, 0.05);
}

export function downHapticFeedback(handedness: Handedness | null) {
    //hapticFeedback(handedness, 1.0, 0.02);
}

export function upHapticFeedback(handedness: Handedness | null) {
    hapticFeedback(handedness, 0.25, 0.05);
}