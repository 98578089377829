import { Component, Property } from "@wonderlandengine/api";
import { quat2_create } from "wle-pp";
import { common } from "../../common.js";

const INDEX_TO_KEY = {
    0: "qx",
    1: "qy",
    2: "qz",
    3: "qw",
    4: "px",
    5: "py",
    6: "pz",
    7: "pw"
};

export const KEY_TO_INDEX = {
    "qx": 0,
    "qy": 1,
    "qz": 2,
    "qw": 3,
    "px": 4,
    "py": 5,
    "pz": 6,
    "pw": 7
};

export const KEYS = Object.keys(KEY_TO_INDEX);

export function getSynchedParamKeyIndex(key) {
    return KEY_TO_INDEX[key];
}

// Component schema will be generated from the keys in this array
export const synchedObjects = ["head", "leftHand", "rightHand", "feet", "hoverboard"];

const synchedSchema = synchedObjects.reduce((acc, key) => {
    acc[key] = Property.object();
    return acc;
}, {});

export class NetworkSyncComponent extends Component {
    static TypeName = "network-sync";
    static Properties = synchedSchema;

    init() {
        common.networkSync = this;
    }

    start() {
        this.tempQuat2 = quat2_create();

        this.lastTransforms = {};
        for (const synchedObjectKey of synchedObjects) {
            this.lastTransforms[synchedObjectKey] = quat2_create();
        }

        this.lastHoverboardData = {
            currentSpeed: 0,
            currentYSpeed: 0,
            currentTurnSpeed: 0
        };

        this.resetSync();
    }

    resetSync() {
        this.forceSync = true;

        for (const synchedObjectKey of synchedObjects) {
            this.lastTransforms[synchedObjectKey].quat2_identity();
        }

        this.lastHoverboardData = {
            currentSpeed: 0,
            currentYSpeed: 0,
            currentTurnSpeed: 0
        };
    }

    networkInit() {
        let networkPackage = {};
        for (const synchedObjectKey of synchedObjects) {
            let currentObjectTransform = networkPackage[synchedObjectKey] = {};
            const transformWorld = this[synchedObjectKey].transformWorld;
            for (const [index, transformElement] of transformWorld.entries()) {
                currentObjectTransform[INDEX_TO_KEY[index]] = transformElement;
            }
        }

        common.hoverboardNetworking.room.send("transform-update", networkPackage);

        common.hoverboardNetworking.room.send("hoverboard-data-update", this.lastHoverboardData);
    }

    update(dt) {
        const hoverboardRoom = common.hoverboardNetworking.room;
        if (hoverboardRoom) {

            // Transform Changes Update
            {
                let networkPackage = {};
                let update = false;

                for (const synchedObjectKey of synchedObjects) {
                    const changes = this.getTransformChangesToSync(synchedObjectKey);
                    if (changes != null) {
                        networkPackage[synchedObjectKey] = changes;
                        update = true;
                    }
                }

                if (update) {
                    hoverboardRoom.send("transform-update", networkPackage);
                }
            }

            // Hoverboard Data Changes Update
            {
                let networkPackage = {};

                let changes = this.getHoverboardDataChangesToSync();

                if (changes != null) {
                    networkPackage.hoverboardData = changes;
                    hoverboardRoom.send("hoverboard-data-update", networkPackage);
                }
            }


            this.forceSync = false;
        }
    }

    getTransformChangesToSync(key) {
        let changes = null;
        const transformWorld = this[key].pp_getTransformWorldQuat(this.tempQuat2);
        for (const [index, transformElement] of transformWorld.entries()) {
            if (this.forceSync || Math.abs(transformElement - this.lastTransforms[key][index]) > 0.00001) {
                if (!changes) changes = {};
                changes[INDEX_TO_KEY[index]] = transformElement;
            }
        }

        if (changes != null) {
            this.lastTransforms[key].quat2_copy(transformWorld);
        }

        return changes;
    }

    getHoverboardDataChangesToSync() {
        let changes = null;

        if (Math.abs(common.hoverboard.currentSpeedAdjusted - this.lastHoverboardData.currentSpeed) > Math.PP_EPSILON) {
            if (!changes) changes = {};
            changes.currentSpeed = common.hoverboard.currentSpeedAdjusted;
            this.lastHoverboardData.currentSpeed = common.hoverboard.currentSpeedAdjusted;
        }

        if (Math.abs(common.hoverboard.currentYSpeedAdjusted - this.lastHoverboardData.currentYSpeed) > Math.PP_EPSILON) {
            if (!changes) changes = {};
            changes.currentYSpeed = common.hoverboard.currentYSpeedAdjusted;
            this.lastHoverboardData.currentYSpeed = common.hoverboard.currentYSpeedAdjusted;
        }

        if (Math.abs(common.hoverboard.currentTurnSpeedAdjusted - this.lastHoverboardData.currentTurnSpeed) > Math.PP_EPSILON) {
            if (!changes) changes = {};
            changes.currentTurnSpeed = common.hoverboard.currentTurnSpeedAdjusted;
            this.lastHoverboardData.currentTurnSpeed = common.hoverboard.currentTurnSpeedAdjusted;
        }

        return changes;
    }
}
