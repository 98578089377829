import { type ItemOwnershipMetadata } from "../asset-provision/item-ownership-metadata.js";
import { ObservableItemIDMap } from "./observable-item-id-map.js";

export class ObservableOwnedItemCollection extends ObservableItemIDMap<Readonly<ItemOwnershipMetadata>> {
    protected override shouldValueReplace(oldValue: Readonly<ItemOwnershipMetadata>, newValue: Readonly<ItemOwnershipMetadata>) {
        if (oldValue.expiresOn === 0) return false;
        return newValue.expiresOn === 0 || newValue.expiresOn > oldValue.expiresOn;
    }

    // NOTE if you add more metadata in the future, you have to override the
    //      getReplacingValue method and merge both the old and new metadata in
    //      the method. for now we only have item expiry in the metadata, so we
    //      can just use the default implementation which returns the new value,
    //      but as soon as we have more metadata properties, we have to override
    //      it
}