import { Checkbox, ClickableWidgetProperties, Label, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, Rect, Row, Spacing, Variable, WidgetEvent, WidgetProperties, filterIDFromProperties } from "lazy-widgets";
import { Handedness } from "wle-pp";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { common } from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";

class InternalClickyCheckbox extends Checkbox {
    private clickSound: { play: () => void };
    private hoverSound: { play: () => void };
    private inBounds: boolean;

    constructor(variable: Variable<boolean>, properties?: Readonly<ClickableWidgetProperties>) {
        super(variable, properties);

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK)!;
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER)!;
        this.inBounds = false;
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor.handedness as unknown as Handedness);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                this.clickSound.play();
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor.handedness as unknown as Handedness);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor.handedness as unknown as Handedness);
            }
        }

        return captured;
    }

    override set clickable(clickable) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }

    override handlePainting(dirtyRects: Rect[]) {
        super.handlePainting(dirtyRects);

        // add thin outline; it's hard to tell there's a checkbox when it's not
        // checked
        const ctx = this.viewport.context;
        ctx.fillStyle = "#666666";
        const actualLength = Math.min(this.checkboxLength, this.width, this.height);
        const offsetX = this.x + (this.width - actualLength) / 2;
        const offsetY = this.y + (this.height - actualLength) / 2;
        ctx.fillRect(offsetX, offsetY, actualLength, 1);
        ctx.fillRect(offsetX, offsetY + actualLength - 1, actualLength, 1);
        ctx.fillRect(offsetX, offsetY, 1, actualLength);
        ctx.fillRect(offsetX + actualLength - 1, offsetY, 1, actualLength);
    }
}

export class ClickyCheckbox extends Row {
    static override autoXML = {
        name: "clicky-checkbox",
        inputConfig: [
            {
                mode: "text",
                name: "text"
            },
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            }
        ],
    };

    constructor(text: string, variable: Variable<boolean>, properties?: WidgetProperties) {
        const filteredProperties = filterIDFromProperties(properties);

        super([
            new Label(text, filteredProperties),
            new Spacing(),
            new InternalClickyCheckbox(variable, filteredProperties),
        ], properties);
    }

    get clickable() {
        return (this._children[2] as InternalClickyCheckbox).clickable;
    }

    set clickable(clickable) {
        (this._children[2] as InternalClickyCheckbox).clickable = clickable;
    }
}
