import { common } from "src/hoverfit/common.js";
import { EasingFunction, MathUtils } from "wle-pp";
import { UICustomPopupHelperParams } from "../../xml-ui/ui-custom-popup-helper.js";

export class PopupTipParams {

    popupParamsCreateCallback!: () => UICustomPopupHelperParams;

    pickWeight: number = 1;

    disableAfterPick: boolean = false;

    cooldownOnPick: number = 0;
    activeChanceFunction: (cooldown: number, params: PopupTipParams) => number = (cooldown: number, params: PopupTipParams) => {
        const normalizedCooldown = MathUtils.mapToRange(cooldown, 0, params.cooldownOnPick / 2, 1, 0);
        const activeChance = EasingFunction.easeIn(normalizedCooldown);
        return activeChance;
    };

    validCallback: (() => boolean) | null = null;
}

export class PopupTip {

    private params: PopupTipParams;

    private currentCooldown: number = 0;
    private active: boolean = true;
    private enabled: boolean = true;

    constructor(params: PopupTipParams) {
        this.params = params;
    }

    getParams(): PopupTipParams {
        return this.params;
    }

    showPopup() {
        common.kioskLowerUI.showCustomPopup(this.params.popupParamsCreateCallback());
        this.currentCooldown = this.params.cooldownOnPick;
        this.enabled = !this.params.disableAfterPick;
    }

    decreaseCooldown() {
        this.currentCooldown = Math.max(0, this.currentCooldown - 1);
        this.active = MathUtils.random() < this.params.activeChanceFunction(this.currentCooldown, this.params);
    }

    isValid() {
        return this.active && this.enabled && (this.params.validCallback == null || this.params.validCallback());
    }
}