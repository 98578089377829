import { common } from "src/hoverfit/common.js";
import { PopupIconImage } from "src/hoverfit/ui/popup/popup.js";
import { wait } from "src/hoverfit/utils/wait.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { type OwnedItem } from "./owned-item.js";

export enum ItemNamespace {
    BuiltIn = "bi",
    IAP = "iap",
    Fitabux = "fitabux",
    Reward = "reward",
}

export enum ItemCategory {
    Skin = "skin",
    Hoverboard = "hoverboard",
    Headwear = "headwear",
    Suit = "suit",
    HairColor = "hairColor",
    Location = "location",
    Bundle = "bundle",
}

export type EquippableItemCategory = Exclude<ItemCategory, ItemCategory.Location | ItemCategory.Bundle>;

export enum HeadwearSubCategory {
    Hair = "hair",
    Helmet = "helmet",
}

export enum ItemCurrency {
    HeyVR = 0,
    Fitabux = 1,
}

export class GenericPurchaseError extends Error { }
export class NoFundsPurchaseError extends GenericPurchaseError {
    constructor(readonly currencyType: ItemCurrency) {
        super('Not enough funds');
    }
}

export abstract class AssetProvider {
    private inventoryReloadTimeout: number | null = null;
    private inventoryReloadTime = 0;
    private inventoryFetchInProgress = false;
    private inventoryRefetchQueued = false;

    async fetchInventory(): Promise<void> {
        // HACK prevent multiple inventory fetches from happening at the same
        //      time, otherwise you're gonna have a funky time with the item
        //      expiry system
        if (this.inventoryFetchInProgress) {
            this.inventoryRefetchQueued = true;
            return;
        }

        this.cancelInventoryReload();
        this.inventoryFetchInProgress = true;

        try {
            do {
                this.inventoryRefetchQueued = false;
                await this.handleInventoryFetch();
            } while (this.inventoryRefetchQueued);
        } finally {
            this.inventoryRefetchQueued = false;
            this.inventoryFetchInProgress = false;
        }
    }

    protected abstract handleInventoryFetch(): Promise<void>;
    abstract purchaseItem(shortID: string): Promise<boolean>;

    constructor(readonly controller: IAPContentController) { }

    get catalog() {
        return this.controller.catalog;
    }

    get inventory() {
        return this.controller.inventory;
    }

    dispose() {
        this.cancelInventoryReload();
    }

    protected cancelInventoryReload() {
        if (this.inventoryReloadTimeout === null) return;
        clearTimeout(this.inventoryReloadTimeout);
        this.inventoryReloadTimeout = null;
    }

    /**
     * Schedule an inventory reload after a given amount of milliseconds. You
     * are expected to cancel any inventory reload when starting to reload your
     * inventory by calling {@link AssetProvider#cancelInventoryReload}.
     *
     * Used for handling items that expire.
     */
    protected scheduleInventoryReload(delayMS: number) {
        this.cancelInventoryReload();
        this.inventoryReloadTime = Date.now() + delayMS;
        this.inventoryReloadTimeout = setTimeout(() => {
            this.inventoryReloadTimeout = null;
            this.fetchInventory();
        }, delayMS) as unknown as number;
    }

    protected async handleExpiredItems(expiredShortIDs: Set<string>, namespace: ItemNamespace) {
        // HACK we need to do this hacky garbage because ECS sucks
        while (!common.popupManager) { await wait(0); }

        for (const shortID of expiredShortIDs) {
            const asset = this.controller.getAsset(`${namespace}:${shortID}`);
            if (asset) {
                common.popupManager.showMessagePopup(`Your ${asset.name} has expired`, PopupIconImage.Info);
            }
        }
    }

    protected async handleGoodExpirableItems(goodExpirableShortIDs: Set<string>) { }

    /**
     * Replace items in inventory of a specific namespace with the given list.
     * All expired items are ignored (in case there is a data race where an API
     * returns an item whose expiry date is in the past) - a notification will
     * be shown if an item expiration is detected.
     *
     * Schedules an inventory reload relative to a given list of owned items. If
     * any item in the list expires, the closest expiry timestamp is picked,
     * otherwise, no inventory reload is scheduled.
     *
     * @param ownedItems A list of owned items, with short IDs. Mutable
     * @param expectedExpirableShortIDs A set of short IDs that could expire, in the ownedItems list, and that you're expected to have. If a new item is already expired and it's in this list, or if it's in this set but not in the new item list, a notification will be shown. Mutable
     */
    protected replaceOwnedItems(ownedItems: OwnedItem[], namespace: ItemNamespace, expectedExpirableShortIDs: Set<string>) {
        this.inventory.deduplicateIDValuePairs(ownedItems);

        let delayMS = Infinity;
        const now = Date.now();
        const goodExpirables = new Set<string>();

        for (let i = ownedItems.length - 1; i >= 0; i--) {
            const ownedItem = ownedItems[i];
            const shortID = ownedItem[0];
            const meta = ownedItem[1];

            if (meta.expiresOn === 0) {
                expectedExpirableShortIDs.delete(shortID);
                continue;
            }

            const thisDelayMS = meta.expiresOn - now;
            if (thisDelayMS <= 0) {
                ownedItems.splice(i, 1); // already expired
            } else {
                if (expectedExpirableShortIDs.delete(shortID)) {
                    goodExpirables.add(shortID);
                }

                delayMS = Math.min(thisDelayMS, delayMS);
            }
        }

        this.cancelInventoryReload();
        if (isFinite(delayMS)) this.scheduleInventoryReload(delayMS);

        this.inventory.replaceNamespace(ownedItems, namespace, false);

        this.handleExpiredItems(expectedExpirableShortIDs, namespace);
        this.handleGoodExpirableItems(goodExpirables);
    }

    /**
     * Set items in the inventory of a specific namespace. All expired items are
     * ignored - a notification will be shown if an item expiration is detected.
     * Does not unpack items.
     *
     * If the new items expire, and the owned items that they replace don't
     * expire or have an expiry date that lasts longer, and the current
     * scheduled reload is farther in the future than the potential new one,
     * then an inventory reload is scheduled.
     *
     * @param ownedItems A list of owned items, with short IDs. Mutable
     * @param expectedExpirableShortIDs A set of short IDs that could expire, in the ownedItems list. If a new item is already expired, and it's in this list, a notification will be shown. Immutable
     */
    protected addOwnedItems(ownedItems: OwnedItem[], namespace: ItemNamespace, expectedExpirableShortIDs: ReadonlySet<string>) {
        let delayMS = Infinity;
        const now = Date.now();
        const expiredShortIDs = new Set<string>();

        for (let i = ownedItems.length - 1; i >= 0; i--) {
            const ownedItem = ownedItems[i];
            const meta = ownedItem[1];

            if (meta.expiresOn === 0) continue;

            const shortID = ownedItem[0];
            const thisDelayMS = meta.expiresOn - now;
            if (thisDelayMS <= 0) {
                if (expectedExpirableShortIDs.has(shortID)) {
                    expiredShortIDs.add(shortID);
                }

                ownedItems.splice(i, 1); // already expired
                continue;
            }

            const curMeta = this.inventory.getShort(shortID, namespace);
            if (curMeta && curMeta.expiresOn === 0) continue;

            delayMS = Math.min(thisDelayMS, delayMS);
        }

        if (isFinite(delayMS) && (this.inventoryReloadTime < now || (now + delayMS) < this.inventoryReloadTime)) {
            this.scheduleInventoryReload(delayMS);
        }

        this.inventory.setManyShort(ownedItems, namespace);

        this.handleExpiredItems(expiredShortIDs, namespace);
    }
}