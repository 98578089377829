export function promptMicrophonePermissions() {
    return new Promise((resolve) => {
        navigator.mediaDevices.getUserMedia({
            video: false,
            // Firefox defaults to stereo mic, use mono to save bandwidth
            audio: {
                advanced: [
                    {
                        channelCount: 1,
                    },
                ],
            },
        }).then((result) => {
            let microphonePermission = "denied";
            if (result != null) {
                microphonePermission = "granted";
            }

            resolve(microphonePermission);
        }).catch((error) => {
            let microphonePermission = "error";
            if (error.name == "NotAllowedError") {
                microphonePermission = "denied";
            }

            resolve(microphonePermission);
        });
    });
}

export const VoIPUtils = {
    promptMicrophonePermissions
};