import { Component, Object3D, PhysXComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { Variable } from "lazy-widgets";
import { Globals, quat_create, vec3_create, Vector3 } from "wle-pp";
import { common } from "../../../common.js";
import { DisableFarObjectsComponent } from "../../../misc/components/enable/disable-far-objects-component.js";

export class BalconyComponent extends Component {
    static TypeName = "balcony";

    @property.object()
    playerBalconyStartPosition!: Object3D;

    isPlayerOnBalcony: Variable<boolean> = new Variable(false);
    firstMoveDone: boolean = false;

    private balconyPosition: Vector3 = vec3_create();
    private balconyForward: Vector3 = vec3_create();

    private disableFarObjectsComponents!: DisableFarObjectsComponent[];
    private preventGoingInsideBalconyCollider!: PhysXComponent;

    init() {
        common.balcony = this;
    }

    start() {
        this.playerBalconyStartPosition.pp_getPosition(this.balconyPosition);
        this.playerBalconyStartPosition.pp_getForward(this.balconyForward);

        this.disableFarObjectsComponents = Globals.getRootObject(this.engine)!.pp_getComponents(DisableFarObjectsComponent);
        this.preventGoingInsideBalconyCollider = Globals.getRootObject(this.engine)!.pp_getObjectByName("Prevent Going Inside Balcony")!.pp_getComponent(PhysXComponent)!;
        this.preventGoingInsideBalconyCollider.active = false;
    }

    moveToTrack(startingPosition = 0) {
        this.isPlayerOnBalcony.value = false;

        this.preventGoingInsideBalconyCollider.active = true;

        Globals.getPlayerLocomotion()!.setActive(false);
        //Globals.getPlayerObjects(this.engine).myPlayer.pp_setForward(vec3_create(GameGlobals.right);

        common.hoverboard.prepareHoverboardToRace(startingPosition);

        for (const disableFarObjectsComponent of this.disableFarObjectsComponents) {
            disableFarObjectsComponent.disableObjects();
        }

        common.hoverboardNetworking.setOwnFitPoints();

        this.firstMoveDone = true;
    }

    moveToBalcony(teleportEvenIfAlreadyOnBalcony = false) {
        if (this.isPlayerOnBalcony.value && !teleportEvenIfAlreadyOnBalcony) return;
        this.isPlayerOnBalcony.value = true;

        this.preventGoingInsideBalconyCollider.active = false;

        common.hoverboard.stopHoverboard();

        Globals.getPlayerLocomotion()!.setActive(true);
        const playerTransformManager = Globals.getPlayerTransformManager()!;
        playerTransformManager.teleportPosition(this.balconyPosition, true);
        playerTransformManager.move(vec3_create(0, -10, 0)); // Snap on ground

        const rotation = quat_create();
        rotation.quat_setForward(this.balconyForward);
        playerTransformManager.setRotationQuat(rotation);

        playerTransformManager.resetReal(true, false, false, true);
        playerTransformManager.resetHeadToReal();

        for (const disableFarObjectsComponent of this.disableFarObjectsComponents) {
            disableFarObjectsComponent.disableObjects();
        }

        common.kioskLowerUI.resetToMainKioskPage();

        common.hoverboardNetworking.setOwnFitPoints();

        this.firstMoveDone = true;
    }

    getPlayerBalconyStartPosition() {
        return this.playerBalconyStartPosition;
    }
}
