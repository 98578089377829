// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-lower.xml";

import { GameLocation, GameMode, VALID_CONFIGURATIONS, isValidConfiguration } from "hoverfit-shared-netcode";
import { type Background, ClickEvent, Icon, Label, type ObservableCallback, ObservableTransformer, Row, Theme, ValidatedVariable, Variable, Widget } from "lazy-widgets";
import { type WLRoot, type WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { common } from "src/hoverfit/common.js";
import { LocationVariant } from "src/hoverfit/misc/asset-provision/asset-manifest-types.js";
import { getAssetThumbnail, isItemCategoryEquippable } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { type ObservableItemIDCollectionEventListener } from "src/hoverfit/misc/data-structs/observable-item-id-collection.js";
import { getRandomName } from "src/hoverfit/misc/get-random-name.js";
import { HeyVR } from "src/hoverfit/misc/heyvr-sdk-provider.js";
import { BUILD_TIMESTAMP_FORMATTED } from "src/hoverfit/misc/version/build-timestamp-formatted.js";
import { VERSION_STRING } from "src/hoverfit/misc/version/version-string.js";
import { MEDALS_PER_TROPHY, RewardTier, getDailyMedalFitPointsThreshold } from "src/hoverfit/utils/reward-utils.js";
import { formatTimeDuration } from "src/hoverfit/utils/time-utils.js";
import { AnalyticsUtils, BrowserUtils, XRUtils } from "wle-pp";
import { HoverboardGameConfig } from "../../../data/game-configuration.js";
import { RewardType } from "../../../data/player-data.js";
import { Gender } from "../../../data/values/gender.js";
import { canJoinTrackNoDelay, getToTrackDelay, getToTrackMaxDelay } from "../../../game/track/track-utils.js";
import { type GenericPurchaseError, HeadwearSubCategory, ItemCategory, ItemCurrency, NoFundsPurchaseError } from "../../../misc/asset-provision/asset-provider.js";
import { RoomData } from "../../../network/components/hoverboard-networking-component.js";
import { RoomState } from "../../../network/room-proxy.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { ArrowDecoratedButton } from "../../lazy-widgets/widgets/arrow-decorated-button.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedButtonBadge } from "../../lazy-widgets/widgets/base-decorated-button.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { Carousel } from "../../lazy-widgets/widgets/carousel.js";
import { ClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { ClickyCheckbox } from "../../lazy-widgets/widgets/clicky-checkbox.js";
import { CustomisationButton } from "../../lazy-widgets/widgets/customisation-button.js";
import { DecoratedButtonLikePane } from "../../lazy-widgets/widgets/decorated-button-like-pane.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { HeaderPane } from "../../lazy-widgets/widgets/header-pane.js";
import { Hyperlink } from "../../lazy-widgets/widgets/hyperlink.js";
import { IconDecoratedButton } from "../../lazy-widgets/widgets/icon-decorated-button.js";
import { Numpad } from "../../lazy-widgets/widgets/numpad.js";
import { OptionButton } from "../../lazy-widgets/widgets/option-button.js";
import { PriceRow } from "../../lazy-widgets/widgets/price-row.js";
import { PurchaseButton } from "../../lazy-widgets/widgets/purchase-button.js";
import { ShopItemButton } from "../../lazy-widgets/widgets/shop-item-button.js";
import { StepperBar } from "../../lazy-widgets/widgets/stepper-bar.js";
import { StepperInput } from "../../lazy-widgets/widgets/stepper-input.js";
import { ThumbnailDecoratedButton } from "../../lazy-widgets/widgets/thumbnail-decorated-button.js";
import { TimeStepperInput } from "../../lazy-widgets/widgets/time-stepper-input.js";
import { WidgetDecoratedButton } from "../../lazy-widgets/widgets/widget-decorated-button.js";
import { getEffectivePrice } from "../../misc/getEffectivePrice.js";
import { REWARD_ID_PREFIX_H, REWARD_ID_PREFIX_HB, REWARD_ID_PREFIX_S, getPotentialRewardBoardLevel, getPotentialRewardHelmetID, getPotentialRewardHelmetLevel, getPotentialRewardSuitID, getPotentialRewardSuitLevel } from "../../misc/getRewardIDs.js";
import { sfTheme } from "../../misc/sf-theme.js";
import { RaceButtonState } from "../../xml-ui/components/pause-menu-component.js";
import { UICustomPopupHelper, UICustomPopupHelperParams } from "../../xml-ui/ui-custom-popup-helper.js";
import { AwardRow } from "../widgets/award-row.js";
import { Background9Slice } from "../widgets/background-9slice.js";
import { CreditList } from "../widgets/credit-list.js";
import { CustomiseGenderButton } from "../widgets/customise-gender-button.js";
import { CustomiseTabButton } from "../widgets/customise-tab-button.js";
import { DecoratedCheckbox } from "../widgets/decorated-checkbox.js";
import { DecoratedLabelCheckbox } from "../widgets/decorated-label-checkbox.js";
import { EventFilterWidget } from "../widgets/event-filter-widget.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { KioskItemGrid } from "../widgets/kiosk-item-grid.js";
import { KioskShopPopup } from "../widgets/kiosk-shop-popup.js";
import { KioskTabButton } from "../widgets/kiosk-tab-button.js";
import { KioskTabIconButton } from "../widgets/kiosk-tab-icon-button.js";
import { FP_PER_BOARD_LEVEL, FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL, LifetimeLevelDisplay } from "../widgets/lifetime-level-display.js";
import { LocationButton } from "../widgets/location-button.js";
import { PlayerList } from "../widgets/player-list.js";
import { UpperUIMode } from "./kiosk-upper-ui-component.js";

export const POLICY_URL_NICE = "pp.hover.fit";
export const TERMS_URL_NICE = "tc.hover.fit";
export const LICENSE_URL_NICE = "hover.fit/licences";
export const POLICY_URL = `http://${POLICY_URL_NICE}`;
export const TERMS_URL = `http://${TERMS_URL_NICE}`;
export const LICENSES_URL = `http://www.${LICENSE_URL_NICE}`;

export enum KioskPage {
    Rewards,
    Multiplayer,
    Play,
    Customise,
    Shop,
    Settings
}

enum MultiplayerLeftPanelBookPage {
    Disconnected = 0,
    Connected = 1
}

enum MultiplayerRightPanelBookPage {
    Host = 0,
    Join = 1,
    Connected = 2
}

export enum ShopPage {
    Boards,
    Suits,
    Hair,
    HairColors,
    Helmets,
    Locations,
    Bundles,
}

enum PopupPage {
    CustomPopup,
    TermsAndConditions,
    SceneConfigurationChange,
    Connecting,
    RewardsLegend,
    PurchaseConfirmation,
    PurchaseSuccess
}

function validateRoomID(str: string): [boolean, null | number] {
    if (str === "") {
        return [true, null];
    }

    str = str.slice(0, 7);

    const num = parseInt(str, 10);
    if (isNaN(num) || !isFinite(num) || num < 0) {
        return [false, null];
    }

    return [true, num];
}

// This is used to avoid showing the tutorial popup if you don't accept the policy
export let firstStartModeBackup: boolean = true;

export class KioskLowerUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-lower-ui";

    ready: boolean = false;

    private previousKioskPageNumber!: number;
    private kioskPage!: Variable<number>;
    private customPage!: Variable<number>;
    private shopPage!: Variable<number>;
    private settingPage!: Variable<number>;
    private showItemPage!: Variable<string>;
    private gender!: Variable<Gender>;
    private skinColor!: Variable<string>;
    private hoverboard!: Variable<string>;
    private suit!: Variable<string>;
    private headwear!: Variable<string>;
    private hairColor!: Variable<string>;
    private hostJoinRoomID!: ValidatedVariable<string, number | null>;
    private privateRoomVar!: Variable<boolean>;
    private npcsAmountInput!: StepperInput;
    private lapsAmountInput!: StepperInput;
    private tagDurationInput!: TimeStepperInput;
    private npcsDifficultyInput!: StepperInput;
    private trackInput!: StepperInput;
    toTrackButton!: DecoratedButton;
    toTrackButtonMP!: DecoratedButton;
    private location = new Variable<GameLocation | null>(null);
    private mode = new Variable<GameMode | null>(null);
    private track = new Variable<number | null>(null);
    private trackLabels = new Variable<string[]>([]);
    private lifetimeFitPoints!: Variable<number>;
    private dailyFitPoints!: Variable<number>;
    private bronzeMedals!: Variable<number>;
    private silverMedals!: Variable<number>;
    private goldMedals!: Variable<number>;
    private platinumMedals!: Variable<number>;
    private voipError!: Variable<string>;

    private itemBuyConfirmImage!: HTMLImageElement;
    private postPurchaseThumbnail!: HTMLImageElement;
    private notEnoughCoinsIcon!: HTMLImageElement;

    private popupBook!: Book;
    private customPopup!: UICustomPopupHelper;
    private mainEventFilter!: EventFilterWidget;
    private popupContainer!: Background;
    private tutorialButton!: DecoratedButton;
    private hostRoomPrivateCheckbox!: DecoratedLabelCheckbox;

    private changingSceneLabel!: Label;
    private changingSceneCancelButton!: DecoratedButton;

    private voipErrorWidget!: Widget;

    private updateMicrophoneBookPage: (() => void) | null = null;
    private updateTermsAndConditionsBookPage: (() => void) | null = null;
    private updateSubscribeNewsletterSettingBookPage: (() => void) | null = null;

    private checkEquipmentChangeEnabled: boolean = true;

    private modeButtons!: Map<GameMode, OptionButton>;
    private onConfigChange!: () => void;
    private lastUnlockedAll = false;
    private resetShopSelection: (() => void) | undefined;

    private watchKioskPage: (() => void) | undefined;
    private watchShowItemPage: (() => void) | undefined;

    private onTermsAndConditionsPopupAccepted: (() => void) | null = null;

    override init(): void {
        super.init();

        this.kioskPage = new Variable(2);
        this.previousKioskPageNumber = this.kioskPage.value;
        this.customPage = new Variable(1);
        this.shopPage = new Variable(0);
        this.settingPage = new Variable(0);
        this.showItemPage = new Variable("");
        this.gender = new Variable(0);
        this.skinColor = new Variable("");
        this.hoverboard = new Variable("");
        this.suit = new Variable("");
        this.headwear = new Variable("");
        this.hairColor = new Variable("");
        this.hostJoinRoomID = new ValidatedVariable<string, number | null>("", validateRoomID);
        this.privateRoomVar = new Variable(false);

        this.lifetimeFitPoints = new Variable(0);
        this.dailyFitPoints = new Variable(0);
        this.bronzeMedals = new Variable(0);
        this.silverMedals = new Variable(0);
        this.goldMedals = new Variable(0);
        this.platinumMedals = new Variable(0);

        this.voipError = new Variable("");

        this.itemBuyConfirmImage = new Image(); // XXX filled in later
        this.itemBuyConfirmImage.crossOrigin = 'anonymous';
        this.postPurchaseThumbnail = new Image(); // XXX filled in later
        this.postPurchaseThumbnail.crossOrigin = 'anonymous';
        this.notEnoughCoinsIcon = new Image();
        this.notEnoughCoinsIcon.crossOrigin = 'anonymous';

        this.gender.watch(this.watchGender);
        this.skinColor.watch(this.watchSkinColor);
        this.hoverboard.watch(this.watchHoverboard);
        this.suit.watch(this.watchSuit);
        this.headwear.watch(this.watchHeadwear);
        this.hairColor.watch(this.watchHairColor);
        common.iapContentController.inventory.watch(this.watchInventory);

        common.kioskLowerUI = this;
    }

    override onActivate(): void {
        super.onActivate();
    }

    override onDeactivate(): void {
        super.onDeactivate();
    }

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(ClickyButton);
        parser.autoRegisterFactory(PlayerList);
        parser.autoRegisterFactory(Carousel);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(KioskShopPopup);
        parser.autoRegisterFactory(DecoratedButton);
        parser.autoRegisterFactory(OptionButton);
        parser.autoRegisterFactory(CustomisationButton);
        parser.autoRegisterFactory(ShopItemButton);
        parser.autoRegisterFactory(Numpad);
        parser.autoRegisterFactory(ClickyCheckbox);
        parser.autoRegisterFactory(StepperInput);
        parser.autoRegisterFactory(TimeStepperInput);
        parser.autoRegisterFactory(LocationButton);
        parser.autoRegisterFactory(KioskTabButton);
        parser.autoRegisterFactory(KioskTabIconButton);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(CustomiseTabButton);
        parser.autoRegisterFactory(CustomiseGenderButton);
        parser.autoRegisterFactory(HeaderPane);
        parser.autoRegisterFactory(EventFilterWidget);
        parser.autoRegisterFactory(Background9Slice);
        parser.autoRegisterFactory(ArrowDecoratedButton);
        parser.autoRegisterFactory(IconDecoratedButton);
        parser.autoRegisterFactory(DecoratedButtonLikePane);
        parser.autoRegisterFactory(PurchaseButton);
        parser.autoRegisterFactory(PriceRow);
        parser.autoRegisterFactory(KioskItemGrid);
        parser.autoRegisterFactory(ThumbnailDecoratedButton);
        parser.autoRegisterFactory(AwardRow);
        parser.autoRegisterFactory(LifetimeLevelDisplay);
        parser.autoRegisterFactory(DecoratedCheckbox);
        parser.autoRegisterFactory(StepperBar);
        parser.autoRegisterFactory(WidgetDecoratedButton);
        parser.autoRegisterFactory(CreditList);
        parser.autoRegisterFactory(Hyperlink);
        parser.autoRegisterFactory(DecoratedLabelCheckbox);
        return parser;
    }

    protected override getRootProperties() {
        return {
            ...super.getRootProperties(),
            enablePasteEvents: !BrowserUtils.isMobile(), // XXX paste event handling shows virtual keyboard on mobile, so disable it for mobile
        };
    }

    private updateTrackLabels() {
        const curTracks = common.gameConfig.modeConfig.tracks;
        const trackCount = curTracks.length;
        const newLabels: string[] = [];
        for (let track = 0; track < trackCount; track++) {
            newLabels.push(curTracks[track].name.toUpperCase());
        }

        this.trackLabels.value = newLabels;
        return trackCount;
    }

    protected override getXMLParserConfig() {
        const trackCount = this.updateTrackLabels();

        const makeCustomisationButton = (selectedItem: Variable<string>, id: string) => {
            const meta = common.iapContentController.inventory.getRequired(id);
            return new CustomisationButton(id, common.iapContentController.getAssetRequired(id), selectedItem, this.gender, meta.expiresOn !== 0);
        };

        const makeShopItemButton = (id: string) => {
            return new ShopItemButton(common.iapContentController.catalog.getRequired(id), this.showItemPage, this.gender);
        };

        return {
            ...super.getXMLParserConfig(),
            variables: {
                kioskPage: this.kioskPage,
                customPage: this.customPage,
                shopPage: this.shopPage,
                settingPage: this.settingPage,
                musicVolume: common.playerData.gameSettings.musicVolume,
                quietMode: common.playerData.gameSettings.quietMode,
                voiceVolume: common.playerData.gameSettings.voiceVolume,
                microphoneEnabled: common.playerData.gameSettings.microphoneEnabled,
                voiceP2P: common.playerData.gameSettings.voiceP2P,
                voiceMediasoup: common.playerData.gameSettings.voiceMediasoup,
                gender: this.gender,
                board: this.hoverboard,
                skinColor: this.skinColor,
                suit: this.suit,
                headwear: this.headwear,
                hairColor: this.hairColor,
                disconnect: () => common.roomProxy.disconnect(),
                quickPlay: () => common.roomProxy.quickPlay(),
                cancelConfigurationChange: () => common.roomProxy.cancelConfigurationChange(),
                hostRoom: () => common.roomProxy.hostRoom(this.hostJoinRoomID.validValue, this.privateRoomVar.value),
                joinRoom: () => common.roomProxy.joinRoom(this.hostJoinRoomID.validValue),
                goToTrack: () => {
                    if (common.pauseMenu.getRaceButtonState().value == RaceButtonState.Start) {
                        this.goToTrack();
                    } else {
                        common.menu.returnToBalcony();
                    }
                },
                toggleTutorial: (clickEvent: ClickEvent) => common.kioskController.toggleTutorial(clickEvent.origin),
                equip: this.writeCurrentStyle.bind(this),
                numpadInputFilter: (inStr: string) => /^\d+$/.test(inStr),
                hostJoinRoomID: this.hostJoinRoomID,
                privateRoomVar: this.privateRoomVar,
                npcsAmount: common.gameConfig.npcsAmount,
                lapsAmount: common.gameConfig.lapsAmount,
                tagDuration: common.gameConfig.tagDuration,
                npcsDifficulty: common.gameConfig.npcsDifficulty,
                location: this.location,
                mode: this.mode,
                track: this.track,
                trackLabels: this.trackLabels,
                trackMax: trackCount - 1,
                lightShopTheme: new Theme({
                    bodyTextFill: 'black',
                }, sfTheme),
                itemBuyConfirmImage: this.itemBuyConfirmImage,
                itemPostPurchaseImage: this.postPurchaseThumbnail,
                notEnoughCoinsIcon: this.notEnoughCoinsIcon,
                lifetimeFitPoints: new ObservableTransformer([this.lifetimeFitPoints], () => `${Math.floor(this.lifetimeFitPoints.value)}`),
                dailyFitPoints: new ObservableTransformer([this.dailyFitPoints], () => `${Math.floor(this.dailyFitPoints.value)}`),
                bronzeMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.bronzeMedals.value % MEDALS_PER_TROPHY),
                silverMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.silverMedals.value % MEDALS_PER_TROPHY),
                goldMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.goldMedals.value % MEDALS_PER_TROPHY),
                platinumMedals: new ObservableTransformer([this.lifetimeFitPoints], () => this.platinumMedals.value % MEDALS_PER_TROPHY),
                bronzeTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.bronzeMedals.value / MEDALS_PER_TROPHY)),
                silverTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.silverMedals.value / MEDALS_PER_TROPHY)),
                goldTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.goldMedals.value / MEDALS_PER_TROPHY)),
                platinumTrophies: new ObservableTransformer([this.lifetimeFitPoints], () => Math.floor(this.platinumMedals.value / MEDALS_PER_TROPHY)),
                fpPerBronzeMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Bronze)}`,
                fpPerSilverMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Silver)}`,
                fpPerGoldMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Gold)}`,
                fpPerPlatinumMedal: `${getDailyMedalFitPointsThreshold(RewardTier.Platinum)}`,
                medalsPerTrophy: `${MEDALS_PER_TROPHY}x`,
                skinItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.Skin,
                hoverboardItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.Hoverboard,
                suitItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.Suit,
                hairItemFilterMale: (id: string) => {
                    const asset = common.iapContentController.getAsset(id, ItemCategory.Headwear);
                    return asset && asset.type === HeadwearSubCategory.Hair && asset.avatarType == Gender.Male;
                },
                hairItemFilterFemale: (id: string) => {
                    const asset = common.iapContentController.getAsset(id, ItemCategory.Headwear);
                    return asset && asset.type === HeadwearSubCategory.Hair && asset.avatarType == Gender.Female;
                },
                hairColorItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.HairColor,
                helmetItemFilter: (id: string) => {
                    const asset = common.iapContentController.getAsset(id, ItemCategory.Headwear);
                    return asset && asset.type === HeadwearSubCategory.Helmet;
                },
                locationItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.Location,
                bundleItemFilter: (id: string) => common.iapContentController.getAssetClass(id) === ItemCategory.Bundle,
                skinColorCustomItemGenerator: makeCustomisationButton.bind(this, this.skinColor),
                hoverboardCustomItemGenerator: makeCustomisationButton.bind(this, this.hoverboard),
                suitCustomItemGenerator: makeCustomisationButton.bind(this, this.suit),
                headwearCustomItemGenerator: makeCustomisationButton.bind(this, this.headwear),
                hairColorCustomItemGenerator: makeCustomisationButton.bind(this, this.hairColor),
                inventory: common.iapContentController.inventory,
                makeShopItemButton,
                catalog: common.iapContentController.catalog,
                closePopup: this.closePopup.bind(this),
                refreshShopItemPage: () => {
                    const id = this.showItemPage.value;
                    this.showItemPage.value = "";
                    this.showItemPage.value = id;
                },
                deniedMicrophone: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "PERMISSIONS DENIED";
                    popupParams.message = "The microphone permissions have been denied.\n\nIf you want to enable them, you have to go into your browser settings and change your settings.";
                    popupParams.primaryButtonText = "DONE";
                    popupParams.primaryButtonClickCallback = () => {
                        common.hoverboardNetworking.updateMicrophonePermissions("granted");
                        this.closePopup();

                        if (common.hoverboardNetworking.microphonePermission!.value == "prompt") {
                            this.showPopup();
                            if (XRUtils.isSessionActive()) {
                                this.displayRequestMicrophoneVRWarningPopup();
                            } else {
                                this.displayRequestMicrophonePopup();
                            }
                        } else {
                            if (common.playerData.gameSettings.policyAccepted.value) {
                                common.hoverboardNetworking.promptMicrophonePermissions();
                            }
                        }
                    };
                    popupParams.lowPriorityButtonText = "CANCEL";
                    popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                    this.showCustomPopup(popupParams);
                },
                deniedPolicyMicrophone: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "POLICY DENIED";
                    popupParams.message = "The policy permissions have been denied.\n\nTo be able to use the microphone, you have to read and accept them.";
                    popupParams.primaryButtonText = "POLICY";
                    popupParams.primaryButtonClickCallback = () => {
                        this.closePopup();

                        this.displayTermsAndConditions();
                    };
                    popupParams.lowPriorityButtonText = "CANCEL";
                    popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                    this.showCustomPopup(popupParams);
                },
                requestMicrophone: () => {
                    if (XRUtils.isSessionActive()) {
                        this.displayRequestMicrophoneVRWarningPopup();
                    } else {
                        this.displayRequestMicrophonePopup();
                    }
                },
                microphoneError: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "MICROPHONE ERROR";
                    popupParams.message = "An errors has occurred while enabling the microphone.\n\nPlease try again, and, if that doesn't help, reload the page.";
                    popupParams.primaryButtonText = "TRY AGAIN";
                    popupParams.primaryButtonClickCallback = () => {
                        this.closePopup();

                        if (common.playerData.gameSettings.policyAccepted.value) {
                            if (XRUtils.isSessionActive()) {
                                const popupParams = new UICustomPopupHelperParams();
                                popupParams.title = "LEAVING VR";
                                popupParams.message = "To try again to enable the microphone, you might be taken out of the current VR session.";
                                popupParams.primaryButtonText = "TRY AGAIN";
                                popupParams.primaryButtonClickCallback = () => {
                                    this.closePopup();

                                    if (common.playerData.gameSettings.policyAccepted.value) {
                                        this.displayRequestMicrophoneVisiblePopup();
                                    }
                                };
                                popupParams.lowPriorityButtonText = "CANCEL";
                                popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                                this.showCustomPopup(popupParams);
                            } else {
                                this.displayRequestMicrophoneVisiblePopup();
                            }
                        }
                    };
                    popupParams.lowPriorityButtonText = "CANCEL";
                    popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                    this.showCustomPopup(popupParams);
                },
                microphoneInfo: () => {
                    this.showCustomInfoPopup("HAVING ISSUES?", "If you are still having issues with the microphone, check the microphone settings on your\noperating system to be sure it's enabled and not muted.");
                },
                shareRoom: () => {
                    if (!common.hoverboardNetworking.room) return;

                    let shareRoomURL = window.location.href.replace(/^https?:\/\//, '').split('?')[0];

                    if (window.location.hostname.endsWith(".heyvr.io")) {
                        shareRoomURL += "?heyvr_room=";
                    } else {
                        shareRoomURL += "?room=";
                    }

                    shareRoomURL += common.hoverboardNetworking.room.id;

                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "SHARE ROOM";
                    popupParams.message = "You can use this link to share your room:\n\n" + shareRoomURL;
                    popupParams.primaryButtonText = "COPY";
                    popupParams.primaryButtonClickCallback = () => {
                        if (window.navigator && window.navigator.clipboard) {
                            window.navigator.clipboard.writeText(shareRoomURL);
                        }
                    };
                    popupParams.lowPriorityButtonText = "CLOSE";
                    popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                    this.showCustomPopup(popupParams);
                },
                voipError: this.voipError,
                generateRandomName: () => common.playerData.name = getRandomName(common.playerData),
                toggleVoipDebugMenu: () => common.MAIN_CHANNEL.emit("toggle_voip_debug"),
                showTermsAndConditions: () => {
                    this.displayTermsAndConditions();
                },
                showBuildVersion: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "GAME VERSION";
                    popupParams.message = "Version - " + VERSION_STRING + "\n" +
                        "Time - " + BUILD_TIMESTAMP_FORMATTED + "\n" +
                        "Branch - " + (BUILD_BRANCH ?? "<unknown>");
                    popupParams.messageFontSpacing = 10;
                    popupParams.primaryButtonText = "CLOSE";
                    popupParams.primaryButtonClickCallback = () => { this.closePopup(); };

                    this.showCustomPopup(popupParams);
                },
                openLicensesPage: () => {
                    if (XRUtils.isSessionActive()) {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "LEAVING VR";
                        popupParams.message = "To open the licenses page, you will be taken out of the current VR session.";
                        popupParams.primaryButtonText = "OPEN";
                        popupParams.primaryButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                            BrowserUtils.openLink(LICENSES_URL);
                        };
                        popupParams.lowPriorityButtonText = "CANCEL";
                        popupParams.lowPriorityButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        popupParams.closeButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        this.showCustomPopup(popupParams);
                    } else {
                        BrowserUtils.openLink(LICENSES_URL);
                    }
                },
                nicePolicyURL: POLICY_URL_NICE,
                niceTermsURL: TERMS_URL_NICE,
                openPrivacyPolicy: () => {
                    if (XRUtils.isSessionActive()) {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "LEAVING VR";
                        popupParams.message = "To open the privacy policy page, you will be taken out of the current VR session.";
                        popupParams.primaryButtonText = "OPEN";
                        popupParams.primaryButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                            BrowserUtils.openLink(POLICY_URL);
                        };
                        popupParams.lowPriorityButtonText = "CANCEL";
                        popupParams.lowPriorityButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        popupParams.closeButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        this.showCustomPopup(popupParams);
                    } else {
                        BrowserUtils.openLink(POLICY_URL);
                    }
                },
                openTermsAndConditions: () => {
                    if (XRUtils.isSessionActive()) {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "LEAVING VR";
                        popupParams.message = "To open the terms and conditions page, you will be taken out of the current VR session.";
                        popupParams.primaryButtonText = "OPEN";
                        popupParams.primaryButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                            BrowserUtils.openLink(TERMS_URL);
                        };
                        popupParams.lowPriorityButtonText = "CANCEL";
                        popupParams.lowPriorityButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        popupParams.closeButtonClickCallback = () => {
                            this.popupBook.changePage(PopupPage.TermsAndConditions);
                        };

                        this.showCustomPopup(popupParams);
                    } else {
                        BrowserUtils.openLink(TERMS_URL);
                    }
                },
                denyTermsAndConditions: () => {
                    this.closePopup();
                    common.playerData.gameSettings.policyAccepted.value = false;
                },
                acceptTermsAndConditions: () => {
                    this.closePopup();
                    common.playerData.gameSettings.policyAccepted.value = true;
                    if (this.onTermsAndConditionsPopupAccepted != null) {
                        this.onTermsAndConditionsPopupAccepted();
                        this.onTermsAndConditionsPopupAccepted = null;
                    }
                },
                subscribeNewsletter: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "PRIZES & UPDATES";
                    popupParams.message = "Subscribe to enter the Squat Contest prize draw and get the latest HoverFit offers and news.\n\nSigning up will allow us to contact you with any winning notifications and promotional information.";
                    popupParams.primaryButtonText = "SUBSCRIBE";
                    popupParams.primaryButtonClickCallback = () => {
                        common.playerData.subscribe();
                        this.closePopup();
                    };
                    popupParams.lowPriorityButtonText = "CLOSE";
                    popupParams.lowPriorityButtonClickCallback = () => {
                        this.closePopup();
                    };

                    this.showCustomPopup(popupParams);
                },
                unsubscribeNewsletter: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "UNSUBSCRIBE";
                    popupParams.message = "Do you wish to unsubscribe from the HoverFit newsletter?\n\nNOTE: Unsubscribing will remove you from the Squat Contest prize draw and other prize challeges.";
                    popupParams.primaryButtonText = "UNSUBSCRIBE";
                    popupParams.primaryButtonClickCallback = () => {
                        common.playerData.unsubscribe();
                        this.closePopup();
                    };
                    popupParams.lowPriorityButtonText = "CLOSE";
                    popupParams.lowPriorityButtonClickCallback = () => {
                        this.closePopup();
                    };

                    this.showCustomPopup(popupParams);
                }
            },
        };
    }

    protected override onRootReady(root: WLRoot): void {
        // HACK this cast shouldn't be necessary. there's an issue with types in
        //      lazy-widgets-wle. FIXME in lazy-widgets-wle
        super.onRootReady(root as WLVirtualKeyboardRoot);

        const mainEventFilter = root.getWidgetByID("main-event-filter") as EventFilterWidget;
        const popupContainer = root.getWidgetByID("popup-container") as Background;
        const popupBook = root.getWidgetByID("popup-book") as Book;
        this.rewardsTabButton = root.getWidgetByID("rewards-tab-button") as KioskTabButton;

        common.playerData.listen(this.onFitpointsChange, "totalFitPoints");
        common.playerData.listen(this.onRewardsChange, "rewards");

        this.popupBook = popupBook;
        this.mainEventFilter = mainEventFilter;
        this.popupContainer = popupContainer;

        root.getWidgetByID("rewards-legend-button").on("click", () => {
            this.showPopup();
            popupBook.changePage(PopupPage.RewardsLegend);
        });

        root.getWidgetByID("helmetLevelDisplay").on("click", () => {
            const wasEquipped = this.headwear.value.startsWith(REWARD_ID_PREFIX_H);

            common.playerData.upgradeReward(RewardType.Helmet);

            if (wasEquipped) {
                this.headwear.value = getPotentialRewardHelmetID();
                this.writeCurrentStyle();
            }
        });
        root.getWidgetByID("suitLevelDisplay").on("click", () => {
            const wasEquipped = this.suit.value.startsWith(REWARD_ID_PREFIX_S);

            common.playerData.upgradeReward(RewardType.Suit);

            if (wasEquipped) {
                this.suit.value = getPotentialRewardSuitID();
                this.writeCurrentStyle();
            }
        });
        root.getWidgetByID("boardLevelDisplay").on("click", () => {
            const wasEquipped = this.hoverboard.value.startsWith(REWARD_ID_PREFIX_HB);

            common.playerData.upgradeReward(RewardType.Board);

            if (wasEquipped) {
                this.hoverboard.value = getPotentialRewardSuitID();
                this.writeCurrentStyle();
            }
        });

        this.onConfigChange = () => {
            const location = this.location.value!;
            const locConfig = VALID_CONFIGURATIONS.get(location)!;
            const mode = this.mode.value!;
            const modeConfig = locConfig.modes.get(mode)!;
            const track = modeConfig.defaultTrack;
            const trackConfig = modeConfig.tracks[track]!;

            AnalyticsUtils.sendEvent("change_map", {
                gameMap: trackConfig.map,
            });

            const newGameConfig = new HoverboardGameConfig();
            newGameConfig.location = location;
            newGameConfig.mode = mode;
            newGameConfig.track = track;

            if (location !== common.gameConfig.location && !isValidConfiguration(newGameConfig, common.iapContentController.isLocationOwned(newGameConfig.location))) {
                // not a valid config. maybe the mode is locked/unavailable?
                // switch to default mode
                newGameConfig.mode = locConfig.defaultMode;
                newGameConfig.track = locConfig.defaultModeConfig.defaultTrack;
            }

            if (!newGameConfig.matches(common.gameConfig)) {
                common.menu.changeGameConfig(new RoomData(common.roomData), newGameConfig);
            }
        };

        this.modeButtons = new Map<GameMode, OptionButton>([
            [GameMode.Race, root.getWidgetByID("race-mode-button") as OptionButton],
            [GameMode.Tag, root.getWidgetByID("tag-mode-button") as OptionButton],
            [GameMode.Roam, root.getWidgetByID("roam-mode-button") as OptionButton],
        ]);

        const locationRow = root.getWidgetByID("location-row") as Row;
        for (const location of Array.from(VALID_CONFIGURATIONS.keys()).sort()) {
            const locationButton = new LocationButton(location, this.location);
            locationButton.on("click", this.onConfigChange);

            locationRow.add(locationButton);
        }


        this.location.value = common.gameConfig.location;
        this.mode.value = common.gameConfig.mode;
        this.track.value = common.gameConfig.track;

        this.location.watch(this.watchLocation, true);
        this.track.watch(this.watchTrack);

        const cBook = root.getWidgetByID("custom-book") as Book;
        const sBook = root.getWidgetByID("shop-book") as Book;
        const siBook = root.getWidgetByID("shop-or-item-book") as Book;
        const purchaseItemButton = root.getWidgetByID("purchaseItemButton") as PurchaseButton;
        const hairInventoryBook = root.getWidgetByID("hair-inventory-book") as Book;
        const hairShopBook = root.getWidgetByID("hair-shop-book") as Book;
        const pickModeBook = root.getWidgetByID("pick-mode-book") as Book;
        const hairToggle = root.getWidgetByID("hairToggle") as ThumbnailDecoratedButton;
        const styleToggle = root.getWidgetByID("styleToggle") as ThumbnailDecoratedButton;

        const hairBookVar = new Variable(0);
        // XXX unwatch never called, but should be safe since it's UI-only logic
        hairBookVar.watch(() => {
            hairInventoryBook.changePage(hairBookVar.value);
            pickModeBook.changePage(hairBookVar.value == 0 ? 0 : 1);
        });
        hairToggle.on("click", () => { hairBookVar.value = 0; });
        styleToggle.on("click", () => { hairBookVar.value = this.gender.value == Gender.Male ? 1 : 2; });

        hairBookVar.value = this.gender.value == Gender.Male ? 1 : 2;
        hairShopBook.changePage(this.gender.value == Gender.Male ? 0 : 1);
        // XXX unwatch never called, but should be safe since it's UI-only logic
        this.gender.watch(() => {
            hairShopBook.changePage(this.gender.value == Gender.Male ? 0 : 1);

            if (hairBookVar.value == 0) return;
            hairBookVar.value = this.gender.value == Gender.Male ? 1 : 2;
        });

        this.resetShopSelection = () => {
            this.readCurrentStyle();
            this.showItemPage.value = "";
            sBook.changePage(this.shopPage.value);
            this.closePopup();
        };

        const kBook = root.getWidgetByID("kiosk-book") as Book;
        kBook.changePage(this.kioskPage.value);

        this.tutorialButton = root.getWidgetByID("tutorial-button") as DecoratedButton;

        this.watchKioskPage = () => {
            if (this.kioskPage.value === this.previousKioskPageNumber) return;

            if (this.previousKioskPageNumber === 3 && this.checkEquipmentChangeEnabled) {
                const iapCC = common.iapContentController;
                const dirty = this.gender.value !== common.playerData.avatarType
                    || this.hoverboard.value !== iapCC.adjustedHoverboardVariant.value
                    || this.skinColor.value !== iapCC.adjustedSkinColor.value
                    || this.suit.value !== iapCC.adjustedSuitVariant.value
                    || (this.headwear.value !== iapCC.adjustedHeadwearVariantMale.value && this.gender.value == Gender.Male)
                    || (this.headwear.value !== iapCC.adjustedHeadwearVariantFemale.value && this.gender.value == Gender.Female)
                    || this.hairColor.value !== iapCC.adjustedHairColor.value;

                if (dirty) {
                    const queuedPageSwitch = this.kioskPage.value;
                    this.kioskPage.value = this.previousKioskPageNumber;

                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "CHANGE EQUIPMENT?";
                    popupParams.message = "Would you like to keep your changes?";
                    popupParams.primaryButtonText = "KEEP";
                    popupParams.primaryButtonClickCallback = () => {
                        this.writeCurrentStyle();
                        this.kioskPage.value = queuedPageSwitch;
                    };
                    popupParams.secondaryButtonText = "REVERT";
                    popupParams.secondaryButtonClickCallback = () => {
                        this.readCurrentStyle();
                        this.kioskPage.value = queuedPageSwitch;
                    };

                    this.showCustomPopup(popupParams);
                    return;
                }
            }

            kBook.changePage(this.kioskPage.value);

            if (this.kioskUpperMode(this.kioskPage.value) == UpperUIMode.CustomisationPreview) {
                this.readCurrentStyle();
            }

            // Close tutorial if open
            if (common.kioskController.tutorialActive) {
                common.kioskController.setTutorialActive(false, this.tutorialButton);
            }

            // Fade between menu and balcony music
            if (common.balcony.isPlayerOnBalcony.value) {
                if (this.previousKioskPageNumber == KioskPage.Shop) {
                    const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                    balconyMusicAudio!.fade(0.0, balconyMusicAudio!.getDefaultVolume(), 0.8);
                    const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                    shopMusicAudio!.fade(shopMusicAudio!.getVolume(), 0.0, 0.8);
                } else if (this.kioskPage.value == KioskPage.Shop) {
                    const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC);
                    balconyMusicAudio!.fade(balconyMusicAudio!.getVolume(), 0.0, 0.8);
                    const shopMusicAudio = common.audioManager.getAudio(AudioID.SHOP_MUSIC);
                    shopMusicAudio!.fade(0.0, shopMusicAudio!.getDefaultVolume(), 0.8);
                }
            }

            // reset shop
            this.resetShopSelection!();

            // Check if top page should change
            if (this.kioskPage.value != KioskPage.Settings) {
                const kioskUpperMode = this.kioskUpperMode(this.kioskPage.value);
                if (kioskUpperMode !== null && (kioskUpperMode !== common.kioskUpperUI.getMode() || common.kioskController.reactivateExternally)) {
                    if (kioskUpperMode == UpperUIMode.CustomisationPreview) {
                        this.readCurrentStyle();
                        common.kioskController.setHoloDisplayExternalReactivation(() => {
                            common.kioskUpperUI.changeMode(kioskUpperMode);
                            common.kioskController.setConfigAvatarActive(true);
                            common.kioskController.setConfigBoardActive(true);
                        });
                    } else if (kioskUpperMode == UpperUIMode.Stats) {
                        this.readCurrentStyle();
                        common.kioskController.setHoloDisplayExternalReactivation(() => {
                            common.kioskUpperUI.changeMode(kioskUpperMode);
                            common.kioskController.setConfigAvatarActive(true);
                            common.kioskController.setConfigBoardActive(false);
                        });
                    } else {
                        common.kioskController.setHoloDisplayExternalReactivation(() => {
                            this.readCurrentStyle();
                            common.kioskUpperUI.changeMode(kioskUpperMode);
                            common.kioskController.setConfigAvatarActive(false);
                            common.kioskController.setConfigBoardActive(false);
                        });
                    }
                }
            }

            this.previousKioskPageNumber = this.kioskPage.value;
        };

        this.kioskPage.watch(this.watchKioskPage, true);

        // XXX unwatch never called, but should be safe since it's UI-only logic
        this.customPage.watch(() => {
            pickModeBook.enabled = this.customPage.value === 3;
            cBook.changePage(this.customPage.value);
        }, true);
        this.shopPage.watch(this.resetShopSelection, true);
        this.showItemPage.watch(() => siBook.changePage(this.showItemPage.value !== "" ? 1 : 0));

        const mpTitleLabel = root.getWidgetByID("multiplayer-title") as Label;
        const mpLeftPanelBook = root.getWidgetByID("multiplayer-left-panel-book") as Book;
        const mpRightPanelBook = root.getWidgetByID("multiplayer-right-panel-book") as Book;

        this.hostRoomPrivateCheckbox = root.getWidgetByID("hostRoomPrivateCheckbox") as DecoratedLabelCheckbox;
        this.hostRoomPrivateCheckbox.setTicked(false);

        mpTitleLabel.text = "START MULTIPLAYER";
        mpLeftPanelBook.changePage(MultiplayerLeftPanelBookPage.Disconnected);
        mpRightPanelBook.changePage(MultiplayerRightPanelBookPage.Join);

        // XXX unwatch never called, but should be safe since it's UI-only logic
        common.roomProxy.watch((rp) => {
            if (rp.value == RoomState.Disconnected) {
                mpTitleLabel.text = "START MULTIPLAYER";
                mpLeftPanelBook.changePage(MultiplayerLeftPanelBookPage.Disconnected);
                mpRightPanelBook.changePage(MultiplayerRightPanelBookPage.Join);
                this.hostJoinRoomID.value = "";
                this.hostJoinRoomID.value = "";
                this.hostRoomPrivateCheckbox.setTicked(false);
            } else if (rp.value == RoomState.Connected) {
                mpTitleLabel.text = common.roomProxy.currentRoomText.value.toUpperCase();
                mpLeftPanelBook.changePage(MultiplayerLeftPanelBookPage.Connected);
                mpRightPanelBook.changePage(MultiplayerRightPanelBookPage.Connected);
            } else if (rp.value == RoomState.Connecting) {
                this.showPopup();
                this.popupBook.changePage(PopupPage.Connecting);
            }
        }, true);

        root.getWidgetByID("hostRoomButton").on("click", () => {
            mpRightPanelBook.changePage(MultiplayerRightPanelBookPage.Host);
            this.hostJoinRoomID.value = "";
            this.hostRoomPrivateCheckbox.setTicked(false);
        });

        root.getWidgetByID("joinRoomButton").on("click", () => {
            mpRightPanelBook.changePage(MultiplayerRightPanelBookPage.Join);
            this.hostJoinRoomID.value = "";
            this.hostRoomPrivateCheckbox.setTicked(false);
        });

        root.getWidgetByID("itemBackButton").on("click", this.resetShopSelection);

        purchaseItemButton.on("click", () => {
            popupContainer.enabled = true;
            mainEventFilter.filterEnabled = true;

            if (HeyVR && common.playerData.isGuest) {
                const popupParams = new UICustomPopupHelperParams();
                popupParams.title = "LOGIN REQUIRED";
                popupParams.message = "You need to be logged in to be able to buy items from the shop.";
                popupParams.primaryButtonText = "LOGIN";
                popupParams.primaryButtonClickCallback = () => {
                    if (XRUtils.isSessionActive()) {
                        this.displayLoginVRWarning();
                    } else {
                        this.closePopup();

                        common.playerData.openLogin();
                    }
                };
                popupParams.lowPriorityButtonText = "CANCEL";
                popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                this.showCustomPopup(popupParams);
            } else {
                popupBook.changePage(PopupPage.PurchaseConfirmation);
            }
        });

        const itemPostPurchaseEquipButton = root.getWidgetByID("itemPostPurchaseEquipButton");
        itemPostPurchaseEquipButton.on("click", () => {
            const id = this.showItemPage.value;
            switch (common.iapContentController.getAssetClass(id)) {
                case ItemCategory.Hoverboard:
                    this.hoverboard.value = id;
                    break;
                case ItemCategory.Suit:
                    this.suit.value = id;
                    break;
                case ItemCategory.Headwear:
                    this.headwear.value = id;
                    break;
                case ItemCategory.HairColor:
                    this.hairColor.value = id;
                    break;
                default:
                    break;
            }
            this.writeCurrentStyle();
            this.closePopup();
        });

        const doRoomJoinButton = root.getWidgetByID("doRoomJoinButton") as Numpad;
        const doRoomHostButton = root.getWidgetByID("doRoomHostButton") as Numpad;
        const updateClickable = () => {
            doRoomJoinButton.submitClickable = this.hostJoinRoomID.valid && this.hostJoinRoomID.validValue !== null;
            doRoomHostButton.submitClickable = this.hostJoinRoomID.valid && this.hostJoinRoomID.validValue !== null;
        };
        updateClickable();
        this.hostJoinRoomID.watch(updateClickable);

        const trackDelayText = 'LOADING...';
        this.toTrackButton = root.getWidgetByID("toTrackButton") as DecoratedButton;
        this.toTrackButton.child.text = trackDelayText;
        this.toTrackButtonMP = root.getWidgetByID("toTrackButtonMP") as DecoratedButton;
        this.toTrackButtonMP.child.text = trackDelayText;

        this.npcsAmountInput = root.getWidgetByID("npcsAmountInput") as StepperInput;
        this.lapsAmountInput = root.getWidgetByID("lapsAmountInput") as StepperInput;
        this.tagDurationInput = root.getWidgetByID("tagDurationInput") as TimeStepperInput;
        this.npcsDifficultyInput = root.getWidgetByID("npcsDifficultyInput") as StepperInput;
        this.trackInput = root.getWidgetByID("trackInput") as StepperInput;

        this.handleLocationOwnershipChange();

        const postPurchasePageLabel = root.getWidgetByID("itemPostPurchaseLabel") as Label;
        const purchaseItemConfirmButton = root.getWidgetByID("purchaseItemConfirmButton") as DecoratedButton;
        const origPurchaseButtonLabel = purchaseItemConfirmButton.child.text;
        purchaseItemConfirmButton.on("click", () => {
            purchaseItemConfirmButton.clickable = false;
            purchaseItemConfirmButton.child.text = "PURCHASING...";

            const handleFailure = (err?: GenericPurchaseError) => {
                if (err && (err instanceof NoFundsPurchaseError)) {
                    if (err.currencyType == ItemCurrency.HeyVR) {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "NOT ENOUGH HEYVR COINS";
                        popupParams.titleIconPath = "assets/textures/ui/icons/kiosk/heyvr-coin.svg";
                        popupParams.titleFontSize = 1.8;
                        popupParams.titleIconSpacing = 2;
                        popupParams.message = "You need to buy HeyVR coins to be able\nto get this item.";
                        popupParams.primaryButtonText = "BUY COINS";
                        popupParams.primaryButtonClickCallback = () => {
                            if (XRUtils.isSessionActive()) {
                                this.displayBuyHeyVRCoinsVRWarning();
                            } else {
                                this.closePopup();

                                AnalyticsUtils.sendEventOnce("open_buy_heyvr_coins");
                                BrowserUtils.openLink("https://heyvr.io/market", true, true, true);
                            }
                        };
                        popupParams.lowPriorityButtonText = "CANCEL";
                        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                        this.showCustomPopup(popupParams);
                    } else if (err.currencyType == ItemCurrency.Fitabux) {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "NOT ENOUGH FITABUX";
                        popupParams.titleIconPath = "assets/textures/ui/icons/kiosk/fitabux.svg";
                        popupParams.titleFontSize = 1.8;
                        popupParams.message = "Every earned Fit Points is converted\nto a Fitabux, so you just need to play\nthe game to get more!";
                        popupParams.primaryButtonText = "PLAY";
                        popupParams.primaryButtonClickCallback = () => {
                            this.closePopup();
                            this.goToTrack();
                        };
                        popupParams.lowPriorityButtonText = "CANCEL";
                        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

                        this.showCustomPopup(popupParams);
                    } else {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "NOT ENOUGH COINS";
                        popupParams.message = "You need more coins to be able\nto get this item.";
                        popupParams.primaryButtonText = "CLOSE";

                        popupParams.primaryButtonClickCallback = () => { this.closePopup(); };

                        this.showCustomPopup(popupParams);
                    }
                } else {
                    this.showCustomInfoPopup("TRANSACTION FAILED", err?.message ?? "Unknown error occurred");
                }
            };

            const item = common.iapContentController.catalog.get(this.showItemPage.value);
            item?.purchase().then((success) => {
                if (!success) {
                    handleFailure(undefined);
                    return;
                }
                const itemName = item.name;
                postPurchasePageLabel.text = itemName.toUpperCase();
                this.postPurchaseThumbnail.src = getAssetThumbnail(item.asset, this.gender.value);
                siBook.changePage(0);
                popupBook.changePage(PopupPage.PurchaseSuccess);
                itemPostPurchaseEquipButton.enabled = isItemCategoryEquippable(item.itemCategory);
                common.audioManager.getAudio(AudioID.PURCHASE)!.play();
            }).catch((e) => {
                handleFailure(e);
            }).finally(() => {
                purchaseItemConfirmButton.clickable = true;
                purchaseItemConfirmButton.child.text = origPurchaseButtonLabel;
            });
        });

        // Set item page labels
        const itemNameLabel = root.getWidgetByID("itemNameLabel") as Label;
        const itemDescLabel = root.getWidgetByID("itemDescLabel") as Label;
        const itemThumbnail = root.getWidgetByID("itemThumbnail") as Icon;
        const itemBuyConfirmLabel = root.getWidgetByID("itemBuyConfirmLabel") as Label;
        const shopGenderRow = root.getWidgetByID("shop-gender-row");
        const shopGenderPlacebo = root.getWidgetByID("shop-gender-placebo");
        const popupPriceRow = root.getWidgetByID("popup-price-row") as PriceRow;

        this.watchShowItemPage = () => {
            const id = this.showItemPage.value;
            if (!id) return;

            const item = common.iapContentController.catalog.getRequired(id);
            const itemName = item!.name;
            const [effectivePrice, discounted] = getEffectivePrice(item!.price, item!.priceDiscounted);

            let showGenderRow = false;
            switch (item!.itemCategory) {
                case ItemCategory.Hoverboard:
                    this.hoverboard.value = item!.id;
                    break;
                case ItemCategory.Suit:
                    this.suit.value = item!.id;
                    showGenderRow = true;
                    break;
                case ItemCategory.Headwear:
                    this.headwear.value = item!.id;
                    break;
                case ItemCategory.HairColor:
                    this.hairColor.value = item!.id;
                    break;
                default:
                    break;
            }

            shopGenderRow.enabled = showGenderRow;
            shopGenderPlacebo.enabled = !showGenderRow;

            itemNameLabel.text = itemName;
            const desc = item?.description ?? 'No item description';
            itemDescLabel.text = `${desc}${item.expiresAfter === 0 ? "" : `\n\nThis item will expire after ${formatTimeDuration(item.expiresAfter)}`}`;
            // XXX assume image is an HTMLImageElement. if we ever use an
            // AsyncImageBitmap as the backing media (e.g. for tinted images),
            // we will have to change this
            (itemThumbnail.image as HTMLImageElement).src = getAssetThumbnail(item.asset, this.gender.value);
            purchaseItemButton.price = effectivePrice;
            purchaseItemButton.discounted = discounted;
            purchaseItemButton.currency = item?.currencyType ?? ItemCurrency.HeyVR; // TODO don't
            popupPriceRow.price = effectivePrice;
            popupPriceRow.currency = item?.currencyType ?? ItemCurrency.HeyVR;
            itemBuyConfirmLabel.text = itemName.toUpperCase();
            this.itemBuyConfirmImage.src = getAssetThumbnail(item.asset, this.gender.value);
        };
        this.showItemPage.watch(this.watchShowItemPage);

        const settingsDevButton = root.getWidgetByID("settings-dev-button") as KioskTabIconButton;
        settingsDevButton.enabled = DEV_MODE;

        const settingsBook = root.getWidgetByID("settings-book") as Book;
        this.settingPage.watch(() => {
            settingsBook.changePage(this.settingPage.value);
        }, true);

        this.changingSceneLabel = root.getWidgetByID("changing-scene-label") as Label;
        this.changingSceneCancelButton = root.getWidgetByID("changing-scene-cancel-button") as DecoratedButton;

        this.customPopup = new UICustomPopupHelper(root, () => { this.closePopup(); });

        this.voipErrorWidget = root.getWidgetByID("voip-error");

        const microphoneBook = root.getWidgetByID("microphone-book") as Book;
        this.updateMicrophoneBookPage = () => {
            const policyAccepted = common.playerData.gameSettings.policyAccepted.value;
            const microphonePermission = common.hoverboardNetworking.microphonePermission!.value;
            const adjustedMicrophonePermission = policyAccepted ? (
                (microphonePermission == null || microphonePermission == "unsupported") ? "error" : microphonePermission) :
                "policy";

            microphoneBook.changePage(["prompt", "denied", "policy", "error", "granted"].indexOf(adjustedMicrophonePermission));
        };

        common.playerData.gameSettings.policyAccepted.watch(this.updateMicrophoneBookPage, true);
        common.hoverboardNetworking.microphonePermission!.watch(this.updateMicrophoneBookPage, true);

        const termsAndConditionsBook = root.getWidgetByID("terms-and-conditions-book") as Book;
        this.updateTermsAndConditionsBookPage = () => {
            const policyAccepted = common.playerData.gameSettings.policyAccepted.value;
            termsAndConditionsBook.changePage(policyAccepted ? 0 : 1);
        };
        common.playerData.gameSettings.policyAccepted.watch(this.updateTermsAndConditionsBookPage, true);

        const subscribeNewsletterSettingBook = root.getWidgetByID("subscribe-newsletter-setting-book") as Book;
        subscribeNewsletterSettingBook.changePage(common.playerData.subscribed ? 1 : 0);
        this.updateSubscribeNewsletterSettingBookPage = () => {
            subscribeNewsletterSettingBook.changePage(common.playerData.subscribed ? 1 : 0);
        };
        common.playerData.listen(this.updateSubscribeNewsletterSettingBookPage, "subscribed");

        const subscribeNewsletterSetting = root.getWidgetByID("subscribe-newsletter-setting") as Widget;
        subscribeNewsletterSetting.enabled = !common.playerData.isGuest;

        this.onAuthChanged = (changedKey?: string) => {
            if (changedKey != "auth_changed") return;

            subscribeNewsletterSetting.enabled = !common.playerData.isGuest;

            this.resetToMainKioskPage();
        };
        common.playerData.listen(this.onAuthChanged, "auth_changed");

        this.ready = true;

        this.onFitpointsChange();
        this.onRewardsChange();
    }



    protected override beforeWidgetUpdate(_root: WLVirtualKeyboardRoot, _dt: number): boolean | void {
        if (DEV_MODE) {
            const unlockAll = common.iapContentController.unlockAllLocations;
            if (this.lastUnlockedAll !== unlockAll) {
                this.lastUnlockedAll = unlockAll;
                this.handleLocationOwnershipChange();
            }
        }

        const joinDelay = getToTrackDelay();
        const delayFinished = joinDelay <= 0;
        const canJoin = delayFinished && canJoinTrackNoDelay();
        this.toTrackButton.clickable = canJoin;
        this.toTrackButtonMP.clickable = canJoin;

        if (!delayFinished) {
            const delayPercentage = Math.min(Math.round((1 - joinDelay / getToTrackMaxDelay()) * 100), 99);
            const percentageText = String(delayPercentage).padStart(2, "0") + "%";
            let loadingText = percentageText;
            if ((delayPercentage > 10) && (delayPercentage < 20)) {
                loadingText = "Avatar Data";
            } else if ((delayPercentage > 30) && (delayPercentage < 40)) {
                loadingText = "Environment";
            } else if ((delayPercentage > 50) && (delayPercentage < 60)) {
                loadingText = "Track Elements";
            } else if ((delayPercentage > 70) && (delayPercentage < 80)) {
                loadingText = "Audio Zones";
            } else if ((delayPercentage > 90) && (delayPercentage < 95)) {
                loadingText = "Spawn Points";
            }
            const padCount = (delayPercentage % 4);
            const padText = '.'.repeat(padCount);
            const loadWord = padText + "LOADING" + padText;
            const trackDelayText = loadWord + "\n" + loadingText;
            this.toTrackButton.child.text = trackDelayText;
            this.toTrackButtonMP.child.text = trackDelayText;
        } else {
            if (common.balcony.isPlayerOnBalcony!.value) {
                const newText = `Start ${common.gameConfig.fancyMode}`.toUpperCase();
                common.kioskLowerUI.toTrackButton.child.text = newText;
                common.kioskLowerUI.toTrackButtonMP.child.text = newText;
            } else {
                common.kioskLowerUI.toTrackButton.child.text = "To Balcony".toUpperCase();
                common.kioskLowerUI.toTrackButtonMP.child.text = "To Balcony".toUpperCase();
            }
        }

        const allowChange = common.menu.getPlayersOnTrack(true) === 0;
        const allowChangeNPCs = allowChange && common.gameConfig.canHaveNPCs;
        this.npcsAmountInput.clickable = allowChangeNPCs;
        this.lapsAmountInput.enabled = common.gameConfig.mode != GameMode.Tag;
        this.lapsAmountInput.clickable = allowChange && common.gameConfig.mode == GameMode.Race;
        this.tagDurationInput.enabled = common.gameConfig.mode == GameMode.Tag;
        this.tagDurationInput.clickable = allowChange;
        this.npcsDifficultyInput.clickable = allowChangeNPCs;
        this.trackInput.clickable = allowChange;
        this.track.setValue(common.gameConfig.track, "net-sync");
        this.lifetimeFitPoints.value = common.playerData.totalFitPoints;
        this.dailyFitPoints.value = common.playerData.dailyFitPoints;
        this.bronzeMedals.value = common.playerData.bronzeMedals;
        this.silverMedals.value = common.playerData.silverMedals;
        this.goldMedals.value = common.playerData.goldMedals;
        this.platinumMedals.value = common.playerData.platinumMedals;

        // This is to force this popup even if something else makes it disappear
        if (common.roomProxy.changingGameConfig) {
            if (this.kioskPage.value != KioskPage.Play ||
                !this.popupContainer.enabled ||
                !this.mainEventFilter.filterEnabled ||
                !this.popupBook.isInPage(PopupPage.SceneConfigurationChange)) {
                this.resetToMainKioskPage();
                this.showPopup();
                this.popupBook.changePage(PopupPage.SceneConfigurationChange);
            }
        } else if (common.roomProxy.state == RoomState.Connecting) {
            if (!this.popupContainer.enabled ||
                !this.mainEventFilter.filterEnabled ||
                !this.popupBook.isInPage(PopupPage.Connecting)) {
                this.showPopup();
                this.popupBook.changePage(PopupPage.Connecting);
            }
        } else {
            if (this.popupContainer.enabled &&
                (this.popupBook.isInPage(PopupPage.SceneConfigurationChange) || this.popupBook.isInPage(PopupPage.Connecting))) {
                this.closePopup();
            }
        }
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    gameModeChanged() {
        this.resetToMainKioskPage();

        const trackInput = this.root!.getWidgetByID("trackInput") as StepperInput;
        this.track.unwatch(this.watchTrack);
        const trackCount = this.updateTrackLabels();
        trackInput.setBounds(0, trackCount - 1);
        this.track.watch(this.watchTrack);

        this.mode.value = common.gameConfig.mode;
    }

    goToTrack() {
        if (common.playerData.gameSettings.showTutorialOnStart.value && firstStartModeBackup) {
            this.displayAskTutorialPopup();
        } else {
            common.menu.moveToTrack();
        }
    }

    displayAskTutorialPopup(showSkipButton = true) {
        common.kioskController.setTutorialActive(false, this.tutorialButton);

        this.resetToMainKioskPage();

        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "WELCOME!";
        popupParams.message = "If this is your first visit to HoverFit,\nplease check out the tutorial.";
        popupParams.primaryButtonText = "TUTORIAL";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();
            this.kioskPage.value = KioskPage.Play;

            firstStartModeBackup = false;
            common.playerData.gameSettings.showTutorialOnStart.value = false;

            common.kioskController.setTutorialActive(true, this.tutorialButton);
        };

        if (showSkipButton) {
            popupParams.lowPriorityButtonText = "SKIP";
            popupParams.lowPriorityButtonClickCallback = () => {
                this.closePopup();

                firstStartModeBackup = false;
                common.playerData.gameSettings.showTutorialOnStart.value = false;

                common.menu.moveToTrack();
            };
        }

        popupParams.closeButtonClickCallback = () => {
            this.closePopup();

            firstStartModeBackup = false;
            common.playerData.gameSettings.showTutorialOnStart.value = false;
        };

        this.showCustomPopup(popupParams);
    }

    changeKioskPage(kioskPage: KioskPage) {
        this.kioskPage.value = kioskPage;
    }

    changeKioskShopPage(shopPage: ShopPage) {
        this.shopPage.value = shopPage;
    }

    resetToMainKioskPage() {
        if (!this.ready) return;

        // This is to trigger a full reset to main page if already on main page

        this.closePopup();

        const checkEquipmentChangeEnabledBackup = this.checkEquipmentChangeEnabled;
        this.checkEquipmentChangeEnabled = false;

        this.kioskPage.value = KioskPage.Multiplayer;
        this.kioskPage.value = KioskPage.Play;

        this.checkEquipmentChangeEnabled = checkEquipmentChangeEnabledBackup;
    }

    showPopup() {
        this.popupContainer.enabled = true;
        this.mainEventFilter.filterEnabled = true;
    }

    closePopup() {
        this.popupContainer.enabled = false;
        this.mainEventFilter.filterEnabled = false;
    }

    setVoIPErrorEnabled(enabled: boolean, errorCode: number) {
        this.voipErrorWidget.enabled = enabled;
        this.voipError.value = "Error 0x" + errorCode.toString(16).padStart(4, "0");
    }

    showCustomPopup(popupParams: UICustomPopupHelperParams) {
        this.customPopup.setupPopup(popupParams);
        this.showPopup();
        this.popupBook.changePage(PopupPage.CustomPopup);
    }

    showCustomInfoPopup(title: string, message: string) {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = title;
        popupParams.message = message;
        popupParams.primaryButtonText = "CLOSE";
        popupParams.primaryButtonClickCallback = () => this.closePopup();

        this.showCustomPopup(popupParams);
    }

    displayFitPointsInfo() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "FIT POINTS";
        popupParams.titleIconPath = "assets/textures/ui/icons/kiosk/fitpoints.svg";
        popupParams.message = "Fit Points are earned just by playing!\nYou will earn them while working out.\nRewards will also be given based on the amount you have!";
        popupParams.primaryButtonText = "REWARDS";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();
            this.kioskPage.value = KioskPage.Rewards;
        };
        popupParams.secondaryButtonText = "PLAY";
        popupParams.secondaryButtonClickCallback = () => {
            this.closePopup();
            this.goToTrack();
        };
        popupParams.lowPriorityButtonText = "CLOSE";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    displayFitabuxInfo() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "FITABUX";
        popupParams.titleIconPath = "assets/textures/ui/icons/kiosk/fitabux.svg";
        popupParams.message = "Fitabux are earned just by playing!\nYou will earn them while working out.\nFitabux can be used to get items in the shop.";
        popupParams.primaryButtonText = "SHOP";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();
            this.kioskPage.value = KioskPage.Shop;
            this.shopPage.value = ShopPage.Helmets;
        };
        popupParams.secondaryButtonText = "PLAY";
        popupParams.secondaryButtonClickCallback = () => {
            this.closePopup();
            this.goToTrack();
        };
        popupParams.lowPriorityButtonText = "CLOSE";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    displayHeyVRCoinsInfo() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "HEYVR COINS";
        popupParams.titleIconPath = "assets/textures/ui/icons/kiosk/heyvr-coin.svg";
        popupParams.message = "Fitabux are earned just by playing!\nYou will earn them while working out.\nFitabux can be used to get items in the shop.";
        popupParams.primaryButtonText = "SHOP";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();
            this.kioskPage.value = KioskPage.Shop;
            this.shopPage.value = ShopPage.Boards;
        };
        popupParams.secondaryButtonText = "BUY COINS";
        popupParams.secondaryButtonClickCallback = () => {
            if (XRUtils.isSessionActive()) {
                this.displayBuyHeyVRCoinsVRWarning();
            } else {
                this.closePopup();

                AnalyticsUtils.sendEventOnce("open_buy_heyvr_coins");
                BrowserUtils.openLink("https://heyvr.io/market", true, true, true);
            }
        };
        popupParams.lowPriorityButtonText = "CLOSE";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    displayLoginReminder() {
        if (HeyVR) {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "LOGIN REMINDER";
            popupParams.message = "As a guest, your current progress could be lost.\nLog in into your account to start saving your data\nand continue where you left off.";
            popupParams.primaryButtonText = "LOGIN";
            popupParams.primaryButtonClickCallback = () => {
                if (XRUtils.isSessionActive()) {
                    this.displayLoginVRWarning();
                } else {
                    this.closePopup();

                    common.playerData.openLogin();
                }
            };
            popupParams.lowPriorityButtonText = "CANCEL";
            popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

            this.showCustomPopup(popupParams);
        }
    }

    displaySubscribeReminder() {
        if (HeyVR) {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "PRIZES & UPDATES";
            popupParams.message = "Subscribe to enter the Squat Contest prize draw and get the latest HoverFit offers and news.\n\nSigning up will allow us to contact you with any winning notifications and promotional information.";
            popupParams.primaryButtonText = "SUBSCRIBE";
            popupParams.primaryButtonClickCallback = () => {
                common.playerData.subscribe();
                this.closePopup();
            };
            popupParams.lowPriorityButtonText = "NOT NOW";
            popupParams.lowPriorityButtonClickCallback = () => {
                common.playerData.snoozeSubscribeReminder();
                this.closePopup();
            };

            popupParams.closeButtonClickCallback = () => {
                common.playerData.snoozeSubscribeReminder();
                this.closePopup();
            };

            this.showCustomPopup(popupParams);
        }
    }

    displayTermsAndConditions(onAccepted: (() => void) | null = null) {
        this.onTermsAndConditionsPopupAccepted = onAccepted;
        this.showPopup();
        this.popupBook.changePage(PopupPage.TermsAndConditions);
    }

    displayLoginVRWarning() {
        if (HeyVR) {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "LEAVING VR";
            popupParams.message = "To log in, you will be taken out of the current VR session.\nA dialog will be opened to let you sign in into your account.";
            popupParams.primaryButtonText = "LOGIN";
            popupParams.primaryButtonClickCallback = () => {
                this.closePopup();
                common.playerData.openLogin();
            };
            popupParams.lowPriorityButtonText = "CANCEL";
            popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

            this.showCustomPopup(popupParams);
        }
    }

    updateSceneConfigurationChangePopup(newGameConfig: HoverboardGameConfig, infoText: string = "", disableCancelButton: boolean = false) {
        const gameConfigText = newGameConfig.locationConfig.name + " - " + newGameConfig.fancyMode + "\n";
        this.changingSceneLabel.text = gameConfigText + infoText;

        this.changingSceneCancelButton.clickable = !disableCancelButton;
    }

    resetConfigurationValues() {
        this.location.value = common.gameConfig.location;
        this.mode.value = common.gameConfig.mode;
        this.track.value = common.gameConfig.track;
    }

    readCurrentStyle() {
        const iapCC = common.iapContentController;
        if (!iapCC.ready) return;

        this.gender.value = common.playerData.avatarType;
        this.hoverboard.value = iapCC.adjustedHoverboardVariant.value;
        this.skinColor.value = iapCC.adjustedSkinColor.value;
        this.suit.value = iapCC.adjustedSuitVariant.value;

        if (common.playerData.avatarType == Gender.Male) {
            this.headwear.value = iapCC.adjustedHeadwearVariantMale.value;
        } else {
            this.headwear.value = iapCC.adjustedHeadwearVariantFemale.value;
        }
        this.hairColor.value = iapCC.adjustedHairColor.value;
    }

    private writeCurrentStyle() {
        const playerData = common.playerData;
        const iapCC = common.iapContentController;

        if (playerData.avatarType !== this.gender.value && this.gender.value !== undefined) {
            playerData.avatarType = this.gender.value;
            AnalyticsUtils.sendEventOnce("change_avatar_gender");
            //AnalyticsUtils.sendEventOnce("change_avatar_gender_" + playerData.avatarType);
        }

        if (iapCC.adjustedSkinColor.value !== this.skinColor.value && this.skinColor.value) {
            playerData.skinColor = this.skinColor.value;
            AnalyticsUtils.sendEventOnce("change_skin_color");
            //AnalyticsUtils.sendEventOnce("change_skin_color_" + playerData.skinColor);
        }

        if (iapCC.adjustedHoverboardVariant.value !== this.hoverboard.value && this.hoverboard.value) {
            playerData.hoverboardVariant = this.hoverboard.value;
            AnalyticsUtils.sendEventOnce("change_hoverboard_skin");
            //AnalyticsUtils.sendEventOnce("change_hoverboard_skin_" + playerData.hoverboardVariant);
        }

        if (iapCC.adjustedSuitVariant.value !== this.suit.value && this.suit.value) {
            playerData.suitVariant = this.suit.value;
            AnalyticsUtils.sendEventOnce("change_suit_color");
            //AnalyticsUtils.sendEventOnce("change_suit_color_" + playerData.suitVariant);
        }

        if (iapCC.adjustedHeadwearVariantMale.value !== this.headwear.value && this.headwear.value) {
            AnalyticsUtils.sendEventOnce("change_headwear");
            if (this.gender.value == Gender.Male) {
                playerData.headwearVariantMale = this.headwear.value;
                //AnalyticsUtils.sendEventOnce("change_headwear_" + playerData.headwearVariantMale);
            } else {
                playerData.headwearVariantFemale = this.headwear.value;
                //AnalyticsUtils.sendEventOnce("change_headwear_" + playerData.headwearVariantFemale);
            }
        }

        if (iapCC.adjustedHairColor.value !== this.hairColor.value && this.hairColor.value) {
            playerData.hairColor = this.hairColor.value;
            AnalyticsUtils.sendEventOnce("change_hair_color");
            //AnalyticsUtils.sendEventOnce("change_hair_color_" + playerData.hairColor);
        }

        common.playerData.delayedSavePlayerData();
    }

    private handleLocationOwnershipChange() {
        const isCurrentOwned = common.iapContentController.isLocationOwned(common.gameConfig.location);

        for (const [gameMode, button] of this.modeButtons) {
            const locConfig = common.gameConfig.locationConfig;
            const modeConfig = locConfig.modes.get(gameMode)!;
            let locked = true;

            for (const trackConfig of modeConfig.tracks) {
                if (!trackConfig.locked) {
                    locked = false;
                    break;
                }
            }

            if (locked && isCurrentOwned) {
                locked = false;
            }

            // XXX remove old event listeners otherwise it will double-fire
            button.off("click", this.onConfigChange);
            button.off("click", this.onClickLockedMode);

            if (locked) {
                button.badge = DecoratedButtonBadge.Lock;
                button.on("click", this.onClickLockedMode);
            } else {
                button.badge = null;
                button.on("click", this.onConfigChange);
            }
        }

        if (isCurrentOwned) {
            this.trackInput.lockMin = Infinity;
            this.trackInput.lockCallback = undefined;
            this.npcsAmountInput.lockMin = Infinity;
            this.npcsAmountInput.lockCallback = undefined;
            this.lapsAmountInput.lockMin = Infinity;
            this.lapsAmountInput.lockCallback = undefined;
            this.tagDurationInput.locked = false;
            this.tagDurationInput.lockCallback = undefined;
            this.npcsDifficultyInput.lockMin = Infinity;
            this.npcsDifficultyInput.lockCallback = undefined;
        } else {
            this.trackInput.lockMin = 1;
            this.trackInput.lockCallback = this.showLocationGatePopup.bind(this, "Purchase this location to unlock other tracks.");
            this.npcsAmountInput.lockMin = 3;
            this.npcsAmountInput.lockCallback = this.showLocationGatePopup.bind(this, "Purchase this location to unlock more NPCs.");
            this.lapsAmountInput.lockMin = 4;
            this.lapsAmountInput.lockCallback = this.showLocationGatePopup.bind(this, "Purchase this location to unlock more laps.");
            this.tagDurationInput.locked = true;
            this.tagDurationInput.lockCallback = this.showLocationGatePopup.bind(this, "Purchase this location to unlock different tag durations.");
            this.npcsDifficultyInput.lockMin = 3;
            this.npcsDifficultyInput.lockCallback = this.showLocationGatePopup.bind(this, "Purchase this location to unlock higher difficulties.");
        }
    }

    private rewardsTabButton!: KioskTabButton;

    private updateRewardsTabBadge() {
        const rewards = common.playerData.rewards;
        if (
            rewards[RewardType.Helmet] < getPotentialRewardHelmetLevel() ||
            rewards[RewardType.Suit] < getPotentialRewardSuitLevel() ||
            rewards[RewardType.Board] < getPotentialRewardBoardLevel()
        ) {
            this.rewardsTabButton.badge = DecoratedButtonBadge.Upgrade;
        } else {
            this.rewardsTabButton.badge = null;
        }
    }

    private lastRewardHelmetLevel = 0;
    private lastRewardSuitLevel = 0;
    private lastRewardBoardLevel = 0;
    private onFitpointsChange = () => {
        const fp = common.playerData.totalFitPoints;
        let needsUpdate = false;

        const helmetLevel = Math.trunc(fp / FP_PER_HELMET_LEVEL);
        if (this.lastRewardHelmetLevel !== helmetLevel) {
            this.lastRewardHelmetLevel = helmetLevel;
            needsUpdate = true;
        }

        const suitLevel = Math.trunc(fp / FP_PER_SUIT_LEVEL);
        if (this.lastRewardSuitLevel !== suitLevel) {
            this.lastRewardSuitLevel = suitLevel;
            needsUpdate = true;
        }

        const boardLevel = Math.trunc(fp / FP_PER_BOARD_LEVEL);
        if (this.lastRewardBoardLevel !== boardLevel) {
            this.lastRewardBoardLevel = boardLevel;
            needsUpdate = true;
        }

        if (needsUpdate) this.updateRewardsTabBadge();
    };

    private onRewardsChange = () => {
        this.updateRewardsTabBadge();
    };

    private onAuthChanged = (changedKey?: string) => {
        if (changedKey != "auth_changed") return;

        this.resetToMainKioskPage();
    };

    private kioskUpperMode(page: KioskPage): UpperUIMode | null {
        const modeMap = new Map(
            [
                [KioskPage.Rewards, UpperUIMode.Stats],
                [KioskPage.Multiplayer, UpperUIMode.Leaderboard],
                [KioskPage.Play, UpperUIMode.Leaderboard],
                [KioskPage.Customise, UpperUIMode.CustomisationPreview],
                [KioskPage.Shop, UpperUIMode.CustomisationPreview],
                [KioskPage.Settings, null]
            ]
        );

        return modeMap.get(page) ?? null;
    }

    private watchGender = () => {
        common.avatarSelector.setAvatarType(this.gender.value, common.kioskController.configAvatarComponent, true, false);

        if (this.gender.value == Gender.Male) {
            this.headwear.value = common.playerData.headwearVariantMale;
        } else {
            this.headwear.value = common.playerData.headwearVariantFemale;
        }
    };

    private watchSkinColor = () => {
        common.avatarSelector.setAvatarSkinColor(this.skinColor.value, common.kioskController.configAvatarComponent, true, false);
    };

    private watchHoverboard = () => {
        common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configBoard!, true, false);
        common.hoverboardSelector.setHoverboard(this.hoverboard.value, common.kioskController.configAvatarBoard!, true, false);
    };

    private watchSuit = () => {
        common.avatarSelector.setAvatarSuit(this.suit.value, common.kioskController.configAvatarComponent, true, false);
    };

    private watchHeadwear = () => {
        common.avatarSelector.setAvatarHeadwear(this.headwear.value, common.kioskController.configAvatarComponent, true, false);
    };

    private watchHairColor = () => {
        common.avatarSelector.setAvatarHairColor(this.hairColor.value, common.kioskController.configAvatarComponent, true, false);
    };

    private watchInventory: ObservableItemIDCollectionEventListener = (_type, ids) => {
        for (const id of ids) {
            if (common.iapContentController.getAssetClass(id) === ItemCategory.Location) {
                this.handleLocationOwnershipChange();
                break;
            }
        }
    };

    private watchLocation = () => {
        for (const button of this.modeButtons.values()) button.clickable = false;

        let hasSameModeAsCurrent = false;
        let firstClickableMode: GameMode | null = null;
        if (this.location.value !== null) {
            const locConfig = VALID_CONFIGURATIONS.get(this.location.value);
            if (locConfig) {
                for (const mode of locConfig.modes.keys()) {
                    const button = this.modeButtons.get(mode)!;
                    button.clickable = true;

                    if (firstClickableMode == null) {
                        firstClickableMode = mode;
                    }

                    if (mode == this.mode.value) {
                        hasSameModeAsCurrent = true;
                    }
                }
            }
        }

        if (!hasSameModeAsCurrent) {
            this.mode.value = firstClickableMode;
        }
    };

    private watchTrack: ObservableCallback<number | null> = (_source, group) => {
        if (group === "net-sync") return;

        const location = common.gameConfig.location;
        const locConfig = VALID_CONFIGURATIONS.get(location)!;
        const mode = common.gameConfig.mode!;
        const modeConfig = locConfig.modes.get(mode)!;
        const track = this.track.value!;
        const trackConfig = modeConfig.tracks[track]!;

        AnalyticsUtils.sendEvent("change_track", {
            gameMap: trackConfig.map,
            gameMapTrackIndex: trackConfig.mapTrackIndex
        });

        const newGameConfig = new HoverboardGameConfig();
        newGameConfig.location = location;
        newGameConfig.mode = mode;
        newGameConfig.track = track;
        common.menu.changeGameConfig(new RoomData(common.roomData), newGameConfig);
    };

    private showLocationGatePopup(message: string) {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "LOCATION NOT OWNED";
        popupParams.message = message;
        popupParams.primaryButtonText = "SHOP";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();
            this.kioskPage.value = KioskPage.Shop;
            this.shopPage.value = ShopPage.Locations;

            for (const id of common.iapContentController.getAllIDsOfClass(ItemCategory.Location)) {
                const locVar = common.iapContentController.getAssetRequired(id) as LocationVariant;
                if (locVar.unlocksLocation === common.gameConfig.location) {
                    this.showItemPage.value = id;
                    break;
                }
            }
        };
        popupParams.lowPriorityButtonText = "CANCEL";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    private onClickLockedMode = () => {
        this.showLocationGatePopup("Purchase this location to unlock other modes.");
        this.mode.value = common.gameConfig.mode;
    };

    private displayBuyHeyVRCoinsVRWarning() {
        if (HeyVR) {
            const popupParams = new UICustomPopupHelperParams();
            popupParams.title = "LEAVING VR";
            popupParams.message = "To buy HeyVR coins, you will be taken out of the current VR session.";
            popupParams.primaryButtonText = "BUY COINS";
            popupParams.primaryButtonClickCallback = () => {
                this.closePopup();

                AnalyticsUtils.sendEventOnce("open_buy_heyvr_coins");
                BrowserUtils.openLink("https://heyvr.io/market", true, true, true);
            };
            popupParams.lowPriorityButtonText = "CANCEL";
            popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

            this.showCustomPopup(popupParams);
        }
    }

    private displayRequestMicrophonePopup() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "REQUEST MICROPHONE";
        popupParams.message = "The browser will prompt you to accept microphone permissions.\n\nIf nothing happens, you might have already denied the permissions, and you need to enable them manually from the browser.";
        popupParams.messageFontSize = 0.9;

        popupParams.primaryButtonText = "REQUEST";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();

            if (common.playerData.gameSettings.policyAccepted.value) {
                this.displayRequestMicrophoneVisiblePopup();
            }
        };
        popupParams.lowPriorityButtonText = "CANCEL";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    private displayRequestMicrophoneVRWarningPopup() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "LEAVING VR";
        popupParams.message = "To request microphone permissions, you will be taken out of the current VR session.\n\nThe browser will prompt you to accept microphone permissions.\nIf nothing happens, you might have already denied the permissions, and you need to enable them manually from the browser.";
        popupParams.messageFontSize = 0.9;
        popupParams.primaryButtonText = "REQUEST";
        popupParams.primaryButtonClickCallback = () => {
            this.closePopup();

            if (common.playerData.gameSettings.policyAccepted.value) {
                this.displayRequestMicrophoneVisiblePopup();
            }
        };
        popupParams.lowPriorityButtonText = "CANCEL";
        popupParams.lowPriorityButtonClickCallback = () => { this.closePopup(); };

        this.showCustomPopup(popupParams);
    }

    private displayRequestMicrophoneVisiblePopup() {
        const popupParams = new UICustomPopupHelperParams();
        popupParams.title = "MICROPHONE REQUESTED";
        popupParams.titleFontSize = 2.0;
        popupParams.message = "A microphone request should now be visible on your browser.";
        popupParams.primaryButtonText = "CLOSE";
        popupParams.primaryButtonClickCallback = () => this.closePopup();

        this.showCustomPopup(popupParams);

        common.hoverboardNetworking.promptMicrophonePermissions().finally(() => this.closePopup());
    }

    override onDestroy() {
        if (this.resetShopSelection) this.shopPage.unwatch(this.resetShopSelection);
        if (this.watchShowItemPage) this.showItemPage.unwatch(this.watchShowItemPage);
        if (this.watchKioskPage) this.kioskPage.unwatch(this.watchKioskPage);
        this.track.unwatch(this.watchTrack);
        this.location.unwatch(this.watchLocation);

        common.playerData.unlisten(this.onAuthChanged);
        common.playerData.unlisten(this.onFitpointsChange);
        common.playerData.unlisten(this.onRewardsChange);
        if (this.updateSubscribeNewsletterSettingBookPage != null) {
            common.playerData.unlisten(this.updateSubscribeNewsletterSettingBookPage);
        }

        common.iapContentController.inventory.unwatch(this.watchInventory);
        this.hairColor.unwatch(this.watchHairColor);
        this.headwear.unwatch(this.watchHeadwear);
        this.suit.unwatch(this.watchSuit);
        this.hoverboard.unwatch(this.watchHoverboard);
        this.skinColor.unwatch(this.watchSkinColor);
        this.gender.unwatch(this.watchGender);

        if (this.updateMicrophoneBookPage != null) {
            common.playerData.gameSettings.policyAccepted.unwatch(this.updateMicrophoneBookPage);
        }

        if (this.updateTermsAndConditionsBookPage != null) {
            common.playerData.gameSettings.policyAccepted.unwatch(this.updateTermsAndConditionsBookPage);
        }
    }
}
