import { Component } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { HowlerAudio } from "src/hoverfit/audio/audio-manager/implementations/howler-audio.js";
import { common } from "src/hoverfit/common.js";
import { vec3_create } from "wle-pp";
import { EnvironmentalSound } from "../environmental-sound.js";

export class EnvironmentalSoundComponent extends Component {
    static TypeName = "environmental-sound";

    @property.string()
    public soundID!: string;

    @property.bool(false)
    public useObjectPositionAsPlayPosition!: boolean;

    @property.float(-999)
    public minWorldY!: number;

    @property.float(999)
    public maxWorldY!: number;

    @property.float(2)
    public minHorizontalRadius!: number;

    @property.float(10)
    public maxHorizontalRadius!: number;

    @property.float(-2)
    public minVerticalDistance!: number;

    @property.float(2)
    public maxVerticalDistance!: number;

    @property.float(1)
    public minPitch!: number;

    @property.float(1)
    public maxPitch!: number;

    private environmentalSound!: EnvironmentalSound;

    getEnvironmentalSound() {
        if (this.environmentalSound == null) {
            this.environmentalSound = new EnvironmentalSound(common.audioManager.getAudio(this.soundID)! as HowlerAudio,
                this.object,
                this.useObjectPositionAsPlayPosition,
                vec3_create(-999, this.minWorldY, -999),
                vec3_create(999, this.maxWorldY, 999),
                this.minHorizontalRadius,
                this.maxHorizontalRadius,
                this.minVerticalDistance,
                this.maxVerticalDistance,
                this.minPitch,
                this.maxPitch
            );
        }

        return this.environmentalSound;
    }
}