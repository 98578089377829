import { Component, MeshComponent } from "@wonderlandengine/api";
import anime from "animejs";
import { getTime } from "src/hoverfit/utils/time-utils.js";
import { Vector3 } from "wle-pp";
import { common } from "../../../common.js";

export class StartGoalComponent extends Component {
    static override TypeName = "start-goal";

    private _currentAnim: anime.AnimeInstance | null = null;

    private _meshComponent!: MeshComponent;

    private _currentScaleLocal!: Vector3;
    private _initialWidth: number = 0;
    private _currentWidth: number = 0;

    private readonly _fadeDuration: number = 600;

    override init(): void {
        this._meshComponent = this.object.pp_getComponent(MeshComponent)!;
        this._currentScaleLocal = this.object.pp_getScaleLocal();
        this._initialWidth = this._currentScaleLocal[2];
        this._currentWidth = this._currentScaleLocal[2];
    }

    hide(): void {
        this.object.pp_setActive(false);
    }

    shrink(): void {
        if (this._currentAnim != null) return;

        this._currentAnim = anime({
            targets: this,
            easing: "easeInQuad",
            _currentWidth: 0.0,
            delay: 0,
            duration: this._fadeDuration,
            autoplay: false,
            update: (anim) => {
                if (this._currentAnim != null) {
                    this._currentScaleLocal[2] = this._currentWidth;
                    this.object.pp_setScaleLocal(this._currentScaleLocal);
                }
            },
            complete: (anim) => {
                if (this._meshComponent != null) {
                    this._meshComponent.active = false;
                }

                this._currentAnim = null;

                if (common.balcony.isPlayerOnBalcony.value) {
                    common.tracksManager.getRaceManager().showFinishGoal();
                }
            }
        });
    }

    resetGoal(): void {
        this._currentWidth = this._initialWidth;

        this._currentScaleLocal[0] = this._initialWidth;
        this._currentScaleLocal[1] = this._initialWidth;
        this._currentScaleLocal[2] = this._initialWidth;

        this.object.pp_setScaleLocal(this._currentScaleLocal);

        this._currentAnim = null;

        this.object.pp_setActive(true);
    }

    override update(dt: number): void {
        if (this._currentAnim != null) {
            this._currentAnim.tick(getTime());
        }
    }
}