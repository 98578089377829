import { XRUtils } from "wle-pp";

export function getTime() {
    const frame = XRUtils.getFrame();
    return (frame && frame.predictedDisplayTime)
        || performance.now()
        || Date.now();
}

export const MS_PER_DAY = 86400000;
export const MS_PER_HOUR = 3600000;
export const MS_PER_MINUTE = 60000;
export const MS_PER_SECOND = 1000;

export const MS_PER_15_MINUTES = 15 * MS_PER_MINUTE;

const TEMP_DATE = new Date();

export const enum FormatTimePart { NONE, MS, S, M, H, D };

export function formatTimeDuration(ms: number, short = false, alwaysShow = FormatTimePart.NONE, alwaysHide = FormatTimePart.NONE, noTimeText = ""): string {
    ms = Math.round(ms);
    const days = Math.trunc(ms / MS_PER_DAY);
    ms %= MS_PER_DAY;
    const hours = Math.trunc(ms / MS_PER_HOUR);
    ms %= MS_PER_HOUR;
    const mins = Math.trunc(ms / MS_PER_MINUTE);
    ms %= MS_PER_MINUTE;
    const secs = Math.trunc(ms / MS_PER_SECOND);
    ms %= MS_PER_SECOND;

    const parts = [];
    if ((days > 0 || alwaysShow >= FormatTimePart.D) && alwaysHide < FormatTimePart.D) parts.push(short ? `${days}d` : `${days} day${days === 1 ? "" : "s"}`);
    if ((hours > 0 || alwaysShow >= FormatTimePart.H) && alwaysHide < FormatTimePart.H) parts.push(short ? `${hours}h` : `${hours} hour${hours === 1 ? "" : "s"}`);
    if ((mins > 0 || alwaysShow >= FormatTimePart.M) && alwaysHide < FormatTimePart.M) parts.push(short ? `${mins}m` : `${mins} minute${mins === 1 ? "" : "s"}`);
    if ((secs > 0 || alwaysShow >= FormatTimePart.S) && alwaysHide < FormatTimePart.S) parts.push(short ? `${secs}s` : `${secs} second${secs === 1 ? "" : "s"}`);
    if ((ms > 0 || alwaysShow >= FormatTimePart.MS) && alwaysHide < FormatTimePart.MS) parts.push(short ? `${ms}ms` : `${ms} millisecond${ms === 1 ? "" : "s"}`);

    const partCount = parts.length;
    if (!short && partCount > 1) {
        const aIndex = partCount - 2;
        const a = parts[aIndex];
        const b = parts.pop()!;
        parts[aIndex] = `${a} and ${b}`;
    } else if (partCount === 0) {
        return noTimeText;
    }

    return parts.join(short ? " " : ", ");
}

export function formatPlayTime(ms: number) {
    return formatTimeDuration(ms, true, FormatTimePart.H, FormatTimePart.S);
}

export function getDayStart(timestamp: number) {
    TEMP_DATE.setTime(timestamp);
    return TEMP_DATE.setHours(0, 0, 0, 0);
}

export function getWeekStart(timestamp: number) {
    TEMP_DATE.setTime(timestamp);
    TEMP_DATE.setHours(0, 0, 0, 0);
    return TEMP_DATE.setHours(-24 * ((TEMP_DATE.getDay() + 6) % 7));
}

export function getMonthStart(timestamp: number) {
    TEMP_DATE.setTime(timestamp);
    TEMP_DATE.setHours(0, 0, 0, 0);
    return TEMP_DATE.setDate(1);
}