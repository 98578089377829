import { Component, type LightComponent, type Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { quat } from "gl-matrix";
import { common } from "src/hoverfit/common.js";
import { type SkyMaterial } from "src/hoverfit/types/material-types.js";
import { CancelledMarker } from "src/hoverfit/utils/cancellable-promise.js";

const TEMP_QUAT_1 = new Float32Array(4);
const TEMP_QUAT_2 = new Float32Array(4);

export class EnvironmentControllerComponent extends Component {
    static TypeName = "environment-controller";

    @property.object()
    ambientSunObject!: Object3D;
    @property.object()
    skyGlowObject!: Object3D;
    @property.object()
    sunObject!: Object3D;
    @property.material()
    skyboxMaterial!: SkyMaterial;

    private pickedSkybox!: string;
    private skyboxMarker?: CancelledMarker;
    private ambientSun!: LightComponent;
    private skyGlow!: LightComponent;
    private sun!: LightComponent;

    init() {
        this.pickedSkybox = "";
        common.environmentController = this;
    }

    start() {
        this.ambientSun = this.ambientSunObject.getComponent("light")!;
        this.skyGlow = this.skyGlowObject.getComponent("light")!;
        this.sun = this.sunObject.getComponent("light")!;
        this.pickEnvironment(0);
    }

    get environments() {
        return common.gameConfig.locationConfig.environments;
    }

    pickEnvironment(index: number) {
        const environment = this.environments[index];
        if (!environment) {
            throw new Error(`Invalid environment index ${index}`);
        }

        this.ambientSun.setColor(environment.ambient);
        this.skyGlow.setColor(environment.skyGlow);
        this.sun.setColor(environment.sun);

        quat.fromEuler(TEMP_QUAT_1, environment.zenith, 0, 0);
        quat.fromEuler(TEMP_QUAT_2, 0, environment.azimuth, 0);
        quat.mul(TEMP_QUAT_1, TEMP_QUAT_2, TEMP_QUAT_1);
        this.sunObject.setRotationLocal(TEMP_QUAT_1);

        const skybox = environment.skybox;
        if (this.pickedSkybox !== skybox) {
            this.pickedSkybox = skybox;

            if (this.skyboxMarker) this.skyboxMarker.cancelled = true;
            const marker = { cancelled: false };
            this.skyboxMarker = marker;

            common.hoverfitSceneResources.getTextureFromURL(`assets/textures/skyboxes/${skybox}.webp`).then((texture) => {
                if (marker.cancelled) return;
                this.skyboxMaterial.texture = texture;
            });
        }
    }
}
