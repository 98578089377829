// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/pause-menu.xml";

import { Property } from "@wonderlandengine/api";
import { type Room } from "colyseus.js";
import { GameMode } from "hoverfit-shared-netcode";
import { Theme, ValidatedVariable, Variable, Widget, type XMLUIParserContext } from "lazy-widgets";
import { BasicXMLUIRootComponent, WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { MAX_VOLUME } from "src/hoverfit/data/game-settings.js";
import { LeaderboardUtils } from "src/hoverfit/game/track/leaderboard/leaderboard-utils.js";
import { LeaderboardType } from "src/hoverfit/game/track/leaderboard/leaderboards-manager.js";
import { AnalyticsUtils, BrowserUtils, Globals, MathUtils } from "wle-pp";
import { common } from "../../../common.js";
import { getRandomName } from "../../../misc/get-random-name.js";
import { cancelSceneLoad } from "../../../misc/load-scene/load-scene.js";
import { BUILD_TIMESTAMP_FORMATTED } from "../../../misc/version/build-timestamp-formatted.js";
import { VERSION_STRING } from "../../../misc/version/version-string.js";
import { replaceSearchParams } from "../../../utils/url-utils.js";
import { POLICY_URL, POLICY_URL_NICE, TERMS_URL, TERMS_URL_NICE } from "../../kiosk/components/kiosk-lower-ui-component.js";
import { Background9Slice } from "../../kiosk/widgets/background-9slice.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { ClickyButton, ContainerClickyButton } from "../../lazy-widgets/widgets/clicky-button.js";
import { ClickyCheckbox } from "../../lazy-widgets/widgets/clicky-checkbox.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { Hyperlink } from "../../lazy-widgets/widgets/hyperlink.js";
import { IconDecoratedButton } from "../../lazy-widgets/widgets/icon-decorated-button.js";
import { Numpad } from "../../lazy-widgets/widgets/numpad.js";
import { copyText } from "../../misc/copy-text.js";
import { sfTheme, sfThemeSmaller } from "../../misc/sf-theme.js";
import { PopupIconImage } from "../../popup/popup.js";
import { PauseMenuCustomPopupHelper, PauseMenuCustomPopupHelperParams } from "../pause-menu-custom-popup-helper.js";

/**
 * state machine (i wont do fancy diagrams this time):
 *
 * [state]: [possible state transitions]
 * maybe_close: pause*, hidden*
 * hidden: pause
 * pause: hidden, room_pick, game_settings
 * room_pick: hidden, pause, wait_msg
 * wait_msg: maybe_close*, ok_msg*
 * ok_msg: maybe_close
 * game_settings: pause
 * confirm_end: maybe_clone
 * mp_consent: maybe_close
 *
 * transitions marked with * can't be done by the user; they are done
 * automatically
 *
 * special behaviour:
 * - transitioning to the maybe_close state automatically transitions to the
 *   pause state if paused flag is set, or hidden state otherwise
 * - transitioning to the hidden state auto-disables the ui root
 */
export enum PauseState {
    MAYBE_CLOSE = -2,
    HIDDEN = -1,
    PAUSE = 0,
    ROOM_PICK = 1,
    WAIT_MSG = 2,
    OK_MSG = 3,
    GAME_SETTINGS = 4,
    CONFIRM_END = 5,
    PERMISSIONS_CONSENT = 6,
    ASK_GAME_MODE = 7,
    LOADING = 8,
    CUSTOM_POPUP = 9
}

export enum RaceButtonState {
    Start = 0,
    ToBalcony = 1,
}

export class PauseMenuComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "pause-menu";

    static override Properties = {
        ...BasicXMLUIRootComponent.Properties,
        resolution: Property.float(1.0),
    };

    private consentWaiting: null | ((accepted: boolean) => void) = null;
    private consentAutoCloseOnAccept: boolean = true;

    private askGameModeResolveCallback: null | ((askGameModeResult: { gameMode: GameMode, isOnline: boolean } | null) => void) = null;

    private ready: boolean = false;

    private message!: Variable<string>;
    private toTrackDebugEnabled!: Variable<boolean>;
    private joinRoomClickable!: Variable<boolean>;
    private joinRoomText!: Variable<string>;
    private shareButtonEnabled!: Variable<boolean>;
    private joinByIDClickable!: Variable<boolean>;
    private roomIDVar!: ValidatedVariable<string, number | null>;
    private curState!: Variable<PauseState>;
    private paused!: boolean;
    private lastRoomID!: null | string;
    private loadingScene!: boolean;
    // TODO this should not be updated by menu.js. this should update itself by
    //      adding some sort of listener
    private raceButtonState!: Variable<RaceButtonState>;
    private voipError!: Variable<string>;

    private voipErrorWidget!: Widget;

    private customPopup!: PauseMenuCustomPopupHelper;

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(Numpad);
        parser.autoRegisterFactory(ClickyButton);
        parser.autoRegisterFactory(ContainerClickyButton);
        parser.autoRegisterFactory(ClickyCheckbox);
        parser.autoRegisterFactory(Hyperlink);
        parser.autoRegisterFactory(Background9Slice);
        parser.autoRegisterFactory(DecoratedButton);
        parser.autoRegisterFactory(IconDecoratedButton);

        return parser;
    }

    protected override getRootProperties() {
        const properties = super.getRootProperties();

        return {
            ...properties,
            resolution: this.resolution,
            theme: new Theme({
                bodyTextFill: "white",
            }, properties?.theme ?? sfTheme),
            enabled: false, // XXX menu starts hidden
            enablePasteEvents: !BrowserUtils.isMobile(), // XXX paste event handling shows virtual keyboard on mobile, so disable it for mobile
        };
    }

    protected override getXMLParserConfig() {
        return {
            ...super.getXMLParserConfig(),
            variables: {
                resumeCallback: () => common.menu.resumeGame(),
                toTrackCallback: () => {
                    if (this.raceButtonState.value === RaceButtonState.ToBalcony) {
                        AnalyticsUtils.sendEvent("move_to_balcony");
                        common.menu.returnToBalcony();
                    } else {
                        common.menu.moveToTrack();
                    }
                },
                toTrackDebugCallback: () => {
                    if (this.raceButtonState.value === RaceButtonState.ToBalcony) {
                        common.menu.returnToBalconyDebug();
                    } else {
                        common.menu.moveToTrackDebug();
                    }
                },
                joinRoomCallback: async () => {
                    const joinText = (this.root!.getWidgetByID("join") as ClickyButton).text;
                    if (joinText === "Join Room") {
                        if (!common.playerData.gameSettings.policyAccepted.value && !await common.pauseMenu.getPermissionsConsent()) {
                            return;
                        }

                        this.setState(PauseState.ROOM_PICK);
                    } else if (joinText === "Cancel Map Change") {
                        if (cancelSceneLoad()) {
                            common.roomData.roomNumber = null;
                            common.roomData.privateRoom = false;
                            const url = new URL(window.location.href);
                            const searchParams = url.searchParams;
                            searchParams.delete("room");
                            replaceSearchParams(url, searchParams);

                            common.popupManager.showQuickMessagePopup("Cancelled map change", PopupIconImage.Warn, "joining");
                        }
                    } else {
                        common.roomProxy.disconnect();
                    }
                },
                shareRoomCallback: () => {
                    let urlToShare = location.origin.replace(new RegExp("^https?://"), "") + location.pathname;
                    if (window.location.hostname.endsWith(".heyvr.io")) {
                        urlToShare += "?heyvr_room=" + this.lastRoomID;
                    } else {
                        urlToShare += "?room=" + this.lastRoomID;
                    }

                    copyText("Share this URL with your friends:", urlToShare);
                },
                settingsCallback: () => this.setState(PauseState.GAME_SETTINGS),
                requestMicrophoneCallback: () => common.hoverboardNetworking.requestMicrophone(),
                toggleDebugMenuCallback: () => {
                    common.MAIN_CHANNEL.emit("toggle_voip_debug");
                },
                backCallback: () => this.setState(PauseState.PAUSE),
                joinRoomByIDCallback: () => {
                    if (this.roomIDVar.validValue != null) {
                        common.roomProxy.hostOrJoinRoom(this.roomIDVar.validValue, true);
                    } else {
                        common.roomProxy.quickPlay();
                    }
                },
                maybeCloseCallback: () => this.setState(PauseState.MAYBE_CLOSE),
                prevMusicVolumeCallback: function () {
                    common.playerData.gameSettings.musicVolume.value = MathUtils.clamp(common.playerData.gameSettings.musicVolume.value - 1, 0, MAX_VOLUME);
                },
                nextMusicVolumeCallback: function () {
                    common.playerData.gameSettings.musicVolume.value = MathUtils.clamp(common.playerData.gameSettings.musicVolume.value + 1, 0, MAX_VOLUME);
                },
                prevVOVolumeCallback: function () {
                    common.playerData.gameSettings.voiceVolume.value = MathUtils.clamp(common.playerData.gameSettings.voiceVolume.value - 1, 0, MAX_VOLUME);
                },
                nextVOVolumeCallback: function () {
                    common.playerData.gameSettings.voiceVolume.value = MathUtils.clamp(common.playerData.gameSettings.voiceVolume.value + 1, 0, MAX_VOLUME);
                },
                pickRandomName: () => {
                    common.playerData.name = getRandomName(common.playerData);
                },
                denyTermsAndConditions: () => {
                    this.finalizeConsent(false);
                },
                acceptTermsAndConditions: () => {
                    this.finalizeConsent(true);
                },
                openPrivacyPolicy: () => {
                    BrowserUtils.openLink(POLICY_URL);
                },
                openTermsAndConditions: () => {
                    BrowserUtils.openLink(TERMS_URL);
                },
                askGameModeSingleRace: () => {
                    this.finalizeAskGameMode(GameMode.Race, false);
                },
                askGameModeMultiTag: () => {
                    this.finalizeAskGameMode(GameMode.Tag, true);
                },
                askPermissions: () => {
                    this.getPermissionsConsent();
                },
                voiceP2P: common.playerData.gameSettings.voiceP2P,
                quietMode: common.playerData.gameSettings.quietMode,
                voiceMediasoup: common.playerData.gameSettings.voiceMediasoup,
                roomIDVar: this.roomIDVar,
                message: this.message,
                numpadInputFilter: (inStr: string) => /^\d+$/.test(inStr),
                devMode: DEV_MODE,
                buildVersion: VERSION_STRING,
                buildTime: BUILD_TIMESTAMP_FORMATTED,
                buildBranch: BUILD_BRANCH ?? "<unknown>",
                nicePolicyURL: POLICY_URL_NICE,
                niceTermsURL: TERMS_URL_NICE,
                policyURL: POLICY_URL,
                termsURL: TERMS_URL,
                consentContentTheme: sfThemeSmaller,
                voipError: this.voipError
            },
        };
    }

    protected override onUITreePicked(uiTree: Widget, context: XMLUIParserContext) {
        super.onUITreePicked(uiTree, context);

        // XXX unwatch never called, but it's harmless since it's UI-only logic
        const joinByID = context.idMap.get("join-by-id") as Numpad;
        this.joinByIDClickable.watch(() => {
            joinByID.submitClickable = this.joinByIDClickable.value;
        }, true);

        const toBalcony = context.idMap.get("toBalcony") as ClickyButton;
        const toTrackDebug = context.idMap.get("toTrackDebug") as ClickyButton;
        this.toTrackDebugEnabled.watch(() => {
            toTrackDebug.enabled = this.toTrackDebugEnabled.value;
        }, true);

        this.raceButtonState.watch(() => {
            switch (this.raceButtonState.value) {
                case RaceButtonState.Start:
                    toBalcony.enabled = false;
                    toTrackDebug.text = "To Track Debug";
                    break;
                default:
                    toBalcony.enabled = true;
                    toTrackDebug.text = "To Balcony Debug";
            }
        }, true);

        const join = context.idMap.get("join") as ClickyButton;
        this.joinRoomClickable.watch(() => {
            join.clickable = this.joinRoomClickable.value;
        }, true);
        this.joinRoomText.watch(() => {
            join.text = this.joinRoomText.value;
        }, true);

        const share = context.idMap.get("share") as ClickyButton;
        this.shareButtonEnabled.watch(() => {
            share.enabled = this.shareButtonEnabled.value;
        }, true);

        const book = context.idMap.get("menu-book") as Book;
        this.curState.watch(() => {
            if (this.curState.value !== PauseState.HIDDEN) {
                book.changePage(this.curState.value);
            }
        }, true);

        this.makeJoinButton();
    }

    protected override getXMLContent() {
        return xmlContent;
    }

    override onRootReady(root: WLVirtualKeyboardRoot) {
        super.onRootReady(root);

        // HACK prevent stutter at end of race by delaying UI activation ONLY
        //      FOR CONFIRMATION POPUPS
        // TODO remove this once engine supports ImageData texture uploads which
        //      should fix performance issues in lazy-widgets
        let delayActive: number | null = null;
        this.curState.watch(() => {
            if (delayActive !== null) clearTimeout(delayActive);
            delayActive = null;

            const newPauseState = this.curState.value;
            this.active = newPauseState !== PauseState.HIDDEN && newPauseState !== PauseState.CONFIRM_END;
        }, true);

        this.voipErrorWidget = this.root!.getWidgetByID("voip-error");

        this.customPopup = new PauseMenuCustomPopupHelper(root, () => { this.closePopup(); });

        this.ready = true;
    }

    private showUpdateMessage(code: number) {
        if (code !== 4400) return false;
        common.popupManager.showMessagePopup("Outdated game client.\nPlease update before trying again.", PopupIconImage.Error, "joining");
        return true;
    }

    override init() {
        common.pauseMenu = this;

        this.message = new Variable("");

        this.toTrackDebugEnabled = new Variable(true);

        this.joinRoomClickable = new Variable(false);
        this.joinRoomText = new Variable("Join Room");
        this.shareButtonEnabled = new Variable(false);
        this.raceButtonState = new Variable(RaceButtonState.Start);
        this.voipError = new Variable("");

        this.joinByIDClickable = new Variable(false);
        /** @type {ValidatedVariable<string, number | null>} */
        const validator = (str: string): [boolean, null | number] => {
            if (str === "") {
                return [true, null];
            }

            const num = parseInt(str, 10);
            if (isNaN(num) || !isFinite(num) || num < 0) {
                return [false, null];
            }

            return [true, num];
        };
        this.roomIDVar = new ValidatedVariable("", (str) => {
            const tuple = validator(str);
            this.joinByIDClickable.value = tuple[0];
            return tuple;
        });

        this.curState = new Variable(PauseState.HIDDEN);

        this.paused = false;
        this.lastRoomID = null;

        super.init();

        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.on("room-no-autojoin", () => {
            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-join", (roomID) => {
            if (this.loadingScene) return;

            if (roomID === null) {
                common.popupManager.showMessagePopup("Joining available room...", PopupIconImage.Info, "joining", 0.5);
            } else {
                common.popupManager.showMessagePopup("Joining room " + roomID, PopupIconImage.Info, "joining", 0.5);
            }

            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-create", (roomID, privateRoom) => {
            if (this.loadingScene) return;

            const roomType = privateRoom ? "private" : "public";
            if (roomID === null) {
                common.popupManager.showMessagePopup("Creating " + roomType + " room", PopupIconImage.Info, "joining", 0.5);
            } else {
                common.popupManager.showMessagePopup("Creating " + roomType + " room " + roomID, PopupIconImage.Info, "joining", 0.5);
            }

            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-init-start", (room: Room) => {
            this.lastRoomID = room.id;

            if (this.loadingScene) return;
            this.disableJoinButton();
        });

        MAIN_CHANNEL.on("room-init-done", (isCreate: boolean, privateRoom: boolean) => {
            this.setState(PauseState.MAYBE_CLOSE);
            common.menu.resumeGame();

            if (this.loadingScene) return;
            this.makeDisconnectButton();

            const roomType = privateRoom == null ? "room" : (privateRoom ? "private room" : "public room");
            if (isCreate) {
                common.popupManager.showQuickMessagePopup("Created " + roomType + " " + this.lastRoomID, PopupIconImage.Info, "joining");
            } else {
                common.popupManager.showQuickMessagePopup("Joined room " + this.lastRoomID, PopupIconImage.Info, "joining");
            }
        });

        MAIN_CHANNEL.on("confirm-end", () => {
            const hoverboardRoom = common.hoverboardNetworking.room;
            if (hoverboardRoom) {
                hoverboardRoom.send("set-end-confirmed");
            } else {
                common.menu.returnToBalcony();
            }
        });

        MAIN_CHANNEL.on("room-create-error", (e) => {
            if (common.playerData.isGuest) {
                common.playerData.name = null;
            }

            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("An error occurred while creating the room\nPlease try again", PopupIconImage.Error, "joining");
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-join-error", (e) => {
            if (common.playerData.isGuest) {
                common.playerData.name = null;
            }

            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                if (e.code == 4212) {
                    if (common.menu.inPausedState()) {
                        common.popupManager.showMessagePopup("The room does not exist.\nYou can host it, or try joining another room.", PopupIconImage.Error, "joining");
                    } else {
                        common.popupManager.clearPopupTag("joining");
                        common.kioskLowerUI.showCustomInfoPopup("NO ROOM", "The room does not exist.\nYou can host it, or try joining another room.");
                    }
                } else {
                    common.popupManager.showMessagePopup("An error occurred while joining the room\nPlease try again.", PopupIconImage.Error, "joining");
                }
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-init-error", (e) => {
            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("An error occurred while preparing the room\nPlease try again", PopupIconImage.Error, "joining");
            }

            common.roomProxy.updateLastSessionGameOnlineConfig(false, null, false);

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-leave", (code) => {
            if (this.loadingScene) return;

            if (!this.showUpdateMessage(code)) {
                const okCodes = [1000, 1005, 4000, 4989];
                if (!okCodes.includes(code)) {
                    common.popupManager.showQuickMessagePopup("You have left the room\nCode: " + code, PopupIconImage.Info, "joining");
                } else {
                    common.popupManager.showQuickMessagePopup("You have left the room", PopupIconImage.Info, "joining");
                }
            }

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-error", (e) => {
            if (this.loadingScene) return;

            if (!('code' in e) || !this.showUpdateMessage(e.code)) {
                common.popupManager.showMessagePopup("A network error occurred", PopupIconImage.Error, "joining");
            }

            this.makeJoinButton();
        });

        MAIN_CHANNEL.on("room-race-completed", () => {
            if (!common.hoverboardNetworking.room) {
                LeaderboardUtils.submitPlayerRaceScore();

                common.kioskUpperUI.updateLastRaceGameConfig();
                common.kioskUpperUI.setLeaderboardType(LeaderboardType.Local);
                common.kioskUpperUI.pickCurrentLeaderboard();
            }

            this.showConfirmEnd();
        });

        MAIN_CHANNEL.on("room-tag-completed", () => {
            this.showConfirmEnd();
        });

        MAIN_CHANNEL.on("hide-message", () => {
            this.setState(PauseState.MAYBE_CLOSE);
            common.menu.resumeGame();
        });

        this.loadingScene = false;
        MAIN_CHANNEL.on("load-scene-start", () => {
            this.loadingScene = true;
            this.makeCancelChangeButton();
        });

        MAIN_CHANNEL.on("load-scene-end", () => {
            this.loadingScene = false;
            this.makeJoinButton();
        });
    }

    disableJoinButton() {
        this.joinRoomClickable.value = false;
        this.shareButtonEnabled.value = false;
    }

    makeJoinButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Join Room";
        this.shareButtonEnabled.value = false;
    }

    makeDisconnectButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Disconnect";
        this.shareButtonEnabled.value = true;
    }

    makeCancelChangeButton() {
        this.joinRoomClickable.value = true;
        this.joinRoomText.value = "Cancel Map Change";
        this.shareButtonEnabled.value = false;
    }

    isStateLocked() {
        return (
            this.curState.value === PauseState.OK_MSG ||
            this.curState.value === PauseState.CONFIRM_END ||
            this.curState.value === PauseState.PERMISSIONS_CONSENT ||
            this.curState.value === PauseState.CUSTOM_POPUP
        );
    }

    isPaused(): boolean {
        return this.paused;
    }

    setPaused(paused: boolean) {
        if (paused === this.paused) return;

        this.paused = paused;

        if (paused) {
            this.setState(PauseState.PAUSE);
        } else {
            this.setState(PauseState.HIDDEN);
        }
    }

    setState(newState: PauseState, checkConfirmEnd = true) {
        if (checkConfirmEnd && this.curState.value === PauseState.CONFIRM_END && newState !== PauseState.CONFIRM_END) {
            this.applyConfirmEnd();
        }

        if (newState === PauseState.MAYBE_CLOSE) {
            if (this.paused) {
                newState = PauseState.PAUSE;
            } else {
                newState = PauseState.HIDDEN;
            }
        }

        this.curState.value = newState;

        if (this.consentWaiting !== null) {
            const callback = this.consentWaiting;
            this.consentWaiting = null;
            callback(false);
        }
    }

    isReady() {
        return this.ready;
    }

    isInState(pauseState: PauseState): boolean {
        return this.curState.value == pauseState;
    }

    getRaceButtonState() {
        return this.raceButtonState;
    }

    showMessage(message: string, waitForOK = false) {
        this.message.value = message;
        this.setState(waitForOK ? PauseState.OK_MSG : PauseState.WAIT_MSG);
    }

    showConfirmEnd() {
        this.setState(PauseState.CONFIRM_END);
    }

    applyConfirmEnd() {
        this.setState(PauseState.MAYBE_CLOSE, false);
        common.MAIN_CHANNEL.emit("confirm-end");
    }

    showCustomPopup(popupParams: PauseMenuCustomPopupHelperParams) {
        this.customPopup.setupPopup(popupParams);
        this.setState(PauseState.CUSTOM_POPUP);
    }

    showCustomInfoPopup(title: string, message: string, buttonText: string = "CLOSE") {
        const popupParams = new PauseMenuCustomPopupHelperParams();
        popupParams.title = title;
        popupParams.message = message;

        popupParams.primaryButtonText = buttonText;
        popupParams.primaryButtonClickCallback = () => this.closePopup();

        popupParams.enableCloseButton = false;

        this.showCustomPopup(popupParams);
    }

    closePopup() {
        if (this.curState.value != PauseState.CUSTOM_POPUP) return;

        this.setState(PauseState.MAYBE_CLOSE);
    }

    showLoadingPopup() {
        this.setState(PauseState.LOADING);
    }

    getPermissionsConsent(autoCloseOnAccept: boolean = true) {
        return new Promise((resolve: (accepted: boolean) => void, _reject) => {
            if (this.consentWaiting !== null) {
                resolve(false);
                return;
            }

            this.setState(PauseState.PERMISSIONS_CONSENT);
            this.consentWaiting = (accept) => {
                common.playerData.gameSettings.policyAccepted.value = accept;

                resolve(accept);
            };

            this.consentAutoCloseOnAccept = autoCloseOnAccept;
        });
    }

    finalizeConsent(accept: boolean) {
        if (this.consentWaiting === null) return;

        const callback = this.consentWaiting;
        this.consentWaiting = null;
        if (this.consentAutoCloseOnAccept || !accept) {
            this.setState(PauseState.MAYBE_CLOSE);
        }
        callback(accept);
    }

    getAskGameModePanel() {
        return new Promise((resolve: (askGameModeResult: { gameMode: GameMode, isOnline: boolean } | null) => void, _reject) => {
            if (this.askGameModeResolveCallback !== null) {
                resolve(null);
                return;
            }

            this.setState(PauseState.ASK_GAME_MODE);
            this.askGameModeResolveCallback = resolve;
        });
    }

    finalizeAskGameMode(gameMode: GameMode, isOnline: boolean) {
        if (this.askGameModeResolveCallback === null) return;

        const callback = this.askGameModeResolveCallback;
        this.askGameModeResolveCallback = null;
        this.setState(PauseState.MAYBE_CLOSE);
        callback({ gameMode, isOnline });
    }

    override update(dt: number): void {
        this.toTrackDebugEnabled.value = Globals.isDebugEnabled();
        super.update(dt);
    }

    setVoIPErrorEnabled(enabled: boolean, errorCode: number) {
        this.voipErrorWidget.enabled = enabled;
        this.voipError.value = "Voice chat is currently not available.\nPlease check your firewall settings or reload the page.\nError 0x" + errorCode.toString().padStart(4, "0");
    }
}
