import { Schema, type } from "@colyseus/schema";
import { NeededUnionType } from "../types/needed-union-type.js";
import { HOVERBOARD_PLAYER_MULTIPLAYER_STATES } from "./hoverboard-multiplayer-state.js";

type KeysToNumberObject<T extends readonly any[]> = { [K in NeededUnionType<T>]: number };

export const VALID_TRACKED_TRANSFORM_KEYS = ['qx', 'qy', 'qz', 'qw', 'px', 'py', 'pz', 'pw'] as const;
export const VALID_TRACKED_HOVERBOARD_DATA_KEYS = ['currentSpeed', 'currentYSpeed', 'currentTurnSpeed'] as const;

export class TrackedTransform extends Schema implements KeysToNumberObject<typeof VALID_TRACKED_TRANSFORM_KEYS> {
    @type('float32') qx: number = 0;
    @type('float32') qy: number = 0;
    @type('float32') qz: number = 0;
    @type('float32') qw: number = 1;
    @type('float32') px: number = 0;
    @type('float32') py: number = 0;
    @type('float32') pz: number = 0;
    // FIXME why does pw exist? should it be removed?
    @type('float32') pw: number = 0;
}

export class TrackedHoverboardData extends Schema implements KeysToNumberObject<typeof VALID_TRACKED_HOVERBOARD_DATA_KEYS> {
    @type('float32') currentSpeed: number = 0;
    @type('float32') currentYSpeed: number = 0;
    @type('float32') currentTurnSpeed: number = 0;
}

export class TrackedPlayer extends Schema {
    @type(TrackedTransform) head: TrackedTransform = new TrackedTransform();
    @type(TrackedTransform) leftHand: TrackedTransform = new TrackedTransform();
    @type(TrackedTransform) rightHand: TrackedTransform = new TrackedTransform();
    @type(TrackedTransform) feet: TrackedTransform = new TrackedTransform();
    @type(TrackedTransform) hoverboard: TrackedTransform = new TrackedTransform();
    @type(TrackedHoverboardData) hoverboardData: TrackedHoverboardData = new TrackedHoverboardData();

    @type('string') name: string = 'Player';
    @type('uint8') avatarType: number = 0;
    @type('string') skinColor: string = "0";
    @type('string') hairColorVariant: string = "0";
    @type('string') suitVariant: string = "0";
    @type('string') headwearVariantMale: string = "0";
    @type('string') headwearVariantFemale: string = "0";
    @type('int8') startingPosition: number = -1;
    @type('string') hoverboardVariant: string = "0";
    @type('int8') lapsAmount: number = -1;
    @type('boolean') isRacing: boolean = false;
    @type('boolean') onTrack: boolean = false;
    @type('uint8') state: number = HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_MENU;
    @type('uint64') fitPoints: number = 0;

    @type('boolean') tagged: boolean = false;
    @type('boolean') invincible: boolean = false;

    @type('boolean') readyToChangeConfig: boolean = false;
}