import { Component, Property } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { AudioPlayer, AudioSetup, Timer } from "wle-pp";
import { common } from "../../../common.js";
import { GAME_STATES } from "../../../game/game-states.js";
import { AudioChannelName } from "../../audio-manager/audio-channel.js";
import { AudioID } from "../../audio-manager/audio-id.js";

const basePath = "assets/audio/sfx/maps/misc/track/motivationals/";

const motivationalSounds = {
    "lap-start": [
        "fly-like-the-wind.webm",
        "fly-with-heart.webm",
        "get-in-the-zone.webm",
        "give-it-everything.webm",
        "keep-the-rhythm.webm",
        "leave-it-all-on-the-track.webm",
        "lets-rip-up-this-track.webm",
        "lets-tear-up-this-track.webm",
        "pump-those-legs.webm",
        "race-with-passion.webm"
    ],
    "lap-middle": [
        "dig-deep.webm",
        "embrace-the-pain.webm",
        "feel-the-burn.webm",
        "keep-going.webm",
        "keep-pushing.webm",
        "keep-the-pace.webm",
        "lets-go.webm",
        "never-give-up.webm",
        "power-through.webm",
        "push-harder.webm",
        "stay-focused.webm",
        "stay-strong.webm",
        "thats-epic.webm",
        "unbelievable-power.webm",
        "you-are-on-fire.webm",
        "you-are-ripping.webm",
        "you-are-shredding-it.webm",
        "you-are-unstoppable.webm"
    ],
    "lap-end": [
        "almost-there.webm",
        "finish-line-in-sight.webm",
        "finish-strong.webm",
        "finish-with-pride.webm",
        "you-are-a-champion.webm"
    ],
    "about-to-overtake": [
        "on-their-trail.webm"
    ],
    "about-to-be-overtaken": [
        "on-your-tail.webm"
    ]
};

const motivationalConditions = {
    "lap-start": (manager) => {
        return common.tracksManager.getRaceManager().getPlayerLapPercentage() < 0.5;
    },
    "lap-middle": (manager) => {
        return common.tracksManager.getRaceManager().getPlayerLapPercentage() > 0.5 || (common.tracksManager.getRaceManager().getPlayerLapsAmount() !== 0);
    },
    "lap-end": (manager) => {
        return common.tracksManager.getRaceManager().getPlayerLapsAmount() === common.gameConfig.lapsAmount.value - 1 && common.tracksManager.getRaceManager().getPlayerLapPercentage() > 0.5 && common.hoverboard.getCurrentSplineTime() > 0.8;
    },
    "about-to-overtake": (manager) => {
        return manager.playerInfront;
    },
    "about-to-be-overtaken": (manager) => {
        return manager.playerBehind;
    },
};

const motivationalPriorities = [
    "about-to-be-overtaken",
    "about-to-overtake",
    "lap-end",
    "lap-middle",
    "lap-start"
];

export class MotivationalAudioManagerComponent extends Component {
    static TypeName = "motivational-audio-manager";
    /* Properties that are configurable in the editor */
    static Properties = {
        playTimerBaseDelay: Property.float(30.0),
        playTimerRandomDelayOffset: Property.float(7.0),
        closePlayerTimerBaseDelay: Property.float(45.0),
        amountRandomSFX: Property.int(10),
    };

    init() {
        common.motivationalAudio = this;

        this.enabled = false;
    }

    start() {
        this.timer = new Timer(this.playTimerBaseDelay + (Math.random() * this.playTimerRandomDelayOffset) - (this.playTimerRandomDelayOffset / 2));
        this.lastPlayedMotivationalIndices = {};

        this.closePlayerTimer = new Timer(this.closePlayerTimerBaseDelay);

        this.loadRandomMotivationals();

        common.tracksManager.getRaceManager().playerNotCloseEmitter.add(this.playerNotClose.bind(this));
        common.tracksManager.getRaceManager().playerCloseBehindEmitter.add(this.playerBehind.bind(this));
        common.tracksManager.getRaceManager().playerCloseInFrontEmitter.add(this.playerInfront.bind(this));

        this.playerBehind = false;
        this.playerInfront = false;

        this.started = false;
    }

    setClosePlayerMotivationalsLocked(value) {
        this.closePlayerMotivationalLocked = value;
    }

    playerNotClose() {
        this.playerBehind = false;
        this.playerInfront = false;
    }

    playerBehind() {
        this.playerBehind = true;
        this.playerInfront = false;
    }

    playerInfront() {
        this.playerBehind = false;
        this.playerInfront = true;
    }

    loadRandomMotivationals() {
        for (let i = 0; i < motivationalPriorities.length; i++) {
            const motivationalKey = motivationalPriorities[i];
            const motivationalSoundArray = motivationalSounds[motivationalKey];

            const amountSFX = Math.min(motivationalSoundArray.length, this.amountRandomSFX);
            const usedSFXIndex = [];
            for (let j = 0; j < amountSFX; j++) {
                let sfxIndex = Math.floor(amountSFX * Math.random());
                while (usedSFXIndex.includes(sfxIndex)) {
                    sfxIndex = Math.floor(amountSFX * Math.random());
                }
                usedSFXIndex.push(sfxIndex);

                const randomSFXSubPath = motivationalSoundArray[sfxIndex];
                const path = basePath + motivationalKey + "/" + randomSFXSubPath;
                const audioSetup = new AudioSetup(path);
                const motivationalID = AudioID.MOTIVATIONAL_ + motivationalKey + "_player_" + j;
                common.audioManager.addSourceAudioToChannel(motivationalID, new AudioPlayer(audioSetup), AudioChannelName.VOICE_OVERS);
            }
        }
    }

    playMotivational() {
        // Skip close player motivationals if the they are locked
        let startingMotivationalPriorityIndex = 0;
        if (this.closePlayerMotivationalLocked) startingMotivationalPriorityIndex = 2;

        for (let i = startingMotivationalPriorityIndex; i < motivationalPriorities.length; i++) {
            const motivationalKey = motivationalPriorities[i];
            if (motivationalConditions[motivationalKey](this)) {
                // Lock close player motivationals
                if (i < 2) {
                    this.setClosePlayerMotivationalsLocked(true);
                    this._restartClosePlayerTimer();
                }

                const amountSFX = Math.min(motivationalSounds[motivationalKey].length, this.amountRandomSFX);
                let newSFXIndex = Math.floor(amountSFX * Math.random());
                while (amountSFX !== 1 && newSFXIndex === this.lastPlayedMotivationalIndices[motivationalKey]) {
                    newSFXIndex = Math.floor(amountSFX * Math.random());
                }
                this.lastPlayedMotivationalIndices[motivationalKey] = newSFXIndex;
                const motivationalID = AudioID.MOTIVATIONAL_ + motivationalKey + "_player_" + newSFXIndex;
                const player = common.audioManager.getAudio(motivationalID);
                player.play();
                break;
            }
        }
    }

    startMotivational() {
        this.started = true;

        let firstMotivationalDelay = 1;
        this.timer.start(firstMotivationalDelay);
    }

    stopMotivational() {
        this.started = false;
    }

    update(dt) {
        if (this.started && this.enabled) {
            if (common.gameConfig.mode === GameMode.Race && common.CURRENT_STATE === GAME_STATES.GAME) {
                this.timer.update(dt);
                this.closePlayerTimer.update(dt);

                if (this.timer.isDone()) {
                    this._restartTimer();
                    this.playMotivational();
                }

                if (this.closePlayerTimer.isDone()) {
                    this.setClosePlayerMotivationalsLocked(false);
                }
            }
        }
    }

    _restartTimer() {
        this.timer.start(this.playTimerBaseDelay + (Math.random() * this.playTimerRandomDelayOffset) - (this.playTimerRandomDelayOffset / 2));
    }

    _restartClosePlayerTimer() {
        this.closePlayerTimer.reset(this.closePlayerTimerBaseDelay);
        this.closePlayerTimer.start();
    }
}