import { Object3D } from "@wonderlandengine/api";
import { EnvironmentalSoundAreaComponent } from "src/hoverfit/audio/game/environmental-sounds/components/environmental-sound-area-component.js";
import { EnvironmentalSoundComponent } from "src/hoverfit/audio/game/environmental-sounds/components/environmental-sound-component.js";
import { EnvironmentalSoundsManager } from "src/hoverfit/audio/game/environmental-sounds/environmental-sounds-manager.js";
import { PopupTipsManager } from "src/hoverfit/ui/kiosk/popup-tip/popup-tips-manager.js";
import { Globals } from "wle-pp";
import { common } from "../../common.js";
import { StatusEffectsManager } from "../hoverboard/status-effects/status-effects-manager.js";
import { TrackComponent } from "./components/track-component.js";
import { MiniMapManager } from "./mini-map-manager.js";
import { RaceManager } from "./race-manager.js";
import { TagResultsBoard } from "./results/tag-results-board.js";
import { TrackStatistics } from "./results/track-statistics.js";
import { Track } from "./track.js";

export class TracksManager {
    private _tracksObject: Object3D;

    private _trackStatistics: TrackStatistics = new TrackStatistics();
    private _raceManager: RaceManager = new RaceManager();

    private _miniMapManager: MiniMapManager = new MiniMapManager();

    private _statusEffectsManager: StatusEffectsManager = new StatusEffectsManager();

    private _popupTipsManager: PopupTipsManager = new PopupTipsManager();

    private _tagResultsBoard!: TagResultsBoard;

    private _updateCountdown: number = 5;
    private _tracksReady: boolean = false;

    private _tracks: Track[] = [];
    private _tracksByMapTrackIndex: Map<number, Track> = new Map();
    private _currentTrack: Track | null = null;
    private _currentMapTrackIndex: number | null = null;

    private _roundStarted: boolean = false;

    private _environmentalSoundsManager: EnvironmentalSoundsManager = new EnvironmentalSoundsManager();

    constructor(tracksObject: Object3D) {
        common.tracksManager = this;

        this._tracksObject = tracksObject;
        this._raceManager.setTracksManager(this);

        for (const trackComponent of this._tracksObject.pp_getComponents(TrackComponent)) {
            const track = new Track(trackComponent.object, trackComponent.mapTrackIndex, trackComponent.showDynamicChevrons);
            track.setTracksManager(this);

            this._tracks.push(track);

            this._tracksByMapTrackIndex.set(track.getMapTrackIndex(), track);
        }
    }

    start() {
        const tagResultsBoardObject = common.hoverboard.object.pp_getObjectByName("Tag Results Board");
        this._tagResultsBoard = new TagResultsBoard(tagResultsBoardObject!);
        this._tagResultsBoard.setVisible(false);

        for (const track of this._tracks) {
            track.start();
        }
    }

    returnedToBalcony() {
        this._statusEffectsManager.clearEffects(null);

        if (this._currentTrack != null) {
            this._currentTrack.returnedToBalcony();
        }

        this._popupTipsManager.returnedToBalcony();
    }

    countdownStarted() {
        if (this._currentTrack != null) {
            this._currentTrack.countdownStarted();
        }

        this.getTrackStatistics().reset();

        this._popupTipsManager.countdownStarted();
    }

    areAllTracksReady(): boolean {
        let areAllTracksReady = this._tracksReady;

        for (const trackComponent of this._tracksByMapTrackIndex.values()) {
            areAllTracksReady &&= trackComponent.isSplineLoaded();
        }

        return areAllTracksReady;
    }

    getRaceManager(): RaceManager {
        return this._raceManager;
    }

    getStatusEffectsManager(): StatusEffectsManager {
        return this._statusEffectsManager;
    }

    getMiniMapManager(): MiniMapManager {
        return this._miniMapManager;
    }

    getTagResultsBoard(): TagResultsBoard {
        return this._tagResultsBoard;
    }

    getTrackStatistics(): TrackStatistics {
        return this._trackStatistics;
    }

    getCurrentTrack(): Track | null {
        return this._currentTrack;
    }

    getEnvironmentalSoundsManager() {
        return this._environmentalSoundsManager;
    }

    setRoundStarted(roundStarted: boolean): void {
        if (this._roundStarted != roundStarted) {
            this._roundStarted = roundStarted;

            if (!this._roundStarted) {
                if (this._currentTrack != null) {
                    this._currentTrack.roundEnded();
                }
            }
        }
    }

    isRoundStarted(): boolean {
        return this._roundStarted;
    }

    setTrackByMapTrackIndex(mapTrackIndex: number) {
        if (this._tracksReady) {

            if (this._currentTrack != null) this._currentTrack.setActive(false);

            this._currentTrack = this._tracksByMapTrackIndex.get(mapTrackIndex)!;
            if (!this._currentTrack) throw new Error("Invalid map track index");

            this._currentTrack.setActive(true);
        }

        this._currentMapTrackIndex = mapTrackIndex;
    }

    update(dt: number) {
        if (this._updateCountdown > 0) {
            this._updateCountdown--;
            if (this._updateCountdown == 0) {
                this.setTrackByMapTrackIndex(common.gameConfig.trackConfig.mapTrackIndex);

                this._raceManager.start(this._tracksObject);
                this._miniMapManager.start(common.balcony.object.pp_getObjectByName("Track Mini Map")!);

                const environmentalSoundAreaComponents = Globals.getSceneObject()!.pp_getComponents(EnvironmentalSoundAreaComponent);
                for (const environmentalSoundAreaComponent of environmentalSoundAreaComponents) {
                    const envSoundsComponents = environmentalSoundAreaComponent.object.pp_getComponents(EnvironmentalSoundComponent);

                    const envSounds = [];
                    for (const envSoundsComponent of envSoundsComponents) {
                        if (envSoundsComponent.active) {
                            envSounds.push(envSoundsComponent.getEnvironmentalSound());
                        }
                    }

                    const envSoundArea = environmentalSoundAreaComponent.getArea();
                    envSoundArea.setSounds(envSounds);
                    this._environmentalSoundsManager.addArea(envSoundArea);
                }
            }
        } else {
            if (!this._tracksReady) {
                this._tracksReady = true;
                for (const trackComponent of this._tracksByMapTrackIndex.values()) {
                    this._tracksReady &&= trackComponent.isReady();
                }

                if (this._tracksReady && this._currentMapTrackIndex! != null) {
                    this.setTrackByMapTrackIndex(this._currentMapTrackIndex);
                }
            }

            this._environmentalSoundsManager.update(dt);
            this._statusEffectsManager.update(dt);
            this._raceManager.update(dt);
            this._miniMapManager.update(dt);

            for (const track of this._tracks) {
                track.update(dt);
            }

            common.leaderboardsManager.update(dt);
        }
    }
}