import { Component, MeshComponent, Object3D, PhysXComponent, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import anime from "animejs";
import { GameMode, HOVERBOARD_PLAYER_MULTIPLAYER_STATES } from "hoverfit-shared-netcode";
import { hapticFeedback } from "src/hoverfit/misc/haptic-feedback.js";
import { AnalyticsUtils, AudioPlayer, AudioSetup, CharacterColliderSetup, CharacterColliderSetupSimplifiedCreationAccuracyLevel, CharacterColliderSetupSimplifiedCreationParams, CharacterColliderSetupUtils, CharacterCollisionResults, CharacterCollisionSurfaceInfo, EasyTuneBool, EasyTuneNumber, GamepadAxesID, GamepadButtonID, Globals, Handedness, MaterialUtils, MathUtils, PhysicsLayerFlags, Quaternion2, Timer, Vector3, VisualTorusParams, XRUtils, quat2_create, quat_create, vec3_create } from "wle-pp";
import { AudioChannelName } from "../../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { AVATAR_FORWARD_OFFSET_FROM_HEAD, AvatarComponent } from "../../../avatar/components/avatar-component.js";
import { common } from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { NetworkPlayerComponent } from "../../../network/components/network-player-component.js";
import { PopupIconDecoration, PopupIconImage } from "../../../ui/popup/popup.js";
import { getDailyMedalFitPointsTier, getDailyMedalName, getDailyMedalPopupIconImage, getRandomEncouragement } from "../../../utils/reward-utils.js";
import { getTime } from "../../../utils/time-utils.js";
import { EasyTuneExtraParamsSet, HoverboardDebugs } from "../../components/hoverboard-debugs-component.js";
import { GAME_STATES } from "../../game-states.js";
import { PickupGrabber } from "../../track/pickups/pickup-grabber.js";
import { HoverboardGlueStatusEffect } from "../status-effects/implementations/glue-status-effect.js";
import { RampStatusEffect, RampStatusEffectParams } from "../status-effects/implementations/ramp-status-effect.js";
import { StatusEffectTarget } from "../status-effects/status-effect-target.js";
import { StatusEffectType } from "../status-effects/status-effect.js";
import { InvincibilityRingComponent } from "./invincibility-ring-component.js";
import { TagRingComponent } from "./tag-ring-component.js";

const SURFACE_SNAP_MAX_DISTANCE = 0.25;

export class HoverboardComponent extends Component implements StatusEffectTarget, PickupGrabber {
    static TypeName = "hoverboard";

    @property.float(60)
    maxSpeed!: number;

    @property.float(0.9)
    speedBraking!: number;

    @property.float(0)
    speedBoostOnStart!: number;

    @property.float(0.8)
    speedGainMultiplier!: number;

    @property.float(28)
    speedSquatGain!: number;

    @property.float(10)
    speedHandGain!: number;



    @property.bool(true)
    verticalMovementEnabled!: boolean;

    @property.float(60)
    maxVerticalSpeed!: number;

    @property.float(10)
    maxVerticalFlySpeed!: number;

    @property.float(60)
    maxVerticalFallSpeed!: number;

    @property.float(30)
    gravityAcceleration!: number;

    @property.float(3)
    verticalOffsetFromGround!: number;



    @property.float(30)
    horizontalSpeedRampBoost!: number;

    @property.float(3)
    verticalSpeedRampBoostMultiplier!: number;

    @property.float(2.5)
    verticalSpeedEnviromentalBoostMultiplier!: number;



    @property.float(90)
    maxTurnSpeed!: number;

    @property.float(5)
    startHeadAngleToTurn!: number;

    @property.float(50)
    startHeadAngleToSlowTurn!: number;

    @property.float(70)
    endHeadAngleToSlowTurn!: number;

    @property.float(1.7)
    turnSpeedMultiplier!: number;

    @property.float(0.3)
    turnSpeedEasing!: number;

    @property.float(0.2)
    turnSpeedSlowDownMultiplier!: number;



    @property.float(3)
    tagCatchDistance!: number;



    @property.bool(false)
    autoAdjustForwardWhenSlidingAlongWall!: boolean;

    @property.float(60)
    autoAdjustForwardTurnSpeed!: number;

    @property.float(0.125)
    autoAdjustForwardTurnSpeedEasing!: number;



    @property.object()
    avatarComponentObject!: Object3D;

    @property.object()
    hoverboardMeshObject!: Object3D;

    @property.object()
    speedIndicatorPanelObject!: Object3D;

    @property.object()
    speedIndicatorObject!: Object3D;

    @property.object()
    currentFitPointsIndicatorObject!: Object3D;

    @property.float(30)
    maxJetStreamTilt!: Object3D;



    @property.float(0)
    wallCollisionSFXAngleThreshold!: number;

    @property.float(40)
    wallCollisionSFXSpeedThreshold!: number;



    private headPosition: Vector3 = vec3_create();
    private headPositionPrev: Vector3 = vec3_create();

    private leftHandPrevY: number = -1;
    private rightHandPrevY: number = -1;

    private currentSpeed: number = 0;
    private currentSpeedAdjusted: number = 0;
    private actualCurrentSpeed: number = 0; // Comptued based on the actual movement performed after the collision check
    private currentYSpeed: number = 0;
    private currentYSpeedAdjusted: number = 0;
    private currentTurnSpeed: number = 0;
    private currentTurnSpeedAdjusted: number = 0;
    private speedSlowDownMultiplier: number = 1;

    private currentSplineTime: number = 0;

    public verticalSpeedJumpMaxVerticalSpeedJumpToAdd: number = 15;
    public verticalSpeedJumpMaxAngle: number = 15;
    private snapOnGroundEnabled: boolean = false;
    private verticalSpeedJumpToAdd: number = 0;
    private verticalSpeedJumpBoost: boolean = false;
    private verticalSpeedJumpIsRamp: boolean = false;
    private rampLayerFlags = new PhysicsLayerFlags();

    private flying: boolean = false;
    private flyModes: GameMode[] = [GameMode.Roam];

    private movementFlatDirection: Vector3 = vec3_create();

    private adjustingForward: boolean = false;
    private adjustingForwardSign: number = 0;
    private adjustingForwardKeepAdjustingTimer: Timer = new Timer(0.1);
    private adjustingForwardLastSign: number = 0;

    private currentDt: number = 0;
    private riseCompleted: boolean = false;
    public minVerticalOffsetFromGround: number = 0.5;
    private riseVerticalOffsetFromGround: number = 0;

    private spawnPosition: number = -1;

    private hoverboardStarted: boolean = false;
    private hoverboardPlayTime: number = 0;
    private hoverboardReady: boolean = false;
    private resetViewDirty: number = 0;

    private hoverSoundPitchMultiplier: number = 1.5;
    private hoverSoundStartPitch: number = 0.7;
    private hoverSoundMaxPitchGain: number = 2;
    private hoverSoundVolume: number = 1.0;

    private visualCurrentSpeed: number = 0;
    private visualCurrentYSpeed: number = 0;
    private visualCurrentSpeedConverted: number = 0;
    private visualCurrentSpeedUpdateTimer = new Timer(0.05);
    private visualTurnSpeed: number = 0;
    private speedPercentage: number = 0;

    private frontStreams: Partial<Record<Handedness, Object3D>> = {};
    private backStreams: Partial<Record<Handedness, Object3D>> = {};

    private audioUpdateDt: number = 0;

    private hoverboardSpeedNormalEventSent: boolean = false;
    private hoverboardSpeedFastEventSent: boolean = false;
    private hoverboardSpeedVeryFastEventSent: boolean = false;

    private goingNormalEventTimer: Timer = new Timer(5);
    private goingFastEventTimer: Timer = new Timer(5);
    private goingVeryFastEventTimer: Timer = new Timer(5);

    private wallAudioPosition: Vector3 = vec3_create();
    private wallAudioNormal: Vector3 = vec3_create();
    private wallHitStillCollidingTimer: Timer = new Timer(0.25);

    private hoverboardFlyEventSent: boolean = false;

    private firstUpdate: boolean = true;

    private colliderSetup!: CharacterColliderSetup;
    private collisionResults: CharacterCollisionResults = new CharacterCollisionResults();

    private speedText!: TextComponent;
    private currentFitPointsText!: TextComponent;

    private uiActive: boolean = false;

    private startTransformQuat: Quaternion2 = quat2_create();
    private debugSavedStartTransformQuat: Quaternion2 = quat2_create();

    private referenceSpaceSyncPivot!: Object3D;

    private currentHoverSoundRate: number = 0;

    private tagRingComponent!: TagRingComponent;
    private invincibilityRingComponent!: InvincibilityRingComponent;

    private avatar!: AvatarComponent;
    private avatarObject!: Object3D;
    private feetTargetObject!: Object3D;

    private earnedFitPoints: number = 0;

    private squatsAmount: number = 0;
    private squatDownPerformed: boolean = false;
    private squatUpPerformed: boolean = false;
    private squatsStartHeight: number | null = null;
    private squatsHeightDistance: number = 0.3;

    private riseAnim: anime.AnimeInstance | null = null;

    private _snapCharge: boolean = false;

    init() {
        common.hoverboard = this;

        this.rampLayerFlags.setFlagActive(6, true);

        this._setupStreams();
    }

    start() {
        this.hoverboardMeshObject.pp_getComponent(MeshComponent)!.object.pp_setPositionLocal(vec3_create(0, 0, AVATAR_FORWARD_OFFSET_FROM_HEAD));

        this.speedText = this.speedIndicatorObject.getComponent(TextComponent)!;
        this.currentFitPointsText = this.currentFitPointsIndicatorObject.getComponent(TextComponent)!;

        this.speedIndicatorPanelObject.pp_setActive(false);
        if (common.circularMap) common.circularMap.setEnabled(false);


        this.referenceSpaceSyncPivot = this.object.pp_addChild();
        this.referenceSpaceSyncPivot.pp_setName("Reference Space Sync Pivot");

        MaterialUtils.setObjectClonedMaterials(this.hoverboardMeshObject);

        // Hoverboard Sound SFX
        const hoverSoundSetup = new AudioSetup("assets/audio/sfx/hoverboard/misc/hover.webm");
        hoverSoundSetup.myVolume = this.hoverSoundVolume;
        hoverSoundSetup.myLoop = true;
        const hoverSoundPlayer = new AudioPlayer(hoverSoundSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.HOVER, hoverSoundPlayer, AudioChannelName.SFX);

        // Hoverboard Rise SFX
        const riseSoundSetup = new AudioSetup("assets/audio/sfx/hoverboard/misc/rise.webm");
        const riseSoundPlayer = new AudioPlayer(riseSoundSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.RISE, riseSoundPlayer, AudioChannelName.SFX);

        // Tag SFX
        const taggedSoundSetup = new AudioSetup("assets/audio/sfx/hoverboard/tag/tagged.webm");
        const taggedSoundPlayer = new AudioPlayer(taggedSoundSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.TAGGED, taggedSoundPlayer, AudioChannelName.SFX);
        const caughtSoundSetup = new AudioSetup("assets/audio/sfx/hoverboard/tag/caught.webm");
        const caughtSoundPlayer = new AudioPlayer(caughtSoundSetup);
        common.audioManager.addSourceAudioToChannel(AudioID.CAUGHT, caughtSoundPlayer, AudioChannelName.SFX);

        // Tag Visuals
        const tagPositionOffset = this.computeTagPositionOffset(false);
        tagPositionOffset.vec3_add(GameGlobals.up.vec3_scale(-0.3), tagPositionOffset);

        this.tagRingComponent = this.object.pp_getComponent(TagRingComponent)!;
        this.tagRingComponent.object.pp_setPositionLocal(tagPositionOffset);

        this.invincibilityRingComponent = this.object.pp_getComponent(InvincibilityRingComponent)!;
        this.invincibilityRingComponent.object.pp_setPositionLocal(tagPositionOffset);

        this.avatar = this.avatarComponentObject.pp_getComponent(AvatarComponent)!;
        this.avatarObject = this.avatar.object.pp_getObjectByName("Avatar")!;
        this.feetTargetObject = this.object.pp_getObjectByName("Feet IK Target")!;

        this.avatarObject.pp_setParent(this.object, false);

        this.hoverboardMeshObject.pp_setActive(false);

        XRUtils.registerSessionStartEndEventListeners(this, this._onXRSessionStart.bind(this), this._onXRSessionEnd.bind(this), true);
    }

    setSoundEnabled(enabled: boolean) {
        if (enabled) {
            common.audioManager.getAudio(AudioID.HOVER)!.play();
        } else {
            common.audioManager.getAudio(AudioID.HOVER)!.stop();
        }
    }

    private static readonly _updateSV =
        {
            avatarForward: vec3_create()
        };
    update(dt: number) {
        if (this.firstUpdate) {
            this._setupEasyTuneVariables();
            this.firstUpdate = false;
        }

        if (this.colliderSetup == null && Globals.getPlayerObjects() != null) {
            this._createColliderSetup();

            Globals.getPlayerObjects()!.myPlayer!.pp_getTransformQuat(this.startTransformQuat);
            Globals.getPlayerObjects()!.myReferenceSpace!.pp_setParent(this.referenceSpaceSyncPivot, false);
        }

        this.currentDt = dt;

        if (this.hoverboardReady) {
            if (this.resetViewDirty > 0) {
                this.resetViewDirty--;
                if (this.resetViewDirty == 0) {
                    this._syncHeadForwardToBoard();
                }
            }
        } else {
            const avatarForward = HoverboardComponent._updateSV.avatarForward;
            this.feetTargetObject.pp_setUp(GameGlobals.up, this.avatarObject.pp_getForward(avatarForward));
        }

        if (this.hoverboardStarted && this.riseCompleted) {
            this.hoverboardPlayTime += dt;

            if (!this.uiActive) {
                this.uiActive = true;

                this.speedIndicatorPanelObject.pp_setActive(true);
                common.tracksManager.getRaceManager().setupSpeedometer();

                if (common.circularMap) {
                    common.circularMap.setEnabled(true);
                }
            }

            if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled) {
                if (Globals.getRightGamepad()!.getButtonInfo(GamepadButtonID.THUMBSTICK).isPressEnd(2)) {
                    const debugSpawnPosition = common.tracksManager.getCurrentTrack()!.getSpawnPositionProvider().getDebugSpawnPosition();

                    if (debugSpawnPosition != null) {
                        const debugPosition = debugSpawnPosition.quat2_getPosition().vec3_add(vec3_create(0, common.hoverboard.verticalOffsetFromGround, 0));
                        debugSpawnPosition.quat2_setPosition(debugPosition);

                        Globals.getPlayerObjects()!.myPlayer!.pp_setTransformQuat(debugSpawnPosition);

                        this._stopBoardAndSnapToGround();
                    } else {
                        Globals.getPlayerObjects()!.myPlayer!.pp_setTransformQuat(this.debugSavedStartTransformQuat);
                    }

                    this.collisionResults.myGroundInfo.myOnSurface = false;
                }
            }

            this._updateCollider();

            this._squatHorizontalBasedMovement(dt);

            if (this.autoAdjustForwardWhenSlidingAlongWall || (Globals.isDebugEnabled() && HoverboardDebugs.autoRace)) {
                this._adjustForwardToMovementDirection(dt);
            }

            this._headDirectionBasedRotation(dt);
            this._snapRotateUpdate(dt);

            this._tagUpdate(dt);

            const activeTrack = common.tracksManager.getCurrentTrack();
            if (activeTrack != null && activeTrack.hasSpline()) {
                this.updateSplineTime();
            }
        }

        if (this.hoverboardReady) {
            this._updateVisualAndSound(dt);
        }

        if (this.riseAnim) {
            this.riseAnim.tick(getTime());
        }
    }

    prepareHoverboardToRace(spawnPositionIndex: number) {
        this.colliderSetup = this._createColliderSetup();

        this._updateStartTransform(spawnPositionIndex);
        this.resetHoverboard();
        this._putStartMenuInFrontOfHoverboard();

        this.avatar.setHoverboardStanceActive(true);

        this.setSoundEnabled(true);

        common.circularMap.resetTagged();

        this.hoverboardReady = true;
    }

    resetHoverboard() {
        this.resetHoverboardState();

        this.feetTargetObject.pp_resetRotationLocal();

        const startPosition = this.startTransformQuat.quat2_getPosition();
        Globals.getPlayerObjects()!.myPlayer!.pp_setPosition(startPosition.vec3_add(GameGlobals.up.vec3_scale(this.minVerticalOffsetFromGround * 2)));
        Globals.getPlayerObjects()!.myPlayer!.pp_resetRotationLocal();
        Globals.getPlayerObjects()!.myPlayer!.pp_setUp(GameGlobals.up, this.startTransformQuat.quat2_getForward());

        this.colliderSetup.myAdditionalParams.myPositionOffsetLocal.vec3_set(0, -this.minVerticalOffsetFromGround, 0);

        // Adjust position around collisions
        const surfaceSnapMaxDistanceBackup = this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance;
        this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = 10;
        this.collisionResults.myGroundInfo.myOnSurface = true;
        this._move(vec3_create(0), 0);
        this.collisionResults.reset();
        this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = surfaceSnapMaxDistanceBackup;

        this._syncHeadForwardToBoard();

        this.hoverboardMeshObject.pp_setActive(true);
        this.hoverboardMeshObject.pp_resetRotationLocal();

        const hoverAudio = common.audioManager.getAudio(AudioID.HOVER)!;
        hoverAudio.setPitch(this.hoverSoundStartPitch + this.currentHoverSoundRate);
        hoverAudio.setVolume(this.hoverSoundVolume);

        this.currentFitPointsText.text = "0";

        if (common.watchController) {
            common.watchController.updateEarnedFitnessPoints(0);
        }

        this.squatsAmount = 0;
        this.squatDownPerformed = false;
        this.squatUpPerformed = true;
        this.squatsStartHeight = null;

        if (Globals.isDebugEnabled() && HoverboardDebugs.turboMode) {
            this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = SURFACE_SNAP_MAX_DISTANCE;
        }
    }

    startHoverboard(speedBoostOnStart = false) {
        if (this.hoverboardStarted) return;

        // sync head position values
        Globals.getPlayerObjects()!.myHead!.pp_getPositionLocal(this.headPosition);
        Globals.getPlayerObjects()!.myHead!.pp_getPositionLocal(this.headPositionPrev);

        if (!Globals.isDebugEnabled() || !HoverboardDebugs.disableStartBoost) {
            if (speedBoostOnStart) {
                // initial speed boost
                this.currentSpeed = this.speedBoostOnStart;
            }
        }

        if (!this.riseCompleted) {
            this.startHoverboardRiseAnimation();
        }

        this.visualCurrentSpeed = 0;
        this.visualCurrentYSpeed = 0;
        this.visualTurnSpeed = 0;
        this._displaySpeed(0, true);

        this.hoverboardStarted = true;
        this.hoverboardPlayTime = 0;
    }

    stopHoverboard() {
        this.earnedFitPoints = 0;

        if (this.hoverboardPlayTime > 0) {
            const playTimeMS = Math.trunc(this.hoverboardPlayTime * 1000);
            common.playerData.dailyPlayTime += playTimeMS;
            common.playerData.weeklyPlayTime += playTimeMS;
            common.playerData.monthlyPlayTime += playTimeMS;
            common.playerData.totalPlayTime += playTimeMS;
            this.hoverboardPlayTime = 0;
        }

        this.resetHoverboardState();

        this.setSoundEnabled(false);

        this.referenceSpaceSyncPivot.pp_resetRotationLocal();

        this.avatar.setHoverboardStanceActive(false);

        this.speedIndicatorPanelObject.pp_setActive(false);
        if (common.circularMap) common.circularMap.setEnabled(false);
        this.tagRingComponent.setVisible(false);
        this.invincibilityRingComponent.setVisible(false);

        this.hoverboardMeshObject.pp_setActive(false);
    }

    startHoverboardRiseAnimation() {
        this.riseVerticalOffsetFromGround = this.minVerticalOffsetFromGround;

        // Disable any instructor audio playing
        common.hoverboardInstructor.stopAudioPlayers();

        let duration = 1000;
        if (Globals.isDebugEnabled() && HoverboardDebugs.skipCountdown) {
            duration = 10;
        }

        const surfacePopOutMaxDistanceBackup = this.colliderSetup.myGroundParams.mySurfacePopOutMaxDistance;
        this.colliderSetup.myGroundParams.mySurfacePopOutMaxDistance = this.verticalOffsetFromGround + 0.5;

        const zero = vec3_create(0);
        this.riseAnim = anime({
            targets: this,
            easing: "easeOutQuad",
            riseVerticalOffsetFromGround: this.verticalOffsetFromGround,
            delay: 0,
            duration: duration,
            autoplay: false,
            update: (anim) => {
                if (this.riseAnim != null) {
                    this.colliderSetup.myAdditionalParams.myPositionOffsetLocal.vec3_set(0, -this.riseVerticalOffsetFromGround, 0);
                    this._move(zero, this.currentDt);
                }
            },
            complete: (anim) => {
                this.colliderSetup.myAdditionalParams.myPositionOffsetLocal.vec3_set(0, -this.verticalOffsetFromGround, 0);
                this._move(zero, this.currentDt);

                this.colliderSetup.myGroundParams.mySurfacePopOutMaxDistance = surfacePopOutMaxDistanceBackup;

                this.riseAnim = null;
                this.riseCompleted = true;

                Globals.getPlayerObjects()!.myPlayer!.pp_getTransformQuat(this.debugSavedStartTransformQuat);
            }
        });

        common.audioManager.getAudio(AudioID.RISE)!.play();
    }

    playerTagged() {
        AnalyticsUtils.sendEvent("tag_player_tagged");

        hapticFeedback(Handedness.LEFT, 0.35, 0.5);
        hapticFeedback(Handedness.RIGHT, 0.35, 0.5);

        common.audioManager.getAudio(AudioID.TAGGED)!.play();
    }

    resetTransformToSpline() {
        if (!this.hoverboardStarted || !this.riseCompleted || common.gameConfig.mode != GameMode.Race) return;

        const spline = common.tracksManager.getCurrentTrack()!.getSpline()!;
        const resetPosition = spline.getPosition(this.currentSplineTime);
        const resetForward = spline.getForward(this.currentSplineTime);

        Globals.getPlayerObjects()!.myPlayer!.pp_setPosition(resetPosition.vec3_add(GameGlobals.up.vec3_scale(this.verticalOffsetFromGround * 2)));
        Globals.getPlayerObjects()!.myPlayer!.pp_setUp(GameGlobals.up, resetForward);

        this._stopBoardAndSnapToGround();
    }

    private static readonly _computeTagPositionOffsetSV =
        {
            forwardOffset: vec3_create(),
            upOffset: vec3_create()
        };
    computeTagPositionOffset(addVerticalOffset: boolean, outTagPositionOffset: Vector3 = vec3_create()) {
        const verticalOffset = addVerticalOffset ? 1 : 0;

        const forwardOffset = HoverboardComponent._computeTagPositionOffsetSV.forwardOffset;
        const upOffset = HoverboardComponent._computeTagPositionOffsetSV.upOffset;
        outTagPositionOffset = GameGlobals.forward.vec3_scale(0.5, forwardOffset).vec3_add(GameGlobals.up.vec3_scale(verticalOffset, upOffset), outTagPositionOffset);
        return outTagPositionOffset;
    }

    getCurrentSplineTime() {
        return this.currentSplineTime;
    }

    applySpeedBoost(boostAmount: number) {
        this.currentSpeed += boostAmount;
    }

    applyVerticalSpeedBoost(boostAmount: number) {
        this.currentYSpeed += boostAmount;
    }

    getEarnedFitPoints() {
        return this.earnedFitPoints;
    }

    getSquatsAmount() {
        return this.squatsAmount;
    }

    isHoverboardStarted() {
        return this.hoverboardStarted;
    }

    getPosition(outPosition: Vector3): Vector3 {
        return this.object.pp_getPosition(outPosition);
    }

    canPickup(): boolean {
        return this.isHoverboardStarted();
    }

    isNPC(): boolean {
        return false;
    }

    isChaser(): boolean {
        let isChaser = false;

        const hoverboardNetworking = common.hoverboardNetworking;
        if (hoverboardNetworking.room) {
            if (common.gameConfig.mode == GameMode.Tag) {
                if (hoverboardNetworking.localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                    isChaser = hoverboardNetworking.localPlayer!.tagged;
                }
            }
        }

        return isChaser;
    }

    arePickupFeedbacksEnabled() {
        return true;
    }

    areStatusEffectFeedbacksEnabled() {
        return true;
    }

    getSpawnPosition(): number {
        return this.spawnPosition;
    }

    private updateFitPoints(dt: number, speedFraction: number) {
        const fitPointsPerSec = 43 * (speedFraction ** 1.75) / 60;
        const addedPoints = dt * fitPointsPerSec;

        const prevWholeEarnedFitPoints = Math.floor(this.earnedFitPoints);

        this.earnedFitPoints += addedPoints;
        const wholeEarnedFitPoints = Math.floor(this.earnedFitPoints);

        if (prevWholeEarnedFitPoints !== wholeEarnedFitPoints) {
            const fitPointsDifference = wholeEarnedFitPoints - prevWholeEarnedFitPoints;
            common.playerData.dailyFitPoints = Math.floor(common.playerData.dailyFitPoints + fitPointsDifference);
            common.playerData.totalFitPoints = Math.floor(common.playerData.totalFitPoints + fitPointsDifference);
            common.playerData.fitabux = Math.floor(common.playerData.fitabux + fitPointsDifference * 10);

            if (common.watchController) {
                common.watchController.updateEarnedFitnessPoints(wholeEarnedFitPoints);
            }

            const medalEarned = this._updateEarnedDailyMedals();
            if (!medalEarned) {
                const showPopupFitPointsStep = 25;
                if (wholeEarnedFitPoints % showPopupFitPointsStep == 0) {
                    this._showFitPointsEarnedPopup();
                }
            }
        }
    }

    private _showFitPointsEarnedPopup() {
        const title = "---- " + getRandomEncouragement() + " ----";
        const message = "You have earned\n" + (1 * Math.floor(this.earnedFitPoints)).toFixed(0) + " Fit Points";
        common.popupManager.showMessagePopup(title + "\n" + message, PopupIconImage.FitPoints, undefined, undefined, undefined, AudioID.POPUP_TRACK_NOTIFICATION);
    }

    private _updateEarnedDailyMedals() {
        let medalEarned = false;

        const dailyRewardTier = getDailyMedalFitPointsTier(common.playerData.dailyFitPoints);

        if (dailyRewardTier > common.playerData.dailyRewardTier) {
            common.playerData.updateDailyRewardTier(dailyRewardTier);

            const title = ">>>> " + getRandomEncouragement() + " <<<<";
            const message = "You earned a\n" + getDailyMedalName(dailyRewardTier).toUpperCase() + " Daily Fit Points Medal";

            common.popupManager.showMessagePopup(title + "\n" + message, getDailyMedalPopupIconImage(dailyRewardTier), undefined, undefined, PopupIconDecoration.BayleafWreath, AudioID.POPUP_TRACK_NOTIFICATION);

            medalEarned = true;
        }

        return medalEarned;
    }

    private static readonly _tagUpdateSV =
        {
            baseTagTransformQuat: quat2_create(),
            tagTransformQuat: quat2_create(),
            currentTagPosition: vec3_create(),
            otherTagPosition: vec3_create()
        };
    private _tagUpdate(dt: number) {
        const hoverboardNetworking = common.hoverboardNetworking;
        if (hoverboardNetworking.room) {
            if (common.gameConfig.mode == GameMode.Tag) {
                if (hoverboardNetworking.localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                    if (hoverboardNetworking.localPlayer!.tagged) {
                        const currentTagPosition = HoverboardComponent._tagUpdateSV.currentTagPosition;
                        const baseTagTransformQuat = HoverboardComponent._tagUpdateSV.baseTagTransformQuat;
                        const tagTransformQuat = HoverboardComponent._tagUpdateSV.tagTransformQuat;
                        this._computeTagTransformQuat(this.object.pp_getTransformQuat(baseTagTransformQuat), tagTransformQuat).quat2_getPosition(currentTagPosition);

                        const otherTagPosition = HoverboardComponent._tagUpdateSV.otherTagPosition;
                        for (const otherPlayer of hoverboardNetworking.otherPlayers!.entries()) {
                            if (!otherPlayer[1].tagged && !otherPlayer[1].invincible) {
                                const otherNetworkPlayer = hoverboardNetworking.otherPlayerObjects!.get(otherPlayer[0])!.pp_getComponent(NetworkPlayerComponent)!;
                                this._computeTagTransformQuat(otherNetworkPlayer.hoverboard!.pp_getTransformQuat(baseTagTransformQuat), tagTransformQuat).quat2_getPosition(otherTagPosition);

                                if (currentTagPosition.vec3_distance(otherTagPosition) <= this.tagCatchDistance * 2) {
                                    hoverboardNetworking.tagPlayer(otherPlayer[0]);

                                    const caughtAudio = common.audioManager.getAudio(AudioID.CAUGHT)!;
                                    if (!caughtAudio.isPlaying()) {
                                        hapticFeedback(Handedness.LEFT, 0.35, 0.35);
                                        hapticFeedback(Handedness.RIGHT, 0.35, 0.35);

                                        caughtAudio.play();
                                    }
                                }
                            }
                        }
                    }

                    this._tagDebugUpdate(dt);
                }
            }
        }
    }

    private resetHoverboardState() {
        this.hoverboardReady = false;
        this.hoverboardStarted = false;
        this.hoverboardPlayTime = 0;

        this.currentSpeed = 0;
        this.currentSpeedAdjusted = 0;
        this.currentYSpeed = 0;
        this.currentYSpeedAdjusted = 0;
        this.currentTurnSpeed = 0;
        this.currentTurnSpeedAdjusted = 0;

        this.speedSlowDownMultiplier = 1;

        this.verticalSpeedJumpToAdd = 0;
        this.verticalSpeedJumpBoost = false;

        this.collisionResults.reset();

        this.riseAnim = null;
        this.riseCompleted = false;

        this.visualCurrentSpeed = 0;
        this.visualCurrentYSpeed = 0;
        this.visualCurrentSpeedConverted = 0;
        this.visualTurnSpeed = 0;
        this.speedPercentage = 0;

        this.uiActive = false;

        this.earnedFitPoints = 0;

        this.currentHoverSoundRate = 0;

        common.tracksManager.getStatusEffectsManager().clearEffects(this);
    }

    private updateSplineTime() {
        const spline = common.tracksManager.getCurrentTrack()!.getSpline()!;
        const objectPosition = this.object.getPositionWorld(this.headPosition);
        this.currentSplineTime = spline.getClosestTimeAroundTime(objectPosition, this.currentSplineTime, 0.01);
    }

    private static readonly _computeTagTransformQuatSV =
        {
            tagPositionOffset: vec3_create(),
            tagPosition: vec3_create()
        };
    private _computeTagTransformQuat(baseTagTransformQuat: Quaternion2, outTagTransformQuat: Quaternion2 = quat2_create()) {
        const tagPositionOffset = HoverboardComponent._computeTagTransformQuatSV.tagPositionOffset;
        const tagPosition = HoverboardComponent._computeTagTransformQuatSV.tagPosition;
        this.computeTagPositionOffset(true, tagPositionOffset);
        tagPositionOffset.vec3_convertPositionToWorldQuat(baseTagTransformQuat, tagPosition);

        outTagTransformQuat.quat2_copy(baseTagTransformQuat);
        outTagTransformQuat.quat2_setPosition(tagPosition);
        return outTagTransformQuat;
    }

    static readonly _tagDebugUpdateSV =
        {
            baseTagTransformQuat: quat2_create(),
            tagTransformQuat: quat2_create()
        };
    private _tagDebugUpdate(dt: number) {
        if (Globals.isDebugEnabled() && HoverboardDebugs.showTagCatchVolume) {
            const baseTagTransformQuat = HoverboardComponent._tagDebugUpdateSV.baseTagTransformQuat;
            const tagTransformQuat = HoverboardComponent._tagDebugUpdateSV.tagTransformQuat;

            const currentTagTransform = this._computeTagTransformQuat(this.object.pp_getTransformQuat(baseTagTransformQuat), tagTransformQuat);
            const hoverboardNetworking = common.hoverboardNetworking;
            this._debugTagTransform(currentTagTransform, hoverboardNetworking.localPlayer!.tagged);

            for (const otherPlayer of hoverboardNetworking.otherPlayers!.entries()) {
                const otherNetworkPlayer = hoverboardNetworking.otherPlayerObjects!.get(otherPlayer[0])!.pp_getComponent(NetworkPlayerComponent)!;
                const otherTagTransform = this._computeTagTransformQuat(otherNetworkPlayer.hoverboard!.pp_getTransformQuat(baseTagTransformQuat), tagTransformQuat);

                this._debugTagTransform(otherTagTransform, otherPlayer[1].tagged);
            }
        }
    }

    static readonly _debugTagTransformSV =
        {
            visualTorusParams: null as (VisualTorusParams | null),

            tagRight: vec3_create(),
            tagUp: vec3_create(),

            resultTransformQuat: quat2_create()
        };
    private _debugTagTransform(tagTransformQuat: Quaternion2, tagged: boolean) {
        if (HoverboardComponent._debugTagTransformSV.visualTorusParams == null) {
            HoverboardComponent._debugTagTransformSV.visualTorusParams = new VisualTorusParams();
        }

        const circularMap = common.circularMap as any;
        const color = tagged ? circularMap.chaserIndicatorColor : circularMap.evaderIndicatorColor;

        const visualTorusParams = HoverboardComponent._debugTagTransformSV.visualTorusParams;
        visualTorusParams.myTransform.mat4_fromQuat(tagTransformQuat);
        visualTorusParams.myRadius = this.tagCatchDistance;
        visualTorusParams.mySegmentThickness = 0.02;

        visualTorusParams.myColor = color;

        Globals.getDebugVisualManager()!.draw(visualTorusParams);

        const tagRight = HoverboardComponent._debugTagTransformSV.tagRight;
        const tagUp = HoverboardComponent._debugTagTransformSV.tagUp;
        const resultTransformQuat = HoverboardComponent._debugTagTransformSV.resultTransformQuat;
        tagTransformQuat.quat2_rotateAxis(90, tagTransformQuat.quat2_getRight(tagRight), resultTransformQuat)
            .quat2_rotateAxis(45, tagTransformQuat.quat2_getUp(tagUp), resultTransformQuat).quat2_toMatrix(visualTorusParams.myTransform);
        Globals.getDebugVisualManager()!.draw(visualTorusParams);


        tagTransformQuat.quat2_rotateAxis(90, tagRight, resultTransformQuat)
            .quat2_rotateAxis(-45, tagUp, resultTransformQuat).quat2_toMatrix(visualTorusParams.myTransform);
        Globals.getDebugVisualManager()!.draw(visualTorusParams);
    }

    private static readonly _squatHorizontalBasedMovementSV =
        {
            leftControllerPosition: vec3_create(),
            rightControllerPosition: vec3_create(),
            movement: vec3_create(),
            prevGroundInfo: new CharacterCollisionSurfaceInfo()
        };
    private _squatHorizontalBasedMovement(dt: number) {
        this.headPosition = Globals.getPlayerObjects()!.myHead!.pp_getPositionLocal(this.headPosition);

        // Head Y Difference

        let headDifference = this.headPositionPrev[1] - this.headPosition[1];

        this._updateSquatsAmount();

        if (Globals.isDebugEnabled()) {
            if (Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.SELECT).isPressed()) {
                headDifference = 0.1;
            } else if (HoverboardDebugs.autoRace) {
                headDifference = 0.1;
            }
        }

        // Hand Y Difference

        const leftControllerPosition = HoverboardComponent._squatHorizontalBasedMovementSV.leftControllerPosition;
        const rightControllerPosition = HoverboardComponent._squatHorizontalBasedMovementSV.rightControllerPosition;
        Globals.getPlayerObjects()!.myHandLeft!.pp_getPositionLocal(leftControllerPosition);
        Globals.getPlayerObjects()!.myHandRight!.pp_getPositionLocal(rightControllerPosition);

        const leftHandY = leftControllerPosition[1];
        const rightHandY = rightControllerPosition[1];

        if (this.leftHandPrevY === -1) {
            this.leftHandPrevY = leftHandY;
        }

        if (this.rightHandPrevY === -1) {
            this.rightHandPrevY = rightHandY;
        }

        const leftHandYDifference = this.leftHandPrevY - leftHandY;
        const rightHandYDifference = this.rightHandPrevY - rightHandY;

        let averageHandYDifference = (Math.abs(rightHandYDifference) + Math.abs(leftHandYDifference)) / 2;

        const handYDifferenceDeadzone = 0.01;
        if (averageHandYDifference <= handYDifferenceDeadzone) {
            averageHandYDifference = 0;
        }

        if (Globals.isDebugEnabled()) {
            if (Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.SQUEEZE).isPressStart()) {
                averageHandYDifference = 10;
            }
        }

        // Compute Horizontal Speed

        let statusEffectMaxSpeedMultiplier = 1;
        let statusEffectSpeedGainMultiplier = 1;
        let statusEffectSpeedBrakingMultiplier = 1;

        const glueStatusEffects = common.tracksManager.getStatusEffectsManager().getStatusEffects(this, StatusEffectType.Glue) as HoverboardGlueStatusEffect[];
        for (const glueStatusEffect of glueStatusEffects) {
            const glueParams = glueStatusEffect.getParams();
            statusEffectMaxSpeedMultiplier *= glueParams.maxSpeedMultiplier;
            statusEffectSpeedGainMultiplier *= glueParams.speedGainMultiplier;
            statusEffectSpeedBrakingMultiplier *= glueParams.speedBrakingMultiplier;
        }

        let newCurrentSpeed = this.currentSpeed;

        const currentMaxSpeed = this.maxSpeed * statusEffectMaxSpeedMultiplier;

        newCurrentSpeed -= Math.min(newCurrentSpeed, this.maxSpeed) * this.speedBraking * statusEffectSpeedBrakingMultiplier * dt;
        if (newCurrentSpeed < currentMaxSpeed) {
            newCurrentSpeed += ((this.speedSquatGain * Math.abs(headDifference)) + (this.speedHandGain * averageHandYDifference)) * this.speedGainMultiplier * statusEffectSpeedGainMultiplier;
            newCurrentSpeed = Math.min(newCurrentSpeed, currentMaxSpeed);
        }

        if (this.currentSpeed <= currentMaxSpeed && newCurrentSpeed > currentMaxSpeed) {
            this.currentSpeed = currentMaxSpeed;
        } else {
            this.currentSpeed = newCurrentSpeed;
        }

        this.currentSpeed = Math.max(this.currentSpeed, 0);

        // FitnessPoints calculation
        const speedFraction = this.currentSpeed / this.maxSpeed;
        this.updateFitPoints(dt, speedFraction);

        if (!this.hoverboardSpeedNormalEventSent) {
            if (speedFraction > 0.3) {
                this.goingNormalEventTimer.update(dt);
                if (this.goingNormalEventTimer.isDone()) {
                    this.hoverboardSpeedNormalEventSent = true;
                    AnalyticsUtils.sendEventOnce("hoverboard_speed_normal");
                }
            } else {
                this.goingNormalEventTimer.start();
            }
        }

        if (!this.hoverboardSpeedFastEventSent) {
            if (speedFraction > 0.7) {
                this.goingFastEventTimer.update(dt);
                if (this.goingFastEventTimer.isDone()) {
                    this.hoverboardSpeedFastEventSent = true;
                    AnalyticsUtils.sendEventOnce("hoverboard_speed_fast");
                }
            } else {
                this.goingFastEventTimer.start();
            }
        }

        if (!this.hoverboardSpeedVeryFastEventSent) {
            if (speedFraction > 0.9) {
                this.goingVeryFastEventTimer.update(dt);
                if (this.goingVeryFastEventTimer.isDone()) {
                    this.hoverboardSpeedVeryFastEventSent = true;
                    AnalyticsUtils.sendEventOnce("hoverboard_speed_very_fast");
                }
            } else {
                this.goingVeryFastEventTimer.start();
            }
        }


        if (Math.abs(this.currentSpeed) < 0.0001) {
            this.currentSpeed = 0;
        }

        const collisionAngle = Math.abs(this.collisionResults.myWallSlideResults.mySlideMovementAngle);
        this.currentSpeedAdjusted = Math.pp_lerp(this.currentSpeed, this.currentSpeed / 4, Math.min(collisionAngle / 45, 1));
        if (Globals.isDebugEnabled() && HoverboardDebugs.turboMode) {
            this.currentSpeedAdjusted *= 5;
        }

        // Compute Vertical Speed

        let leftStickIntensity = Globals.getLeftGamepad()!.getAxesInfo(GamepadAxesID.THUMBSTICK).getAxes()[1];

        const stickIntensityDeadzone = 0.1;
        if (Math.abs(leftStickIntensity) < stickIntensityDeadzone) {
            leftStickIntensity = 0;
        }

        let verticalTargetSpeedOverStickIntensity = this.maxVerticalFlySpeed * -leftStickIntensity * statusEffectMaxSpeedMultiplier; // negative since we want to go up when pressing backward
        if (!this.flyModes.pp_hasEqual(common.gameConfig.mode) || !this.verticalMovementEnabled || (verticalTargetSpeedOverStickIntensity < 0 && this.collisionResults.myGroundInfo.myOnSurface)) {
            verticalTargetSpeedOverStickIntensity = 0;
        }

        if (verticalTargetSpeedOverStickIntensity == 0 && this.gravityAcceleration != 0 && !this.flying && !this.collisionResults.myGroundInfo.myOnSurface) {
            if (!Globals.isDebugEnabled() || !HoverboardDebugs.boardNoCollisionCheck) {
                this.currentYSpeed -= this.gravityAcceleration * dt;
            }
        } else {
            this.currentYSpeed = Math.pp_lerp(this.currentYSpeed, verticalTargetSpeedOverStickIntensity, 5 * dt);

            if (verticalTargetSpeedOverStickIntensity != 0) {
                this.flying = true;

                if (this.currentYSpeed / this.maxVerticalFlySpeed > 0.8) {
                    if (!this.hoverboardFlyEventSent) {
                        this.hoverboardFlyEventSent = true;
                        AnalyticsUtils.sendEventOnce("hoverboard_fly");
                    }
                }
            }
        }

        this.currentYSpeed = Math.pp_clamp(this.currentYSpeed, -this.maxVerticalFallSpeed, this.maxVerticalSpeed);

        if (Math.abs(this.currentYSpeed) < 0.0001) {
            this.currentYSpeed = 0;
        }

        this.currentYSpeedAdjusted = this.currentYSpeed;

        // Slowdown if game is not running
        if (common.CURRENT_STATE !== GAME_STATES.GAME) {
            if (common.CURRENT_STATE == GAME_STATES.POST_ENDGAME) {
                this.speedSlowDownMultiplier = Math.pp_lerp(this.speedSlowDownMultiplier, 0.1, 0.5 * dt);
            } else {
                this.speedSlowDownMultiplier = Math.pp_lerp(this.speedSlowDownMultiplier, 0.1, 2.5 * dt);
            }
        } else {
            this.speedSlowDownMultiplier = Math.pp_lerp(this.speedSlowDownMultiplier, 1, 5 * dt);
            if (this.speedSlowDownMultiplier >= 1 - Math.PP_EPSILON) {
                this.speedSlowDownMultiplier = 1;
            }
        }

        this.currentSpeedAdjusted *= this.speedSlowDownMultiplier;

        if (this.flying) {
            this.currentYSpeedAdjusted *= this.speedSlowDownMultiplier;
        }

        // Compute Movement
        const movement = HoverboardComponent._squatHorizontalBasedMovementSV.movement;
        movement.vec3_set(0, this.currentYSpeedAdjusted * dt, this.currentSpeedAdjusted * dt);
        Globals.getPlayerObjects()!.myPlayer!.pp_convertDirectionObjectToWorld(movement, movement);

        const prevGroundInfo = HoverboardComponent._squatHorizontalBasedMovementSV.prevGroundInfo;
        prevGroundInfo.copy(this.collisionResults.myGroundInfo);

        this._move(movement, dt);

        this._updateVerticalBoost(dt, prevGroundInfo);

        // Prev Values Update

        this.headPositionPrev.vec3_copy(this.headPosition);

        this.leftHandPrevY = leftHandY;
        this.rightHandPrevY = rightHandY;
    }

    private static readonly _headDirectionBasedRotationSV =
        {
            flatHeadForward: vec3_create(),
            flatBoardForward: vec3_create()
        };
    private _headDirectionBasedRotation(dt: number) {
        const flatHeadForward = HoverboardComponent._headDirectionBasedRotationSV.flatHeadForward;
        Globals.getPlayerObjects()!.myHead!.pp_getForward(flatHeadForward);
        flatHeadForward.vec3_removeComponentAlongAxis(GameGlobals.up, flatHeadForward);

        const flatBoardForward = HoverboardComponent._headDirectionBasedRotationSV.flatBoardForward;
        Globals.getPlayerObjects()!.myPlayer!.pp_getForward(flatBoardForward);
        flatBoardForward.vec3_removeComponentAlongAxis(GameGlobals.up, flatBoardForward);
        let angleHeadToBoard = flatHeadForward.vec3_angleSigned(flatBoardForward, GameGlobals.up);

        if (Globals.isDebugEnabled()) {
            const xAxisValue = Globals.getLeftGamepad()!.getAxesInfo(GamepadAxesID.THUMBSTICK).getAxes()[0];
            if (xAxisValue > 0.1) {
                angleHeadToBoard = 20;
            } else if (xAxisValue < -0.1) {
                angleHeadToBoard = -20;
            }
        }

        if (!this.adjustingForward || Math.pp_sign(angleHeadToBoard) == -this.adjustingForwardSign) {
            let adjustedAngleHeadToBoard = -Math.pp_clamp(angleHeadToBoard, -this.startHeadAngleToSlowTurn, this.startHeadAngleToSlowTurn);

            adjustedAngleHeadToBoard = Math.pp_sign(adjustedAngleHeadToBoard) *
                Math.pp_mapToRange(Math.abs(adjustedAngleHeadToBoard), this.startHeadAngleToTurn, this.startHeadAngleToSlowTurn, 0, this.startHeadAngleToSlowTurn);

            if (Globals.isDebugEnabled() && (HoverboardDebugs.unlimitedHoverboardTurn ||
                (!XRUtils.isSessionActive() && Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.SELECT).isPressed()))) {
                adjustedAngleHeadToBoard = -angleHeadToBoard;
            }

            let targetTurnSpeed = adjustedAngleHeadToBoard * this.turnSpeedMultiplier;
            const maxSpeedPercentageForTurnSlowDown = 0.25;
            targetTurnSpeed = Math.pp_lerp(targetTurnSpeed * this.turnSpeedSlowDownMultiplier, targetTurnSpeed, Math.abs(this.currentSpeedAdjusted / (this.maxSpeed * maxSpeedPercentageForTurnSlowDown)));
            let targetSpeedDamping = Math.pp_mapToRange(Math.abs(angleHeadToBoard), this.startHeadAngleToSlowTurn, this.endHeadAngleToSlowTurn, 0, 1);

            if (Globals.isDebugEnabled() && (HoverboardDebugs.unlimitedHoverboardTurn ||
                (!XRUtils.isSessionActive() && Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.SELECT).isPressed()))) {
                targetSpeedDamping = 0;
            }

            targetTurnSpeed = Math.pp_lerp(targetTurnSpeed, 0, targetSpeedDamping);

            this.currentTurnSpeed = Math.pp_lerp(this.currentTurnSpeed, targetTurnSpeed, Math.min((1 / this.turnSpeedEasing), Number.MAX_VALUE) * dt);
            this.currentTurnSpeed = Math.pp_clamp(this.currentTurnSpeed, -this.maxTurnSpeed, this.maxTurnSpeed);

            if (Math.abs(this.currentTurnSpeed) < 0.0001) {
                this.currentTurnSpeed = 0;
            }

            this.currentTurnSpeedAdjusted = this.currentTurnSpeed;

            if (!Globals.isDebugEnabled() || !HoverboardDebugs.boardMovementDisabled) {
                Globals.getPlayerObjects()!.myPlayer!.pp_rotateAxisObject(this.currentTurnSpeedAdjusted * dt, GameGlobals.up);
            }

            Globals.getPlayerObjects()!.myPlayer!.pp_getForward(flatBoardForward);
            flatBoardForward.vec3_removeComponentAlongAxis(GameGlobals.up, flatBoardForward);
        } else {
            this.currentTurnSpeedAdjusted = this.currentTurnSpeed;
        }
    }

    private static readonly _snapRotateUpdateSV =
        {
            headRotation: quat_create()
        };
    private _snapRotateUpdate(dt: number) {
        const maxSpeedToSnapTurn = 2.5;
        const maxSpeedToSnapTurnWhenPaused = 1;

        if ((this.actualCurrentSpeed < maxSpeedToSnapTurn && common.CURRENT_STATE == GAME_STATES.GAME) ||
            (this.actualCurrentSpeed < maxSpeedToSnapTurnWhenPaused && common.CURRENT_STATE !== GAME_STATES.GAME)) {
            const headRotation = HoverboardComponent._snapRotateUpdateSV.headRotation;
            headRotation.quat_identity();

            const axes = Globals.getRightGamepad()!.getAxesInfo(GamepadAxesID.THUMBSTICK).getAxes();

            const snapTurnResetThreshold = 0.4;
            const snapTurnActivateThreshold = 0.5;
            const snapTurnAngle = 30;
            if (!this._snapCharge) {
                if (Math.abs(axes.vec2_length()) < snapTurnResetThreshold) {
                    this._snapCharge = true;
                }
            } else {
                if (Math.abs(axes[0]) > snapTurnActivateThreshold) {
                    const angleToRotate = -Math.pp_sign(axes[0]) * snapTurnAngle;
                    headRotation.quat_fromAxis(angleToRotate, GameGlobals.up);

                    this._snapCharge = false;
                }
            }

            if (headRotation.quat_getAngle() > Math.PP_EPSILON_DEGREES) {
                Globals.getPlayerObjects()!.myPlayer!.pp_rotateQuat(headRotation);
            }
        }
    }

    private static readonly _adjustForwardToMovementDirectionSV =
        {
            hoverboardFlatForward: vec3_create()
        };
    private _adjustForwardToMovementDirection(dt: number) {
        this.adjustingForwardKeepAdjustingTimer.update(dt);

        const hoverboardFlatForward = HoverboardComponent._adjustForwardToMovementDirectionSV.hoverboardFlatForward;
        Globals.getPlayerObjects()!.myPlayer!.pp_getForward(hoverboardFlatForward).vec3_removeComponentAlongAxis(GameGlobals.up, hoverboardFlatForward); // the hoverboard is rotated 180 degrees, sadly
        const angleWithMovementDirection = hoverboardFlatForward.vec3_angleSigned(this.movementFlatDirection, GameGlobals.up);

        this.adjustingForward = false;
        this.adjustingForwardSign = Math.pp_sign(angleWithMovementDirection);

        if (Math.abs(angleWithMovementDirection) > 0.1 || this.adjustingForwardLastSign != 0) {
            if (Math.abs(angleWithMovementDirection) > 0.1) {
                this.adjustingForwardLastSign = this.adjustingForwardSign;
                this.adjustingForwardKeepAdjustingTimer.start();
            }

            this.adjustingForward = true;

            this.currentTurnSpeed = Math.pp_lerp(this.currentTurnSpeed, this.adjustingForwardLastSign * this.autoAdjustForwardTurnSpeed, Math.min((1 / this.autoAdjustForwardTurnSpeedEasing), Number.MAX_VALUE) * dt);
            this.currentTurnSpeed = Math.pp_clamp(this.currentTurnSpeed, -this.maxTurnSpeed, this.maxTurnSpeed);
            const angleToRotate = this.currentTurnSpeed * dt;

            Globals.getPlayerObjects()!.myPlayer!.pp_rotateAxisObject(angleToRotate, GameGlobals.up);
        } else {
            Globals.getPlayerObjects()!.myPlayer!.pp_rotateAxisObject(angleWithMovementDirection, GameGlobals.up);
        }

        if (this.adjustingForwardKeepAdjustingTimer.isDone()) {
            this.adjustingForwardLastSign = 0;
        }
    }

    private _updateVerticalBoost(dt: number, prevGroundInfo: CharacterCollisionSurfaceInfo) {
        if (Globals.isDebugEnabled() && HoverboardDebugs.turboMode) {
            return;
        }

        if (this.currentYSpeed <= 0) {
            if (this.verticalSpeedJumpToAdd > 0) {
                let verticalSpeedRampBoost = this.verticalSpeedJumpToAdd;

                if (this.verticalSpeedJumpIsRamp) {
                    this.currentSpeed += this.horizontalSpeedRampBoost;
                    verticalSpeedRampBoost *= this.verticalSpeedRampBoostMultiplier;

                    const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

                    const statusEffectParams = new RampStatusEffectParams();
                    const statusEffect = new RampStatusEffect(this, statusEffectParams);

                    statusEffectsManager.addStatusEffect(statusEffect);
                } else if (this.verticalSpeedJumpBoost) {
                    verticalSpeedRampBoost *= this.verticalSpeedEnviromentalBoostMultiplier;
                }

                const minBoost = 1;
                this.currentYSpeed = Math.max(this.currentYSpeed, verticalSpeedRampBoost, minBoost);

                this.verticalSpeedJumpToAdd = 0;
                this.verticalSpeedJumpBoost = false;
                this.verticalSpeedJumpIsRamp = false;
            } else {
                const minAngleDifference = 3;
                const minAngleDifferenceToBoost = 7.5;
                const minSpeedToJump = 10;
                const minVerticalSpeedToJump = 2;

                const prevAngle = prevGroundInfo.myOnSurface ? prevGroundInfo.mySurfaceAngle : 0;
                const currentAngle = this.collisionResults.myGroundInfo.myOnSurface ? this.collisionResults.myGroundInfo.mySurfaceAngle : 0;
                const prevPerceivedAngle = prevGroundInfo.myOnSurface ? prevGroundInfo.mySurfacePerceivedAngle : 0;
                const currentPerceivedAngle = this.collisionResults.myGroundInfo.myOnSurface ? this.collisionResults.myGroundInfo.mySurfacePerceivedAngle : 0;
                const angleDifference = prevAngle - currentAngle;
                const perceivedAngleDifference = prevPerceivedAngle - currentPerceivedAngle;

                const isOnRamp = prevGroundInfo.myOnSurface && (prevGroundInfo.mySurfaceReferenceCollisionHit.myObject!.pp_getComponentSelf(PhysXComponent)!.groupsMask & this.rampLayerFlags.getMask()) != 0;
                if (!this.flying && prevGroundInfo.myOnSurface && (angleDifference > minAngleDifference && perceivedAngleDifference > minAngleDifference || isOnRamp)) {
                    const verticalSpeed = this.collisionResults.myMovementResults.myFinalMovement[1] / dt;
                    const notOnSurfaceAnymore = prevGroundInfo.myOnSurface && !this.collisionResults.myGroundInfo.myOnSurface;

                    if (this.currentSpeed > minSpeedToJump && ((isOnRamp && notOnSurfaceAnymore) || (!isOnRamp && verticalSpeed > minVerticalSpeedToJump))) {
                        if (notOnSurfaceAnymore && angleDifference > minAngleDifferenceToBoost && perceivedAngleDifference > minAngleDifferenceToBoost) {
                            this.verticalSpeedJumpBoost = true;
                        }

                        const angleFactor = MathUtils.mapToRange(prevAngle, 0, this.verticalSpeedJumpMaxAngle, 0, 1);
                        const speedFactor = MathUtils.mapToRange(this.currentSpeed, 0, this.maxSpeed, 0, 1);

                        this.verticalSpeedJumpToAdd = this.verticalSpeedJumpMaxVerticalSpeedJumpToAdd * angleFactor * speedFactor;

                        this.verticalSpeedJumpIsRamp = isOnRamp;
                    }
                }
            }
        }
    }

    private _updateStartTransform(spawnPositionIndex: number) {
        const spawnPositionProvider = common.tracksManager.getCurrentTrack()!.getSpawnPositionProvider();

        this.startTransformQuat.quat2_copy(spawnPositionProvider.getSpawnPosition(spawnPositionIndex));
        this.spawnPosition = spawnPositionIndex;

        const activeTrack = common.tracksManager.getCurrentTrack();
        if (activeTrack != null && activeTrack.hasSpline()) {
            this.currentSplineTime = activeTrack.getSpawnPositionProvider().getSpawnPositionSplineTime();
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled && spawnPositionProvider.getDebugSpawnPosition() != null) {
            this.startTransformQuat.quat2_copy(spawnPositionProvider.getDebugSpawnPosition()!);

            if (activeTrack != null && activeTrack.hasSpline()) {
                this.currentSplineTime = activeTrack.getSpline()!.getClosestTime(spawnPositionProvider.getDebugSpawnPosition()!.quat2_getPosition());
            }
        }
    }

    private _putStartMenuInFrontOfHoverboard() {
        const menu = common.menu as any;

        let menuStartPosition = Globals.getPlayerObjects()!.myPlayer!.pp_getPosition();
        menuStartPosition = menuStartPosition.vec3_add(Globals.getPlayerObjects()!.myPlayer!.pp_getForward().vec3_removeComponentAlongAxis(GameGlobals.up).
            vec3_scale(menu.startMainPositionLocal.vec3_valueAlongAxis(GameGlobals.forward)), menuStartPosition).
            vec3_add(GameGlobals.up.vec3_scale(menu.startMainPositionLocal.vec3_valueAlongAxis(GameGlobals.up)), menuStartPosition);
        menu.main.pp_setPosition(menuStartPosition);

        let differenceFlat = Globals.getPlayerObjects()!.myPlayer!.pp_getPosition().vec3_sub(menu.main.pp_getPosition()).vec3_removeComponentAlongAxis(GameGlobals.up);
        menu.main.pp_setUp(GameGlobals.up, differenceFlat.vec3_normalize());

        const countdown = common.countdown;
        if (countdown.isUsingFixedPosition()) {
            countdown.resetToFixedPosition();
        } else {
            let countdownPosition = Globals.getPlayerObjects()!.myPlayer!.pp_getPosition();
            countdownPosition = countdownPosition.vec3_add(Globals.getPlayerObjects()!.myPlayer!.pp_getForward().vec3_removeComponentAlongAxis(GameGlobals.up).
                vec3_scale(countdown.startPositionLocal!.vec3_valueAlongAxis(GameGlobals.forward)), menuStartPosition).
                vec3_add(GameGlobals.up.vec3_scale(countdown.startPositionLocal!.vec3_valueAlongAxis(GameGlobals.up) + this.verticalOffsetFromGround), menuStartPosition);
            countdown.object.pp_setPosition(countdownPosition);

            differenceFlat = Globals.getPlayerObjects()!.myPlayer!.pp_getPosition().vec3_sub(countdown.object.pp_getPosition()).vec3_removeComponentAlongAxis(GameGlobals.up);
            countdown.object.pp_setUp(GameGlobals.up, differenceFlat.vec3_normalize());
        }
    }

    private static readonly _updateSquatsAmountSV =
        {
            headPosition: vec3_create(),
            playerPosition: vec3_create()
        };
    private _updateSquatsAmount() {
        const headPosition = HoverboardComponent._updateSquatsAmountSV.headPosition;
        Globals.getPlayerObjects()!.myHead!.pp_getPosition(headPosition);
        const playerPosition = HoverboardComponent._updateSquatsAmountSV.playerPosition;
        Globals.getPlayerObjects()!.myPlayer!.pp_getPosition(playerPosition);

        const currentHeight = headPosition[1] - playerPosition[1];

        if (this.squatsStartHeight == null) {
            this.squatsStartHeight = currentHeight;
        }

        if (!this.squatDownPerformed) {
            if (currentHeight - this.squatsStartHeight < -this.squatsHeightDistance) {
                this.squatDownPerformed = true;
                if (this.squatUpPerformed) {
                    this.squatUpPerformed = false;
                    this.squatsStartHeight = currentHeight;
                    this.squatsAmount++;
                    this.currentFitPointsText.text = this.squatsAmount;

                    common.playerData.dailySquats++;
                    common.playerData.weeklySquats++;
                    common.playerData.monthlySquats++;
                    common.playerData.totalSquats++;
                    common.tracksManager.getTrackStatistics().squatsAmount = this.squatsAmount;
                }
            }
        } else if (currentHeight < this.squatsStartHeight) {
            // Keep updating the current height if we keep going down, so when we go up we consider the displacement from the lowest height
            this.squatsStartHeight = currentHeight;
        }

        if (!this.squatUpPerformed) {
            if (currentHeight - this.squatsStartHeight > this.squatsHeightDistance) {
                this.squatUpPerformed = true;
                if (this.squatDownPerformed) {
                    this.squatDownPerformed = false;
                    this.squatsStartHeight = currentHeight;
                }
            }
        } else if (currentHeight > this.squatsStartHeight) {
            // Keep updating the current height if we keep going up, so when we go up we consider the displacement from the highest height
            this.squatsStartHeight = currentHeight;
        }
    }

    private static readonly _moveSV =
        {
            actualVelocity: vec3_create(),
            currentTransformQuat: quat2_create()
        };
    private _move(movement: Vector3, dt: number) {
        const collisionSystem = Globals.getCharacterCollisionSystem()!;

        const currentTransformQuat = HoverboardComponent._moveSV.currentTransformQuat;
        Globals.getPlayerObjects()!.myPlayer!.pp_getTransformQuat(currentTransformQuat);

        if (Globals.isDebugEnabled() && HoverboardDebugs.boardNoCollisionCheck) {
            if (!HoverboardDebugs.boardMovementDisabled) {
                Globals.getPlayerObjects()!.myPlayer!.pp_translateWorld(movement);
            }
        } else {
            collisionSystem.checkMovement(movement, currentTransformQuat, this.colliderSetup, this.collisionResults, this.collisionResults);
            if (!Globals.isDebugEnabled() || !HoverboardDebugs.boardMovementDisabled) {
                Globals.getPlayerObjects()!.myPlayer!.pp_translateWorld(this.collisionResults.myMovementResults.myFinalMovement);
            }
        }

        this.collisionResults.myMovementResults.myFinalMovement.vec3_removeComponentAlongAxis(GameGlobals.up, this.movementFlatDirection).vec3_normalize(this.movementFlatDirection);

        const actualVelocity = HoverboardComponent._moveSV.actualVelocity;
        this.actualCurrentSpeed = this.collisionResults.myMovementResults.myFinalMovement.vec3_removeComponentAlongAxis(GameGlobals.up, actualVelocity).vec3_length() / dt;

        if (this.collisionResults.myGroundInfo.myOnSurface && this.currentYSpeed < 0) {
            this.currentYSpeed = Math.pp_lerp(this.currentYSpeed, 0, 10 * dt);
            if (Math.abs(this.currentYSpeed) < 0.1) {
                this.currentYSpeed = 0;
            }

            this.flying = false;
        }
    }

    private static readonly _updateVisualAndSoundSV =
        {
            streamScale: vec3_create()
        };
    private _updateVisualAndSound(dt: number) {
        this.visualCurrentSpeed = Math.pp_lerp(this.visualCurrentSpeed, this.currentSpeedAdjusted, 5 * dt);
        this.visualCurrentYSpeed = Math.pp_lerp(this.visualCurrentYSpeed, this.currentYSpeedAdjusted, 5 * dt);
        this.visualTurnSpeed = Math.pp_lerp(this.visualTurnSpeed, this.currentTurnSpeedAdjusted, 5 * dt);

        const boardTiltAngle = MathUtils.lerp(0, 50, Math.abs(this.visualTurnSpeed) / this.maxTurnSpeed) * Math.pp_sign(this.visualTurnSpeed);

        this.hoverboardMeshObject.pp_resetRotationLocal();
        this.hoverboardMeshObject.pp_rotateAxisObject(-boardTiltAngle, GameGlobals.forward);

        this.feetTargetObject.pp_resetRotationLocal();
        this.feetTargetObject.pp_rotateAxisObject(-boardTiltAngle, GameGlobals.forward);

        const boardVerticalTilt = Math.pp_mapToRange(this.visualCurrentYSpeed, -25, 25, -25, 25);
        this.hoverboardMeshObject.pp_rotateAxisObject(boardVerticalTilt, GameGlobals.right);

        this.speedPercentage = Math.min(1, Math.abs(this.visualCurrentSpeed / this.maxSpeed));

        const streamScale = HoverboardComponent._updateVisualAndSoundSV.streamScale;
        streamScale.vec3_set(1, 1, this.speedPercentage);
        this.frontStreams[Handedness.LEFT]!.pp_setScaleLocal(streamScale);
        this.frontStreams[Handedness.RIGHT]!.pp_setScaleLocal(streamScale);
        this.backStreams[Handedness.LEFT]!.pp_setScaleLocal(streamScale);
        this.backStreams[Handedness.RIGHT]!.pp_setScaleLocal(streamScale);

        this.frontStreams[Handedness.LEFT]!.pp_resetRotationLocal();
        this.frontStreams[Handedness.LEFT]!.pp_rotateAxisObjectDegrees(boardVerticalTilt * 3, GameGlobals.left);
        this.frontStreams[Handedness.RIGHT]!.pp_resetRotationLocal();
        this.frontStreams[Handedness.RIGHT]!.pp_rotateAxisObjectDegrees(boardVerticalTilt * 3, GameGlobals.left);

        const targetHoverSoundRate = Math.pp_clamp(Math.abs(this.visualCurrentSpeed * dt) * this.hoverSoundPitchMultiplier, 0.0, this.hoverSoundMaxPitchGain);
        this.currentHoverSoundRate = Math.pp_lerp(this.currentHoverSoundRate, targetHoverSoundRate, 1 * dt);

        let needsAudioUpdate = false;
        if (this.audioUpdateDt < 0) {
            // first time, update
            needsAudioUpdate = true;
        } else {
            this.audioUpdateDt += dt;
            if (this.audioUpdateDt >= 0.1) {
                // update a maximum of 10 times per second to avoid lag
                needsAudioUpdate = true;
            }
        }

        if (needsAudioUpdate) {
            this.audioUpdateDt = 0;
            const hoverAudio = common.audioManager.getAudio(AudioID.HOVER)!;
            hoverAudio.setPitch(this.hoverSoundStartPitch + this.currentHoverSoundRate);
            hoverAudio.setVolume(this.hoverSoundVolume);
        }

        // This timer is needed to avoid cases where you stop colliding just for a few frames
        this.wallHitStillCollidingTimer.update(dt);
        if (this.collisionResults.myWallSlideResults.myHasSlid) {
            if (this.wallHitStillCollidingTimer.isDone()) {
                const collisionAngle = Math.abs(this.collisionResults.myWallSlideResults.mySlideMovementAngle);

                const wallHitAudio = common.audioManager.getAudio(AudioID.RACE_WALL_HIT)!;
                if (!wallHitAudio.isPlaying() && collisionAngle > this.wallCollisionSFXAngleThreshold && this.currentSpeed > this.wallCollisionSFXSpeedThreshold) {
                    wallHitAudio.play();
                    wallHitAudio.setPitch(MathUtils.random(0.9, 1.15), true);

                    this.object.pp_getPosition(this.wallAudioPosition);
                    this.wallAudioNormal.pp_copy(this.collisionResults.myWallSlideResults.myWallNormal);
                    this.wallAudioNormal.vec3_negate(this.wallAudioNormal);
                    this.wallAudioPosition.vec3_add(this.wallAudioNormal, this.wallAudioPosition);
                    wallHitAudio.setPosition(this.wallAudioPosition, true);

                    hapticFeedback(Handedness.LEFT, 0.35, 0.25);
                    hapticFeedback(Handedness.RIGHT, 0.35, 0.25);
                }
            }

            this.wallHitStillCollidingTimer.start();
        }

        this._displaySpeed(dt);

        if (common.gameConfig.mode == GameMode.Tag && common.hoverboardNetworking.room) {
            if (common.hoverboardNetworking.localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED ||
                common.hoverboardNetworking.localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_ENDED ||
                common.hoverboardNetworking.localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_ENDED_CONTINUE) {
                this.tagRingComponent.setVisible(true);
                this.tagRingComponent.setCatchDistance(this.tagCatchDistance);
                this.tagRingComponent.setTaggedState(common.hoverboardNetworking.localPlayer!.tagged);
            }
        }

        if (common.tracksManager.getStatusEffectsManager().hasStatusEffect(this, StatusEffectType.Invincibility)) {
            this.invincibilityRingComponent.setVisible(true);
        } else {
            this.invincibilityRingComponent.setVisible(false);
        }
    }

    private _updateCollider() {
        this.colliderSetup.myAdditionalParams.myPositionOffsetLocal.vec3_set(0, -this.verticalOffsetFromGround, 0);
    }

    private _setupEasyTuneVariables() {
        const easyTuneVariables = Globals.getEasyTuneVariables()!;

        easyTuneVariables.add(new EasyTuneNumber("Move To Track Percentage", 0, (newValue) => this._debugTeleportTrackPercentage(newValue / 100), true, 2, 25, 0, 100).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneNumber("Max Speed", this.maxSpeed, (newValue) => this.maxSpeed = newValue, false, 1, 50, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Speed Braking", this.speedBraking, (newValue) => this.speedBraking = newValue, false, 3, 0.1, 0, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Speed Boost On Start", this.speedBoostOnStart, (newValue) => this.speedBoostOnStart = newValue, false, 2, 20, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Speed Gain Multiplier", this.speedGainMultiplier, (newValue) => this.speedGainMultiplier = newValue, false, 2, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Speed Squat Gain", this.speedSquatGain, (newValue) => this.speedSquatGain = newValue, false, 1, 20, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Speed Hand Gain", this.speedHandGain, (newValue) => this.speedHandGain = newValue, false, 1, 20, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Squat Height", this.squatsHeightDistance, (newValue) => this.squatsHeightDistance = newValue, true, 3, 0.1, 0, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportEnabled));

        easyTuneVariables.add(new EasyTuneBool("Vertical Movement Enabled", this.verticalMovementEnabled, (newValue) => this.verticalMovementEnabled = newValue, false).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Max Vertical Fly Speed", this.maxVerticalFlySpeed, (newValue) => this.maxVerticalFlySpeed = newValue, false, 2, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Max Vertical Fall Speed", this.maxVerticalFallSpeed, (newValue) => this.maxVerticalFallSpeed = newValue, false, 2, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Gravity Acceleration", this.gravityAcceleration, (newValue) => this.gravityAcceleration = newValue, false, 2, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Vertical Offset From Ground", this.verticalOffsetFromGround, (newValue) => this.verticalOffsetFromGround = newValue, false, 2, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneNumber("Horizontal Speed Ramp Boost", this.horizontalSpeedRampBoost, (newValue) => this.horizontalSpeedRampBoost = newValue, false, 0, 100, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Vertical Speed Ramp Boost Multiplier", this.verticalSpeedRampBoostMultiplier, (newValue) => this.verticalSpeedRampBoostMultiplier = newValue, false, 3, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Vertical Speed Env Boost Multiplier", this.verticalSpeedEnviromentalBoostMultiplier, (newValue) => this.verticalSpeedEnviromentalBoostMultiplier = newValue, false, 3, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneNumber("Start Head Angle To Turn", this.startHeadAngleToTurn, (newValue) => this.startHeadAngleToTurn = newValue, false, 1, 50, 0, 180).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Start Head Angle To Slow Turn", this.startHeadAngleToSlowTurn, (newValue) => this.startHeadAngleToSlowTurn = newValue, false, 1, 50, 0, 180).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("End Head Angle To Slow Turn", this.endHeadAngleToSlowTurn, (newValue) => this.endHeadAngleToSlowTurn = newValue, false, 1, 50, 0, 180).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Turn Speed Multiplier", this.turnSpeedMultiplier, (newValue) => this.turnSpeedMultiplier = newValue, false, 2, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Turn Speed Easing", this.turnSpeedEasing, (newValue) => this.turnSpeedEasing = newValue, false, 2, 0.1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Turn Speed Slow Down Multiplier", this.turnSpeedSlowDownMultiplier, (newValue) => this.turnSpeedSlowDownMultiplier = newValue, false, 2, 0.1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneNumber("Tag Catch Distance", this.tagCatchDistance, (newValue) => this.tagCatchDistance = newValue, false, 1, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneBool("Auto Adjust Forward When Sliding Along Wall", this.autoAdjustForwardWhenSlidingAlongWall, (newValue) => this.autoAdjustForwardWhenSlidingAlongWall = newValue, false).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Auto Adjust Forward Turn Speed", this.autoAdjustForwardTurnSpeed, (newValue) => this.autoAdjustForwardTurnSpeed = newValue, false, 2, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Auto Adjust Forward Turn Speed Easing", this.autoAdjustForwardTurnSpeedEasing, (newValue) => this.autoAdjustForwardTurnSpeedEasing = newValue, false, 2, 10, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneNumber("Hover Sound Pitch Multiplier", this.hoverSoundPitchMultiplier, (newValue) => this.hoverSoundPitchMultiplier = newValue, false, 2, 1, 0).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Hover Sound Max Pitch", this.hoverSoundMaxPitchGain, (newValue) => this.hoverSoundMaxPitchGain = newValue, false, 2, 1, 0, 1.5).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
        easyTuneVariables.add(new EasyTuneNumber("Hover Sound Volume", this.hoverSoundVolume, (newValue) => this.hoverSoundVolume = newValue, false, 2, 1, 0, 1).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));

        easyTuneVariables.add(new EasyTuneBool("Snap On Ground Enabled", this.snapOnGroundEnabled, (newValue) => {
            this.snapOnGroundEnabled = newValue;

            if (this.snapOnGroundEnabled) {
                this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = SURFACE_SNAP_MAX_DISTANCE;
            } else {
                this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = 0;
            }
        }, false).setEasyTuneVariableExtraParams(EasyTuneExtraParamsSet.importExportDisabled));
    }

    private _createColliderSetup() {
        const simplifiedParams = new CharacterColliderSetupSimplifiedCreationParams();

        simplifiedParams.myHeight = 3.5 + this.verticalOffsetFromGround;
        simplifiedParams.myRadius = 3;

        simplifiedParams.myAccuracyLevel = CharacterColliderSetupSimplifiedCreationAccuracyLevel.HIGH;

        simplifiedParams.myIsPlayer = true;

        const canFly = this.flyModes.pp_hasEqual(common.gameConfig.mode);
        simplifiedParams.myCheckOnlyFeet = !canFly;
        simplifiedParams.myCheckCeilings = canFly;

        simplifiedParams.myShouldSlideAlongWall = true;

        simplifiedParams.myCollectGroundInfo = true;
        simplifiedParams.myMaxWalkableGroundAngle = 30;
        simplifiedParams.myMaxDistanceToSnapOnGround = this.snapOnGroundEnabled ? SURFACE_SNAP_MAX_DISTANCE : 0;
        simplifiedParams.myMaxDistanceToPopOutGround = 0.2;

        simplifiedParams.myMaxWalkableGroundStepHeight = 0.25;
        simplifiedParams.myShouldNotFallFromEdges = false;

        simplifiedParams.myHorizontalCheckBlockLayerFlags.setFlagActive(3, true);
        const physXComponents = Globals.getPlayerObjects()!.myPlayer!.pp_getComponents(PhysXComponent);
        for (const physXComponent of physXComponents) {
            simplifiedParams.myHorizontalCheckObjectsToIgnore.pp_pushUnique(physXComponent.object, (first, second) => first.pp_equals(second));
        }
        simplifiedParams.myVerticalCheckBlockLayerFlags.copy(simplifiedParams.myHorizontalCheckBlockLayerFlags);
        simplifiedParams.myVerticalCheckObjectsToIgnore.pp_copy(simplifiedParams.myHorizontalCheckObjectsToIgnore);

        const debugEnabled = false;
        simplifiedParams.myHorizontalCheckDebugEnabled = debugEnabled && Globals.isDebugEnabled();
        simplifiedParams.myVerticalCheckDebugEnabled = debugEnabled && Globals.isDebugEnabled();

        const colliderSetup = CharacterColliderSetupUtils.createSimplified(simplifiedParams);

        colliderSetup.mySplitMovementParams.mySplitMovementEnabled = true;

        colliderSetup.mySplitMovementParams.mySplitMovementMaxSteps = 5;
        colliderSetup.mySplitMovementParams.mySplitMovementMinStepLength = simplifiedParams.myRadius / 3;

        colliderSetup.myGroundParams.mySurfacePopOutMaxDistance = Math.max(1, colliderSetup.myHorizontalCheckParams.myHorizontalCheckFeetDistanceToIgnore);

        colliderSetup.myAdditionalParams.myPositionOffsetLocal.vec3_set(0, -this.verticalOffsetFromGround, 0);

        return colliderSetup;
    }

    private _displaySpeed(dt: number, instant: boolean = false) {
        this.visualCurrentSpeedUpdateTimer.update(dt);
        if (this.visualCurrentSpeedUpdateTimer.isDone() || instant) {
            this.visualCurrentSpeedUpdateTimer.start();

            this.visualCurrentSpeedConverted = this.visualCurrentSpeed * 2.237;
            const speed = Math.abs(this.visualCurrentSpeedConverted);
            this.speedText.text = speed.toFixed(0);

            const trackStatistics = common.tracksManager.getTrackStatistics();
            const roundedSpeed = Math.floor(speed);
            trackStatistics.maxSpeed = Math.max(trackStatistics.maxSpeed, roundedSpeed);
        }
    }

    private _setupStreams() {
        const streamScale = vec3_create(1, 1, 0);

        this.backStreams[Handedness.LEFT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_BL")!;
        this.backStreams[Handedness.LEFT].pp_scaleObject(streamScale);

        this.backStreams[Handedness.RIGHT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_BR")!;
        this.backStreams[Handedness.RIGHT].pp_scaleObject(streamScale);

        this.frontStreams[Handedness.LEFT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_FL")!;
        this.frontStreams[Handedness.LEFT].pp_scaleObject(streamScale);

        this.frontStreams[Handedness.RIGHT] = this.hoverboardMeshObject.pp_getObjectByName("Stream_FR")!;
        this.frontStreams[Handedness.RIGHT].pp_scaleObject(streamScale);
    }

    private _syncHeadForwardToBoard() {
        this.referenceSpaceSyncPivot.pp_resetRotationLocal();

        const flatBoardForward = Globals.getPlayerObjects()!.myPlayer!.pp_getForward().vec3_removeComponentAlongAxis(GameGlobals.up);
        const flatHeadForward = Globals.getPlayerObjects()!.myHead!.pp_getForward().vec3_removeComponentAlongAxis(GameGlobals.up);

        const angleToStart = flatHeadForward.vec3_angleSigned(flatBoardForward, GameGlobals.up);
        if (Math.abs(angleToStart) > 0.1) {
            this.referenceSpaceSyncPivot.pp_rotateAxis(angleToStart, GameGlobals.up);
        }

        Globals.getPlayerObjects()!.myCameraNonXR!.pp_setUp(GameGlobals.up);

        this.avatarObject.pp_setUp(GameGlobals.up, flatBoardForward);
    }

    private _onXRSessionStart() {
        if (this.hoverboardReady) {
            Globals.getPlayerObjects()!.myReferenceSpace!.pp_resetPositionLocal();
        }

        const referenceSpace = XRUtils.getReferenceSpace()!;
        if (referenceSpace.addEventListener != null) {
            referenceSpace.addEventListener("reset", this._onViewReset.bind(this));
        }
    }

    private _onXRSessionEnd() { }

    private _onViewReset() {
        AnalyticsUtils.sendEventOnce("view_reset");

        if (this.hoverboardReady) {
            this.resetViewDirty = 2;
        }

        const maxSpeedToResetToSpline = 2.5;
        if (this.actualCurrentSpeed < maxSpeedToResetToSpline) {
            this.resetTransformToSpline();
        }
    }

    private _stopBoardAndSnapToGround() {
        this.currentSpeed = 0;
        this.currentSpeedAdjusted = 0;
        this.currentYSpeed = 0;
        this.currentYSpeedAdjusted = 0;
        this.currentTurnSpeed = 0;
        this.currentTurnSpeedAdjusted = 0;

        this.speedSlowDownMultiplier = 1;

        this.verticalSpeedJumpToAdd = 0;
        this.verticalSpeedJumpBoost = false;

        // Adjust position around collisions
        const surfaceSnapMaxDistanceBackup = this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance;
        this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = 10;
        this.collisionResults.myGroundInfo.myOnSurface = true;
        this._move(vec3_create(0), 0);
        this.collisionResults.reset();
        this.colliderSetup.myGroundParams.mySurfaceSnapMaxDistance = surfaceSnapMaxDistanceBackup;

        common.tracksManager.getStatusEffectsManager().clearEffects(this);
    }

    private _debugTeleportTrackPercentage(trackPercentage: number) {
        const spline = common.tracksManager.getCurrentTrack()!.getSpline();
        if (this.hoverboardStarted && spline != null) {
            const teleportPosition = spline.getPosition(trackPercentage).vec3_add(GameGlobals.up.vec3_scale(this.verticalOffsetFromGround));
            const teleportForward = spline.getForward(trackPercentage);

            this.currentSpeed = 0;
            this.currentYSpeed = 0;
            this.currentTurnSpeed = 0;

            Globals.getPlayerObjects()!.myPlayer!.pp_setPosition(teleportPosition);
            Globals.getPlayerObjects()!.myPlayer!.pp_setUp(GameGlobals.up, teleportForward);
        }
    }

    override onDestroy(): void {
        XRUtils.unregisterSessionStartEndEventListeners(this);
    }
}
