import { Button, ClickState, Label, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, Rect, filterIDFromProperties, type ClickableWidgetProperties, type LabelProperties, type WidgetAutoXML, type WidgetEvent } from "lazy-widgets";
import { Handedness } from "wle-pp";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { common } from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";

export interface HyperlinkProperties extends ClickableWidgetProperties, LabelProperties {
    disableDefaultOnClick: boolean
}

export class Hyperlink extends Button<Label> {
    static override autoXML: WidgetAutoXML = {
        name: "hyperlink",
        inputConfig: [
            {
                mode: "text",
                name: "text"
            },
            {
                mode: "value",
                validator: "string",
                name: "url"
            },
        ],
    };

    private clickSound: { play: () => void };
    private hoverSound: { play: () => void };
    private inBounds = false;
    private currentFill = "";

    constructor(text: string, readonly url: string, properties?: Readonly<HyperlinkProperties>) {
        super(
            new Label(text, filterIDFromProperties(properties)),
            {
                ...properties,
                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
            }
        );

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK)!;
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER)!;

        if (!properties?.disableDefaultOnClick) {
            this.on("click", () => { window.open(url, "_blank"); });
        }
    }

    private updateFill(): void {
        const oldFill = this.currentFill;

        switch (this.clickHelper.clickState) {
            case ClickState.Hold:
            case ClickState.Hover:
                this.currentFill = "#2571bc";
                break;
            default:
                this.currentFill = "#5badff";
                break;
        }

        // Update canvasFill property of child's theme
        if (oldFill !== this.currentFill) {
            this.markWholeAsDirty();
            this.child.bodyTextFill = this.currentFill;
        }
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor.handedness as unknown as Handedness);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor.handedness as unknown as Handedness);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor.handedness as unknown as Handedness);
            }
        }

        return captured;
    }

    override set clickable(clickable) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    set text(text) {
        this.child.text = text;
    }

    get text() {
        return this.child.text;
    }

    override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }

    override click() {
        if (this.clickSound) {
            this.clickSound.play();
        }

        super.click();
    }

    protected override activate(): void {
        super.activate();
        this.updateFill();
    }

    protected override handlePreLayoutUpdate() {
        if (this.clickHelper.clickStateChanged) {
            this.updateFill();
        }

        super.handlePreLayoutUpdate();
    }

    protected override handlePainting(dirtyRects: Rect[]): void {
        super.handlePainting(dirtyRects);

        const ctx = this.viewport.context;
        ctx.fillStyle = this.currentFill;
        ctx.fillRect(this.x, this.y + this.height - 1, this.width, 1);
    }
}
