import { EventEmitter } from "events";
import { type GameLocation } from "hoverfit-shared-netcode";
import { Observable, ObservableCallback } from "lazy-widgets";
import { common } from "src/hoverfit/common.js";
import { type PlayerData } from "src/hoverfit/data/player-data.js";
import { Gender } from "src/hoverfit/data/values/gender.js";
import { HoverboardDebugs } from "src/hoverfit/game/components/hoverboard-debugs-component.js";
import { Globals } from "wle-pp";
import { type ObservableItemIDCollection, type ObservableItemIDCollectionEventListener, ObservableItemIDCollectionEventType } from "../data-structs/observable-item-id-collection.js";
import { ObservableItemIDMap } from "../data-structs/observable-item-id-map.js";
import { ObservableOwnedItemCollection } from "../data-structs/observable-owned-item-collection.js";
import { type AssetBase, type AssetManifest, type Bundle, type HairColorVariant, type HeadwearVariant, type HoverboardVariant, type LocationVariant, type SkinVariant, type SuitVariant } from "./asset-manifest-types.js";
import { type AssetProvider, type EquippableItemCategory, ItemCategory, ItemNamespace } from "./asset-provider.js";
import { BUILT_IN_ASSET_MANIFEST } from "./built-in-asset-provider.js";
import { type DynamicManifest } from "./dynamic-manifest.js";
import { ShopItem } from "./shop-item.js";

const AVATAR_TYPES = [undefined, Gender.Female, Gender.Male] as const;

class AdjustedEquipment<K extends string> implements Observable<string> {
    private callbacks: ObservableCallback<string>[] = [];
    private _value: string = '';

    constructor(private readonly key: K, private readonly equipmentHolder: Record<K, string> & PlayerData, private readonly defaultIDGetter: () => string, private readonly inventory: ObservableItemIDCollection) {
        const update = this.update.bind(this);
        this._value = defaultIDGetter();
        this.inventory.watch(update, true);
        equipmentHolder.listen(update, key);
    }

    get value(): string {
        return this._value;
    }

    watch(callback: ObservableCallback<string>, callNow?: boolean, group?: unknown): this {
        this.callbacks.push(callback);
        if (callNow) callback(this, group);
        return this;
    }

    unwatch(callback: ObservableCallback<string>): this {
        const idx = this.callbacks.indexOf(callback);
        if (idx >= 0) this.callbacks.splice(idx);
        return this;
    }

    private notify() {
        for (const callback of this.callbacks) {
            try {
                callback(this, undefined);
            } catch (err) {
                console.error(err);
            }
        }
    }

    private update() {
        let value = this.equipmentHolder[this.key] as string;
        if (!this.inventory.has(value)) {
            if (this.inventory.has(this._value)) {
                // adjusted equipment is already a fallback item, don't replace
                return;
            }

            value = this.defaultIDGetter();
        }

        if (this._value === value) return;

        this._value = value;
        this.notify();
    }
}

export class IAPContentController extends EventEmitter {
    private waitingManifests = 0;
    readonly inventory = new ObservableOwnedItemCollection(true);
    readonly catalog = new ObservableItemIDMap<ShopItem>(true);
    private readonly assets = new Map<string, Readonly<AssetBase>>();
    private readonly assetCategories = new Map<string, ItemCategory>();
    private readonly shopProviders: AssetProvider[] = [];
    private readonly readyQueue: (() => void)[] = [];
    private readonly dynamicManifests = new Map<ItemNamespace, DynamicManifest>();
    readonly adjustedSkinColor: AdjustedEquipment<"skinColor">;
    readonly adjustedHoverboardVariant: AdjustedEquipment<"hoverboardVariant">;
    readonly adjustedSuitVariant: AdjustedEquipment<"suitVariant">;
    readonly adjustedHairColor: AdjustedEquipment<"hairColor">;
    readonly adjustedHeadwearVariantMale: AdjustedEquipment<"headwearVariantMale">;
    readonly adjustedHeadwearVariantFemale: AdjustedEquipment<"headwearVariantFemale">;
    private _acquirableAssets: Map<EquippableItemCategory, Map<Gender | undefined, string[]>> | null = null;
    private _acquirableAssetsDirty = false;

    constructor(shopProviderCtors: ReadonlyArray<new (controller: IAPContentController) => AssetProvider>) {
        super();

        for (const ctor of shopProviderCtors) {
            this.shopProviders.push(new ctor(this));
        }

        if (this.ready) this.onFinishLoadingManifests();

        const playerData = common.playerData;
        this.adjustedSkinColor = new AdjustedEquipment("skinColor", playerData, playerData.getDefaultSkinColor.bind(playerData), this.inventory);
        this.adjustedHoverboardVariant = new AdjustedEquipment("hoverboardVariant", playerData, playerData.getDefaultHoverboard.bind(playerData), this.inventory);
        this.adjustedSuitVariant = new AdjustedEquipment("suitVariant", playerData, playerData.getDefaultSuit.bind(playerData), this.inventory);
        this.adjustedHairColor = new AdjustedEquipment("hairColor", playerData, playerData.getDefaultHairColor.bind(playerData), this.inventory);
        this.adjustedHeadwearVariantMale = new AdjustedEquipment("headwearVariantMale", playerData, playerData.getDefaultHeadwearMale.bind(playerData), this.inventory);
        this.adjustedHeadwearVariantFemale = new AdjustedEquipment("headwearVariantFemale", playerData, playerData.getDefaultHeadwearFemale.bind(playerData), this.inventory);
    }

    private onFinishLoadingManifests() {
        if (!this.inventory.suppressed) return;

        // we have to wait for all inventories so that we don't unequip
        // things that we own, however, we don't care if the catalog is not
        // loaded yet. we can handle the catalog asynchronously
        const invPromises: Promise<unknown>[] = [];
        for (let i = this.shopProviders.length - 1; i >= 0; i--) {
            invPromises.push(this.shopProviders[i].fetchInventory());
        }

        Promise.allSettled(invPromises).then(() => {
            this.inventory.unsuppress();
            this.catalog.unsuppress();
        });

        for (const callback of this.readyQueue) {
            try {
                callback();
            } catch (err) {
                console.error(err);
            }
        }

        this.readyQueue.length = 0;
    }

    private finishLoadingManifest() {
        if (--this.waitingManifests === 0) this.onFinishLoadingManifests();
    }

    get ready() {
        return this.waitingManifests === 0;
    }

    waitForReady() {
        return new Promise<void>((resolve, _reject) => {
            if (this.ready) {
                resolve();
            } else {
                this.readyQueue.push(resolve);
            }
        });
    }

    private loadManifestList<T extends AssetBase>(inManifestList: { [key: string]: Readonly<T> }, namespace: ItemNamespace, category: ItemCategory) {
        for (const shortID of Object.getOwnPropertyNames(inManifestList)) {
            const id = `${namespace}:${shortID}`;
            if (this.assets.has(id)) {
                console.error(`Ignored asset with ID "${id}"; ID already exists`);
                continue;
            }

            this.assets.set(id, inManifestList[shortID]);
            this.assetCategories.set(id, category);
        }
    }

    loadManifest(manifest: AssetManifest, namespace: ItemNamespace) {
        this.loadManifestList(manifest.hoverboard, namespace, ItemCategory.Hoverboard);
        this.loadManifestList(manifest.suit, namespace, ItemCategory.Suit);
        this.loadManifestList<HeadwearVariant>(manifest.headwear, namespace, ItemCategory.Headwear);
        this.loadManifestList(manifest.hairColor, namespace, ItemCategory.HairColor);
        this.loadManifestList(manifest.skin, namespace, ItemCategory.Skin);
        this.loadManifestList(manifest.location, namespace, ItemCategory.Location);
        this.loadManifestList(manifest.bundle, namespace, ItemCategory.Bundle);
    }

    async fetchAndLoadManifest(url: string, namespace: ItemNamespace): Promise<AssetManifest> {
        this.waitingManifests++;
        let manifest: AssetManifest;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Unexpected status code ${response.status} ${response.statusText}`);
            }
            manifest = await response.json() as AssetManifest;

            this.loadManifest(manifest, namespace);
        } finally {
            this.finishLoadingManifest();
        }

        return manifest;
    }

    registerDynamicManifest(namespace: ItemNamespace, dynamicManifest: DynamicManifest) {
        if (this.dynamicManifests.has(namespace)) {
            throw new Error('DynamicManifest already registered to given namespace');
        }

        this.dynamicManifests.set(namespace, dynamicManifest);
    }

    private getDynamicAsset(id: string) {
        const colonIdx = id.indexOf(':');
        if (colonIdx < 0) return;

        const dm = this.dynamicManifests.get(id.slice(0, colonIdx) as ItemNamespace);
        if (!dm) return;

        return dm.getAssetByShortID(id.slice(colonIdx + 1));
    }

    getAsset(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Suit): SuitVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Skin): SkinVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Location): LocationVariant | undefined;
    getAsset(id: string, categoryHint: ItemCategory.Bundle): Bundle | undefined;
    getAsset(id: string, categoryHint?: ItemCategory): AssetBase | undefined;
    getAsset(id: string, categoryHint?: ItemCategory): AssetBase | undefined {
        if (categoryHint !== undefined && this.getAssetClass(id) !== categoryHint) {
            return;
        }

        const asset = this.assets.get(id);
        if (asset) return asset;

        const pair = this.getDynamicAsset(id);
        if (pair) {
            if (categoryHint !== undefined && pair[0] !== categoryHint) return;
            return pair[1];
        }

        return;
    }

    getAssetRequired(id: string, categoryHint: ItemCategory.Hoverboard): HoverboardVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Suit): SuitVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Headwear): HeadwearVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.HairColor): HairColorVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Skin): SkinVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Location): LocationVariant;
    getAssetRequired(id: string, categoryHint: ItemCategory.Bundle): Bundle;
    getAssetRequired(id: string, categoryHint?: ItemCategory): AssetBase;
    getAssetRequired(id: string, categoryHint?: ItemCategory) {
        const asset = this.getAsset(id, categoryHint);
        if (!asset) throw new Error(`No such asset with ID "${id}"`);
        return asset;
    }

    getAssetClass(id: string): ItemCategory | undefined {
        const cat = this.assetCategories.get(id);
        if (cat) return cat;
        const pair = this.getDynamicAsset(id);
        if (pair) return pair[0];
        return;
    }

    getAllIDsOfClass(category: ItemCategory): string[] {
        const ids: string[] = [];

        for (const [id, idCategory] of this.assetCategories) {
            if (idCategory === category) ids.push(id);
        }

        return ids;
    }

    get unlockAllLocations() {
        return DEV_MODE && common.gameReady && Globals.isDebugEnabled() && HoverboardDebugs.unlockAllKioskUI;
    }

    isLocationOwned(location: GameLocation) {
        if (this.unlockAllLocations) return true;

        for (const id of this.inventory.getAllIDs()) {
            if (this.assetCategories.get(id) !== ItemCategory.Location) continue;
            const asset = this.assets.get(id) as LocationVariant | undefined;
            if (asset && asset.unlocksLocation === location) return true;
        }

        return false;
    }

    private populateBuiltInAcqAssets(category: EquippableItemCategory) {
        const avatarTypeMap = new Map<Gender | undefined, string[]>();
        for (const avatarType of AVATAR_TYPES) {
            avatarTypeMap.set(avatarType, []);
        }

        // add built-ins
        const builtInCat = BUILT_IN_ASSET_MANIFEST[category];
        for (const shortID of Object.getOwnPropertyNames(builtInCat)) {
            const asset = builtInCat[shortID];
            const idList = avatarTypeMap.get((asset as { avatarType?: Gender }).avatarType)!;
            // TODO it *MIGHT* be more efficient to sort when inserting
            idList.push(`${ItemNamespace.BuiltIn}:${shortID}`);
        }

        this._acquirableAssets!.set(category, avatarTypeMap);
    }

    private readonly catalogListener: ObservableItemIDCollectionEventListener = (type, ids) => {
        if (type === ObservableItemIDCollectionEventType.Update) return;

        let changed = false;
        for (const id of ids) {
            const category = this.getAssetClass(id);
            if (!category || category === ItemCategory.Bundle || category === ItemCategory.Location) continue;
            const asset = this.getAssetRequired(id);
            const avatarTypeMap = this._acquirableAssets!.get(category)!;
            const idList = avatarTypeMap.get((asset as { avatarType?: Gender }).avatarType)!;

            const idx = idList.indexOf(id);
            if (type === ObservableItemIDCollectionEventType.Add) {
                if (idx < 0) {
                    idList.push(id);
                    changed = true;
                    this._acquirableAssetsDirty = true;
                }
            } else if (idx >= 0) {
                idList.splice(idx, 1);
                changed = true;
                // list still sorted after a deletion, no need to mark as dirty
            }
        }

        if (changed) {
            this.emit("acquirable-assets-changed");
        }
    };

    /**
     * List of alphabetically ordered assets, grouped by category, which can
     * either be purchased, or are built-in. Lazy-evaluated. Must also be
     * equippable (locations or bundles will not be included). IDs are used
     * instead of short IDs.
     */
    get acquirableAssets(): ReadonlyMap<EquippableItemCategory, ReadonlyMap<Gender | undefined, string[]>> {
        if (!this._acquirableAssets) {
            this._acquirableAssets = new Map();
            this.populateBuiltInAcqAssets(ItemCategory.Skin);
            this.populateBuiltInAcqAssets(ItemCategory.Hoverboard);
            this.populateBuiltInAcqAssets(ItemCategory.Headwear);
            this.populateBuiltInAcqAssets(ItemCategory.Suit);
            this.populateBuiltInAcqAssets(ItemCategory.HairColor);

            for (const id of this.catalog.getAllIDs()) {
                const category = this.getAssetClass(id);
                if (!category || category === ItemCategory.Bundle || category === ItemCategory.Location) continue;
                const asset = this.getAssetRequired(id);
                const avatarTypeMap = this._acquirableAssets!.get(category)!;
                const idList = avatarTypeMap.get((asset as { avatarType?: Gender }).avatarType)!;
                idList.push(id);
            }

            this._acquirableAssetsDirty = true;
            this.catalog.watch(this.catalogListener);
        }

        if (this._acquirableAssetsDirty) {
            for (const avatarTypeMap of this._acquirableAssets!.values()) {
                for (const idMap of avatarTypeMap.values()) {
                    idMap.sort();
                }
            }

            this._acquirableAssetsDirty = false;
        }

        return this._acquirableAssets;
    }

    getRandomAcquirableAsset(normalizedSeed: number, gender: Gender, category: EquippableItemCategory) {
        const avatarTypeMap = this.acquirableAssets.get(category)!;
        const genderless = avatarTypeMap.get(undefined)!;
        const gendered = avatarTypeMap.get(gender)!;
        const genderlessCount = genderless.length;
        const idx = Math.trunc(normalizedSeed * (genderlessCount + gendered.length));
        return idx < genderlessCount ? genderless[idx] : gendered[idx - genderlessCount];
    }

    dispose() {
        this._acquirableAssets = null;
        this.catalog.unwatch(this.catalogListener);

        for (const provider of this.shopProviders) {
            provider.dispose();
        }
    }
}