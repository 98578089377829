import { Component, Property } from "@wonderlandengine/api";
import { Globals, vec3_create } from "wle-pp";
import { GameGlobals } from "../../game-globals.js";

export class LookAtPlayerComponent extends Component {
    static TypeName = "look-at-player";
    static Properties = {
        keepUp: Property.bool(true)
    };

    update(dt) {
        // Implemented outside class definition
    }
}

LookAtPlayerComponent.prototype.update = function () {
    let headPosition = vec3_create();
    let currentPosition = vec3_create();
    let lookTo = vec3_create();
    return function update(dt) {
        if (Globals.getPlayerHeadManager() == null) return;

        headPosition = Globals.getPlayerHeadManager().getPositionHead(headPosition);
        currentPosition = this.object.pp_getPosition(currentPosition);

        lookTo = headPosition.vec3_sub(currentPosition, lookTo);
        lookTo.vec3_normalize(lookTo);

        this.object.pp_setUp(GameGlobals.up, lookTo);
    };
}();