// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/kiosk-upper.xml";

import { GameMode } from "hoverfit-shared-netcode";
import { MultiContainer, ObservableTransformer, Variable, Widget, XMLUIParserContext } from "lazy-widgets";
import { WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { HoverboardGameConfig } from "src/hoverfit/data/game-configuration.js";
import { type BaseLeaderboard } from "src/hoverfit/game/track/leaderboard/base-leaderboard.js";
import { LEADERBOARD_TITLES, LEADERBOARD_TYPE_MODULO, LeaderboardType } from "src/hoverfit/game/track/leaderboard/leaderboards-manager.js";
import { HeyVR } from "src/hoverfit/misc/heyvr-sdk-provider.js";
import { modulo } from "src/hoverfit/utils/math-utils.js";
import { formatPlayTime } from "src/hoverfit/utils/time-utils.js";
import { XRUtils } from "wle-pp";
import { common } from "../../../common.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { ArrowDecoratedButton } from "../../lazy-widgets/widgets/arrow-decorated-button.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { UICustomPopupHelperParams } from "../../xml-ui/ui-custom-popup-helper.js";
import { KioskBackground } from "../widgets/kiosk-background.js";
import { LeaderboardWidget as WidgetLeaderboard } from "../widgets/leaderboard-widget.js";
import { ScoreRow } from "../widgets/score-row.js";
import { Stats } from "../widgets/stats.js";

export const enum UpperUIMode {
    Leaderboard,
    CustomisationPreview,
    Stats
}

const enum LeaderboardBookPage {
    Unavailable,
    ShowScores,
}

export class KioskUpperUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "kiosk-upper-ui";

    ready: boolean = false;

    private playerName!: Variable<string>;
    private playerDataListener!: () => void;
    private lastTrackChecked!: Variable<number>;
    private modeBook!: Book;
    private widgetLeaderboard!: WidgetLeaderboard;
    private fitPoints!: Variable<number>;
    private fitabux!: Variable<number>;
    private heyVRCoins!: Variable<number>;
    private leaderboardTitleText!: Variable<string>;
    private leaderboardSubtitleText!: Variable<string>;
    private leaderboardScoreLabel!: Variable<string>;
    private leaderboardUnavailableText!: Variable<string>;
    private leaderboardPage!: Variable<LeaderboardBookPage>;
    private leaderboardDisplayedMode!: Variable<string | null>;
    private updateStats: (() => void) | undefined;

    private currentLeaderboard: BaseLeaderboard | null = null;
    private currentLeaderboardType: LeaderboardType = LeaderboardType.Global;
    private lastLocalRaceConfig: HoverboardGameConfig | null = null;

    private leaderboardModeButtonBook!: Book;
    private lastRaceButtonBook!: Book;
    private getNotifiedButtonBook!: Book;
    private updateGetNotifiedButtonVisibility: (() => void) | null = null;

    private configChangedCallback = this.pickCurrentLeaderboard.bind(this, undefined);

    override init() {
        super.init();

        this.playerName = new Variable<string>(common.playerData.name);
        this.fitPoints = new Variable<number>(common.playerData.totalFitPoints);
        this.heyVRCoins = new Variable<number>(common.playerData.heyVRCoins);
        this.fitabux = new Variable<number>(common.playerData.fitabux);

        this.leaderboardTitleText = new Variable<string>("");
        this.leaderboardSubtitleText = new Variable<string>("");
        this.leaderboardScoreLabel = new Variable<string>("");
        this.leaderboardUnavailableText = new Variable<string>("");
        this.leaderboardPage = new Variable(LeaderboardBookPage.Unavailable);
        this.leaderboardDisplayedMode = new Variable(null);

        this.playerDataListener = () => {
            this.fitPoints.value = common.playerData.totalFitPoints;
            this.playerName!.value = common.playerData.name;
            this.heyVRCoins!.value = common.playerData.heyVRCoins;
            this.fitabux!.value = common.playerData.fitabux;
        };

        common.playerData.listen(this.playerDataListener, ["name", "totalFitPoints", "heyVRCoins", "fitabux"]);

        common.kioskUpperUI = this;
    }

    override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(WidgetLeaderboard);
        parser.autoRegisterFactory(Stats);
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(KioskBackground);
        parser.autoRegisterFactory(BackPane);
        parser.autoRegisterFactory(ScoreRow);
        parser.autoRegisterFactory(DecoratedButton);
        parser.autoRegisterFactory(ArrowDecoratedButton);

        return parser;
    }

    override getXMLParserConfig() {
        this.lastTrackChecked = new Variable(common.gameConfig.track);

        return {
            ...super.getXMLParserConfig(),
            variables: {
                welcome: new ObservableTransformer([this.playerName], () => `Welcome, ${this.playerName.value}`),
                fitPoints: this.fitPoints,
                fitabux: this.fitabux,
                heyvrCoins: this.heyVRCoins,
                statsWidth: 536,
                statsRowHeight: 36,
                leaderboardWidth: 536,
                leaderboardRankWidth: 100,
                leaderboardTimeWidth: 100,
                leaderboardRowHeight: 18,
                leaderboardTitleText: this.leaderboardTitleText,
                leaderboardSubtitleText: this.leaderboardSubtitleText,
                leaderboardScoreLabel: this.leaderboardScoreLabel,
                leaderboardUnavailableText: this.leaderboardUnavailableText,
                openLogin: () => {
                    if (XRUtils.isSessionActive()) {
                        common.kioskLowerUI.displayLoginVRWarning();
                    } else {
                        common.playerData.openLogin();
                    }
                },
                nextLeaderboardType: () => {
                    this.changeLeaderboardType(1);
                },
                prevLeaderboardType: () => {
                    this.changeLeaderboardType(-1);
                },
                showLastRaceLeaderboard: () => {
                    if (this.lastLocalRaceConfig != null) {
                        this.showLastRaceLeaderboard();
                    } else {
                        const popupParams = new UICustomPopupHelperParams();
                        popupParams.title = "RACE TO SCORE";
                        popupParams.message = "You need to race once during this session to check the last local race leaderboard!";
                        popupParams.primaryButtonText = "RACE";
                        popupParams.primaryButtonClickCallback = () => {
                            common.kioskLowerUI.closePopup();
                            common.kioskLowerUI.goToTrack();
                        };
                        popupParams.lowPriorityButtonText = "CLOSE";
                        popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };

                        common.kioskLowerUI.showCustomPopup(popupParams);
                    }
                },
                changeLeaderboardMode: () => {
                    const board = this.currentLeaderboard;
                    if (!board) return;

                    board.selectedMode = modulo(board.selectedMode + 1, board.getAvailableModes().length);
                    this.updateLeaderboardTitle();
                    this.refreshLeaderboard(board);
                },
                getNotified: () => {
                    const popupParams = new UICustomPopupHelperParams();
                    popupParams.title = "PRIZES & UPDATES";
                    popupParams.message = "Subscribe to enter the Squat Contest prize draw and get the latest HoverFit offers and news.\n\nSigning up will allow us to contact you with any winning notifications and promotional information.";
                    popupParams.primaryButtonText = "SUBSCRIBE";
                    popupParams.primaryButtonClickCallback = () => {
                        common.playerData.subscribe();
                        common.kioskLowerUI.closePopup();
                    };
                    popupParams.lowPriorityButtonText = "CLOSE";
                    popupParams.lowPriorityButtonClickCallback = () => {
                        common.kioskLowerUI.closePopup();
                    };

                    common.kioskLowerUI.showCustomPopup(popupParams);
                }
            },
        };
    }

    private onAuthChanged!: (changedKey?: string) => void;

    protected override onUITreePicked(uiTree: Widget, context: XMLUIParserContext): void {
        super.onUITreePicked(uiTree, context);

        const stats = context.idMap.get("stats") as Stats;
        this.updateStats = () => {
            stats.replaceStatsData([
                {
                    name: "SQUATS",
                    iconSVG: "assets/textures/ui/icons/kiosk/gold-squat.svg",
                    iconSpacing: 3,
                    iconHeightPercentage: 0.4,
                    dailyValue: `${common.playerData.dailySquats}`,
                    weeklyValue: `${common.playerData.weeklySquats}`,
                    monthlyValue: `${common.playerData.monthlySquats}`,
                    lifetimeValue: `${common.playerData.totalSquats}`,
                },
                {
                    name: "PLAY TIME",
                    iconSVG: "assets/textures/ui/icons/kiosk/clock.svg",
                    dailyValue: formatPlayTime(common.playerData.dailyPlayTime),
                    weeklyValue: formatPlayTime(common.playerData.weeklyPlayTime),
                    monthlyValue: formatPlayTime(common.playerData.monthlyPlayTime),
                    lifetimeValue: formatPlayTime(common.playerData.totalPlayTime),
                },
            ]);
        };

        common.playerData.listen(this.updateStats, [
            "dailySquats", "weeklySquats", "monthlySquats", "totalSquats",
            "dailyPlayTime", "weeklyPlayTime", "monthlyPlayTime", "totalPlayTime",
        ]);
        this.updateStats();

        this.modeBook = context.idMap.get("mode-book") as Book;

        const openLoginButtonContainer = context.idMap.get("openLoginButtonContainer") as MultiContainer;
        openLoginButtonContainer.enabled = HeyVR != null && common.playerData.isGuest;

        this.onAuthChanged = (changedKey?: string) => {
            if (changedKey != "auth_changed") return;

            openLoginButtonContainer.enabled = HeyVR != null && common.playerData.isGuest;
        };
        common.playerData.listen(this.onAuthChanged, "auth_changed");

        const leaderboardBook = context.idMap.get("leaderboard-book") as Book;
        this.leaderboardPage.watch(() => {
            leaderboardBook.changePage(this.leaderboardPage.value);
        }, true);

        this.leaderboardModeButtonBook = context.idMap.get("leaderboard-mode-button-book") as Book;
        this.lastRaceButtonBook = context.idMap.get("last-race-button-book") as Book;
        this.getNotifiedButtonBook = context.idMap.get("get-notified-button-book") as Book;
        this.leaderboardModeButtonBook.changePage(1);
        this.lastRaceButtonBook.changePage(1);
        this.getNotifiedButtonBook.changePage(1);

        this.updateGetNotifiedButtonVisibility = () => {
            this.getNotifiedButtonBook.changePage(!common.playerData.subscribed && this.currentLeaderboardType == LeaderboardType.SquatContest ? 0 : 1);
        };
        common.playerData.listen(this.updateGetNotifiedButtonVisibility, "subscribed");

        const modeButton = context.idMap.get("leaderboard-mode-button") as DecoratedButton;
        this.leaderboardDisplayedMode.watch(() => {
            const text = this.leaderboardDisplayedMode.value;
            if (text) modeButton.label.text = text;
        }, true);

        this.widgetLeaderboard = context.idMap.get("leaderboard") as WidgetLeaderboard;
        this.pickCurrentLeaderboard();
        this.updateLeaderboardTitle();

        common.gameConfig.lapsAmount.watch(this.configChangedCallback);

        this.ready = true;
    }

    changeLeaderboardType(delta: number) {
        this.setLeaderboardType(modulo(this.currentLeaderboardType + delta, LEADERBOARD_TYPE_MODULO));
    }

    setLeaderboardType(leaderboardType: LeaderboardType) {
        if (this.currentLeaderboardType != leaderboardType) {
            this.currentLeaderboardType = leaderboardType;
            this.pickCurrentLeaderboard(true);

            if (this.currentLeaderboard != null && !this.currentLeaderboard.unavailableMessage) {
                this.getNotifiedButtonBook.changePage(!common.playerData.subscribed && leaderboardType == LeaderboardType.SquatContest ? 0 : 1);
                this.leaderboardModeButtonBook.changePage(leaderboardType == LeaderboardType.Local ? 1 : 0);
            }
        }
    }

    refreshLeaderboard(leaderboard?: BaseLeaderboard) {
        if (this.isDestroyed) return;

        if (this.currentLeaderboard != null && (leaderboard == null || this.currentLeaderboard == leaderboard) && !this.currentLeaderboard.unavailableMessage) {

            this.currentLeaderboard.fetchScores().then((leaderboard) => {
                if (this.isDestroyed) return;

                if (this.currentLeaderboard == leaderboard) {
                    // Due to await, it might have been changed after it's fetched

                    this.widgetLeaderboard.replaceLeaderboardScores(this.currentLeaderboard.getScores());

                    const isLeaderboardEmpty = this.currentLeaderboard.getScores().length == 0;
                    this.leaderboardModeButtonBook.changePage(isLeaderboardEmpty || this.currentLeaderboardType == LeaderboardType.Local ? 1 : 0);

                    this.lastRaceButtonBook.changePage(this.lastLocalRaceConfig != null ? 0 : 1);
                }
            });
        } else {
            this.getNotifiedButtonBook.changePage(1);
            this.leaderboardModeButtonBook.changePage(1);
        }
    }

    pickCurrentLeaderboard(hasPlayerIntent: boolean = false) {
        const configPicked = common.gameConfig;
        let newLeaderboard: BaseLeaderboard | null = null;

        if (this.currentLeaderboardType === LeaderboardType.Global || this.currentLeaderboardType === LeaderboardType.Local) {
            if (configPicked.mode === GameMode.Race) {
                newLeaderboard = common.leaderboardsManager.getLeaderboard({
                    type: this.currentLeaderboardType,
                    mode: GameMode.Race,
                    location: configPicked.location,
                    track: configPicked.trackConfig.id,
                    lapsAmount: this.currentLeaderboardType === LeaderboardType.Global ? undefined : configPicked.lapsAmount.value,
                });
            }
        } else {
            newLeaderboard = common.leaderboardsManager.getLeaderboard({
                type: this.currentLeaderboardType
            });
        }

        // try to show last race's leaderboard instead if the plaeyr is switching
        // to the local leaderboard but it has no scores
        if (hasPlayerIntent && this.currentLeaderboardType == LeaderboardType.Local && (!newLeaderboard || newLeaderboard.getScores().length == 0) && this.lastLocalRaceConfig != null) {
            const lastRaceLeaderboard = common.leaderboardsManager.getLeaderboard({
                type: LeaderboardType.Local,
                mode: GameMode.Race,
                location: this.lastLocalRaceConfig.location,
                track: this.lastLocalRaceConfig.trackConfig.id,
                lapsAmount: this.lastLocalRaceConfig.lapsAmount.value,
            });

            if (lastRaceLeaderboard.getScores().length > 0) {
                newLeaderboard = lastRaceLeaderboard;
            }
        }

        if (this.currentLeaderboard !== newLeaderboard || (hasPlayerIntent && !newLeaderboard)) {
            this.currentLeaderboard = newLeaderboard;
            this.updateLeaderboardTitle();
        }

        this.refreshLeaderboard();
    }

    updateLastRaceGameConfig() {
        if (this.lastLocalRaceConfig == null) {
            this.lastLocalRaceConfig = new HoverboardGameConfig();
        }

        this.lastLocalRaceConfig.copyFrom(common.gameConfig);

        this.lastRaceButtonBook.changePage(0);
    }

    showLastRaceLeaderboard() {
        if (this.lastLocalRaceConfig != null) {
            this.getNotifiedButtonBook.changePage(1);
            this.leaderboardModeButtonBook.changePage(1);

            this.currentLeaderboardType = LeaderboardType.Local;
            this.currentLeaderboard = common.leaderboardsManager.getLeaderboard({
                type: LeaderboardType.Local,
                mode: GameMode.Race,
                location: this.lastLocalRaceConfig.location,
                track: this.lastLocalRaceConfig.trackConfig.id,
                lapsAmount: this.lastLocalRaceConfig.lapsAmount.value
            });

            this.updateLeaderboardTitle();
            this.refreshLeaderboard();
        }
    }

    getMode(): UpperUIMode {
        return this.modeBook.currentPage;
    }

    changeMode(mode: UpperUIMode) {
        this.modeBook.changePage(mode);
    }

    protected override getXMLContent(): string {
        return xmlContent;
    }

    protected override beforeWidgetUpdate(root: WLVirtualKeyboardRoot, dt: number): boolean | void {
        this.lastTrackChecked.value = common.gameConfig.track;
        super.beforeWidgetUpdate(root, dt);
    }

    private updateLeaderboardTitle() {
        const board = this.currentLeaderboard;
        this.leaderboardTitleText.value = LEADERBOARD_TITLES[this.currentLeaderboardType];

        if (board) {
            this.leaderboardSubtitleText.value = board.subtitle;
            this.leaderboardScoreLabel.value = board.scoreLabel;
            const unavailableMessage = board.unavailableMessage;
            if (unavailableMessage) {
                this.leaderboardUnavailableText.value = unavailableMessage;
                this.leaderboardPage.value = LeaderboardBookPage.Unavailable;
            } else {
                this.leaderboardUnavailableText.value = "No high scores available";
                this.leaderboardPage.value = LeaderboardBookPage.ShowScores;
            }

            const modeLabels = board.getAvailableModes();
            this.leaderboardDisplayedMode.value = (!unavailableMessage && modeLabels.length > 1) ? modeLabels[board.selectedMode] : null;
        } else {
            this.leaderboardUnavailableText.value = "This leaderboard is not available for the current game mode";
            this.leaderboardPage.value = LeaderboardBookPage.Unavailable;
            this.leaderboardDisplayedMode.value = null;
        }
    }

    override onDestroy(): void {
        if (this.updateStats) common.playerData.unlisten(this.updateStats);
        common.playerData.unlisten(this.onAuthChanged);
        common.playerData.unlisten(this.playerDataListener);
        common.gameConfig.lapsAmount.unwatch(this.configChangedCallback);

        if (this.updateGetNotifiedButtonVisibility != null) {
            common.playerData.unlisten(this.updateGetNotifiedButtonVisibility);
        }
    }
}
