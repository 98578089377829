import { Alignment, Alignment2D, ClickableWidgetProperties, FilledButton, Label, LabelProperties, LeaveEvent, PointerMoveEvent, PointerPressEvent, PointerReleaseEvent, RoundedCorners, SingleParentXMLInputConfig, TextAlignMode, Theme, ThemeScope, Widget, WidgetAutoXML, WidgetEvent, WrapMode, filterIDFromProperties } from "lazy-widgets";
import { Handedness } from "wle-pp";
import { AudioID } from "../../../audio/audio-manager/audio-id.js";
import { Audio } from "../../../audio/audio-manager/audio.js";
import { common } from "../../../common.js";
import { downHapticFeedback, hoverHapticFeedback, upHapticFeedback } from "../../../misc/haptic-feedback.js";
import { sfTheme } from "../../misc/sf-theme.js";
import { getCursorFromLZCID } from "../get-cursor-from-lzcid.js";

export interface ClickyButtonProperties extends LabelProperties, ClickableWidgetProperties {
    noCursorOnForced?: boolean;
}

export class InternalClickyButton<W extends Widget = Widget> extends FilledButton<W> {
    private inBounds: boolean;
    readonly clickSound: Audio | null;
    readonly hoverSound: Audio | null;
    noCursorOnForced: boolean;

    constructor(child: W, properties?: Readonly<ClickyButtonProperties>) {
        super(child, properties);

        this.clickSound = common.audioManager.getAudio(AudioID.BUTTON_CLICK);
        this.hoverSound = common.audioManager.getAudio(AudioID.BUTTON_HOVER);
        this.inBounds = false;
        this.noCursorOnForced = properties?.noCursorOnForced ?? false;
    }

    override handleEvent(event: WidgetEvent) {
        const captured = super.handleEvent(event);

        if (this.noCursorOnForced && this.forced) {
            this.inBounds = false;
            this.root.clearPointerStylesFromWidget(this);
        } else if (this.active && this.clickable) {
            if (event.isa(PointerMoveEvent)) {
                if (!this.inBounds) {
                    this.inBounds = true;
                    this.hoverSound?.play();
                    const cursor = getCursorFromLZCID(event);
                    if (cursor) hoverHapticFeedback(cursor.handedness as unknown as Handedness);
                }
            } else if (event.isa(LeaveEvent)) {
                if (this.inBounds) this.inBounds = false;
            } else if (event.isa(PointerPressEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) downHapticFeedback(cursor.handedness as unknown as Handedness);
            } else if (event.isa(PointerReleaseEvent)) {
                const cursor = getCursorFromLZCID(event);
                if (cursor) upHapticFeedback(cursor.handedness as unknown as Handedness);
            }
        } else {
            this.inBounds = false;
        }

        return captured;
    }

    override set clickable(clickable) {
        if (!clickable) {
            this.inBounds = false;
        }

        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }

    override deactivate() {
        super.deactivate();
        this.inBounds = false;
    }

    override click() {
        if (this.clickSound && !(this.noCursorOnForced && this.forced)) {
            this.clickSound.play();
        }

        super.click();
    }
}

export class InternalClickyTextButton extends InternalClickyButton<Label> {
    constructor(text: string, properties?: Readonly<ClickyButtonProperties>) {
        // XXX init copied from TextButton
        properties = {
            containerAlignment: <Alignment2D>{
                horizontal: Alignment.Center, vertical: Alignment.Stretch,
            },
            bodyTextAlign: TextAlignMode.Center,
            wrapMode: WrapMode.Ellipsis,
            ...properties
        };

        super(new Label(text, filterIDFromProperties(properties)), properties);
    }
}

export class ClickyButton extends RoundedCorners<ThemeScope<InternalClickyTextButton>> {
    static override autoXML: WidgetAutoXML = {
        name: "clicky-button",
        inputConfig: [
            {
                mode: "text",
                name: "text"
            },
        ],
    };

    constructor(text: string, properties?: Readonly<ClickyButtonProperties>) {
        const filteredProperties = filterIDFromProperties(properties);

        super(new ThemeScope(
            new InternalClickyTextButton(text, filteredProperties),
            new Theme({
                backgroundFill: "rgb(227, 229, 241)",
                backgroundGlowFill: "rgb(176, 177, 210)",
                bodyTextFill: "black",
            }, sfTheme),
        ), properties);
    }

    get clickable() {
        return this.child.child.clickable;
    }

    set clickable(clickable) {
        this.child.child.clickable = clickable;
    }

    get text() {
        return this.child.child.child.text;
    }

    set text(text) {
        this.child.child.child.text = text;
    }
}

export class ContainerClickyButton<W extends Widget> extends RoundedCorners<ThemeScope<InternalClickyButton<W>>> {
    static override autoXML: WidgetAutoXML = {
        name: "container-clicky-button",
        inputConfig: SingleParentXMLInputConfig,
    };

    constructor(child: W, properties?: Readonly<ClickyButtonProperties>) {
        const filteredProperties = filterIDFromProperties(properties);

        super(new ThemeScope(
            new InternalClickyButton(child, filteredProperties),
            new Theme({
                backgroundFill: properties?.backgroundFill ?? "rgb(227, 229, 241)",
                backgroundGlowFill: properties?.backgroundGlowFill ?? "rgb(176, 177, 210)",
                bodyTextFill: properties?.bodyTextFill ?? "black",
            }, sfTheme),
        ), properties);
    }

    get clickable() {
        return this.child.child.clickable;
    }

    set clickable(clickable) {
        this.child.child.clickable = clickable;
    }
}
