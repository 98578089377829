import { Object3D } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { common } from "src/hoverfit/common.js";
import { GameGlobals } from "src/hoverfit/misc/game-globals.js";
import { PhysicsUtils, Quaternion2, RaycastParams, RaycastResults, quat2_create, quat_create } from "wle-pp";
import { PickupComponent } from "./pickups/components/pickup-component.js";
import { PickupsManager } from "./pickups/pickups-manager.js";
import { SpawnPositionProvider } from "./spawn-position-provider.js";
import { SplineComponent } from "./splines/components/spline-component.js";
import { Spline } from "./splines/spline.js";
import { TracksManager } from "./tracks-manager.js";

export class Track {
    static TypeName = "track";

    private _trackObject!: Object3D;
    private _mapTrackIndex!: number;
    private _showDynamicChevrons!: boolean;

    private _tracksManager!: TracksManager;

    private _spawnPositionProvider: SpawnPositionProvider = new SpawnPositionProvider();
    private _countdownPosition: Quaternion2 | null = null;
    private _startGoalPosition: Quaternion2 | null = null;
    private _finishGoalPosition: Quaternion2 | null = null;

    private _splineComponent: SplineComponent | null = null;
    private _spline: Spline | null = null;

    private _isRaceSupported: boolean = false;

    private _pickupsManager!: PickupsManager;

    private _ready: boolean = false;
    private _active: boolean = false;

    constructor(trackObject: Object3D, mapTrackIndex: number, showDynamicChevrons: boolean) {
        this._trackObject = trackObject;
        this._mapTrackIndex = mapTrackIndex;
        this._showDynamicChevrons = showDynamicChevrons;

        this._splineComponent = this._trackObject.pp_getComponent(SplineComponent);

        const pickups = [];
        const pickupComponents: PickupComponent[] = [];

        for (const pickupComponentType of PickupsManager.getPickupComponentTypes()) {
            pickupComponents.push(...(this._trackObject.pp_getComponents(pickupComponentType) as unknown as PickupComponent[]));
        }

        for (const pickupComponent of pickupComponents) {
            pickups.push(pickupComponent.createPickup());
        }
        this._pickupsManager = new PickupsManager(pickups);

        this._isRaceSupported = this._splineComponent != null;
    }

    start() {
        this._spawnPositionProvider.start();

        if (this._splineComponent != null) {
            this._splineComponent.loadEmitter!.add(function (this: Track) {
                this._setupTrack();
            }.bind(this), { immediate: true });
        } else {
            this._setupTrack();
        }

        this._trackObject.pp_setActive(false);
    }

    update(dt: number) {
        if (this._active) {
            this._spawnPositionProvider.update(dt);
            this._pickupsManager.update(dt);
        }
    }

    reset() {
        this._pickupsManager.reset();
        this._tracksManager.getRaceManager().reset();
    }

    countdownStarted() {
        this._tracksManager.getRaceManager().countdownStarted();

        const pickupGrabbers = [];

        pickupGrabbers.push(common.hoverboard);
        pickupGrabbers.push(...common.menu.currentNPCs!);

        this._pickupsManager.setPickupGrabbers(pickupGrabbers);
    }

    returnedToBalcony() {
        this._tracksManager.getRaceManager().returnedToBalcony();
    }

    roundEnded() {
        this.reset();
    }

    isReady(): boolean {
        return this._ready;
    }

    getPickupsManager(): PickupsManager {
        return this._pickupsManager;
    }

    getMapTrackIndex(): number {
        return this._mapTrackIndex;
    }

    setTracksManager(tracksManager: TracksManager): void {
        this._tracksManager = tracksManager;
    }

    areDynamicChevronsEnabled(): boolean {
        return this._showDynamicChevrons;
    }

    isCountdownUsingFixedPosition(): boolean {
        return this._countdownPosition != null;
    }

    getCountdownPosition(): Quaternion2 | null {
        return this._countdownPosition;
    }

    hasSpline(): boolean {
        return this._spline != null;
    }

    getSpline(): Spline | null {
        return this._spline;
    }

    isSplineLoaded(): boolean {
        return !this.hasSpline() || this._splineComponent!.isSplineLoaded()!;
    }

    getSpawnPositionProvider(): SpawnPositionProvider {
        return this._spawnPositionProvider;
    }

    setActive(active: boolean) {
        if (active) {
            this._trackObject.pp_setActive(true);

            this._pickupsManager.setActive(true);

            this._tracksManager!.getMiniMapManager().setTrack(this);

            const raceManager = this._tracksManager.getRaceManager();
            if (!this._isRaceSupported || common.gameConfig.mode != GameMode.Race) {
                raceManager.setEnabled(false);
            } else {
                raceManager.setStartAndFinishGoalsPositions(this._startGoalPosition!, this._finishGoalPosition!);
                raceManager.setEnabled(true);
            }

            this.reset();
        } else {
            this._trackObject.pp_setActive(false);

            this._pickupsManager.setActive(false);

            this._tracksManager.getMiniMapManager().setTrack(null);

            const raceManager = this._tracksManager.getRaceManager();
            raceManager.setEnabled(false);
        }

        this._active = active;
    }

    private _setupTrack() {
        if (this._splineComponent == null) {
            this._countdownPosition = null;
            this._startGoalPosition = null;
            this._finishGoalPosition = null;

            const spawnPositions: Quaternion2[] = [];
            const spawnPositionsObjects = this._trackObject.pp_getObjectByName("Spawn Positions")!.pp_getDescendants();
            for (const spawnPositionsObject of spawnPositionsObjects) {
                const position = spawnPositionsObject.pp_getPosition();
                const raycastParams = new RaycastParams();
                raycastParams.myOrigin.vec3_copy(position.vec3_add(GameGlobals.up.vec3_scale(2)));
                raycastParams.myDirection.vec3_copy(GameGlobals.down);
                raycastParams.myDistance = 50;
                raycastParams.myIgnoreHitsInsideCollision = true;
                raycastParams.myBlockLayerFlags.setAllFlagsActive(true);
                const raycastResults: RaycastResults = PhysicsUtils.raycast(raycastParams);

                if (raycastResults.isColliding()) {
                    const originalSpawnPositionTransformQuat = spawnPositionsObject.pp_getTransformQuat();
                    spawnPositions.push(originalSpawnPositionTransformQuat.quat2_setPosition(raycastResults.myHits[0].myPosition));
                } else {
                    spawnPositions.push(spawnPositionsObject.pp_getTransformQuat());
                }
            }
            this._spawnPositionProvider.setSpawnPositions(spawnPositions);
        } else {
            this._spline = this._splineComponent.getSpline()!;

            const startPositionLocator = this._trackObject.pp_getObjectByName("Start Position Locator");
            if (startPositionLocator != null) {
                this._spline.setStartClosestToPosition(startPositionLocator.pp_getPosition());
                this._spline.setDirectionClosestToForward(startPositionLocator.pp_getForward());
            }

            this._countdownPosition = quat2_create();
            this._startGoalPosition = quat2_create();
            this._finishGoalPosition = quat2_create();

            {
                const countdownBasePosition = this._spline.getPosition(this._spline.getTimeByDistance(10)).pp_clone();
                const countdownBaseForward = this._spline.getForward(0).pp_clone();

                const countdownPosition = countdownBasePosition.vec3_add(GameGlobals.up.vec3_scale(5));
                const countdownRotationQuat = quat_create();
                countdownRotationQuat.quat_setUp(GameGlobals.up, countdownBaseForward.vec3_negate());

                this._countdownPosition.quat2_setPositionRotationQuat(countdownPosition, countdownRotationQuat);
            }

            {
                const goalBasePosition = this._spline.getPosition(this._spline.getTimeByDistance(40)).pp_clone();
                const goalBaseForward = this._spline.getForward(this._spline.getTimeByDistance(40)).pp_clone();

                const goalPosition = goalBasePosition.pp_clone();
                const goalRotationQuat = quat_create();
                goalRotationQuat.quat_setUp(GameGlobals.up, goalBaseForward.vec3_negate());

                this._startGoalPosition.quat2_setPositionRotationQuat(goalPosition, goalRotationQuat);
                this._finishGoalPosition.quat2_setPositionRotationQuat(goalPosition, goalRotationQuat);
            }

            {
                const spawnPositions: Quaternion2[] = [];

                const spawnPositionBasePosition = this._spline.getPosition(0).pp_clone();
                const spawnPositionForward = this._spline.getForward(0).pp_clone();
                const spawnPositioRotationQuat = quat_create();
                spawnPositioRotationQuat.quat_setUp(GameGlobals.up, spawnPositionForward);

                const spawnPositionRight = spawnPositioRotationQuat.quat_getRight();

                for (let i = 1; i <= 6; i++) {
                    const positionSign = (i % 2 == 0) ? -1 : 1;
                    const currentSpawnPosition = spawnPositionBasePosition.vec3_add(spawnPositionRight.vec3_scale((1.5 + (3 * Math.floor((i - 1) / 2))) * positionSign));

                    const currentSpawnTransformQuat = quat2_create();
                    currentSpawnTransformQuat.quat2_setPositionRotationQuat(currentSpawnPosition, spawnPositioRotationQuat);

                    spawnPositions.push(currentSpawnTransformQuat);
                }

                this._spawnPositionProvider.setSpawnPositions(spawnPositions);
            }

            const spawnPosition = this._spline.getPosition(0);
            const finishPosition = this._spline.getPosition(this._spline.getTimeByDistance(40));
            this._spline.setStartClosestToPosition(finishPosition);

            this._spawnPositionProvider.setSpawnPositionSplineTime(this._spline.getClosestTime(spawnPosition));
        }

        this._ready = true;
    }
}