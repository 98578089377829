// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - The build system supports XML importing, but typescript doesn't
import xmlContent from "../xml/loading-scene-ui.xml";

import { Emitter, Property } from "@wonderlandengine/api";
import { Label, Theme, Variable, Widget, type XMLUIParserContext } from "lazy-widgets";
import { BasicXMLUIRootComponent, WLVirtualKeyboardRoot } from "lazy-widgets-wle";
import { Background9Slice } from "../../kiosk/widgets/background-9slice.js";
import { BaseFitnessResortUIComponent } from "../../lazy-widgets/components/base-fitness-resort-ui-component.js";
import { Book } from "../../lazy-widgets/widgets/book.js";
import { sfTheme } from "../../misc/sf-theme.js";

export enum LoadingSceneUI {
    HIDDEN = -1,
    MOVING_TO = 0
}

export class LoadingSceneUIComponent extends BaseFitnessResortUIComponent {
    static override TypeName = "loading-scene-ui";

    static override Properties = {
        ...BasicXMLUIRootComponent.Properties,
        resolution: Property.float(1.0),
    };

    private ready: boolean = false;
    private readonly _myReadyEmitter: Emitter = new Emitter();

    private currentUI: Variable<LoadingSceneUI> = new Variable(LoadingSceneUI.HIDDEN);

    private movingToConfigMessage!: Label;

    /** This is needed because there is a bug in 1.1.6 when switching scene, might not be needed in 1.2.0 */
    private avoidDeactivateHotfix: boolean = false;

    showMovingToPopup(message: string) {
        this.movingToConfigMessage.text = message;
        this.currentUI.value = LoadingSceneUI.MOVING_TO;
    }

    closePopup() {
        this.currentUI.value = LoadingSceneUI.HIDDEN;
    }

    isReady() {
        return this.ready;
    }

    registerReadyEventListener(id: unknown, listener: () => void): void {
        this._myReadyEmitter.add(listener, { id: id });
    }

    unregisterReadyEventListener(id: unknown): void {
        this._myReadyEmitter.remove(id);
    }

    sceneIsAboutToSwitch(): void {
        this.avoidDeactivateHotfix = true;
    }

    protected override createXMLParser() {
        const parser = super.createXMLParser();
        parser.autoRegisterFactory(Book);
        parser.autoRegisterFactory(Background9Slice);

        return parser;
    }

    protected override getRootProperties() {
        const properties = super.getRootProperties();

        return {
            ...properties,
            resolution: this.resolution,
            theme: new Theme({
                bodyTextFill: "white",
            }, properties?.theme ?? sfTheme),
            enabled: false,
        };
    }

    protected override getXMLParserConfig() {
        return {
            ...super.getXMLParserConfig(),
            variables: {}
        };
    }

    protected override getXMLContent() {
        return xmlContent;
    }

    protected override onUITreePicked(uiTree: Widget, context: XMLUIParserContext) {
        super.onUITreePicked(uiTree, context);

        this.movingToConfigMessage = context.idMap.get("moving-to-config-message") as Label;

        const book = context.idMap.get("ui-book") as Book;
        this.currentUI.watch(() => {
            if (this.currentUI.value !== LoadingSceneUI.HIDDEN) {
                book.changePage(this.currentUI.value);
            }
        }, true);
    }

    override onRootReady(root: WLVirtualKeyboardRoot) {
        super.onRootReady(root);

        this.currentUI.watch(() => {
            this.active = this.currentUI.value !== LoadingSceneUI.HIDDEN;
        }, true);

        this.ready = true;

        this._myReadyEmitter.notify();
    }

    override onDeactivate(): void {
        if (!this.avoidDeactivateHotfix) {
            super.onDeactivate();
        }
    }
}
