import { Object3D } from "@wonderlandengine/api";
import { common } from "src/hoverfit/common.js";
import { GlueStatusEffect, GlueStatusEffectParams, HoverboardGlueStatusEffect, HoverboardGlueStatusEffectParams } from "src/hoverfit/game/hoverboard/status-effects/implementations/glue-status-effect.js";
import { StatusEffectTarget } from "src/hoverfit/game/hoverboard/status-effects/status-effect-target.js";
import { StatusEffectType } from "src/hoverfit/game/hoverboard/status-effects/status-effect.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupGlueParams extends SyncedPickupParams { }

export class PickupGlue extends SyncedPickup {
    declare protected _params: PickupGlueParams;

    constructor(pickupObject: Object3D, params: PickupGlueParams) {
        super(PickupType.Glue, pickupObject, params);
    }

    _canBePickedUpHook(pickupGrabber: PickupGrabber) {
        const statusEffectTarget = pickupGrabber as unknown as StatusEffectTarget;

        return !common.tracksManager.getStatusEffectsManager().hasStatusEffect(statusEffectTarget, StatusEffectType.Invincibility);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

        let statusEffect = null;

        if (pickupGrabber.isNPC()) {
            const statusEffectParams = new GlueStatusEffectParams();
            statusEffect = new GlueStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);
        } else {
            const statusEffectParams = new HoverboardGlueStatusEffectParams();
            statusEffect = new HoverboardGlueStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);
        }

        statusEffectsManager.addStatusEffect(statusEffect);
    }
}