import { type UnsafeDecodedSaveData } from "src/hoverfit/data/save-data.js";
import { other_2_parse_latest } from "./common/other_2_parse_latest.js";
import { SaveDecoderV1 } from "./save-decoder-v1.js";

export class SaveDecoderV2 extends SaveDecoderV1 {
    override decodeSaveData(rawData: any): UnsafeDecodedSaveData | null {
        const unsafeData = super.decodeSaveData(rawData);
        return unsafeData ? {
            ...unsafeData,
            ...other_2_parse_latest(rawData),
        } : null;
    }
}