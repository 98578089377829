import { Alignment, FlexAlignment, Icon, Label, Row, TextAlignMode, filterIDFromProperties, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { type ItemCurrency } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { CURRENCY_ICONS } from "./shop-item-button.js";

export interface PriceRowProperties extends WidgetProperties {
    iconSize?: number;
}

export class PriceRow extends Row {
    static override autoXML: WidgetAutoXML = {
        name: "price-row",
        inputConfig: [
            {
                mode: "value",
                name: "price",
            },
            {
                mode: "value",
                name: "currency",
            }
        ]
    };

    readonly label: Label;
    private icon: Icon;

    constructor(private _price: number, private _currency: ItemCurrency, properties?: Readonly<PriceRowProperties>) {
        const filterProperties = filterIDFromProperties(properties);
        const label = new Label(
            `${_price}`,
            {
                bodyTextFont: "2.0em sui-generis",
                bodyTextAlign: TextAlignMode.Center,
                ...filterProperties,
            },
        );
        const iconSize = properties?.iconSize ?? 24;
        const icon = new Icon(CURRENCY_ICONS[_currency], {
            minWidth: iconSize, maxWidth: iconSize,
            minHeight: iconSize, maxHeight: iconSize,
        });

        super(
            [label, icon],
            {
                multiContainerAlignment: {
                    main: FlexAlignment.Center,
                    cross: Alignment.Stretch,
                },
                ...properties,
            }
        );

        this.label = label;
        this.icon = icon;
    }

    get price() {
        return this._price;
    }

    set price(price: number) {
        if (this._price === price) return;
        this._price = price;
        this.label.text = `${this._price}`;
    }

    get currency() {
        return this._currency;
    }

    set currency(currency: ItemCurrency) {
        if (this._currency === currency) return;
        this._currency = currency;
        // XXX assume image is an HTMLImageElement. if we ever use an
        // AsyncImageBitmap as the backing media (e.g. for tinted images),
        // we will have to change this
        (this.icon.image as HTMLImageElement).src = CURRENCY_ICONS[this._currency];
    }
}