import { MS_PER_15_MINUTES } from "src/hoverfit/utils/time-utils.js";
import { fitabuxInventory_parse_latest } from "./fitabuxInventory_parse_latest.js";

export function other_1_parse_latest(rawData: Record<string, unknown>) {
    return {
        avatarType: rawData.avatarType,
        skinColor: rawData.skinColor,
        hoverboardVariant: rawData.hoverboardVariant,
        suitVariant: rawData.suitVariant,
        hairColor: rawData.hairColor,
        headwearVariantMale: rawData.headwearVariantMale,
        headwearVariantFemale: rawData.headwearVariantFemale,
        totalFitPoints: rawData.totalFitPoints,
        dailyFitPoints: rawData.dailyFitPoints,
        dailyRewardTier: rawData.dailyRewardTier,
        bronzeMedals: rawData.bronzeMedals,
        silverMedals: rawData.silverMedals,
        goldMedals: rawData.goldMedals,
        platinumMedals: rawData.platinumMedals,
        currentMidnightTime: Number(rawData.currentMidnightTime) * MS_PER_15_MINUTES,
        fitabux: rawData.fitabux,
        fitabuxInventory: fitabuxInventory_parse_latest(rawData),
        rewards: rawData.rewards,
        gameSettingsData: rawData.gameSettingsData,
        iapExpirables: rawData.iapExpirables,
    };
}