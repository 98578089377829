import { Alignment, Column, FlexAlignment, Icon, IconFit, Label, LiveLabel, Row, Spacing, TextAlignMode, Variable, WrapMode, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { common } from "src/hoverfit/common.js";
import { REWARD_TYPE_LABEL, RewardType } from "src/hoverfit/data/player-data.js";
import { BackPane } from "../../lazy-widgets/widgets/back-pane.js";
import { DecoratedButtonBadge } from "../../lazy-widgets/widgets/base-decorated-button.js";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { getPotentialRewardBoardLevel, getPotentialRewardHelmetLevel, getPotentialRewardSuitLevel } from "../../misc/getRewardIDs.js";
import { LevelBar } from "./level-bar.js";

export const FP_PER_HELMET_LEVEL = 600;
export const FP_PER_SUIT_LEVEL = 1000;
export const FP_PER_BOARD_LEVEL = 2000;
const FP_PER_EQUIP_LEVEL = [FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL, FP_PER_BOARD_LEVEL] as const;

export class LifetimeLevelDisplay extends Column {
    static override autoXML: WidgetAutoXML = {
        name: "lifetime-level-display",
        inputConfig: [
            {
                mode: "value",
                name: "type",
                validator: "number"
            },
        ]
    };

    private equipButton: DecoratedButton;
    private readonly level: Variable<number>;
    private readonly fpDisplay: Variable<string>;
    private readonly levelDisplay: Variable<string>;
    private readonly levelDisplayNext: Variable<string>;
    private readonly upgradeArrow: Icon;
    private readonly upgradeLevelLabel: LiveLabel;

    private updateWatcher = () => {
        const fpPerEquip = FP_PER_EQUIP_LEVEL[this.type];
        const fp = common.playerData.totalFitPoints;
        const curLevel = common.playerData.rewards[this.type];
        const potentialLevel = Math.max(curLevel, fp / fpPerEquip);
        const truncPLevel = Math.trunc(potentialLevel);
        this.level.value = potentialLevel;
        this.fpDisplay.value = `${fp}/${(truncPLevel + 1) * fpPerEquip}`;
        this.levelDisplay.value = `LEVEL ${curLevel}`;

        const hasUpgrade = curLevel !== truncPLevel;
        if (hasUpgrade) this.levelDisplayNext.value = `LEVEL ${truncPLevel}`;
        this.upgradeArrow.enabled = hasUpgrade;
        this.upgradeLevelLabel.enabled = hasUpgrade;

        this.updateBadge();
    };

    constructor(readonly type: RewardType, properties?: WidgetProperties) {
        const equipButton = new DecoratedButton("UNLOCK", {
            minWidth: 70, maxWidth: 70, minHeight: 24, maxHeight: 24,
        });

        const typeStr = REWARD_TYPE_LABEL[type];
        const level = new Variable(0);
        const fpDisplay = new Variable("");
        const levelDisplay = new Variable("");
        const levelDisplayNext = new Variable("");

        const upgradeArrow = new Icon(
            "assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg",
            { width: 12, height: 6, enabled: false },
        );
        const upgradeLevelLabel = new LiveLabel(levelDisplayNext, {
            bodyTextFont: "0.75em sui-generis",
            wrapMode: WrapMode.Ellipsis,
            flexShrink: 1,
            enabled: false,
        });

        super([
            new Label(`LIFETIME ${typeStr.toUpperCase()}`, {
                bodyTextAlign: TextAlignMode.Center,
            }),
            new Row([
                new BackPane(
                    new Column([
                        new Icon(`assets/textures/ui/icons/kiosk/rewards-${typeStr}.svg`, {
                            fit: IconFit.Contain, width: 80, height: 56,
                        }),
                        new Label("LEVEL", {
                            bodyTextFont: "0.75em sui-generis",
                            bodyTextAlign: TextAlignMode.Center,
                        }),
                        new LevelBar(level, {
                            bodyTextFont: "0.75em sui-generis",
                        }),
                        new Row([
                            new LiveLabel(fpDisplay, {
                                bodyTextFont: "0.5em sui-generis",
                                wrapMode: WrapMode.Ellipsis,
                                flexShrink: 1,
                                minHeight: 11,
                            }),
                            new Icon(
                                "assets/textures/ui/icons/kiosk/fitpoints.svg",
                                {
                                    width: 6,
                                    height: 6,
                                },
                            ),
                        ], {
                            multiContainerAlignment: {
                                main: FlexAlignment.Center,
                                cross: Alignment.Center,
                            },
                        }),
                    ]),
                    {
                        containerPadding: { left: 10, right: 10, top: 10, bottom: 2 }
                    }
                ),
            ], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                }
            }),
            new Spacing(),
            new Row([
                new LiveLabel(levelDisplay, {
                    bodyTextFont: "0.75em sui-generis",
                    wrapMode: WrapMode.Ellipsis,
                    flexShrink: 1,
                }),
                upgradeArrow,
                upgradeLevelLabel,
            ], {
                multiContainerAlignment: {
                    main: FlexAlignment.Center,
                    cross: Alignment.Center,
                },
            }),
            new Row([equipButton], {
                multiContainerAlignment: {
                    main: FlexAlignment.SpaceAround, cross: Alignment.Stretch,
                },
            })
        ], {
            flex: 1,
            ...properties,
        });

        this.equipButton = equipButton;
        this.level = level;
        this.fpDisplay = fpDisplay;
        this.levelDisplay = levelDisplay;
        this.levelDisplayNext = levelDisplayNext;
        this.upgradeArrow = upgradeArrow;
        this.upgradeLevelLabel = upgradeLevelLabel;
    }

    private updateBadge() {
        let expectedLevelGetter: () => number;

        switch (this.type) {
            case RewardType.Helmet:
                expectedLevelGetter = getPotentialRewardHelmetLevel;
                break;
            case RewardType.Suit:
                expectedLevelGetter = getPotentialRewardSuitLevel;
                break;
            case RewardType.Board:
                expectedLevelGetter = getPotentialRewardBoardLevel;
                break;
        }

        const curLevel = common.playerData.rewards[this.type];
        this.equipButton.child.text = curLevel === 0 ? "UNLOCK" : "UPGRADE";
        if (curLevel < expectedLevelGetter()) {
            this.equipButton.badge = DecoratedButtonBadge.Upgrade;
            this.equipButton.clickable = true;
        } else {
            this.equipButton.badge = null;
            this.equipButton.clickable = false;
        }
    }

    protected activate(): void {
        super.activate();
        this.level.watch(this.updateWatcher, true);
        common.playerData.listen(this.updateWatcher, ["totalFitPoints", "rewards"]);
    }

    protected deactivate(): void {
        common.playerData.unlisten(this.updateWatcher);
        this.level.unwatch(this.updateWatcher);
        super.deactivate();
    }
}