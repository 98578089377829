export enum GameLocation {
    Canyon = "canyon",
    Snow = "snow",
    City = "city",
}

export enum GameMap {
    Canyon = "canyon-open",
    Snow = "snow",
    SnowRace = "snow-race",
    City = "city",
}

export enum GameMode {
    Race = "race",
    Tag = "tag",
    Roam = "roam",
    // XXX unused
    // TODO re-enable this once we have a system that is more tolerant of new modes (e.g. kiosk)
    // Practice = "practice",
}

export interface VagueGameConfigurationIdentifier {
    location: GameLocation,
    mode?: GameMode,
    track?: number,
}

export type GameConfigurationIdentifier = Required<VagueGameConfigurationIdentifier>;