import { Icon, Root, Variable, Viewport, Widget, WidgetAutoXML } from "lazy-widgets";
import { IconDecoratedButton, IconDecoratedButtonProperties } from "../../lazy-widgets/widgets/icon-decorated-button.js";

export interface DecoratedCheckboxProperties extends IconDecoratedButtonProperties {
    untickedSVG?: string;
    tickedSVG?: string;
    iconWidth?: number,
    iconHeight?: number,
}

export class DecoratedCheckbox extends IconDecoratedButton {
    static override autoXML: WidgetAutoXML = {
        name: "decorated-checkbox",
        inputConfig: [
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            }
        ]
    };

    private checkboxIconImage: HTMLImageElement;

    private untickedSVG: string;
    private tickedSVG: string;

    private readonly onTickChange: () => void = () => {
        if (this.isTicked) {
            this.checkboxIconImage.src = this.tickedSVG;
        } else {
            this.checkboxIconImage.src = this.untickedSVG;
        }
    };

    constructor(readonly variable: Variable<boolean>, properties?: DecoratedCheckboxProperties) {
        const checkboxIconImage = new Image();
        checkboxIconImage.crossOrigin = "anonymous";
        checkboxIconImage.src = "";

        const checkboxIcon = new Icon(checkboxIconImage, {
            width: properties?.iconWidth ?? 10,
            height: properties?.iconHeight ?? 10
        });

        super(checkboxIcon,
            {
                containerPadding: { top: 5, right: 5, bottom: 5, left: 5 },
                containerAlignment: { horizontal: 0.5, vertical: 0.5 },
                hasBorder: properties?.hasBorder ?? true,
                normalStroke: properties?.normalStroke ?? "rgba(40, 40, 40, 0.8)",
                forceStrokeThickness: properties?.forceStrokeThickness ?? 6,
                normalStrokeThickness: properties?.normalStrokeThickness ?? 2,
                unclickableStrokeThickness: properties?.unclickableStrokeThickness ?? 2,
                ...properties
            }
        );

        this.checkboxIconImage = checkboxIconImage;

        this.untickedSVG = properties?.untickedSVG ?? "";
        this.tickedSVG = properties?.tickedSVG ?? "assets/textures/ui/icons/kiosk/tick.svg";

        this.checkboxIconImage.src = this.untickedSVG;

        this.on("click", () => {
            this.setTicked(!this.isTicked);
        });
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);
        this.variable.watch(this.onTickChange, true);
    }

    override detach(): void {
        this.variable.unwatch(this.onTickChange);
        super.detach();
    }

    setTicked(ticked: boolean) {
        this.variable.value = ticked;
    }

    get isTicked() {
        return this.variable.value;
    }
}