import { Component, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameMode } from "hoverfit-shared-netcode";
import { AudioID } from "src/hoverfit/audio/audio-manager/audio-id.js";
import { Audio } from "src/hoverfit/audio/audio-manager/audio.js";
import { TextMaterial, Vector4 } from "wle-pp";
import { common } from "../../../common.js";

function getLapToLabelSpaces(num: number) {
    return num > 9 ? "          " : "      ";
}

export class TimerComponent extends Component {
    static TypeName = "timer";

    @property.color()
    defaultColor!: Vector4;

    @property.color()
    tagAlmostOverColor!: Vector4;

    time: number = 0;
    bestLapTime: number = 0;
    bestLapIndex: number = 0;
    duration: number | null = null;

    private started: boolean = false;

    private running: boolean = false;
    private tagAlmostOverAlertDone: boolean = false;
    private updateTimer: boolean = false;

    private tagAlmostOverAlertAudio: Audio | null = null;

    private textTimeIndicator!: TextComponent;
    private textLastLapTimeIndicator!: TextComponent;
    private textLapIndicator!: TextComponent;
    private textLapIndicatorLabel!: TextComponent;

    init() {
        common.timer = this;
    }

    start() {
        this.textTimeIndicator = common.hoverboard.object.pp_getObjectByName("Time Indicator")!.pp_getComponent(TextComponent)!;
        this.textLastLapTimeIndicator = common.hoverboard.object.pp_getObjectByName("Time Indicator Last Lap")!.pp_getComponent(TextComponent)!;
        this.textLapIndicator = common.hoverboard.object.pp_getObjectByName("Lap Indicator")!.pp_getComponent(TextComponent)!;
        this.textLapIndicatorLabel = common.hoverboard.object.pp_getObjectByName("Lap Indicator Label")!.pp_getComponent(TextComponent)!;

        this.textTimeIndicator.material = this.textTimeIndicator.material?.clone();

        this.textTimeIndicator.active = false;
        this.textLastLapTimeIndicator.active = false;
        this.textLapIndicator.active = false;

        this.started = true;
    }

    setDuration(duration: number | null = null) {
        this.duration = duration;
    }

    setCurrentTime(time: number) {
        this.time = time;
    }

    resetTimer() {
        if (!this.started) {
            this.start();
        }

        this.time = 0;
        this.showTime(this.time);
    }

    startTimer() {
        if (this.running) return;

        if (!this.started) {
            this.start();
        }

        this.updateTimer = true;
        (this.textTimeIndicator.material as TextMaterial).color = this.defaultColor;

        this.running = true;
        this.time = this.duration ?? 0;
        this.bestLapTime = Number.MAX_VALUE;
        this.bestLapIndex = 0;

        this.tagAlmostOverAlertDone = false;
        if (this.tagAlmostOverAlertAudio == null) {
            this.tagAlmostOverAlertAudio = common.audioManager.getAudio(AudioID.TAG_ALMOST_OVER_ALERT);
        }

        if (!common.balcony.isPlayerOnBalcony.value) {
            this.textTimeIndicator.active = false;
            this.textLastLapTimeIndicator.active = false;
        }
    }

    stopTimer() {
        if (this.updateTimer) {
            this.stopUpdatingTimer();
            this.showTime(this.time);
        }

        this.running = false;
    }

    stopUpdatingTimer() {
        this.updateTimer = false;
    }

    hideTimer() {
        this.stopTimer();
        this.textTimeIndicator.active = false;
        this.textLastLapTimeIndicator.active = false;
    }

    setLapIndicatorVisible(visible: boolean) {
        this.textLapIndicator.active = visible;
        this.textLapIndicatorLabel.active = visible;
    }

    showCurrentTimer() {
        this.textTimeIndicator.active = true;
    }

    hideLastLapTimer() {
        this.textLastLapTimeIndicator.active = false;
    }

    pauseTimer() {
        this.running = false;
    }

    resumeTimer() {
        this.running = true;
    }

    updateLapsAmount(lapsAmount: number, totalLaps: number) {
        this.textLapIndicator.text = `     ${lapsAmount}   ${totalLaps}`;
        this.textLapIndicatorLabel.text = `Lap${getLapToLabelSpaces(lapsAmount)}of`;
    }

    updateLastLapTime(lastLapTime: number) {
        const time = lastLapTime;
        const m = String(Math.floor(time / 60)).padStart(2, "0");
        const s = String(Math.floor(time) % 60).padStart(2, "0");
        const ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
        const lastLapText = `${m}:${s}.${ms}`;
        this.textLastLapTimeIndicator.text = lastLapText;
    }

    updateBestLapTime(lastLapTime: number) {
        if (lastLapTime < this.bestLapTime) {
            this.bestLapTime = lastLapTime;
            this.bestLapIndex = Math.max(common.tracksManager.getRaceManager().getPlayerLapsAmount(), 0);
        }
    }

    setStartingLastLapTime() {
        this.textLastLapTimeIndicator.text = `--:--.--`;
    }

    showTime(time: number) {
        const m = String(Math.floor(time / 60)).padStart(2, "0");
        const s = String(Math.floor(time) % 60).padStart(2, "0");
        const ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
        const timeString = `${m}:${s}.${ms}`;
        if (common.watchController) common.watchController.updateTime(timeString);
        this.textTimeIndicator.text = timeString;
    }

    update(dt: number) {
        if (this.running) {
            if (this.duration == null) {
                this.time += dt;
            } else {
                this.time -= dt;
                this.time = Math.max(0, this.time);

                if (common.gameConfig.mode == GameMode.Tag) {
                    if (!this.tagAlmostOverAlertDone) {
                        const tagAlmostOverAlert = 20;
                        if (this.time < tagAlmostOverAlert) {
                            this.tagAlmostOverAlertDone = true;

                            (this.textTimeIndicator.material as TextMaterial).color = this.tagAlmostOverColor;
                            this.tagAlmostOverAlertAudio!.play();
                        }
                    }
                }
            }

            if (this.updateTimer) {
                this.showTime(this.time);
            }
        }
    }
}