import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { Gender } from "src/hoverfit/data/values/gender.js";
import { getNonDecorationObjectByName, isDecorationName } from "src/hoverfit/misc/asset-loading.js";
import { ItemCategory } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { getBuiltInItemIDByIndex, getGenderFilteredBuiltInItemIDByIndex } from "src/hoverfit/misc/asset-provision/built-in-asset-provider.js";
import { quat2_create, vec3_create } from "wle-pp";
import { GameGlobals } from "../../misc/game-globals.js";
import { AvatarFaceComponent } from "./avatar-face-component.js";
import { HoverfitTwoJointIkSolverComponent } from "./hoverfit-two-joint-ik-solver-component.js";
import { IKBridgeComponent } from "./ikbridge-component.js";

export let AVATAR_FORWARD_OFFSET_FROM_HEAD = -0.15;

let tempVec = vec3_create();

export class AvatarComponent extends Component {
    static TypeName = "avatar";
    static Properties = {
        anyRoot: Property.object(null),
        headShrink: Property.bool(false),
        head: Property.object(null),
        rightFootTarget: Property.object(null),
        leftFootTarget: Property.object(null),
        currentPlayer: Property.bool(false),
        isDisplayAvatar: Property.bool(false)
    };

    static onRegister(engine) {
        engine.registerComponent(HoverfitTwoJointIkSolverComponent);
        engine.registerComponent(IKBridgeComponent);
        engine.registerComponent(AvatarFaceComponent);
    }

    init() {
        this.ikSolverList = [];
        this.ready = false;

        this.avatarObject = getNonDecorationObjectByName(this.object, "Avatar");

        this.headShrinked = false;

        this.avatarEquipSetupDone = false;

        this.currentAvatarType = Gender.Female;
        this.skinColorKey = getBuiltInItemIDByIndex(ItemCategory.Skin, 0);
        this.suitVariant = getBuiltInItemIDByIndex(ItemCategory.Suit, 0);
        this.hairColor = getBuiltInItemIDByIndex(ItemCategory.HairColor, 0);

        this.headwearVariantMale = getGenderFilteredBuiltInItemIDByIndex(ItemCategory.Headwear, 0, Gender.Male);
        this.headwearVariantFemale = getGenderFilteredBuiltInItemIDByIndex(ItemCategory.Headwear, 0, Gender.Female);

        this.hipsInitialTransformLocalQuat = quat2_create();
    }

    start() {
        // HACK this shouldn't be here, but i added it anyway, otherwise there
        //      is an update order issue specifically on the canyon-long map,
        //      where update is not called before avatar.active is set to false,
        //      causing a data race where initIK is called too late
        this.initIK();
    }

    isReady() {
        return this.ready;
    }

    update(dt) {
        if (!this.isReady()) {
            this.initIK();
        }

        if (this.headShrink && !this.headShrinked && this.ikb != null && this.ikb.isReady()) {
            this.headShrinked = true;
            let avHead = this.bones["b_head"];
            if (!avHead) avHead = this.bones["b_Head"];
            if (avHead) {
                tempVec[0] = 0.01; // shrink X
                tempVec[1] = 0.01; // shrink Z
                tempVec[2] = 0.01; // shrink Z
                avHead.parent.setScalingLocal(tempVec);
            }
        }

        //this.object.pp_rotateLocalDegrees([0, 30 * dt, 0]);
    }

    setHoverboardStanceActive(active) {
        this.footL.pp_resetTransform();
        this.footR.pp_resetTransform();

        let hoverboardStanceEnabled = false; // for now we don't want it to actually be set
        let footAngle = 0;
        let kneeAngle = 0;
        if (active && hoverboardStanceEnabled) {
            this.footL.pp_setPositionLocal(vec3_create(0.125, 0, 0.3 + AVATAR_FORWARD_OFFSET_FROM_HEAD));
            this.footR.pp_setPositionLocal(vec3_create(-0.125, 0, -0.3 + AVATAR_FORWARD_OFFSET_FROM_HEAD));

            footAngle = 0;
            kneeAngle = footAngle + 7.5;
        } else {
            footAngle = 5;
            kneeAngle = footAngle + 7.5;

            this.footL.pp_setPositionLocal(vec3_create(0.125, 0, AVATAR_FORWARD_OFFSET_FROM_HEAD));
            this.footR.pp_setPositionLocal(vec3_create(-0.125, 0, AVATAR_FORWARD_OFFSET_FROM_HEAD));
        }

        let upLeftFootWorld = this.bones["b_LeftFoot"].pp_convertDirectionWorldToObject(GameGlobals.up);
        this.footIKL.extraEndRotationEnabled = true;
        this.footIKL.extraEndRotationQuat.quat_identity();
        this.footIKL.extraEndRotationQuat.quat_rotateAxis(footAngle, upLeftFootWorld, this.footIKL.extraEndRotationQuat);

        let upRightFootWorld = this.bones["b_RightFoot"].pp_convertDirectionWorldToObject(GameGlobals.up);
        this.footIKR.extraEndRotationEnabled = true;
        this.footIKR.extraEndRotationQuat.quat_identity();
        this.footIKR.extraEndRotationQuat.quat_rotateAxis(-footAngle, upRightFootWorld, this.footIKR.extraEndRotationQuat);

        let leftKneeHelperDirection = GameGlobals.forward.vec3_negate().vec3_rotateAxis(-kneeAngle, GameGlobals.up);
        this.leftKneeHelper.setPositionLocal(leftKneeHelperDirection);

        let rightKneeHelperDirection = GameGlobals.forward.vec3_negate().vec3_rotateAxis(kneeAngle, GameGlobals.up);
        this.rightKneeHelper.setPositionLocal(rightKneeHelperDirection);
    }

    setForcedSyncForwardWithHead(forced) {
        if (!this.isReady()) {
            this.initIK();
        }

        if (this.ikb != null) {
            this.ikb.forcedSyncForwardWithHead = forced;
        }
    }

    initIK() {
        if (this.isDisplayAvatar) {
            this.avatarRoot = this.object;
            this.scanAvatarMesh();

            let bones = {};
            this.scanBones(this.object, bones);
            this.bones = bones;
            this.addFaceComponent();

            this.ready = true;
            this.active = false;

            this.initializeHelmetSlot();
        }

        if (!this.isReady()) {
            this.avatarRoot = this.object;
            this.scanAvatarMesh();
            this.scanAvatarBones(this.object);

            this.initializeHelmetSlot();

            this.addIKBridge();

            //IKB.bTestAnims = true; /// De-comment this line to activate animated  IK target movements for testing

            this.ready = true;
        }
    }

    scanAvatarMesh() {
        if (this.avatarObject == null) {
            this.avatarObject = getNonDecorationObjectByName(this.object, "Avatar");
        }

        this.bodyObject = getNonDecorationObjectByName(this.avatarObject, "Body");
        this.eyesObject = getNonDecorationObjectByName(this.avatarObject, "Eyes");
        this.suitObject = getNonDecorationObjectByName(this.avatarObject, "Suit");
    }

    setClonedMaterials() {
        const body = this.bodyObject;
        const bodyMesh = body.getComponent(MeshComponent, 0);
        bodyMesh.material = bodyMesh.material.clone();
        const bodyHairMesh = body.getComponent(MeshComponent, 2);
        bodyHairMesh.material = bodyHairMesh.material.clone();

        const suit = this.suitObject;
        const suitMesh = suit.getComponent(MeshComponent, 0);
        suitMesh.material = suitMesh.material.clone();
    }

    scanAvatarBones(root) {
        let bones = {};
        this.scanBones(root, bones);
        this.bones = bones;
        this.addIK(root, root);

        this.bones["b_Hips"].pp_getTransformLocalQuat(this.hipsInitialTransformLocalQuat);
    }

    scanBones(root, b) {
        let rname = root.name;

        // XXX ignore anything that's part of a decoration, otherwise we're
        //     gonna have a really bad time if the decoration happens to have an
        //     object with bones
        if (isDecorationName(rname)) return;

        if (rname) {
            rname = rname.replace("Bind_", "b_");
            rname = rname.replace("mixamorig:", "b_");

            b[rname] = root;
        }
        for (const C of root.children) {
            this.scanBones(C, b);
        }
    }

    initializeHelmetSlot() {
        const headBone = this.bones ? this.bones["b_Head"] : getNonDecorationObjectByName(this.avatarObject, "mixamorig:Head");
        this.helmetObject = this.engine.scene.addObject();
        this.helmetObject.name = "Helmet";
        this.helmetObject.addComponent(MeshComponent);
        this.helmetObject.addComponent(MeshComponent);
        this.helmetObject.parent = headBone;
    }

    getHelmetObject() {
        return this.helmetObject;
    }


    addFaceComponent() {
        // To be called only after bones have been scanned
        if (!this.faceComponent) {
            this.faceComponent = this.object.addComponent(AvatarFaceComponent, {
                lowerJaw: this.bones["lowerJaw"],
                upperEyeLidLeft: this.bones["upperEyelid.left"],
                upperEyeLidRight: this.bones["upperEyelid.right"]
            });
        }

        return this.faceComponent;
    }

    addIKBridge() {
        this.ikb = this.object.addComponent(IKBridgeComponent, { head: this.head, currentPlayer: this.currentPlayer });

        for (let ikSolver of this.ikSolverList) {
            this.ikb.addIKSolver(ikSolver);
        }
    }

    addIK(rootObj, root) {
        // ** CREATE IK TARGETS
        // create left hand IK target
        let handL = this.engine.scene.addObject();
        handL.name = "handL";
        handL.pp_translateObject(vec3_create(1, 0, 2));

        // create right hand IK target
        let handR = this.engine.scene.addObject();
        handR.name = "handR";
        handR.pp_translateObject(vec3_create(-1, 0.1, 0));

        let footRoot = rootObj;
        if (this.anyRoot) {
            footRoot = this.anyRoot;
        } else {
            let anyBoard = this.bones["Armature"].parent;//pp_getObjectByNameHierarchy("hoverboard")
            if (anyBoard) {
                footRoot = anyBoard;
            }
        }

        if (this.head == null) {
            this.head = getNonDecorationObjectByName(this.object, "Head");
        }

        // create left foot IK target
        if (this.leftFootTarget) {
            this.footL = this.leftFootTarget;
        } else {
            this.footL = getNonDecorationObjectByName(this.object, "Foot Left Target");
            if (this.footL == null) {
                this.footL = this.engine.scene.addObject(footRoot);

            }
        }

        // create right foot IK target
        if (this.rightFootTarget) {
            this.footR = this.rightFootTarget;
        } else {
            // TODO: Get foot IK target points on hoverboard and pass it into avatar

            this.footR = getNonDecorationObjectByName(this.object, "Foot Right Target");
            if (this.footR == null) {
                this.footR = this.engine.scene.addObject(footRoot);

            }
        }

        // set direct access to the IK targets
        this.handL = handL;
        this.handR = handR;

        // create IK solver for left arm
        //this.bones["b_LeftForeArm"].pp_setRotationLocalDegrees([-90, 0, 0]);
        this.handIKL = this.addIKsolver(rootObj, this.bones["b_LeftArm"], this.bones["b_LeftForeArm"], this.bones["b_LeftHand"], handL, true);
        //this.handIKL.bBend = true;

        // create IK solver for right arm
        //this.bones["b_RightForeArm"].pp_setRotationLocalDegrees([-90, 0, 0]);
        this.handIKR = this.addIKsolver(rootObj, this.bones["b_RightArm"], this.bones["b_RightForeArm"], this.bones["b_RightHand"], handR, true);
        //this.handIKR.bBend = true;

        // Create a KneeHelper to help legs bend in forward plane
        let hips = this.bones["b_Hips"];

        this.leftKneeHelper = hips.pp_addChild();
        this.leftKneeHelper.setPositionLocal(GameGlobals.left.vec3_scale(0).vec3_add(GameGlobals.forward.vec3_scale(-4)));

        this.rightKneeHelper = hips.pp_addChild();
        this.rightKneeHelper.setPositionLocal(GameGlobals.left.vec3_scale(0).vec3_add(GameGlobals.forward.vec3_scale(-4)));

        // create IK solver for left leg
        //this.bones["b_LeftLeg"].pp_setRotationLocalDegrees([-90, 0, 0]);
        this.footIKL = this.addIKsolver(rootObj, this.bones["b_LeftUpLeg"], this.bones["b_LeftLeg"], this.bones["b_LeftFoot"], this.footL, false);
        this.footIKL.helper = this.leftKneeHelper;

        // create IK solver for right leg
        //this.bones["b_RightLeg"].pp_setRotationLocalDegrees([-90, 0, 0]);
        this.footIKR = this.addIKsolver(rootObj, this.bones["b_RightUpLeg"], this.bones["b_RightLeg"], this.bones["b_RightFoot"], this.footR, false);
        this.footIKR.helper = this.rightKneeHelper;

        //this.footIKR.bBend = true;/**/

        this.setHoverboardStanceActive(false);

    }

    addIKsolver(obj, R, M, E, T, copyTargetRotation, minGapTime) {
        let iks = obj.addComponent(HoverfitTwoJointIkSolverComponent, {
            root: R, middle: M, end: E, target: T, copyTargetRotation,
            // HACK need to do this because wonderland engine freaks out when
            //      you specify undefined as the value; instead of treating it
            //      as if no value were passed, it sets the actual value to
            //      undefined (which will be coerced to 0 in this case)
            minGapTime: minGapTime ?? HoverfitTwoJointIkSolverComponent.Properties.minGapTime.default,
        });

        this.ikSolverList.push(iks);

        return iks;
    }
}
