import { Object3D } from "@wonderlandengine/api";
import { common } from "src/hoverfit/common.js";
import { InvincibilityStatusEffect, InvincibilityStatusEffectParams } from "src/hoverfit/game/hoverboard/status-effects/implementations/invincibility-status-effect.js";
import { StatusEffectTarget } from "src/hoverfit/game/hoverboard/status-effects/status-effect-target.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupInvincibilityParams extends SyncedPickupParams { }

export class PickupInvincibility extends SyncedPickup {
    protected _params: PickupInvincibilityParams;

    constructor(pickupObject: Object3D, params: PickupInvincibilityParams) {
        super(PickupType.Invincibility, pickupObject, params);

        this._params = params;
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

        let statusEffect = null;

        const statusEffectParams = new InvincibilityStatusEffectParams();
        statusEffect = new InvincibilityStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);

        statusEffectsManager.addStatusEffect(statusEffect);
    }
}