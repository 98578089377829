import { Component, Object3D, PhysXComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { EnvironmentalSoundActor } from "../environmental-sound-actor.js";

export class EnvironmentalSoundActorComponent extends Component {
    static TypeName = "environmental-sound-actor";

    @property.object()
    public disableWhenInsideArea: Object3D | null = null;

    private actor!: EnvironmentalSoundActor;

    init() {
        this.actor = new EnvironmentalSoundActor(this.object, this.disableWhenInsideArea?.pp_getComponent(PhysXComponent) ?? null);
    }

    getActor() {
        return this.actor;
    }
}