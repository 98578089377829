<?xml version="1.0" encoding="UTF-8"?>
<ui-tree name="default" xmlns="lazy-widgets" xmlns:option="lazy-widgets:options" xmlns:on="lazy-widgets:on">
    <rounded-corners option:rounded-corners-radii="@20">
        <layered-container option:min-width="@570" option:max-width="@570" option:min-height="@306" option:max-height="@306" >
            <layer>
                <event-filter option:enable-filter="@false" option:id="main-event-filter">
                    <kiosk-background>
                        <column option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                            <container option:container-padding='@{"left":20,"right":20,"top":8,"bottom":8}' option:flex="@1" option:flex-shrink="@1">
                                <book option:id="kiosk-book">
                                    <column option:flex="@1" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                        <row>
                                            <spacing option:min-width="@8"  option:max-width="@8"/>
                                            <award-row award-type="medal" award-label="DAILY MEDALS" bronze-var="$bronzeMedals" silver-var="$silverMedals" gold-var="$goldMedals" platinum-var="$platinumMedals"/>
                                            <spacing option:min-width="@16"/>
                                            <column option:multi-container-alignment='@{"main":0,"cross":0.5}' option:multi-container-spacing="@4" option:min-width="@135" option:max-width="@135">
                                                <label option:body-text-align="@0.5">LIFETIME FIT POINTS</label>
                                                <back-pane>
                                                    <row>
                                                        <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                        <live-label text-source="$lifetimeFitPoints" option:body-text-font="1.0em sui-generis" option:body-text-align="@1"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/fitpoints.svg" option:width="@15" option:height="@15"/>
                                                        <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                    </row>
                                                </back-pane>
                                                <label option:body-text-align="@0.5">DAILY FIT POINTS</label>
                                                <back-pane>
                                                    <row>
                                                        <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                        <live-label text-source="$dailyFitPoints" option:body-text-font="1.0em sui-generis" option:body-text-align="@1"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/daily-fitpoints.svg" option:width="@15" option:height="@15"/>
                                                        <spacing option:min-width="@0" option:min-height="@0" option:flex="@1"/>
                                                    </row>
                                                </back-pane>
                                            </column>
                                            <container option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":5}' option:container-alignment='@{"horizontal":0,"vertical":1}'>
                                                <icon-decorated-button option:id="rewards-legend-button" option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.2)" option:hold-background-fill="rgba(0, 0, 0, 0.4)" option:max-width="@13" option:max-height="@13" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                                    <icon image="assets/textures/ui/icons/kiosk/info.svg" option:width="@20" option:height="@20"/>
                                                </icon-decorated-button>
                                            </container>
                                            <award-row award-type="trophy" award-label="DAILY TROPHIES" bronze-var="$bronzeTrophies" silver-var="$silverTrophies" gold-var="$goldTrophies" platinum-var="$platinumTrophies"/>
                                            <spacing option:min-width="@8"  option:max-width="@8"/>
                                        </row>
                                        <spacing/>
                                        <row>
                                            <lifetime-level-display option:id="helmetLevelDisplay" type="@0"/>
                                            <lifetime-level-display option:id="suitLevelDisplay" type="@1"/>
                                            <lifetime-level-display option:id="boardLevelDisplay" type="@2"/>
                                        </row>
                                    </column>
                                    <container option:container-padding='@{"left":4,"right":4,"top":0,"bottom":0}'>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                            <container option:container-padding='@{"left":10,"right":10,"top":10,"bottom":10}'>
                                                <row option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@0">
                                                    <label option:id="multiplayer-title" option:body-text-align="@0.5" option:body-text-font="2em sui-generis">START MULTIPLAYER</label>
                                                </row>
                                            </container>
                                            <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}' option:multi-container-spacing="@32" option:flex="@1" option:min-height="@212" option:max-height="@212">
                                                <column option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8" >
                                                    <!-- XXX this is just for counter-balancing the space taken by the right panel title label -->
                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis"> </label>
                                                    <spacing/>
                                                    <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                                        <book option:id="multiplayer-left-panel-book">
                                                            <column option:multi-container-spacing="@10" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                <decorated-button on:click="$quickPlay" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">QUICK PLAY</decorated-button>
                                                                <decorated-button option:id="hostRoomButton" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">HOST ROOM</decorated-button>
                                                                <decorated-button option:id="joinRoomButton" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">JOIN ROOM</decorated-button>
                                                            </column>
                                                            <column option:multi-container-spacing="@10" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                <decorated-button option:id="toTrackButtonMP" on:click="$goToTrack" option:font-size="@1.5" option:font-family="sui-generis-mono-digits" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48"></decorated-button>
                                                                <decorated-button on:click="$shareRoom" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">SHARE ROOM</decorated-button>
                                                                <decorated-button on:click="$disconnect" option:font-size="@1.5" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48">DISCONNECT</decorated-button>
                                                            </column>
                                                        </book>
                                                    </container>
                                                    <spacing/>
                                                </column>
                                                <book option:id="multiplayer-right-panel-book" option:flex="@1">
                                                    <column option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8" option:min-width="@350" option:max-width="@350">
                                                        <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">ENTER ROOM ID</label>
                                                        <back-pane option:flex="@1" option:container-padding='@{"left":10,"right":10,"top":15,"bottom":15}'>
                                                            <column option:flex="@1" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                <spacing/>
                                                                <row option:flex="@1" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8">
                                                                    <spacing option:min-width="@45" option:max-width="@45" option:min-height="@22" option:max-height="@22"/>
                                                                    <rounded-corners option:min-width="@80" option:max-width="@80" option:min-height="@22" option:max-height="@22" option:rounded-corners-radii="@6" option:container-padding='@{"left": 4, "right": 4, "top": 4, "bottom": 4}'>
                                                                        <background option:canvas-fill="white" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'>
                                                                            <live-label text-source="$hostJoinRoomID" option:body-text-align="@0.5" option:body-text-fill="black" option:body-text-font="1.25em sui-generis"/>
                                                                        </background>
                                                                    </rounded-corners>
                                                                    <column option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:min-width="@45" option:max-width="@45" option:min-height="@22" option:max-height="@22">
                                                                        <decorated-label-checkbox option:id="hostRoomPrivateCheckbox" variable="$privateRoomVar" option:ticked-label="PRIVATE" option:unticked-label="PUBLIC" option:font-size="0.65" option:min-width="@45" option:max-width="@45" option:min-height="@17" option:max-height="@17" option:unhover-background-fill="rgba(20, 20, 20, 0.25)" option:hover-background-fill="rgba(20, 20, 20, 0.35)" option:hold-background-fill="rgba(0, 90, 180, 0.55)"/>
                                                                    </column>
                                                                </row>
                                                                <spacing option:min-height="@4" option:max-height="@4"/>
                                                                <row option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                    <numpad option:id="doRoomHostButton" variable="$hostJoinRoomID" option:button-width="@60" option:button-height="@20" option:button-spacing="@8" option:submit-label="HOST" option:submit-callback="$hostRoom"/>
                                                                </row>
                                                                <spacing/>
                                                            </column>
                                                        </back-pane>
                                                    </column>
                                                    <column option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8" option:min-width="@350" option:max-width="@350">
                                                        <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">ENTER ROOM ID</label>
                                                        <back-pane option:flex="@1" option:container-padding='@{"left":10,"right":10,"top":15,"bottom":15}'>
                                                            <column option:flex="@1" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                <spacing/>
                                                                <row option:flex="@1" option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8">
                                                                    <rounded-corners option:min-width="@80" option:max-width="@80" option:min-height="@22" option:max-height="@22" option:rounded-corners-radii="@6" option:container-padding='@{"left": 4, "right": 4, "top": 4, "bottom": 4}'>
                                                                        <background option:canvas-fill="white" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'>
                                                                            <live-label text-source="$hostJoinRoomID" option:body-text-align="@0.5" option:body-text-fill="black" option:body-text-font="1.25em sui-generis"/>
                                                                        </background>
                                                                    </rounded-corners>
                                                                </row>
                                                                <spacing option:min-height="@4" option:max-height="@4"/>
                                                                <row option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}'>
                                                                    <numpad option:id="doRoomJoinButton" variable="$hostJoinRoomID" option:button-width="@60" option:button-height="@20" option:button-spacing="@8" option:submit-label="JOIN" option:submit-callback="$joinRoom"/>
                                                                </row>
                                                                <spacing/>
                                                            </column>
                                                        </back-pane>
                                                    </column>
                                                    <column option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@8" option:min-width="@350" option:max-width="@350" >
                                                        <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">PLAYERS</label>
                                                        <back-pane option:flex="@1" option:container-padding='@{"left":10,"right":5,"top":10,"bottom":10}'>
                                                            <container option:flex="@1">
                                                                <player-list option:flex="@1"/>
                                                            </container>
                                                        </back-pane>
                                                    </column>
                                                </book>
                                            </row>
                                        </column>
                                    </container>
                                    <container option:container-padding='@{"left":4,"right":4,"top":0,"bottom":0}'>
                                        <column option:flex="@1">
                                            <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                                <center>
                                                    <carousel option:slide-speed-percent="@0" option:min-width="@430" option:max-width="@430">
                                                        <row option:id="location-row">
                                                            <spacing option:flex="@0"/>
                                                            <!-- XXX filled in at runtime -->
                                                        </row>
                                                    </carousel>
                                                </center>
                                                <center>
                                                    <carousel option:slide-speed-percent="@0">
                                                        <row>
                                                            <spacing option:flex="@0"/>
                                                            <option-button option:id="race-mode-button" value="race" variable="$mode" option:has-border="@true" option:force-stroke-thickness="@6" option:min-width="@80" option:max-width="@80" option:min-height="@20" option:max-height="@20">RACE</option-button>
                                                            <option-button option:id="tag-mode-button" value="tag" variable="$mode" option:has-border="@true" option:force-stroke-thickness="@6" option:min-width="@80" option:max-width="@80" option:min-height="@20" option:max-height="@20">TAG</option-button>
                                                            <option-button option:id="roam-mode-button" value="roam" variable="$mode" option:has-border="@true" option:force-stroke-thickness="@6" option:min-width="@80" option:max-width="@80" option:min-height="@20" option:max-height="@20">ROAM</option-button>
                                                        </row>
                                                    </carousel>
                                                </center>
                                                <row option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:flex="@1">
                                                    <!-- XXX this is just for counter-balancing the space taken by the "tutorial" button -->
                                                    <spacing option:min-width="@100" option:max-width="@100" option:min-height="@30" option:max-height="@30"/>
                                                    <spacing option:min-width="@5" option:max-width="@5"/>
                                                    <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <decorated-button option:id="toTrackButton" on:click="$goToTrack" option:font-size="@1.5" option:font-family="sui-generis-mono-digits" option:min-width="@160" option:max-width="@160" option:min-height="@48" option:max-height="@48"></decorated-button>
                                                    </column>
                                                    <spacing option:min-width="@5" option:max-width="@5"/>
                                                    <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <decorated-button option:id="tutorial-button" option:has-border="@true" option:force-stroke-thickness="@6" option:min-width="@100" option:max-width="@100" option:min-height="@30" option:max-height="@30" on:click="$toggleTutorial" option:font-size="@1.25">TUTORIAL</decorated-button>
                                                    </column>
                                                </row>
                                                <back-pane option:flex="@1">
                                                    <container option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                        <row option:multi-container-alignment='@{"main":"between","cross":"stretch"}'>
                                                            <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                                <stepper-input option:id="trackInput" option:value-width="@100" option:value-height="@36" option:labels="$trackLabels" variable="$track" min-value="@0" max-value="$trackMax">TRACK</stepper-input>
                                                            </column>
                                                            <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                                <stepper-input option:id="npcsAmountInput" option:value-width="@72" option:icon="assets/textures/ui/icons/kiosk/robot.svg" option:icon-width="@17.28" option:icon-height="@12" variable="$npcsAmount" min-value="@0" max-value="@5">CPU RACERS</stepper-input>
                                                                <stepper-input option:id="npcsDifficultyInput" option:value-width="@72" option:icon="assets/textures/ui/icons/kiosk/robot.svg" option:icon-width="@17.28" option:icon-height="@12" option:labels='@["Very Easy","Easy","Moderate","Hard","Very Hard"]' variable="$npcsDifficulty" min-value="@0" max-value="@4">CPU DIFFICULTY</stepper-input>
                                                            </column>
                                                            <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}' option:multi-container-spacing="@0" option:min-width="@150" option:max-width="@150">
                                                                <stepper-input option:id="lapsAmountInput" option:value-width="@100" option:value-height="@36" variable="$lapsAmount" min-value="@1" max-value="@50">LAPS</stepper-input>
                                                                <time-stepper-input option:id="tagDurationInput" option:value-width="@100" option:value-height="@36" variable="$tagDuration" min-value="@30" max-value="@1200">TAG DURATION</time-stepper-input>
                                                            </column>
                                                        </row>
                                                    </container>
                                                </back-pane>
                                            </column>
                                        </column>
                                    </container>
                                    <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' >
                                            <container option:container-padding='@{"left":8,"right":8,"top":0,"bottom":1}'>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <customise-tab-button variable="$customPage" value="@0">SKIN TONE</customise-tab-button>
                                                        <customise-tab-button variable="$customPage" value="@1">BOARD</customise-tab-button>
                                                        <customise-tab-button variable="$customPage" value="@2">SUIT</customise-tab-button>
                                                        <customise-tab-button variable="$customPage" value="@3">HAIR</customise-tab-button>
                                                        <customise-tab-button variable="$customPage" value="@4">HELMET</customise-tab-button>
                                                    </row>
                                                    <center>
                                                        <separator option:body-text-fill="rgba(120, 120, 120, 0.25)"/>
                                                    </center>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <customise-gender-button variable="$gender" gender="@0" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                        <customise-gender-button variable="$gender" gender="@1" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                    </row>
                                                </row>
                                            </container>
                                            <!-- XXX just to provide extra blank space between top tabs and items. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                                            <spacing option:flex="@0"/>
                                            <container option:container-padding='@{"left":17,"right":15,"top":0,"bottom":0}'>
                                                <book option:id="custom-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                    <kiosk-item-grid option:empty-label="No skin colors&#xA;&#xA;Visit the SHOP for options" item-generator="$skinColorCustomItemGenerator" collection="$inventory" filter="$skinItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                    <kiosk-item-grid option:empty-label="No hoverboards&#xA;&#xA;Visit the SHOP for options" item-generator="$hoverboardCustomItemGenerator" collection="$inventory" filter="$hoverboardItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                    <kiosk-item-grid option:empty-label="No suits&#xA;&#xA;Visit the SHOP for options" item-generator="$suitCustomItemGenerator" collection="$inventory" filter="$suitItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                    <book option:id="hair-inventory-book">
                                                        <kiosk-item-grid option:empty-label="No hair colors&#xA;&#xA;Visit the SHOP for options" item-generator="$hairColorCustomItemGenerator" collection="$inventory" filter="$hairColorItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                        <kiosk-item-grid option:empty-label="No male hair styles&#xA;&#xA;Visit the SHOP for options" option:id="hair-style-inventory-male" item-generator="$headwearCustomItemGenerator" collection="$inventory" filter="$hairItemFilterMale" option:min-height="@200" option:max-height="@200"/>
                                                        <kiosk-item-grid option:empty-label="No female hair styles&#xA;&#xA;Visit the SHOP for options" option:id="hair-style-inventory-female" item-generator="$headwearCustomItemGenerator" collection="$inventory" filter="$hairItemFilterFemale" option:min-height="@200" option:max-height="@200"/>
                                                    </book>
                                                    <kiosk-item-grid option:empty-label="No helmets&#xA;&#xA;Visit the SHOP for options" item-generator="$headwearCustomItemGenerator" collection="$inventory" filter="$helmetItemFilter" option:min-height="@200" option:max-height="@200"/>
                                                </book>
                                            </container>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                <decorated-button on:click="$equip" option:click-audio-id-override="BUTTON_EQUIP" option:min-width="@80" option:max-width="@80" option:min-height="@25" option:max-height="@25">EQUIP</decorated-button>
                                                <book option:id="pick-mode-book" option:enabled="@false" option:min-width="@110" option:max-width="@110" option:min-height="@25" option:max-height="@25">
                                                    <decorated-button option:id="styleToggle">PICK STYLE</decorated-button>
                                                    <decorated-button option:id="hairToggle">PICK COLOR</decorated-button>
                                                </book>
                                            </row>
                                            <!-- XXX just to provide extra blank space at the bottom. space is occupied by multi-container-spacing; this spacing widget is 0-height -->
                                            <spacing option:flex="@0"/>
                                        </column>
                                    </container>
                                    <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":0.5}'>
                                            <container option:container-padding='@{"left":4,"right":4,"top":0,"bottom":0}'>
                                                <column>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <customise-tab-button variable="$shopPage" value="@0">BOARDS</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@1">SUITS</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@2">HAIR</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@3">HAIR COLORS</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@4">HELMETS</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@5">LOCATIONS</customise-tab-button>
                                                        <customise-tab-button variable="$shopPage" value="@6">BUNDLES</customise-tab-button>
                                                    </row>
                                                </column>
                                            </container>
                                            <spacing option:flex="@0"/>
                                            <container option:container-padding='@{"left":17,"right":10,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":"stretch"}' option:max-width="@570" option:max-height="@230">
                                                <book option:id="shop-or-item-book">
                                                    <carousel option:slide-speed-percent="@0" option:vertical="@true" option:loops="@false">
                                                        <book option:id="shop-book">
                                                            <!-- TODO make this layout better; maybe upgrade lazy-widgets and use flex-shrink? -->
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$hoverboardItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$suitItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            <book option:id="hair-shop-book">
                                                                <kiosk-item-grid option:empty-label="Coming soon..." option:id="hair-style-shop-male" item-generator="$makeShopItemButton" collection="$catalog" filter="$hairItemFilterMale" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                                <kiosk-item-grid option:empty-label="Coming soon..." option:id="hair-style-shop-female" item-generator="$makeShopItemButton" collection="$catalog" filter="$hairItemFilterFemale" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            </book>
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$hairColorItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$helmetItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$locationItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                            <kiosk-item-grid option:empty-label="Coming soon..." item-generator="$makeShopItemButton" collection="$catalog" filter="$bundleItemFilter" option:min-height="@230" option:max-height="@230"></kiosk-item-grid>
                                                        </book>
                                                    </carousel>
                                                    <container option:container-padding='@{"left":0,"right":8,"top":0,"bottom":0}'>
                                                        <back-pane>
                                                            <container>
                                                                <row>
                                                                    <column>
                                                                        <arrow-decorated-button option:id="itemBackButton" option:stroke-size="@0.035" direction="left"/>
                                                                    </column>
                                                                    <decorated-button-like-pane option:min-width="@270" option:max-width="@270" option:canvas-fill="rgba(0, 0, 0, 0.1)" option:looks-clickable="@0">
                                                                        <container option:container-padding='@{"left": 10, "right": 10, "top": 10, "bottom": 10}' >
                                                                            <label option:id="itemDescLabel" option:body-text-align="@0.5" option:body-text-font="1.25em sui-generis" option:body-text-spacing="@4"></label>
                                                                        </container>
                                                                    </decorated-button-like-pane>
                                                                    <container option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}' option:min-width="@160" option:max-width="@160">
                                                                        <column>
                                                                            <label option:body-text-align="@0.5" option:id="itemNameLabel" option:body-text-font="1.5em sui-generis" option:body-text-spacing="@4"></label>
                                                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                                                <rounded-corners option:container-padding='@{"left": 4, "right": 4, "top": 4, "bottom": 4}'>
                                                                                    <background option:min-width="@100" option:max-width="@100" option:min-height="@100" option:max-height="@100" option:canvas-fill="white" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}'>
                                                                                            <rounded-corners option:container-padding='@{"left": 4, "right": 4, "top": 4, "bottom": 4}'>
                                                                                                <background option:canvas-fill="#d9d9d9" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'  option:min-width="@92" option:max-width="@92" option:min-height="@92" option:max-height="@92">
                                                                                                    <icon option:id="itemThumbnail" image="assets/items/iap/hb_spider/thumbnail.webp"/>
                                                                                                </background>
                                                                                            </rounded-corners>
                                                                                        </row>
                                                                                    </background>
                                                                                </rounded-corners>
                                                                            </row>
                                                                            <row option:id="shop-gender-row">
                                                                                <spacing/>
                                                                                <customise-gender-button variable="$gender" on:click="$refreshShopItemPage" gender="@0" option:dark-mode="@false" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                                                <customise-gender-button variable="$gender" on:click="$refreshShopItemPage" gender="@1" option:dark-mode="@false" option:min-width="@24" option:max-width="@24" option:min-height="@24" option:max-height="@24"/>
                                                                                <spacing/>
                                                                            </row>
                                                                            <spacing option:id="shop-gender-placebo" option:enabled="@false" option:flex="@0" option:min-height="@16"/>
                                                                            <purchase-button option:id="purchaseItemButton" price="@0" currency="@0" option:unhover-background-fill="rgba(120, 120, 120, 0.35)"/>
                                                                        </column>
                                                                    </container>
                                                                </row>
                                                            </container>
                                                        </back-pane>
                                                    </container>
                                                </book>
                                            </container>
                                        </column>
                                    </container>
                                    <container option:container-padding='@{"left":4,"right":4,"top":0,"bottom":0}'>
                                        <column option:flex="@1" option:multi-container-alignment='@{"main":"between","cross":"stretch"}' >
                                            <container option:container-padding='@{"left":8,"right":8,"top":0,"bottom":1}'>
                                                <column>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                        <customise-tab-button variable="$settingPage" value="@0">AUDIO</customise-tab-button>
                                                        <customise-tab-button variable="$settingPage" value="@1">ABOUT</customise-tab-button>
                                                        <customise-tab-button variable="$settingPage" value="@2" option:id="settings-dev-button" >DEV</customise-tab-button>
                                                    </row>
                                                </column>
                                            </container>
                                            <spacing option:flex="@0"/>
                                            <container option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                                <book option:id="settings-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                    <container option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                        <container option:container-padding='@{"left":0,"right":0,"top":20,"bottom":20}'>
                                                            <row option:multi-container-spacing="@25" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                                <column option:multi-container-spacing="@8" option:flex="@1" option:flex-basis="@0" option:min-width="@238.5" option:max-width="@238.5" option:min-height="@193" option:max-height="@193">
                                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">GAME</label>
                                                                    <back-pane  option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":10,"bottom":10}'>
                                                                        <container option:flex="@1" option:container-padding='@{"left":15,"right":15,"top":10,"bottom":10}'>
                                                                            <column option:multi-container-spacing="@10" option:flex="@1" option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">MUSIC</label>
                                                                                    <stepper-bar option:flex="@1" min-value="@0" max-value="@10" variable="$musicVolume" option:arrow-size="@10" option:step-width="@2" option:step-spacing="@4" option:step-arrow-spacing="@5" option:step-disabled-color="rgba(20, 20, 20, 0.25)"/>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">QUIET MODE</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <decorated-checkbox variable="$quietMode" option:unhover-background-fill="rgba(20, 20, 20, 0.25)" option:hover-background-fill="rgba(20, 20, 20, 0.35)" option:hold-background-fill="rgba(0, 90, 180, 0.55)"/>
                                                                                    </row>
                                                                                </row>
                                                                            </column>
                                                                        </container>
                                                                    </back-pane>
                                                                </column>
                                                                <column option:multi-container-spacing="@8" option:flex="@1" option:flex-basis="@0" option:min-width="@238.5" option:max-width="@238.5" option:min-height="@193" option:max-height="@193">
                                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">VOICE CHAT</label>
                                                                    <back-pane option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":10,"bottom":10}'>
                                                                        <container option:flex="@1" option:container-padding='@{"left":15,"right":15,"top":10,"bottom":10}'>
                                                                            <column option:multi-container-spacing="@10" option:flex="@1" option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">VOICE</label>
                                                                                    <stepper-bar option:flex="@1" min-value="@0" max-value="@10" variable="$voiceVolume" option:arrow-size="@10" option:step-width="@2" option:step-spacing="@4" option:step-arrow-spacing="@5" option:step-disabled-color="rgba(20, 20, 20, 0.25)"/>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">MICROPHONE</label>
                                                                                    <book option:id="microphone-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$requestMicrophone" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">REQUEST</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$deniedMicrophone" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">DENIED</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$deniedPolicyMicrophone" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">DENIED</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$microphoneError" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">ERROR</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <spacing option:max-width="@10" option:min-width="@10" option:max-height="@9" option:min-height="@9"/>
                                                                                            <decorated-checkbox variable="$microphoneEnabled" option:unhover-background-fill="rgba(20, 20, 20, 0.25)" option:hover-background-fill="rgba(20, 20, 20, 0.35)" option:hold-background-fill="rgba(0, 90, 180, 0.55)"/>
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                                                                    <icon-decorated-button on:click="$microphoneInfo" option:min-width="@9" option:max-width="@9" option:min-height="@9" option:max-height="@9"  option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.2)" option:hold-background-fill="rgba(0, 0, 0, 0.4)" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}'>
                                                                                                        <icon image="assets/textures/ui/icons/kiosk/info.svg" option:width="@9" option:height="@9"/>
                                                                                                    </icon-decorated-button>
                                                                                                </column>
                                                                                            </container>
                                                                                        </row>
                                                                                    </book>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">PEER TO PEER</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <decorated-checkbox variable="$voiceP2P" option:unhover-background-fill="rgba(20, 20, 20, 0.25)" option:hover-background-fill="rgba(20, 20, 20, 0.35)" option:hold-background-fill="rgba(0, 90, 180, 0.55)"/>
                                                                                    </row>
                                                                                </row>
                                                                                <back-pane option:id="voip-error" option:enabled="@false" option:fill-color="rgba(20, 20, 20, 0.25)" option:flex="@1" option:container-padding='@{"left":4,"right":4,"top":5,"bottom":5}'>
                                                                                    <column option:multi-container-spacing="@0">
                                                                                        <label option:body-text-align="@0.5" option:body-text-fill="#ffc500" option:body-text-font="0.65em sui-generis">Voice chat is currently not available.&#xA;Please check your firewall settings or reload the page.</label>
                                                                                        <live-label text-source="$voipError" option:body-text-align="@0.5" option:body-text-fill="#ffc500" option:body-text-font="0.65em sui-generis"/>
                                                                                    </column>
                                                                                </back-pane>
                                                                            </column>
                                                                        </container>
                                                                    </back-pane>
                                                                </column>
                                                            </row>
                                                        </container>
                                                    </container>
                                                    <container option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                        <container option:container-padding='@{"left":0,"right":0,"top":20,"bottom":20}'>
                                                            <row option:multi-container-spacing="@25" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                                <column option:multi-container-spacing="@8" option:flex="@1" option:flex-basis="@0" option:min-width="@238.5" option:max-width="@238.5" option:min-height="@193" option:max-height="@193">
                                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">GAME</label>
                                                                    <back-pane option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":10,"bottom":10}'>
                                                                        <container option:flex="@1" option:container-padding='@{"left":15,"right":15,"top":10,"bottom":10}'>
                                                                            <column option:multi-container-spacing="@10" option:flex="@1" option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">TERMS AND CONDITIONS</label>
                                                                                    <book option:id="terms-and-conditions-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$showTermsAndConditions" option:min-width="@90" option:max-width="@90" option:min-height="@20" option:max-height="@20">ACCEPTED</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$showTermsAndConditions" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">DENIED</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                    </book>
                                                                                </row>
                                                                                <row option:id="subscribe-newsletter-setting" option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">NEWSLETTER</label>
                                                                                    <book option:id="subscribe-newsletter-setting-book" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$subscribeNewsletter" option:min-width="@90" option:max-width="@90" option:min-height="@20" option:max-height="@20">SUBSCRIBE</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                            <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                                <decorated-button on:click="$unsubscribeNewsletter" option:min-width="@90" option:max-width="@90" option:min-height="@20" option:max-height="@20">UNSUBSCRIBE</decorated-button>
                                                                                            </container>
                                                                                        </row>
                                                                                    </book>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">GAME VERSION</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                            <decorated-button on:click="$showBuildVersion" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">SHOW</decorated-button>
                                                                                        </container>
                                                                                    </row>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">LICENSES</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                            <decorated-button on:click="$openLicensesPage" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">OPEN</decorated-button>
                                                                                        </container>
                                                                                    </row>
                                                                                </row>
                                                                            </column>
                                                                        </container>
                                                                    </back-pane>
                                                                </column>
                                                                <column option:multi-container-spacing="@8" option:flex="@1" option:flex-basis="@0" option:min-width="@238.5" option:max-width="@238.5" option:min-height="@193" option:max-height="@193">
                                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">CREDITS</label>
                                                                    <back-pane option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":10,"bottom":10}'>
                                                                        <column option:flex="@1" option:flex-basis="@0">
                                                                            <label option:body-text-align="@0.5" option:body-text-font="1.35em sui-generis">Team HoverFit</label>
                                                                            <container option:flex="@1" option:container-padding='@{"left":10,"right":10,"top":0,"bottom":4}'>
                                                                                <scrollable-viewport-widget option:scrollbar-corners-radii="@3.25" option:scrollbar-padding='@{"left":0,"right":0,"top":4,"bottom":3}' option:width-coupling="@1" option:scrollbar-mode="@0" option:scroll-bar-thickness="@8" option:background-fill="rgba(0,0,0,0)" option:background-glow-fill="rgba(0,0,0,0)" option:primary-fill="rgba(255, 255, 255, 0.75)" option:accent-fill="rgba(255, 255, 255, 0.60)">
                                                                                    <container option:flex="@1" option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                                                        <credit-list option:credit-width="195"/>
                                                                                    </container>
                                                                                </scrollable-viewport-widget>
                                                                            </container>
                                                                        </column>
                                                                    </back-pane>
                                                                </column>
                                                            </row>
                                                        </container>
                                                    </container>
                                                    <container option:container-padding='@{"left":10,"right":10,"top":0,"bottom":0}'>
                                                        <container option:container-padding='@{"left":0,"right":0,"top":20,"bottom":20}'>
                                                            <row option:multi-container-spacing="@25" option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                                                                <column option:multi-container-spacing="@8" option:flex="@1" option:flex-basis="@0" option:min-width="@238.5" option:max-width="@238.5" option:min-height="@193" option:max-height="@193">
                                                                    <label option:body-text-align="@0.5" option:body-text-font="1.75em sui-generis">DEBUGS</label>
                                                                    <back-pane option:flex="@1" option:container-padding='@{"left":0,"right":0,"top":10,"bottom":10}'>
                                                                        <container option:flex="@1" option:container-padding='@{"left":15,"right":15,"top":10,"bottom":10}'>
                                                                            <column option:multi-container-spacing="@10" option:flex="@1" option:multi-container-alignment='@{"main":0,"cross":"stretch"}'>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">NAME</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                            <decorated-button on:click="$generateRandomName" option:min-width="@90" option:max-width="@90" option:min-height="@20" option:max-height="@20">RANDOMIZE</decorated-button>
                                                                                        </container>
                                                                                    </row>
                                                                                </row>
                                                                                <row option:multi-container-spacing="@5" option:min-height="@20" option:max-height="@20">
                                                                                    <label option:body-text-align="@0" option:body-text-font="1.05em sui-generis" option:min-width="@100" option:max-width="@100">VOIP MENU</label>
                                                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                                                                        <container option:container-padding='@{"left":1,"right":0,"top":0,"bottom":0}'>
                                                                                            <decorated-button on:click="$toggleVoipDebugMenu" option:min-width="@70" option:max-width="@70" option:min-height="@20" option:max-height="@20">TOGGLE</decorated-button>
                                                                                        </container>
                                                                                    </row>
                                                                                </row>
                                                                            </column>
                                                                        </container>
                                                                    </back-pane>
                                                                </column>
                                                            </row>
                                                        </container>
                                                    </container>
                                                </book>
                                            </container>
                                            <spacing option:flex="@0"/>
                                        </column>
                                    </container>
                                </book>
                            </container>
                            <row option:multi-container-alignment='@{"main":0.5,"cross":1}'>
                                <spacing option:min-width="@30" option:max-width="@30"/>
                                <spacing option:min-width="@10" option:max-width="@10"/>
                                <kiosk-tab-button variable="$kioskPage" value="@0" option:id="rewards-tab-button">REWARDS</kiosk-tab-button>
                                <kiosk-tab-button variable="$kioskPage" value="@1">MULTIPLAYER</kiosk-tab-button>
                                <kiosk-tab-button variable="$kioskPage" value="@2" option:min-width="@90" option:max-width="@90" option:min-height="@20" option:max-height="@20" option:body-text-font="0.9em sui-generis">PLAY</kiosk-tab-button>
                                <kiosk-tab-button variable="$kioskPage" value="@3">CUSTOMISE</kiosk-tab-button>
                                <kiosk-tab-button variable="$kioskPage" value="@4">SHOP</kiosk-tab-button>
                                <spacing option:min-width="@10" option:max-width="@10"/>
                                <kiosk-tab-icon-button icon="assets/textures/ui/icons/kiosk/cog.svg" variable="$kioskPage" value="@5"/>
                            </row>
                        </column>
                    </kiosk-background>
                </event-filter>
            </layer>
            <layer>
                <background option:canvas-fill="rgba(0,0,0,0.5)" option:id="popup-container" option:enabled="@false">
                    <column option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}'>
                        <center>
                            <book option:id="popup-book">
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                            <row>
                                                <spacing/>
                                                <label option:id="custom-popup-title" option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">TITLE</label>
                                                <spacing option:id="custom-popup-title-icon-spacing" option:flex="@0"/>
                                                <icon option:id="custom-popup-title-icon" image="assets/textures/ui/icons/kiosk/info.svg" option:width="@28" option:height="@28"/>
                                                <spacing/>
                                            </row>
                                            <spacing/>
                                            <label option:id="custom-popup-message" option:body-text-align="@0.5" option:body-text-font="1.1em sui-generis" option:max-width="@348">MESSAGE</label>
                                            <spacing/>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@10">
                                                <container option:id="custom-popup-low-priority-button-container" option:container-alignment='@{"horizontal":"0.5","vertical":0.5}' option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40" option:container-padding='@{"left":20,"right":10,"top":1.5,"bottom":0}'>
                                                    <decorated-button option:id="custom-popup-low-priority-button" option:font-size="@1.2" option:normal-stroke="#425d7d" option:unclickable-stroke="#425d7d" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@120" option:max-width="@120" option:min-height="@32" option:max-height="@32">LOW PRIORITY</decorated-button>
                                                </container>
                                                <decorated-button option:id="custom-popup-secondary-button" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">SECONDARY</decorated-button>
                                                <decorated-button option:id="custom-popup-primary-button" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">PRIMARY</decorated-button>
                                            </row>
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                        </column>
                                        <book option:id="custom-popup-close-button-book">
                                            <container option:min-width="@32" option:max-width="@32" option:container-padding='@{"left":0,"right":12,"top":12,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}'>
                                                <icon-decorated-button option:id="custom-popup-close-button" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}' option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.15)" option:hold-background-fill="rgba(0, 0, 0, 0.35)">
                                                    <icon image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                                </icon-decorated-button>
                                            </container>
                                            <spacing option:flex="@0" option:min-width="@32"/>
                                        </book>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@510" option:max-width="@510" option:min-height="@274" option:max-height="@274">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis" option:body-text-spacing="@0">TERMS AND CONDITIONS</label>
                                            <spacing/>
                                            <label option:body-text-align="@0.5" option:body-text-font="0.9em sui-generis" option:body-text-spacing="@4">While playing HoverFit, you can choose to join a public chat room.&#xA;Do not share any personal or sensitive information.&#xA;We are not storing any personal information as part of our&#xA;multiplayer service.</label>
                                            <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                                                <label option:body-text-font="0.9em sui-generis">Privacy policy:</label>
                                                <hyperlink on:click="$openPrivacyPolicy" option:disable-default-on-click="@true" option:body-text-font="0.9em sui-generis" text="$nicePolicyURL" url=""/>
                                            </row>
                                            <label option:body-text-align="@0.5" option:body-text-font="0.9em sui-generis" option:body-text-spacing="@4">By continuing, you agree that you are allowed to join public rooms,&#xA;for the storage to be used to save game related informations,&#xA;and accept the terms and conditions of the service.</label>
                                            <row option:multi-container-alignment='@{"main": 0.5, "cross": "stretch"}'>
                                                <label option:body-text-font="0.9em sui-generis">Terms and conditions:</label>
                                                <hyperlink on:click="$openTermsAndConditions" option:disable-default-on-click="@true" option:body-text-font="0.9em sui-generis" text="$niceTermsURL" url=""/>
                                            </row>
                                            <spacing/>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"0.5","cross":"stretch"}' option:multi-container-spacing="@24">
                                                <container option:container-alignment='@{"horizontal":"0.5","vertical":0.5}' option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40" option:container-padding='@{"left":20,"right":10,"top":1.5,"bottom":0}'>
                                                    <decorated-button on:click="$denyTermsAndConditions"  option:font-size="@1.2" option:normal-stroke="#425d7d" option:unclickable-stroke="#425d7d" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@120" option:max-width="@120" option:min-height="@32" option:max-height="@32">DENY</decorated-button>
                                                </container>
                                                <decorated-button on:click="$acceptTermsAndConditions" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">ACCEPT</decorated-button>
                                            </row>
                                            <spacing option:min-height="@16" option:max-height="@16"/>
                                        </column>
                                        <container option:min-width="@32" option:max-width="@32" option:container-padding='@{"left":0,"right":12,"top":12,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}'>
                                            <icon-decorated-button on:click="$closePopup" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}' option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.15)" option:hold-background-fill="rgba(0, 0, 0, 0.35)">
                                                <icon image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                            </icon-decorated-button>
                                        </container>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">CHANGING LOCATION</label>
                                            <spacing/>
                                            <label option:id="changing-scene-label" option:body-text-align="@0.5" option:body-text-spacing="@8" option:body-text-font="1.25em sui-generis-mono-digits">Switching To</label>
                                            <spacing/>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@10">
                                                <decorated-button option:id="changing-scene-cancel-button" on:click="$cancelConfigurationChange" option:unclickable-button-label-fill="rgb(64,64,64)" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CANCEL</decorated-button>
                                            </row>
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                        </column>
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing/>
                                            <label option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">CONNECTING...</label>
                                            <spacing/>
                                        </column>
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":1,"cross":"stretch"}' option:multi-container-spacing="@0">
                                        <spacing option:flex="@0" option:min-width="@32"/>
                                        <container option:container-padding='@{"left": 20, "right": 20, "top": 22, "bottom": 22}' option:min-width="@348" option:max-width="@348" option:max-height="@200">
                                            <column option:multi-container-alignment='@{"main":"around","cross":"stretch"}'>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label text="$fpPerBronzeMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/daily-fitpoints.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                    <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <icon image="assets/textures/ui/icons/kiosk/medal-bronze.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                </row>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label text="$fpPerSilverMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/daily-fitpoints.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                    <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <icon image="assets/textures/ui/icons/kiosk/medal-silver.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                </row>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label text="$fpPerGoldMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/daily-fitpoints.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                    <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <icon image="assets/textures/ui/icons/kiosk/medal-gold.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                </row>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label text="$fpPerPlatinumMedal" option:body-text-align="@1" option:min-width="@30" option:max-width="@30"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/daily-fitpoints.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                    <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <icon image="assets/textures/ui/icons/kiosk/medal-platinum.svg" option:width="@24" option:height="@24"/>
                                                    </row>
                                                </row>
                                                <spacing/>
                                                <row>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label text="$medalsPerTrophy" option:body-text-align="@1"/>
                                                        <icon image="assets/textures/ui/icons/kiosk/medals.svg" option:width="@72" option:height="@24"/>
                                                    </row>
                                                    <icon image="assets/textures/ui/icons/kiosk/rewards-legend-arrow.svg" option:width="@36" option:height="@18"/>
                                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:min-width="@100" option:max-width="@100">
                                                        <label option:body-text-align="@1">1x</label>
                                                        <icon image="assets/textures/ui/icons/kiosk/trophies.svg" option:width="@72" option:height="@24"/>
                                                    </row>
                                                </row>
                                            </column>
                                        </container>
                                        <container option:container-padding='@{"left":0,"right":12,"top":12,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}'>
                                            <icon-decorated-button option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.15)" option:hold-background-fill="rgba(0, 0, 0, 0.35)" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"stretch","vertical":0}' on:click="$closePopup">
                                                <icon image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                            </icon-decorated-button>
                                        </container>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                            <label  option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">CONFIRM PURCHASE</label>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}' option:multi-container-spacing="@16">
                                                <rounded-corners option:container-padding='@{"left": 2, "right": 2, "top": 2, "bottom": 2}'>
                                                    <background option:min-width="@50" option:max-width="@50" option:min-height="@50" option:max-height="@50" option:canvas-fill="white" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'>
                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}'>
                                                            <rounded-corners option:container-padding='@{"left": 2, "right": 2, "top": 2, "bottom": 2}'>
                                                                <background option:canvas-fill="#d9d9d9" option:container-padding='@{"left":0, "right": 0, "top": 0, "bottom": 0}' option:min-width="@46" option:max-width="@46" option:min-height="@46" option:max-height="@46">
                                                                    <icon image="$itemBuyConfirmImage"/>
                                                                </background>
                                                            </rounded-corners>
                                                        </row>
                                                    </background>
                                                </rounded-corners>
                                                <column option:multi-container-spacing="@6">
                                                    <label option:id="itemBuyConfirmLabel" option:body-text-align="@0.5" option:body-text-font="1.5em sui-generis" option:wrap-mode="ellipsis"></label>
                                                    <price-row price="@0" currency="@0" option:id="popup-price-row"/>
                                                </column>
                                            </row>
                                            <spacing/>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@10">
                                                <container  option:container-alignment='@{"horizontal":"0.5","vertical":0.5}' option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40" option:container-padding='@{"left":20,"right":10,"top":1.5,"bottom":0}'>
                                                    <decorated-button on:click="$closePopup" option:font-size="@1.2" option:normal-stroke="#425d7d" option:unclickable-stroke="#425d7d" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@120" option:max-width="@120" option:min-height="@32" option:max-height="@32">CANCEL</decorated-button>
                                                </container>
                                                <decorated-button option:id="purchaseItemConfirmButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CONFIRM</decorated-button>
                                            </row>
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                        </column>
                                        <container option:min-width="@32" option:max-width="@32" option:container-padding='@{"left":0,"right":12,"top":12,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}'>
                                            <icon-decorated-button on:click="$closePopup" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}' option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.15)" option:hold-background-fill="rgba(0, 0, 0, 0.35)">
                                                <icon image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                            </icon-decorated-button>
                                        </container>
                                    </row>
                                </background-9slice>
                                <background-9slice image="assets/textures/ui/9slice/purchasenotificationpanel-dark-compressed.png" option:horizontal-slice-percent="@0.492647058824" option:vertical-slice-percent="@0.45" option:horizontal-edge-pixels="@134" option:vertical-edge-pixels="@18" option:min-width="@420" option:max-width="@420" option:min-height="@200" option:max-height="@200">
                                    <row option:multi-container-alignment='@{"main":0.5,"cross":"stretch"}' option:flex="@1">
                                        <spacing option:flex="@0" option:min-width="@32" option:max-width="@32"/>
                                        <column option:flex="@1" option:flex-shrink="@1">
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                            <label  option:body-text-align="@0.5" option:body-text-font="2.4em sui-generis">JUST BOUGHT</label>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}' option:multi-container-spacing="@16">
                                                <rounded-corners option:container-padding='@{"left": 2, "right": 2, "top": 2, "bottom": 2}'>
                                                    <background option:min-width="@50" option:max-width="@50" option:min-height="@50" option:max-height="@50" option:canvas-fill="white" option:container-padding='@{"left": 0, "right": 0, "top": 0, "bottom": 0}'>
                                                        <row option:multi-container-alignment='@{"main":0.5,"cross":"0.5"}'>
                                                            <rounded-corners option:container-padding='@{"left": 2, "right": 2, "top": 2, "bottom": 2}'>
                                                                <background option:canvas-fill="#d9d9d9" option:container-padding='@{"left":0, "right": 0, "top": 0, "bottom": 0}' option:min-width="@46" option:max-width="@46" option:min-height="@46" option:max-height="@46">
                                                                    <icon image="$itemBuyConfirmImage"/>
                                                                </background>
                                                            </rounded-corners>
                                                        </row>
                                                    </background>
                                                </rounded-corners>
                                                <label option:id="itemPostPurchaseLabel" option:body-text-font="1.5em sui-generis"></label>
                                            </row>
                                            <spacing/>
                                            <spacing/>
                                            <row option:multi-container-alignment='@{"main":"around","cross":0.5}' option:multi-container-spacing="@10">
                                                <decorated-button on:click="$closePopup" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">CONTINUE</decorated-button>
                                                <decorated-button option:id="itemPostPurchaseEquipButton" option:font-size="@1.5" option:button-label-fill="white" option:normal-stroke="white" option:unclickable-stroke="white" option:unhover-background-fill="#587ba3" option:hover-background-fill="#6e8caf" option:hold-background-fill="#7799be" option:min-width="@150" option:max-width="@150" option:min-height="@40" option:max-height="@40">EQUIP</decorated-button>
                                            </row>
                                            <spacing option:min-height="@20" option:max-height="@20"/>
                                        </column>
                                        <container option:min-width="@32" option:max-width="@32" option:container-padding='@{"left":0,"right":12,"top":12,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}'>
                                            <icon-decorated-button on:click="$closePopup" option:container-padding='@{"left":0,"right":0,"top":0,"bottom":0}' option:container-alignment='@{"horizontal":"1","vertical":0}' option:unhover-background-fill="rgba(0, 0, 0, 0)" option:hover-background-fill="rgba(0, 0, 0, 0.15)" option:hold-background-fill="rgba(0, 0, 0, 0.35)">
                                                <icon image="assets/textures/ui/icons/kiosk/close.svg" option:width="@20" option:height="@20"/>
                                            </icon-decorated-button>
                                        </container>
                                    </row>
                                </background-9slice>
                            </book>
                        </center>
                    </column>
                </background>
            </layer>
        </layered-container>
    </rounded-corners>
</ui-tree>