import { Alignment, Background, Column, FlexAlignment, Root, RoundedCorners, Row, Spacing, Viewport, Widget, type Variable } from "lazy-widgets";
import { ArrowDecoratedButton, ArrowDecoratedButtonProperties } from "./arrow-decorated-button.js";
import { ArrowDirection } from "./arrow-icon.js";

export interface StepperBarProperties extends ArrowDecoratedButtonProperties {
    arrowSize?: number;

    stepHeight?: number;
    stepWidth?: number;
    stepRoundedCornersRadii?: number;
    stepSpacing?: number;
    stepEnabledColor?: string;
    stepDisabledColor?: string;

    stepArrowSpacing?: number
}

export class StepperBar extends Row {
    static override autoXML = {
        name: "stepper-bar",
        inputConfig: [
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            },
            {
                mode: "value",
                validator: "number",
                name: "min-value"
            },
            {
                mode: "value",
                validator: "number",
                name: "max-value"
            },
        ],
    };

    private decButton: ArrowDecoratedButton;
    private incButton: ArrowDecoratedButton;

    private stepBars: Background[];

    private stepEnabledColor: string;
    private stepDisabledColor: string;

    private readonly onValueChange: () => void = () => {
        const currentValue = this.value;
        for (let i = 0; i < this.stepBars.length; i++) {
            this.stepBars[i].canvasFill = i < currentValue ? this.stepEnabledColor : this.stepDisabledColor;
        }
    };

    constructor(readonly variable: Variable<number>, readonly minValue: number, readonly maxValue: number, properties?: StepperBarProperties) {
        const decButton = new ArrowDecoratedButton(
            ArrowDirection.Left,
            {
                iconSize: properties?.arrowSize ?? 10,
                containerPadding: { left: 2, right: 2, top: 2, bottom: 2 }
            },
        ).on("click", () => {
            if (variable.value > minValue) variable.value--;
        });

        const incButton = new ArrowDecoratedButton(
            ArrowDirection.Right,
            {
                iconSize: properties?.arrowSize ?? 10,
                containerPadding: { left: 2, right: 2, top: 2, bottom: 2 }
            },
        ).on("click", () => {
            if (variable.value < maxValue) variable.value++;
        });

        const stepBars = [];
        const stepBarsContainer: RoundedCorners[] = [];
        for (let i = 0; i < maxValue; i++) {
            const stepBar = new Background(
                new Spacing({
                    minHeight: properties?.stepHeight ?? 15,
                    maxHeight: properties?.stepHeight ?? 15,
                    minWidth: properties?.stepWidth ?? 5,
                    maxWidth: properties?.stepWidth ?? 5
                }),
                {
                    containerPadding: { left: 0, right: 0, top: 0, bottom: 0 }
                }
            );

            stepBars.push(stepBar);

            stepBarsContainer.push(
                new RoundedCorners(stepBar,
                    {
                        roundedCornersRadii: properties?.stepRoundedCornersRadii ?? 6
                    }
                ));
        }

        const barsRow = new Row(stepBarsContainer,
            {
                multiContainerAlignment: {
                    main: FlexAlignment.Center,
                    cross: Alignment.Center,
                },
                multiContainerSpacing: properties?.stepSpacing ?? 5
            });

        super(
            [
                new Row(
                    [
                        new Column(
                            [decButton],
                            {
                                multiContainerAlignment: {
                                    main: FlexAlignment.Center,
                                    cross: Alignment.Center,
                                }
                            },
                        ),
                        barsRow,
                        new Column(
                            [incButton],
                            {
                                multiContainerAlignment: {
                                    main: FlexAlignment.Center,
                                    cross: Alignment.Center,
                                }
                            },
                        ),
                    ],
                    {
                        multiContainerAlignment: {
                            main: FlexAlignment.Center,
                            cross: Alignment.Center,
                        },
                        multiContainerSpacing: properties?.stepArrowSpacing ?? 7
                    },
                ),
            ],
            {
                multiContainerAlignment: {
                    main: FlexAlignment.Center,
                    cross: Alignment.Center,
                },
                ...properties
            }
        );

        this.stepBars = stepBars;

        this.stepEnabledColor = properties?.stepEnabledColor ?? "white";
        this.stepDisabledColor = properties?.stepDisabledColor ?? "gray";

        this.decButton = decButton;
        this.incButton = incButton;
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);
        this.variable.watch(this.onValueChange, true);
    }

    override detach(): void {
        this.variable.unwatch(this.onValueChange);
        super.detach();
    }

    override preLayoutUpdate(): void {
        const curVal = this.variable.value;
        this.decButton.clickable = curVal !== this.minValue;
        this.incButton.clickable = curVal !== this.maxValue;
        super.preLayoutUpdate();
    }

    get value() {
        return this.variable.value;
    }

    get normalizedValue() {
        return this.variable.value / this.maxValue;
    }
}