import { GameLocation } from "hoverfit-shared-netcode";
import { type PendingLeaderboardScore } from "../../save-data.js";

const ID_TO_PARTS = new Map<string, Omit<PendingLeaderboardScore, "score" | "elapsingTimeOnNewSession" | "lapsAmount">>([
    ["city-race-city-rush-fastest-lap", { location: GameLocation.City, track: "city-rush" }],
    ["city-race-downtown-derby-fastest-lap", { location: GameLocation.City, track: "downtown-derby" }],
    ["city-race-outer-limits-fastest-lap", { location: GameLocation.City, track: "outer-limits" }],
    ["snow-race-glacier-drift-fastest-lap", { location: GameLocation.Snow, track: "glacier-drift" }],
    ["snow-race-arctic-speedway-fastest-lap", { location: GameLocation.Snow, track: "arctic-speedway" }],
    ["snow-race-polar-dash-fastest-lap", { location: GameLocation.Snow, track: "polar-dash" }],
    ["canyon-race-gold-rush-fastest-lap", { location: GameLocation.Canyon, track: "gold-rush" }],
    ["canyon-race-river-run-fastest-lap", { location: GameLocation.Canyon, track: "river-run" }],
    ["canyon-race-high-noon-fastest-lap", { location: GameLocation.Canyon, track: "high-noon" }],
]);

export function pendingLeaderboardScores_parse_1to2(rawData: Record<string, unknown>) {
    const out: PendingLeaderboardScore[] = [];

    if (Array.isArray(rawData.pendingLeaderboardScores)) {
        for (const record of rawData.pendingLeaderboardScores) {
            if (record === null || typeof record !== "object") continue;
            const parts = ID_TO_PARTS.get(record.leaderboardID);
            if (parts === undefined) continue;
            out.push({
                location: parts.location,
                track: parts.track,
                lapsAmount: -1,
                score: record.score,
                elapsingTimeOnNewSession: record.elapsingTimeOnNewSession,
            });
        }
    }

    return out;
}