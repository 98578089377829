import { HeyVRLeaderboard } from "./heyvr-leaderboard.js";
import { type ContestScoreValue, ContestLeaderboardScore } from "./leaderboard-score.js";

export class HeyVRContestLeaderboard extends HeyVRLeaderboard<ContestLeaderboardScore> {
    constructor(readonly leaderboardIDPrefix: string, subtitle: string, scoreLabel: string) {
        super(subtitle, scoreLabel, ContestLeaderboardScore);
        this.selectedMode = 1;
    }

    protected override scoreToNumber(score: ContestScoreValue): number {
        return score[0];
    }

    protected override scoreFromNumber(val: number): ContestScoreValue {
        return [val, 0];
    }

    protected getLeaderboardID(score?: ContestScoreValue | undefined): string {
        return `${this.leaderboardIDPrefix}-contest-${(score ? new Date(score[1]) : new Date()).getMonth() % 2 ? "even" : "odd"}`;
    }
}