import { SingleParentXMLInputConfig, Widget, WidgetAutoXML } from "lazy-widgets";
import { BaseDecoratedButton, DecoratedButtonProperties } from "./base-decorated-button.js";

export class WidgetDecoratedButton extends BaseDecoratedButton<Widget> {
    static override autoXML: WidgetAutoXML = {
        name: "widget-decorated-button",
        inputConfig: SingleParentXMLInputConfig
    };

    constructor(widget: Widget, properties?: Readonly<DecoratedButtonProperties>) {
        super(widget, properties);
    }

    override set clickable(clickable) {
        super.clickable = clickable;
    }

    override get clickable() {
        return super.clickable;
    }
}