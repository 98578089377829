interface Notifiable {
    notify(name: string | symbol): void;
}

function callNotify(this: Notifiable, name: string | symbol) {
    this.notify(name);
}

/**
 * A decorator for a public field which dispatches a PlayerData event with the
 * name of the property.
 *
 * TODO convert to standard decorators instead of experimental when lazy-widgets
 *      and WLE switches to those
 * TODO get rid of property decorator (else block) and make this accessor-only
 *      once we switch to WLE 1.2/1.3 and the `accessor` keyword is supported
 *      in the built-in WLE editor esbuild version
 */
export function onChange<Class extends Notifiable, Value = unknown>(callback: (this: Class, propertyKey: string | symbol) => void, comparator: (this: Class, oldValue: Value, newValue: Value) => boolean = (a, b) => a === b): PropertyDecorator {
    return function (target: object, propertyKey: string | symbol, descriptor?: PropertyDescriptor): void {
        if (descriptor?.set) {
            const origSet = descriptor.set;
            descriptor.set = function (this: Class, value) {
                const oldValue = descriptor.get!.call(this);
                if (!comparator.call(this, oldValue, value)) {
                    origSet.call(this, value);
                    callback.call(this, propertyKey);
                }
            };
        } else {
            const curValues = new WeakMap();
            Object.defineProperty(target, propertyKey, {
                set: function (value) {
                    const oldValue = curValues.get(this);
                    if (!comparator.call(this, oldValue, value)) {
                        curValues.set(this, value);
                        callback.call(this, propertyKey);
                    }
                },
                get: function () {
                    return curValues.get(this);
                },
                enumerable: true,
                configurable: true,
            });
        }
    };
}

/** Calls the notify method when setter is called on a changed value */
export const notifyOnChange = onChange<Notifiable>(callNotify);
/** Like {@link notifyOnChange}, but for arrays */
export const notifyOnArrayChange = onChange<Notifiable, Array<unknown>>(callNotify, (a, b) => a === b || (a && b && a.length === 0 && b.length === 0));
/**
 * Like {@link notifyOnArrayChange}, but notifies on element changes. Only
 * notifies if you assign the array, not externally change the array.
 */
export const notifyOnStrictArrayChange = onChange<Notifiable, Array<unknown>>(callNotify, (a, b) => {
    if (a === b) return true;
    if (!a || !b || a.length !== b.length) return false;
    for (let i = a.length - 1; i >= 0; i--) {
        if (b[i] !== a[i]) return false;
    }
    return true;
});
