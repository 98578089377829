import { MapSchema, Schema, type } from "@colyseus/schema";
import { GameLocation, GameMode } from "../config/game-configuration-types.js";
import { HOVERBOARD_MULTIPLAYER_STATES } from "./hoverboard-multiplayer-state.js";
import { TrackedPlayer } from "./tracked-player.js";

export class TrackedHoverboardState<P extends TrackedPlayer = TrackedPlayer> extends Schema {
    @type('boolean') privateRoom: boolean = false;

    @type('string') location: GameLocation = GameLocation.Canyon;
    @type('string') mode: GameMode = GameMode.Race;
    @type('uint8') track: number = 0;

    @type(['number']) availableStartingPositions: number[] = new Array<number>(0, 1, 2, 3, 4, 5);

    @type({ map: TrackedPlayer }) players: MapSchema<P> = new MapSchema<P>();
    @type('uint8') roundState: number = HOVERBOARD_MULTIPLAYER_STATES.RACE_PREPARATION;

    @type('uint8') npcsAmount: number = 0;
    @type('uint8') npcsDifficulty: number = 0;
    @type('uint16') npcsSeed: number = 0;
    @type('uint8') lapsAmount: number = 1;
    @type('uint8') tagDuration: number = 90;
}