import { Background, RoundedCorners, SingleParentXMLInputConfig, Widget, filterIDFromProperties, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export interface BackPaneProperties extends WidgetProperties {
    fillColor?: string,
    alpha?: number
}

export class BackPane<W extends Widget = Widget> extends RoundedCorners<Background<W>> {
    static override autoXML: WidgetAutoXML = {
        name: 'back-pane',
        inputConfig: SingleParentXMLInputConfig
    };

    constructor(child: W, properties?: Readonly<BackPaneProperties>) {
        super(
            new Background(
                child,
                {
                    canvasFill: properties?.fillColor ?? `rgba(255, 255, 255, ${properties?.alpha ?? 0.2})`,
                    ...filterIDFromProperties(properties),
                },
            ),
            {
                roundedCornersRadii: 6,
                ...properties,
            },
        );
    }
}