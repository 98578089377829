import { Variable, WidgetAutoXML, WidgetProperties } from "lazy-widgets";
import { DecoratedButton } from "../../lazy-widgets/widgets/decorated-button.js";
import { sfThemeSmaller } from "../../misc/sf-theme.js";

export class KioskTabButton extends DecoratedButton {
    static override autoXML: WidgetAutoXML = {
        name: "kiosk-tab-button",
        inputConfig: [
            {
                mode: "text",
                name: "name",
            },
            {
                mode: "value",
                name: "value",
            },
            {
                mode: "value",
                name: "variable",
                validator: "variable",
            }
        ]
    };

    constructor(name: string, readonly value: unknown, readonly variable: Variable<unknown>, properties?: Readonly<WidgetProperties>) {
        super(name, {
            bodyTextFont: sfThemeSmaller.bodyTextFont,
            buttonLabelFill: "white",
            unhoverBackgroundFill: "rgba(120, 120, 120, 0.25)",
            hoverBackgroundFill: "rgba(120, 120, 120, 0.35)",
            holdBackgroundFill: "rgba(0, 120, 240, 0.35)",
            forceBackgroundFill: "rgba(0, 120, 240, 0.45)",
            roundedCornersRadii: [4, 4, 0, 0],
            minWidth: 75, maxWidth: 75,
            minHeight: 16, maxHeight: 16,
            hasBorder: false,
            noCursorOnForced: true,
            ...properties,
        });


        this.on("click", () => {
            this.variable.value = this.value;
        });
    }

    private watchVariable = () => {
        this.forced = this.variable.value === this.value;
    };

    protected override activate(): void {
        super.activate();
        this.variable.watch(this.watchVariable, true);
    }

    protected override deactivate(): void {
        this.variable.unwatch(this.watchVariable);
        super.deactivate();
    }
}