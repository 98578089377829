import { Component, Property } from "@wonderlandengine/api";
import { XRUtils } from "wle-pp";

export class TargetFramerateUserAgentFilteredComponent extends Component {
    static TypeName = "target-framerate-user-agent-filtered";
    static Properties = {
        _myFirstValidUserAgents: Property.string(),
        _myFirstTargetFramerate: Property.int(),
        _mySecondValidUserAgents: Property.string(),
        _mySecondTargetFramerate: Property.int(),
        _myThirdValidUserAgents: Property.string(),
        _myThirdTargetFramerate: Property.int(),
    };

    start() {
        XRUtils.registerSessionStartEventListener(this, this._onXRSessionStart.bind(this), true);
    }

    _onXRSessionStart(session) {
        if (session.supportedFrameRates != null && session.updateTargetFrameRate != null) {
            let targetFramerate = this._getTargetFramerate();

            if (targetFramerate != null) {

                const supportedFramerates = this.engine.xr.session.supportedFrameRates;
                supportedFramerates.sort((first, second) => Math.abs(first - targetFramerate) - Math.abs(second - targetFramerate));
                this.engine.xr.session.updateTargetFrameRate(supportedFramerates[0]);
            }
        }
    }

    _getTargetFramerate() {
        let targetFramerate = null;

        let userAgent = window.navigator.userAgent;

        let firstValidUserAgents = this._getUserAgentsFromString(this._myFirstValidUserAgents);
        for (let validUserAgent of firstValidUserAgents) {
            if (userAgent.match(validUserAgent) != null) {
                targetFramerate = this._myFirstTargetFramerate;
                break;
            }
        }

        if (targetFramerate == null) {
            let secondValidUserAgents = this._getUserAgentsFromString(this._mySecondValidUserAgents);
            for (let validUserAgent of secondValidUserAgents) {
                if (userAgent.match(validUserAgent) != null) {
                    targetFramerate = this._mySecondTargetFramerate;
                    break;
                }
            }
        }

        if (targetFramerate == null) {
            let thirdValidUserAgents = this._getUserAgentsFromString(this._myThirdValidUserAgents);
            for (let validUserAgent of thirdValidUserAgents) {
                if (userAgent.match(validUserAgent) != null) {
                    targetFramerate = this._myThirdTargetFramerate;
                    break;
                }
            }
        }

        return targetFramerate;
    }

    _getUserAgentsFromString(userAgentsString) {
        let userAgents = [];

        if (userAgentsString.length > 0) {
            userAgents = [...userAgentsString.split(",")];
            for (let i = 0; i < userAgents.length; i++) {
                userAgents[i] = userAgents[i].trim();
            }
        }

        return userAgents;
    }

    onDestroy() {
        XRUtils.unregisterSessionStartEventListener(this);
    }
}