import { Component } from "@wonderlandengine/api";
import { Globals, MathUtils, XRUtils, vec3_create } from "wle-pp";
import { GameGlobals } from "../../game-globals.js";

export class SyncPlayerForwardWithHeadComponent extends Component {
    static TypeName = "sync-player-forward-with-head";
    static Properties = {};

    update(dt) {
        // Implemented outside class definition
    }
}


SyncPlayerForwardWithHeadComponent.prototype.update = function () {
    let playerForward = vec3_create();
    let headForward = vec3_create();
    return function update(dt) {
        if (Globals.getPlayerLocomotion() && Globals.getPlayerLocomotion().isActive() && !XRUtils.isSessionActive()) {
            playerForward = Globals.getPlayerObjects(this.engine).myPlayer.pp_getForward(playerForward);
            headForward = Globals.getPlayerObjects(this.engine).myCameraNonXR.pp_getBackward(headForward);

            playerForward = playerForward.vec3_removeComponentAlongAxis(GameGlobals.up, playerForward);
            headForward = headForward.vec3_removeComponentAlongAxis(GameGlobals.up, headForward);

            let angleToHead = playerForward.vec3_angleSigned(headForward, GameGlobals.up);

            if (Math.abs(angleToHead) > MathUtils.EPSILON_DEGREES) {
                Globals.getPlayerObjects(this.engine).myPlayer.pp_rotateAxis(angleToHead, GameGlobals.up);
                Globals.getPlayerObjects(this.engine).myCameraNonXR.pp_rotateAxis(-angleToHead, GameGlobals.up);
                Globals.getPlayerObjects(this.engine).myHead.pp_rotateAxis(-angleToHead, GameGlobals.up);
            }
        }
    };
}();