import { CatalogItem, heyVRSDK, InventoryItem } from "@heyvr/sdk-gameplay/types";
import { common } from "src/hoverfit/common.js";
import { DEFAULT_ITEM_OWN_META, replaceNamespaceFromManifest, unpackAssetShortID } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { MS_PER_DAY } from "src/hoverfit/utils/time-utils.js";
import { WaitQueue } from "src/hoverfit/utils/wait-queue.js";
import { wait } from "src/hoverfit/utils/wait.js";
import { AnalyticsUtils } from "wle-pp";
import { HeyVR } from "../heyvr-sdk-provider.js";
import { type AssetBase, type AssetManifest } from "./asset-manifest-types.js";
import { AssetProvider, GenericPurchaseError, type ItemCategory, ItemCurrency, ItemNamespace, NoFundsPurchaseError } from "./asset-provider.js";
import { type IAPContentController } from "./iap-content-controller.js";
import { mockConfig } from "./mock-config.js";
import { type OwnedItem } from "./owned-item.js";
import { ShopItem } from "./shop-item.js";

const IAP_ASSET_MANIFEST_URL = "assets/json/shop/iap-asset-manifest.json";

abstract class BaseIAPAssetProvider extends AssetProvider {
    protected readonly manifest: Promise<AssetManifest>;

    constructor(controller: IAPContentController) {
        super(controller);
        this.manifest = controller.fetchAndLoadManifest(IAP_ASSET_MANIFEST_URL, ItemNamespace.IAP);
    }
}

abstract class BasePurchaseableIAPAssetProvider extends BaseIAPAssetProvider {
    private ignoreExpirablesChange = false;
    protected readonly catalogParsed = new WaitQueue();

    private readonly onIAPExpirablesChanged = () => {
        // HACK this is needed to avoid infinite loops
        if (this.ignoreExpirablesChange) return;
        this.fetchInventory();
    };

    constructor(controller: IAPContentController) {
        super(controller);
        common.playerData.listen(this.onIAPExpirablesChanged, "iapExpirables");
    }

    protected async parseCatalog(catalog: readonly CatalogItem[]) {
        const manifest = await this.manifest;
        const added: Array<[shortID: string, item: HeyVRShopItem]> = [];

        for (const item of catalog) {
            const category = item.item_class as ItemCategory | null;
            if (!category) continue;
            const manifestCat = manifest[category];
            if (!manifestCat) continue;
            const shortID = item.slug;
            const asset = manifestCat[shortID];
            if (!asset) continue;
            added.push([shortID, new HeyVRShopItem(this, asset, item)]);
        }

        this.catalog.replaceNamespace(added, ItemNamespace.IAP);
        this.catalogParsed.done();
    }

    protected override async handleExpiredItems(expiredShortIDs: Set<string>, namespace: ItemNamespace): Promise<void> {
        // untrack expirable iap items
        if (expiredShortIDs.size > 0) {
            const playerData = common.playerData;
            const newExpirables = [...playerData.iapExpirables];
            let changed = false;
            for (let i = newExpirables.length - 1; i >= 0; i--) {
                if (expiredShortIDs.has(newExpirables[i][0])) {
                    newExpirables.splice(i, 1);
                    changed = true;
                }
            }

            if (changed) {
                this.ignoreExpirablesChange = true;
                try {
                    playerData.iapExpirables = newExpirables;
                } finally {
                    this.ignoreExpirablesChange = false;
                }

                playerData.delayedSavePlayerData();
            }
        }

        super.handleExpiredItems(expiredShortIDs, namespace);
    }

    protected override async handleGoodExpirableItems(goodExpirableShortIDs: Set<string>): Promise<void> {
        super.handleGoodExpirableItems(goodExpirableShortIDs);

        const playerData = common.playerData;
        const curExpirables = playerData.iapExpirables;
        let needsSave = curExpirables.length !== goodExpirableShortIDs.size;
        if (!needsSave) {
            for (const shortID of curExpirables) {
                if (!goodExpirableShortIDs.has(shortID)) {
                    needsSave = true;
                    break;
                }
            }
        }

        if (needsSave) {
            playerData.iapExpirables = Array.from(goodExpirableShortIDs);
            playerData.delayedSavePlayerData();
        }
    }

    protected async unpackInventory(inventoryItems: ReadonlyArray<InventoryItem>) {
        const added: OwnedItem[] = [];
        const manifest = await this.manifest;
        await this.catalogParsed.wait();

        for (const item of inventoryItems) {
            const meta = item.expires_on ? { expiresOn: (new Date(item.expires_on)).getTime() } : DEFAULT_ITEM_OWN_META;
            unpackAssetShortID(added, this.catalog, manifest, item.slug, ItemNamespace.IAP, meta);
        }

        this.replaceOwnedItems(added, ItemNamespace.IAP, new Set(common.playerData.iapExpirables));
    }

    override dispose() {
        super.dispose();
        common.playerData.unlisten(this.onIAPExpirablesChanged);
    }
}

class MockHeyVRIAPAssetProvider extends BasePurchaseableIAPAssetProvider {
    private onAuthChanged = (changedKey?: string) => {
        if (changedKey != "auth_changed") return;
        this.onUserLoggedIn();
    };

    constructor(controller: IAPContentController) {
        super(controller);

        common.playerData.listen(this.onAuthChanged, "auth_changed");

        wait(0.5).then(async () => {
            if (!common.playerData.isGuest) {
                this.onUserLoggedIn();
            }

            await this.parseCatalog(mockConfig.inventory.catalog as CatalogItem[]);
        });
    }

    protected override async handleInventoryFetch(): Promise<void> {
        if (PRE_OWN_ALL_ITEMS) {
            replaceNamespaceFromManifest(this.inventory, this.catalog, await this.manifest, ItemNamespace.IAP);
        } else {
            await wait(0.5);

            if (mockConfig.inventory) {
                this.unpackInventory(mockConfig.inventory.myItems);
            } else {
                this.inventory.removeNamespace(ItemNamespace.IAP);
            }
        }
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        await wait(0.5);

        let catItem: CatalogItem | null = null;
        for (const otherCatItem of mockConfig.inventory.catalog) {
            if (otherCatItem.slug === shortID) {
                catItem = otherCatItem as CatalogItem;
                break;
            }
        }

        if (!catItem) {
            throw new GenericPurchaseError(`Item with short ID "${shortID}" is not for sale`);
        }

        const price = catItem.price_discounted ?? catItem.price;

        if (price > common.playerData.heyVRCoins) {
            throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
        }

        common.playerData.heyVRCoins -= price;
        mockConfig.user.userBalance -= price;

        const added: OwnedItem[] = [];
        const meta = catItem.expires_after ? { expiresOn: Date.now() + catItem.expires_after * MS_PER_DAY } : DEFAULT_ITEM_OWN_META;
        unpackAssetShortID(added, this.catalog, await this.manifest, shortID, ItemNamespace.IAP, meta);
        this.addOwnedItems(added, ItemNamespace.IAP, new Set(catItem.expires_after ? [shortID] : []));

        // For persistence through map reloads
        mockConfig.inventory.myItems.push({
            // XXX doing assignment like this because the types are bad
            ...(catItem as Omit<CatalogItem, "model">),
            model: catItem.model as null,
            item_class: catItem.item_class as string,
            owned_count: 1,
            expires_on: meta.expiresOn ? (new Date(meta.expiresOn)).toISOString() : "",
            acquired_at: (new Date()).toISOString(),
        });

        return true;
    }

    private onUserLoggedIn(): void {
        common.playerData.heyVRCoins = mockConfig.user.userBalance;

        this.inventory.removeNamespace(ItemNamespace.IAP);
        this.fetchInventory();
    }

    override dispose() {
        super.dispose();
        common.playerData.unlisten(this.onAuthChanged);
    }
}

class HeyVRIAPAssetProvider extends BasePurchaseableIAPAssetProvider {
    private onAuthChanged = (changedKey?: string) => {
        if (changedKey != "auth_changed") return;

        if (!common.playerData.isGuest) {
            this.onUserLoggedIn();
        } else {
            this.inventory.removeNamespace(ItemNamespace.IAP);
            common.playerData.heyVRCoins = 0;
        }
    };

    constructor(readonly heyVR: heyVRSDK, controller: IAPContentController) {
        super(controller);

        if (!common.playerData.isGuest) {
            this.onUserLoggedIn();
        }

        common.playerData.listen(this.onAuthChanged, "auth_changed");

        this.heyVR.inventory.getCatalog().then((catalog) => {
            this.parseCatalog(catalog);
        });
    }

    private async updateCoins(): Promise<void> {
        common.playerData.heyVRCoins = await this.heyVR.user.getAmountCoins();
    }

    protected override async handleInventoryFetch(): Promise<void> {
        if (PRE_OWN_ALL_ITEMS) {
            replaceNamespaceFromManifest(this.inventory, this.catalog, await this.manifest, ItemNamespace.IAP);
        } else {
            let inventoryItems: ReadonlyArray<InventoryItem>;
            try {
                inventoryItems = await this.heyVR.inventory.get();
            } catch (err) {
                console.error(err);
                this.inventory.removeNamespace(ItemNamespace.IAP);
                return;
            }

            this.unpackInventory(inventoryItems);
        }
    }

    private onUserLoggedIn(): void {
        this.inventory.removeNamespace(ItemNamespace.IAP);
        this.updateCoins();
        this.fetchInventory();
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        try {
            const shopItem = this.catalog.getRequired(`${ItemNamespace.IAP}:${shortID}`);
            if (!await this.heyVR.inventory.purchase(shortID, 1)) return false;

            AnalyticsUtils.sendEvent("purchase", {
                itemNamespace: ItemNamespace.IAP,
                itemShortID: shortID,
                itemCategory: shopItem.itemCategory,
            });

            const added: OwnedItem[] = [];
            const meta = shopItem.expiresAfter ? { expiresOn: Date.now() + shopItem.expiresAfter } : DEFAULT_ITEM_OWN_META;
            unpackAssetShortID(added, this.catalog, await this.manifest, shortID, ItemNamespace.IAP, meta);

            // TODO technically an expired item popup can be missed because we
            //      don't update the iapExpirables list in the player data
            //      after a purchase is successful. however, items last at least
            //      a day, and this would require a player to be online for more
            //      than 24 hours straight, so this is not an issue for now. if
            //      you do this, you should probably do it for the mock provider
            //      too

            this.addOwnedItems(added, ItemNamespace.IAP, new Set(shopItem.expiresAfter ? [shortID] : []));
            return true;
        } catch (err) {
            if (err && (err as any).status) {
                if (((err as any).status.debug === "err_not_enough_coins") || ((err as any).status.debug === "err_insufficient_balance")) {
                    throw new NoFundsPurchaseError(ItemCurrency.HeyVR);
                } else {
                    throw new GenericPurchaseError(`${(err as any).status.message}`);
                }
            } else {
                throw new GenericPurchaseError();
            }
        } finally {
            this.updateCoins();
        }
    }

    override dispose() {
        super.dispose();
        common.playerData.unlisten(this.onAuthChanged);
    }
}

export class HeyVRShopItem extends ShopItem {
    constructor(shopProvider: AssetProvider, asset: AssetBase, readonly catalogItem: Readonly<CatalogItem>) {
        super(shopProvider, asset);
    }

    get shortID() {
        return this.catalogItem.slug;
    }

    get namespace(): ItemNamespace.IAP {
        return ItemNamespace.IAP;
    }

    get itemCategory() {
        return this.catalogItem.item_class as ItemCategory;
    }

    get currencyType(): ItemCurrency.HeyVR {
        return ItemCurrency.HeyVR;
    }

    get price() {
        return this.catalogItem.price;
    }

    get priceDiscounted() {
        return this.catalogItem.price_discounted;
    }

    get expiresAfter() {
        return this.catalogItem.expires_after * MS_PER_DAY;
    }

    get description() {
        return super.description ?? this.catalogItem.description;
    }

    purchase() {
        return this.shopProvider.purchaseItem(this.shortID);
    }
}

/**
 * An IAP asset provider that only defines the IAP asset namespace, but doesn't
 * let you own or purchase any IAP asset. Used for environments without a mock
 * shop and without the HeyVR SDK, but where you can still join multiplayer
 * sessions with HeyVR players and therefore need to know the HeyVR assets.
 */
class UnpurchaseableIAPAssetProvider extends BaseIAPAssetProvider {
    protected override async handleInventoryFetch(): Promise<void> {
        if (PRE_OWN_ALL_ITEMS) {
            replaceNamespaceFromManifest(this.inventory, this.catalog, await this.manifest, ItemNamespace.IAP);
        } else {
            this.inventory.removeNamespace(ItemNamespace.IAP);
        }
    }

    override async purchaseItem(shortID: string): Promise<boolean> {
        return false;
    }
}

type IAPAPCtor = new (controller: IAPContentController) => BaseIAPAssetProvider;
const SDKlessIAPAssetProvider: IAPAPCtor = (!WL_EDITOR && FALLBACK_TO_MOCK_PROVIDER) ? MockHeyVRIAPAssetProvider : UnpurchaseableIAPAssetProvider;

export function getIAPAssetProvider() {
    return HeyVR ? HeyVRIAPAssetProvider.bind(null, HeyVR) : SDKlessIAPAssetProvider;
}