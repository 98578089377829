import { Alignment, AxisCoupling, Background, Column, Container, FlexAlignment, Label, LayeredContainer, RoundedCorners, Row, ScrollableViewportWidget, ScrollbarMode, TextAlignMode, WrapMode, filterIDFromProperties, type Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";
import { type LeaderboardScore } from "src/hoverfit/game/track/leaderboard/leaderboard-score.js";

export interface LeaderboardEntry {
    rank: number,
    displayName: string,
    score: number | null,
}

export class LeaderboardWidget extends ScrollableViewportWidget<LayeredContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "leaderboard",
        inputConfig: [
            {
                name: "empty-child",
                mode: "widget",
            },
            {
                name: "table-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "rank-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "time-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "row-height",
                mode: "value",
                validator: "number",
            },
        ]
    };

    private column: Column;

    constructor(readonly emptyChild: Widget, readonly tableWidth: number, readonly rankWidth: number, readonly timeWidth: number, readonly rowHeight: number, properties?: Readonly<WidgetProperties>) {
        const column = new Column([], {
            multiContainerSpacing: 2,
            multiContainerAlignment: {
                main: FlexAlignment.Start, cross: Alignment.Stretch,
            },
            ...filterIDFromProperties(properties),
        });

        super(
            new LayeredContainer(
                [
                    {
                        child: new Container(
                            column,
                            {
                                containerPadding: {
                                    top: 0, right: 5, bottom: 0, left: 0,
                                },
                            },
                        ),
                    },
                    {
                        child: emptyChild,
                        canExpand: false,
                    },
                ]
            ),
            {
                minWidth: 300,
                minHeight: 10,
                scrollBarThickness: 12,
                scrollbarCornersRadii: 4.5,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.ParentToChild,
                flex: 1,
                scrollbarMode: ScrollbarMode.Layout,
                backgroundFill: "rgba(0,0,0,0)",
                backgroundGlowFill: "rgba(0,0,0,0)",
                primaryFill: "rgba(255, 255, 255, 0.75)",
                accentFill: "rgba(255, 255, 255, 0.60)",
                ...properties,
            },
        );

        this.column = column;
    }

    private getEntryContainer(score: LeaderboardScore<unknown>) {
        return new RoundedCorners(
            new Background(
                new Row(
                    [
                        new Container(
                            new Label(
                                `${score.rank}`,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFill: "black",
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                minWidth: this.rankWidth, maxWidth: this.rankWidth,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                `${score.name}`,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    wrapMode: WrapMode.Ellipsis,
                                    bodyTextFill: "black",
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flex: 1,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                score.stringifyScore(),
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFont: "1em sui-generis-mono-digits",
                                    bodyTextFill: "black",
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                minWidth: this.timeWidth, maxWidth: this.timeWidth,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                    ],
                    {
                        multiContainerSpacing: 0,
                    },
                ),
                {
                    containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                    canvasFill: "rgba(255, 255, 255, 0.75)",
                },
            ),
            {
                roundedCornersRadii: 6,
                minWidth: this.tableWidth, maxWidth: this.tableWidth,
                minHeight: this.rowHeight, maxHeight: this.rowHeight,
            },
        );
    }

    replaceLeaderboardScores(leaderboardScores: LeaderboardScore<unknown>[]) {
        this.column.clearChildren();

        if (leaderboardScores.length === 0) {
            this.emptyChild.enabled = true;
        } else {
            this.emptyChild.enabled = false;

            // This assumes the data is sorted
            for (const leaderboardScore of leaderboardScores) {
                this.column.add(this.getEntryContainer(leaderboardScore));
            }
        }
    }
}