import { GameLocation, GameMode } from "hoverfit-shared-netcode";
import { Variable } from "lazy-widgets";
import { common } from "../common.js";
import { HoverboardGameConfigJSON, HoverboardGameOnlineConfigJSON } from "./game-configuration.js";
import { validateBool } from "./validation/validate-bool.js";
import { validateEnum } from "./validation/validate-enum.js";
import { validateInt } from "./validation/validate-int.js";
import { INVALID_RECORD_VALUE, validateRecord } from "./validation/validate-record.js";

export interface DecodedGameSettingsData {
    policyAccepted: boolean | null;

    musicVolume: number;
    quietMode: boolean;

    voiceVolume: number;
    microphoneEnabled: boolean;
    voiceP2P: boolean;
    voiceMediasoup: boolean;

    gameConfigOnLoad: Readonly<HoverboardGameConfigJSON | null>;
    gameOnlineConfigOnLoad: Readonly<HoverboardGameOnlineConfigJSON | null>;

    showTutorialOnStart: boolean;
}

export const MAX_VOLUME = 10;
const LOAD_GROUP = 0xDEADBEEF; // unique key to signal that you shouldn't save

export class GameSettings {
    policyAccepted: Variable<boolean | null> = new Variable(null);

    musicVolume: Variable<number> = new Variable(MAX_VOLUME);
    quietMode: Variable<boolean> = new Variable(false);

    voiceVolume: Variable<number> = new Variable(MAX_VOLUME);
    microphoneEnabled: Variable<boolean> = new Variable(true);
    voiceP2P: Variable<boolean> = new Variable(false);
    voiceMediasoup: Variable<boolean> = new Variable(true);

    gameConfigOnLoad: Variable<Readonly<HoverboardGameConfigJSON> | null> = new Variable(null);
    gameOnlineConfigOnLoad: Variable<Readonly<HoverboardGameOnlineConfigJSON> | null> = new Variable(null);

    showTutorialOnStart: Variable<boolean> = new Variable(true);

    constructor() {
        const saveOnChanged = (_: unknown, group: unknown) => { if (group !== LOAD_GROUP) this.save(); };

        this.policyAccepted.watch(saveOnChanged);

        this.musicVolume.watch(saveOnChanged);
        this.quietMode.watch(saveOnChanged);

        this.voiceVolume.watch(saveOnChanged);
        this.microphoneEnabled.watch(saveOnChanged);
        this.voiceP2P.watch(saveOnChanged);
        this.voiceMediasoup.watch(saveOnChanged);

        this.gameConfigOnLoad.watch(saveOnChanged);
        this.gameOnlineConfigOnLoad.watch(saveOnChanged);

        this.showTutorialOnStart.watch(saveOnChanged);
    }

    reset() {
        this.policyAccepted.setValue(null, LOAD_GROUP);

        this.musicVolume.setValue(MAX_VOLUME, LOAD_GROUP);
        this.quietMode.setValue(false, LOAD_GROUP);

        this.voiceVolume.setValue(MAX_VOLUME, LOAD_GROUP);
        this.microphoneEnabled.setValue(true, LOAD_GROUP);
        this.voiceP2P.setValue(false, LOAD_GROUP);
        this.voiceMediasoup.setValue(true, LOAD_GROUP);

        this.gameConfigOnLoad.setValue(null, LOAD_GROUP);
        this.gameOnlineConfigOnLoad.setValue(null, LOAD_GROUP);

        this.showTutorialOnStart.setValue(true, LOAD_GROUP);
    }

    private save() {
        common.playerData.delayedSavePlayerData();
    }

    loadDecodedData(unsafeData: unknown) {
        if (unsafeData === null || typeof unsafeData !== "object") {
            unsafeData = {};
        }

        const raw = unsafeData as Record<string, unknown>;

        this.policyAccepted.setValue(validateEnum(raw.policyAccepted, [true, false, null], null), LOAD_GROUP);

        this.musicVolume.setValue(validateInt(raw.musicVolume, MAX_VOLUME, 0, MAX_VOLUME), LOAD_GROUP);
        this.quietMode.setValue(validateBool(raw.quietMode, false), LOAD_GROUP);

        this.voiceVolume.setValue(validateInt(raw.voiceVolume, MAX_VOLUME, 0, MAX_VOLUME), LOAD_GROUP);
        this.microphoneEnabled.setValue(validateBool(raw.microphoneEnabled, true), LOAD_GROUP);
        this.voiceP2P.setValue(validateBool(raw.voiceP2P, false), LOAD_GROUP);
        this.voiceMediasoup.setValue(validateBool(raw.voiceMediasoup, true), LOAD_GROUP);

        this.gameConfigOnLoad.setValue(validateRecord(
            raw.gameConfigOnLoad,
            {
                // TODO in the future there should be a utility that extracts accepted enum values into an array
                location: [validateEnum, [GameLocation.Canyon, GameLocation.City, GameLocation.Snow], INVALID_RECORD_VALUE],
                mode: [validateEnum, [GameMode.Race, GameMode.Tag, GameMode.Roam], INVALID_RECORD_VALUE],
                track: [validateInt, INVALID_RECORD_VALUE, 0],
            },
            null,
        ), LOAD_GROUP);
        this.gameOnlineConfigOnLoad.setValue(validateRecord(
            raw.gameOnlineConfigOnLoad,
            {
                isOnline: [validateBool, INVALID_RECORD_VALUE],
                roomID: [validateInt, null, 0],
                isPrivateRoom: [validateBool, INVALID_RECORD_VALUE],
            },
            null,
        ), LOAD_GROUP);

        this.showTutorialOnStart.setValue(validateBool(raw.showTutorialOnStart, true), LOAD_GROUP);
    }
}