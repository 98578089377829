import { Component, MeshComponent, Property, TextComponent, } from "@wonderlandengine/api";
import { CursorTarget } from "@wonderlandengine/components";
import { CursorStyleManagerComponent } from "cursor-style-manager-wle";
import { AnalyticsUtils, BrowserUtils, Globals } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { InstructorAudioType } from "../../avatar/components/hoverboard-instructor-component.js";
import { common } from "../../common.js";
import { downHapticFeedback, hoverHapticFeedback, unHoverHapticFeedback, upHapticFeedback, } from "../../misc/haptic-feedback.js";

const func = [
    "Start",
    "External",
    "Feedback",
    "Instruction",
    "HealthBenefits",
    "GameModeInstructions",
    "LeaderboardTest",
    "Play Online",
    "ToBalcony",
    "ConfirmEnd",
];

export class ButtonComponent extends Component {
    static TypeName = "button";
    static Properties = {
        changeMaterialOnHover: Property.bool(true),
        hoverMaterial: Property.material(),
        /* Function for pressing the button, External if no defined function */
        functionality: Property.enum(func, "Start"),
        /* Optional: Target object for functionality */
        functionalityTarget: Property.object(null),
        /* Optional: Target string parameter for functionality */
        functionalityString: Property.string()
    };

    start() {
        this.csm = Globals.getRootObject().pp_getComponent(CursorStyleManagerComponent);

        this.functionality = func[this.functionality];
        this.mesh = this.object.pp_getComponent(MeshComponent);
        if (this.changeMaterialOnHover) this.defaultMaterial = this.mesh.material;

        this.target = this.object.pp_getComponent(CursorTarget);
        this.target.onHover.add(this.onHover.bind(this));
        this.target.onUnhover.add(this.onUnHover.bind(this));
        this.target.onDown.add(this.onDown.bind(this));
        this.target.onUpWithDown.add(this.onUpWithDown.bind(this));

        this.soundClick = common.audioManager.getAudio(AudioID.BUTTON_CLICK);
        this.soundHover = common.audioManager.getAudio(AudioID.BUTTON_HOVER);

        this.textComponent = this.object.pp_getComponent(TextComponent);

        this.loadingScene = false;
        common.MAIN_CHANNEL.on("load-scene-start", () => {
            this.loadingScene = true;
        });
        common.MAIN_CHANNEL.on("load-scene-end", () => {
            this.loadingScene = false;
        });
    }

    onHover(_, cursor) {
        if (this.changeMaterialOnHover) this.mesh.material = this.hoverMaterial;
        if (cursor.type == "finger-cursor") {
            this.onDown(_, cursor);
        } else {
            if (cursor) hoverHapticFeedback(cursor.handedness);
        }

        this.soundHover.play();

        if (this.csm) {
            this.csm.setStyle(this, "pointer");
        }
    }

    onDown(_, cursor) {
        if (cursor) downHapticFeedback(cursor.handedness);
    }

    onUpWithDown(_, cursor) {
        if (this.functionality === "Start") {
            common.menu.startRace();
        } else if (this.functionality === "ToBalcony") {
            AnalyticsUtils.sendEvent("move_to_balcony");
            common.hoverboardInstructor.stopAudioPlayers();
            common.menu.returnToBalcony();
        } else if (this.functionality === "Feedback") {
            AnalyticsUtils.sendEvent("open_feedback");
            BrowserUtils.openLink(this.functionalityString, true, true, true, true);
        } else if (this.functionality === "Instruction") {
            AnalyticsUtils.sendEvent("listen_instruction");
            common.kioskController.setHowToHoverboardActiveAndPlayAudio();
        } else if (this.functionality === "HealthBenefits") {
            AnalyticsUtils.sendEvent("listen_health_benefits");
            common.kioskController.setFitnessBenefitsActiveAndPlayAudio();
        } else if (this.functionality === "GameModeInstructions") {
            AnalyticsUtils.sendEvent("listen_game_mode_instructions");
            common.hoverboardInstructor.setSelectedAudioPlayer(InstructorAudioType.GameModeInstructions);
            common.hoverboardInstructor.playSelectedAudio();
        } else if (this.functionality === "ConfirmEnd") {
            common.pauseMenu.applyConfirmEnd();
        }

        this.soundClick.play();
        if (cursor) upHapticFeedback(cursor.handedness);
    }

    onUnHover(_, cursor) {
        if (this.changeMaterialOnHover) this.mesh.material = this.defaultMaterial;
        if (cursor.type == "finger-cursor") {
            this.onUpWithDown(_, cursor);
        } else {
            if (cursor) unHoverHapticFeedback(cursor.handedness);
        }

        if (this.csm) {
            this.csm.clearStyle(this);
        }
    }
}
