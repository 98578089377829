import { Object3D, PhysXComponent } from "@wonderlandengine/api";
import { PhysicsCollisionCollector } from "wle-pp";

export class EnvironmentalSoundActor {

    private actorObject: Object3D;
    private disableWhenInsideAreaCollisionsCollector: PhysicsCollisionCollector | null = null;

    constructor(actorObject: Object3D, disableWhenInsideArea: PhysXComponent | null) {
        this.actorObject = actorObject;

        if (disableWhenInsideArea != null) {
            this.disableWhenInsideAreaCollisionsCollector = new PhysicsCollisionCollector(disableWhenInsideArea);
        }
    }

    canPlayAudio() {
        let isInsideDisableArea = false;
        if (this.disableWhenInsideAreaCollisionsCollector != null) {
            const currentCollision = this.disableWhenInsideAreaCollisionsCollector.getCollisions();
            for (const physX of currentCollision) {
                if (physX.object == this.actorObject) {
                    isInsideDisableArea = true;
                    break;
                }
            }
        }

        return !isInsideDisableArea;
    }

    getPosition() {
        return this.actorObject.pp_getPosition();
    }
}