import { DEFAULT_ITEM_OWN_META } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { type OwnedItem } from "src/hoverfit/misc/asset-provision/owned-item.js";
import { MS_PER_MINUTE } from "src/hoverfit/utils/time-utils.js";

export function fitabuxInventory_parse_latest(rawData: Record<string, unknown>) {
    const fitabuxInventory: OwnedItem[] = [];

    const sids = rawData.fitabuxSIDs;
    if (sids && Array.isArray(sids)) {
        for (const shortID of sids) {
            fitabuxInventory.push([shortID, DEFAULT_ITEM_OWN_META]);
        }

        const sidCount = sids.length;
        const expiry = rawData.fitabuxExpiry;
        if (expiry && Array.isArray(expiry)) {
            // flat array with number pairs:
            // - even indices = index in fitabuxSIDs
            // - odd indices = expiry unix timestamp, compressed to nearest minute
            const iMax = expiry.length - expiry.length % 1;
            for (let i = 0; i < iMax; i += 2) {
                const sidIdx = Math.trunc(expiry[i]);
                if (isNaN(sidIdx) || !isFinite(sidIdx) || sidIdx < 0 || sidIdx >= sidCount) continue;
                const expiresOn = Math.trunc(expiry[i + 1] * MS_PER_MINUTE);
                if (isNaN(expiresOn) || !isFinite(expiresOn) || expiresOn <= 0) continue;
                fitabuxInventory[sidIdx][1] = { expiresOn };
            }
        }
    }

    return fitabuxInventory;
}