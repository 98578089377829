import '../index.js';
import { addAutoRegisterHook, load } from '../loader.js';

/* wle:auto-imports:start */
import {Cursor} from '@wonderlandengine/components';
import {CursorTarget} from '@wonderlandengine/components';
import {FixedFoveation} from '@wonderlandengine/components';
import {MouseLookComponent} from '@wonderlandengine/components';
import {CursorStyleManagerComponent} from 'cursor-style-manager-wle';
import {LazyWidgetsInputGuardComponent} from 'lazy-widgets-wle';
import {AdjustHierarchyPhysXScaleComponent} from './../../custom-packages/pp/index.js';
import {CharacterCollisionSystemComponent} from './../../custom-packages/pp/index.js';
import {ConsoleVRToolComponent} from './../../custom-packages/pp/index.js';
import {EasyTuneImportVariablesComponent} from './../../custom-packages/pp/index.js';
import {EasyTuneToolComponent} from './../../custom-packages/pp/index.js';
import {OverlapCursorComponent} from './../../custom-packages/pp/index.js';
import {PPGatewayComponent} from './../../custom-packages/pp/index.js';
import {PlayerLocomotionComponent} from './../../custom-packages/pp/index.js';
import {SetActiveComponent} from './../../custom-packages/pp/index.js';
import {SetHandLocalTransformComponent} from './../../custom-packages/pp/index.js';
import {SetHeadLocalTransformComponent} from './../../custom-packages/pp/index.js';
import {ShowFPSComponent} from './../../custom-packages/pp/index.js';
import {SpatialAudioListenerComponent} from './../../custom-packages/pp/index.js';
import {ToolCursorComponent} from './../../custom-packages/pp/index.js';
import {VirtualGamepadComponent} from './../../custom-packages/pp/index.js';
import {AudioLoadComponent} from './../hoverfit/audio/audio-manager/components/audio-load-component.js';
import {AudioManagerComponent as AudioManagerComponent1} from './../hoverfit/audio/audio-manager/components/audio-manager-component.js';
import {EncryptedAudioSinkComponent} from './../hoverfit/audio/encrypted-audio/components/encrypted-audio-sink-component.js';
import {MotivationalAudioManagerComponent} from './../hoverfit/audio/game/components/motivational-audio-manager-component.js';
import {EnvironmentalSoundActorComponent} from './../hoverfit/audio/game/environmental-sounds/components/environmental-sound-actor-component.js';
import {EnvironmentalSoundAreaComponent} from './../hoverfit/audio/game/environmental-sounds/components/environmental-sound-area-component.js';
import {EnvironmentalSoundComponent} from './../hoverfit/audio/game/environmental-sounds/components/environmental-sound-component.js';
import {AvatarComponent} from './../hoverfit/avatar/components/avatar-component.js';
import {AvatarSelectorComponent} from './../hoverfit/avatar/components/avatar-selector-component.js';
import {HoverboardInstructorComponent} from './../hoverfit/avatar/components/hoverboard-instructor-component.js';
import {TrainerMusclesComponent} from './../hoverfit/avatar/components/trainer-muscles-component.js';
import {WatchControllerComponent} from './../hoverfit/avatar/components/watch-controller-component.js';
import {BalconyComponent} from './../hoverfit/game/balcony/components/balcony-component.js';
import {RoadMapBalconyMarkerComponent} from './../hoverfit/game/balcony/components/road-map-balcony-marker-component.js';
import {RoadMapPlayerMarkerComponent} from './../hoverfit/game/balcony/components/road-map-player-marker-component.js';
import {HoverboardDebugsComponent} from './../hoverfit/game/components/hoverboard-debugs-component.js';
import {IntroComponent} from './../hoverfit/game/components/intro-component.js';
import {MenuComponent} from './../hoverfit/game/components/menu-component.js';
import {EnvironmentControllerComponent} from './../hoverfit/game/environment/environment-controller.js';
import {CircularMapComponent} from './../hoverfit/game/hoverboard/components/circular-map-component.js';
import {HoverboardComponent} from './../hoverfit/game/hoverboard/components/hoverboard-component.js';
import {HoverboardSelectorComponent} from './../hoverfit/game/hoverboard/components/hoverboard-selector-component.js';
import {HoverboardWindEffectComponent} from './../hoverfit/game/hoverboard/components/hoverboard-wind-effect-component.js';
import {InvincibilityRingComponent} from './../hoverfit/game/hoverboard/components/invincibility-ring-component.js';
import {NPCControllerComponent} from './../hoverfit/game/hoverboard/components/npc-controller-component.js';
import {TagRingComponent} from './../hoverfit/game/hoverboard/components/tag-ring-component.js';
import {TimerComponent} from './../hoverfit/game/hoverboard/components/timer-component.js';
import {CountdownComponent} from './../hoverfit/game/track/components/countdown-component.js';
import {DynamicChevronComponent} from './../hoverfit/game/track/components/dynamic-chevron-component.js';
import {ReadinessIndicatorComponent} from './../hoverfit/game/track/components/readiness-indicator-component.js';
import {TrackComponent} from './../hoverfit/game/track/components/track-component.js';
import {TrackModeInstructionsComponent} from './../hoverfit/game/track/components/track-mode-instructions-component.js';
import {TracksManagerComponent} from './../hoverfit/game/track/components/tracks-manager-component.js';
import {PickupGlueComponent} from './../hoverfit/game/track/pickups/components/pickup-glue-component.js';
import {PickupInvincibilityComponent} from './../hoverfit/game/track/pickups/components/pickup-invincibility-component.js';
import {PickupRocketComponent} from './../hoverfit/game/track/pickups/components/pickup-rocket-component.js';
import {PickupShieldComponent} from './../hoverfit/game/track/pickups/components/pickup-shield-component.js';
import {PickupSpringComponent} from './../hoverfit/game/track/pickups/components/pickup-spring-component.js';
import {PickupSuperRocketComponent} from './../hoverfit/game/track/pickups/components/pickup-super-rocket-component.js';
import {PickupSuperSpringComponent} from './../hoverfit/game/track/pickups/components/pickup-super-spring-component.js';
import {PickupTimerComponent} from './../hoverfit/game/track/pickups/components/pickup-timer-component.js';
import {HoverfitSceneResourcesComponent} from './../hoverfit/misc/components/hoverfit-scene-resources-component.js';
import {AutoTextureScrollComponent} from './../hoverfit/misc/components/material/auto-texture-scroll-component.js';
import {SetTransformOnTrackedHandComponent} from './../hoverfit/misc/components/set-transform-on-tracked-hand-component.js';
import {TargetFramerateUserAgentFilteredComponent as TargetFramerateUserAgentFilteredComponent1} from './../hoverfit/misc/components/target-framerate-user-agent-filtered-component.js';
import {LookAtPlayerComponent} from './../hoverfit/misc/components/transform/look-at-player-component.js';
import {MoveAwayComponent} from './../hoverfit/misc/components/transform/move-away-component.js';
import {ResetPositionOnSessionComponent} from './../hoverfit/misc/components/transform/reset-position-on-session-end-component.js';
import {RotateOverTimeComponent} from './../hoverfit/misc/components/transform/rotate-over-time-component.js';
import {ScaleChildCollisionsComponent} from './../hoverfit/misc/components/transform/scale-child-collisions-component.js';
import {SetFeetPositionComponent} from './../hoverfit/misc/components/transform/set-feet-position-component.js';
import {SyncPlayerForwardWithHeadComponent} from './../hoverfit/misc/components/transform/sync-player-forward-with-head-component.js';
import {SyncedFollowForwardComponent} from './../hoverfit/misc/components/transform/synced-follow-forward-component.js';
import {SyncedFollowPositionComponent} from './../hoverfit/misc/components/transform/synced-follow-position-component.js';
import {SceneSwitcherVariablesComponent} from './../hoverfit/misc/load-scene/components/scene-switcher-variables-component.js';
import {VersionTextSetterComponent} from './../hoverfit/misc/version/components/version-text-setter-component.js';
import {HoverboardNetworkingComponent} from './../hoverfit/network/components/hoverboard-networking-component.js';
import {NetworkPlayerComponent} from './../hoverfit/network/components/network-player-component.js';
import {NetworkPlayerPoolComponent} from './../hoverfit/network/components/network-player-pool-component.js';
import {NetworkSyncComponent} from './../hoverfit/network/components/network-sync-component.js';
import {NetworkedHoverboardComponent} from './../hoverfit/network/components/networked-hoverboard-component.js';
import {ButtonComponent} from './../hoverfit/ui/components/button-component.js';
import {SetVoIPDebugMenuTransformComponent} from './../hoverfit/ui/components/set-voip-debug-menu-transform-component.js';
import {SpeedometerBlocksRotationComponent} from './../hoverfit/ui/components/speedometer-blocks-rotation-component.js';
import {SpeedometerSpeedSliderComponent} from './../hoverfit/ui/components/speedometer-speed-slider-component.js';
import {UIAnchorComponent} from './../hoverfit/ui/components/ui-anchor-component.js';
import {VoipDebugMenuComponent} from './../hoverfit/ui/components/voip-debug-menu-component.js';
import {KioskControllerComponent} from './../hoverfit/ui/kiosk/components/kiosk-controller-component.js';
import {KioskLowerUIComponent} from './../hoverfit/ui/kiosk/components/kiosk-lower-ui-component.js';
import {KioskUpperUIComponent} from './../hoverfit/ui/kiosk/components/kiosk-upper-ui-component.js';
import {PopupManagerComponent} from './../hoverfit/ui/popup/components/popup-manager-component.js';
import {PauseMenuComponent} from './../hoverfit/ui/xml-ui/components/pause-menu-component.js';
/* wle:auto-imports:end */

addAutoRegisterHook((engine) => {
/* wle:auto-register:start */
engine.registerComponent(Cursor);
engine.registerComponent(CursorTarget);
engine.registerComponent(FixedFoveation);
engine.registerComponent(MouseLookComponent);
engine.registerComponent(CursorStyleManagerComponent);
engine.registerComponent(LazyWidgetsInputGuardComponent);
engine.registerComponent(AdjustHierarchyPhysXScaleComponent);
engine.registerComponent(CharacterCollisionSystemComponent);
engine.registerComponent(ConsoleVRToolComponent);
engine.registerComponent(EasyTuneImportVariablesComponent);
engine.registerComponent(EasyTuneToolComponent);
engine.registerComponent(OverlapCursorComponent);
engine.registerComponent(PPGatewayComponent);
engine.registerComponent(PlayerLocomotionComponent);
engine.registerComponent(SetActiveComponent);
engine.registerComponent(SetHandLocalTransformComponent);
engine.registerComponent(SetHeadLocalTransformComponent);
engine.registerComponent(ShowFPSComponent);
engine.registerComponent(SpatialAudioListenerComponent);
engine.registerComponent(ToolCursorComponent);
engine.registerComponent(VirtualGamepadComponent);
engine.registerComponent(AudioLoadComponent);
engine.registerComponent(AudioManagerComponent1);
engine.registerComponent(EncryptedAudioSinkComponent);
engine.registerComponent(MotivationalAudioManagerComponent);
engine.registerComponent(EnvironmentalSoundActorComponent);
engine.registerComponent(EnvironmentalSoundAreaComponent);
engine.registerComponent(EnvironmentalSoundComponent);
engine.registerComponent(AvatarComponent);
engine.registerComponent(AvatarSelectorComponent);
engine.registerComponent(HoverboardInstructorComponent);
engine.registerComponent(TrainerMusclesComponent);
engine.registerComponent(WatchControllerComponent);
engine.registerComponent(BalconyComponent);
engine.registerComponent(RoadMapBalconyMarkerComponent);
engine.registerComponent(RoadMapPlayerMarkerComponent);
engine.registerComponent(HoverboardDebugsComponent);
engine.registerComponent(IntroComponent);
engine.registerComponent(MenuComponent);
engine.registerComponent(EnvironmentControllerComponent);
engine.registerComponent(CircularMapComponent);
engine.registerComponent(HoverboardComponent);
engine.registerComponent(HoverboardSelectorComponent);
engine.registerComponent(HoverboardWindEffectComponent);
engine.registerComponent(InvincibilityRingComponent);
engine.registerComponent(NPCControllerComponent);
engine.registerComponent(TagRingComponent);
engine.registerComponent(TimerComponent);
engine.registerComponent(CountdownComponent);
engine.registerComponent(DynamicChevronComponent);
engine.registerComponent(ReadinessIndicatorComponent);
engine.registerComponent(TrackComponent);
engine.registerComponent(TrackModeInstructionsComponent);
engine.registerComponent(TracksManagerComponent);
engine.registerComponent(PickupGlueComponent);
engine.registerComponent(PickupInvincibilityComponent);
engine.registerComponent(PickupRocketComponent);
engine.registerComponent(PickupShieldComponent);
engine.registerComponent(PickupSpringComponent);
engine.registerComponent(PickupSuperRocketComponent);
engine.registerComponent(PickupSuperSpringComponent);
engine.registerComponent(PickupTimerComponent);
engine.registerComponent(HoverfitSceneResourcesComponent);
engine.registerComponent(AutoTextureScrollComponent);
engine.registerComponent(SetTransformOnTrackedHandComponent);
engine.registerComponent(TargetFramerateUserAgentFilteredComponent1);
engine.registerComponent(LookAtPlayerComponent);
engine.registerComponent(MoveAwayComponent);
engine.registerComponent(ResetPositionOnSessionComponent);
engine.registerComponent(RotateOverTimeComponent);
engine.registerComponent(ScaleChildCollisionsComponent);
engine.registerComponent(SetFeetPositionComponent);
engine.registerComponent(SyncPlayerForwardWithHeadComponent);
engine.registerComponent(SyncedFollowForwardComponent);
engine.registerComponent(SyncedFollowPositionComponent);
engine.registerComponent(SceneSwitcherVariablesComponent);
engine.registerComponent(VersionTextSetterComponent);
engine.registerComponent(HoverboardNetworkingComponent);
engine.registerComponent(NetworkPlayerComponent);
engine.registerComponent(NetworkPlayerPoolComponent);
engine.registerComponent(NetworkSyncComponent);
engine.registerComponent(NetworkedHoverboardComponent);
engine.registerComponent(ButtonComponent);
engine.registerComponent(SetVoIPDebugMenuTransformComponent);
engine.registerComponent(SpeedometerBlocksRotationComponent);
engine.registerComponent(SpeedometerSpeedSliderComponent);
engine.registerComponent(UIAnchorComponent);
engine.registerComponent(VoipDebugMenuComponent);
engine.registerComponent(KioskControllerComponent);
engine.registerComponent(KioskLowerUIComponent);
engine.registerComponent(KioskUpperUIComponent);
engine.registerComponent(PopupManagerComponent);
engine.registerComponent(PauseMenuComponent);
/* wle:auto-register:end */
});

if (!WL_EDITOR && EDITOR_SCENE === "snow") {
    /* wle:auto-constants:start */
const Constants = {
    ProjectName: 'fitness-resort-hoverboard-snow',
    RuntimeBaseName: 'WonderlandRuntime',
    WebXRRequiredFeatures: ['local','local-floor',],
    WebXROptionalFeatures: ['local','local-floor',],
};
const RuntimeOptions = {
    physx: true,
    loader: true,
    xrFramebufferScaleFactor: 1,
    canvas: 'canvas',
};
/* wle:auto-constants:end */

    load(RuntimeOptions, Constants);

    /* wle:auto-benchmark:start */
/* wle:auto-benchmark:end */
}
