export function formatScoreToTime(time: number | null) {
    if (time == null) return "--:--.---";

    const m = String(Math.floor(time / 60)).padStart(2, "0");
    const s = String(Math.floor(time) % 60).padStart(2, "0");
    const ms = String(Math.floor((time * 1000) % 1000)).padStart(3, "0");
    return `${m}:${s}.${ms}`;
}

export type ContestScoreValue = [value: number, timestamp: number];

export interface LeaderboardScore<V> {
    rank: number;
    name: string;
    score: V;

    stringifyScore(): string;
    isHigher(other: this): boolean;
}

export type LeaderboardScoreCtor<S extends LeaderboardScore<unknown>> = new (rank: number, name: string, score: S['score']) => S;

export class NumericLeaderboardScore implements LeaderboardScore<number> {
    constructor(readonly rank: number, readonly name: string, readonly score: number) { }

    stringifyScore(): string {
        return this.score.toString();
    }

    isHigher(other: NumericLeaderboardScore): boolean {
        return this.score >= other.score;
    }
}

export class TimeLeaderboardScore implements LeaderboardScore<number> {
    constructor(readonly rank: number, readonly name: string, readonly score: number) { }

    stringifyScore(): string {
        return formatScoreToTime(this.score / 1000);
    }

    isHigher(other: TimeLeaderboardScore): boolean {
        return this.score <= other.score;
    }
}

export class ContestLeaderboardScore implements LeaderboardScore<ContestScoreValue> {
    constructor(readonly rank: number, readonly name: string, readonly score: ContestScoreValue) { }

    stringifyScore(): string {
        const score = this.score[0];
        return `${score} squat${score === 1 ? "" : "s"}`;
    }

    isHigher(other: ContestLeaderboardScore): boolean {
        return this.score[0] >= other.score[0];
    }
}