import { Component, MeshComponent, Property } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { Globals, MaterialUtils, MathUtils, ObjectCloneParams, ObjectPool, ObjectPoolParams, vec3_create, vec4_create } from "wle-pp";
import { common } from "../../../common.js";
import { GameGlobals } from "../../../misc/game-globals.js";
import { NetworkPlayerComponent } from "../../../network/components/network-player-component.js";

const arrowIndicatorUpOffset = [0.0, 0.069, 0.0];
const arrowIndicatorDownOffset = [0.0, 0.015, 0.0];
const arrowIndicatorDownRotation = [0.0, 0.0, Math.PI];

export class CircularMapComponent extends Component {
    static TypeName = "circular-map";
    static Properties = {
        distanceCutoff: Property.float(50.0),
        mapRadius: Property.float(0.25),
        heightIndicatorThreshold: Property.float(4.0),
        indicatorPrototype: Property.object(),
        arrowIndicatorPrototype: Property.object(),
        mapBase: Property.object(),
        chaserIndicatorColor: Property.color(),
        chaserMapColor: Property.color(),
        evaderIndicatorColor: Property.color(),
        evaderMapColor: Property.color(),
        arrowIndicatorUpColor: Property.color(),
        arrowIndicatorDownColor: Property.color(),
    };

    init() {
        common.circularMap = this;

        this.neutralIndicatorColor = vec4_create();
        this.neutralIndicatorColor.vec4_copy(this.indicatorPrototype.pp_getComponent(MeshComponent).material.diffuseColor);
        this.neutralMapColor = vec4_create();
        this.neutralMapColor.vec4_copy(this.mapBase.pp_getComponent(MeshComponent).material.color);

        this.tempVec = vec3_create();
        this.tempVec2 = vec3_create();
        this.tempVec3 = vec3_create();

        this.activeIndicatorsBySessionId = new Map();
        this.activeArrowIndicatorsBySessionId = new Map();

        this.started = false;
    }

    _start() {
        if (this.started) return;

        let poolParams = new ObjectPoolParams();
        poolParams.myInitialPoolSize = 10;
        poolParams.myPercentageToAddWhenEmpty = 0;
        poolParams.myAmountToAddWhenEmpty = 1;

        poolParams.myCloneParams = new ObjectCloneParams();
        poolParams.myCloneParams.myComponentDeepCloneParams.setDeepCloneComponentVariable(MeshComponent.TypeName, "material", true);

        this.indicatorsPoolID = "indicators_" + MathUtils.randomUUID();
        this.arrowIndicatorsPoolID = "arrow_indicators_" + MathUtils.randomUUID();
        Globals.getObjectPoolManager().addPool(this.indicatorsPoolID, new ObjectPool(this.indicatorPrototype, poolParams));
        Globals.getObjectPoolManager().addPool(this.arrowIndicatorsPoolID, new ObjectPool(this.arrowIndicatorPrototype, poolParams));

        MaterialUtils.setObjectClonedMaterials(this.mapBase);

        this.indicatorPrototype.pp_setActive(false);
        this.arrowIndicatorPrototype.pp_setActive(false);

        this.setEnabled(this.enabled);

        this.started = true;
    }

    update(dt) {
        if (!this.started && Globals.getObjectPoolManager() != null) {
            this._start();
        }

        for (const networkPlayer of common.hoverboardNetworking.getNetworkPlayersObjects().values()) {
            let networkPlayerComponent = networkPlayer.pp_getComponent(NetworkPlayerComponent);
            this.updateNetworkPlayerMapPosition(networkPlayerComponent, networkPlayerComponent.hoverboard);
        }
    }

    setMapTagged(tagged) {
        if (tagged) {
            this.mapBase.pp_getComponent(MeshComponent).material.color = this.chaserMapColor;
        } else {
            this.mapBase.pp_getComponent(MeshComponent).material.color = this.evaderMapColor;
        }
    }

    setIndicatorTagged(sessionId, tagged) {
        let indicatorTagged = this.activeIndicatorsBySessionId.get(sessionId);

        if (indicatorTagged != null) {
            if (tagged) {
                indicatorTagged.pp_getComponent(MeshComponent).material.diffuseColor = this.chaserIndicatorColor;
            } else {
                indicatorTagged.pp_getComponent(MeshComponent).material.diffuseColor = this.evaderIndicatorColor;
            }
        }

        let arrowIndicatorTagged = this.activeArrowIndicatorsBySessionId.get(sessionId);
        if (arrowIndicatorTagged != null) {
            if (tagged) {
                arrowIndicatorTagged.pp_getComponent(MeshComponent).material.diffuseColor = this.chaserIndicatorColor;
            } else {
                arrowIndicatorTagged.pp_getComponent(MeshComponent).material.diffuseColor = this.evaderIndicatorColor;
            }
        }
    }

    resetTagged() {
        this._start();

        this.mapBase.pp_getComponent(MeshComponent).material.color = this.neutralMapColor;

        let indicatorsPool = Globals.getObjectPoolManager().getPool(this.indicatorsPoolID);
        for (let indicator of indicatorsPool.getObjects()) {
            indicator.pp_getComponent(MeshComponent).material.diffuseColor = this.neutralIndicatorColor;
        }

        let arrowIndicatorsPool = Globals.getObjectPoolManager().getPool(this.arrowIndicatorsPoolID);
        for (let arrowIndicator of arrowIndicatorsPool.getObjects()) {
            arrowIndicator.pp_getComponent(MeshComponent).material.diffuseColor = this.neutralIndicatorColor;
        }
    }

    setEnabled(isEnabled) {
        this.enabled = isEnabled;
        this.object.pp_setActive(isEnabled);

        if (isEnabled) {
            this.indicatorPrototype.pp_setActive(false);
            let indicatorsPool = Globals.getObjectPoolManager().getPool(this.indicatorsPoolID);
            for (let inactiveIndicator of indicatorsPool.getAvailableObjects()) {
                inactiveIndicator.pp_setActive(false);
            }

            this.arrowIndicatorPrototype.pp_setActive(false);
            let arrowIndicatorsPool = Globals.getObjectPoolManager().getPool(this.arrowIndicatorsPoolID);
            for (let inactiveArrowIndicator of arrowIndicatorsPool.getAvailableObjects()) {
                inactiveArrowIndicator.pp_setActive(false);
            }

            for (let activeIndicator of this.activeIndicatorsBySessionId.values()) {
                activeIndicator.pp_setScaleLocal(0); // This is done to delay the visualization until the first transform has been received
            }
        }
    }

    showPlayerOnMap(sessionId) {
        this._start();

        if (this.activeIndicatorsBySessionId.has(sessionId)) return;

        let indicatorsPool = Globals.getObjectPoolManager().getPool(this.indicatorsPoolID);
        this.activeIndicatorsBySessionId.set(sessionId, indicatorsPool.get());
        this.activeIndicatorsBySessionId.get(sessionId).pp_setScaleLocal(0);

        let arrowIndicatorsPool = Globals.getObjectPoolManager().getPool(this.arrowIndicatorsPoolID);
        const inactiveArrowIndicator = arrowIndicatorsPool.get();
        if (inactiveArrowIndicator) {
            this.activeArrowIndicatorsBySessionId.set(sessionId, inactiveArrowIndicator);
            inactiveArrowIndicator.pp_setScaleLocal(0);
        }

        if (this.enabled) {
            this.activeIndicatorsBySessionId.get(sessionId).pp_setActive(true);
        }
    }

    hidePlayerOnMap(sessionId) {
        this._start();

        if (!this.activeIndicatorsBySessionId.has(sessionId)) return;

        let indicatorsPool = Globals.getObjectPoolManager()?.getPool(this.indicatorsPoolID);
        indicatorsPool?.release(this.activeIndicatorsBySessionId.get(sessionId));

        let arrowIndicatorsPool = Globals.getObjectPoolManager()?.getPool(this.arrowIndicatorsPoolID);
        arrowIndicatorsPool?.release(this.activeArrowIndicatorsBySessionId.get(sessionId));

        this.activeIndicatorsBySessionId.delete(sessionId);
        this.activeArrowIndicatorsBySessionId.delete(sessionId);
    }

    updateNetworkPlayerMapPosition(networkPlayerComponent, networkHoverboardObject) {
        let indicator = this.activeIndicatorsBySessionId.get(networkPlayerComponent.sessionId);
        let arrowIndicator = this.activeArrowIndicatorsBySessionId.get(networkPlayerComponent.sessionId);

        if (indicator != null) {
            // Get positions
            networkHoverboardObject.pp_getPosition(this.tempVec);
            let otherHeight = this.tempVec[1];
            this.tempVec.vec3_removeComponentAlongAxis(GameGlobals.up, this.tempVec);

            common.hoverboard.object.pp_getPosition(this.tempVec2);
            let mapHeight = this.tempVec2[1];
            this.tempVec2.vec3_removeComponentAlongAxis(GameGlobals.up, this.tempVec2);

            // Calculate height difference
            let heightDifference = otherHeight - mapHeight;

            let differenceVectorThisAndOther = this.tempVec.vec3_sub(this.tempVec2, this.tempVec);
            let distance = differenceVectorThisAndOther.vec3_length();

            let flatThisObjectForward = common.hoverboard.object.pp_getForward(this.tempVec3).vec3_removeComponentAlongAxis(GameGlobals.up, this.tempVec3);
            let flatOtherPlayerForward = networkHoverboardObject.pp_getForward(this.tempVec2).vec3_removeComponentAlongAxis(GameGlobals.up, this.tempVec2);

            let angleRotation = flatOtherPlayerForward.vec3_anglePivotedSignedRadians(flatThisObjectForward, GameGlobals.up);
            let anglePosition = differenceVectorThisAndOther.vec3_anglePivotedSignedRadians(flatThisObjectForward, GameGlobals.up);

            this.updateIndicatorMapPosition(anglePosition, angleRotation, distance, heightDifference, indicator, arrowIndicator);
        }
    }

    updateIndicatorMapPosition(anglePosition, angleRotation, distance, heightDifference, indicator, arrowIndicator) {
        if (!this.enabled) return;

        if (distance > this.distanceCutoff) {
            distance = this.distanceCutoff;
        }

        // Position update
        let distanceMultiplier = distance / this.distanceCutoff;
        let x = -Math.sin(anglePosition) * distanceMultiplier * this.mapRadius;
        let y = Math.cos(anglePosition) * distanceMultiplier * this.mapRadius;

        this.tempVec.vec3_set(x, 0.005, y);
        indicator.setPositionLocal(this.tempVec);

        if (arrowIndicator) {
            arrowIndicator.resetRotation();
            if (heightDifference > this.heightIndicatorThreshold) {
                arrowIndicator.pp_setActive(true);
                this.tempVec.vec3_add(arrowIndicatorUpOffset, this.tempVec);
                arrowIndicator.setPositionLocal(this.tempVec);
                if (common.gameConfig.mode != GameMode.Tag) {
                    arrowIndicator.pp_getComponent(MeshComponent).material.diffuseColor = this.arrowIndicatorUpColor;
                }
            } else if (heightDifference < -this.heightIndicatorThreshold) {
                this.tempVec.vec3_add(arrowIndicatorDownOffset, this.tempVec);
                arrowIndicator.setPositionLocal(this.tempVec);
                arrowIndicator.pp_setRotationLocalRadians(arrowIndicatorDownRotation);
                arrowIndicator.pp_setActive(true);
                if (common.gameConfig.mode != GameMode.Tag) {
                    arrowIndicator.pp_getComponent(MeshComponent).material.diffuseColor = this.arrowIndicatorDownColor;
                }
            } else {
                arrowIndicator.pp_setActive(false);
            }
        }

        // Rotation update
        this.tempVec.vec3_set(0, -angleRotation, 0);
        indicator.pp_setRotationLocalRadians(this.tempVec);

        indicator.pp_resetScaleLocal();

        if (arrowIndicator) {
            arrowIndicator.pp_resetScaleLocal();
        }
    }

    onLeaveRoom() {
        const activeIndicators = Array.from(this.activeIndicatorsBySessionId.keys());
        for (const sessionId of activeIndicators) {
            this.hidePlayerOnMap(sessionId);
        }
    }
}
