import { common } from "src/hoverfit/common.js";
import { LeaderboardScore, type LeaderboardScoreCtor } from "./leaderboard-score.js";

export abstract class BaseLeaderboard<S extends LeaderboardScore<unknown> = LeaderboardScore<any>> {
    private scores: S[] = [];
    private clearScoresOnNextSubmitDirty = false;
    selectedMode = 0;
    readonly abstract unavailableMessage: string | null;

    constructor(readonly subtitle: string, readonly scoreLabel: string, private readonly leaderboardScoreCtor: LeaderboardScoreCtor<S>) { }

    update(dt: number) { }

    submitScore(score: S["score"]): Promise<this> {
        return this.submitNamedScore(score, common.playerData.name);
    }

    submitNamedScore(score: S["score"], name: string): Promise<this> {
        if (this.clearScoresOnNextSubmitDirty) {
            this.clearScoresOnNextSubmitDirty = false;
            this.clearScores();
        }

        this.addScore(score, name, null);

        return Promise.resolve(this);
    }

    abstract fetchScores(): Promise<this>;
    abstract getAvailableModes(): string[];

    getScores(): S[] {
        return this.scores;
    }

    clearScores() {
        this.scores.pp_clear();
    }

    clearScoresOnNextSubmit() {
        this.clearScoresOnNextSubmitDirty = true;
    }

    removeScore(name: string) {
        this.scores.pp_remove((elementToCheck) => elementToCheck.name == name);
    }

    protected addScore(score: S["score"], name: string, rank: number | null = null) {
        const newScore = new this.leaderboardScoreCtor(rank ?? -1, name, score);
        const oldScore = this.scores.pp_find((elementToCheck) => elementToCheck.name == name);

        if (oldScore == null || newScore.isHigher(oldScore)) {
            if (oldScore) this.scores.pp_removeEqual(oldScore);
            this.scores.push(newScore);
        }

        this.scores.sort((first, second) => {
            return first.isHigher(second) ? -1 : 1;
        });

        if (rank == null) {
            let previousIndex = -1;
            let adjusted = false;
            for (const score of this.scores) {
                if (!adjusted) {
                    if (score.rank == -1) {
                        score.rank = previousIndex == -1 ? 1 : previousIndex + 1;
                        adjusted = true;
                    }
                } else {
                    if (score.rank == previousIndex) {
                        score.rank++;
                    } else {
                        break;
                    }
                }

                previousIndex = score.rank;
            }
        }
    }
}