import { common } from "src/hoverfit/common.js";
import { ItemNamespace } from "src/hoverfit/misc/asset-provision/asset-provider.js";
import { FP_PER_BOARD_LEVEL, FP_PER_HELMET_LEVEL, FP_PER_SUIT_LEVEL } from "../kiosk/widgets/lifetime-level-display.js";

export function getPotentialRewardHelmetLevel() {
    return Math.trunc(common.playerData.totalFitPoints / FP_PER_HELMET_LEVEL);
}

export function getPotentialRewardSuitLevel() {
    return Math.trunc(common.playerData.totalFitPoints / FP_PER_SUIT_LEVEL);
}

export function getPotentialRewardBoardLevel() {
    return Math.trunc(common.playerData.totalFitPoints / FP_PER_BOARD_LEVEL);
}

export const REWARD_ID_PREFIX_H = `${ItemNamespace.Reward}:h_reward-`;
export function getPotentialRewardHelmetID() {
    return REWARD_ID_PREFIX_H + (getPotentialRewardHelmetLevel() * FP_PER_HELMET_LEVEL).toFixed(0);
}

export const REWARD_ID_PREFIX_S = `${ItemNamespace.Reward}:s_reward-`;
export function getPotentialRewardSuitID() {
    return REWARD_ID_PREFIX_S + (getPotentialRewardSuitLevel() * FP_PER_SUIT_LEVEL).toFixed(0);
}

export const REWARD_ID_PREFIX_HB = `${ItemNamespace.Reward}:hb_reward-`;
export function getPotentialRewardBoardID() {
    return REWARD_ID_PREFIX_HB + (getPotentialRewardBoardLevel() * FP_PER_BOARD_LEVEL).toFixed(0);
}