import { type CatalogItem, type InventoryItem, type SandboxConfigKeys, type SandboxConfigLeaderboard, type SandboxConfigMarketing, type SandboxConfigSaveGame, type SandboxConfigUser } from "@heyvr/sdk-gameplay/types";

interface ExtendedSandboxConfigUser extends SandboxConfigUser {
    responseDelay: number,
}

export const mockConfig: {
    marketing: Readonly<SandboxConfigMarketing>,
    inventory: {
        catalog: ReadonlyArray<CatalogItem>,
        myItems: InventoryItem[],
    },
    leaderboard: Readonly<SandboxConfigLeaderboard>,
    saveGame: Readonly<SandboxConfigSaveGame>;
    user: ExtendedSandboxConfigUser;
    config: Readonly<SandboxConfigKeys>;
} = {
    "marketing": {
        "subscriptions": []
    },
    "inventory": {
        "catalog": [
            {
                "name": "Falcon - Wildwood",
                "slug": "hb_forest",
                "description": "Harness nature's power with the Wildwood! This organic board blends raw, earthy energy with sleek speed, perfect for nature lovers seeking fast-paced, wilderness-inspired adventure.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/f0ebb9c6-e5cc-4e6f-b377-d370785aba1f.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Falcon - Golden Shard",
                "slug": "hb_kintsugi",
                "description": "Blaze through the game with the Golden Shard! Its sleek, ancient art design combines speed and style, making you look amazing while leaving your competition in the dust.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/w/sq_sd/704fb8d5-660c-4687-bc7c-7822f29f7a90.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Falcon - Surgewave",
                "slug": "hb_lake",
                "description": "Ride the waves with Surgewave! This water-inspired board flows like a lake but speeds through the game. Glide effortlessly, leaving a wake of envy with sleek, aquatic style.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/a/sq_sd/f83a695b-c58a-47e2-985d-885c7e69d0c7.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Falcon - Web Strike",
                "slug": "hb_spider",
                "description": "Unleash deadly speed with Web Strike! Lacquered in venom and web-like patterns, this board combines precision and danger, perfect for thrill-seekers who crave an edge that stings.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Falcon - Morph Drift",
                "slug": "hb_amoeba",
                "description": "Once microscopic, now mighty! The bio-inspired Morph Drift combines speed and flexibility, perfect for gamers who want a futuristic, agile board ready for any challenge.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/t/sq_sd/8478a59d-31b7-40d6-b7a7-f2476c2a553c.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Titan - Metro Jet",
                "slug": "hb_city",
                "description": "Urban style meets high-tech speed. With its sleek, industrial design and powerful dual engines, feel the pulse of the city as you race through any adventure.",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/7/sq_sd/2e41e672-57d6-451a-84a2-ecd0e5d44a7a.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Titan - Neon Pulse",
                "slug": "hb_neon",
                "description": "Light up the night with neon-infused graphics and high speed performance, this futuristic board lights up the darkness. Stand out and cruise in electrifying style. This neon gem is your ticket to glow!",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/i/sq_sd/4a1b3bbf-99b6-4481-8237-e256facbdc43.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Titan - Stealth Striker",
                "slug": "hb_tank-camo",
                "description": "Military-inspired and battle-ready. This camouflage board delivers speed and durability, giving you the confidence of an armored unit as you race through your adventures.",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/1/sq_sd/f74e2aea-30b9-40f7-9de6-042d6b275e2a.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Titan - Urban Splash",
                "slug": "hb_graffiti",
                "description": "Unleash your inner artist. Splattered with vibrant colors, this board adds flair to every win. Perfect for creative racers who leave their opponents in a colorful daze.",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/46dadf07-653e-4350-8eac-df307bf8ce6d.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Titan - Aqua Glide",
                "slug": "hb_pool",
                "description": "Chill out and race in style. Featuring a cool, water-ripple design, relax, take the plunge, and leave your competition all washed up. Perfect for laid-back racers who still want to win!",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/2/sq_sd/15097f92-d7bd-4765-a24f-36438d9b6f39.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Viper - Vibe Racer",
                "slug": "hb_racer",
                "description": "Sleek, streamlined, and fast! With sharp neon accents and aerodynamic design, this board is built for racers craving speed and precision. Dominate the track in style and agility.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/0/sq_sd/eeb8ad6a-638a-4dd5-bdc1-5bcb6c0e01b2.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Viper - Deco Dash",
                "slug": "hb_artdeco",
                "description": "Vintage elegance meets modern speed. Featuring intricate geometric patterns, glide with sophistication as you race, blending grace with performance. Ideal for racers who love refined style and fast-paced action.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/w/sq_sd/a634136b-ba34-4e97-93ad-7d78fe6107ca.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Viper - Tech Flux",
                "slug": "hb_circuitry",
                "description": "Where tech meets speed! This electrified design pulses with futuristic energy, built for lightning-fast racing. Thrive on advanced tech and sleek, fast-paced thrills.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/o/sq_sd/c7ea9228-4631-43da-8b0f-92a75d1cc56e.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Viper - Velo Weave",
                "slug": "hb_knittwear",
                "description": "Comfort meets speed. With a soft, textured surface like your favorite sweater, this board keeps you cozy while racing. Perfect for blending warmth with high-speed adventure.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/3a706367-e187-47fc-9e22-5f9567dd59ac.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Viper - Silverstream",
                "slug": "hb_mercury",
                "description": "Glide like liquid metal! With a sleek, metallic finish, this board offers fluid precision and speed. Shine on the track with smooth, unstoppable agility.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/z/sq_sd/66095c3f-b168-4a14-9a4a-e2ab0de33a5e.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Delta - Neon Blade",
                "slug": "hb_future",
                "description": "Sleek and futuristic, the Neon Blade\u2019s sharp angles and neon accents are built for speed. Zoom ahead of the curve with style and cutting-edge performance.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/u/sq_sd/a3d7e6d3-d57a-4fc3-b0d4-e38a8c1ce689.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Delta - Rust Runner",
                "slug": "hb_junker",
                "description": "Built tough, the Rust Runner\u2019s industrial, weathered look embraces grit and durability. With exposed bolts and rugged panels, it\u2019s made for racers who love adventure on rough roads.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/b/sq_sd/5bcbcafc-e680-475f-8b96-aea67ede8871.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Delta - Green Streak",
                "slug": "hb_eco",
                "description": "Eco-conscious speed at its finest! The Green Streak features sleek, energy-efficient design and clean propulsion. Ride fast, ride clean, and leave a sustainable mark on the competition.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/z/sq_sd/562ddb74-00de-4b86-8b2c-04579687a650.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Delta - Headline",
                "slug": "hb_newspaper",
                "description": "Catch the headlines wrapped in vintage newsprint designs. Bring a retro, urban vibe to your rides. Ride fast, stay informed, and leave a lasting impression on the track!",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/m/sq_sd/c05d7015-cfab-4e5f-bdd9-a8096ba0f169.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Delta - Retro Glider",
                "slug": "hb_vaporwave",
                "description": "Capture the retro-futuristic vibe with the Retro Glider Hoverboard! Featuring neon pastel gradients and pixelated patterns, this board brings 80s nostalgia into the fast-paced digital future.",
                "price": 40,
                "price_discounted": 40,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/v/sq_sd/1be670c1-38b5-4d1c-8a97-445efb81147a.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Future - Wraptor",
                "slug": "hb_christmas2024",
                "description": "",
                "price": 60,
                "price_discounted": 60,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/v/sq_sd/1be670c1-38b5-4d1c-8a97-445efb81147a.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Crimson Strike",
                "slug": "h_red-tron",
                "description": "Dominate the race with the Crimson Strike helmet! Its sleek black and red design delivers high-speed performance and bold style. Stand out and never slow down.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/p/sq_sd/e0150903-de76-4896-9d16-71af5e67344c.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Grunge Vortex",
                "slug": "h_grunge",
                "description": "Race through the track with the Grunge Vortex helmet! Its bold green and black design combines speed with striking style. Ride fast and fearless.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/5/sq_sd/0086f6f3-95b6-42bf-85e8-97c6a1f359dd.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Electro Pulse",
                "slug": "h_pinkwaves",
                "description": "Make a statement with the Electro Pulse helmet! Its striking pink, gray, and yellow design combines speed and style. Stand out and dominate the track.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/b/sq_sd/b18a81f3-a5ae-43d4-ba56-8e18d2914c18.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Geo Dash",
                "slug": "h_geometric",
                "description": "Dominate the track with the GeoDash helmet! Its bold, faceted geometric design blends teal and blue, giving you a sharp, futuristic edge. Perfect for racers who value precision and speed.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/v/sq_sd/42099816-8e49-4947-9b96-69b3a855334e.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Inkstrike",
                "slug": "h_tattoo",
                "description": "Make a bold statement with the Inkstrike helmet! Featuring intricate tattoo-style designs in black and white, this helmet combines art with speed. Ride with style and attitude.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/w/sq_sd/17de1c72-4323-4b26-85c9-4b92219a8f22.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Acid Flux",
                "slug": "h_acid-racer",
                "description": "Tear up the track with the Acid Flux helmet! Its striking neon green and black design is built for speed and style, perfect for racers who crave boldness and high-performance action.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/f/sq_sd/e4f27426-0df6-4f1e-bb1b-8848a591c999.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Flashwave",
                "slug": "h_funky",
                "description": "Blaze through the competition with the Flashwave helmet! Featuring vibrant neon colors and bold patterns, it\u2019s designed for racers who want to electrify the track with their speed and style.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/s/sq_sd/ddef6b1a-9cb9-4e5d-a638-fe34d55cf40a.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Punchline",
                "slug": "h_comic",
                "description": "Get ready to race in style with the Punchline helmet! Inspired by classic comic book art, this colorful, eye-popping design, make a bold, fun statement.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/f/sq_sd/2755073c-9b71-4034-9218-5c77f7a9dd70.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Moto - Checkmate",
                "slug": "h_chequered",
                "description": "Cross the finish line in style with the Checkmate helmet! Featuring a bold yellow and black checkered design, it\u2019s built for speed and precision, perfect for racers who aim to win.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/7/sq_sd/4125abc4-f3f0-436b-a800-ee5700e40b1f.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Golden Ranger",
                "slug": "h_ranger",
                "description": "Lead the pack with the Golden Ranger helmet! Its bold gold visor and rugged black accents combine elegance with strength, command both style and speed.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/g/sq_sd/3d577975-d601-493e-8c1e-679de4618391.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Sky Darter",
                "slug": "h_dragonfly",
                "description": "Fly through the competition with the Dragon Scale helmet! Its unique honeycomb design and vibrant colors give racers the agility and speed they need, with a fierce, futuristic style.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/4/sq_sd/0dd31a01-cf9b-4877-a0f7-85d2ed339095.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Sundae Swirl",
                "slug": "h_ice-cream",
                "description": "Add some sweetness to your speed with the Sundae Swirl helmet! Featuring a fun ice cream design with sprinkles, keep things cool and playful.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/s/sq_sd/41de4475-13a1-40c0-8705-939e55fa9e93.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Eyeris",
                "slug": "h_monster-eye",
                "description": "Get a new perspective with the Eyeris helmet. Turn heads with this eerie, oversized eye design and keep an unblinking watch on the competition.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/h/sq_sd/660a3ca4-7127-4f44-9449-fea73434af5f.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Olympus",
                "slug": "h_greek",
                "description": "Channel ancient heroes with the Olympus helmet. Its bold Greek patterns give you a legendary edge, turning heads as you race with the power of mythology on your side.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/5eee3467-61d6-4f96-b62f-e1c1134409d0.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Stellar Rush",
                "slug": "h_starry",
                "description": "Reach for the stars with the Stellar Rush helmet! Its cosmic design features a deep galaxy pattern, perfect for racers who want to feel the speed of the universe while leaving everyone in their dust.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/m/sq_sd/86bc88f4-4fe1-4016-9bda-dd72d41d4142.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Sonic Surge",
                "slug": "h_soundwaves",
                "description": "Turn up the volume with the Sonic Surge helmet! Featuring dynamic blue waves and vibrant design, this helmet commands attention, letting you race to the beat with electrifying style.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/x/sq_sd/791b74a1-0235-4146-8df4-0aa82be7ba3c.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Diamond Edge",
                "slug": "h_diamond",
                "description": "Shine on the track with the Diamond Edge helmet! Its faceted, crystal-like design brings a sleek and sharp style. Cut through the competition in style.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/b/sq_sd/8bc995c7-ec44-4f96-8947-a882a97f0275.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Lustre",
                "slug": "h_pearl",
                "description": "Dive into elegance with the Lustre helmet! Its smooth, glossy shell paired with a natural, textured edge evokes the beauty of the sea, ideal for racers who want refined style and sleek performance.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/i/sq_sd/6999e845-a9fb-4570-83a8-57db770d49c5.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Cyber - Inferno",
                "slug": "h_obsidian",
                "description": "Feel the heat with the Inferno helmet! Its fiery red and gold marbled design, contrasted with deep black, brings a molten, volcanic look to the track. Ride with power and intensity.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/t/sq_sd/3b83c9d9-3f9a-4160-8bbe-3a7b2650882c.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Vertex - Blaze",
                "slug": "s_red-tron",
                "description": "Set the track on fire with the Blaze suit! Its fiery design and sleek form are built for speed and intensity. Dominate your races with unstoppable energy and flair.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/e/sq_sd/ea1f7579-fba8-4c0c-a076-47d45a9c9484.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Vertex - Golden Mosaic",
                "slug": "s_mosaic",
                "description": "Embody strength and resilience with the Golden Mosaic suit. The detailed, interlocking pattern and bold gold kneepads symbolize endurance, making this suit ideal for racers ready to power through any challenge.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/3884158e-8bc6-45a7-a010-d0c199c83199.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Vertex - Tropical Blitz",
                "slug": "s_pineapple",
                "description": "Bring the heat with the Tropical Blitz suit! Featuring a bright yellow and green design, it\u2019s made for racers who want to stand out with a fresh, bold look.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/e761e8ac-6858-484a-a377-9db8947c5700.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Vertex - Galaxy",
                "slug": "s_dark-matter",
                "description": "Disappear into the depths of the universe with the Galaxy suit. Its sleek, black, and speckled design gives an aura of mystery and power as you dominate the track in stealth.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/t/sq_sd/a6452ad6-0ee7-4fd1-91d6-b100a4b82cfb.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Vertex - Venom",
                "slug": "s_halftone",
                "description": "Strike with precision in the Venom suit! Its bold green and black design, inspired by toxic patterns, merges sleek style with deadly agility. Perfect for racers who embrace their wild side.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/n/sq_sd/c4e3d941-94fa-4746-ae80-074d8516449d.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Striker - Ultraviolet",
                "slug": "s_violet-kneepads",
                "description": "Step into the fast lane with Ultraviolet. This suit\u2019s bold purple tones and dynamic design offer high performance with a striking look. Perfect for those who race to stand out.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Striker - Sugar Rush",
                "slug": "s_candy-cane",
                "description": "Sweet meets speed with the Sugar Rush suit! Featuring candy-striped accents and a sleek design, this suit is perfect for racers who want to add some flavor to their fast-paced adventures.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Striker - Trailblazer",
                "slug": "s_weathered",
                "description": "Embrace the elements with the Trailblazer suit! Its weathered leather accents and durable design are perfect for racers who thrive in tough conditions. Built to endure, built to win!",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Striker - ChromoFlow",
                "slug": "s_tie-dye",
                "description": "Blend retro charm with futuristic vibes in the ChromoFlow suit! Featuring tie-dye-inspired accents, this suit brings color and energy to your high-speed adventures.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Striker - Dot Matrix",
                "slug": "s_polka-dot",
                "description": "Break the mold with the Dot Matrix suit! Its playful polka dot design combines fun with futuristic speed, keeping you both stylish and agile as you zip past the competition.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Armor - Force",
                "slug": "s_super",
                "description": "Channel unstoppable power with the Force suit! Its bold green and black design stands out as you tear through the track. Built for racers who dominate with strength and precision.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/k/sq_sd/93bb7048-efce-4cc4-a73e-6b15f1a443da.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Armor - Cosmic Strider",
                "slug": "s_sci-fi",
                "description": "The Cosmic Strider suit blends futuristic patterns with sleek design. Built for comfort and performance, this suit lets you glide through every challenge with speed and high-tech style.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/q/sq_sd/5967eed9-d6ba-4ec2-941a-a0286e845b5d.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Armor - Drift",
                "slug": "s_float",
                "description": "The Drift suit blends earthy orange and sky-blue hues, offering a balance of grounded style and a lightweight feel. Designed to keep you effortlessly gliding through every race.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/78f7fac4-fa43-4803-9608-38692f53822a.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Armor - Nebula",
                "slug": "s_alien",
                "description": "Race in the unknown with the Nebula suit. Its otherworldly patterns and sleek design give you an extraterrestrial edge, built for those who want to dominate the track in style.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/l/sq_sd/1312bc9c-ceb3-4682-a7a0-cd7c8ed5ed82.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Armor - Impact",
                "slug": "s_crash",
                "description": "The Impact suit combines bold black and yellow panels for a striking look. With textured padding for added style and function, it\u2019s designed to withstand the toughest races and turns heads.",
                "price": 30,
                "price_discounted": 30,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/f/sq_sd/83c19cc1-aaa8-4d2d-adda-b6faf8d304ac.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Holiday Hoversuit",
                "slug": "s_christmas2024",
                "description": "",
                "price": 50,
                "price_discounted": 50,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/f/sq_sd/83c19cc1-aaa8-4d2d-adda-b6faf8d304ac.webp",
                "item_class": "suit",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Shadow Wave",
                "slug": "hc_black-blue",
                "description": "A sleek gradient of black to deep blue, adding a cool, mysterious vibe to your look on the track.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/2/sq_sd/f188290f-e4ca-4e32-a587-c50c3ae15a9e.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Ember Noir",
                "slug": "hc_black-red",
                "description": "A fiery blend of black and deep red, adding an intense, bold touch to your look on the track.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/h/sq_sd/c4aeb1ae-cb7d-4dc3-a6c4-4e8d47c5ffb7.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Sage Hue",
                "slug": "hc_blond-green",
                "description": "A soft blend of blonde and green hues, adding a touch of earthy sophistication to your racing style.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/ad47321b-8ca1-4d27-bf29-d412f3e32e05.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Rose Gold",
                "slug": "hc_blond-pink",
                "description": "A delicate mix of pink and blonde tones, creating a soft, stylish hue perfect for racers with a unique touch.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/p/sq_sd/726c4e55-8e2f-4db0-978c-b63d2f5b2c3f.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Midnight Tide",
                "slug": "hc_blue",
                "description": "Dive deep with this blue gradient that shifts from ocean teal to midnight navy, perfect for racers who ride with cool confidence.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/e/sq_sd/73cd4bf4-c13c-45be-b925-cd5b22c12b51.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Copper Flame",
                "slug": "hc_brown-orange",
                "description": "A bold mix of warm brown and vibrant orange, this color adds a fiery edge to your style on the track.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/91c57d0f-3fe7-4230-a785-0797bab7059e.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Emerald Surge",
                "slug": "hc_green",
                "description": "A vibrant emerald green that energizes your look, perfect for racers who want to channel nature's speed and power.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/y/sq_sd/c2e7614f-ff94-4780-b1fe-32e5a4994cb5.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Pink Flash",
                "slug": "hc_pink",
                "description": "A striking hot pink shade that blazes with energy, giving your hair a vibrant, electrifying look on the track.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/9f4eee22-9695-47c8-a2cc-3d397c585d81.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Frosted Pink",
                "slug": "hc_platinum-pink",
                "description": "A cool platinum that fades into vibrant pink, giving you a striking blend of icy elegance and bold flair.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/c/sq_sd/a1e4edfe-3ee5-42b1-a845-aeb555722c43.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Ember Blaze",
                "slug": "hc_red",
                "description": "A fiery red that fades into deep black, capturing the intense heat and bold energy of a blazing ember.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/u/sq_sd/5fc0dc03-477c-4840-8178-70549151f3d0.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Dusk Fade",
                "slug": "hc_sunset",
                "description": "A deep blend of red, purple, and black, capturing the dramatic hues of twilight for a bold, moody look.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/t/sq_sd/e3838bf3-eb98-4ee0-ba4e-c7f638d425d7.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Prism Glow",
                "slug": "hc_unicorn",
                "description": "A vibrant blend of purples, pinks, and blues, this color shifts like a prism, making every move magical.",
                "price": 10,
                "price_discounted": 10,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/q/sq_sd/7d1c5da4-5ac4-4461-9faf-665e872e828d.webp",
                "item_class": "hairColor",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Sleek Bob",
                "slug": "h_f2",
                "description": "A sleek, aerodynamic bob that cuts through the air just as you cut through the competition. Designed for racers who want style and speed in perfect harmony.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/r/sq_sd/3e5abc89-7f7a-4e2a-9471-a634f218c7db.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Turbo Tails",
                "slug": "h_f3",
                "description": "These playful pigtails keep your hair out of the way as you race to victory. Perfect for racers who want to add a dash of fun while staying fast and focused.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/z/sq_sd/4679abdf-42d6-4acf-abf9-be49ddae2e3b.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Twin Turbo",
                "slug": "h_f4",
                "description": "Power through the track with these sleek, high pigtails! Built for aerodynamic flow, they add flair and function, keeping you sharp and stylish as you zoom past the competition.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/p/sq_sd/45b259c1-ea52-49cc-820b-eacbd408a66d.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Velo Bun",
                "slug": "h_f5",
                "description": "Keep your cool and your hair locked in place with the Velo Bun! Sleek and aerodynamic, it\u2019s perfect for racers who need style without distraction while dominating every curve of the track.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/b/sq_sd/ac7ca15e-9627-4203-a36a-d658942daa31.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Speed Tail",
                "slug": "h_f6",
                "description": "The Speed Tail keeps your hair sleek and secure, ensuring no distractions as you blaze through the competition. Stylish and practical for racers who need both speed and focus.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/s/sq_sd/fa7a5ce3-90f8-45db-ac76-dbd4375ca6d9.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Razor Run",
                "slug": "h_f7",
                "description": "Slice through the competition with Razor Run. The edgy undercut paired with a high ponytail delivers both style and speed, keeping you aerodynamic as you dominate the track.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/2/sq_sd/85908c66-2000-46ec-82c7-d15f41c4add5.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Braided Pulse",
                "slug": "h_f8",
                "description": "Feel the energy with the Braided Pulse! This stylish, braided look keeps you sharp and secure as you navigate the track with intensity, speed and flair.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/x/sq_sd/4f669c91-6b43-4213-8038-304bb3da520b.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Twin Stream",
                "slug": "h_f9",
                "description": "Glide effortlessly with the Twin Stream! These sleek pigtails are designed for balance and style, letting you breeze through the track with confidence and speed. Perfect for racers who want a dynamic edge.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/s/sq_sd/8b569d92-27ca-4acc-82e5-f6b3e0cd999c.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Spiral Knot",
                "slug": "h_f10",
                "description": "Take control with the Spiral Knot. This braided bun brings style and precision to the track, keeping you focused and streamlined as you weave through the competition.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/a752940c-491a-4362-9093-32fcb176c01e.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Sidewinder",
                "slug": "h_f11",
                "description": "Carve through the competition with the Sidewinder. This sharp, asymmetrical cut keeps you looking fierce while maintaining aerodynamic flow, perfect for racers who ride with both style and speed.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/u/sq_sd/f77f5876-accb-4eb0-8e97-1cb9a9831d7d.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Sprint Cut",
                "slug": "h_m2",
                "description": "Keep it sharp and simple with the Sprint Cut. This short, sleek style is perfect for racers who want to minimize wind resistance and keep their head in the game.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/w/sq_sd/f02ea23a-4504-4b47-a1c0-7ed8c3f3ab92.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Zoom Flow",
                "slug": "h_m3",
                "description": "Achieve effortless speed with the Zoom Flow. This style offers a balanced, medium-length cut that stays sharp at high speeds. It\u2019s perfect for racers who want to blaze through the competition.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/w/sq_sd/03fe4c1a-3d41-4daf-9686-6fad56976ff7.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "TrailBlazer",
                "slug": "h_m4",
                "description": "The TrailBlazer offers a sleek, braided look that combines style and power. Keep your cool while speeding past opponents, with hair that\u2019s built to perform under pressure.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/f/sq_sd/bfa9d3fc-2472-47f5-810d-2c72e51173cd.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "High Edge",
                "slug": "h_m5",
                "description": "The High Edge gives you a clean, sharp look that cuts through the air with precision. Perfect for racers who want to stay cool and stylish while dominating the course.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/h/sq_sd/9534458c-6ebf-4803-8fcf-3115c0fec34e.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Skyline",
                "slug": "h_m6",
                "description": "Bold, rebellious, and unstoppable. The Skyline mohawk gives you an edge both on the track. Elevate your style and leave competitors behind with this fierce, aerodynamic cut.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/h/sq_sd/ae6f2285-4bcb-4711-95bd-5ec22ac48589.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Dart Fade",
                "slug": "h_m7",
                "description": "With a sharp fade and distinct side cut, will have you standing out on the track. Glide through the competition with a look as fast and bold as your moves.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/8/sq_sd/302725c8-f14b-4770-8007-3e8bbbdaf467.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Side Wave",
                "slug": "h_m8",
                "description": "This smooth, flowing haircut creates a wave-like effect as you zoom past the competition. The relaxed fringe adds movement to your style, perfect for riders who want to glide through the track with ease.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/i/sq_sd/3e6e8de4-22bf-4ce8-be5b-da5cb969495b.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Braided Fury",
                "slug": "h_m10",
                "description": "Unleash your inner racer with the Braided Fury. The intricate braids add style, while the tied-back ponytail ensures you're aerodynamic and ready for the track.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/z/sq_sd/39b0df2f-0c02-43e0-b101-fa1ca2bd74df.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Blade Knot",
                "slug": "h_m11",
                "description": "This bold undercut with a top knot is for racers who thrive on sharp turns and quick maneuvers. Stylish and edgy, it keeps you cool under pressure on every race.",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/t/sq_sd/03c27cfe-59a1-4290-8097-ebccfdfe690e.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Thunder Bolt",
                "slug": "h_m9",
                "description": "With a bold side pattern and sleek ponytail, the Thunder Bolt haircut strikes with sharpness and speed. Designed for racers who want to leave an electrifying impression on the track!",
                "price": 20,
                "price_discounted": 20,
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/m/sq_sd/885b15f6-61df-4c3c-8ac6-358fa816a490.webp",
                "item_class": "headwear",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Thunder Canyon",
                "slug": "l_canyon",
                "description": "",
                "price": 80,
                "price_discounted": 80,
                "model": null,
                "thumbnail": "",
                "item_class": "location",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Polar World",
                "slug": "l_snow",
                "description": "",
                "price": 80,
                "price_discounted": 80,
                "model": null,
                "thumbnail": "",
                "item_class": "location",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "Thrill City",
                "slug": "l_city",
                "description": "",
                "price": 80,
                "price_discounted": 80,
                "model": null,
                "thumbnail": "",
                "item_class": "location",
                "type": "singular",
                "expires_after": 0
            },
            {
                "name": "1-Year Premium Pass",
                "slug": "b_one-year-premium-pass",
                "description": "",
                "price": 400,
                "price_discounted": 400,
                "model": null,
                "thumbnail": "",
                "item_class": "bundle",
                "type": "singular",
                "expires_after": 366
            }
        ],
        "myItems": [
            {
                "name": "Cyber - Sonic Surge",
                "slug": "h_soundwaves",
                "description": "Turn up the volume with the Sonic Surge helmet! Featuring dynamic blue waves and vibrant design, this helmet commands attention, letting you race to the beat with electrifying style.",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/x/sq_sd/791b74a1-0235-4146-8df4-0aa82be7ba3c.webp",
                "item_class": "headwear",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            },
            {
                "name": "Thunder Bolt",
                "slug": "h_m9",
                "description": "With a bold side pattern and sleek ponytail, the Thunder Bolt haircut strikes with sharpness and speed. Designed for racers who want to leave an electrifying impression on the track!",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/m/sq_sd/885b15f6-61df-4c3c-8ac6-358fa816a490.webp",
                "item_class": "headwear",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            },
            {
                "name": "Sidewinder",
                "slug": "h_f11",
                "description": "Carve through the competition with the Sidewinder. This sharp, asymmetrical cut keeps you looking fierce while maintaining aerodynamic flow, perfect for racers who ride with both style and speed.",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/u/sq_sd/f77f5876-accb-4eb0-8e97-1cb9a9831d7d.webp",
                "item_class": "headwear",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            },
            {
                "name": "Midnight Tide",
                "slug": "hc_blue",
                "description": "Dive deep with this blue gradient that shifts from ocean teal to midnight navy, perfect for racers who ride with cool confidence.",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/e/sq_sd/73cd4bf4-c13c-45be-b925-cd5b22c12b51.webp",
                "item_class": "hairColor",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            },
            {
                "name": "Vertex - Tropical Blitz",
                "slug": "s_pineapple",
                "description": "Bring the heat with the Tropical Blitz suit! Featuring a bright yellow and green design, it\u2019s made for racers who want to stand out with a fresh, bold look.",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/j/sq_sd/e761e8ac-6858-484a-a377-9db8947c5700.webp",
                "item_class": "suit",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            },
            {
                "name": "Viper - Tech Flux",
                "slug": "hb_circuitry",
                "description": "Where tech meets speed! This electrified design pulses with futuristic energy, built for lightning-fast racing. Thrive on advanced tech and sleek, fast-paced thrills.",
                "model": null,
                "thumbnail": "https://media.heyvr.io/resized/platform/arcade/game/catalog/thumbnail/o/sq_sd/c7ea9228-4631-43da-8b0f-92a75d1cc56e.webp",
                "item_class": "hoverboard",
                "type": "singular",
                "owned_count": 1,
                "acquired_at": "2023-03-09T03:44:26.000000Z",
                "expires_on": ""
            }
        ]
    },
    "leaderboard": {
        "scores": [
            {
                "rank": 1,
                "score": 52353,
                "user": "MCHoff",
                "created_at": "2024-09-19T08:13:46.000000Z"
            },
            {
                "rank": 2,
                "score": 61036,
                "user": "Dudge",
                "created_at": "2024-09-17T17:10:42.000000Z"
            },
            {
                "rank": 3,
                "score": 67378,
                "user": "TheFitnessResort",
                "created_at": "2024-09-19T11:54:36.000000Z"
            },
            {
                "rank": 4,
                "score": 101883,
                "user": "maruzayln",
                "created_at": "2024-09-18T17:21:30.000000Z"
            },
            {
                "rank": 5,
                "score": 101907,
                "user": "Loti",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 6,
                "score": 111907,
                "user": "Marcelia",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 7,
                "score": 121907,
                "user": "igniga",
                "created_at": "2024-09-22T19:14:57.000000Z"
            }
        ],
        "mine": [
            {
                "rank": 1,
                "score": 52353,
                "user": "MCHoff",
                "created_at": "2024-09-19T08:13:46.000000Z"
            },
            {
                "rank": 2,
                "score": 61036,
                "user": "Dudge",
                "created_at": "2024-09-17T17:10:42.000000Z"
            },
            {
                "rank": 3,
                "score": 67378,
                "user": "TheFitnessResort",
                "created_at": "2024-09-19T11:54:36.000000Z"
            },
            {
                "rank": 4,
                "score": 101883,
                "user": "heyVRUser",
                "created_at": "2024-09-18T17:21:30.000000Z"
            },
            {
                "rank": 5,
                "score": 101907,
                "user": "Loti",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 6,
                "score": 111907,
                "user": "Another",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 7,
                "score": 121907,
                "user": "igniga",
                "created_at": "2024-09-22T19:14:57.000000Z"
            }
        ],
        "friends": [
            {
                "rank": 1,
                "score": 52353,
                "user": "MCHoff",
                "created_at": "2024-09-19T08:13:46.000000Z"
            },
            {
                "rank": 2,
                "score": 61036,
                "user": "Yinch",
                "created_at": "2024-09-17T17:10:42.000000Z"
            },
            {
                "rank": 3,
                "score": 67378,
                "user": "TheFitnessResort",
                "created_at": "2024-09-19T11:54:36.000000Z"
            },
            {
                "rank": 4,
                "score": 101883,
                "user": "heyVRUser",
                "created_at": "2024-09-18T17:21:30.000000Z"
            },
            {
                "rank": 5,
                "score": 101907,
                "user": "Loti",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 6,
                "score": 111907,
                "user": "Marcelia",
                "created_at": "2024-09-22T19:14:57.000000Z"
            },
            {
                "rank": 7,
                "score": 121907,
                "user": "One",
                "created_at": "2024-09-22T19:14:57.000000Z"
            }
        ]
    },
    "saveGame": {
        "slots": {
            "1": {
                "name": "Save Slot 1",
                "save_data": "{\"key_1\":\"Value 1\",\"key_2\":\"Value 2\"}",
                "created_at": "2023-03-10T19:54:09.000000Z",
                "updated_at": "2023-03-10T19:59:52.000000Z"
            },
            "2": {
                "name": "Save Slot 2",
                "save_data": `{
                    "version": 4,
                    "fitabux": 1435,
                    "hairColor": "iap:hc_blue",
                    "skinColor": "bi:sc_0",
                    "avatarType": 0,
                    "goldMedals": 0,
                    "suitVariant": "iap:s_pineapple",
                    "bronzeMedals": 0,
                    "silverMedals": 0,
                    "dailyFitPoints": 0,
                    "platinumMedals": 0,
                    "totalFitPoints": 1435,
                    "dailyRewardTier": -1,
                    "headwearVariantMale": "iap:h_m9",
                    "headwearVariantFemale": "iap:h_f11",
                    "fitabuxInventory": [

                    ],
                    "hoverboardVariant": "iap:hb_circuitry",
                    "currentMidnightTime": 1727042400000,
                    "rewards": [0,0,0],
                    "gameSettingsData": {
                         "policyAccepted":true,
                         "musicVolume":10,
                         "quietMode":false,
                         "voiceVolume":10,
                         "microphoneEnabled":true,
                         "voiceP2P":false,
                         "voiceMediasoup":true,
                         "gameConfigOnLoad":{"location":"canyon","mode":"race","track":0},
                         "gameOnlineConfigOnLoad":{"isOnline":false,"roomID":null,"isPrivateRoom":false},
                         "showTutorialOnStart":false
                    }
                }`,
                "created_at": "2023-03-05T18:14:01.000000Z",
                "updated_at": "2023-03-07T19:15:01.000000Z"
            },
            "3": {
                "name": "Save Slot 3",
                "save_data": "{\"key_5\":\"Value 5\",\"key_6\":\"Value 6\"}",
                "created_at": "2023-03-10T19:49:46.000000Z",
                "updated_at": "2023-04-10T11:40:41.000000Z"
            },
            "4": {
                "name": "Save Slot 4",
                "save_data": "{\"key_7\":\"Value 7\",\"key_8\":\"Value 8\"}",
                "created_at": "2023-02-13T17:19:11.000000Z",
                "updated_at": "2023-02-13T17:19:11.000000Z"
            },
            "5": {
                "name": "Save Slot 5",
                "save_data": "{\"key_9\":\"Value 9\",\"key_10\":\"Value 10\"}",
                "created_at": "2023-03-05T18:13:27.000000Z",
                "updated_at": "2023-03-12T10:11:02.000000Z"
            }
        }
    },
    "user": {
        "loggedIn": true,
        "responseDelay": 1000,
        "username": "heyVRUser",
        "userBalance": 4500,
        "alias": "heyVRUserAlias"
    },
    "config": {
        "arena.token": "YOUR API TOKEN",
        "game.slug": "sandbox-game",
        "debug.enabled": true
    }
};