import { Object3D } from "@wonderlandengine/api";
import { common } from "src/hoverfit/common.js";
import { SpringStatusEffect, SpringStatusEffectParams } from "src/hoverfit/game/hoverboard/status-effects/implementations/spring-status-effect.js";
import { StatusEffectTarget } from "src/hoverfit/game/hoverboard/status-effects/status-effect-target.js";
import { PickupGrabber } from "../pickup-grabber.js";
import { PickupType } from "../pickup.js";
import { SyncedPickup, SyncedPickupParams } from "./synced-pickup.js";

export class PickupSpringParams extends SyncedPickupParams { }

export class PickupSpring extends SyncedPickup {
    declare protected _params: PickupSpringParams;

    constructor(pickupObject: Object3D, params: PickupSpringParams) {
        super(PickupType.Spring, pickupObject, params);
    }

    protected _onPickupHook(pickupGrabber: PickupGrabber) {
        const statusEffectsManager = common.tracksManager.getStatusEffectsManager();

        const statusEffectParams = new SpringStatusEffectParams();
        const statusEffect = new SpringStatusEffect(pickupGrabber as unknown as StatusEffectTarget, statusEffectParams);

        statusEffectsManager.addStatusEffect(statusEffect);
    }
}