import { Component, Object3D, WonderlandEngine } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import * as Colyseus from "colyseus.js";
import { EventEmitter } from "events";
import { GameMode, HOVERBOARD_MULTIPLAYER_STATES, HOVERBOARD_PLAYER_MULTIPLAYER_STATES, PROTOCOL_VERSION, TrackedHoverboardData, TrackedHoverboardState, TrackedPlayer, TrackedTransform, VALID_CONFIGURATIONS } from "hoverfit-shared-netcode";
import { Variable } from "lazy-widgets";
import { NonFunctionPropNames } from "node_modules/@colyseus/schema/lib/types/HelperTypes.js";
import { AudioSink } from "src/hoverfit/audio/voip/audio-sink.js";
import { VoIPPeer } from "src/hoverfit/audio/voip/voip-peer.js";
import { VoIPUtils } from "src/hoverfit/audio/voip/voip-utils.js";
import { Gender } from "src/hoverfit/data/values/gender.js";
import { LeaderboardUtils } from "src/hoverfit/game/track/leaderboard/leaderboard-utils.js";
import { LeaderboardType } from "src/hoverfit/game/track/leaderboard/leaderboards-manager.js";
import { TagResultBoardData } from "src/hoverfit/game/track/results/tag-results-board.js";
import { AnalyticsUtils, GamepadButtonEvent, GamepadButtonID, Globals, MathUtils, Timer } from "wle-pp";
import * as backends from "../../../../build-data/backends.json" assert { type: "json" };
import { AudioChannelName } from "../../audio/audio-manager/audio-channel.js";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { AudioSinkComponent } from "../../audio/voip/components/audio-sink-component.js";
import { VoIPHelper } from "../../audio/voip/voip-helper.js";
import { AvatarComponent } from "../../avatar/components/avatar-component.js";
import { common } from "../../common.js";
import { HoverboardGameConfig } from "../../data/game-configuration.js";
import { HoverboardDebugs } from "../../game/components/hoverboard-debugs-component.js";
import { GAME_STATES } from "../../game/game-states.js";
import { setNPCCountdownEndTimestamp } from "../../game/hoverboard/components/npc-controller-component.js";
import { PriorityLevel } from "../../misc/data-structs/expiring-priority-queue.js";
import { isAnotherSceneLoading } from "../../misc/load-scene/load-scene.js";
import { MessagePopupParams } from "../../ui/popup/implementations/message-popup.js";
import { PopupIconImage } from "../../ui/popup/popup.js";
import { replaceSearchParams } from "../../utils/url-utils.js";
import { RoomState } from "../room-proxy.js";
import { NetworkPlayerComponent } from "./network-player-component.js";
import { KEY_TO_INDEX, KEYS, synchedObjects } from "./network-sync-component.js";

const MAX_CONN_RETRIES: number = 1;

export class RoomData {
    roomNumber: number | null = null;
    privateRoom: boolean = false;

    constructor(dataToCopy?: RoomData) {
        if (dataToCopy != null) {
            this.roomNumber = dataToCopy.roomNumber;
            this.privateRoom = dataToCopy.privateRoom;
        }
    }
}

export class HoverboardNetworkingComponent extends Component {
    static TypeName = "hoverboard-networking";

    @property.bool(false)
    spatialAudio!: boolean;

    room: Colyseus.Room<TrackedHoverboardState> | null = null;
    localPlayer: TrackedPlayer | null = null;
    otherPlayers: Map<string, TrackedPlayer> = new Map();
    otherPlayerObjects: Map<string, Object3D> = new Map();

    npcSeed: number = MathUtils.randomInt(0, 65534);

    voip!: (VoIPHelper<string> & EventEmitter);
    microphonePermission: Variable<PermissionState | "error" | "unsupported" | null> = new Variable(null);

    private url: string | null = null;
    private joiningRoom: boolean = false;
    private client!: Colyseus.Client;
    private localPlayerKey: string = "";
    private nameListener = this.setOwnName.bind(this);
    private fitPointsListener = this.setOwnFitPoints.bind(this);
    private initNetworkTimer: Timer = new Timer(0.5);

    private enablePlayerJoinedTimer: Timer = new Timer(3);

    private microphoneStatus: PermissionStatus | undefined;
    private sinkComponentObject!: Object3D;
    private sink!: AudioSink;
    private updateVoIPWarningsCallback = this.updateVoIPWarnings.bind(this);
    private updateVoIPWarningsCallbackSetup: boolean = false;

    static onRegister(engine: WonderlandEngine) {
        engine.registerComponent(AudioSinkComponent);
    }

    onActivate() {
        common.playerData.listen(this.nameListener, "name");
        this.setOwnName();
        common.playerData.listen(this.fitPointsListener, "totalFitPoints");
        this.setOwnFitPoints();
    }

    onDeactivate() {
        common.playerData.unlisten(this.fitPointsListener);
        common.playerData.unlisten(this.nameListener);
    }

    init() {
        common.hoverboardNetworking = this;

        this.sinkComponentObject = this.object.pp_addChild();
        const audioSinkComponent = this.sinkComponentObject.addComponent(AudioSinkComponent, {
            rotateForward: true, // XXX wle-pp points the wrong way
        })!;
        this.sink = audioSinkComponent.sink;

        // XXX let the user override the backend
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const preferredBackend = urlParams.get("backend");

        if (preferredBackend !== null) {
            const wantedURL = (backends as unknown as Record<string, string>)[preferredBackend];
            if (wantedURL) {
                console.warn(`Backend URL overwritten with ID "${preferredBackend}", which resolves to URL "${wantedURL}"`);
                this.url = wantedURL;
            } else {
                console.warn(`Invalid backend ID "${preferredBackend}"`);
            }
        }

        if (!this.url) {
            console.debug(`Using default backend, which resolves to URL "${DEFAULT_COLYSEUS_BACKEND_URL}"`);
            this.url = DEFAULT_COLYSEUS_BACKEND_URL;
        }

        this.client = new Colyseus.Client(this.url);

        // XXX VoIPHelper keeps track of peers in their own way. it's set up to
        // use sessionIds as peer IDs
        this.voip = new VoIPHelper({
            iceServers: [
                {
                    "urls": VOIP_STUN_URLS
                }
            ],
            spatialAudio: this.spatialAudio,
            audioSink: this.sink,
        }) as (VoIPHelper<string> & EventEmitter);

        this.voip.addListener("peer-added", function (voipPeer) {
            const audioID = AudioID.VOIP_ + voipPeer.id;
            common.audioManager.addSourceAudioToChannel(audioID, voipPeer.audioSource, AudioChannelName.VOIP);
        });

        this.voip.addListener("peer-removed", function (voipPeer) {
            const audioID = AudioID.VOIP_ + voipPeer.id;
            common.audioManager.removeAudio(audioID);
        });

        common.MAIN_CHANNEL.on("try-disconnect", () => {
            if (this.room) {
                this.tryDisconnect();

                common.roomData.roomNumber = null;
                common.roomData.privateRoom = false;
            }
        });

        this.microphonePermission.watch(() => {
            if (this.microphonePermission.value == "granted") {
                this._requestMicrophone();
            } else {
                this.revokeMicrophone();
            }
        }, true);

        try {
            navigator.permissions.query({ name: "microphone" as PermissionName }).then((status) => {
                this.microphoneStatus = status;
                status.onchange = this.updateMicrophonePermissions.bind(this, "error");
                this.updateMicrophonePermissions("error");
            });
        } catch (err) {
            console.error(err);
            this.updateMicrophonePermissions((err as Error).name === "TypeError" ? "granted" : "error");
        }
    }

    start() {
        this.sinkComponentObject.pp_setParent(Globals.getPlayerObjects(this.engine)!.myHead!);
        this.sinkComponentObject.pp_resetTransformLocal();
    }

    _start() {
        if (Globals.isDebugEnabled() && HoverboardDebugs.toggleVOIPTypeShortcutEnabled) {
            Globals.getRightGamepad(this.engine)!.registerButtonEventListener(GamepadButtonID.TOP_BUTTON, GamepadButtonEvent.PRESS_END, 0, () => {
                if (this.voip) {
                    console.debug("toggling p2p and mediasoup off");
                    const hadP2P = this.voip.p2pEnabled;
                    this.voip.toggleP2P(false);
                    const hadMS = this.voip.mediasoupEnabled;
                    this.voip.toggleMediasoup(false);
                    setTimeout(() => {
                        if (hadP2P) {
                            console.debug("toggling p2p back on");
                            this.voip.toggleP2P(true);
                        }
                        if (hadMS) {
                            console.debug("toggling mediasoup back on");
                            this.voip.toggleMediasoup(true);
                        }
                    }, 500);
                }
            });
        }
    }

    resetPlayer() {
        const menu = common.menu;
        if (menu) {
            menu.returnToBalcony();
        }
    }

    setOwnName() {
        if (this.room) {
            this.room.send("set-name", { name: common.playerData.name });
        }
    }

    setOwnFitPoints() {
        if (this.room) {
            const value = common.balcony.isPlayerOnBalcony.value ? common.playerData.totalFitPoints : 0;
            if (this.localPlayer!.fitPoints !== value) {
                this.room.send("set-fitpoints", { value });
            }
        }
    }

    makeRoomOptions(roomNumber: number | null) {
        const iapCC = common.iapContentController;

        return {
            protocolVersion: PROTOCOL_VERSION,
            // TODO i don't like that we have to coerce room number into a
            //      string. we should force consistent types on the codebase
            //      when we port everything to typescript
            desiredRoomNumber: roomNumber === null ? null : `${roomNumber}`,
            gameConfiguration: {
                location: common.gameConfig.location,
                mode: common.gameConfig.mode,
                track: common.gameConfig.track,
            },
            npcsAmount: common.gameConfig.npcsAmount.value,
            lapsAmount: common.gameConfig.lapsAmount.value,
            tagDuration: common.gameConfig.tagDuration.value,
            npcsDifficulty: common.gameConfig.npcsDifficulty.value,
            maxClients: common.gameConfig.maxClients,
            desiredName: common.playerData.name,
            desiredFitPoints: common.playerData.totalFitPoints,
            desiredAvatarType: common.playerData.avatarType,
            desiredAvatarSkinColor: iapCC.adjustedSkinColor.value,
            desiredAvatarSuitVariant: iapCC.adjustedSuitVariant.value,
            desiredAvatarHeadwearVariantMale: iapCC.adjustedHeadwearVariantMale.value,
            desiredAvatarHeadwearVariantFemale: iapCC.adjustedHeadwearVariantFemale.value,
            desiredAvatarHairColor: iapCC.adjustedHairColor.value,
            desiredHoverboardVariant: iapCC.adjustedHoverboardVariant.value,
            privateRoom: false,
            failIfIdNotAvailable: false
        };
    }

    async join(roomNumber: number | null = null,) {
        return this.joinOrCreate(roomNumber, undefined, false);
    }

    async create(roomNumber: number | null = null, privateRoom: boolean) {
        if (!common.playerData.gameSettings.policyAccepted.value) {
            common.kioskLowerUI.displayTermsAndConditions(() => { this.create(roomNumber, privateRoom); });
            return;
        }

        if (this.joiningRoom) {
            return;
        }

        this.joiningRoom = true;
        const MAIN_CHANNEL = common.MAIN_CHANNEL;

        try {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;

            // exit room if already joined
            MAIN_CHANNEL.emit("try-disconnect");

            // if join failed/not joined, create new room
            MAIN_CHANNEL.emit("room-create", roomNumber, privateRoom);

            const roomOptions = this.makeRoomOptions(roomNumber);
            roomOptions.privateRoom = privateRoom;
            this.replaceRoom(await this.client.create("hoverfit", roomOptions));
            searchParams.set("room", this.room!.id);

            // update url with room id
            replaceSearchParams(url, searchParams);

            if (this.room != null) {
                // initialize room
                this.initializeRoom(true, privateRoom);
            }
        } catch (e) {
            console.error("join error", e);
            MAIN_CHANNEL.emit("room-create-error", e);
        } finally {
            this.joiningRoom = false;
        }
    }

    async joinOrCreate(roomNumber: number | null = null, privateRoom: boolean | null = null, tryCreateOnJoinFail: boolean = true) {
        if (!common.playerData.gameSettings.policyAccepted.value) {
            common.kioskLowerUI.displayTermsAndConditions(() => { this.joinOrCreate(roomNumber, privateRoom, tryCreateOnJoinFail); });
            return;
        }

        if (this.joiningRoom) {
            return;
        }

        this.joiningRoom = true;
        const MAIN_CHANNEL = common.MAIN_CHANNEL;

        try {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;

            // exit room if already joined
            MAIN_CHANNEL.emit("try-disconnect");

            MAIN_CHANNEL.emit("room-join", roomNumber);

            // get random existing room to join if none were specified
            if (roomNumber === null) {
                const availableRooms = await this.client.getAvailableRooms();
                const filteredRooms = availableRooms.filter(function (availableRoom) {
                    // get a romm that:
                    // 1. is not full (already filtered by getAvailableRooms)
                    // 2. has players
                    // 3. has empty spots on the track (trackConfig.maxPlayers not exceeded)
                    const meta = availableRoom.metadata;
                    if (meta && (typeof meta === "object") && availableRoom.clients > 0) {
                        const locationConfig = VALID_CONFIGURATIONS.get(meta.location);
                        if (!locationConfig) return false;
                        const modeConfig = locationConfig.modes.get(meta.mode);
                        if (!modeConfig) return false;
                        const trackConfig = modeConfig.tracks[meta.track];
                        if (!trackConfig) return false;
                        return trackConfig.maxPlayers === null || availableRoom.clients <= trackConfig.maxPlayers - 1;
                    }

                    return false;
                });

                if (filteredRooms.length > 0) {
                    const pickedRoom = filteredRooms[Math.trunc(filteredRooms.length * Math.random())];
                    roomNumber = parseInt(pickedRoom.roomId);
                }
            }

            // join existing room by id
            let joined = false;
            const roomOptions = this.makeRoomOptions(roomNumber);
            if (privateRoom != null) {
                roomOptions.privateRoom = privateRoom;
            }

            let tryJoinButRoomNotExists = false;

            let retries = 0;
            if (roomNumber !== null) {
                while (!joined) {
                    try {
                        this.replaceRoom(await this.client.joinById(roomNumber.toString(), roomOptions));
                        searchParams.set("room", this.room!.id);
                        joined = true;
                    } catch (error) {
                        if ((error as { code: number }).code == 4989) {
                            searchParams.set("room", roomNumber.toString());

                            const newRoomData = new RoomData();
                            newRoomData.roomNumber = roomNumber;
                            if (privateRoom != null) {
                                newRoomData.privateRoom = privateRoom;
                            }

                            const newGameConfig = HoverboardGameConfig.fromServerJSONString((error as Error).message);
                            if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false) || this.isDestroyed) {
                                return;
                            }

                            roomOptions.gameConfiguration = newGameConfig.toIdentifier();
                        } else if ((error as { code: number }).code === 4400) {
                            throw error;
                        } else {
                            console.warn("Failed to join room by id", error);
                            tryJoinButRoomNotExists = (error as { code: number }).code == 4212; // room not found error

                            if (!tryCreateOnJoinFail) {
                                throw error;
                            }

                            break;
                        }
                    }

                    if (!joined && ++retries > MAX_CONN_RETRIES) {
                        throw new Error("Maximum connection retries exceeded");
                    }
                }
            }

            // if join failed/not joined, create new room
            if (!joined && tryCreateOnJoinFail) {
                let created = false;
                try {
                    if (tryJoinButRoomNotExists) {
                        roomOptions.failIfIdNotAvailable = true;
                    }

                    this.replaceRoom(await this.client.create("hoverfit", roomOptions));
                    searchParams.set("room", this.room!.id);
                    created = true;
                } catch (error) {
                    if ((error as { code: number }).code == 4989) {
                        if (roomNumber != null) searchParams.set("room", roomNumber.toString());
                        const currentRoomConfig = JSON.parse((error as Error).message);
                        if (!common.gameConfig.matches(currentRoomConfig)) {
                            const newRoomData = new RoomData();
                            newRoomData.roomNumber = roomNumber;
                            if (privateRoom != null) {
                                newRoomData.privateRoom = privateRoom;
                            }

                            const newGameConfig = HoverboardGameConfig.fromServerJSONString((error as Error).message);
                            if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false) || this.isDestroyed) {
                                return;
                            }

                            roomOptions.gameConfiguration = newGameConfig.toIdentifier();
                        }
                    } else if ((error as { code: number }).code === 4400 || roomNumber === null) {
                        throw error;
                    } else {
                        console.warn("Failed to create room with id", roomNumber);
                    }
                }

                if (!created && !joined && roomNumber != null) {
                    console.warn("Try to wait for room creation and join that");
                    // We tried to join but couldn't because the room did not exist
                    // but then we fail to create it, very likely someone was creating it at the same time
                    // Try to join to that room a few times, maybe in between it completes the creation
                    // otherwise just create one
                    let joinAttempts = 10;
                    while (joinAttempts > 0) {
                        joinAttempts--;
                        try {
                            await (new Promise(resolve => setTimeout(resolve, 100)));
                            this.replaceRoom(await this.client.joinById(roomNumber.toString(), roomOptions));
                            searchParams.set("room", this.room!.id);
                            joined = true;
                            break;
                        } catch (error) {
                            if ((error as { code: number }).code == 4989) {
                                searchParams.set("room", roomNumber.toString());

                                const newRoomData = new RoomData();
                                newRoomData.roomNumber = roomNumber;
                                if (privateRoom != null) {
                                    newRoomData.privateRoom = privateRoom;
                                }

                                const newGameConfig = HoverboardGameConfig.fromServerJSONString((error as Error).message);
                                if (!await common.menu.changeGameConfig(newRoomData, newGameConfig, false) || this.isDestroyed) {
                                    return;
                                }

                                roomOptions.gameConfiguration = newGameConfig.toIdentifier();
                                break;
                            } else if ((error as { code: number }).code === 4400) {
                                throw error;
                            }
                        }
                    }

                    if (!joined) {
                        roomOptions.failIfIdNotAvailable = false;

                        this.replaceRoom(await this.client.create("hoverfit", roomOptions));
                        searchParams.set("room", this.room!.id);
                    }
                }
            }

            // update url with room id
            replaceSearchParams(url, searchParams);

            if (this.room != null) {
                // initialize room
                this.initializeRoom(false, privateRoom);
            }
        } catch (e) {
            console.error("join error", e);

            MAIN_CHANNEL.emit("room-join-error", e);
        } finally {
            this.joiningRoom = false;
        }
    }

    initializeRoom(isCreate: boolean, privateRoom: boolean | null) {
        const MAIN_CHANNEL = common.MAIN_CHANNEL;
        MAIN_CHANNEL.emit("room-init-start", this.room);

        common.roomData.roomNumber = parseInt(this.room!.id);

        common.roomProxy.currentRoomPrivateness.value = privateRoom;
        this.room!.state.listen("privateRoom", (value: boolean) => {
            common.roomData.privateRoom = value;
            common.roomProxy.currentRoomPrivateness.value = value;
        });

        this.room!.state.listen("lapsAmount", (value: number) => {
            common.gameConfig.lapsAmount.setValue(value, false);
        });

        this.room!.state.listen("tagDuration", (value: number) => {
            common.gameConfig.tagDuration.setValue(value, false);
        });

        this.room!.state.listen("npcsAmount", (value: number) => {
            common.gameConfig.npcsAmount.setValue(value, false);
        });

        this.room!.state.listen("npcsDifficulty", (value: number) => {
            common.gameConfig.npcsDifficulty.setValue(value, false);
        });

        this.room!.state.listen("npcsSeed", (value: number) => {
            this.npcSeed = value;
        });

        this.room!.state.listen("roundState", (value: number) => {
            common.tracksManager.setRoundStarted(value === HOVERBOARD_MULTIPLAYER_STATES.RACE_STARTED || value === HOVERBOARD_MULTIPLAYER_STATES.RACE_ENDED);
        });

        const readinessIndicator = common.readinessIndicator;
        readinessIndicator.onJoinRoom();

        this.enablePlayerJoinedTimer.start();

        common.networkSync.resetSync();

        try {
            this.room!.state.players.onAdd((player, key) => {
                if (key !== this.room!.sessionId) {
                    const playerObject = common.networkPlayerPool.getEntity();
                    this.otherPlayers.set(key, player);
                    this.otherPlayerObjects.set(key, playerObject);
                    const networkPlayerComponent = playerObject.getComponent(NetworkPlayerComponent);

                    networkPlayerComponent.setSessionId(key);
                    networkPlayerComponent.setEnabled(true);
                    this.syncNetworkPlayerState(player, networkPlayerComponent);

                    // add player to VoIP helper
                    this.voip.addOtherPeer(key);

                    const hoverboardObject = networkPlayerComponent.hoverboardComponent.getHoverboardMeshObject();
                    player.listen("hoverboardVariant", (hoverboard: string) => {
                        common.hoverboardSelector.setHoverboard(hoverboard, hoverboardObject, false, false);
                    });
                    common.hoverboardSelector.setHoverboard(player.hoverboardVariant, hoverboardObject, false, false);

                    const avatar = playerObject.pp_getComponent(AvatarComponent);
                    const avatarFaceComponent = avatar.addFaceComponent();
                    networkPlayerComponent.setFaceComponent(avatarFaceComponent);

                    player.listen("avatarType", (type: Gender) => {
                        common.avatarSelector.setAvatarType(type, avatar, false, false);
                    });
                    common.avatarSelector.setAvatarType(player.avatarType, avatar, false, false);
                    player.listen("skinColor", (color: string) => {
                        common.avatarSelector.setAvatarSkinColor(color, avatar, false, false);
                    });
                    common.avatarSelector.setAvatarSkinColor(player.skinColor, avatar, false, false);
                    player.listen("suitVariant", (suit: string) => {
                        common.avatarSelector.setAvatarSuit(suit, avatar, false, false);
                    });
                    common.avatarSelector.setAvatarSuit(player.suitVariant, avatar, false, false);

                    player.listen("headwearVariantMale", (headwear: string) => {
                        avatar.headwearVariantMale = headwear;
                        common.avatarSelector.setAvatarHeadwear(headwear, avatar, false, false);
                    });

                    player.listen("headwearVariantFemale", (headwear: string) => {
                        avatar.headwearVariantFemale = headwear;
                        common.avatarSelector.setAvatarHeadwear(headwear, avatar, false, false);
                    });

                    let headwearVariant = player.headwearVariantFemale;
                    if (avatar.currentAvatarType == Gender.Male) {
                        headwearVariant = player.headwearVariantMale;
                    }
                    common.avatarSelector.setAvatarHeadwear(headwearVariant, avatar, false, false);

                    player.listen("hairColorVariant", (hairColorVariant: string) => {
                        common.avatarSelector.setAvatarHairColor(hairColorVariant, avatar, false, false);
                    });
                    common.avatarSelector.setAvatarHairColor(player.hairColorVariant, avatar, false, false);

                    player.listen("name", (name: string) => {
                        networkPlayerComponent.setName(name);
                    });
                    networkPlayerComponent.setName(player.name != null ? player.name : "Unnamed");

                    player.listen("fitPoints", (fitPoints: number) => {
                        networkPlayerComponent.setFitPoints(fitPoints);
                    });
                    networkPlayerComponent.setFitPoints(player.fitPoints);

                    player.listen("isRacing", (isRacing: boolean) => {
                        networkPlayerComponent.setRacing(isRacing);
                        networkPlayerComponent.hoverboardComponent.setRacing(isRacing);
                    });
                    networkPlayerComponent.setRacing(player.isRacing);
                    networkPlayerComponent.hoverboardComponent.setRacing(player.isRacing);

                    player.listen("onTrack", (onTrack: boolean) => {
                        networkPlayerComponent.setOnTrack(onTrack);
                    });
                    networkPlayerComponent.setOnTrack(player.onTrack);

                    player.listen("lapsAmount", (lapsAmount: number) => {
                        networkPlayerComponent.setLapsAmount(lapsAmount);

                        if (common.balcony.isPlayerOnBalcony.value && common.tracksManager.isRoundStarted()) {
                            if (lapsAmount == common.gameConfig.lapsAmount.value - 1) {
                                common.tracksManager.getRaceManager().prepareFinishLine(false);
                            }
                        }
                    });

                    for (const synchedObject of synchedObjects) {
                        const playerSynchedObject = (player as unknown as Record<string, TrackedTransform>)[synchedObject];
                        for (const key of KEYS) {
                            const idx = (KEY_TO_INDEX as Record<string, number>)[key] as number;
                            playerSynchedObject.listen(key as NonFunctionPropNames<TrackedTransform>, (newValue: number, _oldValue: number) => {
                                networkPlayerComponent.setTransformIndex(synchedObject, idx, newValue);
                            });
                        }
                    }

                    for (const propertyName of Object.keys(player.hoverboardData)) {
                        player.hoverboardData.listen(propertyName as NonFunctionPropNames<TrackedHoverboardData>, (newValue, _oldValue) => {
                            networkPlayerComponent.setHoverboardDataPropertyValue(propertyName, newValue);
                        });
                    }

                    // TODO networking should not depend on UI, UI should depend
                    //      on networking; do an event system
                    if (this.enablePlayerJoinedTimer.isDone()) {
                        common.popupManager.showQuickMessagePopup(player.name + " joined the room", PopupIconImage.Info);
                    }

                    common.MAIN_CHANNEL.emit("room-player-join", [key, player]);
                } else {
                    // Own player
                    this.localPlayer = player;
                    this.localPlayerKey = key;

                    player.listen("name", (name: string) => {
                        // XXX only set if different so that name doesn't stop
                        // being a default name if it's one
                        if (common.playerData.name !== name) {
                            common.playerData.name = name;
                        }
                    });

                    // We need a different event from the tagged listen, because that can also change during TAG setup and not
                    // just when the race is going
                    this.room!.onMessage("event-player-tagged", () => {
                        common.hoverboard.playerTagged();
                    });
                }

                // All players

                player.listen("onTrack", (onTrack: boolean) => {
                    if (!common.tracksManager.isRoundStarted()) {
                        const playersOnTrack = this.getPlayersOnTrack().length;

                        common.menu.returnAllNPCs();

                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);

                            if (common.gameConfig.mode == GameMode.Race) {
                                const countdown = common.countdown;
                                if (!countdown.visible) {
                                    if (countdown.isUsingFixedPosition()) {
                                        countdown.resetToFixedPosition();
                                        countdown.setVisible(true);
                                        countdown.resetCountdown();
                                    }
                                }
                            }
                        } else {
                            const countdown = common.countdown;
                            countdown.setVisible(false);
                            countdown.resetCountdown();
                        }
                    }
                });

                player.listen("startingPosition", () => {
                    if (!common.tracksManager.isRoundStarted()) {
                        const playersOnTrack = this.getPlayersOnTrack().length;

                        common.menu.returnAllNPCs();

                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);
                        }
                    }
                });

                player.listen("tagged", (tagged: boolean) => {
                    const circularMap = common.circularMap;
                    circularMap.setIndicatorTagged(key, tagged);

                    if (key === this.localPlayerKey) {
                        circularMap.setMapTagged(tagged);
                    } else {
                        this.otherPlayerObjects.get(key)!.getComponent(NetworkPlayerComponent)!.setTagged(tagged);
                    }
                });

                player.listen("invincible", (invincible: boolean) => {
                    if (key != this.localPlayerKey) {
                        this.otherPlayerObjects.get(key)!.getComponent(NetworkPlayerComponent)!.setInvincible(invincible);
                    }
                });

                player.listen("state", (value: number) => {
                    if (value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY || value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_UNREADY) {
                        readinessIndicator.onPlayerJoinRace(player.startingPosition);
                        readinessIndicator.setIndexReadiness(player.startingPosition, value === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);

                        if (key === this.localPlayerKey) {
                            common.menu.setStartButtonReady(value !== HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                        }
                    }
                });
            });

            this.room!.state.players.onRemove((player, key) => {
                if (this.room != null && key !== this.room.sessionId) {
                    // player disconnected. remove corresponding peer from voip
                    // helper
                    this.voip.removeOtherPeer(key);

                    const circularMap = common.circularMap;
                    if (circularMap) {
                        circularMap.hidePlayerOnMap(key);
                    }

                    const playerObject = this.otherPlayerObjects.get(key);
                    if (player.onTrack) {
                        readinessIndicator.onPlayerLeaveRace(player.startingPosition);
                    }

                    common.networkPlayerPool.returnEntity(playerObject);

                    this.otherPlayers.delete(key);
                    this.otherPlayerObjects.delete(key);

                    if (!common.tracksManager.isRoundStarted()) {
                        common.menu.returnAllNPCs();

                        const playersOnTrack = this.getPlayersOnTrack().length;
                        if (playersOnTrack > 0) {
                            common.menu.setupNPCs(true);
                        } else {
                            const countdown = common.countdown;
                            countdown.setVisible(false);
                            countdown.resetCountdown();
                        }
                    }

                    common.MAIN_CHANNEL.emit("room-player-leave", key);
                }
            });

            // TODO: Segregate by game mode
            // Gameplay messaging
            this.room!.onMessage("move-to-track", ({ player }) => {
                common.menu.moveToTrack(false, player.startingPosition);

                if (common.gameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerJoinRace(player.startingPosition);
                    readinessIndicator.setIndexReadiness(player.startingPosition, player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                }
            });

            this.room!.onMessage("return-to-balcony", ({ player: _unusedPlayer, previousStartingPosition }) => {
                common.menu.returnToBalcony(false);

                if (common.gameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerLeaveRace(previousStartingPosition);
                }

                if (common.tracksManager.isRoundStarted()) {
                    if (common.gameConfig.mode == GameMode.Race) {
                        common.tracksManager.getRaceManager().showFinishGoal();
                    }
                }
            });

            this.room!.onMessage("start-round", ({ roundDuration, players, countdownEndServerTimeStamp }) => {
                setNPCCountdownEndTimestamp(countdownEndServerTimeStamp);
                this.roundStart(players);

                if (common.gameConfig.mode == GameMode.Race) {
                    common.leaderboardsManager.getLeaderboard({
                        type: LeaderboardType.Local,
                        mode: GameMode.Race,
                        location: common.gameConfig.location,
                        track: common.gameConfig.trackConfig.id,
                        lapsAmount: common.gameConfig.lapsAmount.value,
                    })!.clearScoresOnNextSubmit();
                }

                let numberOfRacers = 0;
                for (const playerID in players) {
                    const player = players[playerID];

                    if (player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                        numberOfRacers++;
                    }
                }

                common.menu.startRace(false, numberOfRacers, roundDuration);

                common.tracksManager.setRoundStarted(true);
            });

            this.room!.onMessage("round-cancelled", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room!.onMessage("restart-round", () => {
                common.tracksManager.setRoundStarted(false);

                common.menu.finishNPCsRace();
                common.menu.returnAllNPCs();

                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
                balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);

                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC)!;
                if (raceMusicAudio != null) {
                    // TODO Fade yet not implemented
                    // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
                    raceMusicAudio.stop();
                }

                common.motivationalAudio.stopMotivational();

                const countdown = common.countdown;
                countdown.setVisible(false);
                countdown.resetCountdown();

                common.timer.stopTimer();
            });


            this.room!.onMessage("confirm-race-finished", () => {
                if (!common.balcony.isPlayerOnBalcony.value) {
                    common.CURRENT_STATE = GAME_STATES.POST_ENDGAME;
                    MAIN_CHANNEL.emit("room-race-completed");
                }
            });

            this.room!.onMessage("event-finish-tag", ({ chasersWin, chasersCatches, evadersSurvived }) => {
                if (!common.balcony.isPlayerOnBalcony.value) {
                    common.timer.setCurrentTime(0);
                    common.timer.stopTimer();

                    const tagResultsBoardData = new TagResultBoardData();

                    tagResultsBoardData.chasersWin = chasersWin;

                    tagResultsBoardData.chasersCatches = chasersCatches;
                    tagResultsBoardData.evadersSurvived = evadersSurvived;

                    if (!chasersWin) {
                        tagResultsBoardData.totalSeconds = common.timer.duration!;
                    } else {
                        tagResultsBoardData.totalSeconds = common.timer.duration! - common.timer.time;
                    }

                    tagResultsBoardData.fitPoints = common.hoverboard.getEarnedFitPoints();

                    const trackStatistics = common.tracksManager.getTrackStatistics();
                    tagResultsBoardData.maxSpeed = trackStatistics.maxSpeed;
                    tagResultsBoardData.squatsAmount = trackStatistics.squatsAmount;

                    const tagResultsBoard = common.tracksManager.getTagResultsBoard();
                    tagResultsBoard.updateBoardData(tagResultsBoardData);
                    tagResultsBoard.setVisible(true);

                    common.CURRENT_STATE = GAME_STATES.POST_ENDGAME;

                    AnalyticsUtils.sendEvent("tag_completed");

                    MAIN_CHANNEL.emit("room-tag-completed");
                }
            });

            this.room!.onMessage("race-finished-confirmed", () => {
                this.resetPlayer();
            });

            this.room!.onMessage("player-join-race", ({ message, player }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);

                if (common.gameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerJoinRace(player.startingPosition);
                    readinessIndicator.setIndexReadiness(player.startingPosition, player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_RACETRACK_READY);
                }
            });

            this.room!.onMessage("player-leave-race", ({ message, player: _unusedPlayer, previousStartingPosition }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);

                if (common.gameConfig.mode != GameMode.Roam) {
                    readinessIndicator.onPlayerLeaveRace(previousStartingPosition);
                }
            });

            this.room!.onMessage("event-finish-race", ({ message, raceOver, sessionId, player, finishTime, bestLapTime }) => {
                if (sessionId != this.localPlayerKey) {
                    common.popupManager.showQuickMessagePopup(message + (raceOver ? "\n The race has ended" : ""), PopupIconImage.Info, undefined, undefined, undefined, AudioID.POPUP_TRACK_NOTIFICATION);

                    const score = Math.floor(finishTime * 1000);
                    common.leaderboardsManager.getLeaderboard({
                        type: LeaderboardType.Local,
                        mode: GameMode.Race,
                        location: common.gameConfig.location,
                        track: common.gameConfig.trackConfig.id,
                        lapsAmount: common.gameConfig.lapsAmount.value
                    })!.submitNamedScore(score, player.name).then(() => {
                        common.kioskUpperUI.pickCurrentLeaderboard();
                    });
                } else {
                    if (raceOver) {
                        common.popupManager.showQuickMessagePopup("\n The race has ended", PopupIconImage.Info, undefined, undefined, undefined, AudioID.POPUP_TRACK_NOTIFICATION);
                    }

                    LeaderboardUtils.submitPlayerRaceScore();
                }

                common.kioskUpperUI.updateLastRaceGameConfig();
                common.kioskUpperUI.setLeaderboardType(LeaderboardType.Local);
                common.kioskUpperUI.pickCurrentLeaderboard();
            });

            this.room!.onMessage("event-quit-race", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room!.onMessage("event-player-leave", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room!.onMessage("display-tag-message", ({ message }) => {
                common.popupManager.showMessagePopup(message, PopupIconImage.Info, undefined, undefined, undefined, AudioID.POPUP_TRACK_NOTIFICATION);
            });

            this.room!.onMessage("display-info", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Info);
            });

            this.room!.onMessage("display-warn", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Warn);
            });

            this.room!.onMessage("display-error", ({ message }) => {
                common.popupManager.showQuickMessagePopup(message, PopupIconImage.Error);
            });

            this.room!.onMessage("cant-change-game-config-all-player-balcony", () => {
                common.kioskLowerUI.showCustomInfoPopup("ALL ON BALCONY", "All players must be on the balcony to change the game configuration.");
                common.kioskLowerUI.resetConfigurationValues();
            });

            this.room!.onMessage("cancel-change-game-config", () => {
                common.roomProxy.cancelConfigurationChange(false);
            });

            this.room!.onMessage("timer-adjusted", ({ adjustedTimerValue, adjustmentAmount, playerID }) => {
                common.timer.setCurrentTime(adjustedTimerValue);

                const change = adjustmentAmount > 0 ? "increased" : "reduced";
                const amount = (adjustmentAmount > 0 ? "+" : "-") + adjustmentAmount.toFixed(0);
                if (this.localPlayerKey == playerID) {
                    common.popupManager.showQuickMessagePopup("You " + change + " time\nby " + amount + " seconds", PopupIconImage.StopWatch, undefined, undefined, undefined, null);
                } else {
                    const player = this.otherPlayers.get(playerID);
                    if (player != null) {
                        common.popupManager.showQuickMessagePopup(player.name + " has " + change + " time\nby " + amount + " seconds", PopupIconImage.StopWatch, undefined, undefined, undefined, AudioID.PICKUP_TIMER);
                    } else {
                        common.popupManager.showQuickMessagePopup("Time have been " + change + "\nby " + amount + " seconds", PopupIconImage.StopWatch, undefined, undefined, undefined, AudioID.PICKUP_TIMER);
                    }
                }
            });

            this.room!.onMessage("pickup-request", ({ pickupID, syncCounter, pickupRequestUUID }) => {
                if (common.tracksManager.isRoundStarted()) {
                    const pickupsManager = common.tracksManager.getCurrentTrack()!.getPickupsManager();
                    pickupsManager.onPickupRequestConfirmed(pickupID, syncCounter, pickupRequestUUID);
                }
            });

            this.room!.onMessage("pickup-respawn", ({ pickupID, syncCounter }) => {
                if (common.tracksManager.isRoundStarted()) {
                    const pickupsManager = common.tracksManager.getCurrentTrack()!.getPickupsManager();
                    pickupsManager.onPickupRespawn(pickupID, syncCounter);
                }
            });

            // Voip messaging
            this.room!.onMessage("p2p-toggled", ({ nonce, p2pPriority }) => {
                this.voip.p2pToggled(nonce, p2pPriority);
            });
            this.room!.onMessage("p2p-toggle-denied", ({ nonce }) => {
                this.voip.p2pToggleDenied(nonce);
            });
            this.room!.onMessage("other-p2p-toggled", ({ sessionId, p2pPriority }) => {
                this.voip.setP2PPriorityOf(sessionId, p2pPriority);
            });
            this.room!.onMessage("ice-candidate", ({ sessionId, data }) => {
                this.voip.receiveIceCandidate(sessionId, data);
            });
            this.room!.onMessage("session-description", ({ sessionId, data }) => {
                this.voip.receiveSessionDescription(sessionId, data);
            });
            this.room!.onMessage("mediasoup-ticket", ({ ticket, nonce }) => {
                this.voip.receiveMediasoupTicket(nonce, ticket);
            });
            this.room!.onMessage("mediasoup-ticket-denied", ({ nonce }) => {
                this.voip.mediasoupTicketDenied(nonce);
            });

            this.room!.onMessage("change-game-config", (value) => {
                const newRoomData = new RoomData(common.roomData);
                const newGameConfig = new HoverboardGameConfig();
                newGameConfig.copyFromIdentifier(value.newGameConfig);
                common.menu.changeGameConfig(newRoomData, newGameConfig, false);
            });

            // VoIP command handling/handshake relaying
            // XXX onLeave overrides `this` despite using a lambda, so store it
            const disposeCallback = this.disposeRoom.bind(this);
            this.room!.onLeave((code) => {
                disposeCallback();
                MAIN_CHANNEL.emit("room-leave", code);
            });

            this.room!.onError((error) => {
                disposeCallback();
                MAIN_CHANNEL.emit("room-error", error);
            });

            this.voip.on("toggle-p2p", (nonce, enabled: boolean) => {
                this.updateVoIPWarnings();
                this.room!.send("toggle-p2p", { enabled, nonce });
            });
            this.voip.on("ice-candidate", (sessionId: string, iceCandidate) => {
                this.room!.send("ice-candidate", { sessionId, iceCandidate });
            });
            this.voip.on("session-description", (sessionId: string, sessionDescription) => {
                this.room!.send("session-description", { sessionId, sessionDescription });
            });
            this.voip.on("p2p-enabled", () => this.updateVoIPWarnings());
            this.voip.on("p2p-disabled", () => this.updateVoIPWarnings());
            this.voip.p2pSupported = true;

            this.voip.on("get-mediasoup-ticket", (nonce) => {
                this.updateVoIPWarnings();
                this.room!.send("get-mediasoup-ticket", { nonce });
            });
            this.voip.on("mediasoup-enabled", () => this.updateVoIPWarnings());
            this.voip.on("mediasoup-disabled", () => this.updateVoIPWarnings());
            this.voip.mediasoupSupported = true;

            this.voip.on("peer-added", (otherPeer: VoIPPeer<string>) => {
                otherPeer.on("consumption-mode-changed", () => this.updateVoIPWarnings());

                this.updateVoIPWarnings();
            });

            // TODO in the future, when there are public rooms, don't
            // auto-toggle P2P when a public room is joined to prevent IP
            // leakage
            let hadVoIP = false;
            if (common.playerData.gameSettings.voiceP2P.value) {
                hadVoIP = true;
                this.voip.toggleP2P(true);
            }
            if (common.playerData.gameSettings.voiceMediasoup.value) {
                hadVoIP = true;
                this.voip.toggleMediasoup(true);
            }

            if (hadVoIP) {
                this.requestMicrophone();
            }

            this.resetPlayer();
            this.updateVoIPWarnings();

            MAIN_CHANNEL.emit("room-init-done", isCreate, privateRoom);
        } catch (e) {
            console.error("Initialization Error: ", e);
            this.disposeRoom();
            MAIN_CHANNEL.emit("room-init-error", e);
        }
    }

    updateVoIPWarnings() {
        let p2pBad = false;
        let p2pUnavailable = false;
        let mediasoupUnavailable = false;
        let p2pWanted = false;
        let allPeersHaveP2P = true;
        let mediasoupWanted = false;
        let allPeersHaveMediasoup = true;
        let noPeers = true;

        const badPeersP2P: VoIPPeer<string>[] = [];
        const badPeersMediasoup: VoIPPeer<string>[] = [];

        if (this.room) {
            p2pWanted = common.playerData.gameSettings.voiceP2P.value;
            if (p2pWanted && p2pWanted !== this.voip.p2pEnabled) {
                p2pUnavailable = true;
            }

            mediasoupWanted = common.playerData.gameSettings.voiceMediasoup.value;
            if (mediasoupWanted && mediasoupWanted !== this.voip.mediasoupEnabled) {
                mediasoupUnavailable = true;
            }

            noPeers = this.voip.otherPeers.size > 0;

            for (const peer of this.voip.otherPeers.values()) {
                if (!peer.hasP2P) {
                    allPeersHaveP2P = false;
                    badPeersP2P.push(peer);
                } else if (!peer.consumingP2P) {
                    p2pBad = p2pWanted && !p2pUnavailable;
                    badPeersP2P.push(peer);
                }

                if (!peer.usingMediasoup) {
                    badPeersMediasoup.push(peer);
                    allPeersHaveMediasoup = false;
                }
            }
        }

        const errorCode = (p2pBad ? 1 << 6 : 0) | (allPeersHaveP2P ? 1 << 5 : 0) | (p2pUnavailable ? 1 << 4 : 0) | (p2pWanted ? 1 << 3 : 0) | (allPeersHaveMediasoup ? 1 << 2 : 0) | (mediasoupUnavailable ? 1 << 1 : 0) | (mediasoupWanted ? 1 << 0 : 0);
        const atLeastOnePeerNoConnection = badPeersP2P.some(element => badPeersMediasoup.includes(element));
        const showError = !noPeers && (atLeastOnePeerNoConnection || ((mediasoupUnavailable || !mediasoupWanted) && (p2pUnavailable || !p2pWanted)));
        common.kioskLowerUI.setVoIPErrorEnabled(showError && !!this.room, errorCode);
        common.pauseMenu.setVoIPErrorEnabled(showError && !!this.room, errorCode);
    }

    syncNetworkPlayerState(player: TrackedPlayer, networkPlayerComponent: NetworkPlayerComponent) {
        for (const synchedObject of synchedObjects) {
            for (const key of KEYS) {
                const value = (player as unknown as Record<string, TrackedTransform>)[synchedObject][key as NonFunctionPropNames<TrackedTransform>];
                if (value) networkPlayerComponent.setTransform(synchedObject, key, value);
            }
        }

        for (const propertyName of Object.keys(player.hoverboardData)) {
            networkPlayerComponent.setHoverboardDataPropertyValue(propertyName, player.hoverboardData[propertyName as NonFunctionPropNames<TrackedHoverboardData>]);
        }
    }

    async tryDisconnect() {
        if (!this.room) {
            return;
        }

        try {
            await this.room.leave();
        } catch (err) {
            console.warn(err);

            if (this.room.connection) {
                try {
                    this.room.connection.close();
                } catch (closeErr) {
                    console.warn(closeErr);
                }
            }
        }
    }

    disposeVoIP() {
        this.voip.reset(true);
    }

    disposeRoomConnections() {
        if (this.room) {
            this.room.removeAllListeners();
            this.tryDisconnect();
            this.room = null;
        }
    }

    disposeRoom() {
        // remove room id from url (unless it's a scene load)
        if (!isAnotherSceneLoading) {
            const url = new URL(window.location.href);
            const searchParams = url.searchParams;
            searchParams.delete("room");
            replaceSearchParams(url, searchParams);

            common.roomData.roomNumber = null;
            common.roomData.privateRoom = false;

            if (common.playerData.isGuest) {
                common.playerData.name = null;
            }
        }

        this.disposeVoIP();

        // Cleanup other players
        for (const playerObject of this.otherPlayerObjects.values()) {
            common.networkPlayerPool?.returnEntity(playerObject);
        }

        common.circularMap?.onLeaveRoom();
        common.readinessIndicator?.onLeaveRoom();

        this.otherPlayers.clear();
        this.otherPlayerObjects.clear();

        const countdown = common.countdown;
        countdown.setVisible(false);
        countdown.resetCountdown();

        this.disposeRoomConnections();

        this.resetPlayer();

        this.updateVoIPWarnings();

        // TODO maybe we should also find a way to remove all this.room listener manually, since a callback could be called
        // in the meantime, but the data will not be valid anymore (actually happened when disconnecting while scene loading)
    }

    replaceRoom(room: Colyseus.Room<TrackedHoverboardState>) {
        if (this.room) {
            this.disposeRoom();
        }

        this.room = room;
    }

    setRoundReady(value: boolean) {
        if (this.room)
            this.room.send("set-round-ready", { value });
    }

    moveToTrack() {
        if (this.room) {
            this.room.send("move-to-track");
        }
    }

    returnToBalcony() {
        if (this.room) {
            this.room.send("return-to-balcony");
        }
    }

    raceFinished() {
        if (this.room) {
            this.room.send("set-race-finished", { finishTime: common.menu.finishTime, bestLapTime: common.menu.bestLapTime });
        }
    }

    incrementLaps() {
        if (this.room) {
            this.room.send("increment-laps");
        }
    }

    changeGameConfig(newGameConfig: HoverboardGameConfig) {
        if (this.room) {
            this.room.send("change-game-config", {
                location: newGameConfig.location,
                mode: newGameConfig.mode,
                track: newGameConfig.track,
            });
        }
    }

    updateLapsAmount(newLapsAmount: number) {
        if (this.room) {
            this.room.send("update-laps-amount", { newLapsAmount });
        }
    }

    updateTagDuration(newTagDuration: number) {
        if (this.room) {
            this.room.send("update-tag-duration", { newTagDuration });
        }
    }

    updateNPCsAmount(newNPCsAmount: number) {
        if (this.room) {
            this.room.send("update-npcs-amount", { newNPCsAmount });
        }
    }

    updateNPCsDifficulty(newNPCsDifficulty: number) {
        if (this.room) {
            this.room.send("update-npcs-difficulty", { newNPCsDifficulty });
        }
    }

    adjustTimer(adjustment: number) {
        if (this.room) {
            this.room.send("adjust-timer", { adjustment });
        }
    }

    setTrack(track: number) {
        if (this.room) {
            this.room.send("change-game-config", {
                location: common.gameConfig.location,
                mode: common.gameConfig.mode,
                track,
            });
        }
    }

    sendPickupRequest(pickupID: string, syncCounter: number, pickupRequestUUID: string) {
        if (this.room) {
            this.room.send("pickup-request", { pickupID, syncCounter, pickupRequestUUID });
        }
    }

    sendPickupRespawn(pickupID: string, syncCounter: number) {
        if (this.room) {
            this.room.send("pickup-respawn", { pickupID, syncCounter });
        }
    }

    setPlayerInvincible(invincible: boolean) {
        if (this.room) {
            this.room.send("set-player-invincible", { invincible });
        }
    }

    cancelConfigurationChange() {
        if (this.room) {
            this.room.send("cancel-change-game-config");
        }
    }

    changeGameConfigReady(ready: boolean = true) {
        if (this.room) {
            this.room.send("change-game-config-ready", { ready });
        }
    }

    changeGameConfigDisconnect() {
        if (this.room) {
            this.room.send("change-game-config-disconnect");
        }
    }

    allPlayersReadyToChangeConfig() {
        let allPlayersReady = true;

        for (const player of this.otherPlayers.values()) {
            if (!player.readyToChangeConfig) {
                allPlayersReady = false;
                break;
            }
        }

        return allPlayersReady;
    }

    private onPolicyAcceptedChanged = () => {
        if (!common.playerData.gameSettings.policyAccepted.value) {
            this.revokeMicrophone();
            if (common.roomProxy.state == RoomState.Connected) {
                common.roomProxy.disconnect();
            }
        }

        this.updateVoIPWarningsCallback();
    };

    private onMicrophoneEnabledChanged = () => {
        if (common.playerData.gameSettings.microphoneEnabled.value) {
            this.requestMicrophone();
        } else {
            this.revokeMicrophone();
        }

        this.updateVoIPWarningsCallback();
    };

    private onVoiceP2PChanged = () => {
        try {
            this.voip.toggleP2P(common.playerData.gameSettings.voiceP2P.value);
        } catch (error) {
            // Do nothing
        }

        if (!common.playerData.gameSettings.voiceP2P.value && !common.playerData.gameSettings.voiceMediasoup.value) {
            this.revokeMicrophone();
        } else {
            this.requestMicrophone();
        }

        this.updateVoIPWarningsCallback();
    };

    private onVoiceMediasoupChanged = () => {
        try {
            this.voip.toggleMediasoup(common.playerData.gameSettings.voiceMediasoup.value);
        } catch (error) {
            // Do nothing
        }

        if (!common.playerData.gameSettings.voiceP2P.value && !common.playerData.gameSettings.voiceMediasoup.value) {
            this.revokeMicrophone();
        } else {
            this.requestMicrophone();
        }

        this.updateVoIPWarningsCallback();
    };

    update(dt: number) {
        if (common.gameReady && !this.updateVoIPWarningsCallbackSetup) {
            common.playerData.gameSettings.policyAccepted.watch(this.onPolicyAcceptedChanged);
            common.playerData.gameSettings.microphoneEnabled.watch(this.onMicrophoneEnabledChanged);
            common.playerData.gameSettings.voiceP2P.watch(this.onVoiceP2PChanged);
            common.playerData.gameSettings.voiceMediasoup.watch(this.onVoiceMediasoupChanged);

            this.updateVoIPWarningsCallback();
            this.updateVoIPWarningsCallbackSetup = true;
        }

        if (this.initNetworkTimer.isRunning()) {
            this.initNetworkTimer.update(dt);
            if (this.initNetworkTimer.isDone()) {
                this._start();
            }
        }

        if (this.room) {
            if (this.voip.spatialAudio) {
                this.voip.updateSpatialPos();
            }
        }

        if (this.room) {
            this.enablePlayerJoinedTimer.update(dt);
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.tagRandomCatchEvader) {
            if (Globals.getRightGamepad()!.getButtonInfo(GamepadButtonID.SQUEEZE).isPressEnd(2)) {
                if (common.gameConfig.mode == GameMode.Tag) {
                    if (this.room) {
                        if (this.localPlayer!.state == HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED && !this.localPlayer!.tagged) {
                            let randomChaser = null;
                            const otherPlayerObjects = Array.from(this.room!.state.players.entries());
                            while (randomChaser == null) {
                                const randomOtherPlayer = Math.pp_randomPick(otherPlayerObjects)!;
                                if (randomOtherPlayer[1].state == HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED && randomOtherPlayer[1].tagged) {
                                    randomChaser = randomOtherPlayer;
                                }
                            }

                            this.room.send("player-tagged", { chaserSessionId: randomChaser[0], evaderSessionId: this.localPlayerKey });
                        }
                    }
                }
            }
        }
    }

    roundStart(players: Record<string, TrackedPlayer>) {
        let localPlayer = null;

        for (const playerID in players) {
            if (playerID == this.localPlayerKey) {
                localPlayer = players[playerID];
            }
        }

        if (common.gameConfig.mode == GameMode.Tag) {
            if (localPlayer!.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                const instructionPopupParams = new MessagePopupParams();
                instructionPopupParams.durationSeconds = 6;
                instructionPopupParams.showSeconds = 1;
                instructionPopupParams.hideSeconds = 1;
                instructionPopupParams.priorityParams.expireSeconds = Infinity;
                instructionPopupParams.priorityParams.priorityLevel = PriorityLevel.VeryHigh;
                instructionPopupParams.popupWindowParams.popupIconImage = PopupIconImage.Rocket;
                instructionPopupParams.audioOnShow = AudioID.POPUP_TRACK_NOTIFICATION;

                if (localPlayer!.tagged) {
                    common.audioManager.getAudio(AudioID.CHASER_READY)!.play();
                    instructionPopupParams.popupWindowParams.message = "Catch the Evaders!";
                } else {
                    common.audioManager.getAudio(AudioID.EVADER_READY)!.play();
                    instructionPopupParams.popupWindowParams.message = "Avoid the Chasers!";
                }

                common.popupManager.showPopup(instructionPopupParams);
            }

            for (const playerID in players) {
                const player = players[playerID];

                if (player.state === HOVERBOARD_PLAYER_MULTIPLAYER_STATES.RACE_STARTED) {
                    const circularMap = common.circularMap;
                    circularMap.setIndicatorTagged(playerID, player.tagged);

                    if (player == localPlayer) {
                        circularMap.setMapTagged(player.tagged);
                    } else {
                        const networkPlayerComponent = this.otherPlayerObjects.get(playerID)!.getComponent(NetworkPlayerComponent)!;
                        networkPlayerComponent.setTagged(player.tagged);
                    }
                }
            }
        }
    }

    tagPlayer(evaderSessionId: string) {
        this.room!.send("player-tagged", { chaserSessionId: this.localPlayerKey, evaderSessionId: evaderSessionId });
    }

    getNetworkPlayersObjects() {
        return this.otherPlayerObjects;
    }

    onDestroy() {
        this.disposeVoIP();
        this.disposeRoomConnections();

        common.playerData.gameSettings.policyAccepted.unwatch(this.onPolicyAcceptedChanged);
        common.playerData.gameSettings.microphoneEnabled.unwatch(this.onMicrophoneEnabledChanged);
        common.playerData.gameSettings.voiceP2P.unwatch(this.onVoiceP2PChanged);
        common.playerData.gameSettings.voiceMediasoup.unwatch(this.onVoiceMediasoupChanged);

        if (this.microphoneStatus) {
            this.microphoneStatus.onchange = null;
            this.microphoneStatus = undefined;
        }
    }

    clearNPCReferences() {
        const npcsKeys = [];
        for (const otherPlayerObjectPair of this.otherPlayerObjects.entries()) {
            if (otherPlayerObjectPair[1].pp_getComponent(NetworkPlayerComponent)!.isNPC) {
                npcsKeys.push(otherPlayerObjectPair[0]);
            }
        }

        for (const npcKey of npcsKeys) {
            this.otherPlayerObjects.delete(npcKey);
        }
    }

    setupNPCReferences(index: string, object: Object3D) {
        this.otherPlayerObjects.set(index, object);
    }

    getPlayers(includeCurrentPlayer = true) {
        const players = [];

        for (const playerPair of this.room!.state.players.entries()) {
            if (includeCurrentPlayer || playerPair[0] != this.localPlayerKey) {
                players.push(playerPair[1]);
            }
        }

        return players;
    }

    getPlayersOnTrack(includeCurrentPlayer = true) {
        const playersOnTrack = [];

        for (const playerPair of this.room!.state.players.entries()) {
            if (playerPair[1].onTrack && (includeCurrentPlayer || playerPair[0] != this.localPlayerKey)) {
                playersOnTrack.push(playerPair[1]);
            }
        }

        return playersOnTrack;
    }

    setPeerMute(key: string, muted: boolean) {
        const peer = this.voip.getOtherPeer(key);
        if (!peer) return;
        peer.audioSource.muted = muted;
    }

    isPeerMuted(key: string) {
        const peer = this.voip.getOtherPeer(key);
        if (!peer) return true;
        return peer.audioSource.muted;
    }

    requestMicrophone() {
        this.updateMicrophonePermissions("granted");
        if (this.microphonePermission.value == "granted") this._requestMicrophone();
    }

    revokeMicrophone() {
        this.voip.revokeMic();
    }

    async _requestMicrophone() {
        if (!this.room ||
            (!common.playerData.gameSettings.voiceP2P.value && !common.playerData.gameSettings.voiceMediasoup.value) ||
            !common.playerData.gameSettings.microphoneEnabled.value ||
            !common.playerData.gameSettings.policyAccepted.value) {
            return;
        }

        this.voip.requestMic().then(() => {
            this.microphonePermission.value = this.voip.microphoneResult as (PermissionState | "error" | "unsupported" | null);
        });
    }

    updateMicrophonePermissions(permissionStateOnUnsupported: PermissionState | "error" | "unsupported" | null) {
        this.microphonePermission.value = this.microphoneStatus ? this.microphoneStatus.state : permissionStateOnUnsupported;
    }

    async promptMicrophonePermissions() {
        return VoIPUtils.promptMicrophonePermissions().then((microphonePermission) => {
            this.microphonePermission.value = microphonePermission as (PermissionState | "error" | "unsupported" | null);
        });
    }
}