import { EventEmitter } from "events";
import { type AudioManager } from "./audio/audio-manager/audio-manager.js";
import { type MotivationalAudioManagerComponent } from "./audio/game/components/motivational-audio-manager-component.js";
import { type AvatarSelectorComponent } from "./avatar/components/avatar-selector-component.js";
import { type HoverboardInstructorComponent } from "./avatar/components/hoverboard-instructor-component.js";
import { type WatchControllerComponent } from "./avatar/components/watch-controller-component.js";
import { HoverboardGameConfig } from "./data/game-configuration.js";
import { PlayerData } from "./data/player-data.js";
import { type BalconyComponent } from "./game/balcony/components/balcony-component.js";
import { type IntroComponent } from "./game/components/intro-component.js";
import { type MenuComponent } from "./game/components/menu-component.js";
import { type HoverboardTrackerComponent } from "./game/components/movement-tracker-component.js";
import { type EnvironmentControllerComponent } from "./game/environment/environment-controller.js";
import { GAME_STATES } from "./game/game-states.js";
import { type CircularMapComponent } from "./game/hoverboard/components/circular-map-component.js";
import { type HoverboardComponent } from "./game/hoverboard/components/hoverboard-component.js";
import { type HoverboardSelectorComponent } from "./game/hoverboard/components/hoverboard-selector-component.js";
import { type TimerComponent } from "./game/hoverboard/components/timer-component.js";
import { type CountdownComponent } from "./game/track/components/countdown-component.js";
import { EnvironmentSurroundingControllerComponent } from "./game/track/components/environment-surrounding-controller-component.js";
import { type ReadinessIndicatorComponent } from "./game/track/components/readiness-indicator-component.js";
import { LeaderboardsManager } from "./game/track/leaderboard/leaderboards-manager.js";
import { TracksManager } from "./game/track/tracks-manager.js";
import { BuiltInAssetProvider } from "./misc/asset-provision/built-in-asset-provider.js";
import { FitabuxAssetProvider } from "./misc/asset-provision/fitabux-asset-provider.js";
import { getIAPAssetProvider } from "./misc/asset-provision/iap-asset-provider.js";
import { IAPContentController } from "./misc/asset-provision/iap-content-controller.js";
import { RewardAssetProvider } from "./misc/asset-provision/reward-asset-provider.js";
import { HoverfitSceneResources } from "./misc/components/hoverfit-scene-resources-component.js";
import { type SceneSwitcherVariablesComponent } from "./misc/load-scene/components/scene-switcher-variables-component.js";
import { PreferenceManager } from "./misc/preferences/preference-manager.js";
import { RoomData, type HoverboardNetworkingComponent } from "./network/components/hoverboard-networking-component.js";
import { type NetworkPlayerPoolComponent } from "./network/components/network-player-pool-component.js";
import { type NetworkSyncComponent } from "./network/components/network-sync-component.js";
import { RoomProxy } from "./network/room-proxy.js";
import { KioskControllerComponent } from "./ui/kiosk/components/kiosk-controller-component.js";
import { type KioskLowerUIComponent } from "./ui/kiosk/components/kiosk-lower-ui-component.js";
import { type KioskUpperUIComponent } from "./ui/kiosk/components/kiosk-upper-ui-component.js";
import { type PopupManager } from "./ui/popup/popup-manager.js";
import { type PauseMenuComponent } from "./ui/xml-ui/components/pause-menu-component.js";

type CommonData = {
    CURRENT_STATE: GAME_STATES,
    gameConfig: HoverboardGameConfig,
    iapContentController: IAPContentController,
    playerData: PlayerData,
    preferences: PreferenceManager,
    roomData: RoomData,
    leaderboardsManager: LeaderboardsManager,
    hoverboard: HoverboardComponent,
    quietMode: boolean,
    MAIN_CHANNEL: EventEmitter,
    sceneSwitcherVars: SceneSwitcherVariablesComponent,
    readinessIndicator: ReadinessIndicatorComponent,
    tracksManager: TracksManager,
    menu: MenuComponent,
    pauseMenu: PauseMenuComponent,
    networkPlayerPool: NetworkPlayerPoolComponent,
    networkSync: NetworkSyncComponent,
    hoverboardTracker: HoverboardTrackerComponent,
    intro: IntroComponent,
    countdown: CountdownComponent,
    timer: TimerComponent,
    hoverboardSelector: HoverboardSelectorComponent,
    circularMap: CircularMapComponent,
    hoverboardNetworking: HoverboardNetworkingComponent,
    balcony: BalconyComponent,
    hoverboardInstructor: HoverboardInstructorComponent,
    avatarSelector: AvatarSelectorComponent,
    motivationalAudio: MotivationalAudioManagerComponent,
    kioskUpperUI: KioskUpperUIComponent,
    kioskLowerUI: KioskLowerUIComponent,
    kioskController: KioskControllerComponent,
    roomProxy: RoomProxy,
    watchController: WatchControllerComponent,
    audioManager: AudioManager,
    popupManager: PopupManager,
    environmentSourroundingController: EnvironmentSurroundingControllerComponent,
    hoverfitSceneResources: HoverfitSceneResources,
    environmentController: EnvironmentControllerComponent,
    loadTimestamp: number,
    gameReady: boolean,
};

export const common: CommonData = {} as CommonData;

const COMMON_DESTROY_DENY_LIST = new Set(["playerData", "preferences", "gameConfig", "iapContentController", "roomData", "leaderboardsManager"]);

export function destroyCommon() {
    if (common.MAIN_CHANNEL) common.MAIN_CHANNEL.removeAllListeners();
    if (common.roomProxy) common.roomProxy.destroy();
    if (common.audioManager) common.audioManager.destroy();
    for (const name of Object.getOwnPropertyNames(common)) {
        if (COMMON_DESTROY_DENY_LIST.has(name)) continue;
        delete (common as Record<string, unknown>)[name];
    }
}

let firstTimeInit = true;
export function initCommon() {
    if (firstTimeInit) {
        common.gameConfig = new HoverboardGameConfig();
        common.playerData = new PlayerData(true);
        common.iapContentController = new IAPContentController([
            BuiltInAssetProvider,
            RewardAssetProvider,
            FitabuxAssetProvider,
            getIAPAssetProvider(),
        ]);
        common.preferences = new PreferenceManager();
        common.roomData = new RoomData();
        common.leaderboardsManager = new LeaderboardsManager();

        firstTimeInit = false;
    }

    common.CURRENT_STATE = GAME_STATES.INTRO;
    common.hoverboard = null as unknown as HoverboardComponent;
    common.MAIN_CHANNEL = new EventEmitter();
    // XXX yes, i know this is extremely ugly, but i want an error to be thrown
    //     if someone tries to use these variables and they haven't been
    //     initialized yet, but i don't want the type in the common object to be
    //     `ActualType | null`, otherwise you have to spam exclamation marks
    //     everywhere (e.g. `common.variable!`), and that's worse than having a
    //     single ugly section of code - [20230823 raf]
    common.sceneSwitcherVars = null as unknown as SceneSwitcherVariablesComponent;
    common.readinessIndicator = null as unknown as ReadinessIndicatorComponent;
    common.tracksManager = null as unknown as TracksManager;
    common.menu = null as unknown as MenuComponent;
    common.pauseMenu = null as unknown as PauseMenuComponent;
    common.networkPlayerPool = null as unknown as NetworkPlayerPoolComponent;
    common.networkSync = null as unknown as NetworkSyncComponent;
    common.hoverboardTracker = null as unknown as HoverboardTrackerComponent;
    common.intro = null as unknown as IntroComponent;
    common.countdown = null as unknown as CountdownComponent;
    common.timer = null as unknown as TimerComponent;
    common.hoverboardSelector = null as unknown as HoverboardSelectorComponent;
    common.circularMap = null as unknown as CircularMapComponent;
    common.hoverboardNetworking = null as unknown as HoverboardNetworkingComponent;
    common.balcony = null as unknown as BalconyComponent;
    common.hoverboardInstructor = null as unknown as HoverboardInstructorComponent;
    common.avatarSelector = null as unknown as AvatarSelectorComponent;
    common.motivationalAudio = null as unknown as MotivationalAudioManagerComponent;
    common.kioskUpperUI = null as unknown as KioskUpperUIComponent;
    common.kioskLowerUI = null as unknown as KioskLowerUIComponent;
    common.kioskController = null as unknown as KioskControllerComponent;
    common.roomProxy = new RoomProxy();
    common.watchController = null as unknown as WatchControllerComponent;
    common.audioManager = null as unknown as AudioManager;
    common.popupManager = null as unknown as PopupManager;
    common.environmentSourroundingController = null as unknown as EnvironmentSurroundingControllerComponent;
    common.hoverfitSceneResources = null as unknown as HoverfitSceneResources;
    common.environmentController = null as unknown as EnvironmentControllerComponent;
    common.loadTimestamp = performance.now();
    common.gameReady = false;
}
