import { Mocker } from "@heyvr/sdk-gameplay/sandbox";
// import wleTrace from "@playkostudios/wle-trace";
import { loadRuntime } from "@wonderlandengine/api";
import { common, initCommon } from "./hoverfit/common.js";
import { mockConfig } from "./hoverfit/misc/asset-provision/mock-config.js";
import { heyVRSetupDonePromise } from "./hoverfit/misc/heyvr-sdk-provider.js";
import { initPPMods } from "./hoverfit/pp/pp-mods/init-pp-mods.js";
// import { injectDebugCode, toggleDebugFeature } from "lazy-widgets";

// wleTrace.waitForInjections(() => {
//     // XXX check for resource management bugs
//     // wleTrace.enableWithPrefix("guard:");
//     // wleTrace.enable("breakpoint:guard-failed");
//     // wleTrace.enable("breakpoint:strict-guard-only");
//     // wleTrace.enable("destruction-traces");
//     // wleTrace.enableWithPrefix("debug:");

//     wleTrace.enable("trace:WASM._wljs_component_init");
// });

// injectDebugCode();
// toggleDebugFeature("flashdamage", true);

let preloadPromises = [heyVRSetupDonePromise];
if (!WL_EDITOR) {
    if (DEV_MODE) {
        if (!DISABLE_HEYVR_SANDBOX) {
            console.warn("Using heyVR in development mode");

            Mocker.mock.import(mockConfig);
        } else {
            console.warn("HeyVR sandbox disabled despite being in development mode");
        }

        preloadPromises.push(new Promise((resolve) => {
            if (document.readyState === "complete") {
                resolve(); // XXX data race
            } else {
                window.addEventListener("load", resolve, { once: true });
            }
        }));
    }
}

const hooks = [];
export let loadStarted = false;

export function addAutoRegisterHook(hook) {
    hooks.push(hook);
}

function requestSession(engine, Constants, mode) {
    engine
        .requestXRSession(mode, Constants.WebXRRequiredFeatures, Constants.WebXROptionalFeatures)
        .catch((e) => console.error(e));
}

function setupButtonsXR(engine, Constants) {
    /* Setup AR / VR buttons */
    const arButton = document.getElementById("ar-button");
    if (arButton) {
        arButton.dataset.supported = engine.arSupported;
        arButton.addEventListener("click", () => requestSession(engine, Constants, "immersive-ar"));
    }
    const vrButton = document.getElementById("vr-button");
    if (vrButton) {
        vrButton.dataset.supported = engine.vrSupported;
        vrButton.addEventListener("click", () => requestSession(engine, Constants, "immersive-vr"));
    }
}

export async function load(RuntimeOptions, Constants, editorBuild = true) {
    if (preloadPromises.length > 0) {
        console.warn("This build has an async pre-load stage. Waiting...");
        try {
            await Promise.all(preloadPromises);
        } catch (e) {
            console.error(e);
        }
    }

    if (RuntimeOptions.canvas !== undefined && RuntimeOptions.canvas !== RUNTIME_OPTIONS_CANVAS) {
        console.warn(`Editor-supplied canvas ID ("${RuntimeOptions.canvas}") does not match the build config's canvas ID ("${RUNTIME_OPTIONS_CANVAS}") and will be ignored`);
        RuntimeOptions.canvas = RUNTIME_OPTIONS_CANVAS;
    }

    initPPMods();
    initCommon();

    if (editorBuild) {
        common.gameConfig.guessFromMap(EDITOR_SCENE);
    } else {
        common.gameConfig.copyFromIdentifier(DEFAULT_GAME_CONFIG);
    }

    let sceneName = common.gameConfig.projectName;
    if (editorBuild) {
        console.warn("\n\n\n\nHEY YOU!\nYES, YOU!\nThis is an editor build, which is doing a scene override. The default scene in the CI could be different.\nDon't forget to update build - config.json\n\n\n\n\n");
        sceneName = Constants.ProjectName;
    }

    const engine = await loadRuntime(Constants.RuntimeBaseName, RuntimeOptions);

    engine.onSceneLoaded.once(() => {
        const el = document.getElementById("version");
        if (el) setTimeout(() => el.remove(), 2000);
    });

    /* WebXR setup. */
    const setupButtonsXRBind = setupButtonsXR.bind(null, engine, Constants);
    if (document.readyState === "loading") {
        window.addEventListener("load", setupButtonsXRBind);
    } else {
        setupButtonsXRBind();
    }

    // auto-register components from ALL scenes
    for (const hook of hooks) {
        hook(engine);
    }

    const sceneBinName = `${sceneName}.bin`;
    try {
        await engine.scene.load(sceneBinName);
    } catch (err) {
        console.error(err);
        window.alert(`Failed to load ${sceneBinName}. Check console for details`);
    }
}
