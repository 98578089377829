import { Component, Object3D, WonderlandEngine, type Material } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { LoadingSceneUIComponent } from "src/hoverfit/ui/xml-ui/components/loading-scene-ui-component.js";
import { common } from "../../../common.js";

export class SceneSwitcherVariablesComponent extends Component {
    static override TypeName = "scene-switcher-variables";
    @property.material()
    textMaterial!: Material;

    @property.material()
    uiMaterialNoDepth!: Material;
    @property.object()
    cursorStyleManagerObject!: Object3D;

    static override onRegister(engine: WonderlandEngine) {
        engine.registerComponent(LoadingSceneUIComponent);
    }


    override init() {
        common.sceneSwitcherVars = this;
    }
}
