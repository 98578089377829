import { Alignment, AxisCoupling, Background, Column, Container, filterIDFromProperties, FlexAlignment, Icon, Label, LayeredContainer, RoundedCorners, Row, ScrollableViewportWidget, ScrollbarMode, TextAlignMode, type Widget, type WidgetAutoXML, type WidgetProperties } from "lazy-widgets";

export interface StatsEntry {
    name: string,
    iconSVG?: string,
    iconHeightPercentage?: number,
    iconSpacing?: number,
    dailyValue: string,
    weeklyValue: string,
    monthlyValue: string,
    lifetimeValue: string,
}

export class Stats extends ScrollableViewportWidget<LayeredContainer> {
    static override autoXML: WidgetAutoXML = {
        name: "stats",
        inputConfig: [
            {
                name: "empty-child",
                mode: "widget",
            },
            {
                name: "table-width",
                mode: "value",
                validator: "number",
            },
            {
                name: "row-height",
                mode: "value",
                validator: "number",
            },
        ]
    };

    private column: Column;

    constructor(readonly emptyChild: Widget, readonly tableWidth: number, readonly rowHeight: number, properties?: Readonly<WidgetProperties>) {
        const column = new Column([], {
            multiContainerSpacing: 6,
            multiContainerAlignment: {
                main: FlexAlignment.Start, cross: Alignment.Stretch,
            },
            ...filterIDFromProperties(properties),
        });

        super(
            new LayeredContainer(
                [
                    {
                        child: new Container(
                            column,
                            {
                                containerPadding: {
                                    top: 0, right: 5, bottom: 0, left: 0,
                                },
                            },
                        ),
                    },
                    {
                        child: emptyChild,
                        canExpand: false,
                    },
                ]
            ),
            {
                minWidth: 300,
                minHeight: 10,
                scrollBarThickness: 12,
                scrollbarCornersRadii: 4.5,
                widthCoupling: AxisCoupling.Bi,
                heightCoupling: AxisCoupling.ParentToChild,
                flex: 1,
                scrollbarMode: ScrollbarMode.Layout,
                backgroundFill: "rgba(0,0,0,0)",
                backgroundGlowFill: "rgba(0,0,0,0)",
                primaryFill: "rgba(0, 0, 0, 0.3)",
                accentFill: "rgba(0, 0, 0, 0.3)",
                ...properties,
            },
        );

        this.column = column;
    }

    private getEntryContainer(data: StatsEntry) {
        const bodyTextFont = "1.5em sui-generis-mono-digits";

        const nameWidgets: Widget[] = [
            new Label(
                data.name,
                {
                    bodyTextAlign: TextAlignMode.Center,
                    bodyTextFill: "white",
                    bodyTextFont: bodyTextFont,
                },
            ),
        ];

        if (data.iconSVG) {
            const iconImage = new Image();
            iconImage.crossOrigin = "anonymous";
            iconImage.src = data.iconSVG;

            const iconSize = this.rowHeight * (data?.iconHeightPercentage ?? 0.5);
            nameWidgets.unshift(
                new Label(
                    "",
                    {
                        minWidth: iconSize,
                        maxWidth: iconSize,
                        minHeight: iconSize,
                        maxHeight: iconSize,
                    }
                )
            );

            nameWidgets.push(
                new Icon(
                    iconImage,
                    {
                        width: iconSize,
                        height: iconSize,
                    }
                )
            );
        }

        return new RoundedCorners(
            new Background(
                new Row(
                    [
                        new Container(
                            new Row(
                                nameWidgets,
                                {
                                    multiContainerSpacing: data.iconSpacing ?? 5,
                                    multiContainerAlignment: {
                                        main: FlexAlignment.Center,
                                        cross: Alignment.Stretch,
                                    }
                                }),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flexBasis: 0, flex: 1.4, flexShrink: 1.4,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                data.dailyValue,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFill: "white",
                                    bodyTextFont: bodyTextFont,
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flexBasis: 0, flex: 1, flexShrink: 1,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                data.weeklyValue,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFill: "white",
                                    bodyTextFont: bodyTextFont,
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flexBasis: 0, flex: 1, flexShrink: 1,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                data.monthlyValue,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFill: "white",
                                    bodyTextFont: bodyTextFont,
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flexBasis: 0, flex: 1, flexShrink: 1,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        ),
                        new Container(
                            new Label(
                                data.lifetimeValue,
                                {
                                    bodyTextAlign: TextAlignMode.Center,
                                    bodyTextFill: "white",
                                    bodyTextFont: bodyTextFont,
                                },
                            ),
                            {
                                containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                                containerAlignment: { horizontal: Alignment.Stretch, vertical: Alignment.Stretch },
                                flexBasis: 0, flex: 1, flexShrink: 1,
                                minHeight: this.rowHeight, maxHeight: this.rowHeight,
                            },
                        )
                    ],
                    {
                        multiContainerSpacing: 0,
                    },
                ),
                {
                    containerPadding: { left: 0, right: 0, top: 0, bottom: 0 },
                    canvasFill: "rgba(0, 0, 0, 0.3)",
                },
            ),
            {
                roundedCornersRadii: 12,
                minWidth: this.tableWidth, maxWidth: this.tableWidth,
                minHeight: this.rowHeight, maxHeight: this.rowHeight,
            },
        );
    }

    replaceStatsData(statsDataArray: Array<StatsEntry>) {
        this.column.clearChildren();

        if (statsDataArray.length === 0) {
            this.emptyChild.enabled = true;
        } else {
            this.emptyChild.enabled = false;
            // This assumes the data is sorted
            for (const statsEntry of statsDataArray) {
                this.column.add(this.getEntryContainer(statsEntry));
            }
        }
    }
}