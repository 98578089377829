import { Component, Object3D } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { Globals, Handedness, InputSourceType, InputUtils, Quaternion2, quat2_create } from "wle-pp";

export class SetTransformOnTrackedHandComponent extends Component {
    static TypeName = "set-transform-on-tracked-hand";

    @property.object()
    transform!: Object3D;

    @property.enum(["Left", "Right"], "Left")
    private readonly handedness!: number;

    private gamepadTransform: Quaternion2 = quat2_create();
    private trackedHandTransform: Quaternion2 = quat2_create();
    private adjustedHandedness!: Handedness;

    private isHand: boolean = false;

    start() {
        this.object.pp_getTransformLocalQuat(this.gamepadTransform);
        this.transform.pp_getTransformLocalQuat(this.trackedHandTransform);
        this.adjustedHandedness = InputUtils.getHandednessByIndex(this.handedness)!;
    }

    update(dt: number) {
        const inputSourceType = Globals.getHandPose(this.adjustedHandedness)!.getInputSourceType();
        if (inputSourceType == InputSourceType.TRACKED_HAND && !this.isHand) {
            this.isHand = true;
            this.object.pp_setTransformLocalQuat(this.trackedHandTransform);
        } else if (inputSourceType == InputSourceType.GAMEPAD && this.isHand) {
            this.isHand = false;
            this.object.pp_setTransformLocalQuat(this.gamepadTransform);
        }
    }
}