export enum AudioID {
    INTRO = "intro_sound",
    BALCONY_MUSIC = "balcony_music",
    BUTTON_CLICK = "button_click",
    BUTTON_CANCEL = "button_cancel",
    BUTTON_HOVER = "button_hover",
    BUTTON_EQUIP = "button_equip",
    WATCH_BUTTON_CLICK = "watch_button_click",
    TRACK_MUSIC = "track_music",
    INSTRUCTOR = "instructor",
    HEALTH = "health",
    GAME_MODE_INSTRUCTION = "game_mode_instruction",
    RACER_READY = "racer_ready",
    RACERS_READY = "racers_ready",
    RACE_FINISH = "race_finish",
    RACE_WALL_HIT = "race_wall_hit",
    STATUS_EFFECT_ROCKET = "status_effect_rocket",
    STATUS_EFFECT_SUPER_ROCKET = "status_effect_super_rocket",
    STATUS_EFFECT_SPRING = "status_effect_spring",
    STATUS_EFFECT_SUPER_SPRING = "status_effect_super_spring",
    STATUS_EFFECT_GLUE = "status_effect_glue",
    STATUS_EFFECT_RAMP = "status_effect_ramp",
    STATUS_EFFECT_INVINCIBILITY = "status_effect_invincibility",
    STATUS_EFFECT_SHIELD = "status_effect_shield",
    PICKUP_TIMER = "pickup_timer",
    AMBIENT = "ambient",
    CHASER_READY = "chaser_ready",
    EVADER_READY = "evader_ready",
    HOVER = "hover",
    RISE = "rise",
    TAGGED = "tagged",
    CAUGHT = "caught",
    TAG_ALMOST_OVER_ALERT = "tag_almost_over_alert",
    COUNTDOWN = "countdown",
    KEY_PRESSED = "key_pressed",
    BALCONY_AMBIENT = "balcony_ambient",
    SHOP_MUSIC = "shop_music",
    POPUP_NOTIFICATION = "popup_notification",
    POPUP_TRACK_NOTIFICATION = "popup_track_notification",
    PURCHASE = "purchase",

    // The _ means that the audio ID will be combined with some other forms of IDs to get the final one
    MOTIVATIONAL_ = "motivational_",
    VOIP_ = "voip_",
}