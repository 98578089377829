{
    "canyon": {
        "name": "Thunder Canyon",
        "defaultMode": "race",
        "environments": [
            {
                "name": "Day",
                "skybox": "day",
                "ambient": [1, 1, 1, 1],
                "skyGlow": [0.878, 0.723, 0.248, 0.8],
                "sun": [0.961, 0.815, 0.422, 0.7],
                "zenith": 20,
                "azimuth": 90
            }
        ],
        "modes": {
            "race": {
                "tracks": [
                    {
                        "id": "high-noon",
                        "name": "High Noon",
                        "map": "canyon-open",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "id": "river-run",
                        "name": "River Run",
                        "map": "canyon-open",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "id": "gold-rush",
                        "name": "Gold Rush",
                        "map": "canyon-open",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "id": "road-runner",
                        "name": "Road Runner",
                        "map": "canyon-open",
                        "mapTrackIndex": 4,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "id": "roam-track",
                        "name": "Roam Track",
                        "map": "canyon-open",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0
                    }
                ]
            }
        }
    },
    "snow": {
        "name": "Polar World",
        "defaultMode": "race",
        "environments": [
            {
                "name": "Day",
                "skybox": "snow-day",
                "ambient": [1, 1, 1, 1],
                "skyGlow": [0.329, 0.612, 0.595, 1],
                "sun": [0.562, 0.688, 0.843, 1],
                "zenith": 80,
                "azimuth": 0
            }
        ],
        "modes": {
            "race": {
                "tracks": [
                    {
                        "id": "polar-dash",
                        "name": "Polar Dash",
                        "map": "snow-race",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "id": "arctic-speedway",
                        "name": "Arctic Speedway",
                        "map": "snow-race",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "id": "glacier-drift",
                        "name": "Glacier Drift",
                        "map": "snow-race",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "id": "ice-chase",
                        "name": "Ice Chase",
                        "map": "snow",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "id": "roam-track",
                        "name": "Roam Track",
                        "map": "snow",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            }
        }
    },
    "city": {
        "name": "Thrill City",
        "defaultMode": "race",
        "environments": [
            {
                "name": "Twilight",
                "skybox": "twilight",
                "ambient": [1, 0.749, 0.698, 0.55],
                "skyGlow": [0.116, 0.260, 0.286, 1],
                "sun": [1, 1, 1, 1],
                "zenith": 80,
                "azimuth": 90
            },
            {
                "name": "Day",
                "skybox": "day",
                "ambient": [1, 1, 1, 1],
                "skyGlow": [0.116, 0.260, 0.286, 1],
                "sun": [1, 1, 1, 1],
                "zenith": 20,
                "azimuth": 90
            }
        ],
        "modes": {
            "race": {
                "tracks": [
                    {
                        "id": "outer-limits",
                        "name": "Outer Limits",
                        "map": "city",
                        "mapTrackIndex": 1,
                        "maxPlayers": 6,
                        "difficulties": 5
                    },
                    {
                        "id": "downtown-derby",
                        "name": " Downtown Derby",
                        "map": "city",
                        "mapTrackIndex": 2,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    },
                    {
                        "id": "city-rush",
                        "name": "City Rush",
                        "map": "city",
                        "mapTrackIndex": 3,
                        "maxPlayers": 6,
                        "difficulties": 5,
                        "locked": true
                    }
                ]
            },
            "tag": {
                "tracks": [
                    {
                        "id": "thunder-tag",
                        "name": "Thunder Tag",
                        "map": "city",
                        "mapTrackIndex": 4,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            },
            "roam": {
                "tracks": [
                    {
                        "id": "roam-track",
                        "name": "Roam Track",
                        "map": "city",
                        "mapTrackIndex": 0,
                        "maxPlayers": 6,
                        "difficulties": 0,
                        "locked": true
                    }
                ]
            }
        }
    }
}