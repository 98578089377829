export type LocationEnvironmentColor = [r: number, g: number, b: number, a: number];

export interface LocationEnvironment {
    name: string,
    skybox: string,
    ambient: LocationEnvironmentColor,
    skyGlow: LocationEnvironmentColor,
    sun: LocationEnvironmentColor,
    /**
     * Sun's zenith angle, in degrees. 0 deg points down. 90 deg points
     * sideways.
     */
    zenith: number,
    /**
     * Sun's azimuth angle, in degrees. 0 deg points towards -Z. Rotation is
     * counter-clockwise (90 deg points towards -X).
     */
    azimuth: number,
}