import { Root, Variable, Viewport, Widget, WidgetAutoXML } from "lazy-widgets";
import { DecoratedButton, LabelledDecoratedButtonProperties } from "../../lazy-widgets/widgets/decorated-button.js";

export interface DecoratedCheckboxProperties extends LabelledDecoratedButtonProperties {
    untickedLabel?: string;
    tickedLabel?: string;
}

export class DecoratedLabelCheckbox extends DecoratedButton {
    static override autoXML: WidgetAutoXML = {
        name: "decorated-label-checkbox",
        inputConfig: [
            {
                mode: "value",
                validator: "variable",
                name: "variable"
            }
        ]
    };

    private untickedLabel: string;
    private tickedLabel: string;

    private readonly onTickChange: () => void = () => {
        if (this.isTicked) {
            this.label.text = this.tickedLabel;
        } else {
            this.label.text = this.untickedLabel;
        }
    };

    constructor(readonly variable: Variable<boolean>, properties?: DecoratedCheckboxProperties) {
        super("label",
            {
                containerPadding: { top: 5, right: 5, bottom: 5, left: 5 },
                containerAlignment: { horizontal: 0.5, vertical: 0.5 },
                hasBorder: properties?.hasBorder ?? true,
                normalStroke: properties?.normalStroke ?? "rgba(40, 40, 40, 0.8)",
                forceStrokeThickness: properties?.forceStrokeThickness ?? 6,
                normalStrokeThickness: properties?.normalStrokeThickness ?? 2,
                unclickableStrokeThickness: properties?.unclickableStrokeThickness ?? 2,
                buttonLabelFill: properties?.buttonLabelFill ?? "white",
                ...properties
            }
        );

        this.untickedLabel = properties?.untickedLabel ?? "";
        this.tickedLabel = properties?.tickedLabel ?? "ticked";

        this.on("click", () => {
            this.setTicked(!this.isTicked);
        });
    }

    override attach(root: Root, viewport: Viewport, parent: Widget | null): void {
        super.attach(root, viewport, parent);
        this.variable.watch(this.onTickChange, true);
    }

    override detach(): void {
        this.variable.unwatch(this.onTickChange);
        super.detach();
    }

    setTicked(ticked: boolean) {
        this.variable.value = ticked;
    }

    get isTicked() {
        return this.variable.value;
    }
}