import { heyVRSDK } from "@heyvr/sdk-gameplay/types";

let HeyVR: heyVRSDK | null = null;

export let heyVRSetupDonePromise: Promise<void> | null = null;

if (!WL_EDITOR) {
    let heyVRSetupDonePromiseResolve!: () => void;
    heyVRSetupDonePromise = new Promise<void>((resolve) => {
        heyVRSetupDonePromiseResolve = resolve;
    });

    if (window.location.hostname.endsWith(".heyvr.io")) {
        import("@heyvr/sdk-gameplay/dist")
            .then((module) => {
                HeyVR = {
                    marketing: module.Marketing,
                    inventory: module.Inventory,
                    leaderboard: module.Leaderboard,
                    saveGame: module.SaveGame,
                    arena: module.Arena,
                    user: module.User
                };
            }).finally(() => {
                heyVRSetupDonePromiseResolve();
            });
    } else if (DEV_MODE && !DISABLE_HEYVR_SANDBOX) {
        import("@heyvr/sdk-gameplay/sandbox")
            .then((module) => {
                HeyVR = {
                    marketing: module.MarketingSandbox,
                    inventory: module.InventorySandbox,
                    leaderboard: module.LeaderboardSandbox,
                    saveGame: module.SaveGameSandbox,
                    arena: module.ArenaSandbox,
                    user: module.UserSandbox
                };
            }).finally(() => {
                heyVRSetupDonePromiseResolve();
            });
    } else {
        heyVRSetupDonePromiseResolve();
    }
}

export { HeyVR };

