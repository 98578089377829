import { AbstractVisualElement } from "wle-pp/cauldron/visual/elements/visual_element.js";

let _classModded = false;

export function initVisualElementMod() {
    if (_classModded) return;
    _classModded = true;

    AbstractVisualElement.prototype.destroy = function destroy() {
        this._myDestroyed = true;
    };
}