import { Component } from "@wonderlandengine/api";
import { vec3_create, Vector3 } from "wle-pp";
import { seededRandom } from "../../utils/math-utils.js";

export class SelfMoveComponent extends Component {
    static TypeName = "self-move";

    turn: number = 1;

    private speed: number = 1;
    private mode: number = 1;

    private position: Vector3 = vec3_create(0);

    private seqIndex: number = 0;

    seqRandom(s: number = Number.NEGATIVE_INFINITY) {
        if (s != Number.NEGATIVE_INFINITY) {
            this.seqIndex = s;
            return seededRandom(s);
        } else {
            return seededRandom(++this.seqIndex);
        }
    }

    setSpeed(newSpeed: number) {
        this.speed = newSpeed;
        this.seqRandom(Math.floor(newSpeed * newSpeed));
    }

    update(dt: number) {
        if (dt > 0.1) dt = 0.1;
        if (this.mode == 2) {
            dt *= 0.2;
        } else if (this.mode == 0) {
            dt *= 2;
        }
        this.object.translateObject([0, 0, this.speed * dt]);
        this.object.getPositionLocal(this.position);
        this.position[1] = 0;
        const localDistance = this.position.vec3_length();
        if (localDistance > 1000) {
            this.object.rotateAxisAngleDegObject([0, 1, 0], 180);
            this.object.translateObject([0, 0, 1]);

        }

        ///this.object.rotateAxisAngleDegObject([0, 1, 0], dt * this.turn);

        if (this.seqRandom() < 0.001) {
            this.mode = (this.mode + (1 + Math.floor(2 * this.seqRandom()))) % 3;
        }
    }
}
