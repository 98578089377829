import { UICustomPopupHelper, UICustomPopupHelperParams } from "./ui-custom-popup-helper.js";

export class PauseMenuCustomPopupHelperParams extends UICustomPopupHelperParams {
    title: string = "";
    titleFontSize: number = 2;
    titleIconPath: string = "";
    titleIconSpacing: number = 0;
    message: string = "";
    messageFontSize: number = 0.92;
    messageFontSpacing: number | null = 4;
    primaryButtonText: string = "";
    secondaryButtonText: string = "";
    lowPriorityButtonText: string = "";
    primaryButtonWidth: number | null = null;
    primaryButtonClickCallback: (() => void) | null = null;
    secondaryButtonClickCallback: (() => void) | null = null;
    lowPriorityButtonClickCallback: (() => void) | null = null;
    enableCloseButton: boolean = true;
    closeButtonClickCallback: (() => void) | null = null;
}

export class PauseMenuCustomPopupHelper extends UICustomPopupHelper {
    protected defaultLargeHighPriorityButtonWidth: number = 125;
    protected defaultSmallHighPriorityButtonWidth: number = 100;
    protected defaultLargeLowPriorityButtonWidth: number = 100;
    protected defaultSmallLowPriorityButtonWidth: number = 66.5;
}
