import { GamepadButtonID, Globals, Quaternion2, SaveUtils, vec3_create } from "wle-pp";
import { common } from "../../common.js";
import { HoverboardDebugs } from "../components/hoverboard-debugs-component.js";

export class SpawnPositionProvider {
    private _spawnPositions: Quaternion2[] = [];

    private _spawnPositionSplineTime: number = 0;

    private _debugSpawnPosition: Quaternion2 | null = null;
    private _debugSpawnPositionNPC: Quaternion2 | null = null;

    private _firstUpdate: boolean = true;

    setSpawnPositions(spawnPosition: Quaternion2[]) {
        this._spawnPositions = spawnPosition;
    }

    setSpawnPositionSplineTime(spawnPositionSplineTime: number) {
        this._spawnPositionSplineTime = spawnPositionSplineTime;
    }

    start() {

    }

    update(dt: number) {
        if (this._firstUpdate) {
            this._firstUpdate = false;

            if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled) {
                this._debugSpawnPosition = SaveUtils.loadArray(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position");
                this._debugSpawnPositionNPC = SaveUtils.loadArray(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position_npc");
            }
        }

        if (Globals.isDebugEnabled() && HoverboardDebugs.saveSpawnPositionShortcutEnabled) {
            if (Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.THUMBSTICK).isPressEnd(2)) {
                if (common.hoverboard.isHoverboardStarted()) {
                    this._debugSpawnPosition = common.hoverboard.object.pp_getTransformQuat();

                    const debugPosition = this._debugSpawnPosition.quat2_getPosition().vec3_add(vec3_create(0, -common.hoverboard.verticalOffsetFromGround, 0));
                    this._debugSpawnPosition.quat2_setPosition(debugPosition);

                    SaveUtils.save(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position", JSON.stringify(Array.from(this._debugSpawnPosition)));
                } else {
                    this._debugSpawnPosition = null;
                    SaveUtils.remove(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position");
                }
            }

            if (Globals.getLeftGamepad()!.getButtonInfo(GamepadButtonID.TOP_BUTTON).isPressEnd(2)) {
                if (common.hoverboard.isHoverboardStarted()) {
                    this._debugSpawnPositionNPC = common.hoverboard.object.pp_getTransformQuat();

                    const debugPosition = this._debugSpawnPositionNPC.quat2_getPosition().vec3_add(vec3_create(0, -common.hoverboard.verticalOffsetFromGround, 0));
                    this._debugSpawnPositionNPC.quat2_setPosition(debugPosition);

                    SaveUtils.save(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position_npc", JSON.stringify(Array.from(this._debugSpawnPositionNPC)));
                } else {
                    this._debugSpawnPositionNPC = null;
                    SaveUtils.remove(common.gameConfig.location + "_" + common.gameConfig.mode + "_" + "hoverboard_debug_spawn_position_npc");
                }
            }
        }
    }

    getSpawnPosition(spawnPositionIndex?: number): Quaternion2 {
        if (spawnPositionIndex != null && spawnPositionIndex >= 0 && this._spawnPositions.length > spawnPositionIndex) {
            return this._spawnPositions[spawnPositionIndex];
        } else {
            return Math.pp_randomPick(this._spawnPositions)!;
        }
    }

    getSpawnPositionSplineTime(): number {
        return this._spawnPositionSplineTime;
    }

    getDebugSpawnPosition(): Quaternion2 | null {
        return this._debugSpawnPosition;
    }

    getDebugSpawnPositionNPC(): Quaternion2 | null {
        return this._debugSpawnPositionNPC;
    }
}