import { common } from "src/hoverfit/common.js";
import { type UnsafeDecodedSaveData } from "src/hoverfit/data/save-data.js";
import { DEFAULT_ITEM_OWN_META } from "src/hoverfit/misc/asset-provision/asset-utils.js";
import { type OwnedItem } from "src/hoverfit/misc/asset-provision/owned-item.js";
import { HeyVR } from "src/hoverfit/misc/heyvr-sdk-provider.js";
import { getCookie, unsetCookie } from "src/hoverfit/utils/cookie-utils.js";
import { SAVE_SLOT_KEY } from "./common/constants.js";
import { pendingLeaderboardScores_parse_1to2 } from "./common/pendingLeaderboardScores_parse_1to2.js";
import { SaveDecoder } from "./common/save-decoder.js";

const PERMISSIONS_KEY = "permissionsAccepted";
const QUIET_MODE_PREF_KEY = "quietModeEnabled";
const P2P_PREF_KEY = "p2pEnabled";
const LAST_SESSION_GAME_CONFIG_KEY = "lastSessionGameConfig";
const LAST_SESSION_GAME_ONLINE_CONFIG_KEY = "lastSessionGameOnlineConfig";
const FIRST_START_MODE_KEY = "firstStartMode";
const PENDING_LEADERBOARD_SCORES_KEY = "pendingLeaderboardScore";

export class SaveDecoderLegacy extends SaveDecoder {
    private hadTotalFPCookie = false;

    override decodeSaveData(rawData: any): UnsafeDecodedSaveData | null {
        let fallbackTotalFP: number | undefined;
        const policyAccepted = common.preferences.getPref(PERMISSIONS_KEY, null);
        if (policyAccepted) {
            const fallbackTotalFPStr = getCookie("hoverfit_total_fp");
            if (fallbackTotalFPStr !== undefined) {
                this.hadTotalFPCookie = true;
                const fallbackTotalFPNum = Number(fallbackTotalFPStr);
                if (!isNaN(fallbackTotalFPNum) && isFinite(fallbackTotalFPNum)) {
                    fallbackTotalFP = fallbackTotalFPNum;
                }
            }
        }

        const quietMode = common.preferences.getPref(QUIET_MODE_PREF_KEY);
        const voiceP2P = common.preferences.getPref(P2P_PREF_KEY);
        // XXX ignore mediasoupEnabled; the default value was changed after we
        //     got rid of legacy saves
        const gameConfigOnLoad = common.preferences.getPref(LAST_SESSION_GAME_CONFIG_KEY);
        const gameOnlineConfigOnLoad = common.preferences.getPref(LAST_SESSION_GAME_ONLINE_CONFIG_KEY);
        const showTutorialOnStart = common.preferences.getPref(FIRST_START_MODE_KEY);

        let gameSettingsData: unknown | undefined;
        if (policyAccepted !== null || quietMode !== undefined ||
            voiceP2P !== undefined || gameConfigOnLoad !== undefined ||
            gameOnlineConfigOnLoad !== undefined ||
            showTutorialOnStart !== undefined) {
            gameSettingsData = {
                policyAccepted, quietMode, voiceP2P, gameConfigOnLoad,
                gameOnlineConfigOnLoad, showTutorialOnStart,
            };
        }

        const pendingLeaderboardScores = common.preferences.getPref(PENDING_LEADERBOARD_SCORES_KEY, null);

        if (rawData === null || (typeof rawData !== 'object')) {
            if (fallbackTotalFP !== undefined || gameSettingsData !== undefined || pendingLeaderboardScores) {
                return {
                    totalFitPoints: fallbackTotalFP,
                    gameSettingsData,
                    pendingLeaderboardScores: pendingLeaderboardScores_parse_1to2({ pendingLeaderboardScores }),
                };
            }

            return null;
        }

        const fitabuxInventory: OwnedItem[] = [];
        // no ownership metadata in legacy saves. assume default metadata
        if (Array.isArray(rawData.fitabuxInventory)) {
            for (const shortID of rawData.fitabuxInventory) {
                fitabuxInventory.push([shortID, DEFAULT_ITEM_OWN_META]);
            }
        }

        return {
            avatarType: rawData.avatarType,
            skinColor: rawData.skinColor,
            hoverboardVariant: rawData.hoverboardVariant,
            suitVariant: rawData.suitVariant,
            hairColor: rawData.hairColor,
            headwearVariantMale: rawData.headwearVariantMale,
            headwearVariantFemale: rawData.headwearVariantFemale,
            totalFitPoints: rawData.totalFitPoints ?? fallbackTotalFP,
            dailyFitPoints: rawData.dailyFitPoints,
            dailyRewardTier: rawData.dailyRewardTier,
            bronzeMedals: rawData.bronzeMedals,
            silverMedals: rawData.silverMedals,
            goldMedals: rawData.goldMedals,
            platinumMedals: rawData.platinumMedals,
            currentMidnightTime: rawData.currentMidnightTime,
            fitabux: rawData.fitabux,
            fitabuxInventory,
            rewards: rawData.rewards,
            gameSettingsData,
            pendingLeaderboardScores: pendingLeaderboardScores_parse_1to2({ pendingLeaderboardScores }),
            iapExpirables: [],
        };
    }

    override async postUpgradeCleanup(): Promise<void> {
        // cleanup preferences
        common.preferences.unsetPrefs([
            PERMISSIONS_KEY, QUIET_MODE_PREF_KEY, LAST_SESSION_GAME_CONFIG_KEY,
            LAST_SESSION_GAME_ONLINE_CONFIG_KEY, P2P_PREF_KEY,
            FIRST_START_MODE_KEY, PENDING_LEADERBOARD_SCORES_KEY,
            "mediasoupEnabled", "userAcceptedMicrophoneQuery",
        ]);

        // cleanup beta cookie-backed fitpoints
        if (this.hadTotalFPCookie) {
            unsetCookie("hoverfit_total_fp");
            this.hadTotalFPCookie = false;
        }

        // cleanup old unused save slots
        if (HeyVR) await HeyVR.saveGame.remove(1);
        common.preferences.unsetPref(`${SAVE_SLOT_KEY}1`);
    }
}