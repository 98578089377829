import { BaseLeaderboard } from "./base-leaderboard.js";
import { type LeaderboardScore } from "./leaderboard-score.js";

export class Leaderboard<S extends LeaderboardScore<unknown>> extends BaseLeaderboard<S> {
    override readonly unavailableMessage = null;

    override fetchScores(): Promise<this> {
        return Promise.resolve(this);
    }

    override getAvailableModes(): string[] {
        return ["ALL"];
    }
}