import { Component, TextComponent } from "@wonderlandengine/api";
import { GameMode } from "hoverfit-shared-netcode";
import { common } from "../../../common.js";

export class ReadinessIndicatorComponent extends Component {
    static TypeName = "readiness-indicator";
    static Properties = {
    };

    init() {
        common.readinessIndicator = this;
        this.disposed = true;
        this.textComponent = this.object.getComponent(TextComponent);
        this.textComponent.text = "";
        this.readiness = [null, null, null, null, null, null];

        this.trackIndexMap = [5, 3, 1, 0, 2, 4];
    }

    reset() {
        this.readiness = [null, null, null, null, null, null];
        if (!this.disposed) {
            this.textComponent.text = "";
        }
    }

    onPlayerJoinRace(index) {
        this.readiness[index] = false;
        this.setText();
    }

    onPlayerLeaveRace(index) {
        this.readiness[index] = null;
        this.setText();
    }

    onJoinRoom() {
        this.reset();
    }

    onLeaveRoom() {
        this.reset();
    }

    setIndexReadiness(index, ready) {
        if (this.readiness[index] != null) {
            this.readiness[index] = ready;
        }

        this.setText();
    }

    setEnabled(enabled) {
        this.object.pp_setActive(enabled);
    }

    setText() {
        if (common.gameConfig.mode != GameMode.Roam) {
            let readyText = "Ready Players\n";

            if (common.gameConfig.mode == GameMode.Race) {
                let addSpace = false;
                for (let i = 0; i < this.readiness.length; i++) {
                    let trackIndex = this.trackIndexMap[i];
                    if (this.readiness[trackIndex] != null) {
                        const isReady = this.readiness[trackIndex];
                        if (addSpace) readyText += " ";
                        readyText += isReady ? "X" : "O";
                        addSpace = true;
                    }
                }
            } else {
                let amountValid = 0;
                let amountReady = 0;
                for (const isReady of this.readiness) {
                    if (isReady != null) {
                        amountValid++;
                        if (isReady) {
                            amountReady++;
                        }
                    }
                }

                let addSpace = false;
                for (let i = 0; i < amountValid; i++) {
                    if (addSpace) readyText += " ";
                    readyText += amountReady > 0 ? "X" : "O";
                    addSpace = true;
                    amountReady--;
                }
            }

            this.textComponent.text = readyText;
        } else {
            this.textComponent.text = "";
        }
    }

    onDestroy() {
        // XXX don't clean up children here, they are auto-destroyed
        this.disposed = true;
    }
}
