import { Component, Object3D, TextComponent } from "@wonderlandengine/api";
import { property } from "@wonderlandengine/api/decorators.js";
import { GameMode, gameConfigChangeRequiresReload, isValidConfiguration } from "hoverfit-shared-netcode";
import { Gender } from "src/hoverfit/data/values/gender.js";
import { KioskPage, firstStartModeBackup } from "src/hoverfit/ui/kiosk/components/kiosk-lower-ui-component.js";
import { UICustomPopupHelperParams } from "src/hoverfit/ui/xml-ui/ui-custom-popup-helper.js";
import { AnalyticsUtils, GamepadButtonEvent, GamepadButtonID, Globals, Timer, Vector3, XRUtils } from "wle-pp";
import { AudioID } from "../../audio/audio-manager/audio-id.js";
import { common } from "../../common.js";
import { HoverboardGameConfig, HoverboardGameConfigJSON } from "../../data/game-configuration.js";
import { LoadCancelError, cancelSceneLoad, loadScene } from "../../misc/load-scene/load-scene.js";
import { RoomData } from "../../network/components/hoverboard-networking-component.js";
import { NetworkPlayerComponent } from "../../network/components/network-player-component.js";
import { PopupIconImage } from "../../ui/popup/popup.js";
import { PauseMenuComponent, RaceButtonState } from "../../ui/xml-ui/components/pause-menu-component.js";
import { GAME_STATES } from "../game-states.js";
import { NPCControllerComponent } from "../hoverboard/components/npc-controller-component.js";
import { TrackModeInstructionsComponent } from "../track/components/track-mode-instructions-component.js";
import { LeaderboardUtils } from "../track/leaderboard/leaderboard-utils.js";
import { LeaderboardType } from "../track/leaderboard/leaderboards-manager.js";
import { HoverboardDebugs, HoverboardRuntimeDebugs } from "./hoverboard-debugs-component.js";

// TODO pull all game logic out of this. i dont know who had the brilliant idea
//      of tying game logic to the menu, but now this is a shitshow that needs
//      to be cleaned for the kiosk
// raf: just in case you're wondering, i was very angry when i wrote that, but
//      the point still stands. thankfully we're getting closer to a point where
//      we can remove this, but still:

////////////////////////////////////////////////////////////////////////////////
//
//  DO NOT ADD NEW updateGameConfigValue METHODS HERE. WE'RE TRYING TO MOVE AWAY
//  FROM THAT SO THAT NOT EVERY FILE IN THE GAME DEPENDS ON menu.js
//
//  if you're adding another game property like NPC difficulty, then look at
//  NPCsDifficultyVariable and HoverboardGameConfig for an example of what to do
//  instead. it might be faster to just slap a new method on menu.js, but this
//  will just add even more technical debt
//
////////////////////////////////////////////////////////////////////////////////

let feedbackModalOpened = false;

export class MenuComponent extends Component {
    static TypeName = "menu";

    @property.object()
    private main!: Object3D;

    @property.object()
    private pause!: Object3D;

    public finishTime: number = 0;
    public bestLapTime: number = -1;

    public startMainPositionLocal!: Vector3;

    public currentNPCs: NPCControllerComponent[] = [];

    public firstUpdateDone: boolean = false;
    public ready: boolean = false;

    private localState: GAME_STATES = GAME_STATES.INTRO;

    private avatarConfigDone: boolean = false;

    private pauseMenuToTrackLabelDirty: boolean = true;

    private cursorObject!: Object3D;
    private pauseComponent!: PauseMenuComponent;

    private stateChangeCallbacks: ((oldState: GAME_STATES, newState: GAME_STATES) => void)[] = [];

    private changingGameConfig: ((value: unknown) => void)[] | null = null;

    private trackModeInstructionsComponent!: TrackModeInstructionsComponent;

    private startButtonReady: boolean = false;

    private windowVisibilityChangedListener: (() => void) | null = null;
    private sessionVisibilityChangedListener: ((event: XRSessionEvent) => void) | null = null;

    private showTutorialOnStartDelay: Timer = new Timer(0.75);

    init() {
        common.menu = this;

        this.startMainPositionLocal = this.main.pp_getPositionLocal();
        this.trackModeInstructionsComponent = this.main.pp_getComponent(TrackModeInstructionsComponent)!;

        AnalyticsUtils.setAnalyticsEnabled(true);
        AnalyticsUtils.setSendDataCallback((window as unknown as { gtag: any }).gtag);

        this.windowVisibilityChangedListener = async () => {
            try {
                if (document.visibilityState != "visible") {
                    common.playerData.delayedSavePlayerData();
                }
            } catch (error) {
                // Do Nothing
            }
        };

        window.addEventListener("visibilitychange", this.windowVisibilityChangedListener);
    }

    start() {
        common.playerData.init();

        this.setupGameSettingsListener();

        this.cursorObject = Globals.getPlayerObjects()!.myHandRight!.pp_getObjectByName("Cursor")!;

        Globals.getAudioManager()!.stop();

        this.pauseComponent = this.pause.getComponent(PauseMenuComponent)!;

        Globals.getLeftGamepad()!.registerButtonEventListener(GamepadButtonID.TOP_BUTTON, GamepadButtonEvent.PRESS_END, 0, () => {
            if (this.localState !== GAME_STATES.INTRO) {
                this.togglePause();
            }
        });

        Globals.getLeftGamepad()!.registerButtonEventListener(GamepadButtonID.MENU, GamepadButtonEvent.PRESS_END, 0, () => {
            if (this.localState !== GAME_STATES.INTRO) {
                this.togglePause();
            }
        });

        this.stateChangeCallbacks = [];

        this.stateChangeCallbacks.push(this.cursorToggleFunction.bind(this));

        this.setMainEnabled(false);

        XRUtils.registerSessionStartEndEventListeners(this, this._onXRSessionStart.bind(this), this._onXRSessionEnd.bind(this), true);

        common.intro.registerGameReadyEventListener(this, this._onGameReady.bind(this));

        common.loadTimestamp = performance.now();

        this.ready = true;
    }

    togglePause() {
        // XXX this is the actual public function for pausing and unpausing
        if (!this.pauseComponent.isStateLocked()) {
            if (!this.inPausedState()) {
                this.setPauseEnabled(true);
                AnalyticsUtils.sendEventOnce("open_pause_menu");
            } else {
                this.resumeGame();
            }
        }
    }

    private onPreAuthChanged = (changedKey?: string) => {
        if (changedKey != "pre_auth_changed") return;

        if (common.intro.isDone()) {
            this.returnToBalcony(true, true);
        }
    };

    private _onGameReady() {
        common.playerData.listen(this.onPreAuthChanged, "pre_auth_changed");
    }

    private setMainEnabled(enabled: boolean) {
        this.main.pp_setActive(enabled);

        this.trackModeInstructionsComponent.setModeInstructionsVisible(enabled);

        if (common.gameConfig.mode != GameMode.Roam && common.hoverboardNetworking.room != null) {
            common.readinessIndicator.setEnabled(enabled);
        } else {
            common.readinessIndicator.setEnabled(false);
        }

        if (!enabled) {
            this.main.pp_resetTransformLocal();
        }
    }

    private setPauseEnabled(enabled: boolean) {
        const countdown = common.countdown;
        if (enabled && common.hoverboardNetworking.room && countdown.isRunning()) return;
        const CURRENT_STATE = common.CURRENT_STATE;
        if (enabled && (CURRENT_STATE !== GAME_STATES.GAME && CURRENT_STATE !== GAME_STATES.MENU)) return;

        if (enabled) {
            countdown.pauseCountdown();
            common.CURRENT_STATE = (CURRENT_STATE === GAME_STATES.GAME) ? GAME_STATES.PAUSE : GAME_STATES.PAUSE_MENU;
        }

        if (enabled) {
            common.kioskController.anchorToPlayer();
        } else {
            common.kioskController.anchorToStand();
        }
    }

    inPausedState(): boolean {
        const CURRENT_STATE = common.CURRENT_STATE;
        return CURRENT_STATE === GAME_STATES.PAUSE || CURRENT_STATE === GAME_STATES.PAUSE_MENU || CURRENT_STATE === GAME_STATES.POST_ENDGAME;
    }

    setStartButtonReady(ready: boolean) {
        const startButtonText = this.main.pp_getObjectByName("Start Button")!.pp_getComponent(TextComponent)!;
        if (ready) {
            startButtonText.text = "READY";
        } else {
            startButtonText.text = "CANCEL";
        }

        this.startButtonReady = ready;
    }

    resumeGame() {
        if (!this.inPausedState()) return;
        common.CURRENT_STATE = (common.CURRENT_STATE === GAME_STATES.PAUSE) ? GAME_STATES.GAME : GAME_STATES.MENU;

        if (common.CURRENT_STATE === GAME_STATES.GAME) {
            const countdown = common.countdown;
            if (countdown.finished) {
                common.timer.resumeTimer();
                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC);
                if (raceMusicAudio && !raceMusicAudio.isPlaying()) {
                    raceMusicAudio.play();
                }
            } else {
                countdown.resumeCountdown();
            }
        }

        this.setPauseEnabled(false);
    }

    startRace(checkNetworkRoom: boolean = true, numberOfRacers: number = 1, roundDuration: number | null = null) {
        if (Globals.isDebugEnabled() && HoverboardRuntimeDebugs.moveToTrackDebug) {
            this.startRaceDebug();
            return;
        }

        if (!this.inPausedState()) {
            if (common.hoverboardNetworking.room && checkNetworkRoom) {
                common.hoverboardNetworking.getPlayersOnTrack();
                common.hoverboardNetworking.setRoundReady(this.startButtonReady);
            } else {
                if (!common.balcony.isPlayerOnBalcony.value) {
                    this.setMainEnabled(false);
                }

                if (common.gameConfig.mode == GameMode.Race) {
                    common.leaderboardsManager.getLeaderboard({
                        type: LeaderboardType.Local,
                        mode: GameMode.Race,
                        location: common.gameConfig.location,
                        track: common.gameConfig.trackConfig.id,
                        lapsAmount: common.gameConfig.lapsAmount.value,
                    }).clearScoresOnNextSubmit();
                }

                for (const npc of this.currentNPCs) {
                    npc.startRound();
                }

                this.finishTime = 0;
                this.bestLapTime = -1;

                if (!common.balcony.isPlayerOnBalcony.value) {
                    if (common.CURRENT_STATE === GAME_STATES.MENU) {
                        common.CURRENT_STATE = GAME_STATES.GAME;
                    }
                }

                if (common.gameConfig.mode == GameMode.Tag) {
                    common.timer.setDuration(roundDuration ?? common.gameConfig.tagDuration.value);
                } else if (common.gameConfig.mode == GameMode.Race) {
                    common.timer.setDuration(null);

                    if (numberOfRacers == 1) {
                        common.audioManager.getAudio(AudioID.RACER_READY)!.play();
                    } else {
                        common.audioManager.getAudio(AudioID.RACERS_READY)!.play();
                    }
                }

                common.countdown.startCountdown();

                if (!common.balcony.isPlayerOnBalcony.value) {
                    this.resumeGame();
                }

                if (common.gameConfig.mode == GameMode.Roam) {
                    common.countdown.endCountdown(true);
                }

                AnalyticsUtils.sendEvent("start_game", {
                    multiplayer: !!common.hoverboardNetworking.room,
                    gameMode: common.gameConfig.mode,
                    gameMap: common.gameConfig.map,
                    onBalcony: common.balcony.isPlayerOnBalcony.value,
                    playerCount: numberOfRacers,
                    laps: common.gameConfig.mode == GameMode.Race ? common.gameConfig.lapsAmount.value : undefined,
                });
            }

            if (!common.hoverboardNetworking.room) {
                common.tracksManager.setRoundStarted(true);
            }
        }
    }

    returnToBalcony(checkNetworkRoom: boolean = true, teleportEvenIfAlreadyOnBalcony: boolean = false) {
        if (Globals.isDebugEnabled() && HoverboardRuntimeDebugs.moveToTrackDebug) {
            this.returnToBalconyDebug(teleportEvenIfAlreadyOnBalcony);
            return;
        }

        if (common.balcony.isPlayerOnBalcony.value || !common.balcony.firstMoveDone) {
            if (common.balcony.firstMoveDone && teleportEvenIfAlreadyOnBalcony) {
                common.balcony.moveToBalcony(teleportEvenIfAlreadyOnBalcony);
            }

            return;
        }

        if (common.hoverboardNetworking.room && checkNetworkRoom) {
            common.hoverboardNetworking.returnToBalcony();
        } else {
            if (common.gameConfig.mode == GameMode.Race && common.CURRENT_STATE == GAME_STATES.PAUSE) {
                AnalyticsUtils.sendEvent("abandon_race", {
                    multiplayer: !!common.hoverboardNetworking.room,
                });
            }

            this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

            this.setMainEnabled(false);

            common.tracksManager.getTagResultsBoard().setVisible(false);

            if (!common.hoverboardNetworking.room) {
                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
                const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC)!;
                balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);
                // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
                raceMusicAudio?.stop();
                common.motivationalAudio.stopMotivational();

                const countdown = common.countdown;
                countdown.setVisible(false);
                countdown.resetCountdown();
            }

            const raceFinishAudio = common.audioManager.getAudio(AudioID.RACE_FINISH)!;
            if (raceFinishAudio.isPlaying()) {
                raceFinishAudio.fade(raceFinishAudio.getDefaultVolume(), 0, 1);
            }

            if (!common.hoverboardNetworking.room) {
                common.timer.stopTimer();
            }

            common.timer.hideTimer();

            if (common.CURRENT_STATE != GAME_STATES.INTRO) {
                common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;
            }

            common.balcony.moveToBalcony(teleportEvenIfAlreadyOnBalcony);

            common.tracksManager.returnedToBalcony();

            // XXX needs to be done after returning to balcony due to stats that
            //     are only applied after the match ends
            common.playerData.savePlayerData();
            LeaderboardUtils.submitPlayerContestScore();

            this.resumeGame();
            if (!common.hoverboardNetworking.room) {
                common.tracksManager.setRoundStarted(false);

                this.finishNPCsRace();
                this.returnAllNPCs();
            }

            common.kioskUpperUI.pickCurrentLeaderboard();

            common.kioskController.update(0);
        }
    }

    moveToTrack(checkNetworkRoom: boolean = true, startingPosition: number = 0) {
        if (!common.balcony.isPlayerOnBalcony.value) return;

        HoverboardRuntimeDebugs.moveToTrackDebug = false;

        const hoverboardNetworking = common.hoverboardNetworking;
        const hoverboardRoom = hoverboardNetworking.room;

        if (hoverboardRoom && checkNetworkRoom) {
            hoverboardNetworking.moveToTrack();
        } else {
            if (!hoverboardRoom && common.gameConfig.mode == GameMode.Tag) {
                const popupParams = new UICustomPopupHelperParams();
                popupParams.title = "ONLINE ONLY";
                popupParams.message = "Tag mode is only available when playing online.";
                popupParams.primaryButtonText = "JOIN";
                popupParams.primaryButtonClickCallback = () => {
                    common.kioskLowerUI.closePopup();
                    common.kioskLowerUI.changeKioskPage(KioskPage.Multiplayer);
                };
                popupParams.lowPriorityButtonText = "CLOSE";
                popupParams.lowPriorityButtonClickCallback = () => { common.kioskLowerUI.closePopup(); };
                common.kioskLowerUI.showCustomPopup(popupParams);
            } else {
                this.setStartButtonReady(true);

                this.pauseComponent.getRaceButtonState().value = RaceButtonState.ToBalcony;

                const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
                balconyMusicAudio.fade(balconyMusicAudio.getVolume(), 0.0, 0.8);

                this.setMainEnabled(true);

                if (common.gameConfig.mode != GameMode.Roam) {
                    const countdown = common.countdown;
                    countdown.setVisible(true);
                    countdown.resetCountdown();
                }

                common.timer.stopTimer();
                common.timer.resetTimer();

                common.balcony.moveToTrack(startingPosition);

                // Cleanup before going to the track to see npcs race on the track
                this.returnAllNPCs();
                this.setupNPCs(false);

                common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;

                common.hoverboardInstructor.stopAudioPlayers();

                this.resumeGame();

                AnalyticsUtils.sendEvent("move_to_track", {
                    multiplayer: !!common.hoverboardNetworking.room,
                    gameMode: common.gameConfig.mode,
                    gameMap: common.gameConfig.map,
                });
            }
        }
    }

    private startRaceDebug() {
        if (!this.inPausedState()) {
            if (!common.balcony.isPlayerOnBalcony.value) {
                this.setMainEnabled(false);
            }

            if (common.gameConfig.mode == GameMode.Race) {
                common.leaderboardsManager.getLeaderboard({
                    type: LeaderboardType.Local,
                    mode: GameMode.Race,
                    location: common.gameConfig.location,
                    track: common.gameConfig.trackConfig.id,
                    lapsAmount: common.gameConfig.lapsAmount.value,
                }).clearScoresOnNextSubmit();
            }

            this.finishTime = 0;
            this.bestLapTime = -1;

            if (!common.balcony.isPlayerOnBalcony.value) {
                if (common.CURRENT_STATE === GAME_STATES.MENU) {
                    common.CURRENT_STATE = GAME_STATES.GAME;
                }
            }

            common.countdown.startCountdown();

            if (!common.balcony.isPlayerOnBalcony.value) {
                this.resumeGame();
            }

            if (common.gameConfig.mode == GameMode.Roam) {
                common.countdown.endCountdown(true);
            }
        }
    }

    returnToBalconyDebug(teleportEvenIfAlreadyOnBalcony: boolean = false) {
        if (common.balcony.isPlayerOnBalcony.value || !common.balcony.firstMoveDone) {
            if (common.balcony.firstMoveDone && teleportEvenIfAlreadyOnBalcony) {
                common.balcony.moveToBalcony(teleportEvenIfAlreadyOnBalcony);
            }

            return;
        }

        this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

        this.setMainEnabled(false);

        common.tracksManager.getTagResultsBoard().setVisible(false);

        if (!common.hoverboardNetworking.room) {
            const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
            const raceMusicAudio = common.audioManager.getAudio(AudioID.TRACK_MUSIC)!;
            balconyMusicAudio.fade(0, balconyMusicAudio.getDefaultVolume(), 0.8);
            // raceMusicAudio.fade(raceMusicAudio.getDefaultVolume(), 0.0, 0.8);
            raceMusicAudio?.stop();
            common.motivationalAudio.stopMotivational();

            const countdown = common.countdown;
            countdown.setVisible(false);
            countdown.resetCountdown();
        }

        const raceFinishAudio = common.audioManager.getAudio(AudioID.RACE_FINISH)!;
        if (raceFinishAudio.isPlaying()) {
            raceFinishAudio.fade(raceFinishAudio.getDefaultVolume(), 0, 1);
        }

        if (!common.hoverboardNetworking.room) {
            common.timer.stopTimer();
        }

        common.timer.hideTimer();

        if (common.CURRENT_STATE != GAME_STATES.INTRO) {
            common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;
        }

        common.balcony.moveToBalcony(teleportEvenIfAlreadyOnBalcony);

        common.tracksManager.returnedToBalcony();

        // XXX needs to be done after returning to balcony due to stats that
        //     are only applied after the match ends
        common.playerData.savePlayerData();
        LeaderboardUtils.submitPlayerContestScore();

        this.resumeGame();

        common.kioskUpperUI.pickCurrentLeaderboard();

        common.kioskController.update(0);
    }

    moveToTrackDebug() {
        if (!common.balcony.isPlayerOnBalcony.value) return;

        HoverboardRuntimeDebugs.moveToTrackDebug = true;

        this.setStartButtonReady(true);

        this.pauseComponent.getRaceButtonState().value = RaceButtonState.ToBalcony;

        const balconyMusicAudio = common.audioManager.getAudio(AudioID.BALCONY_MUSIC)!;
        balconyMusicAudio.fade(balconyMusicAudio.getVolume(), 0.0, 0.8);

        this.setMainEnabled(true);

        common.timer.stopTimer();
        common.timer.resetTimer();

        common.balcony.moveToTrack(0);

        common.CURRENT_STATE = GAME_STATES.PAUSE_MENU;

        common.hoverboardInstructor.stopAudioPlayers();

        this.resumeGame();
    }

    returnAllNPCs() {
        common.hoverboardNetworking.clearNPCReferences();

        if (this.currentNPCs.length) {
            for (let i = 0; i < this.currentNPCs.length; i++) {
                const npc = this.currentNPCs[i];
                common.networkPlayerPool.returnEntity(npc.object);
            }
        }

        this.currentNPCs = [];
    }

    setupNPCs(includeCurrentPlayer: boolean) {
        if (common.gameConfig.canHaveNPCs && common.gameConfig.npcsAmount.value > 0) {
            const seed = common.hoverboardNetworking.npcSeed;

            const activeNPCsAmount = this.getNPCsActiveAmount(includeCurrentPlayer);
            for (let i = 0; i < activeNPCsAmount; i++) {
                const player = common.networkPlayerPool.getEntity();
                const networkPlayerComponent = player.getComponent(NetworkPlayerComponent);
                networkPlayerComponent.setEnabled(true);

                networkPlayerComponent.setNPC(i, seed + i);
                common.hoverboardNetworking.setupNPCReferences(i.toString(), player);

                this.currentNPCs.push(networkPlayerComponent.npcController);
            }
        }
    }

    finishNPCsRace() {
        for (const currentNPC of this.currentNPCs) {
            currentNPC.finishRace();
        }
    }

    onCountdownFinished() {
        for (let i = 0; i < this.currentNPCs.length; i++) {
            const npc = this.currentNPCs[i];
            npc.startRacing();
        }
    }

    private onStateChange(oldState: GAME_STATES, newState: GAME_STATES) {
        for (const f of this.stateChangeCallbacks) {
            f(oldState, newState);
        }
    }

    private cursorToggleFunction(oldState: GAME_STATES, newState: GAME_STATES) {
        if (newState == GAME_STATES.GAME) {
            this.cursorObject.pp_setActive(false);
        } else {
            this.cursorObject.pp_setActive(true);
        }
    }

    async changeGameConfig(newRoomData: RoomData, newGameConfig: HoverboardGameConfig, checkNetworkRoom: boolean = true) {
        const hoverboardNetworking = common.hoverboardNetworking;

        if (!isValidConfiguration(newGameConfig, true)) {
            common.popupManager?.showQuickMessagePopup("Invalid game configuration\nAre you running an outdated game client?", PopupIconImage.Error);
            return false;
        }

        const needsReload = gameConfigChangeRequiresReload(common.gameConfig, newGameConfig);
        if (!needsReload) {
            if (needsReload === null) {
                if (common.popupManager != null) {
                    common.popupManager?.showQuickMessagePopup("You are already playing with\nthis game configuration", PopupIconImage.Warn);
                }
            } else {
                if (hoverboardNetworking.room && checkNetworkRoom) {
                    hoverboardNetworking.changeGameConfig(newGameConfig);
                } else {
                    if (common.gameConfig.mode != newGameConfig.mode) {
                        common.gameConfig.mode = newGameConfig.mode;
                        common.kioskLowerUI.gameModeChanged();
                    }

                    common.gameConfig.track = newGameConfig.track;
                    common.tracksManager.setTrackByMapTrackIndex(common.gameConfig.trackConfig.mapTrackIndex);

                    common.readinessIndicator.reset();
                }
            }

            common.kioskUpperUI.pickCurrentLeaderboard();

            const newSessionGameConfig = new HoverboardGameConfigJSON();
            newSessionGameConfig.location = newGameConfig.location;
            newSessionGameConfig.mode = newGameConfig.mode;
            newSessionGameConfig.track = newGameConfig.track;

            common.playerData.gameSettings.gameConfigOnLoad.value = newSessionGameConfig;

            return true;
        }

        // can only cancel scene download when in singleplayer
        if (this.changingGameConfig && common.roomData.roomNumber == null) {
            if (!cancelSceneLoad()) {
                common.popupManager?.showQuickMessagePopup("A game configuration change\nis already in progress", PopupIconImage.Warn);
                return false;
            }

            await new Promise((resolve, _reject) => {
                if (this.changingGameConfig) {
                    this.changingGameConfig.push(resolve);
                } else {
                    resolve(undefined);
                }
            });
        }

        let configChanged = false;

        if (!this.changingGameConfig) {
            if (hoverboardNetworking.room && checkNetworkRoom) {
                hoverboardNetworking.changeGameConfig(newGameConfig);
            } else {
                this.changingGameConfig = [];

                try {
                    await loadScene(this.engine, newGameConfig, newRoomData);
                    configChanged = true;

                    const newSessionGameConfig = new HoverboardGameConfigJSON();
                    newSessionGameConfig.location = newGameConfig.location;
                    newSessionGameConfig.mode = newGameConfig.mode;
                    newSessionGameConfig.track = newGameConfig.track;

                    common.playerData.gameSettings.gameConfigOnLoad.value = newSessionGameConfig;
                } catch (error) {
                    if (!(error instanceof LoadCancelError)) {
                        common.kioskLowerUI.showCustomInfoPopup("ERROR", "Could not change to the selected game configuration.");
                        console.error("Change game config error: ", error);
                    }

                    if (common.intro.isDone() && (common.intro.isFadedOut() || common.intro.isManuallyFadingOut())) {
                        common.intro.cancelManualFadeOut();
                    }

                    common.kioskLowerUI.resetConfigurationValues();

                    if (common.hoverboardNetworking.room) {
                        common.hoverboardNetworking.cancelConfigurationChange();
                    }
                }

                const callbacks = this.changingGameConfig;
                this.changingGameConfig = null;
                for (const callback of callbacks) callback(undefined);
            }
        } else {
            common.popupManager?.showQuickMessagePopup("A game configuration change\nis already in progress", PopupIconImage.Warn);
        }

        if (configChanged) common.kioskUpperUI?.pickCurrentLeaderboard();

        return configChanged;
    }

    private onAvatarTypeChanged = () => {
        const playerData = common.playerData;
        common.avatarSelector.setAvatarType(playerData.avatarType, playerData.avatar!, false, true);
    };

    private onSkinColorChanged = () => {
        common.avatarSelector.setAvatarSkinColor(common.iapContentController.adjustedSkinColor.value, common.playerData.avatar!, false, true);
    };

    private onSuitVariantChanged = () => {
        common.avatarSelector.setAvatarSuit(common.iapContentController.adjustedSuitVariant.value, common.playerData.avatar!, false, true);
    };

    private onHeadwearChanged = () => {
        const playerData = common.playerData;
        const iapCC = common.iapContentController;
        common.avatarSelector.setAvatarHeadwear(playerData.avatarType === Gender.Male ? iapCC.adjustedHeadwearVariantMale.value : iapCC.adjustedHeadwearVariantFemale.value, playerData.avatar!, false, true);
    };

    private onHairColorChanged = () => {
        common.avatarSelector.setAvatarHairColor(common.iapContentController.adjustedHairColor.value, common.playerData.avatar!, false, true);
    };

    private onHoverboardVariantChanged = () => {
        common.hoverboardSelector.setHoverboard(common.iapContentController.adjustedHoverboardVariant.value, common.hoverboard.hoverboardMeshObject, false, true);
    };

    update(dt: number) {
        if (!this.firstUpdateDone) {
            this.firstUpdate();
            this.firstUpdateDone = true;
        }

        if (common.playerData.gameSettings.showTutorialOnStart.value && firstStartModeBackup &&
            common.intro.isDone() && this.showTutorialOnStartDelay.isRunning()) {
            this.showTutorialOnStartDelay.update(dt);
            if (this.showTutorialOnStartDelay.isDone()) {
                if (!DEV_MODE || !Globals.isDebugEnabled()) {
                    common.kioskLowerUI.displayAskTutorialPopup(false);
                }
            }

        }

        const CURRENT_STATE = common.CURRENT_STATE;
        if (this.localState !== CURRENT_STATE) {
            this.onStateChange(this.localState, CURRENT_STATE);
            this.localState = CURRENT_STATE;
        }

        const playerData = common.playerData;
        const iapCC = common.iapContentController;
        if (!this.avatarConfigDone && playerData.avatar!.isReady() && common.kioskController.configAvatarComponent!.isReady() && iapCC.ready) {
            // HACK this is needed because we're using ECS, which is sh-
            //      suboptimal. ideally we would just have a very explicit
            //      initialisation method for everything, and use a class-based
            //      (or even procedural) approach instead of the abomination
            //      that is wonderland's ECS
            common.playerData.listen(this.onAvatarTypeChanged, 'avatarType');
            this.onAvatarTypeChanged();
            iapCC.adjustedSkinColor.watch(this.onSkinColorChanged, true);
            iapCC.adjustedSuitVariant.watch(this.onSuitVariantChanged, true);
            iapCC.adjustedHeadwearVariantMale.watch(this.onHeadwearChanged, true);
            iapCC.adjustedHeadwearVariantFemale.watch(this.onHeadwearChanged, true);
            iapCC.adjustedHairColor.watch(this.onHairColorChanged, true);
            iapCC.adjustedHoverboardVariant.watch(this.onHoverboardVariantChanged, true);

            this.avatarConfigDone = true;
        }

        if (this.pauseMenuToTrackLabelDirty) {
            this.pauseComponent.getRaceButtonState().value = RaceButtonState.Start;

            this.pauseMenuToTrackLabelDirty = false;
        }

        playerData.update(dt);
    }

    getPlayersOnTrack(includeCurrentPlayer: boolean): number {
        let playersOnTrack = (!common.balcony.isPlayerOnBalcony.value && includeCurrentPlayer) ? 1 : 0;
        if (common.hoverboardNetworking.room) {
            playersOnTrack += common.hoverboardNetworking.getPlayersOnTrack(false).length;
        }

        return playersOnTrack;
    }

    private firstUpdate() {
        Globals.getLeftHandPose()!.setSwitchToTrackedHandDelay(2);
        Globals.getLeftHandPose()!.setSwitchToTrackedHandDelayEnabled(true);
        Globals.getLeftHandPose()!.setSwitchToTrackedHandDelayKeepCheckingForGamepadFrameCounter(9999);
        Globals.getLeftHandPose()!.setSwitchToTrackedHandDelayNoInputSourceRiskyFixEnabled(true);

        Globals.getRightHandPose()!.setSwitchToTrackedHandDelay(2);
        Globals.getRightHandPose()!.setSwitchToTrackedHandDelayEnabled(true);
        Globals.getRightHandPose()!.setSwitchToTrackedHandDelayKeepCheckingForGamepadFrameCounter(9999);
        Globals.getRightHandPose()!.setSwitchToTrackedHandDelayNoInputSourceRiskyFixEnabled(true);
    }

    private getNPCsActiveAmount(includeCurrentPlayer: boolean): number {
        if (!common.gameConfig.canHaveNPCs) return 0;

        let maxNPCs = common.gameConfig.npcsAmount.value;

        const trackMaxPlayers = common.gameConfig.trackConfig.maxPlayers;
        if (trackMaxPlayers !== null) {
            // FIXME this looks wrong. it should be
            // (!common.balcony.isPlayerOnBalcony.value && includeCurrentPlayer) ? ...
            //       but idk if that messes up setupNPCs
            let playersOnTrack = common.balcony.isPlayerOnBalcony.value ? 0 : 1;
            if (common.hoverboardNetworking.room) {
                playersOnTrack = common.hoverboardNetworking.getPlayersOnTrack(includeCurrentPlayer).length;
                playersOnTrack += !includeCurrentPlayer ? 1 : 0;
            }

            maxNPCs = Math.max(0, trackMaxPlayers - playersOnTrack);
        }

        return Math.min(maxNPCs, common.gameConfig.npcsAmount.value);

    }

    private _onXRSessionStart(session: XRSession) {
        AnalyticsUtils.sendEventOnce("enter_xr");

        (window as any).closeBetaInfoModal();

        this.sessionVisibilityChangedListener = async (event: XRSessionEvent) => {
            try {
                if (event.session.visibilityState != "visible") {
                    common.playerData.delayedSavePlayerData();
                }
            } catch (error) {
                // Do Nothing
            }
        };

        session.addEventListener("visibilitychange", this.sessionVisibilityChangedListener);
    }

    private _onXRSessionEnd() {
        if (common.intro.isDone() && !feedbackModalOpened) {
            if (!Globals.isDebugEnabled() || !HoverboardDebugs.disableFeedbackModal) {
                feedbackModalOpened = true;
                //(window as any).openBetaInfoModal();
            }
        }

        common.playerData.delayedSavePlayerData();
    }

    private setupGameSettingsListener() {
        common.playerData.gameSettings.policyAccepted.watch((_, group) => {
            const accepted = common.playerData.gameSettings.policyAccepted.value;
            if (accepted !== null) common.preferences.allowStorage.value = accepted;
        }, true);
    }

    onDestroy(): void {
        if (this.avatarConfigDone) {
            const iapCC = common.iapContentController;
            iapCC.adjustedHoverboardVariant.unwatch(this.onHoverboardVariantChanged);
            iapCC.adjustedHairColor.unwatch(this.onHairColorChanged);
            iapCC.adjustedHeadwearVariantFemale.unwatch(this.onHeadwearChanged);
            iapCC.adjustedHeadwearVariantMale.unwatch(this.onHeadwearChanged);
            iapCC.adjustedSuitVariant.unwatch(this.onSuitVariantChanged);
            iapCC.adjustedSkinColor.unwatch(this.onSkinColorChanged);
            common.playerData.unlisten(this.onAvatarTypeChanged);
        }

        common.playerData.savePlayerData();

        if (this.windowVisibilityChangedListener != null) {
            window.removeEventListener("visibilitychange", this.windowVisibilityChangedListener);
            this.windowVisibilityChangedListener = null;
        }

        if (this.sessionVisibilityChangedListener != null && XRUtils.isSessionActive()) {
            XRUtils.getSession()!.removeEventListener("visibilitychange", this.sessionVisibilityChangedListener);
            this.sessionVisibilityChangedListener = null;
        }

        common.playerData.unlisten(this.onPreAuthChanged);
    }
}